import { EventContentArg } from '@fullcalendar/core';
import { useTranslation } from 'react-i18next';
import {
  AbsoluteContainer,
  CityText,
  Container,
  Content,
  FlagWrapper,
  RowSpaceBetween,
  StreetText,
  TimeText,
  TitleText,
} from './Event.styled';
import { LinearProgress } from '@mui/material';
import { useIsFieldWorker } from 'components/Routes/FieldWorkerRoute/hooks';
import Icon from 'components/Icon/Icon';
import {
  Check,
  Notepad,
  ListChecks,
  FunnelSimpleX,
} from '@phosphor-icons/react';
import moment from 'moment';
import Tooltip from 'components/Tooltip/Tooltip';
import { COLORS } from 'assets/styled';

interface IEventProps {
  content: EventContentArg;
}

const Event = ({ content }: IEventProps) => {
  const { t } = useTranslation();
  const isFieldWorker = useIsFieldWorker();
  const extendedProps = content.event._def.extendedProps;
  const address = extendedProps.shippingData
    ? extendedProps.shippingData.address
    : extendedProps.address;
  const hasReport = extendedProps.hasReport;
  const processedInOffice = extendedProps.processedInOffice;
  const allLinesReceived = extendedProps.allLinesReceived;
  const notAllLinesReceived = extendedProps.notAllLinesReceived;
  const isInPast = extendedProps.isInPast;
  const isFieldWorkInProgress = extendedProps?.status?.id?.toString() === '5';
  const isFailed = extendedProps.isFailed;
  const backgroundColor = content.backgroundColor;
  const textColor = content.textColor;
  const borderColor = content.borderColor;
  const instance = content.event._instance;
  let timeText = '';

  if (instance) {
    const startDate = moment(instance.range.start).utc();
    const endDate = moment(instance.range.end).utc();
    timeText = startDate.format('HH:mm') + ' - ' + endDate.format('HH:mm');
  }
  // Define a component to render conditionally
  let absoluteComponent = null;

  if (isFieldWorkInProgress) {
    // Show a progress bar when field work is in progress
    absoluteComponent = (
      <AbsoluteContainer>
        <LinearProgress variant="indeterminate" />
      </AbsoluteContainer>
    );
  } else if (processedInOffice && !isFieldWorker) {
    absoluteComponent = (
      <FlagWrapper
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
      >
        <Tooltip content={t('Processed')}>
          <Icon svg={Check} color={textColor} size={12} weight="bold" />
        </Tooltip>
      </FlagWrapper>
    );
  } else if (hasReport) {
    absoluteComponent = (
      <FlagWrapper
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
      >
        <Tooltip content={t('Has Report')}>
          <Icon svg={Notepad} color={textColor} size={12} weight="bold" />
        </Tooltip>
      </FlagWrapper>
    );
  } else if (allLinesReceived && !isInPast) {
    absoluteComponent = (
      <FlagWrapper
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
      >
        <Tooltip content={t('All Lines Received')}>
          <Icon svg={ListChecks} color={COLORS.GREEN} size={12} weight="bold" />
        </Tooltip>
      </FlagWrapper>
    );
  } else if (notAllLinesReceived && !isInPast) {
    absoluteComponent = (
      <FlagWrapper
        $backgroundColor={backgroundColor}
        $borderColor={borderColor}
      >
        <Tooltip content={t('Not All Lines Received')}>
          <Icon
            svg={FunnelSimpleX}
            color={COLORS.RED_TRASH}
            size={12}
            weight="bold"
          />
        </Tooltip>
      </FlagWrapper>
    );
  }

  return (
    <Container
      data-testid={`appointment-${content.timeText}-${content.event.title}`}
    >
      {absoluteComponent}
      <Content>
        <RowSpaceBetween>
          <TimeText>{timeText}</TimeText>
          <TitleText marginRight={isFieldWorkInProgress ? '5rem' : 0}>
            {content?.event?.title && t(content.event.title)}
          </TitleText>
        </RowSpaceBetween>
        <CityText>{address?.city}</CityText>
        <StreetText>
          {address?.street} {address?.house_number}
        </StreetText>
      </Content>
    </Container>
  );
};

export default Event;
