import { formatPurchaseOrdersTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { RoutesConfig } from 'navigation/routes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  IPurchaseOrder,
  IPurchaseOrderTableDTO,
} from 'types/PurchaseOrders.types';
import { SINGLE_SALES_ORDER_PURCHASE_ORDERS_PER_PAGE } from './constants';
import {
  useGetSalesOrderId,
  useGetSingleSalesOrderPurchaseOrders,
} from './hooks';
import {
  Container,
  LoaderWrapper,
  NoContentLabel,
} from './SingleSalesOrderPurchaseOrdersPage.styled';
import { tableConfig } from 'pages/Manager/PurchaseOrdersPage/constants';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';

const SingleSalesOrderPurchaseOrdersPage = () => {
  const navigate = useNavigate();
  const salesOrderId = useGetSalesOrderId();
  const [page, setPage] = useState<number>(1);
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const { isLoading, data, isFetched } = useGetSingleSalesOrderPurchaseOrders(
    salesOrderId,
    page,
    SINGLE_SALES_ORDER_PURCHASE_ORDERS_PER_PAGE
  );
  const handleViewButtonClick = (rowData: IPurchaseOrderTableDTO) => {
    navigate(
      RoutesConfig.SinglePurchaseOrder.fullPath
        .replace(':id', rowData.navigationID)
        .replace('/*', '')
    );
  };

  let tableData: IPurchaseOrderTableDTO[] = [];
  if (data?.purchase_orders) {
    tableData = formatPurchaseOrdersTableData(
      data.purchase_orders
        .map((purchaseOrder: IPurchaseOrder[]) => {
          return purchaseOrder;
        })
        .flat(),
      currencySymbol
    );
  }

  const { steps } = useRunTour(
    GuidePages.SINGLE_SALES_ORDER_PURCHASE_ORDERS,
    salesOrderId,
    isFetched
  );

  return (
    <Container className="single-sales-order-step-10">
      {tableData.length ? (
        <Table
          isLoading={isLoading}
          page={page}
          perPage={SINGLE_SALES_ORDER_PURCHASE_ORDERS_PER_PAGE}
          total={data?.total}
          tableData={tableData}
          tableConfig={tableConfig}
          hasExtraAction={true}
          loadPage={(newPage) => setPage(newPage)}
          onViewButtonClick={handleViewButtonClick}
          overflowX
        />
      ) : (
        <>
          {isLoading && (
            <LoaderWrapper>
              <Spinner size={50} color={COLORS.PRIMARY} />
            </LoaderWrapper>
          )}
          {!isLoading && (
            <NoContentLabel>
              {t('There are currently no Purchase orders on this Sales order')}
            </NoContentLabel>
          )}
        </>
      )}
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleSalesOrderPurchaseOrdersPage;
