import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  Description,
  ButtonWrapper,
} from './ThreeButtonsModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Level } from 'components/Modal/type';

interface IThreeButtonsModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  onFirstButton: () => void;
  firstButtonLabel: string;
  firstButtonDisabled?: boolean;
  onSecondButton: () => void;
  secondButtonLabel: string;
  secondButtonDisabled?: boolean;
  onThirdButton?: () => void;
  thirdButtonlabel: string;
  thirdButtonDisabled?: boolean;
  description?: string;
  title: string;
  level?: Level;
  pwId?: string;
}

export const ThreeButtonsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  onFirstButton,
  firstButtonLabel,
  firstButtonDisabled,
  onSecondButton,
  secondButtonLabel,
  secondButtonDisabled,
  onThirdButton,
  thirdButtonlabel,
  thirdButtonDisabled,
  description,
  title,
  level = 'FIRST',
  pwId,
}: IThreeButtonsModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', maxWidth: '700rem', position: 'fixed' }}
      level={level}
    >
      <TopContainer>
        <CloseContainer>
          <Close
            data-testid={pwId}
            onClick={() => {
              onCancel && onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title data-testid={`${pwId}-title`}>{title}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description data-testid={`${pwId}-description`}>
            {description}
          </Description>
        </TitleContainer>
      </TopContainer>
      <ButtonContainer>
        <Margin>
          <ButtonWrapper>
            <Button
              pwId={pwId}
              onClick={() => {
                onFirstButton && onFirstButton();
              }}
              secondary
              width="100%"
              label={firstButtonLabel}
              disabled={firstButtonDisabled}
            />
          </ButtonWrapper>
        </Margin>
        <Margin>
          <ButtonWrapper>
            <Button
              primary
              width="100%"
              label={secondButtonLabel}
              onClick={() => {
                onSecondButton && onSecondButton();
              }}
              disabled={secondButtonDisabled}
            />
          </ButtonWrapper>
        </Margin>
        <ButtonWrapper>
          <Button
            pwId={pwId}
            primary
            width="100%"
            label={thirdButtonlabel}
            onClick={() => {
              onThirdButton && onThirdButton();
            }}
            disabled={thirdButtonDisabled}
          />
        </ButtonWrapper>
      </ButtonContainer>
    </Modal>
  );
};
