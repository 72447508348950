import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Label,
  RowSpaceBetween,
  RowSpaceBetweenAlignCenter,
  TimePickerColumnContainer,
  Title,
  TitleContainer,
} from './EditAppointmentReminderSettingsModal.styled';
import { Input } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { editEmailCompanySettingsSchema } from './validation';
import { toast } from 'react-toastify';
import { transformEmptyValues } from 'utils/transformEmptyValues';
import { useEditCompanySettings } from '../hooks';
import { IEditCompanySettingsDTO } from 'types/Admin.types';
import moment, { Moment } from 'moment';
import TimePicker from 'components/TimePicker/TimePicker';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IEditAppointmentReminderSettingsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  settingsData: any;
  companyId: string;
}
export const EditAppointmentReminderSettingsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  settingsData,
  companyId,
}: IEditAppointmentReminderSettingsModalProps) => {
  const { t } = useTranslation();
  const { isPhone, isSmallPhone } = useBreakpointFlag();

  const {
    mutate: editCompanySettings,
    isSuccess,
    isLoading,
  } = useEditCompanySettings(Number(companyId));

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Company settings updated'));
      onCancel();
    }
  }, [isSuccess]);

  const handleEditCompanySettings = (values: any) => {
    const editCompanySettingsDTO: IEditCompanySettingsDTO = {
      appointment_days_before_reminder: values.appointment_days_before_reminder,
      appointment_reminder_hour: values.appointment_reminder_hour,
      appointment_lower_interval: values.appointment_lower_interval,
      appointment_upper_interval: values.appointment_upper_interval,
    };
    editCompanySettings(editCompanySettingsDTO);
  };

  const getInitialValues = () => {
    return {
      appointment_days_before_reminder:
        settingsData?.appointment_days_before_reminder,
      appointment_reminder_hour: settingsData?.appointment_reminder_hour,
      appointment_lower_interval: settingsData?.appointment_lower_interval,
      appointment_upper_interval: settingsData?.appointment_upper_interval,
      is_checked:
        settingsData?.appointment_days_before_reminder &&
        settingsData?.appointment_reminder_hour,
    };
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        overflow: 'visible',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Company appointment reminder settings')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={getInitialValues()}
          validationSchema={editEmailCompanySettingsSchema}
        >
          {({ values, setFieldValue, handleBlur, errors, isValid }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };

            return (
              <Container>
                <RowSpaceBetween>
                  <RowSpaceBetweenAlignCenter>
                    <Checkbox
                      isChecked={values.is_checked}
                      onChange={() => {
                        if (values.is_checked) {
                          setFieldValue('appointment_days_before_reminder', '');
                          setFieldValue('appointment_reminder_hour', '');
                          setFieldValue('is_checked', false);
                        } else setFieldValue('is_checked', true);
                      }}
                    />
                    <H4>{t('Automatic appointment reminder')}</H4>
                  </RowSpaceBetweenAlignCenter>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>
                        {t('Appointment days before reminder').toUpperCase()}
                      </H4>
                    </Label>
                    <Input
                      disabled={!values.is_checked}
                      errorMessage={
                        errors['appointment_days_before_reminder'] &&
                        values.is_checked
                          ? (errors[
                              'appointment_days_before_reminder'
                            ] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(
                          e,
                          'appointment_days_before_reminder'
                        );
                      }}
                      height={'41rem'}
                      name="appointment_days_before_reminder"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <TimePickerColumnContainer>
                    <Label>
                      <H4>{t('Appointment reminder hour').toUpperCase()}</H4>
                    </Label>
                    <TimePicker
                      disabled={!values.is_checked}
                      width={isPhone ? '100%' : '300rem'}
                      time={moment(
                        values?.appointment_reminder_hour,
                        'HH:mm:ss'
                      )}
                      setTime={(newValue: Moment) => {
                        setFieldValue(
                          'appointment_reminder_hour',
                          newValue?.format('HH:mm:ss')
                        );
                      }}
                    />
                  </TimePickerColumnContainer>
                </RowSpaceBetween>

                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Appointment lower interval').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['appointment_lower_interval']
                          ? (errors['appointment_lower_interval'] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'appointment_lower_interval');
                      }}
                      height={'41rem'}
                      name="appointment_lower_interval"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Appointment upper interval').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['appointment_upper_interval']
                          ? (errors['appointment_upper_interval'] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'appointment_upper_interval');
                      }}
                      height={'41rem'}
                      name="appointment_upper_interval"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={
                      isLoading ||
                      !isValid ||
                      (values.is_checked &&
                        (!values.appointment_days_before_reminder ||
                          !values.appointment_reminder_hour))
                    }
                    onClick={() => {
                      if (isValid)
                        handleEditCompanySettings(transformEmptyValues(values));
                    }}
                    primary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
