import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ActionsIcons,
  ActionsWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  LoaderWrapper,
  MollieCustomerSpan,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import {
  ChatCircle,
  DownloadSimple,
  EnvelopeSimple,
  WarningCircle,
} from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ReactComponent as MailFailed } from 'assets/icons/mail-failed.svg';
import { ReactComponent as MailPending } from 'assets/icons/mail-pending.svg';
import Spinner from 'components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { EmailStatuses } from 'types/Email.types';
import { SingleSalesInvoiceRoutes } from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';
import { RoutesConfig } from 'navigation/routes';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';

interface IActionsMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  canEditSupplier: boolean;
  canCreateItem: boolean;
  canCreatePurchaseOrder: boolean;
  setIsCommentModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsActivateModalOpen: Dispatch<SetStateAction<boolean>>;
  supplierComments: any;
  id: any;
}

const ActionsMobileModal = ({
  isOpen,
  setIsOpen,
  data,
  canEditSupplier,
  canCreateItem,
  canCreatePurchaseOrder,
  setIsCommentModalOpen,
  setIsDeleteModalOpen,
  setIsActivateModalOpen,
  supplierComments,
  id,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{data?.supplier?.company_name}</Title>
        </TitleContainer>
        <ActionsWrapper>
          <ActionsIcons>
            <Icon
              svg={ChatCircle}
              color={COLORS.PRIMARY}
              size={25}
              onClick={() => setIsCommentModalOpen(true)}
            />
            {!!supplierComments?.total && (
              <Icon svg={WarningCircle} size={25} color={COLORS.PRIMARY} />
            )}
          </ActionsIcons>
        </ActionsWrapper>
        <ButtonsWrapper>
          {!data?.supplier?.deleted_at ? (
            <Button
              width={'100%'}
              onClick={() => setIsDeleteModalOpen(true)}
              label={t('Delete Supplier')}
              disabled={!canEditSupplier}
              redOutline
            />
          ) : (
            <Button
              width={'100%'}
              onClick={() => setIsActivateModalOpen(true)}
              secondary
              label={t('Activate Supplier')}
              disabled={!canEditSupplier}
            />
          )}
          <Button
            width={'100%'}
            onClick={() =>
              navigate(RoutesConfig.NewStockItem.fullPath, {
                state: { prepopulatePurchaseOrderData: data },
              })
            }
            label={t('Create stock item')}
            secondary
            disabled={!canCreateItem}
          />
          <Button
            data-testid="create-purchase-order-button"
            width={'100%'}
            onClick={() =>
              navigate(
                `${SingleSupplierRoutes.SingleSupplierNewPurchaseOrder.fullPath}`.replace(
                  ':id',
                  id || ''
                ),
                {
                  state: {
                    prepopulatePurchaseOrderData: {
                      supplierData: data,
                    },
                  },
                }
              )
            }
            label={t('Create purchase order')}
            primary
            disabled={!canCreatePurchaseOrder}
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
