import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isActive?: boolean;
}

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
  padding-left: 15rem;
  width: 100%;
  max-width: 90%;
  overflow-x: auto;
  padding-right: 30rem;
  padding-top: 30rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  ${respondTo.smallTablet`
    max-width: 100%;
    padding-top: 10rem;
    padding-right: 0;
  `}
`;

export const Tab = styled.div`
  border: 1px solid ${COLORS.GREY_500};
  border-top-left-radius: 10rem;
  border-top-right-radius: 10rem;
  border-bottom: none;
  padding: 5rem 10rem;
  background-color: ${COLORS.WHITE};
  min-width: 75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: 0.3s ease;

  &:hover {
    ${(props: StyledProps) =>
      props.isActive
        ? css`
            background-color: ${COLORS.PRIMARY};
          `
        : css`
            background-color: ${COLORS.BACKGROUND_COLOR};
          `}
  }

  ${(props: StyledProps) =>
    props.isActive &&
    css`
      background-color: ${COLORS.PRIMARY};

      ${TabText} {
        color: ${COLORS.STRICT_WHITE};
      }
    `}
`;

export const TabText = styled.span`
  color: ${COLORS.GREY_700};
  font-size: 14rem;
  display: inline-block;
  white-space: nowrap;
`;
