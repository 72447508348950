import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30rem;

  ${respondTo.smallTablet`
    justify-content: flex-start;
  `}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;
