import { ISalesOrderLine } from 'types/SalesOrders.types';
import { StockItemTypeIdEnum } from 'types/StockItem.types';

export const getIsLineReceived = (
  line: ISalesOrderLine,
  t: (str: string) => string
) => {
  if (line.stock_item) {
    if (line.stock_item?.type?.id == StockItemTypeIdEnum.SERVICE) {
      // Service stock item
      return t('Received');
    } else {
      // Normal stock item
      if (line.take_from_stock) {
        // Normal stock item - take from stock
        return Number(line.stock_item.in_stock) >= Number(line.quantity)
          ? t('Received')
          : t('Not received');
      } else {
        // Normal stock item - dont take from stock
        return t('Not received');
      }
    }
  }
  return line.received_purchase_amount >= Number(line.quantity)
    ? t('Received')
    : t('Not received');
};
