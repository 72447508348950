import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { NavLink } from 'react-router-dom';
import { Badge } from '@mui/material';

interface StyledProps {
  $isActive: boolean;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;
  background-color: ${COLORS.WHITE};
  width: 100%;
  max-width: 100%;
  height: 65rem;
  padding: 10rem 15rem;
  padding-left: 0;
  position: fixed;
  transition: all 0.3s;
  left: 0;
  bottom: 0;
  z-index: 100;
  box-shadow: 0px -1px 5px 0px ${COLORS.GREY_300};
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 10rem;
  transform: translateY(-50%);
`;

export const InnerWrapper = styled.div`
  display: flex;
  text-align: center;
  width: 100%;
  max-width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const ItemText = styled.span`
  text-align: center;
  color: ${COLORS.BLACK};
  font-size: 16rem;
  white-space: nowrap;
`;

export const ItemContainer = styled(NavLink)`
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s;
  gap: 2rem;
  min-width: 110rem;
  width: auto;
  margin: 0 10rem;

  &:active,
  &.active {
    ${ItemText} {
      color: ${COLORS.PRIMARY};
    }
  }

  ${(props: StyledProps) =>
    props.$isActive &&
    css`
      color: ${COLORS.PRIMARY};
    `}
`;

export const StyledBadge = styled(Badge)`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; // Remove tap highlight gray rectangle
  bottom: 15rem;
  left: 20rem;
`;
