import styled from 'styled-components';
import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  margin: 0 30rem;
  padding: 30rem 0;

  ${respondTo.bigPhone`
    margin: 0 20rem;
    padding: 20rem 0;
  `}
`;

export const Headline = styled.label`
  font-size: 18rem;
  font-weight: 600;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
  line-height: 27rem;
`;

export const Description = styled.p`
  font-size: 16rem;
  font-weight: 400;
  line-height: 24rem;
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
`;
