import Placeholder from 'components/Input/Placeholder/Placeholder';
import { FieldProps } from 'formik';
import { useState } from 'react';
import {
  ErrorMessage,
  ErrorMessageWrapper,
  RelativeDiv,
  SelectWrapper,
  StyledReactSelect,
} from './Select.styled';
import CustomMultiValueRemove from './SelectComponents/CustomMultiValueRemove/CustomMultiValueRemove';
import { SelectComponents } from 'react-select/dist/declarations/src/components';
import { GroupBase } from 'react-select';
import CustomDropdownIndicator from './SelectComponents/CustomDropdownIndicator/CustomDropdownIndicator';
import CustomClearIndicator from './SelectComponents/CustomClearIndicator/CustomClearIndicator';
import { useTranslation } from 'react-i18next';

interface Option {
  label: string;
  value: string;
}

interface CustomSelectProps extends FieldProps {
  options: Array<Option>;
  isMulti: boolean;
  className?: string;
  placeholder?: string;
  selectedValue?: string;
  defaultValue?: Option | undefined;
  onSelect?: (option: any) => void;
  onInputChange?: (value: string) => void;
  menuIsOpen?: boolean;
  errorMessage: string;
  disabled: boolean;
  onMenuScrollToBottom?: () => void;
  components?:
    | Partial<SelectComponents<unknown, boolean, GroupBase<unknown>>>
    | undefined;
  pwId?: string;
  isLoading?: boolean;
  translate?: boolean;
  height?: string;
  menuHeight?: string;
  menuPlacement?: string;
  isClearable?: boolean;
  isSearchable?: boolean;
}

export const CustomSelect = ({
  className,
  height = '40rem',
  menuHeight = '300rem',
  placeholder,
  field,
  form,
  menuIsOpen,
  options,
  isMulti,
  selectedValue,
  defaultValue,
  onSelect,
  onInputChange,
  errorMessage,
  disabled,
  onMenuScrollToBottom,
  components,
  pwId,
  isLoading,
  translate = true,
  menuPlacement = 'auto',
  isClearable = false,
  isSearchable = true,
}: CustomSelectProps) => {
  const [isInputEmpty, setIsInputEmpty] = useState<boolean>(true);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);

  const onChange = (option: Option | Option[]) => {
    setIsInputEmpty(!option);
    onSelect && onSelect(option);
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as Option[])?.map((item: Option) => item.value)
        : (option as Option)?.value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option: Option) =>
              field && field?.value?.indexOf(option?.value) >= 0
          )
        : options.find(
            (option: Option) =>
              JSON.stringify(option.value) === JSON.stringify(field.value) || // If field.value is not primitive
              option.value === selectedValue?.toString()
          );
    } else {
      return isMulti ? [] : ('' as string);
    }
  };

  const { t } = useTranslation();

  const formatOptionLabel = (
    { label, color }: any,
    { context }: { context: 'menu' | 'value' }
  ) => {
    const optionStyle = {
      color: color || 'inherit',
    };
    return (
      <div
        data-testid={`${pwId}-${
          context === 'value' ? 'selected-' : ''
        }option-${label}`}
        style={{ display: 'flex', ...optionStyle }}
      >
        {translate ? t(label) : label}
      </div>
    );
  };

  return (
    <SelectWrapper className={className}>
      <RelativeDiv data-testid={pwId}>
        <StyledReactSelect
          height={height}
          formatOptionLabel={formatOptionLabel}
          components={{
            MultiValueRemove: (props: any) => (
              <CustomMultiValueRemove
                {...props}
                pwId={`${pwId}-multi-value-remove`}
              />
            ),
            DropdownIndicator: (props: any) => (
              <CustomDropdownIndicator
                isLoading={isLoading || isSearchLoading}
                {...props}
              />
            ),
            ClearIndicator: (props: any) => <CustomClearIndicator {...props} />,
            IndicatorSeparator: null,
            ...components,
          }}
          menuPlacement={menuPlacement}
          defaultValue={defaultValue ?? getValue()}
          name={field.name}
          isSearchable={isSearchable}
          value={getValue()}
          onChange={onChange}
          classNamePrefix="select"
          placeholder="&nbsp;"
          options={options}
          isMulti={isMulti}
          isClearable={isClearable || isMulti}
          isDisabled={disabled}
          isError={errorMessage !== '' && typeof errorMessage === 'string'}
          onInputChange={(value: any) => {
            setIsSearchLoading(true);
            onInputChange && onInputChange(value);
            if (value) {
              setIsInputEmpty(false);
            } else if (!field.value) {
              setIsInputEmpty(true);
            }
            setIsSearchLoading(false);
          }}
          onMenuScrollToBottom={onMenuScrollToBottom}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base: any) => ({
              ...base,
              zIndex: 9999,
            }),
            menuList: (base: any) => ({
              ...base,
              maxHeight: menuHeight || '300rem',
            }),
          }}
        />
        <Placeholder
          isTranslatedToTop={!isInputEmpty || !!getValue() || !!defaultValue}
          isFormikPlaceholder={true}
          isSelectPlaceholder={true}
          placeholder={placeholder}
        />
      </RelativeDiv>
      <ErrorMessageWrapper>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </ErrorMessageWrapper>
    </SelectWrapper>
  );
};

export default CustomSelect;
