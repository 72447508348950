import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Field } from 'formik';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, gapSm, paddingLg, paddingXs } from 'assets/styled';

interface StyledProps {
  search: boolean;
  height: string;
  wrapperStyles?: CSSProperties;
  secure: boolean;
  $hasIcon?: boolean;
  maxWidth?: string;
  isLoading?: boolean;
}

interface IInputLabel {
  type: string;
  errormessage: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  ${({ wrapperStyles }: StyledProps) => wrapperStyles}
`;

export const InputLabel = styled.label`
  margin-bottom: 10rem;
  align-self: flex-start;
  font-weight: 700;
  font-size: 16rem;
  line-height: 16rem;
  color: ${COLORS.BLACK_200};

  ${({ type }: IInputLabel) =>
    type === 'file' &&
    css`
      margin-bottom: 0;
      display: flex;
      align-items: center;
      border: 1rem solid ${COLORS.GREY_300};
      background-color: ${COLORS.GREY_100};
      height: 100%;
      padding: 10rem;
      cursor: pointer;
    `}
`;

export const StyledInput = styled(Field)`
  height: ${(props: StyledProps) => props.height};
  border: none;
  border: 1rem solid ${COLORS.GREY_1000};
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  flex-grow: 0;
  padding-left: 15rem;
  outline: none !important;
  transition: all 0.3s;
  color: ${COLORS.BLACK};
  padding-right: ${(props: StyledProps) => (props.$hasIcon ? '50rem' : '5rem')};
  max-width: ${(props: StyledProps) => (props.maxWidth ? props.maxWidth : '')};

  ::placeholder {
    font-size: 14rem;
  }

  ${({ type }: IInputLabel) => {
    if (type !== 'file') return;
    return css`
      display: none;
    `;
  }}

  :hover {
    border-color: ${COLORS.BLACK};
    box-shadow: 0 0 0 0.5rem ${COLORS.BLACK};
  }

  :focus {
    border-color: ${COLORS.PRIMARY};
    box-shadow: 0 0 0 0.5rem ${COLORS.PRIMARY};
  }

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}
  ${({ errormessage }: IInputLabel) =>
    errormessage &&
    css`
      border: 1rem solid ${COLORS.RED_100};
    `}

  &:disabled {
    background-color: ${COLORS.GREY_200};
    color: ${COLORS.GREY_1000};
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
      border: 1rem solid ${COLORS.GREY_1000};
    }
  }

  ${({ secure }: StyledProps) =>
    secure &&
    css`
      text-security: disc;
      -webkit-text-security: disc;
    `}
  ${({ isLoading }: StyledProps) =>
    isLoading &&
    css`
      padding-right: ${paddingLg};
    `}
`;

export const StyledTextArea = styled(Field)`
  ${(props: StyledProps) =>
    props.height &&
    css`
      height: ${(props: StyledProps) => props.height};
    `}
  font-family: inherit;
  border: 1rem solid ${COLORS.GREY_1000};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  flex-grow: 0;
  padding: ${paddingXs};
  gap: ${gapSm};
  transition: all 0.3s;
  resize: none;
  color: ${COLORS.BLACK};

  ${(props: StyledProps) =>
    props.search &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.BLACK};
      :focus {
        border: 1rem solid ${COLORS.BLACK} !important;
      }
    `}
  ${({ errormessage }: IInputLabel) =>
    errormessage && `border: 1rem solid ${COLORS.RED_100};`}
    
  &:disabled {
    background-color: ${COLORS.GREY_200};
    color: ${COLORS.GREY_1000};
    cursor: not-allowed;
  }

  :hover {
    border-color: ${COLORS.BLACK};
    box-shadow: 0 0 0 0.5rem ${COLORS.BLACK};
  }

  :focus {
    border-color: ${COLORS.PRIMARY};
    box-shadow: 0 0 0 0.5rem ${COLORS.PRIMARY};
  }

  ${({ secure }: StyledProps) =>
    secure &&
    css`
      text-security: disc;
      -webkit-text-security: disc;
    `}
`;

export const ErrorContainer = styled.div`
  min-height: 16rem;
`;

export const ErrorMessage = styled.div`
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
  padding-top: 3rem;
`;

export const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15rem;
  height: 15rem;
  position: absolute;
  top: 24%;
  right: 10rem;
`;

export const SymbolWrap = styled.div`
  position: absolute;
  top: 10%;
  right: 10rem;
`;
export const SymbolSpan = styled.span`
  font-size: 20rem;
  color: ${COLORS.GREY_1000};
`;

export const LoaderWrap = styled.div`
  position: absolute;
  top: 20%;
  right: 10rem;
`;
