import { borderRadiusSm, COLORS, H4, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  marginTop: string;
  top: string;
}

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  max-width: 770rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    max-width: 100%;
    padding: 20rem;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    margin-bottom: 10rem;
    align-items: center;
  `}
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};

  ${respondTo.smallPhone`
    justify-content: space-evenly;
  `}
`;

export const Margin = styled.div`
  margin-left: ${marginMd};

  ${respondTo.smallPhone`
    margin-left: 0;
  `}
`;

export const FieldWrapper = styled.div`
  width: 50%;
  padding-right: 15rem;

  ${respondTo.bigPhone`
    width: 100%;
    padding-right: 0;
  `}
`;

export const RowMarginTop = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}

  ${respondTo.bigPhone`
      flex-direction: column;
      gap: 20rem;
      margin-top: 20rem;
    `}
`;

export const RowSingleCell = styled.div`
  display: flex;
  margin-top: ${marginMd};
  padding-right: 30rem;
`;

export const CustomerDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProductsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  text-transform: uppercase;
  margin-bottom: ${marginSm};
`;

export const ProductTitle = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  margin-bottom: ${marginSm};
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
  margin-bottom: ${marginSm};
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
`;

export const SingleProductWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${marginSm};
`;

export const ProductSupplierLabel = styled(H4)``;

export const ProductFlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30rem;
`;

export const GreenLine = styled.div`
  margin-top: ${marginMd};
  border-bottom: 1rem solid ${COLORS.LOGYX_LIGHT_GREEN};
  display: flex;
  width: 100%;
`;

export const TotalPurchasePriceWrapper = styled.div`
  position: relative;
  width: 50%;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const EyeWrapper = styled.div`
  position: absolute;
  cursor: pointer;
  user-select: none;
  position: absolute;
  right: 15rem;
  width: 20rem;
  ${(props: StyledProps) =>
    props.top &&
    css`
      top: ${(props: StyledProps) => props.top};
    `}
`;

export const ProductFormDTOFieldWrapper = styled.div`
  position: relative;
  max-width: 48%;
  width: 100%;

  ${respondTo.bigPhone`
    max-width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  width: 48%;
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 10rem;
  flex-wrap: wrap;
  margin-bottom: ${marginMd};
`;
