import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  AdvancedFilterWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  SelectInputWrapper,
  Title,
  TitleContainer,
} from './FilterMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import Button from 'components/Button/Button';
import { Select } from 'components/Select/Select';

interface IFilterMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  filtersData: any;
  statusOptions: Option[];
  setIsAdvancedFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  advancedFilters: string;
  setSelectedStatusOption: Dispatch<SetStateAction<Option>>;
}

const FilterMobileModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  filtersData,
  statusOptions,
  setIsAdvancedFilterModalOpen,
  advancedFilters,
  setSelectedStatusOption,
}: IFilterMobileModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Filter')}</Title>
        </TitleContainer>

        <SelectInputWrapper>
          <Select
            defaultValue={
              filtersData.selectedStatusOption
                ? filtersData.selectedStatusOption
                : statusOptions[0]
            }
            name="status"
            placeholder={t('Status')}
            isMulti={false}
            isDisabled={false}
            isSearchable={false}
            onChange={(e: Option) => setSelectedStatusOption(e)}
            options={statusOptions}
          />
        </SelectInputWrapper>
        <AdvancedFilterWrapper>
          <Button
            onClick={() => setIsAdvancedFilterModalOpen(true)}
            label={t('Advanced filter')}
            width="200rem"
            height="40rem"
            secondary={!advancedFilters}
            primary={!!advancedFilters}
          />
        </AdvancedFilterWrapper>
        <ButtonsWrapper>
          <Button
            onClick={() => setIsOpen(false)}
            label={t('Finish')}
            width="100%"
            height="40rem"
            primary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default FilterMobileModal;
