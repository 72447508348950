import { marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  margin-top: 50rem;
  overflow: scroll;
  height: calc(100vh - 271rem);
  align-items: center;
  padding-bottom: 50rem;

  ${respondTo.smallTablet`
    margin-top: 20rem;
    padding: 0 20rem;
  `}
`;

export const NoContentLabel = styled.label`
  margin-top: ${marginMd};
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const RightAlign = styled.div`
  width: 850rem;
  display: flex;
  justify-content: flex-end;

  ${respondTo.midTablet`
    width: 100%;
    max-width: 100%;
  `}

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
    gap: 20rem;
  `}
`;
