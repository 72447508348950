import React, { Dispatch, SetStateAction } from 'react';
import { Modal } from '../Modal';
import {
  CloseContainer,
  Container,
  LanguageImage,
  LanguageLabel,
  LanguageOption,
} from './LanguageModal.styled';
import i18n from 'providers/i18n/i18n';
import { changeLanguage } from 'utils/changeLanguage';
import { getSelectedLanguageIcon } from 'components/LanguageSwitcher/helpers';
import { Close } from 'components/Close/Close';

export interface ILanguageModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  lngs: any;
}

const LanguageModal = ({ isOpen, setIsOpen, lngs }: ILanguageModalProps) => {
  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <CloseContainer>
        <Close onClick={() => setIsOpen(false)} />
      </CloseContainer>
      <Container>
        {Object.keys(lngs).map((lng) => (
          <React.Fragment key={lng}>
            <LanguageOption
              onClick={() => {
                changeLanguage(lng as any);
                setIsOpen(false);
              }}
            >
              <LanguageImage alt="" src={getSelectedLanguageIcon(lng)} />
              <LanguageLabel isSelectedLanguage={i18n.resolvedLanguage === lng}>
                {lngs[lng].nativeName}
              </LanguageLabel>
            </LanguageOption>
          </React.Fragment>
        ))}
      </Container>
    </Modal>
  );
};

export default LanguageModal;
