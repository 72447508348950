import { COLORS, marginLg, marginMd, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1075rem;
  width: 100%;
  height: 100%;
  padding: ${paddingMd};
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  margin-top: ${marginLg};
  margin-bottom: ${marginLg};
  align-self: center;
  min-height: 250rem;

  ${respondTo.smallTablet`
    margin-top: 20rem;
    max-width: calc(100% - 30rem);
  `}
`;

export const NoContentLabel = styled.label`
  margin-bottom: ${marginMd};
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const AlignCenter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;
