import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ActionsIcons,
  ActionsWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  LoaderWrapper,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { ReactComponent as LogyxLogo } from 'assets/icons/logyxLogoSmall.svg';
import Icon from 'components/Icon/Icon';
import {
  ChatCircle,
  ChatCircleDots,
  DownloadSimple,
} from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Spinner from 'components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { SinglePurchaseOrderRoutes } from 'navigation/SinglePurchaseOrder/SinglePurchaseOrder.routes';
import { PurchaseOrderStatus } from '../constants';

interface IActionsMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  isLoadingExport: boolean;
  exportPurchaseOrder: any;
  infoData: any;
  setIsDirectOrderModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsPurchaseOrderConfirmationModalOpen: Dispatch<SetStateAction<boolean>>;
  canEditPurchaseOrder: boolean;
}

const ActionsMobileModal = ({
  isOpen,
  setIsOpen,
  data,
  isLoadingExport,
  exportPurchaseOrder,
  infoData,
  setIsDirectOrderModalOpen,
  setIsPurchaseOrderConfirmationModalOpen,
  canEditPurchaseOrder,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>
            {t('Purchase order')} {data?.number}
          </Title>
        </TitleContainer>
        <ActionsWrapper>
          <ActionsIcons>
            {isLoadingExport ? (
              <LoaderWrapper>
                <Spinner size={20} />
              </LoaderWrapper>
            ) : (
              <Icon
                data-testid="download-pdf"
                svg={DownloadSimple}
                size={25}
                color={COLORS.PRIMARY}
                onClick={() => exportPurchaseOrder(data?.id)}
              />
            )}

            <Icon
              data-testid="add-comment"
              size={25}
              color={COLORS.PRIMARY}
              svg={
                !infoData?.purchase_order_info?.comment_count
                  ? ChatCircle
                  : ChatCircleDots
              }
              onClick={() => {
                navigate(
                  SinglePurchaseOrderRoutes.SinglePurchaseOrderNewComment.path
                );
              }}
            />
          </ActionsIcons>
        </ActionsWrapper>

        <ButtonsWrapper>
          {data?.digital_order_enabled && (
            <Button
              onClick={() => setIsDirectOrderModalOpen(true)}
              label={t('Direct order')}
              width="100%"
              greenOutline
              icon={LogyxLogo}
              iconStyles={{ width: '18rem', height: '18rem' }}
              disabled={
                data?.logyx_connection_in_progress ||
                data?.status?.name !== PurchaseOrderStatus.NEW
              }
            />
          )}
          <Button
            onClick={() => setIsPurchaseOrderConfirmationModalOpen(true)}
            disabled={!data || !canEditPurchaseOrder}
            label={t('Order confirmation')}
            width="100%"
            secondary
          />
          <Button
            onClick={() => {
              navigate(
                SinglePurchaseOrderRoutes.SinglePurchaseOrderNewInvoice.path,
                {
                  state: { prepopulateInvoiceData: data },
                }
              );
              setIsOpen(false);
            }}
            disabled={!data || !canEditPurchaseOrder}
            label={t('Add invoice')}
            width="100%"
            secondary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
