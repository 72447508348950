import { borderRadiusSm, COLORS, marginLg, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: ${marginLg};
  margin-left: ${marginLg};
  margin-right: ${marginLg};

  ${respondTo.smallTablet`
    padding: 0 20rem;
    max-width: 100%;
    margin: 0;
  `}

  ${respondTo.bigPhone`
    padding: 0 15rem;
  `}
`;

export const HeaderWrapper = styled.div`
  display: flex;
  max-width: 750rem;
  width: 100%;

  ${respondTo.smallTablet`
    margin-top: 20rem;
  `}
`;

export const ContactDetailsContainer = styled.div`
  margin-top: ${marginMd};
  padding: 30rem;
  max-width: 750rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
`;

export const ContactDetailsLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  margin-bottom: ${marginMd};
`;
