import { queryClient } from 'index';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getQuotationByQuotationId,
  getQuotationFiles,
  getQuotationComments,
  newComment,
  uploadQuotationFile,
  deleteQuotationFile,
  createSalesOrder,
  getQuotationSalesOrder,
  changeStatus,
  exportQuotation,
  sendQuotationEmail,
  createQuotationAction,
  getQuotationActions,
  uploadQuotationSignature,
  getQuotationSignature,
  getQuotationInfoById,
  getQuotationLatestEmailStatus,
  getQuotationAttachmentsPreview,
  getQuotationActiveAppointmentInfo,
  cancelRecurringQuotation,
  getQuotationLatestAction,
} from 'services/Quotation/QuotationService';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useTranslation } from 'react-i18next';
import {
  ICreateQuotationActionData,
  QuotationTypeIdsEnum,
} from 'types/Quotations.types';
import { EmailStatuses } from 'types/Email.types';
import { invalidateFileQueries } from 'utils/hooks/useHandleDeleteFile';
import { QuotationStatuses } from './constants';
import { Option } from 'components/Select/type';
import { useGetQuotationStatuses } from 'utils/hooks/useGetQuotationStatuses';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';

export const useGetSingleQuotation = (quotationId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_QUOTATION, quotationId],
    queryFn: () => {
      return getQuotationByQuotationId(quotationId);
    },
    enabled: !!quotationId,
  });

export const useGetSingleQuotationSalesOrder = (quotationId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_QUOTATION_SALES_ORDER, quotationId],
    queryFn: () => {
      return getQuotationSalesOrder(quotationId);
    },
    enabled: !!quotationId,
  });

export const useChangeQuotationStatus = () =>
  useMutation(
    (params: { id: string; status_id: string }) =>
      changeStatus(params.id, params.status_id),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CHANGE_QUOTATION_STATUS,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_QUOTATION);
      },
      mutationKey: ReactMutationKeys.CHANGE_QUOTATION_STATUS,
    }
  );

export const useGetQuotationFiles = (perPage: number, quotationId: string) =>
  useInfiniteQuery({
    queryKey: [ReactQueryKeys.GET_QUOTATION_FILES, quotationId],
    queryFn: ({ pageParam = 1 }) =>
      getQuotationFiles(quotationId, pageParam, perPage),
    getNextPageParam: (lastPage) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      // On success
    },
  });

export const useGetQuotationComments = (
  perPage: number,
  quotationId: string,
  dateFrom: string,
  dateTo: string
) =>
  useInfiniteQuery({
    queryKey: [
      ReactQueryKeys.GET_QUOTATION_COMMENTS,
      quotationId,
      dateFrom,
      dateTo,
    ],
    queryFn: ({ pageParam = 1 }) =>
      getQuotationComments(quotationId, pageParam, perPage, dateFrom, dateTo),
    getNextPageParam: (lastPage: any) => {
      if (lastPage.per_page * lastPage.page >= lastPage.total) {
        // Return undefined here so hasNextPage equals to false
        return undefined;
      }
      return lastPage.page + 1;
    },
    onSuccess: (data) => {
      return data;
    },
  });

interface IUseNewQuotationCommentParams {
  headline: string;
  description: string;
  isInternal: boolean;
  id: string;
}
export const useNewQuotationComment = () =>
  useMutation(
    (params: IUseNewQuotationCommentParams) =>
      newComment(
        params.headline,
        params.description,
        params.isInternal,
        params.id
      ),
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_QUOTATION_COMMENT,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_QUOTATION_INFO);
        queryClient.invalidateQueries(ReactQueryKeys.GET_QUOTATION_COMMENTS);
      },
      mutationKey: ReactMutationKeys.ADD_QUOTATION_COMMENT,
    }
  );

interface IUseDeleteQuotationFile {
  quotationId: string;
  quotationFileId: string;
}
export const useDeleteQuotationFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseDeleteQuotationFile) =>
      deleteQuotationFile(params.quotationId, params.quotationFileId),
    {
      onSuccess: (data) => {
        toast.success(t('File successfully deleted'));
        invalidateFileQueries();
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_QUOTATION_FILE,
        });
      },
      mutationKey: ReactMutationKeys.DELETE_QUOTATION_FILE,
    }
  );
};

interface IUseUploadQuotationFileParams {
  files: any;
  quotationId: string;
}
export const useUploadQuotationFile = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUploadQuotationFileParams) => {
      const fileFormData = new FormData();
      if (params.files && params.files.length) {
        params.files.forEach((file: any) => {
          fileFormData.append(`files`, file);
        });
      }

      return uploadQuotationFile(params.quotationId, fileFormData);
    },
    {
      onSuccess: () => {
        toast.success(t('Upload successful'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_QUOTATION_FILE,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_QUOTATION_INFO);
        queryClient.invalidateQueries(ReactQueryKeys.GET_QUOTATION_FILES);
      },
      mutationKey: ReactMutationKeys.UPLOAD_QUOTATION_FILE,
    }
  );
};

interface IUseCreateSalesOrderParams {
  quotationId: string;
}
export const useCreateSalesOrder = () => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseCreateSalesOrderParams) => {
      return createSalesOrder(params.quotationId);
    },
    {
      onSuccess: (data) => {
        // Success
      },
      onError: (error: any) => {
        if (
          error?.response?.data?.errors?.[0]?.split(':')?.[1] ===
          ' Quotation already has Sales order associated to it'
        ) {
          toast.error(t('Quotation already has Sales order associated to it'));
        } else {
          toast.error(getToastErrorMessage(error));
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_QUOTATIONS);
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_QUOTATION);
        queryClient.invalidateQueries(ReactQueryKeys.GET_SALES_ORDERS);
      },
      mutationKey: ReactMutationKeys.CREATE_SALES_ORDER,
    }
  );
};

export const useExportQuotation = () =>
  useMutation((quotationId: string) => exportQuotation(quotationId), {
    onSuccess: () => {
      // Success
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.EXPORT_QUOTATION,
      });
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.EXPORT_QUOTATION,
  });

const useGetWaitingForResponseQuotationStatus = () => {
  const statusOptions: Option[] = useGetQuotationStatuses();
  return statusOptions?.find(
    (statusOption: Option) =>
      statusOption.name === QuotationStatuses.WAITING_FOR_RESPONSE
  );
};

interface ISendQuotationEmailDTO {
  note: string;
  files: any;
}

export const useSendQuotationEmail = (quotationId: string) => {
  const { t } = useTranslation();
  const waitingForResponseQuotationStatus =
    useGetWaitingForResponseQuotationStatus();

  return useMutation(
    (params: ISendQuotationEmailDTO) =>
      sendQuotationEmail(quotationId, params.note, params.files),
    {
      onSuccess: () => {
        toast.success(t('E-mail is being sent'), {
          autoClose: 7000,
          className: ReactMutationKeys.SEND_QUOTATION_EMAIL,
        });

        const quotationData: any = queryClient.getQueryData([
          ReactQueryKeys.GET_SINGLE_QUOTATION,
          quotationId.toString(),
        ]);

        if (quotationData?.sent_to_customer)
          handleEmailStatusFetching(quotationId);
        else handleEmailStatusFirstTimeFetching(quotationId);

        // If quotation is definitive, sending an email will set the quotation to WAITING_FOR_RESPONSE status on the backend
        // Refetch quotation to obtain new status will not work immediately because the status change is not instant, because the job is still in the celery queue
        if (
          quotationData.type_id === QuotationTypeIdsEnum.DEFINITIVE &&
          waitingForResponseQuotationStatus
        ) {
          queryClient.setQueryData(
            [ReactQueryKeys.GET_SINGLE_QUOTATION, quotationId.toString()],
            {
              ...quotationData,
              status: waitingForResponseQuotationStatus,
              status_id: waitingForResponseQuotationStatus?.id,
            }
          );
        }
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.SEND_QUOTATION_EMAIL,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.SEND_QUOTATION_EMAIL,
    }
  );
};

const handleEmailStatusFetching = (quotationId: string) => {
  let intervalCount = 0;
  const intervalId = setInterval(() => {
    if (intervalCount >= 4) {
      clearInterval(intervalId); // Stop further intervals
    }

    const statusData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_QUOTATION_EMAIL_STATUS,
      quotationId.toString(),
    ]);
    const quotationData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_QUOTATION,
      quotationId.toString(),
    ]);

    //Check if status has changed and stop further intervals
    if (
      statusData?.id !== quotationData?.last_email_id &&
      statusData?.status !== EmailStatuses.PENDING
    ) {
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_QUOTATION]);
      clearInterval(intervalId);
    } else
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_QUOTATION_EMAIL_STATUS,
      ]);

    intervalCount++;
  }, 7000);

  // Continue with the rest of the onSuccess logic
  queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_QUOTATION_HISTORY);
};

const handleEmailStatusFirstTimeFetching = (quotationId: string) => {
  let intervalCount = 0;
  const intervalId = setInterval(() => {
    if (intervalCount >= 4) {
      clearInterval(intervalId); // Stop further intervals
    }

    const statusData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_QUOTATION_EMAIL_STATUS,
      quotationId.toString(),
    ]);
    const quotationData: any = queryClient.getQueryData([
      ReactQueryKeys.GET_SINGLE_QUOTATION,
      quotationId.toString(),
    ]);

    //Check if status exists and stop further intervals
    if (
      quotationData?.last_email_id &&
      statusData?.status !== EmailStatuses.PENDING
    ) {
      clearInterval(intervalId); // Stop further intervals
    } else {
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_SINGLE_QUOTATION_EMAIL_STATUS,
      ]);
      queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_QUOTATION]);
    }

    intervalCount++;
  }, 7000);

  // Continue with the rest of the onSuccess logic
  queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_QUOTATION_HISTORY);
};

export const useCreateQuotationAction = (quotationId: string) =>
  useMutation(
    (createQuotationActionData: ICreateQuotationActionData) =>
      createQuotationAction(quotationId, createQuotationActionData),
    {
      onSuccess: () => {
        // Success
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CREATE_QUOTATION_ACTION,
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_QUOTATION_ACTIONS);
      },
      mutationKey: ReactMutationKeys.CREATE_QUOTATION_ACTION,
    }
  );

export const useGetQuotationActions = (quotationId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_QUOTATION_ACTIONS, quotationId],
    queryFn: () => {
      return getQuotationActions(quotationId);
    },
  });

interface IUseUploadQuotationSignatureParams {
  file: any;
  quotationId: string;
  full_name: string;
  date: string;
}

export const useUploadQuotationSignature = (quotationId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (params: IUseUploadQuotationSignatureParams) => {
      const fileFormData = new FormData();
      fileFormData.append('file', params.file);
      fileFormData.append('fullname', params.full_name);
      fileFormData.append('date', params.date);
      return uploadQuotationSignature(params.quotationId, fileFormData);
    },
    {
      onSuccess: (data) => {
        toast.success(t('Signature added'), {
          className: ReactMutationKeys.UPLOAD_QUOTATION_SIGNATURE,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_QUOTATION_SIGNATURE,
          quotationId,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_QUOTATION,
          quotationId,
        ]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPLOAD_QUOTATION_SIGNATURE,
        });
      },
      onSettled: () => {
        //Settled
      },
      mutationKey: ReactMutationKeys.UPLOAD_QUOTATION_SIGNATURE,
    }
  );
};

export const useGetSingleQuotationSignature = (quotationId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_QUOTATION_SIGNATURE, quotationId],
    queryFn: () => {
      return getQuotationSignature(quotationId);
    },
  });

export const useGetSingleQuotationInfo = (quotationId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_QUOTATION_INFO, quotationId],
    queryFn: () => {
      return getQuotationInfoById(quotationId);
    },
  });

export const useGetQuotationLatestEmailStatus = (quotationId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_QUOTATION_EMAIL_STATUS, quotationId],
    queryFn: () => {
      return getQuotationLatestEmailStatus(quotationId);
    },
  });

export const useGetQuotationAttachmentsPreview = (
  quotationId: string,
  type: AddEmailNoteModalType,
  entityType?: AddEmailNoteModalType
) => {
  const isEnabled =
    !!quotationId &&
    type === AddEmailNoteModalType.QUOTATION &&
    (entityType === AddEmailNoteModalType.QUOTATION ||
      entityType === undefined);

  return useQuery({
    queryKey: [ReactQueryKeys.GET_QUOTATION_ATTACHMENTS_PREVIEW, quotationId],
    queryFn: () => {
      return getQuotationAttachmentsPreview(quotationId);
    },
    enabled: isEnabled,
  });
};

export const useGetQuotationActiveAppointmentInfo = (quotationId: string) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_QUOTATION_ACTIVE_APPOINTMENT_INFO,
      quotationId,
    ],
    queryFn: () => {
      return getQuotationActiveAppointmentInfo(quotationId);
    },
  });

export const useCancelRecurringQuotation = () => {
  const { t } = useTranslation();
  return useMutation(
    (quotationId: string) => cancelRecurringQuotation(quotationId),
    {
      onSuccess: (data) => {
        toast.success(t('Recurring cancelled'));
        queryClient.invalidateQueries(ReactQueryKeys.GET_SINGLE_QUOTATION);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.CANCEL_RECURRING_QUOTATION,
        });
      },
      mutationKey: ReactMutationKeys.CANCEL_RECURRING_QUOTATION,
    }
  );
};

export const useGetQuotationLatestAction = (quotationId: string) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_QUOTATION_LATEST_ACTION, quotationId],
    queryFn: () => {
      return getQuotationLatestAction(quotationId);
    },
  });
