import { Container, Item, ItemText } from './MobileSubMenu.styled';
import { ISubMenuItem } from '../SubSideMenu/type';
import { useTranslation } from 'react-i18next';

export interface ISubMenuProps {
  subMenuItems: ISubMenuItem[];
  subMenuItemsInfo?: { [key: string]: string };
}

const MobileSubMenu = ({ subMenuItems, subMenuItemsInfo }: ISubMenuProps) => {
  const { t } = useTranslation();

  return (
    <Container>
      {subMenuItems.map((subMenu: ISubMenuItem, index) => {
        return (
          <Item to={subMenu.route} end={subMenu.route === ''} key={index}>
            <ItemText>
              {t(subMenu.text)}&nbsp;
              {subMenuItemsInfo?.[subMenu.text]
                ? `(${subMenuItemsInfo?.[subMenu.text]})`
                : null}
            </ItemText>
          </Item>
        );
      })}
    </Container>
  );
};

export default MobileSubMenu;
