import styled from 'styled-components';
import { COLORS, borderRadiusSm } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 690rem;
  min-height: 385rem;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};

  ${respondTo.bigPhone`
    max-width: 100%;
    padding: 20rem;
  `}
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10rem;

  ${respondTo.bigPhone`
    margin-bottom: 10rem;
    align-items: center;
  `}

  ${respondTo.smallPhone`
    flex-direction: column;
    margin-bottom: 20rem;
    gap: 10rem;
  `}
`;
export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  margin-top: 14rem; //30-16

  ${respondTo.bigPhone`
    flex-wrap: wrap;
    gap: 15rem;
  `}
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 50%;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const AlignRow = styled(Row)`
  justify-content: center;
`;
