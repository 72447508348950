import { borderRadiusSm, COLORS, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  align-self: center;
  margin-top: 50rem;
  padding: 30rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 960rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};

  ${respondTo.smallTablet`
    padding: 20rem;
    margin-top: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const Line = styled.div`
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
  border-bottom: 1rem solid ${COLORS.GREY_200};
  width: 100%;
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
`;

export const TitleWrapper = styled.div`
  max-width: 960rem;
  width: 100%;
  text-align: start;
  align-self: center;
`;

export const SelectDiv = styled.div`
  min-width: 83rem;
  height: 31rem;
  border-radius: ${borderRadiusSm};
  border: 1px solid ${COLORS.PRIMARY};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: ${COLORS.PRIMARY};
    font-weight: 400;
    transition: ease 100ms;
    color: ${COLORS.WHITE};
  }
`;

export const SelectLabel = styled.label`
  pointer-events: none;
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  cursor: pointer;
`;

export const ButtonsWrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 430rem;
  width: 100%;
  margin-top: 40rem;
  margin-bottom: ${marginMd};

  ${respondTo.smallTablet`
    gap: 20rem;
    justify-content: center;
  `}
`;
