import { Funnel, MagnifyingGlass } from '@phosphor-icons/react';
import {
  AdvancedFilterWrapper,
  ButtonWrapper,
  Container,
  CreateToDoButton,
  FlexAlignCenter,
  Row,
  TitleContainer,
} from './ToDoPage.styled';
import { COLORS, H3 } from 'assets/styled';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Table from 'components/Table/Table';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useGetToDoAdvancedFilters } from './advancedFilters';
import { tableConfig, TabsType, TODOS_PER_PAGE } from './constants';
import { IToDo, IToDoTableDTO } from 'types/ToDo.types';
import Tabs from './Tabs/Tabs';
import AddEditToDoModal from './AddEditToDoModal/AddEditToDoModal';
import ViewToDoModal from './ViewToDoModal/ViewToDoModal';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  useGetPrepopulateTodoId,
  useGetToDoPageFilters,
  useGetToDos,
  useGetToDoTypes,
  useManageAndSaveFilters,
  useUpdateToDo,
} from './hooks';
import { formatToDosTableData } from 'components/Table/tableDataFormatter';
import { Option } from 'components/Select/type';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import Icon from 'components/Icon/Icon';
import FilterMobileModal from './FilterMobileModal/FilterMobileModal';
import { IRootReducerState } from 'store/store';
import { toast } from 'utils/toast';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTodoId } from 'store/Common/actions/common';

const ToDoPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const [isAddEditToDoModalOpen, setIsAddEditToDoModalOpen] =
    useState<boolean>(false);
  const [isViewToDoModalOpen, setIsViewToDoModalOpen] =
    useState<boolean>(false);
  const [selectedToDoId, setSelectedToDoId] = useState<string>('');
  const [toDoForEditId, setToDoForEditId] = useState<string>('');
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<TabsType>(TabsType.ALL);
  const [selectedTab, setSelectedTab] = useState<boolean | undefined>(
    undefined
  );
  const forceUpdate = useSelector(
    (state: IRootReducerState) => state.commonInfo.forceUpdateTodoPage
  );
  const [isMobileFilterModalOpen, setIsMobileFilterModalOpen] =
    useState<boolean>(false);

  const { isSmallTablet } = useBreakpointFlag();

  const { todoPageFilters } = useGetToDoPageFilters();

  const activeTodoId = useSelector(
    (state: IRootReducerState) => state.commonInfo.activeTodoId
  );

  //Navigation from notification
  const todoId = useGetPrepopulateTodoId();
  useEffect(() => {
    if (todoId) {
      setSelectedToDoId(todoId);
      setIsViewToDoModalOpen(true);
    }
  }, []);
  const filtersData = useManageAndSaveFilters(todoPageFilters);

  const {
    page,
    setPage,
    searchBy,
    setSearchBy,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    advancedFilters,
    setAdvancedFilters,
  } = filtersData;

  const [relatedTypeId, setRelatedTypeId] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const { data, isLoading: isLoadingData } = useGetToDos(
    forceUpdate,
    page,
    sortBy,
    sortDirection,
    TODOS_PER_PAGE,
    debouncedSearchBy,
    advancedFilters,
    selectedTab
  );

  const { mutate: updateToDo } = useUpdateToDo();

  let tableData: IToDoTableDTO[] = [];
  if (data) {
    tableData = formatToDosTableData(
      data.todos
        .map((todo: IToDo[]) => {
          return todo;
        })
        .flat(),
      activeTodoId
    );
  }
  const { data: toDoTypesOptions } = useGetToDoTypes();
  const { filterTypes } = useGetToDoAdvancedFilters(toDoTypesOptions);

  const changeTab = (tab: TabsType) => {
    setPage(1);
    setActiveTab(tab);
    if (tab === TabsType.FINISHED) {
      setSelectedTab(true);
    } else if (tab === TabsType.OPEN) {
      setSelectedTab(false);
    } else {
      setSelectedTab(undefined);
    }
  };

  const handleViewButtonClick = (rowData: IToDoTableDTO) => {
    setRelatedTypeId(rowData?.relatedTypeId);
    setSelectedToDoId(rowData?.todoId?.toString());
    setIsViewToDoModalOpen(true);
  };

  const handleStartButtonClick = (rowData: IToDoTableDTO) => {
    if (activeTodoId)
      toast.error(
        t(
          'An active ToDo already exists. Please finish it before starting a new one.'
        )
      );
    else dispatch(setActiveTodoId(Number(rowData.todoId)));
  };

  const onCheckboxChange = (rowData: IToDoTableDTO) => {
    updateToDo(
      {
        id: rowData.todoId.toString(),
        updateToDoData: { finished: !rowData.finished },
      },
      {
        onSuccess: () => {
          if (
            activeTodoId &&
            activeTodoId === rowData.todoId &&
            !rowData.finished
          )
            dispatch(setActiveTodoId(null));
        },
      }
    );
  };

  const onSelectChange = (
    e: Option,
    rowData: IToDoTableDTO,
    rowDataKey: string
  ) => {
    let updateToDoData;
    if (rowDataKey === 'priority') updateToDoData = { priority_id: e.value };
    if (rowDataKey === 'assignedTo')
      updateToDoData = { assigned_to_user_id: e === null ? null : e.value };

    updateToDo({
      id: rowData.todoId.toString(),
      updateToDoData,
    });
  };

  return (
    <Container>
      <TitleContainer>
        <H3>{t('Overview')}</H3>
        <ButtonWrapper>
          <Button
            className="quotations-step-3"
            onClick={() => {
              setIsEditMode(false);
              setToDoForEditId('');
              setIsAddEditToDoModalOpen(true);
            }}
            label={t('Create To Do')}
            width="200rem"
            height="41rem"
            primary
          />
        </ButtonWrapper>
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={isSmallTablet ? '100%' : '400rem'}
            wrapperStyles={{ width: isSmallTablet ? '100%' : '' }}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />

          {isSmallTablet && (
            <Icon
              svg={Funnel}
              size={30}
              color={COLORS.PRIMARY}
              weight="regular"
              onClick={() => setIsMobileFilterModalOpen(true)}
            />
          )}
        </FlexAlignCenter>
        <AdvancedFilterWrapper>
          <Button
            onClick={() => setIsAdvancedFilterModalOpen(true)}
            label={t('Advanced filter')}
            width="145rem"
            height="31rem"
            secondary={!advancedFilters}
            primary={!!advancedFilters}
          />
        </AdvancedFilterWrapper>
      </Row>
      {isSmallTablet && (
        <CreateToDoButton>
          <Button
            className="quotations-step-3"
            onClick={() => {
              setIsEditMode(false);
              setToDoForEditId('');
              setIsAddEditToDoModalOpen(true);
            }}
            label={t('Create To Do')}
            width="100%"
            height="41rem"
            primary
          />
        </CreateToDoButton>
      )}
      <Tabs activeTab={activeTab} onSelectTab={changeTab} />
      <Table
        key={forceUpdate}
        page={page}
        isLoading={isLoadingData}
        perPage={TODOS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        onStartButtonClick={handleStartButtonClick}
        withoutMarginTop={true}
        onCheckboxChange={onCheckboxChange}
        onSelectChange={onSelectChange}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
        labelsRow
      />
      <AdvancedFilterModal
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
      />
      {isAddEditToDoModalOpen && (
        <AddEditToDoModal
          isOpen={isAddEditToDoModalOpen}
          setIsOpen={setIsAddEditToDoModalOpen}
          id={toDoForEditId}
          isEditMode={isEditMode}
          key={toDoForEditId}
          relatedTypeId={relatedTypeId}
          forceUpdate={forceUpdate}
        />
      )}
      {isViewToDoModalOpen && (
        <ViewToDoModal
          isOpen={isViewToDoModalOpen}
          setIsOpen={setIsViewToDoModalOpen}
          onEdit={(id: string) => {
            setToDoForEditId(id);
            setIsEditMode(true);
            setIsAddEditToDoModalOpen(true);
          }}
          id={selectedToDoId}
          key={selectedToDoId + 'key'}
          relatedTypeId={relatedTypeId}
          forceUpdate={forceUpdate}
        />
      )}

      {isSmallTablet && (
        <FilterMobileModal
          isOpen={isMobileFilterModalOpen}
          setIsOpen={setIsMobileFilterModalOpen}
          onCancel={() => setIsMobileFilterModalOpen(false)}
          setIsAdvancedFilterModalOpen={setIsAdvancedFilterModalOpen}
          advancedFilters={advancedFilters}
        />
      )}
    </Container>
  );
};

export default ToDoPage;
