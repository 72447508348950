import { menuItems, TODO_BUTTON_TEXT } from '../SideMenu/constants';
import {
  Container,
  InnerWrapper,
  Wrapper,
  ItemContainer,
  ItemText,
  ArrowWrapper,
  StyledBadge,
} from './MobileMenu.styled';
import { useTranslation } from 'react-i18next';
import {
  useCheckPermissions,
  useGetActiveCurrentRoute,
  useGetUnfinishedTodosCount,
} from '../SideMenu/hooks';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { CaretRight } from '@phosphor-icons/react';

const MobileMenu = () => {
  const { t } = useTranslation();
  const filteredMenuItems = useCheckPermissions(menuItems);

  const currentRoute = useGetActiveCurrentRoute(filteredMenuItems);
  const { data: unfinishedTodosCount } = useGetUnfinishedTodosCount();

  return (
    <Container className="home-step-6">
      <ArrowWrapper>
        <Icon
          svg={CaretRight}
          color={COLORS.PRIMARY}
          size={25}
          weight="regular"
        />
      </ArrowWrapper>
      <Wrapper>
        <InnerWrapper>
          {filteredMenuItems.map((menuItem: any) => {
            const isActive =
              currentRoute && currentRoute.route === menuItem.route;
            return (
              <ItemContainer
                to={menuItem.route}
                key={menuItem.text}
                className={isActive ? 'active' : ''}
                $isActive={isActive}
              >
                <Icon
                  svg={menuItem.icon}
                  size={20}
                  color={isActive ? COLORS.PRIMARY : COLORS.BLACK}
                />
                {menuItem.text === TODO_BUTTON_TEXT && (
                  <StyledBadge
                    max={99}
                    badgeContent={unfinishedTodosCount}
                    color="primary"
                    vertical="bottom"
                    onClick={() => {
                      //
                    }}
                  />
                )}
                <ItemText>{t(menuItem.text)}</ItemText>
              </ItemContainer>
            );
          })}
        </InnerWrapper>
      </Wrapper>
    </Container>
  );
};

export default MobileMenu;
