import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  getEmailTemplateQuotationInfo,
  requestAppointmentFromEmail,
} from 'services/Quotation/QuotationService';
import { toast } from 'utils/toast';
import { IRequestAppointmentDTO } from './types';

export const useGetEmailTemplateQuotationInfo = (
  token: string,
  companyId: string
) =>
  useQuery({
    queryKey: ReactQueryKeys.GET_EMAIL_TEMPLATE_QUOTATION_INFO,
    queryFn: () => {
      return getEmailTemplateQuotationInfo(token, companyId);
    },
  });

export const useRequestAppointmentFromEmail = () => {
  const { t } = useTranslation();

  return useMutation(
    (requestAppoitmentData: IRequestAppointmentDTO) =>
      requestAppointmentFromEmail(
        requestAppoitmentData.token,
        requestAppoitmentData.companyId,
        requestAppoitmentData.optionalMessage
      ),
    {
      onSuccess: () => {
        toast.success(t('Appointment requested successfully'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.REQUEST_APPOINTMENT_FROM_EMAIL,
        });
      },
      mutationKey: ReactMutationKeys.REQUEST_APPOINTMENT_FROM_EMAIL,
    }
  );
};
