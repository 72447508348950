import { COLORS } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelectedLanguage: boolean;
}

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: 15rem;
  justify-content: center;
  align-items: center;
  padding-top: 50rem;
`;

export const LanguageImage = styled.img`
  margin: 0rem;
  width: 25rem;
  height: 25rem;
  cursor: pointer;
`;

export const LanguageOption = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  width: 100%;
  max-width: 100%;
  border-bottom: 1rem solid ${COLORS.GREY_200};
  padding: 10rem 0;

  &:last-child {
    border-bottom: none;
  }
`;

export const LanguageLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 20rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
  padding: 10rem 0;
  width: 100%;

  ${(props: StyledProps) =>
    props.isSelectedLanguage &&
    css`
      color: ${COLORS.PRIMARY};
    `};
`;
