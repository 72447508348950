import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import {
  CloseContainer,
  AppointmentFilterTypeLabel,
  AppointmentFilterTypesWrapper,
  AppointmentWrapper,
} from './NewAppointmentModal.styled';
import MeasurementTab from './MeasurementTab/MeasurementTab';
import { useState, Dispatch, SetStateAction } from 'react';
import InstallationTab from './InstallationTab/InstallationTab';
import GeneralTab from './GeneralTab/GeneralTab';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import ServiceTab from './ServiceTab/ServiceTab';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { AppointmentType } from 'types/Appointment.types';
import { Option } from 'components/Select/type';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { getArrivalTime } from './helpers';

export interface INewAppointmentModalProps {
  isCreatingAppointmentFromNavigationState: boolean;
  setIsCreatingAppointmentFromNavigationState: Dispatch<
    SetStateAction<boolean>
  >;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: AppointmentType;
  startDate?: string;
  endDate?: string;
  time_from?: string;
  time_to?: string;
  selectedUsersOptions: Option[];
  selectedWorkersOptions: Option[];
  selectedResourcesOptions: Option[];
}

const NewAppointmentModal = ({
  isCreatingAppointmentFromNavigationState,
  setIsCreatingAppointmentFromNavigationState,
  isOpen,
  setIsOpen,
  type,
  startDate,
  endDate,
  time_from,
  time_to,
  selectedUsersOptions,
  selectedWorkersOptions,
  selectedResourcesOptions,
}: INewAppointmentModalProps) => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const [selectedAppointmentType, setSelectedAppointmentType] =
    useState<AppointmentType>(type ? type : AppointmentType.MEASURING);

  // While the appointment is being created, we dont allow the user to close the appointment creation modal or change tabs,
  // so the isSuccess listeners inside the tabs are not dismounted and are able to execute
  const isMutatingCreateAppointment = useIsMutating(
    ReactMutationKeys.CREATE_APPOINTMENT
  );

  const {
    appointment_lower_interval: appointmentLowerInterval,
    appointment_upper_interval: appointmentUpperInterval,
  } = useSelector((state: IRootReducerState) => state.companyInfo.settings);

  const { arrivalFrom, arrivalTo } = getArrivalTime(
    time_from,
    appointmentLowerInterval,
    appointmentUpperInterval
  );

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        !isMutatingCreateAppointment && setIsOpen(boolean);
      }}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        overflow: 'visible',
        paddingRight: 0,
        maxWidth: '550rem',
      }}
    >
      <CloseContainer>
        <Close
          onClick={() => !isMutatingCreateAppointment && setIsOpen(false)}
        />
      </CloseContainer>
      <AppointmentWrapper>
        <AppointmentFilterTypesWrapper>
          <AppointmentFilterTypeLabel
            data-testid="measurement-label"
            disabled={isCreatingAppointmentFromNavigationState}
            onClick={() =>
              !isMutatingCreateAppointment &&
              !isCreatingAppointmentFromNavigationState &&
              setSelectedAppointmentType(AppointmentType.MEASURING)
            }
            isSelected={selectedAppointmentType === AppointmentType.MEASURING}
          >
            {t(capitalizeFirstLetter(AppointmentType.MEASURING.toLowerCase()))}
          </AppointmentFilterTypeLabel>
          <AppointmentFilterTypeLabel
            data-testid="installation-label"
            disabled={isCreatingAppointmentFromNavigationState}
            onClick={() =>
              !isMutatingCreateAppointment &&
              !isCreatingAppointmentFromNavigationState &&
              setSelectedAppointmentType(AppointmentType.INSTALLATION)
            }
            isSelected={
              selectedAppointmentType === AppointmentType.INSTALLATION
            }
          >
            {t(
              capitalizeFirstLetter(AppointmentType.INSTALLATION.toLowerCase())
            )}
          </AppointmentFilterTypeLabel>
          <AppointmentFilterTypeLabel
            data-testid="service-label"
            disabled={isCreatingAppointmentFromNavigationState}
            onClick={() =>
              !isMutatingCreateAppointment &&
              !isCreatingAppointmentFromNavigationState &&
              setSelectedAppointmentType(AppointmentType.SERVICE)
            }
            isSelected={selectedAppointmentType === AppointmentType.SERVICE}
          >
            {t(capitalizeFirstLetter(AppointmentType.SERVICE.toLowerCase()))}
          </AppointmentFilterTypeLabel>
          <AppointmentFilterTypeLabel
            data-testid="general-label"
            disabled={isCreatingAppointmentFromNavigationState}
            onClick={() =>
              !isMutatingCreateAppointment &&
              !isCreatingAppointmentFromNavigationState &&
              setSelectedAppointmentType(AppointmentType.GENERAL)
            }
            isSelected={selectedAppointmentType === AppointmentType.GENERAL}
          >
            {t(capitalizeFirstLetter(AppointmentType.GENERAL.toLowerCase()))}
          </AppointmentFilterTypeLabel>
        </AppointmentFilterTypesWrapper>

        {selectedAppointmentType === AppointmentType.MEASURING && (
          <MeasurementTab
            isNewAppointmentModalOpen={isOpen}
            isCreatingAppointmentFromNavigationState={
              isCreatingAppointmentFromNavigationState &&
              state?.type === AppointmentType.MEASURING
            }
            setIsCreatingAppointmentFromNavigationState={
              setIsCreatingAppointmentFromNavigationState
            }
            startDate={startDate}
            endDate={endDate}
            time_to={time_to}
            time_from={time_from}
            arrival_to={arrivalTo}
            arrival_from={arrivalFrom}
            closeAppointmentModal={() => setIsOpen(false)}
            selectedResourcesOptions={selectedResourcesOptions}
            selectedWorkersOptions={selectedWorkersOptions}
            selectedUsersOptions={selectedUsersOptions}
            appointmentUpperInterval={appointmentUpperInterval}
            appointmentLowerInterval={appointmentLowerInterval}
          />
        )}
        {selectedAppointmentType === AppointmentType.INSTALLATION && (
          <InstallationTab
            isNewAppointmentModalOpen={isOpen}
            isCreatingAppointmentFromNavigationState={
              isCreatingAppointmentFromNavigationState &&
              state?.type === AppointmentType.INSTALLATION
            }
            setIsCreatingAppointmentFromNavigationState={
              setIsCreatingAppointmentFromNavigationState
            }
            startDate={startDate}
            endDate={endDate}
            time_to={time_to}
            time_from={time_from}
            arrival_to={arrivalTo}
            arrival_from={arrivalFrom}
            closeAppointmentModal={() => setIsOpen(false)}
            selectedResourcesOptions={selectedResourcesOptions}
            selectedWorkersOptions={selectedWorkersOptions}
            selectedUsersOptions={selectedUsersOptions}
            appointmentUpperInterval={appointmentUpperInterval}
            appointmentLowerInterval={appointmentLowerInterval}
          />
        )}
        {selectedAppointmentType === AppointmentType.SERVICE && (
          <ServiceTab
            isNewAppointmentModalOpen={isOpen}
            isCreatingAppointmentFromNavigationState={
              isCreatingAppointmentFromNavigationState &&
              state?.type === AppointmentType.SERVICE
            }
            setIsCreatingAppointmentFromNavigationState={
              setIsCreatingAppointmentFromNavigationState
            }
            startDate={startDate}
            endDate={endDate}
            time_to={time_to}
            time_from={time_from}
            arrival_to={arrivalTo}
            arrival_from={arrivalFrom}
            closeAppointmentModal={() => setIsOpen(false)}
            selectedResourcesOptions={selectedResourcesOptions}
            selectedWorkersOptions={selectedWorkersOptions}
            selectedUsersOptions={selectedUsersOptions}
            appointmentUpperInterval={appointmentUpperInterval}
            appointmentLowerInterval={appointmentLowerInterval}
          />
        )}
        {selectedAppointmentType === AppointmentType.GENERAL && (
          <GeneralTab
            isNewAppointmentModalOpen={isOpen}
            isCreatingAppointmentFromNavigationState={
              isCreatingAppointmentFromNavigationState &&
              state?.type === AppointmentType.GENERAL
            }
            setIsCreatingAppointmentFromNavigationState={
              setIsCreatingAppointmentFromNavigationState
            }
            startDate={startDate}
            endDate={endDate}
            time_to={time_to}
            time_from={time_from}
            arrival_to={arrivalTo}
            arrival_from={arrivalFrom}
            closeAppointmentModal={() => setIsOpen(false)}
            selectedResourcesOptions={selectedResourcesOptions}
            selectedWorkersOptions={selectedWorkersOptions}
            selectedUsersOptions={selectedUsersOptions}
            appointmentUpperInterval={appointmentUpperInterval}
            appointmentLowerInterval={appointmentLowerInterval}
          />
        )}
      </AppointmentWrapper>
    </Modal>
  );
};

export default NewAppointmentModal;
