import {
  Container,
  FirstRow,
  NoContentLabel,
  NotificationsWrapper,
  SpinnerNoContentWrapper,
  Title,
} from './NotificationsPage.styled';
import { useTranslation } from 'react-i18next';
import { useGetNotifications, useMarkAllAsRead } from './hooks';
import { useCallback, useRef } from 'react';
import { NOTIFICATIONS_PER_PAGE } from './constants';
import { INotification } from 'types/Notification.types';
import NotificationCard from './NotificationCard/NotificationCard';
import Button from 'components/Button/Button';
import { COLORS } from 'assets/styled';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { useLoadNextPage } from 'utils/hooks/useLoadNextPage';
import Spinner from 'components/Spinner/Spinner';

const NotificationsPage = () => {
  const { t } = useTranslation();
  const { data, fetchNextPage, hasNextPage, isLoading } = useGetNotifications(
    NOTIFICATIONS_PER_PAGE,
    ''
  );

  const loadMoreRef = useRef<HTMLDivElement>(null);
  useLoadNextPage(loadMoreRef, hasNextPage, fetchNextPage, isLoading);

  const { mutate: markAllAsRead, isLoading: isLoadingMarkAllAsRead } =
    useMarkAllAsRead();

  let notifications: INotification[] = [];
  if (data?.pages?.length) {
    notifications = data.pages.map((page) => page.notifications).flat();
  }

  return (
    <Container>
      <FirstRow>
        <Title>{t('Notifications')}</Title>
        <Button
          label={t('Delete all')}
          sizeIcon={15}
          colorIcon={COLORS.PRIMARY}
          weightIcon="bold"
          link
          onClick={() =>
            !isLoadingMarkAllAsRead &&
            markAllAsRead(
              {},
              {
                onSuccess: () => {
                  queryClient.invalidateQueries([
                    ReactQueryKeys.GET_NOTIFICATIONS,
                  ]);
                  queryClient.invalidateQueries([
                    ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT,
                  ]);
                },
              }
            )
          }
          fontSize="18rem"
        />
      </FirstRow>
      <NotificationsWrapper>
        {notifications?.map((notification: INotification, index: number) => {
          return <NotificationCard key={index} notification={notification} />;
        })}
        <div
          ref={loadMoreRef}
          style={{
            height: '1px',
          }}
        />
      </NotificationsWrapper>
      <SpinnerNoContentWrapper>
        {isLoading ? (
          <Spinner />
        ) : notifications?.length ? null : (
          <NoContentLabel>
            {t('There are currently no notifications')}
          </NoContentLabel>
        )}
      </SpinnerNoContentWrapper>
    </Container>
  );
};
export default NotificationsPage;
