import {
  marginMd,
  marginLg,
  paddingLg,
  paddingMd,
  paddingSm,
  COLORS,
  borderRadiusSm,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  min-width: 1000rem;
  padding: ${paddingLg};

  ${respondTo.horizontalTablet`
    padding: ${paddingMd};
     min-width: auto;
  `}

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginLg};
  gap: 50rem;

  ${respondTo.bigTablet`
    gap: 30rem;
    width: 100%;
    margin-bottom: 30rem;
  `}

  ${respondTo.bigPhone`
    flex-direction: column;
    margin-bottom: 20rem;
    gap: 20rem;
  `}
`;

export const CardColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
  width: 460rem;

  ${respondTo.midTablet`
    max-width: 100%;
    width: 100%;
    flex-direction: column;
  `}
`;

export const WideCardColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
  width: 725rem;

  ${respondTo.midTablet`
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  `}
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50rem;
  width: 100%;

  ${respondTo.midTablet`
    gap: 20rem;
    flex-direction: column;
  `}

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
    height: auto;
  `}
`;

export const CalendarWrapper = styled.div`
  width: 100%;
  padding-top: 45rem;
  padding-bottom: ${paddingSm};
  padding-left: ${paddingMd};
  padding-right: ${paddingMd};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  margin-top: ${marginLg};
  overflow: auto;

  ${respondTo.bigTablet`
    margin-top: 0;
  `}

  ${respondTo.smallTablet`
    padding: 20rem;
  `}
`;

export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginMd};

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6.17rem;
  cursor: pointer;
`;

export const PlanningLabel = styled.label`
  font-size: 24rem;
  line-height: 16rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;

  ${respondTo.smallTablet`
    gap: 5rem;
  `}
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  pointer-events: none;
`;

export const RelativeDiv = styled.div`
  position: relative;
`;
