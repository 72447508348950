import {
  Container,
  CreatingMessage,
  NavigationStateMessageAndSelectWrapper,
  RowAlignCenterWithGap,
  TopCalendarWrapper,
} from './PlanningPage.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Close } from 'components/Close/Close';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import CalendarIntegration from './CalendarIntegration/CalendarIntegration';
import { CaretLeft } from '@phosphor-icons/react';
import { Size } from 'components/Close/type';
import CalendarTabs from './CalendarTabs/CalendarTabs';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { AppointmentType } from 'types/Appointment.types';
import { useTranslation } from 'react-i18next';
import PlanningTab from './PlanningTab/PlanningTab';
import { useGetPlanningPageFilters, useManageTabFilters } from './hooks';
import { IPlanningTabFilter } from 'store/Filters/types';

const PlanningPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const {
    planningPageFilters,
    appointmentTypeOptions,
    appointmentViewOptions,
  } = useGetPlanningPageFilters();

  const { addTab, removeTab, selectTab, activeTabId, tabs } =
    useManageTabFilters(planningPageFilters);

  const [
    isCreatingAppointmentFromNavigationState,
    setIsCreatingAppointmentFromNavigationState,
  ] = useState<boolean>(state ? true : false);

  // Shown after navigating to planning page from single quotation or single sales order page for creating new appointment
  const renderCreateFromNavigationStateMessage = () => {
    if (isCreatingAppointmentFromNavigationState) {
      const isForQuotation = state?.quotation_id;
      const isForSalesOrder = state?.sales_order_id;

      return (
        t('Creating') +
        ' ' +
        t(state?.type === AppointmentType.GENERAL ? 'service' : state?.type) +
        ' ' +
        t('appointment for') +
        ' ' +
        (isForQuotation
          ? t('Quotation')
          : isForSalesOrder
          ? t('Sales order')
          : t('Customer')) +
        ' ' +
        (isForQuotation
          ? state.quotation_number
          : isForSalesOrder
          ? state.sales_order_number
          : state.customer_number)
      );
    }
  };

  return (
    <Container
      isSideMenuCollapsed={isSideMenuCollapsed}
      className={`planning-step-1`}
    >
      <NavigationStateMessageAndSelectWrapper>
        <RowAlignCenterWithGap>
          {isCreatingAppointmentFromNavigationState && state?.type && (
            <>
              <Icon
                onClick={() => navigate(-1)}
                svg={CaretLeft}
                size={15}
                color={COLORS.BLACK}
              />
              <CreatingMessage>
                {renderCreateFromNavigationStateMessage()}
              </CreatingMessage>
              <Close
                size={Size.SMALL}
                onClick={() =>
                  setIsCreatingAppointmentFromNavigationState(false)
                }
              />
            </>
          )}
        </RowAlignCenterWithGap>
      </NavigationStateMessageAndSelectWrapper>
      <TopCalendarWrapper>
        <CalendarTabs
          calendarTabs={tabs}
          activeCalendarTab={activeTabId}
          onAddTab={addTab}
          onRemoveTab={removeTab}
          onSelectTab={selectTab}
        />
        <CalendarIntegration />
      </TopCalendarWrapper>
      {tabs?.map((tab: IPlanningTabFilter) => (
        <React.Fragment key={tab.id}>
          {tab.id === activeTabId && (
            <PlanningTab
              isCreatingAppointmentFromNavigationState={
                isCreatingAppointmentFromNavigationState
              }
              setIsCreatingAppointmentFromNavigationState={
                setIsCreatingAppointmentFromNavigationState
              }
              activeTab={tabs.find(
                (tab: IPlanningTabFilter) => tab.id === activeTabId
              )}
              appointmentTypeOptions={appointmentTypeOptions}
              appointmentViewOptions={appointmentViewOptions}
            />
          )}
        </React.Fragment>
      ))}
    </Container>
  );
};

export default PlanningPage;
