import { COLORS, borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 850rem;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  margin: 0 auto;
`;

export const NoResultsContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
`;

export const Scrollable = styled.div`
  max-height: calc(75vh);
  overflow-y: scroll;
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 30rem;

  ${respondTo.smallTablet`
    padding: 20rem;
  `}
`;

export const Message = styled.span`
  margin-top: 50rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
`;

export const Headline = styled.span`
  font-size: 24rem;
  line-height: 36rem;
  margin: 30rem 0;
  color: ${COLORS.BLACK};
  display: block;

  ${respondTo.smallTablet`
    margin-top: 0;
    font-size: 20rem;
    line-height: 1.2;
  `}
`;

export const SubHeadline = styled.span`
  font-size: 18rem;
  line-height: 27rem;
  margin-bottom: 20rem;
  color: ${COLORS.BLACK};
  display: block;
`;

export const HorizontalLine = styled.div`
  width: 790rem;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin: 20rem auto;
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  pointer-events: none;
`;
