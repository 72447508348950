import styled from 'styled-components';
import { COLORS, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10rem;
  padding: 30rem;
  background-color: ${COLORS.WHITE};
  width: 100%;
  overflow: hidden;
  height: 200rem;

  ${respondTo.midTablet`
    height: auto;
  `}

  ${respondTo.bigPhone`
    padding: 20rem;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6.17rem;
  cursor: pointer;
`;

export const NoPermissionMessage = styled.div`
  color: ${COLORS.BLACK};
  font-size: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;
`;

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 30rem;
  padding: 0 20rem 0 20rem;

  ${respondTo.midTablet`
   padding: 0;
  `}
`;

export const Number = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin-top: ${marginMd};
`;

export const Count = styled.div`
  font-size: 40rem;
  font-weight: 500;
  line-height: 16rem;
  letter-spacing: 0.75rem;
  text-align: center;
  color: ${COLORS.PRIMARY};
  margin-top: ${marginSm};
  height: 30rem;

  ${respondTo.bigPhone`
    font-size: 34rem;
  `}
`;

export const SpinnerWrapper = styled.div`
  margin-top: ${marginSm};
`;
