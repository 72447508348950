import { FormikErrors, FormikTouched, getIn } from 'formik';
import { ICreateQuotationProductFormDTO } from '../constants';
import { isNumeric } from 'utils/validation';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { DiscountType } from 'types/Discount.types';

export const getFormikErrorMesssage = (
  index: number,
  touched: FormikTouched<{ productFormDtos: ICreateQuotationProductFormDTO[] }>,
  errors: FormikErrors<{ productFormDtos: ICreateQuotationProductFormDTO[] }>,
  areAllFieldsTouched: boolean,
  getInString: string
) => {
  return (touched.productFormDtos &&
    getIn(touched.productFormDtos[index], getInString)) ||
    areAllFieldsTouched
    ? errors.productFormDtos &&
        getIn(errors.productFormDtos[index], getInString)
    : '';
};

export function getDiscountErrorMessage(
  value: string,
  fieldName: string,
  totalSalesPriceWithoutDiscount: string,
  discountType: DiscountType,
  lowerThanLabel: string
) {
  if (value === '' || value === undefined || value === null) {
    return undefined;
  } else if (!isNumeric(value)) {
    return `${capitalizeFirstLetter(fieldName)} must be a number`;
  }

  if (discountType === DiscountType.PERCENTAGE) {
    if (Number(value) < 0 || Number(value) > 100) {
      return `${capitalizeFirstLetter(fieldName)} must be between 0 and 100`;
    }
    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      return `${capitalizeFirstLetter(
        fieldName
      )} can only have two decimal places`;
    }
  } else {
    if (Number(value) < 0) {
      return `${capitalizeFirstLetter(fieldName)} must be higher or equal to 0`;
    }
    if (Number(value) > Number(totalSalesPriceWithoutDiscount)) {
      return `${capitalizeFirstLetter(
        fieldName
      )} can't be higher than ${lowerThanLabel}`;
    }
    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      return `${capitalizeFirstLetter(
        fieldName
      )} can only have two decimal places`;
    }
  }
  return undefined;
}

export function getGeneralDiscountErrorMessage(
  value: string,
  errorMessageFieldName: string
) {
  if (value) {
    if (Number(value) < 0 || Number(value) > 100) {
      return `${errorMessageFieldName} must be between 0 and 100`;
    }
    if (!/^\d+(\.\d{1,2})?$/.test(value)) {
      return `${errorMessageFieldName} can only have two decimal places`;
    }
  }
  return undefined;
}

export const checkAreDiscountsValid = (
  productFormDtos: ICreateQuotationProductFormDTO[]
) => {
  for (const productFormDto of productFormDtos) {
    if (
      getDiscountErrorMessage(
        productFormDto.discount,
        'Discount',
        (
          Number(productFormDto.salesPrice) * Number(productFormDto.quantity)
        ).toString(),
        productFormDto.discountType,
        'total sales price'
      )
    ) {
      return false;
    }
  }
  return true;
};

export const validatePlacementField = (value: string | undefined) => {
  if (value && value.length > 500) {
    return 'Maximum allowed length is 500 characters';
  }
  return undefined;
};
