import { Field, Formik } from 'formik';
import { COLORS, H5 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  Container,
  ApplyWrapper,
  WorkersAndResources,
  StyledSelect,
  Cont,
  StyledSelectTop,
  HorizontalLine,
  DateTimeSection,
  WorkersAndResourcesHeading,
  ArrivalTimeText,
  SalesOrderHeading,
  OptionsWrapper,
  OptionLabel,
  UsersHeading,
  UsersContainer,
  Scrollable,
  DateTimeWrapper,
  TimeIntervalErrorMessageCont,
  TimeIntervalErrorMessage,
  ArrivalTimeWrapper,
  ArrivalTime,
  ArrivalTimeInner,
  CheckboxContainer,
  HalfCont,
  AltTimeWrapper,
  DateLabel,
  TimeWrapper,
  RowSpaceBetween,
} from './MeasurementTab.styled';
import CustomSelect from 'components/Select/FormikSelect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  useCreateAppointment,
  useGetCustomersSummaryInfinite,
  useGetResourcesInfinite,
  useGetUsersSummaryInfinite,
  useGetWorkersInfinite,
} from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { ICreateAppointmentDTO } from 'pages/Manager/PlanningPage/types';
import { Option } from 'components/Select/type';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import {
  AddEmailNoteModal,
  IEmailDetails,
} from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import {
  CUSTOMERS_PER_PAGE,
  MeasurementTabOptions,
  QUOTATIONS_PER_PAGE,
  SALES_ORDERS_PER_PAGE,
} from './constants';
import {
  RESOURCES_PER_PAGE,
  USERS_PER_PAGE,
  WORKERS_PER_PAGE,
} from '../GeneralTab/constants';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  ICreateAppointmentNavigationState,
  useGetSelectedNewAppointmentEntity,
  useHandleSelectOptions,
} from '../hooks';
import DatePicker from 'components/DatePicker/DatePicker';
import moment, { Moment } from 'moment';
import TimePicker from 'components/TimePicker/TimePicker';
import {
  validateDateTimeInterval,
  validateTimeFields,
  validateTimeOnlyInterval,
} from '../validation';
import { validateQuotationSalesOrderAndCustomerField } from './validation';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { getArrivalTime, getNewAppointmentEmailDetails } from '../helpers';
import { Minus, WarningCircle } from '@phosphor-icons/react';
import { useGetSalesOrdersSummaryInfinite } from 'utils/hooks/useGetSalesOrders';
import { useGetQuotationsSummaryInfinite } from 'utils/hooks/useGetQuotations';
import {
  AppointmentTypeIds,
  IAlternativeContact,
} from 'types/Appointment.types';
import Loader from 'components/Loader/Loader';
import { useGetSingleCustomerAlternativeContacts } from 'pages/Manager/SingleCustomerPage/SingleCustomerAltContacts/hooks';
import { CUSTOMER_ALTERNATIVE_CONTACTS_PER_PAGE } from 'pages/Manager/SingleCustomerPage/SingleCustomerAltContacts/SingleCustomerAltContacts';

export interface IMeasurementTabProps {
  isNewAppointmentModalOpen: boolean;
  isCreatingAppointmentFromNavigationState: boolean;
  setIsCreatingAppointmentFromNavigationState: Dispatch<
    SetStateAction<boolean>
  >;
  closeAppointmentModal: () => void;
  startDate?: string;
  endDate?: string;
  time_from?: string;
  time_to?: string;
  arrival_to?: string;
  arrival_from?: string;
  selectedUsersOptions: Option[];
  selectedWorkersOptions: Option[];
  selectedResourcesOptions: Option[];
  appointmentLowerInterval: number;
  appointmentUpperInterval: number;
}

const MeasurementTab = ({
  isNewAppointmentModalOpen,
  isCreatingAppointmentFromNavigationState,
  setIsCreatingAppointmentFromNavigationState,
  closeAppointmentModal,
  startDate,
  endDate,
  time_from,
  time_to,
  arrival_to,
  arrival_from,
  selectedUsersOptions,
  selectedWorkersOptions,
  selectedResourcesOptions,
  appointmentUpperInterval,
  appointmentLowerInterval,
}: IMeasurementTabProps) => {
  const { t } = useTranslation();
  const { state }: { state: ICreateAppointmentNavigationState } = useLocation();

  const [quotationsSearchBy, setQuotationsSearchBy] = useState<string>('');
  const debouncedQuotationsSearchBy = useDebounce(quotationsSearchBy);

  const [salesOrderSearchBy, setSalesOrderSearchBy] = useState<string>('');
  const debouncedSalesOrderSearchBy = useDebounce(salesOrderSearchBy);

  const [customerSearchBy, setCustomerSearchBy] = useState<string>('');
  const debouncedCustomerSearchBy = useDebounce(customerSearchBy);

  const [altContactSearchBy, setAltContactSearchBy] = useState<string>('');
  const debouncedAltContactSearchBy = useDebounce(altContactSearchBy);

  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedUserSearchBy = useDebounce(userSearchBy);

  const [workerSearchBy, setWorkerSearchBy] = useState<string>('');
  const debouncedWorkerSearchBy = useDebounce(workerSearchBy);

  const [resourceSearchBy, setResourceSearchBy] = useState<string>('');
  const debouncedResourceSearchBy = useDebounce(resourceSearchBy);

  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);

  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);

  const [showArrivalTime, setShowArrivalTime] = useState<boolean>(false);

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [formikValues, setFormikValues] = useState<ICreateAppointmentDTO>();
  const [selectedOption, setSelectedOption] = useState<MeasurementTabOptions>(
    isCreatingAppointmentFromNavigationState && state?.sales_order_id
      ? MeasurementTabOptions.SALES_ORDER
      : isCreatingAppointmentFromNavigationState && state?.quotation_id
      ? MeasurementTabOptions.QUOTATION
      : MeasurementTabOptions.CUSTOMER
  );

  const [selectedAlternativeContact, setSelectedAlternativeContact] =
    useState<IAlternativeContact>();

  const {
    data: quotationsData,
    fetchNextPage: fetchNextPageQuotations,
    hasNextPage: hasNextPageQuotations,
    isLoading: isLoadingQuotations,
  } = useGetQuotationsSummaryInfinite(
    QUOTATIONS_PER_PAGE,
    debouncedQuotationsSearchBy,
    selectedOption === MeasurementTabOptions.QUOTATION
  );

  const quotations = useMemo(() => {
    if (quotationsData?.pages?.length) {
      return quotationsData.pages
        .map((page) => page.quotations)
        .flat()
        .map((quotation: any) => {
          return {
            value: quotation.id,
            label: `${quotation.number}`,
          };
        });
    }
    return [];
  }, [quotationsData]);

  const {
    data: salesOrdersData,
    fetchNextPage: fetchNextPageSalesOrders,
    hasNextPage: hasNextPageSalesOrders,
    isLoading: isLoadingSalesOrders,
  } = useGetSalesOrdersSummaryInfinite(
    SALES_ORDERS_PER_PAGE,
    debouncedSalesOrderSearchBy,
    selectedOption === MeasurementTabOptions.SALES_ORDER
  );

  const salesOrders = useMemo(() => {
    if (salesOrdersData?.pages?.length) {
      return salesOrdersData.pages
        .map((page) => page.sales_orders)
        .flat()
        .map((sales_order: any) => {
          return {
            value: sales_order.id,
            label: `${sales_order.number}`,
          };
        });
    }
    return [];
  }, [salesOrdersData]);

  const {
    data: customersData,
    fetchNextPage: fetchNextPageCustomers,
    hasNextPage: hasNextPageCustomers,
    isLoading: isLoadingCustomers,
  } = useGetCustomersSummaryInfinite(
    CUSTOMERS_PER_PAGE,
    debouncedCustomerSearchBy,
    selectedOption === MeasurementTabOptions.CUSTOMER
  );

  const customers = useMemo(() => {
    if (customersData?.pages?.length) {
      return customersData.pages
        .map((page) => page.customers)
        .flat()
        .map((customer: any) => {
          return {
            value: customer.id,
            label: `${customer.name} ${customer.last_name}`,
          };
        });
    }
    return [];
  }, [customersData]);

  const {
    mutate: createAppointment,
    isSuccess,
    isLoading,
  } = useCreateAppointment();

  const isMutatingCreateAppointment = useIsMutating(
    ReactMutationKeys.CREATE_APPOINTMENT
  );
  const createAppointmentIsLoading = !!isMutatingCreateAppointment || isLoading;

  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersSummaryInfinite(
    USERS_PER_PAGE,
    debouncedUserSearchBy,
    isNewAppointmentModalOpen
  );

  const {
    data: workersData,
    fetchNextPage: fetchNextPageWorkers,
    hasNextPage: hasNextPageWorkers,
    isLoading: isLoadingWorkers,
  } = useGetWorkersInfinite(
    WORKERS_PER_PAGE,
    debouncedWorkerSearchBy,
    undefined,
    undefined,
    isNewAppointmentModalOpen
  );

  const {
    data: resourcesData,
    fetchNextPage: fetchNextPageResources,
    hasNextPage: hasNextPageResources,
    isLoading: isLoadingResources,
  } = useGetResourcesInfinite(
    RESOURCES_PER_PAGE,
    debouncedResourceSearchBy,
    undefined,
    undefined,
    isNewAppointmentModalOpen
  );

  const {
    userOptions,
    setSelectedUserOptions,
    workerOptions,
    setSelectedWorkerOptions,
    resourceOptions,
    setSelectedResourceOptions,
  } = useHandleSelectOptions(
    usersData,
    workersData,
    resourcesData,
    selectedUsersOptions,
    selectedWorkersOptions,
    selectedResourcesOptions
  );

  useEffect(() => {
    if (isSuccess) {
      setIsCreatingAppointmentFromNavigationState(false);
      closeAppointmentModal();
      setIsSendEmailModalOpen(false);
    }
  }, [isSuccess]);

  const checkEmailSending = (values: ICreateAppointmentDTO) => {
    if (values?.alternative_contact)
      setSelectedAlternativeContact(values?.alternative_contact);
    setFormikValues(values);
    setIsSendEmailModalOpen(true);
  };
  const handleCreateAppointment = async (
    values: ICreateAppointmentDTO,
    shouldSendEmail: boolean
  ) => {
    const createAppointmentData: ICreateAppointmentDTO = {
      ...(values?.quotation_id &&
        selectedOption === MeasurementTabOptions.QUOTATION && {
          quotation_id: values?.quotation_id,
        }),
      ...(values?.sales_order_id &&
        selectedOption === MeasurementTabOptions.SALES_ORDER && {
          sales_order_id: values?.sales_order_id,
        }),
      ...(values?.customer_id &&
        selectedOption === MeasurementTabOptions.CUSTOMER && {
          customer_id: values?.customer_id,
        }),
      ...(values?.alternative_contact &&
        selectedOption === MeasurementTabOptions.CUSTOMER && {
          alternative_contact_id: values?.alternative_contact.id,
        }),
      date_from: `${values.startDate} ${values.time_from}`,
      date_to: `${values.endDate} ${values.time_to}`,
      appointment_type_id: values.appointment_type_id,
      users_ids: values?.users_ids,
      workers_ids: values?.workers_ids,
      resources_ids: values?.resources_ids,
      confirmed: isConfirmed,
      ...(values.arrival_from && {
        arrival_from: `${values.startDate} ${values.arrival_from}`,
      }),
      ...(values.arrival_to && {
        arrival_to: `${values.startDate} ${values.arrival_to}`,
      }),
    };
    createAppointment({
      createAppointmentData,
      shouldSendEmail,
      note: '',
      files: [],
    });
  };

  const {
    setSelectedQuotationId,
    setSelectedSalesOrderId,
    setSelectedCustomerId,
    selectedQuotation,
    selectedSalesOrder,
    selectedCustomer,
  } = useGetSelectedNewAppointmentEntity(state);

  const {
    data: customerAlternativeContactsData,
    hasNextPage: hasNextPageAltContacts,
    fetchNextPage: fetchNextPageAltContacts,
    isLoading: isLoadingAltContacts,
  } = useGetSingleCustomerAlternativeContacts(
    selectedCustomer?.id || 0,
    CUSTOMER_ALTERNATIVE_CONTACTS_PER_PAGE,
    debouncedAltContactSearchBy
  );

  const alternativeContacts = useMemo(() => {
    if (customerAlternativeContactsData?.pages?.length) {
      return customerAlternativeContactsData.pages
        .map((page) => page.alternative_contacts)
        .flat()
        .map((alternativeContact: IAlternativeContact) => {
          return {
            value: alternativeContact,
            label: `${alternativeContact.first_name} ${alternativeContact.last_name}`,
          };
        });
    }
    return [];
  }, [customerAlternativeContactsData]);

  const emailDetails: IEmailDetails | undefined = getNewAppointmentEmailDetails(
    selectedQuotation,
    selectedSalesOrder,
    selectedCustomer,
    selectedAlternativeContact
  );

  const getTimeInterval = (timeFrom: string, timeTo: string) => {
    return moment(timeTo, 'HH:mm:ss').diff(moment(timeFrom, 'HH:mm:ss'));
  };
  const getDateInterval = (dateFrom: string, dateTo: string) => {
    return moment(dateTo).diff(moment(dateFrom));
  };

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={() => {
          // onSubmit
        }}
        initialValues={{
          quotation_id:
            isCreatingAppointmentFromNavigationState && state?.quotation_id
              ? state?.quotation_id
              : '',
          sales_order_id:
            isCreatingAppointmentFromNavigationState && state?.sales_order_id
              ? state?.sales_order_id
              : '',
          customer_id:
            isCreatingAppointmentFromNavigationState && state?.customer_id
              ? state?.customer_id
              : '',
          alternative_contact: null,
          startDate: startDate || '',
          endDate: endDate || '',
          date_from: '',
          date_to: '',
          time_to: time_to?.split('+')[0] || '',
          time_from: time_from?.split('+')[0] || '',
          arrival_to: arrival_to?.split('+')[0] || '',
          arrival_from: arrival_from?.split('+')[0] || '',
          appointment_type_id: AppointmentTypeIds.MEASURING,
          users_ids: selectedUsersOptions.map((user) => user.id) || [],
          resources_ids:
            selectedResourcesOptions.map((resource) => resource.id) || [],
          workers_ids: selectedWorkersOptions.map((worker) => worker.id) || [],
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, setFieldValue, setFieldError, values, isValid }) => {
          return (
            <Container>
              {createAppointmentIsLoading && <Loader />}
              <Scrollable>
                <DateTimeSection>
                  <H5>{t('DATE & TIME')}</H5>
                  <DateLabel>{t('From')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.startDate)}
                      setDate={(newValue: any) => {
                        const initialDateInterval = getDateInterval(
                          values.startDate,
                          values.endDate
                        );

                        const newDateFrom = newValue?.format('YYYY-MM-DD');
                        const newDateTo = moment(newValue)
                          .add(initialDateInterval, 'milliseconds')
                          .format('YYYY-MM-DD');

                        setFieldValue('startDate', newDateFrom);
                        setFieldValue('endDate', newDateTo);
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              startDate: newDateFrom,
                              endDate: newDateTo,
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <Field
                        name="time_from"
                        component={TimePicker}
                        width="100%"
                        pwId="time-from-select"
                        time={moment(values?.time_from, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          const initialTimeInterval = getTimeInterval(
                            values?.time_from,
                            values?.time_to
                          );
                          const newTimeFrom = newValue?.format('HH:mm:ss');
                          const {
                            arrivalFrom: newArrivalTimeFrom,
                            arrivalTo: newArrivalTimeTo,
                          } = getArrivalTime(
                            newTimeFrom,
                            appointmentLowerInterval,
                            appointmentUpperInterval
                          );
                          const newTimeTo = moment(newValue, 'HH:mm:ss')
                            .add(initialTimeInterval, 'milliseconds')
                            .format('HH:mm:ss');

                          setFieldValue('time_from', newTimeFrom);
                          setFieldValue('time_to', newTimeTo);
                          setFieldValue('arrival_from', newArrivalTimeFrom);
                          setFieldValue('arrival_to', newArrivalTimeTo);

                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            newTimeFrom,
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            newTimeTo,
                            setFieldError,
                            t,
                            'time_to'
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <DateLabel>{t('To')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.endDate)}
                      setDate={(newValue: any) => {
                        setFieldValue(
                          'endDate',
                          newValue?.format('YYYY-MM-DD')
                        );
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              endDate: newValue?.format('YYYY-MM-DD'),
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <Field
                        name="time_to"
                        component={TimePicker}
                        width="100%"
                        pwId="time-to-select"
                        time={moment(values?.time_to, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            values.time_from,
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            newValue?.format('HH:mm:ss'),
                            setFieldError,
                            t,
                            'time_to'
                          );
                          setFieldValue(
                            'time_to',
                            newValue?.format('HH:mm:ss')
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <TimeIntervalErrorMessageCont>
                    <TimeIntervalErrorMessage>
                      {errors.time_to}
                    </TimeIntervalErrorMessage>
                  </TimeIntervalErrorMessageCont>
                  <ArrivalTimeWrapper>
                    <ArrivalTimeInner>
                      <ArrivalTime>
                        <ArrivalTimeText
                          onClick={() => setShowArrivalTime(true)}
                        >
                          {t('Arrival time')}
                        </ArrivalTimeText>
                        <Tooltip
                          content={
                            t(
                              'This is the expected arrival time that will be communicated with the customer via the appointment email'
                            ) + '.'
                          }
                        >
                          <Icon
                            svg={WarningCircle}
                            color={COLORS.PRIMARY}
                            size={20}
                            weight="regular"
                            marginLeft={'10rem'}
                            pointer={false}
                          />
                        </Tooltip>
                      </ArrivalTime>
                      {showArrivalTime && (
                        <>
                          <AltTimeWrapper>
                            <Field
                              name="arrival_from"
                              component={TimePicker}
                              width="100%"
                              pwId="time-from-select"
                              time={moment(values?.arrival_from, 'HH:mm:ss')}
                              setTime={(newValue: Moment) => {
                                validateTimeOnlyInterval(
                                  newValue?.format('HH:mm:ss'),
                                  values.arrival_to,
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_from',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                            <Icon
                              svg={Minus}
                              size={18}
                              weight="bold"
                              color={COLORS.BLACK}
                              marginLeft="5rem"
                              marginRight="5rem"
                            />
                            <Field
                              name="arrival_to"
                              component={TimePicker}
                              width="100%"
                              pwId="time-to-select"
                              time={moment(values?.arrival_to, 'HH:mm:ss')}
                              setTime={(newValue: Moment) => {
                                validateTimeOnlyInterval(
                                  values.arrival_from,
                                  newValue?.format('HH:mm:ss'),
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_to',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                          </AltTimeWrapper>
                        </>
                      )}
                    </ArrivalTimeInner>
                    <TimeIntervalErrorMessageCont>
                      <TimeIntervalErrorMessage>
                        {errors.arrival_to}
                      </TimeIntervalErrorMessage>
                    </TimeIntervalErrorMessageCont>
                  </ArrivalTimeWrapper>
                </DateTimeSection>
                <HorizontalLine />
                <SalesOrderHeading>
                  <OptionsWrapper>
                    {isCreatingAppointmentFromNavigationState &&
                    (state?.sales_order_id || state?.customer_id) ? null : (
                      <OptionLabel
                        data-testid="quotation-label"
                        onClick={() => {
                          setSelectedOption(MeasurementTabOptions.QUOTATION);
                          validateQuotationSalesOrderAndCustomerField(
                            values.quotation_id,
                            values.sales_order_id,
                            values.customer_id,
                            setFieldError,
                            t,
                            MeasurementTabOptions.QUOTATION
                          );
                        }}
                        isSelected={
                          selectedOption === MeasurementTabOptions.QUOTATION
                        }
                      >
                        {t(MeasurementTabOptions.QUOTATION)}
                      </OptionLabel>
                    )}
                    {isCreatingAppointmentFromNavigationState &&
                    (state?.quotation_id || state?.customer_id) ? null : (
                      <OptionLabel
                        data-testid="sales-order-label"
                        onClick={() => {
                          setSelectedOption(MeasurementTabOptions.SALES_ORDER);
                          validateQuotationSalesOrderAndCustomerField(
                            values.quotation_id,
                            values.sales_order_id,
                            values.customer_id,
                            setFieldError,
                            t,
                            MeasurementTabOptions.SALES_ORDER
                          );
                        }}
                        isSelected={
                          selectedOption === MeasurementTabOptions.SALES_ORDER
                        }
                      >
                        {t(MeasurementTabOptions.SALES_ORDER)}
                      </OptionLabel>
                    )}
                    {isCreatingAppointmentFromNavigationState &&
                    (state?.quotation_id || state?.sales_order_id) ? null : (
                      <OptionLabel
                        onClick={() => {
                          setSelectedOption(MeasurementTabOptions.CUSTOMER);
                          validateQuotationSalesOrderAndCustomerField(
                            values.quotation_id,
                            values.sales_order_id,
                            values.customer_id,
                            setFieldError,
                            t,
                            MeasurementTabOptions.CUSTOMER
                          );
                        }}
                        isSelected={
                          selectedOption === MeasurementTabOptions.CUSTOMER
                        }
                      >
                        {t(MeasurementTabOptions.CUSTOMER)}
                      </OptionLabel>
                    )}
                  </OptionsWrapper>
                </SalesOrderHeading>
                {selectedOption === MeasurementTabOptions.QUOTATION &&
                  (isCreatingAppointmentFromNavigationState &&
                  state?.quotation_id ? (
                    <FormikInput
                      disabled
                      height={'41rem'}
                      name="quotation_id"
                      value={state?.quotation_number}
                      onChange={(e: any) =>
                        setQuotationsSearchBy(e.target.value)
                      }
                      wrapperStyles={{
                        width: '200rem',
                      }}
                    />
                  ) : (
                    <StyledSelectTop
                      pwId="quotation-id"
                      name="quotation_id"
                      options={quotations}
                      onInputChange={(e: string) => setQuotationsSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Quotation')}
                      isMulti={false}
                      onSelect={({ value }: any) => {
                        setSelectedSalesOrderId(undefined);
                        setSelectedCustomerId(undefined);
                        setSelectedQuotationId(value);
                        setFieldValue('quotation_id', value);
                        setFieldValue('sales_order_id', '');
                        setFieldValue('customer_id', '');
                        setFieldValue('alternative_contact', null);
                        validateQuotationSalesOrderAndCustomerField(
                          value,
                          values.sales_order_id,
                          values.customer_id,
                          setFieldError,
                          t,
                          selectedOption
                        );
                      }}
                      onMenuScrollToBottom={() => {
                        hasNextPageQuotations && fetchNextPageQuotations();
                      }}
                      isLoading={isLoadingQuotations}
                      translate={false}
                      errorMessage={errors.quotation_id}
                      menuHeight={'200rem'}
                    />
                  ))}
                {selectedOption === MeasurementTabOptions.SALES_ORDER ? (
                  isCreatingAppointmentFromNavigationState &&
                  state?.sales_order_id ? (
                    <FormikInput
                      disabled
                      height={'41rem'}
                      name="sales_order_id"
                      value={state?.sales_order_number}
                      wrapperStyles={{
                        width: '200rem',
                      }}
                    />
                  ) : (
                    <StyledSelectTop
                      pwId="sales-order-id"
                      name="sales_order_id"
                      options={salesOrders}
                      onInputChange={(e: string) => setSalesOrderSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Sales order')}
                      isMulti={false}
                      onSelect={({ value }: any) => {
                        setSelectedQuotationId(undefined);
                        setSelectedCustomerId(undefined);
                        setSelectedSalesOrderId(value);
                        setFieldValue('sales_order_id', value);
                        setFieldValue('quotation_id', '');
                        setFieldValue('customer_id', '');
                        setFieldValue('alternative_contact', null);
                        validateQuotationSalesOrderAndCustomerField(
                          values.quotation_id,
                          value,
                          values.customer_id,
                          setFieldError,
                          t,
                          selectedOption
                        );
                      }}
                      onMenuScrollToBottom={() =>
                        hasNextPageSalesOrders && fetchNextPageSalesOrders()
                      }
                      isLoading={isLoadingSalesOrders}
                      translate={false}
                      errorMessage={errors.sales_order_id}
                      menuHeight={'200rem'}
                    />
                  )
                ) : null}
                {selectedOption === MeasurementTabOptions.CUSTOMER ? (
                  <RowSpaceBetween>
                    {isCreatingAppointmentFromNavigationState &&
                    state?.customer_id ? (
                      <FormikInput
                        disabled
                        height={'41rem'}
                        name="customer_id"
                        value={state?.customer_number}
                        onChange={(e: any) =>
                          setCustomerSearchBy(e.target.value)
                        }
                        wrapperStyles={{
                          width: 'calc(50% - 10rem)',
                        }}
                      />
                    ) : (
                      <StyledSelectTop
                        name="customer_id"
                        options={customers}
                        onInputChange={(e: string) => setCustomerSearchBy(e)}
                        component={CustomSelect}
                        placeholder={t('Customer')}
                        isMulti={false}
                        onSelect={({ value }: any) => {
                          setSelectedQuotationId(undefined);
                          setSelectedSalesOrderId(undefined);
                          setSelectedCustomerId(value);
                          setFieldValue('customer_id', value);
                          setFieldValue('quotation_id', '');
                          setFieldValue('sales_order_id', '');
                          setFieldValue('alternative_contact', null);
                          validateQuotationSalesOrderAndCustomerField(
                            values.quotation_id,
                            values.sales_order_id,
                            value,
                            setFieldError,
                            t,
                            selectedOption
                          );
                        }}
                        onMenuScrollToBottom={() =>
                          hasNextPageCustomers && fetchNextPageCustomers()
                        }
                        isLoading={isLoadingCustomers}
                        translate={false}
                        errorMessage={errors.customer_id}
                        menuHeight={'200rem'}
                      />
                    )}

                    <StyledSelectTop
                      key={selectedCustomer?.id}
                      disabled={!selectedCustomer}
                      name="alternative_contact"
                      options={alternativeContacts}
                      onInputChange={(e: string) => setAltContactSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Alternative contact')}
                      isMulti={false}
                      onSelect={(option: any) => {
                        setFieldValue(
                          'alternative_contact',
                          option ? option.value : null
                        );
                      }}
                      onMenuScrollToBottom={() =>
                        hasNextPageAltContacts && fetchNextPageAltContacts()
                      }
                      isLoading={isLoadingAltContacts}
                      translate={false}
                      menuHeight={'200rem'}
                      isClearable={true}
                    />
                  </RowSpaceBetween>
                ) : null}
                <HorizontalLine />
                <UsersHeading>
                  <H5>{t('USERS')}</H5>
                </UsersHeading>
                <UsersContainer>
                  <HalfCont>
                    <StyledSelect
                      name="users_ids"
                      options={userOptions}
                      onInputChange={(e: string) => setUserSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Users')}
                      isMulti={true}
                      onSelect={(value: Option[]) =>
                        setSelectedUserOptions(value)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageUsers && fetchNextPageUsers()
                      }
                      isLoading={isLoadingUsers}
                      translate={false}
                      menuHeight={'200rem'}
                    />
                  </HalfCont>
                </UsersContainer>
                <HorizontalLine />
                <WorkersAndResourcesHeading>
                  <H5>{t('WORKERS & RESOURCES')}</H5>
                </WorkersAndResourcesHeading>
                <WorkersAndResources>
                  <Cont>
                    <StyledSelect
                      width="200rem"
                      pwId="workers-id"
                      name="workers_ids"
                      options={workerOptions}
                      onInputChange={(e: string) => setWorkerSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Workers')}
                      isMulti={true}
                      onSelect={(value: Option[]) =>
                        setSelectedWorkerOptions(value)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageWorkers && fetchNextPageWorkers()
                      }
                      isLoading={isLoadingWorkers}
                      translate={false}
                      menuHeight={'200rem'}
                      menuPlacement={'top'}
                    />
                  </Cont>
                  <Cont>
                    <StyledSelect
                      width="200rem"
                      pwId="resources-id"
                      name="resources_ids"
                      options={resourceOptions}
                      onInputChange={(e: string) => setResourceSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Resources')}
                      isMulti
                      onSelect={(value: Option[]) =>
                        setSelectedResourceOptions(value)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageResources && fetchNextPageResources()
                      }
                      isLoading={isLoadingResources}
                      translate={false}
                      menuHeight={'200rem'}
                      menuPlacement={'top'}
                    />
                  </Cont>
                </WorkersAndResources>
                <CheckboxContainer>
                  <Checkbox
                    isChecked={isConfirmed}
                    onChange={setIsConfirmed}
                    label={t('The customer already confirmed the appointment')}
                  />
                </CheckboxContainer>
              </Scrollable>
              <ApplyWrapper>
                <Button
                  disabled={createAppointmentIsLoading}
                  onClick={closeAppointmentModal}
                  secondary
                  width="200rem"
                  label={t('Cancel')}
                />
                <Button
                  disabled={createAppointmentIsLoading || !isValid}
                  onClick={() => {
                    if (
                      !validateQuotationSalesOrderAndCustomerField(
                        values.quotation_id,
                        values.sales_order_id,
                        values.customer_id,
                        setFieldError,
                        t,
                        selectedOption
                      )
                    ) {
                      return;
                    }
                    checkEmailSending(values);
                  }}
                  primary
                  width="200rem"
                  label={t('Save')}
                />
              </ApplyWrapper>
            </Container>
          );
        }}
      </Formik>
      <YesOrNoModal
        pwId="yes-or-no-appointment-confirmation-modal"
        level="SECOND"
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onYes={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onNo={() => {
          handleCreateAppointment(formikValues!, false);
          setIsSendEmailModalOpen(false);
        }}
        title={''}
        description={
          isConfirmed
            ? t(
                'Do you want to send the appointment confirmation to the customer'
              ) + '?'
            : t('Do you want to send the appointment offer to the customer') +
              '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={formikValues?.quotation_id || formikValues?.sales_order_id || ''}
        type={AddEmailNoteModalType.MEASUREMENT_CREATION}
        newAppointment={formikValues!}
        isConfirmedAppointment={isConfirmed}
        onCreateAppointmentSuccess={() => {
          isCreatingAppointmentFromNavigationState &&
            setIsCreatingAppointmentFromNavigationState(false);
          closeAppointmentModal();
        }}
        emailDetails={emailDetails}
      />
    </div>
  );
};

export default MeasurementTab;
