export enum NotificationType {
  SALES_ORDER_COMPLETED = 'SALES_ORDER_COMPLETED',
  QUOTATION_EXPIRES_SOON = 'QUOTATION_EXPIRES_SOON',
  INVOICE_PAID = 'INVOICE_PAID',
  INVOICE_PAST_DUE = 'INVOICE_PAST_DUE',
  GENERAL = 'GENERAL',
  QUOTATION_ACCEPTED = 'QUOTATION_ACCEPTED',
  QUOTATION_REJECTED = 'QUOTATION_REJECTED',
  TODO_ASSIGNED = 'TODO_ASSIGNED',
  TODO_COMPLETED = 'TODO_COMPLETED',
  REPORT_AVAILABLE = 'REPORT_AVAILABLE',
  PURCHASE_ORDER_RECEIVED = 'PURCHASE_ORDER_RECEIVED',
  SALES_ORDER_AVAILABLE = 'SALES_ORDER_AVAILABLE',
  APPOINTMENT_CONFIRMED = 'APPOINTMENT_CONFIRMED',
  DOWNPAYMENT_INVOICE_PAID = 'DOWNPAYMENT_INVOICE_PAID',
  CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST',
  DECLINE_APPOINTMENT_OFFER = 'DECLINE_APPOINTMENT_OFFER',
}
