import { COLORS, H4 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ButtonContainer,
  Container,
  Count,
  FilterContainer,
  Headline,
  Number,
  NumberContainer,
  SelectInputWrapper,
  TitleContainer,
  TitleWrapper,
} from './FilterCard.styled';
import { formatNumberCompact } from 'utils/numberFormatter';
import { Select } from 'components/Select/Select';
import Spinner from 'components/Spinner/Spinner';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import Icon from 'components/Icon/Icon';
import MoreButton from 'components/MoreButton/MoreButton';
import { IAnalyticsNavigationState } from '../types';

interface IFilterCardProps {
  title: string;
  filterOptions?: any;
  extraFilterOptions?: any;
  label?: string;
  route: string;
  icon: any;
  value: number;
  totalValue: number;
  onFilterChange?: (selectedValue: any) => void;
  onExtraFilterChange?: (selectedValue: any) => void;
  defaultValue?: any;
  extraDefaultValue?: any;
  width?: string;
  extraFilterPlaceholder?: string;
}

export const FilterCard = ({
  title,
  label,
  route,
  icon,
  filterOptions,
  extraFilterOptions,
  value,
  totalValue,
  onFilterChange,
  onExtraFilterChange,
  defaultValue,
  extraDefaultValue,
  width,
  extraFilterPlaceholder,
}: IFilterCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  return (
    <Container width={width}>
      <TitleContainer hasExtraOption={extraFilterOptions}>
        <TitleWrapper>
          <Icon svg={icon} size={30} color={COLORS.PRIMARY} />
          <Headline hasSelect={filterOptions?.length}>{t(title)}</Headline>
        </TitleWrapper>
        <FilterContainer hasExtraOption={extraFilterOptions}>
          {filterOptions && (
            <SelectInputWrapper>
              <Select
                defaultValue={defaultValue ? defaultValue : filterOptions[0]}
                name="activeFilter"
                placeholder={''}
                isMulti={false}
                isDisabled={false}
                isSearchable={false}
                onChange={(selectedOption) => {
                  onFilterChange && onFilterChange(selectedOption);
                }}
                options={filterOptions}
              />
            </SelectInputWrapper>
          )}
          {extraFilterOptions && (
            <SelectInputWrapper>
              <Select
                defaultValue={
                  extraDefaultValue ? extraDefaultValue : extraFilterOptions[0]
                }
                name="activeExtraFilter"
                placeholder={extraFilterPlaceholder || ''}
                isMulti={false}
                isDisabled={false}
                isSearchable={false}
                onChange={(selectedOption) => {
                  onExtraFilterChange && onExtraFilterChange(selectedOption);
                }}
                options={extraFilterOptions}
              />
            </SelectInputWrapper>
          )}
        </FilterContainer>
      </TitleContainer>
      <NumberContainer>
        <Number>
          <H4>{t(label)}</H4>
          <Count>
            {value === undefined ? <Spinner /> : formatNumberCompact(value)}
          </Count>
        </Number>
        <Number>
          <H4>{t('TOTAL VALUE')}</H4>
          <Count>
            {totalValue === undefined ? (
              <Spinner />
            ) : (
              `${currencySymbol}${formatNumberCompact(totalValue)}`
            )}
          </Count>
        </Number>
      </NumberContainer>
      <ButtonContainer
        onClick={() => {
          const analyticsNavigationState: IAnalyticsNavigationState = {};
          if (title === t('Order Portfolio')) {
            analyticsNavigationState.selectedSubStatus = defaultValue;
          } else {
            analyticsNavigationState.dateIntervalOption = defaultValue;
          }
          navigate(route, {
            state: analyticsNavigationState,
          });
        }}
      >
        <MoreButton />
      </ButtonContainer>
    </Container>
  );
};
