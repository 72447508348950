import { Box, Grid, Card, CardContent, Skeleton } from '@mui/material';

const CardSkeleton = ({ numRows }: any) => {
  return (
    <>
      {numRows > 0 && (
        <Box>
          <Grid container spacing={3}>
            {Array.from({ length: numRows }).map((_, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="80%" />
                      </Grid>
                      <Grid item xs={6}>
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="80%" />
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="80%" />
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Box display="flex" justifyContent="center" paddingBottom={2}>
                    <Skeleton variant="rectangular" width={50} height={20} />
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default CardSkeleton;
