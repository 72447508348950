import styled, { css, keyframes } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, marginMd, paddingSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isBlueColor: boolean;
  isPaginationTR: boolean;
  isPaginationPresent: boolean;
  $withoutPagination: boolean;
  isClickable: boolean;
  flexValue: number;
  extraActionsFlexValue: number;
  $bigCell: true;
  labelsRow: boolean;
  $withoutMarginTop?: boolean;
  isHovered?: boolean;
  selectField?: boolean;
  isActiveTodo?: boolean;
  $overflowX?: boolean;
}

export const TableResponsive = styled.div`
  -webkit-overflow-scrolling: touch;
  position: relative;

  ${(props: StyledProps) =>
    props.$overflowX &&
    respondTo.smallDesktop`
      overflow-x: auto;
      max-width: calc(100vw - 480rem);
  `}

  ${respondTo.horizontalTablet`
    overflow-x: auto;
    max-width: 100%;
  `}
`;

export const CustomTable = styled.table`
  margin-top: ${marginMd};
  border-collapse: collapse;
  max-width: 100%;
  width: 100%;

  ${(props: StyledProps) =>
    props.$withoutMarginTop &&
    css`
      margin-top: 0;
    `}

  ${(props: StyledProps) =>
    props.$withoutPagination &&
    css`
      margin-top: 0;
      thead tr th {
        position: sticky;
        top: 0;
      }
    `}

  th {
    background-color: ${COLORS.PRIMARY};
    color: ${COLORS.WHITE};
    font-weight: 300;
    display: flex;
    align-items: center;

    label {
      color: ${COLORS.WHITE};
    }
  }
  th:first-child {
    border-top-left-radius: ${borderRadiusSm};
    flex: 0;
  }
  th:last-child {
    border-top-right-radius: ${borderRadiusSm};
    flex: 0;
  }

  th,
  td {
    min-width: 0;
    padding: 20rem 10rem;
    vertical-align: middle;
    text-align: center;
    font-weight: 500;
    transition: ease 200ms;
  }
  td {
    background-color: ${COLORS.WHITE};
  }

  ${(props: StyledProps) =>
    props.$overflowX &&
    respondTo.smallDesktop`
    th,
    td {
      min-width: 150rem;
    }
    td:first-child,
    th:first-child {
      min-width: 0;
    }
    td:last-child,
    th:last-child {
      min-width: 0;
    }
  `}

  ${respondTo.horizontalTablet`
    th, td label {
      line-height: 1.3;
      font-size: 18rem;
    }

    th,
    td {
      min-width: 150rem;
      padding: 20rem 5rem;
    }
    th {
      padding: 10rem 5rem;
    }
    td:first-child,
    th:first-child {
      min-width: 0;
    }
    td:last-child,
    th:last-child {
      min-width: 0;
    }
  `}

  td:first-child {
    flex: 0;
    border: none;
    width: 30rem;
  }
  td:last-child {
    flex: 0;
    border: none;
    width: 30rem;
  }

  tr:not(:last-child) {
    td {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  tr:last-child {
    border-color: transparent;
    td {
      flex: 1;
      padding: 10rem;
    }
    td:first-child {
      border-bottom-left-radius: ${borderRadiusSm};
      flex: 0;
    }
    td:last-child {
      border-bottom-right-radius: ${borderRadiusSm};
      flex: 0;
    }
  }
`;

export const TableRow = styled.tr`
  td {
    background-color: ${(props: StyledProps) =>
      props.isHovered
        ? COLORS.GREY_200
        : props.isActiveTodo
        ? COLORS.BACKGROUND_COLOR
        : COLORS.WHITE};
  }
  ${(props: StyledProps) =>
    props.isActiveTodo &&
    css`
      border: 1rem solid ${COLORS.WHITE};
    `};
`;

export const TD = styled.td`
  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `};

  border-bottom: 1px solid ${COLORS.GREY_300};

  ${(props: StyledProps) =>
    props.labelsRow &&
    css`
      border: none;
    `};
`;

export const TDLabels = styled.td`
  display: flex;
  width: 100% !important;
  max-width: 100%;
  justify-content: flex-start !important;
  gap: 10rem !important;
  padding: 0 30rem 20rem !important;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 50rem);
    background-color: ${COLORS.GREY_300};
    height: 1px;
  }
`;

export const PaginationTR = styled.tr`
  height: 84rem;
  pointer-events: none;
  user-select: none;
  ${(props: StyledProps) =>
    !props.isPaginationPresent &&
    css`
      height: 50rem;
    `}
`;

export const TdLabel = styled.label`
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  user-select: none;
  position: relative;
  word-break: normal;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isBlueColor &&
    css`
      cursor: pointer;
      color: ${COLORS.PRIMARY};
    `}
`;

export const ThLabel = styled.label`
  vertical-align: middle;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  user-select: none;
  position: relative;
  word-break: normal;
`;

export const ThWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ThIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
  margin-top: 15rem;

  ${(props: StyledProps) =>
    props.$overflowX &&
    respondTo.smallDesktop`
      margin-top: 10rem;
  `}

  ${respondTo.horizontalTablet`
    margin-top: 10rem;
  `}
`;

export const Th = styled.th`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${(props: StyledProps) =>
    props.flexValue &&
    css`
      flex: ${props.flexValue};
    `}

  ${(props: StyledProps) =>
    props.$bigCell &&
    css`
      justify-content: space-between;
    `}
`;

export const ExtraActionsHeader = styled.th`
  ${(props: StyledProps) =>
    props.extraActionsFlexValue &&
    css`
      flex: ${props.extraActionsFlexValue};
    `}

  ${(props: StyledProps) =>
    props.$overflowX &&
    respondTo.smallDesktop`
    min-width: 100rem !important;
  `}
  ${respondTo.horizontalTablet`
    min-width: 100rem !important;
  `}
`;

export const ExtraActionsHeaderInvisibleContentWrapper = styled.div`
  opacity: 0;
  user-select: none;
  pointer-events: none;
  display: flex;
`;

export const ExtraActionsTableData = styled.td`
  display: flex;
  justify-content: space-evenly;
  gap: 10rem;
  ${(props: StyledProps) =>
    props.extraActionsFlexValue &&
    css`
      flex: ${props.extraActionsFlexValue};
    `}

  ${(props: StyledProps) =>
    props.$overflowX &&
    respondTo.smallDesktop`
    min-width: 100rem !important;
  `}
  ${respondTo.horizontalTablet`
    min-width: 100rem !important;
  `}

   border-bottom: 1px solid ${COLORS.GREY_300};

  ${(props: StyledProps) =>
    props.labelsRow &&
    css`
      border: none;
    `};
`;

const swipe = keyframes`
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-20rem);
  }
  100% {
    transform: translateX(0);
  }
`;

export const IconWrapper = styled.div`
  position: absolute;
  right: 30rem;
  bottom: 15rem;
  animation: ${swipe} 2s infinite;
`;

export const LabelWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10rem;
  flex-wrap: wrap;
  border-bottom: 1px solid ${COLORS.GREY_300};
  background-color: ${COLORS.WHITE};
  padding-bottom: ${paddingSm};
`;

export const CardList = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  padding: 20rem;
  border-radius: 10rem;
  width: 100%;
  max-width: 100%;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 4%;
`;
export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;
export const CardHeaderLabelWrapper = styled.div`
  min-height: 30rem;
  display: flex;
  align-items: center;

  ${(props: StyledProps) =>
    props.selectField &&
    css`
      height: 43rem;
    `};
`;
export const CardHeaderLabel = styled.label`
  font-weight: 300;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  line-height: 1;
`;
export const Card = styled.div`
  max-width: 48%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${COLORS.GREY_300};
  padding-bottom: 20rem;
  margin-bottom: 20rem;
  justify-content: space-between;
  position: relative;

  ${(props: StyledProps) =>
    props.isActiveTodo &&
    css`
      background-color: ${COLORS.BACKGROUND_COLOR};
    `};

  &:nth-child(1n) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -4%;
      background-color: ${COLORS.GREY_300};
      width: 1px;
      height: 80%;

      ${respondTo.bigPhone`
        display: none;
      `}
    }
  }

  &:nth-child(2n) {
    &::before {
      display: none;
    }
  }

  ${respondTo.bigPhone`
    max-width: 100%;
  `}
`;
export const CardLeftSide = styled.div`
  max-width: 50%;
  width: 100%;
`;
export const CardRightSide = styled.div`
  max-width: 50%;
  width: 100%;
`;
export const CardValueWrapper = styled.div`
  min-height: 30rem;
  display: flex;
  align-items: center;
`;

export const CardValueHolder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const CardValue = styled.label`
  font-weight: 300;
  line-height: 1;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props: StyledProps) =>
    props.isBlueColor &&
    css`
      cursor: pointer;
      color: ${COLORS.PRIMARY};
    `}
`;
export const ExtraActionsCard = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 20rem;
  display: flex;
  gap: 10rem;
  justify-content: center;
`;

export const PaginationWrapper = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CardLabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
  flex-wrap: wrap;
  padding: 5rem 0;
`;

export const ExtraActionsWrapper = styled.div`
  display: flex;
  gap: 10rem;
  justify-content: center;
`;
