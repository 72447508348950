import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  $flexValue: string;
  $isBlueColor: boolean;
  $selected: boolean;
  $isRed: boolean;
  isPaginationPresent: boolean;
  width: string;
}

export const Table = styled.div``;
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  padding: 10rem 20rem;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.GREY_500};
  border-radius: ${borderRadiusSm};
`;

export const HeaderCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 0 5rem;
  ${(props: StyledProps) =>
    props.$flexValue &&
    css`
      flex: ${props.$flexValue};
    `};
`;

export const HeaderLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GREY_500};
  padding: 15rem;

  &:last-of-type {
    border: none;
  }

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : `none`};
`;

export const BodyCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 1;
  padding: 0 5rem;
  ${(props: StyledProps) =>
    props.$flexValue &&
    css`
      flex: ${props.$flexValue};
    `};

  ${respondTo.smallTablet`
      min-height: 30rem;
      align-items: center;
    `}
`;

export const BodyLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;

  ${(props: StyledProps) =>
    props.$isBlueColor &&
    css`
      color: ${COLORS.PRIMARY};
    `};

  ${respondTo.smallTablet`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

export const ActionHeaderCell = styled.div`
  display: flex;
  width: 40rem;
  justify-content: flex-end;
  color: ${COLORS.PRIMARY};
  ${(props: StyledProps) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const ActionCell = styled.div`
  display: flex;
  width: 40rem;
  justify-content: flex-end;
  ${(props: StyledProps) =>
    props.width &&
    css`
      width: ${props.width};
    `};
`;

export const Footer = styled.div`
  padding: 20rem;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 4%;
`;

export const Card = styled.div`
  max-width: 48%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${COLORS.GREY_300};
  margin-bottom: 20rem;
  justify-content: space-between;
  position: relative;
  padding: 10rem 10rem;
  padding-bottom: 20rem;
  border-radius: 5rem;

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : `none`};

  &:nth-child(2n) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -4%;
      background-color: ${COLORS.GREY_300};
      width: 1px;
      height: 80%;

      ${respondTo.bigPhone`
        display: none;
      `}
    }
  }

  ${respondTo.bigPhone`
    max-width: 100%;
  `}
`;

export const CardLeftSide = styled.div`
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const CardRightSide = styled.div`
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const HeaderCellMobile = styled.div`
  display: flex;
  text-align: left;
  padding: 0;
  min-height: 30rem;
  align-items: center;
  justify-content: flex-start;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  margin-top: 20rem;
`;

export const ActionsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const PaginationWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
