import apiClient from '../api/apiService';
import openApiClient from 'services/api/openApiService';
import {
  ICreateQuotationActionData,
  ICreateQuotationData,
  ICreateQuotationLineDTO,
  IEditQuotationLineData,
  QuotationTypeIdsEnum,
} from 'types/Quotations.types';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import i18n from 'providers/i18n/i18n';
import { Moment } from 'moment';
import { SortDirection } from 'components/Table/constants';
import { ACTIVE_FILTER } from '../../pages/Manager/QuotationsPage/constants';
import { IEditQuotationWorkingHoursLine } from 'pages/Manager/SingleQuotation/SingleQuotationProductsPage/EditQuotationWorkingHoursLineModal/types';
import { IEditOrderDetailsDTO } from 'pages/Manager/SingleQuotation/EditOrderDetailsModal/constants';
import { IEditCustomerDetailsDTO } from 'components/Modal/EditCustomerDetailsModal/constants';
import { openFileInNewTab } from 'utils/openFileInNewTab';
import { IReorderLinesRequestDTO } from 'components/DragAndDrop/DraggableLines/hooks';

const BASE_URL = '/v1/quotations/';

export const createQuotation = async (
  createQuotationData: ICreateQuotationData
): Promise<any> => {
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, createQuotationData.queryParams);
  const { data } = await apiClient.post<any>(
    fullUrl,
    createQuotationData.payload
  );

  return data.data;
};

export const getQuotations = async (
  page?: number,
  perPage?: number,
  quotationStatuses?: string,
  dateInterval?: string,
  activeFilter?: string,
  type_id?: QuotationTypeIdsEnum,
  searchBy?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string,
  selectedLabelIds?: number[]
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    quotation_statuses: quotationStatuses,
    ...(type_id && { type_id }),
    date_interval: dateInterval,
    search: searchBy,
    ...(sortBy &&
      sortDirection != SortDirection.NO_SORT && { sort_by: sortBy }),
    ...(sortDirection != SortDirection.NO_SORT && {
      sort_direction: sortDirection,
    }),
    ...(activeFilter === ACTIVE_FILTER.ACTIVE && { filter_active: true }),
    ...(activeFilter === ACTIVE_FILTER.CLOSED && { filter_closed: true }),
    ...(advancedFilters && { advanced_filters: advancedFilters }),
    ...(selectedLabelIds && { label_ids: selectedLabelIds.join(',') }),
  };
  const url = BASE_URL;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getQuotationsSummary = async (
  page?: number,
  perPage?: number,
  searchBy?: string
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    search: searchBy,
  };
  const url = `${BASE_URL}summary`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);

  return data.data;
};

export const getQuotationByQuotationId = async (id: string): Promise<any> => {
  const url = `${BASE_URL}${id}`;
  const { data } = await apiClient.get<any>(url);
  return data.data.quotation;
};

export const getQuotationInfo = async (): Promise<any> => {
  const url = `${BASE_URL}info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getQuotationSalesOrder = async (id: string): Promise<any> => {
  const url = `${BASE_URL}${id}/sales-order`;
  const { data } = await apiClient.get<any>(url);
  return data.data.sales_oder;
};

export const getQuotationQuotations = async (
  id: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${id}/quotations`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const changeStatus = async (
  id: string,
  status_id: string
): Promise<any> => {
  const url = BASE_URL + `${id}/status`;
  const { data } = await apiClient.patch<any>(url, {
    new_status_id: status_id,
  });

  return data.data;
};

export const newComment = async (
  headline: string,
  description: string,
  isInternal: boolean,
  id: string
): Promise<any> => {
  const url = BASE_URL + `${id}/comments`;
  const { data } = await apiClient.post<any>(url, {
    headline,
    description,
    is_internal: isInternal,
  });

  return data.data;
};

export const getQuotationFiles = async (
  quotationId: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    per_page: perPage,
    page: page,
  };
  const url = BASE_URL + `${quotationId}/files`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getQuotationComments = async (
  id: string,
  page: number,
  perPage: number,
  dateFrom: string,
  dateTo: string
) => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
    ...(dateFrom && { date_from: dateFrom }),
    ...(dateTo && { date_to: dateTo }),
  };

  const url = BASE_URL + `${id}/comments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get(fullUrl);

  return data.data;
};

export const uploadQuotationFile = async (
  id: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${id}/files`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const deleteQuotationFile = async (
  quotationId: string,
  quotationFileId: string
) => {
  const url = BASE_URL + `${quotationId}/files/${quotationFileId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const createSalesOrder = async (quotationId: string) => {
  const url = BASE_URL + `${quotationId}/transfer-to-sales-order`;
  const { data } = await apiClient.post<any>(url);
  return data.data;
};

export const exportQuotation = async (quotationId: string): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${quotationId}/export`,
    queryParamsObj
  );
  const { data } = await apiClient.post<any>(fullUrl, quotationId, {
    responseType: 'blob', // We need Blob object in order to download PDF
  });

  openFileInNewTab(data, true);

  return data.data;
};

export const getQuotationAppointmentsForDateInterval = async (
  id: string,
  dateFrom: Moment,
  dateTo: Moment
) => {
  const queryParamsObj = {
    date_from: `${dateFrom.format('YYYY-MM-DD')} 00:00:00`,
    date_to: `${dateTo.format('YYYY-MM-DD')} 00:00:00`,
  };

  const url = BASE_URL + `${id}/appointments`;
  const fullUrl = getUrlWithQueryParams(url, queryParamsObj);

  const { data } = await apiClient.get(fullUrl);

  return data.data;
};

export const getQuotationAppointments = async (
  id: string,
  page: number,
  perPage: number
) => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${id}/appointments`,
    queryParamsObj
  );
  const { data } = await apiClient.get(fullUrl);

  return data.data;
};

export const sendQuotationEmail = async (
  quotationId: string,
  note: string,
  files: any
) => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
  };
  const fullUrl = getUrlWithQueryParams(
    BASE_URL + `${quotationId}/email-customer`,
    queryParamsObj
  );
  const formData = new FormData();
  const defaultAttachmentIds: string[] = [];

  if (note) formData.append('note', note);

  if (files && files.length) {
    files.forEach((file: any) => {
      if (file.id) {
        defaultAttachmentIds.push(file.id);
      } else {
        formData.append('files', file);
      }
    });
  }

  if (defaultAttachmentIds.length > 0) {
    formData.append(
      'default_attachment_ids',
      JSON.stringify(defaultAttachmentIds)
    );
  }

  const { data } = await apiClient.post<any>(fullUrl, formData);
  return data.data;
};

export const getActionOptions = async (): Promise<any> => {
  const url = `${BASE_URL}actions`;
  const { data } = await apiClient.get<any>(url);
  return data.data.action_types;
};

export const createQuotationAction = async (
  quotationId: string,
  createQuotationActionData: ICreateQuotationActionData
): Promise<any> => {
  const url = `${BASE_URL}${quotationId}/actions`;
  const { data } = await apiClient.post<any>(url, createQuotationActionData);

  return data.data;
};

export const getQuotationActions = async (
  quotationId: string
): Promise<any> => {
  const url = `${BASE_URL}${quotationId}/actions`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const editQuotationLine = async (
  quotationId: string,
  quotationLineId: string,
  editQuotationLineData: IEditQuotationLineData
): Promise<any> => {
  const url = BASE_URL + `${quotationId}/lines/${quotationLineId}`;
  const { data } = await apiClient.patch<any>(url, editQuotationLineData);

  return data.data;
};

export const uploadQuotationSignature = async (
  id: string,
  fileFormData: FormData
) => {
  const headers = { 'Content-Type': 'multipart/form-data' };
  const url = BASE_URL + `${id}/signatures`;
  const { data } = await apiClient.post(url, fileFormData, { headers });

  return data.data;
};

export const getSignatureInfo = async (
  token: string,
  companyId: string
): Promise<any> => {
  const queryParamsObj = {
    token,
    company_id: companyId,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}signature-info`,
    queryParamsObj
  );
  const { data } = await openApiClient.get<any>(fullUrl);
  return data.data;
};

export const uploadCustomerSignature = async (
  file: any,
  token: string,
  companyId: string
) => {
  const queryParamsObj = {
    token,
    company_id: companyId,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}customer-signature`,
    queryParamsObj
  );
  const headers = { 'Content-Type': 'multipart/form-data' };
  const { data } = await openApiClient.post(fullUrl, file, { headers });

  return data.data;
};

export const customerExport = async (
  token: string,
  companyId: string
): Promise<any> => {
  const queryParamsObj = {
    lang: i18n.resolvedLanguage,
    token,
    company_id: companyId,
  };

  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}customer-export`,
    queryParamsObj
  );
  const { data } = await openApiClient.post<any>(fullUrl, undefined, {
    responseType: 'blob', // We need Blob object in order to download PDF
  });
  openFileInNewTab(data, true);

  return data.data;
};

export const getQuotationSignature = async (id: string) => {
  const url = BASE_URL + `${id}/signatures`;
  const { data } = await apiClient.get(url);

  return data.data;
};

export const editQuotationFile = async (
  quotationId: string,
  fileId: string,
  newFileName: string
) => {
  const url = BASE_URL + `${quotationId}/files/${fileId}`;
  const payload = {
    new_file_name: newFileName,
  };
  const { data } = await apiClient.patch(url, payload);

  return data.data;
};

export const getQuotationHistory = async (
  quotationId: string
): Promise<any> => {
  const url = `${BASE_URL}${quotationId}/status-history`;
  const { data } = await apiClient.get<any>(url);

  return data.data;
};

export const getQuotationInfoById = async (
  quotationId: string
): Promise<any> => {
  const url = `${BASE_URL}${quotationId}/info`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getQuotationAttachmentsPreview = async (
  quotationId: string
): Promise<any> => {
  const url = `${BASE_URL}${quotationId}/attachments-preview`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const getQuotationLatestEmailStatus = async (
  id: string
): Promise<any> => {
  const url = `${BASE_URL}${id}/emails/latest-status`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const editQuotationPricesDefinitive = async (
  quotationId: string,
  arePricesDefinitive: boolean
) => {
  const fullUrl =
    BASE_URL +
    `${quotationId}/prices-definitive?all_prices_definitive=${arePricesDefinitive}`;
  const { data } = await apiClient.patch(fullUrl);
  return data.data;
};

export const deleteQuotationLine = async (
  quotationId: string,
  quotationLineId: string
) => {
  const url = BASE_URL + `${quotationId}/lines/${quotationLineId}`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const createQuotationLine = async (
  quotationId: string,
  createQuotationLineData: ICreateQuotationLineDTO
): Promise<any> => {
  const url = BASE_URL + `${quotationId}/lines`;
  const { data } = await apiClient.post<any>(url, createQuotationLineData);

  return data.data;
};

export const editQuotationWorkingHoursLine = async (
  quotationId: string,
  quotationLineId: string,
  workingHoursLineDTO: IEditQuotationWorkingHoursLine
) => {
  const url =
    BASE_URL + `${quotationId}/lines/${quotationLineId}/working_hours`;
  const { data } = await apiClient.patch(url, workingHoursLineDTO);
  return data.data;
};

export const editQuotationLineCustomerDetails = async (
  quotationId: string,
  customerDetailsDTO: IEditCustomerDetailsDTO
) => {
  const url = BASE_URL + `${quotationId}/customer-snapshot`;
  const { data } = await apiClient.put(url, customerDetailsDTO);
  return data.data;
};

export const editOrderDetails = async (
  quotationId: string,
  editDetailsDTO: IEditOrderDetailsDTO
) => {
  const url = BASE_URL + `${quotationId}/details`;
  const { data } = await apiClient.patch(url, editDetailsDTO);

  return data.data;
};

export const editQuotationDownpayment = async (
  quotationId: string,
  editDetailsDTO: IEditOrderDetailsDTO
) => {
  const url = BASE_URL + `${quotationId}/details`;
  const { data } = await apiClient.patch(url, editDetailsDTO);

  return data.data;
};

export const getQuotationReportsCurrent = async (
  id: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${id}/reports`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const getQuotationReportsHistorical = async (
  id: string,
  page: number,
  perPage: number
): Promise<any> => {
  const queryParamsObj = {
    page: page,
    per_page: perPage,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}${id}/historical-reports`,
    queryParamsObj
  );
  const { data } = await apiClient.get<any>(fullUrl);
  return data.data;
};

export const changeQuotationType = async (
  quotationId: string,
  quotationTypeId: QuotationTypeIdsEnum
): Promise<any> => {
  const url = BASE_URL + `${quotationId}/type`;
  const { data } = await apiClient.patch<any>(url, {
    new_type_id: quotationTypeId,
  });

  return data.data;
};

export const getQuotationActiveAppointmentInfo = async (
  id: string
): Promise<any> => {
  const url = `${BASE_URL}${id}/has_appointment_in_progress`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};

export const reorderQuotationLines = async (
  quotationId: number,
  dto: IReorderLinesRequestDTO
) => {
  const url = BASE_URL + `${quotationId}/reorder-lines`;
  const { data } = await apiClient.patch(url, dto);
  return data.data;
};

export const cancelRecurringQuotation = async (quotationId: string) => {
  const url = BASE_URL + `${quotationId}/recurring`;
  const { data } = await apiClient.delete<any>(url);
  return data.data;
};

export const getEmailTemplateQuotationInfo = async (
  token: string,
  companyId: string
): Promise<any> => {
  const queryParamsObj = {
    token,
    company_id: companyId,
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}quotation-info`,
    queryParamsObj
  );
  const { data } = await openApiClient.get<any>(fullUrl);
  return data.data;
};

export const requestAppointmentFromEmail = async (
  token: string,
  companyId: string,
  optionalMessage: string
): Promise<any> => {
  const queryParamsObj = {
    token,
    company_id: companyId,
    ...(optionalMessage && { optional_message: optionalMessage }),
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}request-appointment`,
    queryParamsObj
  );
  const { data } = await openApiClient.put<any>(fullUrl);
  return data.data;
};

export const declineOfferFromEmail = async (
  token: string,
  companyId: string,
  optionalMessage: string
): Promise<any> => {
  const queryParamsObj = {
    token,
    company_id: companyId,
    ...(optionalMessage && { optional_message: optionalMessage }),
  };
  const fullUrl = getUrlWithQueryParams(
    `${BASE_URL}decline-offer`,
    queryParamsObj
  );
  const { data } = await openApiClient.put<any>(fullUrl);
  return data.data;
};

export const editQuotationGeneralDiscount = async (
  quotationId: string,
  generalDiscount: number | null
) => {
  const url = BASE_URL + `${quotationId}/default-line-discount`;
  const { data } = await apiClient.patch(url, {
    discount_amount: generalDiscount,
  });
};

export const getQuotationLatestAction = async (id: string): Promise<any> => {
  const url = `${BASE_URL}${id}/latest-action`;
  const { data } = await apiClient.get<any>(url);
  return data.data;
};
