import {
  borderRadiusSm,
  COLORS,
  marginMd,
  marginXs,
  paddingMd,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isRead: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  background-color: ${COLORS.WHITE};
  padding: ${paddingMd};
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};
  ${(props: StyledProps) =>
    props.isRead
      ? css`
          border: 1rem solid transparent;
        `
      : css`
          border: 1rem solid ${COLORS.PRIMARY};
        `}

  ${respondTo.bigPhone`
    margin-bottom: 20rem;
    padding: 20rem;
  `}
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapperMobile = styled.div`
  display: none;

  ${respondTo.bigPhone`
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 20rem;
      gap: 20rem;
  `}
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  ${respondTo.bigPhone`
    justify-content: flex-end;
  `}
`;

export const DateLabel = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  color: ${COLORS.GREY_1000};
`;

export const DescriptionLabel = styled.label`
  margin-top: ${marginXs};
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;

export const RowGap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: normal;
  align-items: center;
  gap: 30rem;

  ${respondTo.bigPhone`
      display: none;
  `}
`;
