import { Switch } from '@mui/material';
import Tooltip from 'components/Tooltip/Tooltip';
import { PurchaseOrderStatus } from 'pages/Manager/SinglePurchaseOrder/constants';
import { useTranslation } from 'react-i18next';
import { IPurchaseOrder, IPurchaseOrderLine } from 'types/PurchaseOrders.types';
import { useEditPurchaseOrderLineStatus } from '../../hooks';
import { getTooltipText } from './helpers';
import { ReceivedLabel, SwitchWrapper } from './StatusReceivedSwitch.styled';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IStatusReceivedSwitchProps {
  purchaseOrder: IPurchaseOrder;
  line: IPurchaseOrderLine;
}

export const StatusReceivedSwitch = ({
  line,
  purchaseOrder,
}: IStatusReceivedSwitchProps) => {
  const { t } = useTranslation();
  const isReceived = line?.is_received;
  const { isPhone } = useBreakpointFlag();

  const { mutate: editStatus, isLoading } = useEditPurchaseOrderLineStatus(
    purchaseOrder?.id,
    line?.id
  );

  const isStatusValid =
    purchaseOrder?.status?.name !== PurchaseOrderStatus.CANCELLED;

  return (
    <SwitchWrapper>
      <ReceivedLabel isReceived={isReceived}>{t('Received')}</ReceivedLabel>
      <Tooltip content={getTooltipText(t, isReceived, isStatusValid)}>
        <Switch
          disabled={isLoading || (!isReceived && !isStatusValid)}
          checked={isReceived}
          size={isPhone ? 'small' : 'medium'}
          onChange={() => {
            editStatus({
              is_received: !isReceived,
            });
          }}
        />
      </Tooltip>
    </SwitchWrapper>
  );
};
