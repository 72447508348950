import { IViewProductProps, ViewProductType } from './type';

export const ViewProductModalFormatter = (
  data: any,
  type: string
): IViewProductProps => {
  switch (type) {
    case ViewProductType.PURCHASE_ORDER_LINE:
      return formatPurchaseOrderLine(data);
    case ViewProductType.PURCHASE_ORDER_LINE_FORM_DTO:
      return formatPurchaseOrder(data);
    case ViewProductType.SALES_ORDER:
    case ViewProductType.PENDING_INVOICE:
      return formatPendingInvoiceOrSalesOrder(data);

    default:
      return data;
  }
};

const formatPurchaseOrder = (data: any): IViewProductProps => {
  const formattedData: IViewProductProps = {
    product: data?.product,
    quantity: data?.quantity,
    placement: data?.placement,
    status: data?.status,
    supplier: data?.supplier,
    salesPrice: data?.salesPrice,
    totalDiscount: data?.totalDiscount,
    totalPurchaseCost: data?.totalPurchaseCost,
  };
  return formattedData;
};

const formatPendingInvoiceOrSalesOrder = (data: any): IViewProductProps => {
  const formattedData: IViewProductProps = {
    product: data?.product,
    quantity: data?.quantity,
    placement: data?.placement,
    status: data?.status?.name,
    supplier: data?.supplier.company_name,
    salesPrice: data?.product_sales_price,
    totalDiscount: data?.total_discount_amount,
    totalPurchaseCost: data?.product_purchase_price,
    purchaseOrder: data?.purchase_order,
  };
  return formattedData;
};

const formatPurchaseOrderLine = (data: any): IViewProductProps => {
  const formattedData: IViewProductProps = {
    product: {
      ...data?.product,
      logyx_configuration_intent: { data: data?.product?.logyx_attributes }, // Mocking intent object
    },
    quantity: data?.quantity,
    placement: data?.sales_order_line?.placement,
    status: data?.sales_order_line?.status.name,
    supplier: data?.supplier.company_name,
    salesPrice: data?.sales_order_line?.product_sales_price,
    totalDiscount: data?.sales_order_line?.total_discount_amount,
    totalPurchaseCost: data?.sales_order_line?.product_purchase_price,
  };
  return formattedData;
};
