import {
  NoContentLabel,
  CommentsContainer,
  RightAlign,
  DatePickerWrap,
} from './SingleQuotationCommentsPage.styled';
import { useCallback, useRef, useState } from 'react';
import { useGetQuotationComments } from '../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import CommentCard from '../../../../components/Cards/CommentCard/CommentCard';
import { QUOTATION_COMMENTS_PER_PAGE } from './constants';
import { useTranslation } from 'react-i18next';
import { SingleQuotationRoutes } from 'navigation/SingleQuotation/SingleQuotation.routes';
import Button from 'components/Button/Button';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { CustomDateRangePicker } from 'components/CustomDateRangePicker/CustomDateRangePicker';
import { COLORS } from 'assets/styled';
import { Plus } from '@phosphor-icons/react';

const SingleQuotationCommentsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  const { fetchNextPage, isFetching, isFetched, data, hasNextPage } =
    useGetQuotationComments(
      QUOTATION_COMMENTS_PER_PAGE,
      id!,
      startDate,
      endDate
    );

  const dataRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const onScroll = useCallback(async () => {
    const dataEl = dataRef.current;
    if (!dataEl) {
      return;
    }
    if (
      !(
        dataEl.offsetHeight + Math.round(dataEl.scrollTop) >=
        dataEl.scrollHeight
      )
    ) {
      return;
    }
    hasNextPage && fetchNextPage();
  }, [dataRef, dataRef.current, hasNextPage]);

  const { steps } = useRunTour(
    GuidePages.SINGLE_QUOTATION_COMMENTS,
    id,
    isFetched
  );

  const comments = data?.pages.map((page) => page.comments).flat();
  if (!comments?.length) {
    return (
      <CommentsContainer
        onScroll={onScroll}
        ref={dataRef}
        className="single-quotation-step-7"
      >
        <RightAlign>
          <DatePickerWrap>
            <CustomDateRangePicker
              setStartDate={setStartDate}
              setEndDate={setEndDate}
            />
          </DatePickerWrap>

          <Button
            label={t('Add another comment')}
            link
            icon={Plus}
            sizeIcon={20}
            weightIcon="regular"
            colorIcon={COLORS.PRIMARY}
            onClick={() =>
              navigate(
                `/quotations/${id!}/${
                  SingleQuotationRoutes.SingleQuotationNewComment.path
                }`
              )
            }
            fontSize="18rem"
          />
        </RightAlign>
        {!isFetching && (
          <NoContentLabel>
            {t('There are currently no comments on this Quotation')}
          </NoContentLabel>
        )}
        <IntroductionGuide steps={steps} />
      </CommentsContainer>
    );
  }

  return (
    <CommentsContainer
      onScroll={onScroll}
      ref={dataRef}
      className="single-quotation-step-7"
    >
      <RightAlign>
        <DatePickerWrap>
          <CustomDateRangePicker
            setStartDate={setStartDate}
            setEndDate={setEndDate}
          />
        </DatePickerWrap>
        <Button
          label={t('Add another comment')}
          link
          icon={Plus}
          sizeIcon={20}
          weightIcon="regular"
          colorIcon={COLORS.PRIMARY}
          onClick={() =>
            navigate(
              `/quotations/${id!}/${
                SingleQuotationRoutes.SingleQuotationNewComment.path
              }`
            )
          }
          fontSize="18rem"
        />
      </RightAlign>

      {comments.map((item: any, index: number) => {
        return (
          <CommentCard
            key={`${index}`}
            title={item.headline}
            name={
              item.customer
                ? `${t('Customer')} ${item.customer.name} ${
                    item.customer.last_name
                  }`
                : `${item.user?.name} ${item.user?.last_name}`
            }
            type={item.is_internal ? t('Internal') : t('External')}
            date={item.created_at}
            description={item.description}
            imageUrl={item.user.image_url}
          />
        );
      })}
      <IntroductionGuide steps={steps} />
    </CommentsContainer>
  );
};

export default SingleQuotationCommentsPage;
