import {
  Container,
  TabContainer,
  Tab,
  CreateNewQuotationLabel,
  ContentTabWrapper,
} from './NewQuotationPage.styled';
import { useState } from 'react';
import CustomerTab from './CustomerTab/CustomerTab';
import ProductTab from './ProductTab/ProductTab';
import OrderTab from './OrderTab/OrderTab';
import { CreateQuotationTab, IGeneralDiscountInfo } from './constants';
import { ICreateQuotationCustomerDetails } from './CustomerTab/constants';
import { ICreateQuotationProductFormDTO } from './ProductTab/constants';
import {
  useAddNavigationCheckAfterProductIsAdded,
  useCreateQuotation,
  useGetDefaultPaymentCondition,
  useGetPaymentConditionById,
  useGetPrepopulateQuotationData,
  useGetPrepopulateQuotationDataForCustomer,
} from './hooks';
import OverviewTab from './OverviewTab/OverviewTab';
import { ICreateQuotationOrderDetails } from './OrderTab/constants';
import {
  ICreateQuotationPayload,
  ICreateQuotationLineDTO,
  ICreateQuotationQueryParamsForCustomer,
  QuotationTypeIdsEnum,
  ICreateQuotationCustomerData,
  ICreateQuotationAddressData,
} from 'types/Quotations.types';
import moment from 'moment';
import {
  getInitialCustomerDetailsData,
  getInitialOrderDetailsData,
  getInitialProductsData,
  calculateInitialDownpayment,
  getPaymentConditionText,
  getDownPaymentConditionText,
} from './helpers';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import { ICountry } from 'store/Common/types';
import { useTranslation } from 'react-i18next';
import { isNumeric } from 'utils/validation';
import { calculateSalesPriceWithDiscount } from './OrderTab/helpers';
import { ICompanySettings } from 'types/Company.types';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { LeavePageModal } from 'components/Modal/LeavePageModal/LeavePageModal';
import { useSocketConnection } from 'providers/SocketIOProvider/SocketIOProvider';
import { ILabel } from 'types/EntityLabel.types';
import { IPaymentCondition } from 'types/PaymentCondition.types';

const NewQuotationPage = () => {
  const { t } = useTranslation();
  const prepopulateQuotationData = useGetPrepopulateQuotationData();
  const prepopulateQuotationDataForCustomer =
    useGetPrepopulateQuotationDataForCustomer();

  const [queryParamsForCreatingCustomer, setQueryParamsForCreatingCustomer] =
    useState<ICreateQuotationQueryParamsForCustomer>({
      force_create_customer: false,
    });

  const [activeTab, setActiveTab] = useState<string>(
    CreateQuotationTab.CUSTOMER_DETAILS
  );

  const initialCountry: ICountry = useGetInitialCountry();

  const [customerDetails, setCustomerDetails] =
    useState<ICreateQuotationCustomerDetails>(
      getInitialCustomerDetailsData(
        prepopulateQuotationData,
        initialCountry.cca2,
        undefined,
        prepopulateQuotationDataForCustomer
      )
    );

  const [generalDiscountInfo, setGeneralDiscountInfo] =
    useState<IGeneralDiscountInfo>({
      value: prepopulateQuotationData?.default_line_discount || '',
      isAppliedToAllLines: !!prepopulateQuotationData?.default_line_discount,
    });

  const [productFormDtos, setProductFormDtos] = useState<
    ICreateQuotationProductFormDTO[]
  >(getInitialProductsData(prepopulateQuotationData));

  const companySettings: ICompanySettings = useSelector(
    (state: IRootReducerState) => state.companyInfo.settings
  );

  const [orderDetails, setOrderDetails] =
    useState<ICreateQuotationOrderDetails>(
      getInitialOrderDetailsData(prepopulateQuotationData, companySettings)
    );

  useSocketConnection();

  const { mutate: createQuotation, data, status } = useCreateQuotation();

  const {
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    setCheckNavigation,
  } = useAddNavigationCheckAfterProductIsAdded(productFormDtos, status);

  // Fetch the payment condition if provided, else fetch the default one
  const { data: prepopulatedPaymentCondition } = useGetPaymentConditionById(
    prepopulateQuotationData?.payment_condition_id
  );
  const { data: prepopulatedDefaultPaymentCondition } =
    useGetDefaultPaymentCondition(
      !prepopulateQuotationData?.payment_condition_id
    );

  const handleCreateQuotation = () => {
    const address: ICreateQuotationAddressData = {
      country: customerDetails.country,
      street: customerDetails.street,
      zip_code: customerDetails.code,
      city: customerDetails.city,
      house_number: customerDetails.house_number,
    };
    const customer: ICreateQuotationCustomerData = {
      name: customerDetails.name,
      last_name: customerDetails.last_name,
      phone: customerDetails.phone,
      email: customerDetails.email,
      address: address,
      gender: customerDetails.gender ? customerDetails.gender : null,
      title: customerDetails.title,
      company_name: customerDetails.company_name || null,
      vat_number: customerDetails.vat_number || null,
      alternative_contact_id: customerDetails.alternativeContact?.id || null,
      alternative_invoice_email:
        customerDetails.alternative_invoice_email || null,
    };

    const quotationLines: ICreateQuotationLineDTO[] = productFormDtos.map(
      (productFormDto: ICreateQuotationProductFormDTO) => {
        const productAttributes = {};
        for (const attributeKey of Object.keys(productFormDto.attributes)) {
          productAttributes[attributeKey] =
            productFormDto.attributes[attributeKey];
        }

        return {
          ...(!productFormDto.isStockItem && {
            product_id: productFormDto.product.id,
          }),
          ...(!productFormDto.isStockItem && {
            measurement_check: productFormDto.measurementCheck,
          }),
          quantity: Number(productFormDto.quantity),
          product_attributes: productAttributes,
          description: productFormDto.description,
          ...(productFormDto.logyxConfigurationIntent && {
            logyx_configuration_intent_uuid:
              productFormDto.logyxConfigurationIntent.uuid,
          }),
          placement: productFormDto.placement,
          discount: {
            type: productFormDto.discountType,
            amount: +productFormDto.discount,
          },
          supplier_id: productFormDto.supplier.id,
          ...(isNumeric(productFormDto.purchasePrice) && {
            product_purchase_price: Number(productFormDto.purchasePrice),
          }),
          product_sales_price: Number(productFormDto.salesPrice),
          is_stock_item: productFormDto.isStockItem,
          stock_item_id: productFormDto.stockItemId,
        };
      }
    );
    const workingHourLine: ICreateQuotationLineDTO = {
      quantity: Number(orderDetails.workingHours),
      working_hours_line: true,
      working_hours_rate: orderDetails.workingHoursRate.toString(),
      product_attributes: {},
      discount: {
        type: orderDetails.workingHoursDiscountType,
        amount: +orderDetails.workingHoursDiscount,
      },
    };
    if (
      Number(workingHourLine.quantity) !== 0 &&
      Number(workingHourLine.working_hours_rate) !== 0
    ) {
      quotationLines.push(workingHourLine);
    }

    const createQuotationPayload: ICreateQuotationPayload = {
      type_id: orderDetails.isDraft
        ? QuotationTypeIdsEnum.DRAFT
        : QuotationTypeIdsEnum.DEFINITIVE,
      valid_to: moment()
        .endOf('day')
        .add(orderDetails?.daysValid, 'day')
        .toISOString(),
      description: 'Quotation description',
      ...(orderDetails.paymentConditionText && {
        payment_condition: orderDetails.paymentConditionText,
      }),
      ...(orderDetails.downpaymentConditionText && {
        downpayment_condition: orderDetails.downpaymentConditionText,
      }),
      ...(orderDetails.paymentConditionId && {
        payment_condition_id: Number(orderDetails.paymentConditionId),
      }),
      reference: orderDetails.reference,
      quotation_lines: quotationLines,
      discount: {
        type: orderDetails.discountType,
        amount: +orderDetails.discount,
      },
      customer: customer,
      ...(customerDetails.existingCustomer?.id && {
        // customer_id is sent when an existing customer is selected
        customer_id: customerDetails.existingCustomer.id.toString(),
      }),
      ...(customerDetails.isShippingDataFormShown && {
        shipping_data: {
          first_name: customerDetails.shipping_data?.first_name || '',
          last_name: customerDetails.shipping_data?.last_name || '',
          phone: customerDetails.shipping_data?.phone || '',
          country: customerDetails.shipping_data?.country || '',
          street: customerDetails.shipping_data?.street || '',
          city: customerDetails.shipping_data?.city || '',
          zip_code: customerDetails.shipping_data?.zip_code || '',
          house_number: customerDetails.shipping_data?.house_number || '',
          company_name: customerDetails.shipping_data?.company_name
            ? customerDetails.shipping_data?.company_name
            : null,
          email: customerDetails.shipping_data?.email || '',
          title: customerDetails.shipping_data?.title || '',
        },
      }),
      ...(orderDetails.recurringTypeId && {
        recurring_type_id: Number(orderDetails.recurringTypeId),
      }),
      ...(orderDetails.recurringPeriodId && {
        recurring_period_id: Number(orderDetails.recurringPeriodId),
      }),
      ...(Number(generalDiscountInfo.value) &&
        generalDiscountInfo.isAppliedToAllLines && {
          default_line_discount: Number(generalDiscountInfo.value),
        }),
    };
    if (prepopulateQuotationData) {
      createQuotationPayload.parent_quotation_id = prepopulateQuotationData.id;
    }

    if (orderDetails.downPayment && +orderDetails.downPayment != 0)
      createQuotationPayload.downpayment = +orderDetails.downPayment;

    if (orderDetails.labels?.length !== 0)
      createQuotationPayload.label_ids = orderDetails.labels?.map(
        (label: ILabel) => label.id
      );

    if (customerDetails.shipping_data)
      createQuotationPayload.shipping_data = {
        ...customerDetails.shipping_data,
        company_name: customerDetails.shipping_data.company_name
          ? customerDetails.shipping_data.company_name
          : null,
      };

    createQuotation({
      payload: createQuotationPayload,
      queryParams: queryParamsForCreatingCustomer,
    });
  };

  const handleActiveTab = () => {
    if (activeTab === CreateQuotationTab.CUSTOMER_DETAILS) {
      return (
        <TabContainer>
          <Tab active>1. {t('Customer details')}</Tab>
          <Tab>2. {t('Products')}</Tab>
          <Tab>3. {t('Order details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateQuotationTab.PRODUCTS) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Customer details')}</Tab>
          <Tab active>2. {t('Products')}</Tab>
          <Tab>3. {t('Order details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateQuotationTab.ORDER_DETAILS) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Customer details')}</Tab>
          <Tab finished>2. {t('Products')}</Tab>
          <Tab active>3. {t('Order details')}</Tab>
        </TabContainer>
      );
    } else {
      return (
        <TabContainer>
          <Tab finished>1. {t('Customer details')}</Tab>
          <Tab finished>2. {t('Products')}</Tab>
          <Tab finished>3. {t('Order details')}</Tab>
        </TabContainer>
      );
    }
  };

  return (
    <Container>
      <CreateNewQuotationLabel>
        {prepopulateQuotationData
          ? t('Edit Quotation')
          : t('Create New Quotation')}
      </CreateNewQuotationLabel>
      {handleActiveTab()}
      <ContentTabWrapper>
        {activeTab === CreateQuotationTab.CUSTOMER_DETAILS && (
          <CustomerTab
            onForward={(
              customerQueryParams: ICreateQuotationQueryParamsForCustomer
            ) => {
              setQueryParamsForCreatingCustomer(customerQueryParams);
              setActiveTab(CreateQuotationTab.PRODUCTS);
            }}
            customerDetails={customerDetails}
            setCustomerDetails={setCustomerDetails}
            resetCustomerTabForm={(showEmptyValues: boolean) => {
              setCustomerDetails(
                getInitialCustomerDetailsData(
                  prepopulateQuotationData,
                  initialCountry.cca2,
                  showEmptyValues
                )
              );
            }}
            isEditQuotation={!!prepopulateQuotationData}
          />
        )}
        {activeTab === CreateQuotationTab.PRODUCTS && (
          <ProductTab
            onBack={() => setActiveTab(CreateQuotationTab.CUSTOMER_DETAILS)}
            onForward={(
              productFormDtosData: ICreateQuotationProductFormDTO[]
            ) => {
              const initialTotalSalesPrice = calculateSalesPriceWithDiscount(
                orderDetails,
                productFormDtosData
              );
              const paymentCondition: IPaymentCondition | undefined =
                prepopulatedPaymentCondition ||
                prepopulatedDefaultPaymentCondition;
              const paymentConditionText = getPaymentConditionText(
                prepopulateQuotationData,
                paymentCondition
              );
              const downpaymentConditionText = getDownPaymentConditionText(
                prepopulateQuotationData,
                paymentCondition
              );
              const initialDownpayment = calculateInitialDownpayment(
                initialTotalSalesPrice,
                paymentCondition?.downpayment_threshold,
                paymentCondition?.downpayment_percentage
              ).toFixed(2);
              setOrderDetails({
                ...orderDetails,
                downPayment: initialDownpayment?.toString(),
                paymentConditionText: paymentConditionText,
                downpaymentConditionText: downpaymentConditionText,
              });
              setActiveTab(CreateQuotationTab.ORDER_DETAILS);
            }}
            productFormDtos={productFormDtos}
            setProductFormDtos={setProductFormDtos}
            hideMeasurementsToggle={prepopulateQuotationData}
            generalDiscountInfo={generalDiscountInfo}
            setGeneralDiscountInfo={setGeneralDiscountInfo}
          />
        )}
        {activeTab === CreateQuotationTab.ORDER_DETAILS && (
          <OrderTab
            onBack={() => setActiveTab(CreateQuotationTab.PRODUCTS)}
            onForward={() => setActiveTab(CreateQuotationTab.OVERVIEW)}
            setOrderDetails={setOrderDetails}
            orderDetails={orderDetails}
            productFormDtos={productFormDtos}
            prepopulatedPaymentCondition={
              prepopulatedPaymentCondition ||
              prepopulatedDefaultPaymentCondition
            }
          />
        )}
        {activeTab === CreateQuotationTab.OVERVIEW && (
          <OverviewTab
            onBack={() => setActiveTab(CreateQuotationTab.ORDER_DETAILS)}
            onForward={() => handleCreateQuotation()}
            customerDetails={customerDetails}
            productFormDtos={productFormDtos}
            orderDetails={orderDetails}
            status={status}
            newlyCreatedQuotationId={data?.quotation?.id}
          />
        )}
      </ContentTabWrapper>
      <LeavePageModal
        isOpen={showPrompt}
        setIsOpen={setCheckNavigation}
        onYes={() => confirmNavigation()}
        onNo={() => cancelNavigation()}
        title={''}
        description={t(
          'Are you sure you want to leave the quotation creation wizard? This will discard the progress.'
        )}
      />
    </Container>
  );
};

export default NewQuotationPage;
