import { COLORS, marginLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 15rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const StyledLink = styled(Link)``;

export const Logo = styled.img`
  width: 179rem;
  height: 261rem;
  margin-bottom: ${marginLg};

  @media screen and (max-width: 1440px) {
    margin-bottom: 30rem;
  }
`;

export const Headline = styled.h2`
  color: ${COLORS.BLACK};
  margin-bottom: 20rem;
`;

export const PasswordInputWrapper = styled.div`
  position: relative;
`;

export const EyeWrapper = styled.div`
  user-select: none;
  position: absolute;
  top: 50%;
  right: 15rem;
  transform: translateY(-50%);
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;
