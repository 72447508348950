import { H4 } from 'assets/styled';
import { Field, Formik } from 'formik';
import {
  ColumnContainer,
  ColumnContainerSelect,
  Container,
  Heading,
  HorizontalLine,
  RowSpaceBetween,
  StyledCustomSelect,
} from '../EditCustomerDetailsModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Option } from 'components/Select/type';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { useTranslation } from 'react-i18next';
import { IEditCustomerShipToDetails } from '../constants';
import { editCustomerDetailsShipToTabSchema } from '../validation';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import { Info } from '@phosphor-icons/react';

interface IShipToTabProps {
  pwId?: string;
  shipToDetails: IEditCustomerShipToDetails;
  setShipToDetails: (shipToDetails: IEditCustomerShipToDetails) => void;
  setIsValid: (isValid: boolean) => void;
}
export const ShipToTab = ({
  shipToDetails,
  setShipToDetails,
  setIsValid,
  pwId,
}: IShipToTabProps) => {
  const { t } = useTranslation();
  const countryOptions: Option[] = useGetCountryOptions();
  const { isSmallTablet } = useBreakpointFlag();
  const titlesOptions = useGetTitleOptions();

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={(values) => {
          //
        }}
        initialValues={shipToDetails}
        validationSchema={editCustomerDetailsShipToTabSchema}
      >
        {({ values, setFieldValue, handleBlur, isValid, errors }) => {
          const handleBlurFunction = (e: any, name: string) => {
            const value = e.target.value.trim();
            setFieldValue(name, value);
            setShipToDetails(values);
            handleBlur(e);
            setIsValid(isValid);
          };
          return (
            <Container>
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-first-name-title`}>
                      {t('First name').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-first-name-input-field`}
                    errorMessage={errors['name'] ? errors['name'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'name');
                    }}
                    height={'43rem'}
                    name="name"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-last-name-title`}>
                      {t('Last name').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-last-name-input-field`}
                    errorMessage={
                      errors['last_name'] ? errors['last_name'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'last_name');
                    }}
                    height={'43rem'}
                    name="last_name"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainerSelect>
                  <Heading>
                    <H4 data-testid={`${pwId}-title-label`}>
                      {t('Title').toUpperCase()}
                    </H4>
                  </Heading>
                  <Field
                    pwId={`${pwId}-title`}
                    errorMessage={errors['title'] ? errors['title'] : ''}
                    disabled={false}
                    name="title"
                    options={titlesOptions}
                    component={StyledCustomSelect}
                    placeholder={t('Title')}
                    isMulti={false}
                    onSelect={(option: Option) => {
                      setShipToDetails({ ...values, title: option.value });
                      setIsValid(isValid);
                    }}
                  />
                </ColumnContainerSelect>

                <ColumnContainerSelect>
                  <Heading>
                    <H4 data-testid={`${pwId}-country-code-title`}>
                      {t('Country code').toUpperCase()}
                    </H4>
                  </Heading>
                  <Field
                    pwId="country-field"
                    errorMessage={errors['country'] ? errors['country'] : ''}
                    disabled={false}
                    height={'41rem'}
                    name="country"
                    width="300rem"
                    options={countryOptions}
                    component={StyledCustomSelect}
                    isMulti={false}
                    onSelect={(option: Option) => {
                      setShipToDetails({ ...values, country: option.value });
                      setIsValid(isValid);
                    }}
                  />
                </ColumnContainerSelect>
              </RowSpaceBetween>

              <HorizontalLine />
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-zip-code-title`}>
                      {t('Zip code').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-zip-code-input-field`}
                    errorMessage={errors['code'] ? errors['code'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'code');
                    }}
                    height={'43rem'}
                    name="code"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>

                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-house-number-title`}>
                      {t('House number').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-house-number-input-field`}
                    errorMessage={
                      errors['house_number'] ? errors['house_number'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'house_number');
                    }}
                    height={'43rem'}
                    name="house_number"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-city-title`}>
                      {t('City').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-city-input-field`}
                    errorMessage={errors['city'] ? errors['city'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'city');
                    }}
                    height={'43rem'}
                    name="city"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-street-title`}>
                      {t('Street').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-street-input-field`}
                    errorMessage={errors['street'] ? errors['street'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'street');
                    }}
                    height={'43rem'}
                    name="street"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-email-title`}>{t('E-MAIL')}</H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-email-input-field`}
                    errorMessage={errors['email'] ? errors['email'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'email');
                    }}
                    height={'43rem'}
                    name="email"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                    icon={Info}
                    iconSize={20}
                    iconTooltip={t('Valid format: example@mail.com')}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-phone-number-title`}>
                      {t('Phone number').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-phone-number-input-field`}
                    errorMessage={errors['phone'] ? errors['phone'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'phone');
                    }}
                    height={'43rem'}
                    name="phone"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-company-name-title`}>
                      {t('Company name').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-company-name-input-field`}
                    errorMessage={
                      errors['company_name'] ? errors['company_name'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'company_name');
                    }}
                    height={'43rem'}
                    name="company_name"
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '300rem',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>
            </Container>
          );
        }}
      </Formik>
    </div>
  );
};
