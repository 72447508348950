import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { useTranslation } from 'react-i18next';
import { IPurchaseOrderFormDTO, IPurchaseOrderLineFormDTO } from '../constants';
import {
  PurchaseOrderLineContainer,
  PurchaseOrderLinesWrapper,
  SupplierCompanyNameLabel,
  Container,
  FormHeaderContainer,
  HeaderLabel,
  FormCell,
  FormLabel,
  StockItemAbsoluteInfoTooltipWrapper,
  IsLogyxProductAbsoluteWrapper,
  Line,
  LabelSwitchWrapper,
  SwitchSpan,
  LabelsRow,
  LabelWrapper,
  AddLabelDropdownWrapper,
  LabelsContainer,
  CardWrapper,
  Card,
  CardLeftSide,
  HeaderCell,
  CardRightSide,
  MobileAction,
  ActionCell,
  Header,
  SelectAll,
} from './PurchaseOrderForm.styled';
import {
  createPurchaseOrderSchema,
  getFormikErrorMesssage,
} from './validation';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { ReactComponent as LogyxLogo } from 'assets/icons/logyxLogoSmall.svg';
import { FlexColumn } from '../Overview/Overview.styled';
import { getLineColorBasedOnSelectedLines } from './helper';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { Info } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Switch from '@mui/material/Switch';
import { ILabel, LabelEntityTypeId } from 'types/EntityLabel.types';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import { AddLabelDropdown } from 'components/AddLabelDropdown/AddLabelDropdown';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IPurchaseOrderFormProps {
  purchaseOrderForm: IPurchaseOrderFormDTO;
  handlePurchasePriceOnChange: any;
  handleSelect: (supplierId: number, productId: number) => void;
  handleSelectAll: (supplierId: number) => void;
  handleAddLabel: (supplierId: number, newLabel: ILabel) => void;
  handleDeleteLabel: (supplierId: number, labelId: number) => void;
  handleCheckTransferLabels: (supplierId: number) => void;
  areAllFieldsTouched: boolean;
  openViewModal: (isOpen: boolean) => void;
  setSelectedLine: (line: IPurchaseOrderLineFormDTO) => void;
  transferSwitchDisabled: boolean;
}

const PurchaseOrderForm = ({
  purchaseOrderForm,
  handlePurchasePriceOnChange,
  handleSelect,
  handleSelectAll,
  handleAddLabel,
  handleDeleteLabel,
  handleCheckTransferLabels,
  areAllFieldsTouched,
  openViewModal,
  setSelectedLine,
  transferSwitchDisabled,
}: IPurchaseOrderFormProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const renderMobileForm = ({
    handleBlur,
    setFieldValue,
    values,
    errors,
  }: any) => {
    return (
      <Form>
        <FieldArray
          name="purchaseOrderLines"
          render={() => (
            <>
              <Header>
                <SupplierCompanyNameLabel>
                  {purchaseOrderForm.supplier.company_name}
                </SupplierCompanyNameLabel>
                <SelectAll
                  onClick={() =>
                    handleSelectAll(Number(purchaseOrderForm.supplier.id))
                  }
                >
                  {t('Select all')}
                </SelectAll>
              </Header>
              <CardWrapper>
                {purchaseOrderForm.purchaseOrderLines.map(
                  (purchaseOrderLine, purchaseOrderLineIndex) => {
                    const isSelected = purchaseOrderLine.isSelected;
                    return (
                      <Card key={purchaseOrderLineIndex} $selected={isSelected}>
                        <CardLeftSide>
                          <HeaderCell>
                            <HeaderLabel>{t('Product')}</HeaderLabel>
                          </HeaderCell>
                          <HeaderCell>
                            <HeaderLabel>{t('Type')}</HeaderLabel>
                          </HeaderCell>
                          <HeaderCell>
                            <HeaderLabel>{t('Quantity')}</HeaderLabel>
                          </HeaderCell>
                          <HeaderCell>
                            <HeaderLabel>{t('In stock')}</HeaderLabel>
                          </HeaderCell>
                          <HeaderCell>
                            <HeaderLabel>{t('Purchase price')}</HeaderLabel>
                          </HeaderCell>
                        </CardLeftSide>
                        <CardRightSide>
                          {purchaseOrderLine.product
                            .logyx_configuration_intent ? (
                            <IsLogyxProductAbsoluteWrapper>
                              <Icon
                                svg={LogyxLogo}
                                size={21}
                                wrapperStyle={{ cursor: 'default' }}
                              />
                            </IsLogyxProductAbsoluteWrapper>
                          ) : null}
                          {purchaseOrderLine.isStockItem ? (
                            <StockItemAbsoluteInfoTooltipWrapper>
                              <Tooltip
                                content={
                                  purchaseOrderLine.isSelected
                                    ? t(
                                        'This item will be added to the purchase order'
                                      )
                                    : t('This item will be taken from stock')
                                }
                              >
                                <Icon
                                  svg={Info}
                                  size={21}
                                  color={COLORS.PRIMARY}
                                />
                              </Tooltip>
                            </StockItemAbsoluteInfoTooltipWrapper>
                          ) : null}
                          <FormCell>
                            <FormLabel>
                              {purchaseOrderLine.product.name}
                            </FormLabel>
                          </FormCell>
                          <FormCell>
                            <FormLabel>
                              {purchaseOrderLine.isStockItem
                                ? t('Stock item')
                                : t('Product')}
                            </FormLabel>
                          </FormCell>
                          <FormCell>
                            <FormLabel
                              onClick={() => {
                                setSelectedLine(purchaseOrderLine);
                                openViewModal(true);
                              }}
                            >
                              {getIn(
                                values.purchaseOrderLines[
                                  purchaseOrderLineIndex
                                ],
                                'quantity'
                              )}
                            </FormLabel>
                          </FormCell>
                          <FormCell>
                            <FormLabel
                              onClick={() => {
                                setSelectedLine(purchaseOrderLine);
                                openViewModal(true);
                              }}
                            >
                              {getIn(
                                values.purchaseOrderLines[
                                  purchaseOrderLineIndex
                                ],
                                'in_stock'
                              ) || '/'}
                            </FormLabel>
                          </FormCell>
                          <FormCell isInputCell>
                            <Input
                              pwId={`purchase-cost-per-unit-${purchaseOrderLineIndex}`}
                              disabled={!purchaseOrderLine.isSelected}
                              errorMessage={
                                areAllFieldsTouched &&
                                purchaseOrderLine.isSelected &&
                                getFormikErrorMesssage(
                                  purchaseOrderLineIndex,
                                  values,
                                  errors,
                                  `purchaseOrderLines.${purchaseOrderLineIndex}.product.purchase_price`
                                )
                              }
                              height={'41rem'}
                              name={`purchaseOrderLines.${purchaseOrderLineIndex}.product.purchase_price`}
                              type="number"
                              handleOnChange={(value: any) => {
                                handlePurchasePriceOnChange(
                                  value,
                                  Number(purchaseOrderForm.supplier.id),
                                  Number(purchaseOrderLine.product.id)
                                );
                              }}
                              onBlur={(e) => {
                                const value = e.target.value.trim();
                                setFieldValue(e.target.name, value);
                                handleBlur(e);
                                handlePurchasePriceOnChange(
                                  e.target.value,
                                  Number(purchaseOrderForm.supplier.id),
                                  Number(purchaseOrderLine.product.id)
                                );
                              }}
                              symbol={currencySymbol}
                              maxWidth={'150rem'}
                            />
                          </FormCell>
                        </CardRightSide>
                        <MobileAction>
                          <FormLabel
                            isMoreLabel
                            onClick={() => {
                              setSelectedLine(purchaseOrderLine);
                              openViewModal(true);
                            }}
                          >
                            {t('More')}
                          </FormLabel>
                          <ActionCell>
                            <Button
                              data-testid={`${purchaseOrderLine.product.name}-select-button-${purchaseOrderLineIndex}`}
                              width={'100rem'}
                              height={'31rem'}
                              onClick={() =>
                                handleSelect(
                                  Number(purchaseOrderForm.supplier.id),
                                  Number(purchaseOrderLine.product.id)
                                )
                              }
                              label={
                                purchaseOrderLine.isSelected
                                  ? t('Unselect')
                                  : t('Select')
                              }
                              secondary
                            />
                          </ActionCell>
                        </MobileAction>
                      </Card>
                    );
                  }
                )}
              </CardWrapper>

              <LabelsContainer>
                <LabelSwitchWrapper>
                  <SwitchSpan
                    onClick={() => {
                      if (transferSwitchDisabled) return;
                      handleCheckTransferLabels(
                        Number(purchaseOrderForm.supplier.id)
                      );
                    }}
                  >
                    {t('Transfer Sales Labels')}
                  </SwitchSpan>
                  <Switch
                    disabled={transferSwitchDisabled}
                    checked={values.is_label_transferable}
                    onChange={() => {
                      handleCheckTransferLabels(
                        Number(purchaseOrderForm.supplier.id)
                      );
                    }}
                  />
                </LabelSwitchWrapper>

                <LabelsRow>
                  <AddLabelDropdownWrapper
                    $withoutMargin={values?.labels?.length === 0}
                  >
                    <AddLabelDropdown
                      inModal={true}
                      entityType={LabelEntityTypeId.PURCHASE_ORDER}
                      onClick={(newLabel: ILabel) => {
                        const labelExists = values?.labels?.some(
                          (label: ILabel) => label.id === newLabel.id
                        );
                        if (!labelExists)
                          handleAddLabel(
                            Number(purchaseOrderForm.supplier.id),
                            newLabel
                          );
                      }}
                    />
                  </AddLabelDropdownWrapper>
                  <LabelWrapper>
                    {values.labels?.map((label: ILabel) => {
                      return (
                        <EntityLabel
                          key={label.id}
                          label={label}
                          tooltipContent={
                            label?.name?.length > 15
                              ? label.name
                              : label.description
                          }
                          onDelete={() => {
                            handleDeleteLabel(
                              Number(purchaseOrderForm.supplier.id),
                              label.id
                            );
                          }}
                        />
                      );
                    })}
                  </LabelWrapper>
                </LabelsRow>
              </LabelsContainer>
            </>
          )}
        />
      </Form>
    );
  };

  const renderDesktopForm = ({
    handleBlur,
    setFieldValue,
    values,
    errors,
  }: any) => {
    return (
      <Form>
        <FieldArray
          name="purchaseOrderLines"
          render={() => (
            <>
              <SupplierCompanyNameLabel>
                {purchaseOrderForm.supplier.company_name}
              </SupplierCompanyNameLabel>
              <FormHeaderContainer>
                <HeaderLabel flex={2}>{t('Product')}</HeaderLabel>
                <HeaderLabel flex={1}>{t('Type')}</HeaderLabel>
                <HeaderLabel flex={0.8}>{t('Quantity')}</HeaderLabel>
                <HeaderLabel flex={0.8}>{t('In stock')}</HeaderLabel>
                <HeaderLabel flex={1.5}>{t('Purchase price')}</HeaderLabel>
                <HeaderLabel flex={0.5} />
                <HeaderLabel
                  flex={1}
                  isSelectAll
                  onClick={() =>
                    handleSelectAll(Number(purchaseOrderForm.supplier.id))
                  }
                >
                  {t('Select all')}
                </HeaderLabel>
              </FormHeaderContainer>
              <PurchaseOrderLinesWrapper>
                {purchaseOrderForm.purchaseOrderLines.map(
                  (purchaseOrderLine, purchaseOrderLineIndex) => {
                    return (
                      <FlexColumn
                        key={`${purchaseOrderForm.supplier.company_name}-line-${purchaseOrderLineIndex}`}
                      >
                        <PurchaseOrderLineContainer
                          isSelected={purchaseOrderLine.isSelected}
                          isOnlyOne={
                            purchaseOrderForm.purchaseOrderLines.length === 1
                          }
                          isFirst={purchaseOrderLineIndex === 0}
                          isLast={
                            purchaseOrderForm.purchaseOrderLines.length - 1 ===
                            purchaseOrderLineIndex
                          }
                        >
                          {purchaseOrderLine.product
                            .logyx_configuration_intent ? (
                            <IsLogyxProductAbsoluteWrapper>
                              <Icon
                                svg={LogyxLogo}
                                size={21}
                                wrapperStyle={{ cursor: 'default' }}
                              />
                            </IsLogyxProductAbsoluteWrapper>
                          ) : null}
                          {purchaseOrderLine.isStockItem ? (
                            <StockItemAbsoluteInfoTooltipWrapper>
                              <Tooltip
                                content={
                                  purchaseOrderLine.isSelected
                                    ? t(
                                        'This item will be added to the purchase order'
                                      )
                                    : t('This item will be taken from stock')
                                }
                              >
                                <Icon
                                  svg={Info}
                                  size={21}
                                  color={COLORS.PRIMARY}
                                />
                              </Tooltip>
                            </StockItemAbsoluteInfoTooltipWrapper>
                          ) : null}
                          <FormCell flex={2}>
                            <FormLabel>
                              {purchaseOrderLine.product.name}
                            </FormLabel>
                          </FormCell>
                          <FormCell flex={1}>
                            <FormLabel>
                              {purchaseOrderLine.isStockItem
                                ? t('Stock item')
                                : t('Product')}
                            </FormLabel>
                          </FormCell>
                          <FormCell flex={0.8}>
                            <FormLabel
                              onClick={() => {
                                setSelectedLine(purchaseOrderLine);
                                openViewModal(true);
                              }}
                            >
                              {getIn(
                                values.purchaseOrderLines[
                                  purchaseOrderLineIndex
                                ],
                                'quantity'
                              )}
                            </FormLabel>
                          </FormCell>
                          <FormCell flex={0.8}>
                            <FormLabel
                              onClick={() => {
                                setSelectedLine(purchaseOrderLine);
                                openViewModal(true);
                              }}
                            >
                              {getIn(
                                values.purchaseOrderLines[
                                  purchaseOrderLineIndex
                                ],
                                'in_stock'
                              ) || '/'}
                            </FormLabel>
                          </FormCell>
                          <FormCell flex={1.5} isInputCell>
                            <Input
                              pwId={`purchase-cost-per-unit-${purchaseOrderLineIndex}`}
                              disabled={!purchaseOrderLine.isSelected}
                              errorMessage={
                                areAllFieldsTouched &&
                                purchaseOrderLine.isSelected &&
                                getFormikErrorMesssage(
                                  purchaseOrderLineIndex,
                                  values,
                                  errors,
                                  `purchaseOrderLines.${purchaseOrderLineIndex}.product.purchase_price`
                                )
                              }
                              height={'41rem'}
                              name={`purchaseOrderLines.${purchaseOrderLineIndex}.product.purchase_price`}
                              type="number"
                              handleOnChange={(value: any) => {
                                handlePurchasePriceOnChange(
                                  value,
                                  Number(purchaseOrderForm.supplier.id),
                                  Number(purchaseOrderLine.product.id)
                                );
                              }}
                              onBlur={(e) => {
                                const value = e.target.value.trim();
                                setFieldValue(e.target.name, value);
                                handleBlur(e);
                                handlePurchasePriceOnChange(
                                  e.target.value,
                                  Number(purchaseOrderForm.supplier.id),
                                  Number(purchaseOrderLine.product.id)
                                );
                              }}
                              symbol={currencySymbol}
                              maxWidth={'150rem'}
                            />
                          </FormCell>
                          <FormCell flex={0.5}>
                            <FormLabel
                              isMoreLabel
                              onClick={() => {
                                setSelectedLine(purchaseOrderLine);
                                openViewModal(true);
                              }}
                            >
                              {t('More')}
                            </FormLabel>
                          </FormCell>
                          <FormCell flex={1}>
                            <Button
                              data-testid={`${purchaseOrderLine.product.name}-select-button-${purchaseOrderLineIndex}`}
                              width={'100rem'}
                              height={'31rem'}
                              onClick={() =>
                                handleSelect(
                                  Number(purchaseOrderForm.supplier.id),
                                  Number(purchaseOrderLine.product.id)
                                )
                              }
                              label={
                                purchaseOrderLine.isSelected
                                  ? t('Unselect')
                                  : t('Select')
                              }
                              secondary
                            />
                          </FormCell>
                        </PurchaseOrderLineContainer>
                        {purchaseOrderForm.purchaseOrderLines.length - 1 ===
                        purchaseOrderLineIndex ? null : (
                          <Line
                            color={getLineColorBasedOnSelectedLines(
                              purchaseOrderForm.purchaseOrderLines,
                              purchaseOrderLineIndex
                            )}
                          />
                        )}
                      </FlexColumn>
                    );
                  }
                )}

                <LabelsContainer>
                  <LabelSwitchWrapper>
                    <SwitchSpan
                      onClick={() => {
                        if (transferSwitchDisabled) return;
                        handleCheckTransferLabels(
                          Number(purchaseOrderForm.supplier.id)
                        );
                      }}
                    >
                      {t('Transfer Sales Labels')}
                    </SwitchSpan>
                    <Switch
                      disabled={transferSwitchDisabled}
                      checked={values.is_label_transferable}
                      onChange={() => {
                        handleCheckTransferLabels(
                          Number(purchaseOrderForm.supplier.id)
                        );
                      }}
                    />
                  </LabelSwitchWrapper>

                  <LabelsRow>
                    <AddLabelDropdownWrapper
                      $withoutMargin={values?.labels?.length === 0}
                    >
                      <AddLabelDropdown
                        entityType={LabelEntityTypeId.PURCHASE_ORDER}
                        onClick={(newLabel: ILabel) => {
                          const labelExists = values?.labels?.some(
                            (label: ILabel) => label.id === newLabel.id
                          );
                          if (!labelExists)
                            handleAddLabel(
                              Number(purchaseOrderForm.supplier.id),
                              newLabel
                            );
                        }}
                      />
                    </AddLabelDropdownWrapper>
                    <LabelWrapper>
                      {values.labels?.map((label: ILabel) => {
                        return (
                          <EntityLabel
                            key={label.id}
                            label={label}
                            tooltipContent={
                              label?.name?.length > 15
                                ? label.name
                                : label.description
                            }
                            onDelete={() => {
                              handleDeleteLabel(
                                Number(purchaseOrderForm.supplier.id),
                                label.id
                              );
                            }}
                          />
                        );
                      })}
                    </LabelWrapper>
                  </LabelsRow>
                </LabelsContainer>
              </PurchaseOrderLinesWrapper>
            </>
          )}
        />
      </Form>
    );
  };

  const { isSmallTablet } = useBreakpointFlag();

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{ ...purchaseOrderForm }}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={createPurchaseOrderSchema}
        onSubmit={() => {
          // On submit
        }}
        validateOnMount={true}
      >
        {({ handleBlur, setFieldValue, values, errors }) => {
          return (
            <>
              {isSmallTablet
                ? renderMobileForm({
                    handleBlur,
                    setFieldValue,
                    values,
                    errors,
                  })
                : renderDesktopForm({
                    handleBlur,
                    setFieldValue,
                    values,
                    errors,
                  })}
            </>
          );
        }}
      </Formik>
    </Container>
  );
};

export default PurchaseOrderForm;
