import Button from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  EditStockAmountLabel,
  ButtonWrapper,
  CloseAbsoluteWrapper,
  Container,
  FormContainer,
} from './EditStockAmountModal.styled';
import { Close } from 'components/Close/Close';
import Input from 'components/Input/Input';
import { marginMd } from 'assets/styled';
import { useEditStockAmount, useGetStockItemId } from './hooks';
import { isNumeric } from 'utils/validation';

const PWID = 'edit-stock-amount-modal';

export interface IEditStockAmountModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  initialAmount: number;
  pwId?: string;
}

export function EditStockAmountModal({
  isOpen,
  setIsOpen,
  initialAmount,
  pwId,
}: IEditStockAmountModalProps) {
  const { t } = useTranslation();
  const stockItemId = useGetStockItemId();
  const [amount, setAmount] = useState<string | null>(
    initialAmount?.toString()
  );
  const [amountError, setAmountError] = useState<string>('');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isOpen) {
      const firstInput =
        containerRef.current?.querySelector<HTMLInputElement>('input');
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, [isOpen]);

  useEffect(() => {
    // When the page loads, until data is fetched for the single stock item, initialAmount is undefined so this needs to update the amount in state when the data gets fetched
    setAmount(initialAmount?.toString());
  }, [initialAmount]);

  useEffect(() => {
    if (isOpen) {
      setAmount(initialAmount?.toString());
      handleValidateAmountInput(initialAmount?.toString());
    }
  }, [isOpen]);

  const {
    mutate: editStockAmount,
    isLoading: editStockAmountIsLoading,
    isSuccess: editStockAmountIsSuccess,
  } = useEditStockAmount(stockItemId);

  useEffect(() => {
    if (editStockAmountIsSuccess) {
      setIsOpen(false);
      setAmount(null);
    }
  }, [editStockAmountIsSuccess]);

  const handleValidateAmountInput = (amount: string) => {
    if (!isNumeric(amount)) {
      setAmountError('Amount must be a number');
    } else {
      if (Number(amount) < 0 || amount.includes('.')) {
        setAmountError('Invalid amount');
      } else if (Number(amount) >= 1000000) {
        setAmountError('Amount must be less than a million');
      } else {
        setAmountError('');
      }
    }
  };

  const handleEditStockAmount = () => {
    if (amountError?.length === 0) {
      editStockAmount({
        stockItemId: Number(stockItemId),
        amount: Number(amount),
      });
    }
  };

  const handleEnterPress = (event: any) => {
    if (event.key === 'Enter' && isOpen) {
      handleEditStockAmount();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onBackDropClick={() => {
        setAmount(null);
        setAmountError('');
      }}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <Container onKeyDown={handleEnterPress} ref={containerRef}>
        <EditStockAmountLabel data-testid={`${pwId}-${PWID}-title`}>
          {t('Edit stock amount')}
        </EditStockAmountLabel>
        <FormContainer>
          <Input
            pwId={`${pwId}-${PWID}-amount-field`}
            error={amountError !== ''}
            errorMessage={amountError}
            placeholder={t('Amount')}
            width={'300rem'}
            height="41rem"
            value={amount?.toString()}
            onChange={(e) => {
              handleValidateAmountInput(e.target.value);
              setAmount(e.target.value);
            }}
            wrapperStyles={{ marginBottom: marginMd }}
          />
        </FormContainer>
        <ButtonWrapper>
          <Button
            pwId={`${pwId}-${PWID}-cancel-button`}
            label={t('Cancel')}
            secondary
            width="200rem"
            onClick={() => {
              setIsOpen(false);
              setAmount(null);
              setAmountError('');
            }}
          />
          <Button
            pwId={`${pwId}-${PWID}-confirm-button`}
            label={t('Confirm')}
            primary
            width="200rem"
            onClick={handleEditStockAmount}
            disabled={
              amount?.length === 0 ||
              amountError?.length !== 0 ||
              editStockAmountIsLoading
            }
          />
        </ButtonWrapper>
        <CloseAbsoluteWrapper>
          <Close
            pwId={`${pwId}-${PWID}-close-button`}
            onClick={() => {
              setIsOpen(false);
              setAmount(null);
              setAmountError('');
            }}
          />
        </CloseAbsoluteWrapper>
      </Container>
    </Modal>
  );
}
