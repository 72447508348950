import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ButtonsWrapper,
  CloseContainer,
  Container,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';

interface IActionsMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  setIsPaymentModalOpen: Dispatch<SetStateAction<boolean>>;
  canEditPurchaseInvoice: boolean;
}

const ActionsMobileModal = ({
  isOpen,
  setIsOpen,
  data,
  setIsPaymentModalOpen,
  canEditPurchaseInvoice,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>
            {t('Invoice')}&nbsp;
            {data?.invoice_number}
          </Title>
        </TitleContainer>
        <ButtonsWrapper>
          <Button
            label={t('Add Payment')}
            secondary
            disabled={!canEditPurchaseInvoice || !data?.open_amount}
            width="100%"
            onClick={() => setIsPaymentModalOpen(true)}
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
