import { CloseStyled } from './Close.styled';
import { Size } from './type';

export interface ICloseProps {
  onClick?: () => void;
  size?: Size;
  id?: string;
  pwId?: string;
}

export const Close = ({
  pwId,
  onClick,
  size = Size.DEFAULT,
  id,
}: ICloseProps) => (
  <CloseStyled
    data-testid={`${pwId}-close-button`}
    onClick={onClick}
    size={size}
    id={id}
    className="close-button"
  />
);
