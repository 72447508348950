import { Option } from 'components/Select/type';
import { SortDirection } from 'components/Table/constants';
import {
  ONGOING_STATUS,
  SalesOrderStatusType,
} from 'pages/Manager/SingleSalesOrder/constants';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  exportSalesOrderExcel,
  getSalesOrders,
} from 'services/SalesOrder/SalesOrderService';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { ISalesOrdersPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';
import {
  DateInterval,
  getInitialFilterGroupsFromDateInterval,
} from 'utils/hooks/useGetDateIntervalOptions';
import { useGetSalesOrderStatuses } from 'utils/hooks/useGetSalesOrderStatuses';
import { IAnalyticsNavigationState } from '../AnalyticsPage/types';
import { useLocation } from 'react-router-dom';
import { Base64 } from 'js-base64';
import { prepareData } from 'components/Modal/AdvancedFilterModal/helpers';
import { IFilterGroup } from 'components/Modal/AdvancedFilterModal/types';
import { useGetExpectedInvoiceDateOptions } from 'utils/hooks/useGetExpectedInvoiceDateIntervalOptions';
import { useTranslation } from 'react-i18next';
import { IExportSalesOrderExcelDTO } from './types';
import { toast } from 'utils/toast';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';

export const useGetSalesOrders = (
  page?: number,
  perPage?: number,
  salesOrderStatuses?: string,
  salesOrderPaymentStatuses?: string,
  salesOrderSubStatuses?: string,
  searchBy?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string,
  selectedLabelIds?: number[]
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SALES_ORDERS,
      page,
      perPage,
      salesOrderStatuses,
      salesOrderPaymentStatuses,
      salesOrderSubStatuses,
      searchBy,
      sortBy,
      sortDirection,
      advancedFilters,
      selectedLabelIds,
    ],
    queryFn: () => {
      return getSalesOrders(
        page,
        perPage,
        salesOrderStatuses,
        salesOrderPaymentStatuses,
        salesOrderSubStatuses,
        searchBy,
        sortBy,
        sortDirection,
        advancedFilters,
        selectedLabelIds
      );
    },
  });

export const useGetSalesOrdersPageFilters = () => {
  const dispatch = useDispatch();
  const salesOrdersPageFilters: ISalesOrdersPageFilters | null = useSelector(
    (state: IRootReducerState) => state.filtersInfo.salesOrdersPage
  );
  const statusOptions = useGetSalesOrderStatuses(SalesOrderStatusType.STATUS);
  const subStatusOptions = useGetSalesOrderStatuses(
    SalesOrderStatusType.SUBSTATUS
  );
  const paymentStatusOptions = useGetSalesOrderStatuses(
    SalesOrderStatusType.PAYMENT
  );
  const expectedInvoiceDateOptions = useGetExpectedInvoiceDateOptions();

  if (!salesOrdersPageFilters) {
    const initialSalesOrdersPageFilters: ISalesOrdersPageFilters = {
      page: 1,
      searchBy: '',
      selectedStatusOptions: [],
      selectedSubStatusOptions: [],
      selectedPaymentStatusOptions: [],
      sortBy: undefined,
      sortDirection: undefined,
      advancedFilters: '',
      selectedLabelIds: [],
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.SALES_ORDERS,
        data: initialSalesOrdersPageFilters,
      })
    );
    return {
      salesOrdersPageFilters: initialSalesOrdersPageFilters,
      statusOptions,
      subStatusOptions,
      paymentStatusOptions,
      expectedInvoiceDateOptions,
    };
  }

  return {
    salesOrdersPageFilters,
    statusOptions,
    subStatusOptions,
    paymentStatusOptions,
    expectedInvoiceDateOptions,
  };
};

export const useManageAndSaveFilters = (
  initialFilters: ISalesOrdersPageFilters
) => {
  const dispatch = useDispatch();

  // State for each filter
  const [page, setPage] = useState<number>(initialFilters.page);
  const [searchBy, setSearchBy] = useState<string>(initialFilters.searchBy);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<Option[]>(
    initialFilters.selectedStatusOptions
  );
  const [selectedSubStatusOptions, setSelectedSubStatusOptions] = useState<
    Option[]
  >(initialFilters.selectedSubStatusOptions);

  const [selectedPaymentStatusOptions, setSelectedPaymentStatusOptions] =
    useState<Option[]>(initialFilters.selectedPaymentStatusOptions);

  const [sortBy, setSortBy] = useState<string | undefined>(
    initialFilters.sortBy
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialFilters.sortDirection
  );
  const [advancedFilters, setAdvancedFilters] = useState<string>(
    initialFilters.advancedFilters
  );
  const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>(
    initialFilters.selectedLabelIds
  );

  // Ref to track initial render
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      // On mount, set initialRender to false
      initialRender.current = false;
    } else {
      // * Reset page count if any other filter changes *
      setPage(1);
    }
  }, [
    searchBy,
    selectedStatusOptions,
    selectedSubStatusOptions,
    selectedPaymentStatusOptions,
    sortBy,
    sortDirection,
    advancedFilters,
    selectedLabelIds,
  ]);

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<ISalesOrdersPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  // Update persisted page count when only page changes
  useEffect(() => {
    filtersRef.current = {
      ...filtersRef.current,
      page,
    };
  }, [page]);
  // Reset persisted page count if any other filter changes
  useEffect(() => {
    filtersRef.current = {
      page: 1,
      searchBy,
      selectedStatusOptions,
      selectedSubStatusOptions,
      selectedPaymentStatusOptions,
      sortBy,
      sortDirection,
      advancedFilters,
      selectedLabelIds,
    };
  }, [
    searchBy,
    selectedStatusOptions,
    selectedSubStatusOptions,
    selectedPaymentStatusOptions,
    sortBy,
    sortDirection,
    advancedFilters,
    selectedLabelIds,
  ]);

  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.SALES_ORDERS,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedSubStatusOptions,
    setSelectedSubStatusOptions,
    selectedPaymentStatusOptions,
    setSelectedPaymentStatusOptions,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    advancedFilters,
    setAdvancedFilters,
    selectedLabelIds,
    setSelectedLabelIds,
  };
};

const _getInitialFilterGroups = (state: IAnalyticsNavigationState) => {
  if (!state?.dateIntervalOption) {
    return undefined;
  }
  const filterGroups: IFilterGroup[] = getInitialFilterGroupsFromDateInterval(
    state?.dateIntervalOption.value as DateInterval
  );

  return filterGroups;
};
export const useHandleAnalyticsNavigationState = (
  setAdvancedFilters: Dispatch<SetStateAction<string>>,
  setSelectedStatusOptions: Dispatch<SetStateAction<Option[]>>,
  setSelectedSubStatusOptions: Dispatch<SetStateAction<Option[]>>,
  setSelectedPaymentStatusOptions: Dispatch<SetStateAction<Option[]>>,
  statusOptions: Option[],
  subStatusOptions: Option[]
) => {
  const { state }: { state: IAnalyticsNavigationState } = useLocation();

  // * Handle navigation from Analytics - Order Portfolio (All ongoing sales orders)
  useEffect(() => {
    if (state?.selectedSubStatus) {
      if (state?.selectedSubStatus.value === '') {
        // All is selected
        setSelectedStatusOptions([]);
        setSelectedSubStatusOptions([]);
      } else {
        // Ongoing status + selected substatus
        const existingOngoingStatusOption: Option | undefined =
          statusOptions.find(
            (statusOption: Option) => statusOption.value === ONGOING_STATUS
          );
        const existingSubStatusOption: Option | undefined =
          subStatusOptions.find(
            (subStatusOption: Option) =>
              subStatusOption.value === state?.selectedSubStatus?.value
          );
        if (existingOngoingStatusOption && existingSubStatusOption) {
          setSelectedStatusOptions([existingOngoingStatusOption]);
          setSelectedSubStatusOptions([existingSubStatusOption]);
        }
      }
    }
  }, []);

  // * Handle navigation from Analytics - Sales (for provided dateInterval)
  // Extract filter groups from provided dateInterval and set them as initial values for AdvancedFilterModal
  const [initialFilterGroups, _] = useState<IFilterGroup[] | undefined>(
    _getInitialFilterGroups(state)
  );

  useEffect(() => {
    if (initialFilterGroups) {
      const filters: string = Base64.encode(
        JSON.stringify(prepareData(initialFilterGroups))
      );
      // Include the advanced filters base64 in the query params of the request and remove other filters
      setAdvancedFilters(filters);
      setSelectedStatusOptions([]);
      setSelectedSubStatusOptions([]);
      setSelectedPaymentStatusOptions([]);
    }
  }, [initialFilterGroups]);

  return initialFilterGroups;
};

export const useExportSalesOrderExcel = () => {
  const { t } = useTranslation();
  return useMutation(
    (exportExcelData: IExportSalesOrderExcelDTO) =>
      exportSalesOrderExcel(exportExcelData),
    {
      onSuccess: () => {
        toast.success(t('A download link is sent. Please check your email.'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EXPORT_SALES_ORDER_EXCEL,
        });
      },
      mutationKey: ReactMutationKeys.EXPORT_SALES_ORDER_EXCEL,
    }
  );
};
