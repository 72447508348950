import {
  COLORS,
  borderRadiusSm,
  gapMd,
  marginMd,
  paddingMd,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${paddingMd};
  padding-bottom: ${paddingMd};
  padding-left: ${paddingMd};
  padding-right: ${paddingMd};
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};
  width: 100%;

  ${respondTo.midTablet`
    justify-content: flex-start;
    gap: 20rem;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${gapMd};
  align-items: center;
`;

export const SelectInputWrapper = styled.div`
  width: 203rem;
  height: 41rem;
  z-index: 2;
`;

export const ViewTypeOptionLabel = styled.label`
  user-select: none;
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const TableWrapper = styled.div`
  width: 100%;
`;

export const SecondRow = styled.div`
  width: 100%;

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;
