import {
  Container,
  FlexAlignCenter,
  Row,
  TitleContainer,
  RowRightPart,
  FiltersWrapper,
  ButtonWrapper,
  ActionsWrapper,
  QuotButton,
  ExportButton,
} from './QuotationsPage.styled';
import { COLORS, H3 } from 'assets/styled';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import Table from 'components/Table/Table';
import { formatQuotationsTableData } from 'components/Table/tableDataFormatter';
import Input from 'components/Input/Input';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import { MACBOOK_WIDTH, QUOTATIONS_PER_PAGE, tableConfig } from './constants';
import {
  useGetQuotations,
  useGetQuotationsPageFilters,
  useHandleAnalyticsNavigationState,
  useManageAndSaveFilters,
} from './hooks';
import { IQuotation, IQuotationTableDTO } from 'types/Quotations.types';
import { RoutesConfig } from 'navigation/routes';
import { useDebounce } from 'utils/hooks/useDebounce';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useState } from 'react';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useGetQuotationsAdvancedFilters } from './advancedFilters';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import CheckableSelect from 'components/Select/SelectComponents/CheckableSelect/CheckableSelect';
import { CaretDown, Funnel, MagnifyingGlass } from '@phosphor-icons/react';
import { useWindowDimensions } from 'utils/hooks/useWindowDimensions';
import LabelsFilter from 'components/LabelsFilter/LabelsFilter';
import { parseQuotationTypeStringIntoId } from './helpers';
import { formatInitialAdvancedFilters } from 'components/Modal/AdvancedFilterModal/helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import Icon from 'components/Icon/Icon';
import FilterMobileModal from './FilterMobileModal/FilterMobileModal';

const PWID = 'quotation-page';

const QuotationsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const canCreateQuotation = useCan(Actions.CREATE_QUOTATION);
  const currencySymbol = useGetCurrencySymbol();
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const [isMobileFilterModalOpen, setIsMobileFilterModalOpen] =
    useState<boolean>(false);
  const { steps } = useRunTour(GuidePages.QUOTATIONS);

  const { isSmallTablet } = useBreakpointFlag();

  const {
    quotationsPageFilters,
    statusOptions,
    dateIntervalOptions,
    activeFilterOptions,
    quotationTypeOptions,
  } = useGetQuotationsPageFilters();

  const filtersData = useManageAndSaveFilters(quotationsPageFilters);

  const {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedDateIntervalOption,
    setSelectedDateIntervalOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    selectedActiveFilterOption,
    setSelectedActiveFilterOption,
    selectedQuotationTypeOption,
    setSelectedQuotationTypeOption,
    advancedFilters,
    setAdvancedFilters,
    selectedLabelIds,
    setSelectedLabelIds,
  } = filtersData;

  useHandleAnalyticsNavigationState(setSelectedDateIntervalOption);

  const debouncedSearchBy = useDebounce(searchBy);

  const { data, isLoading } = useGetQuotations(
    page,
    QUOTATIONS_PER_PAGE,
    selectedStatusOptions?.map((option: Option) => option.value).join(','),
    selectedDateIntervalOption?.value,
    selectedActiveFilterOption?.value,
    parseQuotationTypeStringIntoId(selectedQuotationTypeOption?.value),
    debouncedSearchBy,
    sortBy,
    sortDirection,
    advancedFilters,
    selectedLabelIds
  );

  let tableData: IQuotationTableDTO[] = [];
  if (data) {
    tableData = formatQuotationsTableData(
      data.quotations
        .map((quotation: IQuotation[]) => {
          return quotation;
        })
        .flat(),
      currencySymbol
    );
  }

  const handleViewButtonClick = (rowData: IQuotationTableDTO) => {
    navigate(`${rowData.navigationID}`);
  };

  const { filterTypes } = useGetQuotationsAdvancedFilters(statusOptions);
  const { width } = useWindowDimensions();

  return (
    <Container className="quotations-step-1">
      <TitleContainer>
        <H3 data-testid={`${PWID}-overview-title`}>{t('Overview')}</H3>
        <ButtonWrapper>
          <Button
            pwId={`${PWID}-create-quotation-button`}
            className="quotations-step-3"
            onClick={() => navigate(RoutesConfig.NewQuotation.fullPath)}
            label={t('Create quotation')}
            width="200rem"
            height="41rem"
            primary
            disabled={!canCreateQuotation}
          />
        </ButtonWrapper>
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId={`${PWID}-search-input`}
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            wrapperStyles={{ width: isSmallTablet ? '100%' : '' }}
            width={
              isSmallTablet
                ? '100%'
                : width > MACBOOK_WIDTH
                ? '400rem'
                : '300rem'
            }
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
          {isSmallTablet && (
            <Icon
              svg={Funnel}
              size={30}
              color={COLORS.PRIMARY}
              weight="regular"
              onClick={() => setIsMobileFilterModalOpen(true)}
            />
          )}
        </FlexAlignCenter>
        <RowRightPart className="quotations-step-2">
          <CheckableSelect
            pwId={`${PWID}-type-input`}
            placeholder={t('Type')}
            options={quotationTypeOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(options: Option[]) =>
              setSelectedQuotationTypeOption(options[0])
            }
            defaultValues={
              selectedQuotationTypeOption
                ? [selectedQuotationTypeOption]
                : [quotationTypeOptions[0]]
            }
          />
          <CheckableSelect
            pwId={`${PWID}-status-input`}
            placeholder={t('Status')}
            isMulti
            options={statusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => setSelectedStatusOptions(e)}
            defaultValues={selectedStatusOptions}
          />

          <CheckableSelect
            pwId={`${PWID}-active-filter-input`}
            placeholder={t('Active filter')}
            options={activeFilterOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(options: Option[]) =>
              setSelectedActiveFilterOption(options[0])
            }
            defaultValues={
              selectedActiveFilterOption
                ? [selectedActiveFilterOption]
                : [activeFilterOptions[0]]
            }
          />
          <CheckableSelect
            pwId={`${PWID}-date-input`}
            placeholder={t('Date')}
            options={dateIntervalOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(options: Option[]) =>
              setSelectedDateIntervalOption(options[0])
            }
            value={selectedDateIntervalOption}
            defaultValues={
              selectedDateIntervalOption
                ? [selectedDateIntervalOption]
                : [dateIntervalOptions[0]]
            }
          />
        </RowRightPart>
      </Row>
      {isSmallTablet && (
        <ActionsWrapper>
          <QuotButton>
            <Button
              className="quotations-step-3"
              onClick={() => navigate(RoutesConfig.NewQuotation.fullPath)}
              label={t('Create quotation')}
              width="100%"
              height="35rem"
              primary
              disabled={!canCreateQuotation}
            />
          </QuotButton>
          {/* <ExportButton>
            <Button
              onClick={() => null}
              label={t('Export')}
              icon={CaretDown}
              iconPosition="right"
              sizeIcon={15}
              width="100%"
              height="35rem"
              secondary
            />
          </ExportButton> */}
        </ActionsWrapper>
      )}
      <FiltersWrapper>
        <LabelsFilter
          pwId={`${PWID}`}
          selectedLabelIds={selectedLabelIds}
          setSelectedLabelIds={setSelectedLabelIds}
        />
        <Button
          pwId={`${PWID}-advanced-filter-button`}
          onClick={() => setIsAdvancedFilterModalOpen(true)}
          label={t('Advanced filter')}
          width="145rem"
          height="31rem"
          secondary={!advancedFilters}
          primary={!!advancedFilters}
        />
      </FiltersWrapper>
      <Table
        pwId={PWID}
        page={page}
        isLoading={isLoading}
        perPage={QUOTATIONS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
        labelsRow
      />
      <AdvancedFilterModal
        pwId={PWID}
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
        initialFilterGroups={
          advancedFilters
            ? formatInitialAdvancedFilters(advancedFilters)
            : undefined
        }
      />
      <IntroductionGuide steps={steps} />
      {isSmallTablet && (
        <FilterMobileModal
          isOpen={isMobileFilterModalOpen}
          setIsOpen={setIsMobileFilterModalOpen}
          onCancel={() => setIsMobileFilterModalOpen(false)}
          filtersData={filtersData}
          statusOptions={statusOptions}
          dateIntervalOptions={dateIntervalOptions}
          activeFilterOptions={activeFilterOptions}
          quotationTypeOptions={quotationTypeOptions}
          setIsAdvancedFilterModalOpen={setIsAdvancedFilterModalOpen}
          advancedFilters={advancedFilters}
        />
      )}
    </Container>
  );
};

export default QuotationsPage;
