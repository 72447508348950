import {
  ButtonsWrapper,
  Container,
  Line,
  Title,
  TitleWrapper,
  Wrapper,
} from './NewPurchaseOrderFromSalesOrderPage.styled';
import { Fragment, useEffect, useState } from 'react';
import {
  useCreatePurchaseOrderBatch,
  useGetPrepopulateFormData,
} from './hooks';
import { useTranslation } from 'react-i18next';
import { ICreatePurchaseOrderData } from 'types/PurchaseOrders.types';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPurchaseOrderFormDTO, IPurchaseOrderLineFormDTO } from './constants';
import {
  getInitialData,
  isAnyProductSelectedTotal,
  isFormValid,
} from './helpers';
import Button from 'components/Button/Button';
import PurchaseOrderForm from './PurchaseOrderForm/PurchaseOrderForm';
import Overview from './Overview/Overview';
import { isAnyProductSelected } from './Overview/helpers';
import { ViewProductModalFormatter } from 'components/Modal/ViewProductModal/helpers';
import { ViewProductType } from 'components/Modal/ViewProductModal/type';
import { ViewProductModal } from 'components/Modal/ViewProductModal/ViewProductModal';
import { ISalesOrder } from 'types/SalesOrders.types';
import { ILabel } from 'types/EntityLabel.types';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const NewPurchaseOrderFromSalesOrderPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const prepopulateFormData: ISalesOrder = useGetPrepopulateFormData();

  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const [isOverview, setIsOverview] = useState<boolean>(false);

  const [isViewProductModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [selectedPurchaseOrderLine, setSelectedPurchaseOrderLine] =
    useState<IPurchaseOrderLineFormDTO>();

  const [purchaseOrderFormDTOs, setPurchaseOrderFormDTOs] = useState<
    IPurchaseOrderFormDTO[]
  >(getInitialData(prepopulateFormData));

  const {
    mutate: createPurchaseOrderBatch,
    isSuccess,
    isLoading,
  } = useCreatePurchaseOrderBatch();

  useEffect(() => {
    if (isSuccess) {
      navigate(location.pathname.replace('/purchase-order', '/purchases'));
    }
  }, [isSuccess]);

  const handleSubmit = () => {
    if (!isOverview) {
      setAreAllFieldsTouched(true);
      if (isFormValid(purchaseOrderFormDTOs)) {
        setIsOverview(true);
      }
    } else {
      handleCreatePurchaseOrders(purchaseOrderFormDTOs);
    }
  };

  const handleCreatePurchaseOrders = (
    purchaseOrderFormDTOs: IPurchaseOrderFormDTO[]
  ) => {
    const purchaseOrdersForBatchCreation: ICreatePurchaseOrderData[] =
      purchaseOrderFormDTOs
        .filter((purchaseOrderFormDTO) =>
          isAnyProductSelected(purchaseOrderFormDTO)
        )
        .map((purchaseOrderFormDTO) =>
          handleCreatePurchaseOrderForBatch(purchaseOrderFormDTO)
        );
    createPurchaseOrderBatch(purchaseOrdersForBatchCreation);
  };

  const handleCreatePurchaseOrderForBatch = (
    purchaseOrderFormDTO: IPurchaseOrderFormDTO
  ) => {
    const salesOrderLabels = prepopulateFormData.labels;
    const salesOrderLabelIds = salesOrderLabels.map((label) => label.id);

    const createPurchaseOrderData: ICreatePurchaseOrderData = {
      description: 'TODO: description',
      product_lines: [],
      sales_order_id: Number(prepopulateFormData.id),
      ...(!!purchaseOrderFormDTO.labels?.length && {
        label_ids: purchaseOrderFormDTO.labels
          .filter((label) => {
            // If labels are transferable, skip labels from sales order
            if (purchaseOrderFormDTO.is_label_transferable) {
              return !salesOrderLabelIds.includes(label.id);
            }
            return true;
          })
          .map((label) => label.id),
      }),
      is_label_transferable: purchaseOrderFormDTO.is_label_transferable,
    };

    createPurchaseOrderData.supplier_id = purchaseOrderFormDTO.supplier.id;

    const purchaseOrderProductLines = purchaseOrderFormDTO.purchaseOrderLines
      .filter(
        (purchaseOrderLine) =>
          purchaseOrderLine.isStockItem ||
          (purchaseOrderLine.isSelected && !purchaseOrderLine.isStockItem)
      )
      .map((purchaseOrderLineDTO: IPurchaseOrderLineFormDTO) => {
        return {
          product_id: purchaseOrderLineDTO.product.id,
          supplier_id: purchaseOrderFormDTO.supplier.id,
          quantity: purchaseOrderLineDTO.quantity,
          sales_order_line_id: purchaseOrderLineDTO.sales_order_line_id,
          take_from_stock:
            !purchaseOrderLineDTO.isSelected &&
            purchaseOrderLineDTO.isStockItem,
          product_purchase_price: +purchaseOrderLineDTO.product.purchase_price,
        };
      });

    createPurchaseOrderData.product_lines = []; // Leave as empty array
    createPurchaseOrderData.sales_order_lines = purchaseOrderProductLines;

    return createPurchaseOrderData;
  };

  const handlePurchasePriceOnChange = (
    value: string,
    supplierId: number,
    productId: number
  ) => {
    // Find purchaseOrderForm
    const purchaseOrderFormDTO: IPurchaseOrderFormDTO | undefined =
      purchaseOrderFormDTOs.find((purchaseOrderFormDTO) => {
        return Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId);
      });
    if (!purchaseOrderFormDTO) {
      return;
    }
    // Find purchaseOrderLine
    const purchaseOrderLine: IPurchaseOrderLineFormDTO | undefined =
      purchaseOrderFormDTO.purchaseOrderLines.find(
        (purchaseOrderLine) =>
          Number(purchaseOrderLine?.product.id) === Number(productId)
      );
    if (!purchaseOrderLine) {
      return;
    }
    // Update price
    if (purchaseOrderLine) {
      purchaseOrderLine.product.purchase_price = value;
    }
    const indexOfUpdatedForm = purchaseOrderFormDTOs.findIndex(
      (purchaseOrderFormDTO) =>
        Number(purchaseOrderFormDTO.supplier) === Number(supplierId)
    );
    const newPurchaseOrderFormDTOs = [...purchaseOrderFormDTOs];
    purchaseOrderFormDTOs[indexOfUpdatedForm] = purchaseOrderFormDTO;
    setPurchaseOrderFormDTOs(newPurchaseOrderFormDTOs);
  };

  const handleSelectAll = (supplierId: number) => {
    // Find purchaseOrderForm
    const purchaseOrderFormDTO: IPurchaseOrderFormDTO | undefined =
      purchaseOrderFormDTOs.find((purchaseOrderFormDTO) => {
        return Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId);
      });
    if (!purchaseOrderFormDTO) {
      return;
    }
    // Set isSelected to true for all purchaseOrderLines
    purchaseOrderFormDTO.purchaseOrderLines.forEach((purchaseOrderLine) => {
      purchaseOrderLine.isSelected = true;
    });

    // Update the purchaseOrderFormDTOs array
    const indexOfUpdatedForm = purchaseOrderFormDTOs.findIndex(
      (purchaseOrderFormDTO) =>
        Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId)
    );

    const newPurchaseOrderFormDTOs = [...purchaseOrderFormDTOs];
    newPurchaseOrderFormDTOs[indexOfUpdatedForm] = purchaseOrderFormDTO;
    setPurchaseOrderFormDTOs(newPurchaseOrderFormDTOs);
  };

  const handleSelect = (supplierId: number, productId: number) => {
    // Find purchaseOrderForm
    const purchaseOrderFormDTO: IPurchaseOrderFormDTO | undefined =
      purchaseOrderFormDTOs.find((purchaseOrderFormDTO) => {
        return Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId);
      });
    if (!purchaseOrderFormDTO) {
      return;
    }
    // Find purchaseOrderLine
    const purchaseOrderLine: IPurchaseOrderLineFormDTO | undefined =
      purchaseOrderFormDTO.purchaseOrderLines.find(
        (purchaseOrderLine) =>
          Number(purchaseOrderLine?.product.id) === Number(productId)
      );
    if (!purchaseOrderLine) {
      return;
    }
    // Update isSelected
    if (purchaseOrderLine) {
      purchaseOrderLine.isSelected = !purchaseOrderLine.isSelected;
    }
    const indexOfUpdatedForm = purchaseOrderFormDTOs.findIndex(
      (purchaseOrderFormDTO) =>
        Number(purchaseOrderFormDTO.supplier) === Number(supplierId)
    );
    const newPurchaseOrderFormDTOs = [...purchaseOrderFormDTOs];
    purchaseOrderFormDTOs[indexOfUpdatedForm] = purchaseOrderFormDTO;
    setPurchaseOrderFormDTOs(newPurchaseOrderFormDTOs);
  };

  const handleAddLabel = (supplierId: number, newLabel: ILabel) => {
    // Find purchaseOrderForm
    const purchaseOrderFormDTO: IPurchaseOrderFormDTO | undefined =
      purchaseOrderFormDTOs.find((purchaseOrderFormDTO) => {
        return Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId);
      });
    if (!purchaseOrderFormDTO) {
      return;
    }

    // Check if label already exists
    const existingLabel = purchaseOrderFormDTO.labels?.find(
      (label: ILabel) => label.id === newLabel.id
    );
    if (existingLabel) return;

    purchaseOrderFormDTO.labels = [...purchaseOrderFormDTO.labels, newLabel];

    // Update purchaseOrderFormDTOs
    const indexOfUpdatedForm = purchaseOrderFormDTOs.findIndex(
      (purchaseOrderFormDTO) =>
        Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId)
    );

    const newPurchaseOrderFormDTOs = [...purchaseOrderFormDTOs];
    newPurchaseOrderFormDTOs[indexOfUpdatedForm] = purchaseOrderFormDTO;
    setPurchaseOrderFormDTOs(newPurchaseOrderFormDTOs);
  };

  const handleDeleteLabel = (supplierId: number, labelId: number) => {
    // Find purchaseOrderForm
    const purchaseOrderFormDTO: IPurchaseOrderFormDTO | undefined =
      purchaseOrderFormDTOs.find((purchaseOrderFormDTO) => {
        return Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId);
      });
    if (!purchaseOrderFormDTO) {
      return;
    }

    const salesOrderLabels = prepopulateFormData.labels;
    const salesOrderLabelIds = salesOrderLabels.map((label) => label.id);

    if (salesOrderLabelIds.includes(labelId))
      purchaseOrderFormDTO.is_label_transferable = false;

    purchaseOrderFormDTO.labels = purchaseOrderFormDTO.labels?.filter(
      (currentLabel: ILabel) => currentLabel.id !== labelId
    );

    // Update purchaseOrderFormDTOs
    const indexOfUpdatedForm = purchaseOrderFormDTOs.findIndex(
      (purchaseOrderFormDTO) =>
        Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId)
    );

    const newPurchaseOrderFormDTOs = [...purchaseOrderFormDTOs];
    newPurchaseOrderFormDTOs[indexOfUpdatedForm] = purchaseOrderFormDTO;
    setPurchaseOrderFormDTOs(newPurchaseOrderFormDTOs);
  };

  const handleCheckTransferLabels = (supplierId: number) => {
    // Find purchaseOrderForm
    const purchaseOrderFormDTO: IPurchaseOrderFormDTO | undefined =
      purchaseOrderFormDTOs.find((purchaseOrderFormDTO) => {
        return Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId);
      });
    if (!purchaseOrderFormDTO) {
      return;
    }
    purchaseOrderFormDTO.is_label_transferable =
      !purchaseOrderFormDTO.is_label_transferable;

    const salesOrderLabels = prepopulateFormData.labels;
    const salesOrderLabelIds = salesOrderLabels.map((label) => label.id);

    if (purchaseOrderFormDTO.is_label_transferable) {
      const existingLabelIds =
        purchaseOrderFormDTO.labels?.map((label) => label.id) || [];
      const newLabels = salesOrderLabels.filter(
        (salesLabel: ILabel) => !existingLabelIds.includes(salesLabel.id)
      );
      // Add labels from sales order
      purchaseOrderFormDTO.labels = purchaseOrderFormDTO.labels
        ? [...purchaseOrderFormDTO.labels, ...newLabels]
        : newLabels;
    } else {
      //Remove labels from sales order
      purchaseOrderFormDTO.labels = purchaseOrderFormDTO.labels?.filter(
        (currentLabel: ILabel) => !salesOrderLabelIds.includes(currentLabel.id)
      );
    }
    // Update purchaseOrderFormDTOs
    const indexOfUpdatedForm = purchaseOrderFormDTOs.findIndex(
      (purchaseOrderFormDTO) =>
        Number(purchaseOrderFormDTO.supplier.id) === Number(supplierId)
    );

    const newPurchaseOrderFormDTOs = [...purchaseOrderFormDTOs];
    newPurchaseOrderFormDTOs[indexOfUpdatedForm] = purchaseOrderFormDTO;
    setPurchaseOrderFormDTOs(newPurchaseOrderFormDTOs);
  };

  const { isSmallPhone } = useBreakpointFlag();

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>{t('Add purchase order')}</Title>
      </TitleWrapper>
      <Container>
        {!isOverview ? (
          purchaseOrderFormDTOs.map(
            (
              purchaseOrderForm: IPurchaseOrderFormDTO,
              purchaseOrderFormIndex: number
            ) => {
              return (
                <Fragment key={purchaseOrderFormIndex}>
                  <PurchaseOrderForm
                    purchaseOrderForm={purchaseOrderForm}
                    handlePurchasePriceOnChange={handlePurchasePriceOnChange}
                    handleSelect={handleSelect}
                    handleSelectAll={handleSelectAll}
                    areAllFieldsTouched={areAllFieldsTouched}
                    openViewModal={setIsViewModalOpen}
                    setSelectedLine={setSelectedPurchaseOrderLine}
                    handleAddLabel={handleAddLabel}
                    handleDeleteLabel={handleDeleteLabel}
                    handleCheckTransferLabels={handleCheckTransferLabels}
                    transferSwitchDisabled={!prepopulateFormData.labels.length}
                  />
                  {purchaseOrderFormIndex <
                    purchaseOrderFormDTOs.length - 1 && <Line />}
                </Fragment>
              );
            }
          )
        ) : (
          <Overview purchaseOrderFormDTOs={purchaseOrderFormDTOs} />
        )}

        <ButtonsWrapper>
          <Button
            label={isOverview ? t('Back') : t('Cancel')}
            secondary
            width={isSmallPhone ? '150rem' : '200rem'}
            onClick={() => {
              isOverview
                ? setIsOverview(false)
                : navigate(location.pathname.replace('/purchase-order', ''));
            }}
          />
          <Button
            disabled={
              !isAnyProductSelectedTotal(purchaseOrderFormDTOs) || isLoading
            }
            label={isOverview ? t('Finish') : t('Next')}
            primary
            width={isSmallPhone ? '150rem' : '200rem'}
            onClick={handleSubmit}
          />
        </ButtonsWrapper>
        <ViewProductModal
          isOpen={isViewProductModalOpen}
          setIsOpen={setIsViewModalOpen}
          onCancel={() => setIsViewModalOpen(false)}
          productLineData={ViewProductModalFormatter(
            selectedPurchaseOrderLine,
            ViewProductType.PURCHASE_ORDER_LINE_FORM_DTO
          )}
        />
      </Container>
    </Wrapper>
  );
};

export default NewPurchaseOrderFromSalesOrderPage;
