import { COLORS, H3 } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  LeftSide,
  RightSide,
  TitleContainer,
  ImgWrapper,
  Wrapper,
  HeaderWrapper,
  ButtonContainer,
  ButtonWrapper,
  RouterWrapper,
  ThreeDots,
} from './SingleSupplierPage.styled';
import Button from 'components/Button/Button';
import { subMenuItems } from './constants';
import {
  useGetSingleSupplier,
  useGetSingleSupplierComments,
  useGetSingleSupplierInfo,
} from './hooks';
import { useEffect, useState } from 'react';
import { useActivateSupplier, useDeleteSupplier } from './hooks';
import { RoutesConfig } from 'navigation/routes';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import {
  CaretLeft,
  ChatCircle,
  DotsThree,
  WarningCircle,
} from '@phosphor-icons/react';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import SingleSupplierRouter from 'navigation/SingleSupplier/SingleSupplierRouter';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { CommentsModal } from './CommentsModal/CommentsModal';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';
import SubSideMenu from 'components/Layout/Navigation/SubSideMenu/SubSideMenu';
import { mapSubSideMenuItemsInfo } from 'components/Layout/Navigation/SubSideMenu/helpers';
import useScrollPosition from 'utils/hooks/useScrollMobileSubMenu';
import MobileSubMenu from 'components/Layout/Navigation/MobileSubMenu/MobileSubMenu';
import ActionsMobileModal from './ActionsMobileModal/ActionsMobileModal';
// import ActionsMobileModal from './ActionsMobileModal/ActionsMobileModal';

const PWID = 'single-supplier-page';
const COMMENTS_PER_PAGE = 3;
const FIRST_PAGE = 1;

const SingleSupplierPage = () => {
  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const canEditSupplier = useCan(Actions.EDIT_PURCHASE_ORDER);
  const canCreatePurchaseOrder = useCan(Actions.CREATE_PURCHASE_ORDER);
  const canCreateItem = useCan(Actions.CREATE_STOCK_ITEM);
  const [isActionsFilterModalOpen, setIsActionsFilterModalOpen] =
    useState<boolean>(false);

  const {
    data: supplierData,
    isLoading,
    isError: isErrorSupplier,
  } = useGetSingleSupplier(id || '');

  const { data: supplierComments } = useGetSingleSupplierComments(
    id || '',
    true,
    FIRST_PAGE,
    COMMENTS_PER_PAGE
  );

  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);

  const {
    mutate: deleteSupplier,
    isLoading: deleteSupplierIsLoading,
    isSuccess: deleteSupplierIsSuccess,
  } = useDeleteSupplier();

  const {
    mutate: activateSupplier,
    isLoading: activateSupplierIsLoading,
    isSuccess: activateSupplierIsSuccess,
  } = useActivateSupplier();

  const { data: infoData } = useGetSingleSupplierInfo(id!);

  useEffect(() => {
    if (deleteSupplierIsSuccess) {
      setIsDeleteModalOpen(false);
    }
  }, [deleteSupplierIsSuccess]);

  useEffect(() => {
    if (activateSupplierIsSuccess) {
      setIsActivateModalOpen(false);
    }
  }, [activateSupplierIsSuccess]);

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const { isTablet, isSmallTablet } = useBreakpointFlag();
  const isScrolled = useScrollPosition(45, isSmallTablet);
  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();

  return !isErrorSupplier ? (
    <Container>
      {!isSmallTablet && (
        <SubSideMenu
          pwId={PWID}
          subMenuItems={subMenuItems}
          subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.supplier_info)}
        />
      )}
      <Wrapper>
        <HeaderWrapper
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
          isScrolled={isScrolled}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                pwId={`${PWID}-back-arrow`}
                svg={CaretLeft}
                marginRight="15rem"
                size={15}
                color={COLORS.BLACK}
                weight="regular"
                onClick={() => navigate(RoutesConfig.Suppliers.fullPath)}
              />
              {isLoading ? (
                <Spinner size={15} />
              ) : (
                <H3 data-testid={`${PWID}-company-name`}>
                  {supplierData?.supplier?.company_name}
                </H3>
              )}

              {isSmallTablet && (
                <ThreeDots>
                  <Icon
                    svg={DotsThree}
                    color={COLORS.BLACK}
                    size={30}
                    weight="bold"
                    onClick={() => setIsActionsFilterModalOpen(true)}
                  />
                </ThreeDots>
              )}
            </LeftSide>
            {!isSmallTablet && (
              <RightSide>
                <ImgWrapper>
                  <Tooltip content={t('Comments')} key={'commentKey'}>
                    <Icon
                      svg={ChatCircle}
                      color={COLORS.PRIMARY}
                      size={25}
                      onClick={() => setIsCommentModalOpen(true)}
                    />
                  </Tooltip>
                  {!!supplierComments?.total && (
                    <Icon
                      svg={WarningCircle}
                      size={25}
                      color={COLORS.PRIMARY}
                    />
                  )}
                </ImgWrapper>
              </RightSide>
            )}
          </TitleContainer>

          {isSmallTablet && (
            <MobileSubMenu
              subMenuItems={subMenuItems}
              subMenuItemsInfo={mapSubSideMenuItemsInfo(
                infoData?.supplier_info
              )}
            />
          )}

          {!isSmallTablet && (
            <ButtonContainer>
              <ButtonWrapper>
                {!supplierData?.supplier?.deleted_at ? (
                  <Button
                    pwId={`${PWID}-delete-supplier-button`}
                    width={'200rem'}
                    onClick={() => setIsDeleteModalOpen(true)}
                    label={t('Delete Supplier')}
                    disabled={!canEditSupplier}
                    redOutline
                  />
                ) : (
                  <Button
                    pwId={`${PWID}-activate-supplier-button`}
                    width={'200rem'}
                    onClick={() => setIsActivateModalOpen(true)}
                    secondary
                    label={t('Activate Supplier')}
                    disabled={!canEditSupplier}
                  />
                )}
                <Button
                  pwId={`${PWID}-create-item-button`}
                  width={'200rem'}
                  onClick={() =>
                    navigate(RoutesConfig.NewStockItem.fullPath, {
                      state: { prepopulatePurchaseOrderData: supplierData },
                    })
                  }
                  label={t('Create stock item')}
                  secondary
                  disabled={!canCreateItem}
                />
                <Button
                  pwId={`${PWID}-create-purchase-order-button`}
                  width={'200rem'}
                  onClick={() =>
                    navigate(
                      `${SingleSupplierRoutes.SingleSupplierNewPurchaseOrder.fullPath}`.replace(
                        ':id',
                        id || ''
                      ),
                      {
                        state: {
                          prepopulatePurchaseOrderData: {
                            supplierData: supplierData,
                          },
                        },
                      }
                    )
                  }
                  label={t('Create purchase order')}
                  primary
                  disabled={!canCreatePurchaseOrder}
                />
              </ButtonWrapper>
            </ButtonContainer>
          )}
        </HeaderWrapper>

        <RouterWrapper
          routerWithoutPaddingBottom={routerWithoutPaddingBottom}
          isScrolled={isScrolled}
        >
          <SingleSupplierRouter />
        </RouterWrapper>
      </Wrapper>

      <YesOrNoModal
        pwId={`${PWID}-activate-supplier-modal`}
        disabled={activateSupplierIsLoading}
        isOpen={isActivateModalOpen}
        setIsOpen={setIsActivateModalOpen}
        onYes={() => {
          activateSupplier(id || '');
        }}
        onNo={() => setIsActivateModalOpen(false)}
        title={t('Activate Supplier')}
        description={`${t(`Are you sure you want to activate this Supplier`)}?`}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          pwId={PWID}
          submit={{
            onClick: () => {
              deleteSupplier(id || '');
            },
            text: t('Delete'),
            disabled: deleteSupplierIsLoading,
          }}
          cancel={{ onClick: () => setIsDeleteModalOpen(false) }}
          title={t('Delete Supplier')}
          description={
            t(
              `Are you sure you want to set this Supplier's status to Deleted`
            ) + '?'
          }
        />
      </Modal>
      <CommentsModal
        pwId={PWID}
        isOpen={isCommentModalOpen}
        setIsOpen={setIsCommentModalOpen}
        supplierId={id || ''}
        onCancel={() => setIsCommentModalOpen(false)}
      />

      <ActionsMobileModal
        isOpen={isActionsFilterModalOpen}
        setIsOpen={setIsActionsFilterModalOpen}
        data={supplierData}
        canEditSupplier={canEditSupplier}
        setIsCommentModalOpen={setIsCommentModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        setIsActivateModalOpen={setIsActivateModalOpen}
        supplierComments={supplierComments}
        canCreateItem={canCreateItem}
        id={id}
        canCreatePurchaseOrder={canCreatePurchaseOrder}
      />
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SingleSupplierPage;
