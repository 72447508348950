import {
  borderRadiusSm,
  COLORS,
  marginLg,
  marginMd,
  marginXs,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import Button from 'components/Button/Button';
import CustomSelect from 'components/Select/FormikSelect';
import { Field } from 'formik';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${marginLg};
  margin-left: ${marginLg};
  margin-right: ${marginLg};
  flex: 1;
  align-items: center;

  ${respondTo.smallTablet`
    margin: 0;
    padding: 15rem;
  `}
`;

export const AddSupplierWrapper = styled.div`
  display: flex;
  max-width: 750rem;
  width: 100%;
`;

export const SupplierDetailsContainer = styled.div`
  margin-top: ${marginMd};
  padding: 30rem;
  max-width: 750rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const SupplierDetailsLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  margin-bottom: ${marginMd};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${marginXs};
  justify-content: space-between;
  gap: 30rem;

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 10rem; 
  `}
`;

export const StyledSelect = styled(Field)`
  margin-left: ${marginMd};
  width: 203rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const StyledCustomSelect = styled(CustomSelect)`
  margin-right: 10rem;
  width: 203rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${marginMd};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const ButtonCreate = styled(Button)`
  margin-left: ${marginMd};
`;

export const StyledSelectNoMargin = styled(Field)`
  width: 50%;
  padding-right: 15rem;

  ${respondTo.bigPhone`
    width: 100%;
    padding-right: 0;
  `}
`;
