import { COLORS, borderRadiusSm, marginMd, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  max-width: 850rem;
  width: 100%;
  padding: ${paddingMd};
  border-radius: ${borderRadiusSm};
  background-color: ${COLORS.WHITE};

  ${respondTo.smallTablet`
    max-width: calc(100% - 40rem);
  `}
`;

export const TopRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
    gap: 20rem;
  `}
`;

export const LocationLabel = styled.label`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
`;
