import { COLORS, marginLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { Form } from 'formik';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 15rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const StyledLink = styled(Link)``;

export const Logo = styled.img`
  width: 179rem;
  height: 261rem;
  margin-bottom: ${marginLg};

  @media screen and (max-width: 1440px) {
    margin-bottom: 30rem;
  }

  ${respondTo.bigPhone`
    width: 150rem;
    height: auto;
  `}
`;

export const Headline = styled.h2`
  color: ${COLORS.BLACK};
  font-size: 50rem;
  line-height: 75rem;
  font-weight: 500rem;
  margin-bottom: ${marginLg};

  @media screen and (max-width: 1440px) {
    margin-bottom: 30rem;
  }

  ${respondTo.smallTablet`
    font-size: 40rem;
  `}

  ${respondTo.bigPhone`
    font-size: 35rem;
  `}
`;

export const Label = styled.span`
  font-size: 16rem;
  color: ${COLORS.BLACK};
  margin-bottom: 10rem;
  text-align: center;
  width: 580rem;
  margin-bottom: ${marginLg};

  ${respondTo.smallTablet`
    margin-bottom: 30rem;
  `}

  ${respondTo.bigPhone`
    max-width: 100%;
    width: 100%;
  `}
`;

export const LinkLabel = styled(Link)`
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  text-decoration: underline;
  color: ${COLORS.BLACK};
`;

export const RowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem;
`;

export const StyledForm = styled(Form)`
  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;
