import styled from 'styled-components';
import { COLORS, H4, marginSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10rem;
  padding: 30rem;
  background-color: ${COLORS.WHITE};
  width: 100%;
  overflow: hidden;
  height: 200rem;

  ${respondTo.midTablet`
    height: auto;
  `}

  ${respondTo.bigPhone`
    padding: 20rem;
  `}
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6.17rem;
  cursor: pointer;
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 50%;
`;
export const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginSm};
  gap: 30rem;
`;
export const NoPermissionMessage = styled.div`
  color: ${COLORS.BLACK};
  font-size: 16rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const NoPermissionMessageAlt = styled(NoPermissionMessage)`
  justify-content: flex-start;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const Name = styled.div`
  display: flex;
  font-family: Poppins;
  font-size: 16rem;
  font-weight: 300;
  line-height: 16rem;
  letter-spacing: 0.75rem;
  text-align: left;
  white-space: nowrap;
  margin-right: ${marginXs};
  color: ${COLORS.BLACK};
`;
export const Description = styled.div`
  text-transform: uppercase;
  display: flex;
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  letter-spacing: 0.005em;
  text-align: left;
  color: ${COLORS.PRIMARY};
  white-space: nowrap;
`;

export const CustomersSuppliersLabel = styled(H4)`
  text-transform: uppercase;
  margin-bottom: ${marginXs};
  color: ${COLORS.BLACK};
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
