import {
  BackgroundColorWrapper,
  CardContainer,
  Heading,
  TimelineCard,
  EditedFromToLabel,
  FirstRow,
  Container,
  SwiperWrapper,
} from './SingleQuotationGeneralPage.styled';
import { useCreateSalesOrder, useGetSingleQuotation } from '../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import SimpleWideCard from 'components/Cards/SimpleWideCard/SimpleWideCard';
import { useGetHistoryItems } from './hooks';
import CustomSwiper from 'components/CustomSwiper/CustomSwiper';
import { useShowCreateSalesOrderAfterSignatureModal } from './hooks';
import { Dispatch, useEffect, useState } from 'react';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { formatNumber } from 'utils/numberFormatter';
import { QuotationStatuses } from '../constants';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { EditOrderDetailsModal } from '../EditOrderDetailsModal/EditOrderDetailsModal';
import { EditCustomerDetailsModal } from 'components/Modal/EditCustomerDetailsModal/EditCustomerDetailsModal';
import { EditCustomerDetailsType } from 'components/Modal/EditCustomerDetailsModal/constants';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useLocale } from 'utils/hooks/useLocale';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SingleQuotationGeneralPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { locale } = useLocale();
  const currencySymbol = useGetCurrencySymbol();

  const { data: quotation, isLoading } = useGetSingleQuotation(
    typeof id === 'string' ? id : ''
  );

  const [isEditCustomerDetailsModalOpen, setIsEditCustomerDetailsModalOpen] =
    useState<boolean>(false);
  const [isEditCustomerFailedModalOpen, setIsEditCustomerFailedModalOpen] =
    useState<boolean>(false);
  const [isEditOrderDetailsModalOpen, setIsEditOrderDetailsModalOpen] =
    useState<boolean>(false);

  const canEditQuotation = useCan(Actions.EDIT_QUOTATION);

  const [
    isCreateSalesOrderAfterSignatureModalShown,
    setIsCreateSalesOrderAfterSignatureModalShown,
  ] = useShowCreateSalesOrderAfterSignatureModal() as [
    boolean,
    Dispatch<React.SetStateAction<boolean>>
  ];

  const isClosed = () =>
    quotation?.status?.name === QuotationStatuses.ACCEPTED ||
    quotation?.status?.name === QuotationStatuses.NOT_ACCEPTED ||
    quotation?.status?.name === QuotationStatuses.COPIED_TO_NEW;

  const orderDetailsCardLeftData = {
    'Quotation number': quotation?.number,
    'Due date': quotation?.valid_to
      ? moment(quotation.valid_to.split('T')[0])
          .locale(locale)
          .format('MMM DD, YYYY')
      : null,
    'Creation date': quotation?.created_at
      ? moment(quotation.created_at.split('T')[0])
          .locale(locale)
          .format('MMM DD, YYYY')
      : '',
    'Closed date': quotation?.closed_at
      ? moment(quotation.closed_at.split('T')[0])
          .locale(locale)
          .format('MMM DD, YYYY')
      : '',
    'Sales person': quotation?.user?.name + ' ' + quotation?.user?.last_name,
    Reference: quotation?.reference || '',
    'Payment condition': quotation?.payment_condition,
  };
  const orderDetailsCardRightData = {
    'Total sales amount': `${currencySymbol}${formatNumber(
      quotation?.total_amount
    )}`,
    'Expected total purchase cost exc. VAT': `${currencySymbol}${formatNumber(
      quotation?.total_cost
    )}`,
    'Total discount': `${currencySymbol}${
      quotation?.total_discount_amount
        ? formatNumber(quotation?.total_discount_amount)
        : '0'
    }`,
    'Expected profit': `${quotation?.expected_profit}%`,
    'Down payment': quotation?.downpayment
      ? `${currencySymbol}${formatNumber(quotation?.downpayment)}`
      : '',
  };

  const customerDetailsCardLeftData = {
    Number: quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.customer_number
      : quotation?.customer?.number,
    Name: quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.first_name +
        ' ' +
        quotation?.customer_snapshot?.last_name
      : quotation?.customer?.name + ' ' + quotation?.customer?.last_name,
    Address: quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.address?.full_address
      : quotation?.customer?.address?.full_address,
    'Company name': quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.company_name
      : quotation?.customer?.company_name,
    'Company VAT': quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.company_vat
      : quotation?.customer?.vat_number,
    customerID: quotation?.customer_id,
  };
  const customerDetailsCardRightData: Record<string, any> = {
    'E-mail': quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.email
      : quotation?.customer?.email,
    'Phone number': quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.phone_number
      : quotation?.customer?.phone,

    ...(quotation?.customer_snapshot?.alternative_invoice_email && {
      'Alternative invoice E-mail':
        quotation?.customer_snapshot?.alternative_invoice_email,
    }),
  };

  const showMoreData = {
    Name: quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.shipping_data?.first_name +
        ' ' +
        quotation?.customer_snapshot?.shipping_data?.last_name
      : quotation?.shipping_data?.first_name +
        ' ' +
        quotation?.shipping_data?.last_name,
    'E-mail': quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.shipping_data?.email
      : quotation?.shipping_data?.email,
    Address: quotation?.shipping_data?.address?.full_address,
    'Company name': quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.shipping_data?.company_name
      : quotation?.shipping_data?.company_name,
    'Phone number': quotation?.customer_snapshot
      ? quotation?.customer_snapshot?.shipping_data?.phone
      : quotation?.shipping_data?.phone,
  };

  const {
    mutate: createSalesOrder,
    data: newSalesOrder,
    isSuccess: createSalesOrderIsSuccess,
  } = useCreateSalesOrder();

  useEffect(() => {
    if (createSalesOrderIsSuccess) {
      navigate(`/sales/${newSalesOrder?.sales_order?.id}`, {
        state: {
          createdFromQuotation: true,
        },
      });
    }
  }, [createSalesOrderIsSuccess]);

  const editedFromQuotation = quotation?.parent_quotation;
  const editedToQuotation = quotation?.children_quotations?.length
    ? quotation?.children_quotations[0]
    : undefined;

  const { historyItems, isFetchedHistoryItems } = useGetHistoryItems(quotation);

  return (
    <Container className="single-quotation-step-5">
      <FirstRow>
        {editedFromQuotation && (
          <EditedFromToLabel
            onClick={() => navigate(`/quotations/${editedFromQuotation.id}`)}
          >
            {`${t('Edited from')} ${editedFromQuotation.number}`}
          </EditedFromToLabel>
        )}
        {editedToQuotation && (
          <EditedFromToLabel
            onClick={() => navigate(`/quotations/${editedToQuotation.id}`)}
          >
            {`${t('Edited to')} ${editedToQuotation.number}`}
          </EditedFromToLabel>
        )}
      </FirstRow>
      <CardContainer>
        <SimpleWideCard
          cardTitle={t('Customer details')}
          leftSubtitle={t('GENERAL')}
          rightSubtitle={t('CONTACT')}
          leftData={customerDetailsCardLeftData}
          rightData={customerDetailsCardRightData}
          showMoreData={showMoreData}
          isLoading={isLoading}
          onEditClick={() => {
            isClosed()
              ? setIsEditCustomerFailedModalOpen(true)
              : setIsEditCustomerDetailsModalOpen(true);
          }}
        />
        <SimpleWideCard
          cardTitle={t('Order details')}
          leftSubtitle={t('GENERAL')}
          rightSubtitle={t('PRICE')}
          leftData={orderDetailsCardLeftData}
          rightData={orderDetailsCardRightData}
          isLoading={isLoading}
          editable={canEditQuotation}
          onEditClick={() => {
            isClosed()
              ? setIsEditCustomerFailedModalOpen(true)
              : setIsEditOrderDetailsModalOpen(true);
          }}
        />
        <TimelineCard>
          <BackgroundColorWrapper>
            <Heading>{t('Timeline')}</Heading>
            {isFetchedHistoryItems && historyItems?.length ? (
              <SwiperWrapper>
                <CustomSwiper historyItems={historyItems} />
              </SwiperWrapper>
            ) : null}
          </BackgroundColorWrapper>
        </TimelineCard>
      </CardContainer>
      <YesOrNoModal
        pwId="yes-or-no-create-sales-order-modal"
        isOpen={isCreateSalesOrderAfterSignatureModalShown}
        setIsOpen={setIsCreateSalesOrderAfterSignatureModalShown}
        onYes={() => createSalesOrder({ quotationId: id! })}
        onNo={() => setIsCreateSalesOrderAfterSignatureModalShown(false)}
        title={''}
        description={t('Do you want to create a sales order') + '?'}
      />
      <OkModal
        isOpen={isEditCustomerFailedModalOpen}
        setIsOpen={setIsEditCustomerFailedModalOpen}
        description={t('You cannot edit a closed quotation') + '.'}
        buttonLabel={t('Close')}
        level="FIRST"
        onClick={() => setIsEditCustomerFailedModalOpen(false)}
      />
      {quotation && (
        <EditCustomerDetailsModal
          data={quotation}
          setIsOpen={setIsEditCustomerDetailsModalOpen}
          isOpen={isEditCustomerDetailsModalOpen}
          onCancel={() => setIsEditCustomerDetailsModalOpen(false)}
          type={EditCustomerDetailsType.QUOTATION}
        />
      )}
      {quotation && (
        <EditOrderDetailsModal
          quotationData={quotation}
          setIsOpen={setIsEditOrderDetailsModalOpen}
          isOpen={isEditOrderDetailsModalOpen}
          onCancel={() => setIsEditOrderDetailsModalOpen(false)}
        />
      )}
    </Container>
  );
};

export default SingleQuotationGeneralPage;
