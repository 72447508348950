import {
  Container,
  LeftSide,
  RightSide,
  TitleContainer,
  ImgWrapper,
  Wrapper,
  HeaderWrapper,
  RouterWrapper,
  ButtonContainer,
  ButtonWrapper,
  MollieCustomerSpan,
  ButtonContainerAlt,
  ThreeDots,
} from './SingleCustomerPage.styled';
import { H3 } from 'assets/styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  useActivateCustomer,
  useDeleteCustomer,
  useGetSingleCustomerComments,
  useGetSingleCustomer,
  useGetSingleCustomerInfo,
} from './hooks';
import { RoutesConfig } from 'navigation/routes';
import { useEffect, useState } from 'react';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { CommentsModal } from './CommentsModal/CommentsModal';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import Tooltip from 'components/Tooltip/Tooltip';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import {
  CaretLeft,
  ChatCircle,
  DotsThree,
  WarningCircle,
} from '@phosphor-icons/react';
import { AddNewMollieCustomerModal } from './AddNewMollieCustomerModal/AddNewMollieCustomerModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { useCheckRouterWithoutPaddingBottom } from 'navigation/hooks';
import SingleCustomerRouter from 'navigation/SingleCustomer/SingleCustomerRouter';
import { getSubMenuItems } from './helpers';
import SubSideMenu from 'components/Layout/Navigation/SubSideMenu/SubSideMenu';
import { mapSubSideMenuItemsInfo } from 'components/Layout/Navigation/SubSideMenu/helpers';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import { INewAltContactNavigationState } from './types';
import useScrollPosition from 'utils/hooks/useScrollMobileSubMenu';
import MobileSubMenu from 'components/Layout/Navigation/MobileSubMenu/MobileSubMenu';
import ActionsMobileModal from './ActionsMobileModal/ActionsMobileModal';
import { AppointmentType } from 'types/Appointment.types';
import { ICreateAppointmentNavigationState } from '../PlanningPage/NewAppointmentModal/hooks';

const COMMENTS_PER_PAGE = 3;
const FIRST_PAGE = 1;
const PWID = 'single-customer-page';

const SingleCustomerPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation();
  const { id } = useParams();
  const canEditCustomerAndQuotations = useCan(Actions.EDIT_SALES_ORDER);

  const isOnAlternativeContactsRoute = location.pathname.includes(
    SingleCustomerRoutes.SingleCustomerAltContacts.path
  );

  const {
    data: customerData,
    isLoading,
    isError: isErrorCustomer,
  } = useGetSingleCustomer(id!);
  const { data: customerComments } = useGetSingleCustomerComments(
    id!,
    true,
    FIRST_PAGE,
    COMMENTS_PER_PAGE
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isActivateModalOpen, setIsActivateModalOpen] =
    useState<boolean>(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState<boolean>(false);
  const [isAddMollieCustomerModalOpen, setIsAddMollieCustomerModalOpen] =
    useState<boolean>(false);
  const [isActionsFilterModalOpen, setIsActionsFilterModalOpen] =
    useState<boolean>(false);

  const {
    mutate: deleteCustomer,
    isLoading: deleteCustomerIsLoading,
    isSuccess: deleteCustomerIsSuccess,
  } = useDeleteCustomer();

  const {
    mutate: activateCustomer,
    isLoading: activateCustomerIsLoading,
    isSuccess: activateCustomerIsSuccess,
  } = useActivateCustomer();

  const { data: infoData } = useGetSingleCustomerInfo(id!);

  useEffect(() => {
    if (deleteCustomerIsSuccess) {
      setIsDeleteModalOpen(false);
    }
  }, [deleteCustomerIsSuccess]);

  useEffect(() => {
    if (activateCustomerIsSuccess) {
      setIsActivateModalOpen(false);
    }
  }, [activateCustomerIsSuccess]);

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const { isTablet, isSmallTablet } = useBreakpointFlag();
  const isScrolled = useScrollPosition(45, isSmallTablet);
  const routerWithoutPaddingBottom = useCheckRouterWithoutPaddingBottom();

  return !isErrorCustomer ? (
    <Container>
      {!isSmallTablet && (
        <SubSideMenu
          pwId={PWID}
          subMenuItems={getSubMenuItems(customerData)}
          subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.customer_info)}
        />
      )}
      <Wrapper>
        <HeaderWrapper
          isOnAlternativeContactsRoute={isOnAlternativeContactsRoute}
          isSideMenuCollapsed={isSideMenuCollapsed}
          isTablet={isTablet}
          isScrolled={isScrolled}
        >
          <TitleContainer>
            <LeftSide>
              <Icon
                pwId={`${PWID}-back-arrow`}
                svg={CaretLeft}
                marginRight="15rem"
                size={15}
                color={COLORS.BLACK}
                weight="regular"
                onClick={() => navigate(RoutesConfig.Customers.fullPath)}
              />
              {isLoading ? (
                <Spinner size={15} />
              ) : (
                <>
                  <H3
                    data-testid={`${PWID}-customer-full-name`}
                  >{`${customerData?.customer?.name} ${customerData?.customer?.last_name}`}</H3>
                  {customerData?.customer?.mollie_customer && (
                    <MollieCustomerSpan>{`${t(
                      'Mollie customer'
                    )}`}</MollieCustomerSpan>
                  )}
                </>
              )}

              {isSmallTablet && (
                <ThreeDots>
                  <Icon
                    svg={DotsThree}
                    color={COLORS.BLACK}
                    size={30}
                    weight="bold"
                    onClick={() => setIsActionsFilterModalOpen(true)}
                  />
                </ThreeDots>
              )}
            </LeftSide>
            {!isSmallTablet && (
              <RightSide>
                <ImgWrapper>
                  <Tooltip content={t('Comments')} key={'commentKey'}>
                    <Icon
                      svg={ChatCircle}
                      color={COLORS.PRIMARY}
                      size={25}
                      onClick={() => setIsCommentModalOpen(true)}
                    />
                  </Tooltip>
                  {!!customerComments?.total && (
                    <Icon
                      svg={WarningCircle}
                      size={25}
                      color={COLORS.PRIMARY}
                    />
                  )}
                </ImgWrapper>
              </RightSide>
            )}
          </TitleContainer>

          {isSmallTablet && (
            <MobileSubMenu
              subMenuItems={getSubMenuItems(customerData)}
              subMenuItemsInfo={mapSubSideMenuItemsInfo(
                infoData?.customer_info
              )}
            />
          )}

          {!isSmallTablet && (
            <ButtonContainer>
              <ButtonWrapper>
                {!customerData?.customer?.deleted_at ? (
                  <Button
                    pwId={`${PWID}-delete-customer-button`}
                    width={'200rem'}
                    onClick={() => setIsDeleteModalOpen(true)}
                    label={t('Delete Customer')}
                    redOutline
                    disabled={!canEditCustomerAndQuotations}
                  />
                ) : (
                  <Button
                    pwId={`${PWID}-activate-customer-button`}
                    width={'200rem'}
                    onClick={() => setIsActivateModalOpen(true)}
                    secondary
                    label={t('Activate Customer')}
                    disabled={!canEditCustomerAndQuotations}
                  />
                )}
                <Button
                  onClick={() => {
                    const navigationState: ICreateAppointmentNavigationState = {
                      openModal: true,
                      type: AppointmentType.MEASURING,
                      customer_id: id,
                      customer_number: customerData?.customer?.number,
                    };
                    navigate(RoutesConfig.Planning.fullPath, {
                      state: navigationState,
                    });
                  }}
                  secondary
                  width="200rem"
                  label={t('New measurement')}
                />
                {/* {
                  //intented use is for automated Mollie mandates, but doesn't seem to be used currently
                  !customerData?.customer?.mollie_customer && (
                    <Button
                      pwId={`${PWID}-link-to-Mollie-button`}
                      onClick={() => setIsAddMollieCustomerModalOpen(true)}
                      secondary
                      width="200rem"
                      label={t('Link to Mollie')}
                    />
                  )
                } */}
                <Button
                  pwId={`${PWID}-create-quotation-button`}
                  width={'200rem'}
                  label={t('Create quotation')}
                  primary
                  disabled={!canEditCustomerAndQuotations}
                  onClick={() => {
                    navigate(RoutesConfig.NewQuotation.fullPath, {
                      state: {
                        prepopulateQuotationDataForCustomer:
                          customerData.customer,
                      },
                    });
                  }}
                />
              </ButtonWrapper>
            </ButtonContainer>
          )}
          {isOnAlternativeContactsRoute && !isSmallTablet && (
            <ButtonContainerAlt>
              <Button
                pwId={`${PWID}-create-alternative-contact-button`}
                width={'200rem'}
                label={t('Create alternative contact')}
                primary
                onClick={() => {
                  const navigationState: INewAltContactNavigationState = {
                    customerId: Number(id),
                  };
                  navigate(RoutesConfig.NewAltContact.fullPath, {
                    state: navigationState,
                  });
                }}
              />
            </ButtonContainerAlt>
          )}
        </HeaderWrapper>

        <RouterWrapper
          isScrolled={isScrolled}
          isOnAlternativeContactsRoute={isOnAlternativeContactsRoute}
          routerWithoutPaddingBottom={routerWithoutPaddingBottom}
        >
          <SingleCustomerRouter />
        </RouterWrapper>
      </Wrapper>
      <YesOrNoModal
        pwId={PWID}
        disabled={activateCustomerIsLoading}
        isOpen={isActivateModalOpen}
        setIsOpen={setIsActivateModalOpen}
        onYes={() => {
          activateCustomer(id!);
        }}
        onNo={() => setIsActivateModalOpen(false)}
        title={t('Activate Customer')}
        description={`${t(`Are you sure you want to activate this Customer`)}?`}
      />
      <Modal
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          pwId={PWID}
          submit={{
            onClick: () => {
              deleteCustomer(id!);
            },
            text: t('Delete'),
            disabled: deleteCustomerIsLoading,
          }}
          cancel={{ onClick: () => setIsDeleteModalOpen(false) }}
          title={t('Delete Customer')}
          description={
            t(
              `Are you sure you want to set this Customer's status to Deleted`
            ) + '?'
          }
        />
      </Modal>
      <AddNewMollieCustomerModal
        pwId={PWID}
        isOpen={isAddMollieCustomerModalOpen}
        setIsOpen={setIsAddMollieCustomerModalOpen}
        onCancel={() => setIsAddMollieCustomerModalOpen(false)}
        customerId={id!}
      />
      <CommentsModal
        isOpen={isCommentModalOpen}
        setIsOpen={setIsCommentModalOpen}
        customerId={id!}
        onCancel={() => setIsCommentModalOpen(false)}
      />

      <ActionsMobileModal
        id={id!}
        isOpen={isActionsFilterModalOpen}
        setIsOpen={setIsActionsFilterModalOpen}
        data={customerData}
        canEditCustomerAndQuotations={canEditCustomerAndQuotations}
        setIsCommentModalOpen={setIsCommentModalOpen}
        setIsDeleteModalOpen={setIsDeleteModalOpen}
        setIsActivateModalOpen={setIsActivateModalOpen}
        setIsAddMollieCustomerModalOpen={setIsAddMollieCustomerModalOpen}
        customerComments={customerComments}
      />
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SingleCustomerPage;
