import { gapMd, marginLg, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

interface StyledProps {
  isRed: string;
}

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1075rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};
  gap: 30rem;
  padding: 0 15rem;
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
  margin-top: ${marginLg};

  ${respondTo.smallTablet`
    margin-top: 20rem;
  `}
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${gapMd};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
