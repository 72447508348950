import {
  AppointmentTypeLabel,
  HorizontalLine,
  ButtonsWrapper,
} from './ReportForm.styled';
import { useEffect, useRef, useState } from 'react';
import {
  AppointmentType,
  IAppointment,
  IAppointmentInterval,
  IFwAppointmentLine,
} from 'types/Appointment.types';
import { ISalesInvoice } from 'types/Invoice.types';
import {
  IReportPageErrors,
  isFormValid,
  validateAll,
  validateSignature,
} from './validation';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { ISubmitReportDTO } from '../hooks';
import { useSubmitReport } from './hooks';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import GeneralPicturesSection from './GeneralPicturesSection/GeneralPicturesSection';
import TimerSection from './TimerSection/TimerSection';
import ProductsSection from './ProductsSection/ProductsSection';
import SituationDescriptionResourceSection, {
  IResourceWorkingHours,
} from './SituationDescriptionResourceSection/SituationDescriptionResourceSection';
import SignatureAndCommentsSection from './SignatureAndCommentsSection/SignatureAndCommentsSection';
import InvoicesSection from './InvoicesSection/InvoicesSection';
import { formatResourceWorkingHoursIntoReportResources } from './helpers';
import { IQuotation, QuotationTypeIdsEnum } from 'types/Quotations.types';
import { Level } from 'components/Modal/type';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import Loader from 'components/Loader/Loader';

export interface IReportData {
  appointment: IAppointment;
  appointmentLines: IFwAppointmentLine[];
  intervals: IAppointmentInterval[];
  invoices: ISalesInvoice[];
  firstName: string;
  lastName: string;
  signatureFullName: string;
}

export interface IInitialReportData {
  newQuotation: IQuotation | null;
  pictures: any[];
  situationDescription: string;
  followUpActionDescription: string;
  furtherComments: string;
  signature: string;
  expectedTotalWorkingHours: number;
  resourceWorkingHours: IResourceWorkingHours[];
}

interface IReportFormProps {
  reportData: IReportData;
  isLoadingIntervals: boolean;
  isLoadingInvoices: boolean;
  initialData?: IInitialReportData; // Readonly mode
  previewInvoiceModalLevel?: Level;
}

const ReportForm = ({
  reportData,
  initialData,
  isLoadingIntervals,
  isLoadingInvoices,
  previewInvoiceModalLevel = 'FIRST',
}: IReportFormProps) => {
  const isReadOnly = !!initialData;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [pictures, setPictures] = useState<any[]>(initialData?.pictures || []);
  const [situationDescription, setSituationDescription] = useState<string>(
    initialData?.situationDescription || ''
  );
  const [isFollowUpActionRequired, setIsFollowUpActionRequired] =
    useState<boolean>(!!initialData?.followUpActionDescription || false);
  const [followUpActionDescription, setFollowUpActionDescription] =
    useState<string>(initialData?.followUpActionDescription || '');
  const [furtherComments, setFurtherComments] = useState<string>(
    initialData?.furtherComments || ''
  );
  const [fullName, setFullName] = useState<string>(
    reportData?.signatureFullName || ''
  );
  const sigRef = useRef() as React.MutableRefObject<any>;
  const [signature, setSignature] = useState<string>(
    initialData?.signature || ''
  );

  // Measurement only
  const [expectedTotalWorkingHours, setExpectedTotalWorkingHours] = useState<
    number | null
  >(initialData?.expectedTotalWorkingHours || null);
  // Measurement only
  const [resourceWorkingHours, setResourceWorkingHours] = useState<
    IResourceWorkingHours[]
  >(initialData?.resourceWorkingHours || []);

  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);

  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);

  const { appointment, appointmentLines, intervals, invoices } = reportData;

  // Error handling
  const [errors, setErrors] = useState<IReportPageErrors>({
    situationDescription: '',
    fullName: '',
    signature: '',
    followUpActionDescription: '',
  });

  const { isSmallPhone } = useBreakpointFlag();

  const handleSubmitReport = async () => {
    const validationErrors: IReportPageErrors = validateAll(
      situationDescription,
      fullName,
      signature,
      followUpActionDescription,
      t,
      isFollowUpActionRequired,
      appointment.type.name === AppointmentType.MEASURING
    );
    setErrors(validationErrors);
    if (isFormValid(validationErrors)) {
      const url = sigRef.current?.getCanvas().toDataURL('image/png');
      const signatureBlob = await fetch(url).then((r) => r.blob());
      const submitReportDTO: ISubmitReportDTO = {
        files: pictures,
        file: signatureBlob,
        situation_description: situationDescription,
        signature_fullname: fullName,
        customer_comment: furtherComments,
        expected_working_hours: expectedTotalWorkingHours,
        follow_up_action_description: followUpActionDescription,
        report_resources:
          formatResourceWorkingHoursIntoReportResources(resourceWorkingHours),
      };
      submitReport(submitReportDTO);
    }
  };

  const {
    mutate: submitReport,
    isLoading: isLoadingSubmitReport,
    isSuccess: isSuccessSubmitReport,
  } = useSubmitReport(appointment.id.toString());

  useEffect(() => {
    if (isSuccessSubmitReport) {
      navigate(RoutesConfig.FwAppointments.fullPath);
    }
  }, [isSuccessSubmitReport]);

  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
    const error = validateSignature(sigRef.current.toDataURL(), t);
    setErrors((prevErrors) => ({ ...prevErrors, signature: error }));
  };

  return (
    <>
      {isLoadingSubmitReport && <Loader />}
      <AppointmentTypeLabel>
        {t(`${appointment?.type?.name}`)}
      </AppointmentTypeLabel>
      <GeneralPicturesSection
        isReadOnly={isReadOnly}
        newQuotation={initialData?.newQuotation || null}
        firstName={reportData.firstName}
        lastName={reportData.lastName}
        appointment={appointment}
        pictures={pictures}
        setPictures={setPictures}
      />
      <HorizontalLine />
      <TimerSection
        isReadOnly={isReadOnly}
        intervals={intervals}
        isLoadingIntervals={isLoadingIntervals}
      />
      <HorizontalLine />
      <ProductsSection
        appointment={appointment}
        appointmentLines={appointmentLines}
      />
      <HorizontalLine />
      <SituationDescriptionResourceSection
        isReadOnly={isReadOnly}
        situationDescription={situationDescription}
        setSituationDescription={setSituationDescription}
        isFollowUpActionRequired={isFollowUpActionRequired}
        setIsFollowUpActionRequired={setIsFollowUpActionRequired}
        followUpActionDescription={followUpActionDescription}
        setFollowUpActionDescription={setFollowUpActionDescription}
        errors={errors}
        setErrors={setErrors}
        expectedTotalWorkingHours={expectedTotalWorkingHours}
        setExpectedTotalWorkingHours={setExpectedTotalWorkingHours}
        resourceWorkingHours={resourceWorkingHours}
        setResourceWorkingHours={setResourceWorkingHours}
        isMeasuring={appointment?.type?.name === AppointmentType.MEASURING}
      />
      <HorizontalLine />
      <SignatureAndCommentsSection
        isReadOnly={isReadOnly}
        sigRef={sigRef}
        signature={signature}
        setSignature={setSignature}
        handleSignatureEnd={handleSignatureEnd}
        furtherComments={furtherComments}
        setFurtherComments={setFurtherComments}
        fullName={fullName}
        setFullName={setFullName}
        errors={errors}
        setErrors={setErrors}
        isMeasuring={appointment.type.name === AppointmentType.MEASURING}
      />
      {appointment?.type?.name === AppointmentType.MEASURING ? null : (
        <>
          <HorizontalLine />
          <InvoicesSection
            isLoadingInvoices={isLoadingInvoices}
            invoices={invoices}
            previewInvoiceModalLevel={previewInvoiceModalLevel}
          />
        </>
      )}
      {isReadOnly ? null : (
        <ButtonsWrapper>
          <Button
            disabled={isLoadingSubmitReport}
            secondary
            label={t('Back')}
            width={isSmallPhone ? '150rem' : '200rem'}
            onClick={() => navigate(-1)}
          />
          <Button
            disabled={
              isLoadingSubmitReport ||
              (areAllFieldsTouched ? !isFormValid(errors) : false)
            }
            primary
            label={t('Submit')}
            width={isSmallPhone ? '150rem' : '200rem'}
            onClick={() => setIsSubmitModalOpen(true)}
          />
        </ButtonsWrapper>
      )}
      <ConfirmModal
        isOpen={isSubmitModalOpen}
        setIsOpen={setIsSubmitModalOpen}
        onConfirm={() => {
          if (!areAllFieldsTouched) {
            setAreAllFieldsTouched(true);
          }
          setIsSubmitModalOpen(false);
          handleSubmitReport();
        }}
        onCancel={() => {
          if (!areAllFieldsTouched) {
            setAreAllFieldsTouched(true);
          }
          setIsSubmitModalOpen(false);
        }}
        title={t('Submit report')}
        description={
          pictures.length
            ? t('Are you sure you want to submit') + '?'
            : t(
                'You have not added any pictures, are you sure you want to submit'
              ) + '?'
        }
      />
    </>
  );
};

export default ReportForm;
