import styled from 'styled-components';
import { COLORS, marginSm, marginXs, paddingXs } from 'assets/styled';
import { Field } from 'formik';
import { hoverLineOut } from 'assets/styled/commonStyles';
import { respondTo } from 'assets/styled/breakpoints';

export const Calendar = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  position: relative;
  width: 300rem;
  font-size: 30rem;
`;

export const StyledPicker = styled(Field)`
  width: 300rem;
`;

export const DateTimeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
  width: 100%;
  max-width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: ${paddingXs};
  max-height: 859rem;

  ${respondTo.smallTablet`
    max-height: 100%;
  `}
`;

export const CheckboxContainer = styled.div`
  margin-top: 20rem;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  max-width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
`;

export const TabContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20rem;
  justify-content: left;
`;

export const TimeWrapper = styled.div`
  width: 50%;
  max-width: 100%;
`;

export const AltTimeWrapper = styled.div`
  padding-top: 10rem;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const ApplyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  padding-top: 20rem;
`;
export const WorkersAndResources = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  padding-top: ${paddingXs};
`;

export const StyledSelect = styled(Field)`
  width: 100%;
`;
export const ArrivalButton = styled.div`
  margin: 10rem 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const SalesOrderHeading = styled.div`
  padding: ${marginXs} 0;
`;
export const ProductsHeading = styled.div`
  padding: ${marginXs} 0;
`;

export const StyledSelectTop = styled(Field)`
  width: 100%;
`;

export const CustomerSection = styled.div`
  margin-top: ${marginSm};
`;

export const Cont = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HalfCont = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 10rem);
  padding: ${paddingXs} 0;
`;

export const WorkersAndResourcesHeading = styled.div`
  margin: ${marginXs} 0;
`;

export const ProductErrorLabel = styled.label`
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
`;

export const UsersHeading = styled.div`
  margin: ${marginXs} 0;
`;

export const UsersContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 40rem;
`;

export const Scrollable = styled.div`
  max-height: calc(70vh);
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 30rem;

  ${respondTo.smallTablet`
    max-height: calc(90vh);
  `}
`;

export const TimeIntervalErrorMessageCont = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const TimeIntervalErrorMessage = styled.label`
  font-size: 14rem;
  font-weight: 300;
  color: ${COLORS.RED_200};
  margin-bottom: 5rem;
`;

export const ArrivalTime = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.PRIMARY};
`;

export const ArrivalTimeText = styled.span`
  ${hoverLineOut}
  cursor: pointer;
  display: inline-block;
  align-self: auto;
  font-size: 18rem;
`;

export const ArrivalTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin-top: 5rem;
`;

export const ArrivalTimeInner = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  flex-direction: column;
`;

export const DateTimeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 10rem;
`;

export const DateLabel = styled.label`
  color: ${COLORS.PRIMARY};
  font-size: 16rem;
  display: block;
  margin-top: 5rem;
  font-weight: 300;
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
`;

export const SelectAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
`;

export const SelectAllLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 16rem;
  color: ${COLORS.PRIMARY};
  ${hoverLineOut}
`;

export const ClearLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 16rem;
  color: ${COLORS.RED_200};
  ${hoverLineOut}
`;

export const ProductsHeadingSecondRow = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10rem;
  margin-bottom: 10rem;
`;

export const PaginationWrapper = styled.div`
  margin-top: 10rem;
`;
