import { baseCustomerDetailsSchema } from 'pages/Manager/NewQuotationPage/CustomerTab/validation';
import * as Yup from 'yup';

export const createAltContactSchema = () => {
  return baseCustomerDetailsSchema().pick([
    'name',
    'last_name',
    'title',
    'country',
    'phone',
    'email',
    'code',
    'street',
    'city',
    'house_number',
  ]);
};
