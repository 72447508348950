import { Container } from './SingleQuotationQuotationsPage.styled';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useGetSingleQuotationQuotations } from './hooks';
import { IQuotation, IQuotationTableDTO } from 'types/Quotations.types';
import { formatQuotationsTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { QUOTATIONS_PER_PAGE } from './constants';
import { useState } from 'react';
import { RoutesConfig } from 'navigation/routes';
import { tableConfig } from 'pages/Manager/QuotationsPage/constants';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
const SingleQuotationQuotationsPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();
  const [page, setPage] = useState<number>(1);

  const { isLoading, data, isFetched } = useGetSingleQuotationQuotations(
    typeof id === 'string' ? id : '',
    page,
    QUOTATIONS_PER_PAGE
  );

  let tableData: IQuotationTableDTO[] = [];
  if (data) {
    tableData = formatQuotationsTableData(
      data.quotations
        .map((quotation: IQuotation[]) => {
          return quotation;
        })
        .flat(),
      currencySymbol
    );
  }

  const handleViewButtonClick = (rowData: IQuotationTableDTO) => {
    navigate(
      `${RoutesConfig.SingleQuotation.fullPath.replace(
        ':id/*',
        rowData.navigationID
      )}`
    );
  };

  const { steps } = useRunTour(
    GuidePages.SINGLE_QUOTATION_QUOTATIONS,
    id,
    isFetched
  );

  return (
    <Container className="single-quotation-step-8">
      <Table
        isLoading={isLoading}
        page={page}
        perPage={QUOTATIONS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        overflowX
      />
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleQuotationQuotationsPage;
