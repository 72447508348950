import { H4 } from 'assets/styled';
import { Field, Formik } from 'formik';
import {
  ColumnContainer,
  ColumnContainerSelect,
  Container,
  Heading,
  HorizontalLine,
  RowSpaceBetween,
  StyledCustomSelect,
} from '../EditCompanyDataModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import { IEditAddress } from '../constants';
import { editAddressTabSchema } from '../validation';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { Option } from 'components/Select/type';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
interface IAddressTabProps {
  address: IEditAddress;
  setAddress: (address: IEditAddress) => void;
  setIsValid: (isValid: boolean) => void;
}
export const AddressTab = ({
  address,
  setAddress,
  setIsValid,
}: IAddressTabProps) => {
  const { t } = useTranslation();
  const countryOptions: Option[] = useGetCountryOptions();
  const { isPhone } = useBreakpointFlag();

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={(values) => {
          //
        }}
        initialValues={address}
        validationSchema={editAddressTabSchema}
      >
        {({ values, setFieldValue, handleBlur, errors, isValid }) => {
          const handleBlurFunction = (e: any, name: string) => {
            const value = e.target.value.trim();
            setFieldValue(name, value);
            setAddress(values);
            handleBlur(e);
            setIsValid(isValid);
          };

          return (
            <Container>
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Street').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={errors['street'] ? errors['street'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'street');
                    }}
                    height={'43rem'}
                    name="street"
                    wrapperStyles={{
                      width: isPhone ? '100%' : '300rem',
                      marginBottom: isPhone ? 0 : '4rem',
                      marginTop: isPhone ? '10rem' : '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('House number').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={
                      errors['house_number'] ? errors['house_number'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'house_number');
                    }}
                    height={'43rem'}
                    name="house_number"
                    wrapperStyles={{
                      width: isPhone ? '100%' : '300rem',
                      marginBottom: isPhone ? 0 : '4rem',
                      marginTop: isPhone ? '10rem' : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('Zip code').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={errors['zip_code'] ? errors['zip_code'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'zip_code');
                    }}
                    height={'43rem'}
                    name="zip_code"
                    wrapperStyles={{
                      width: isPhone ? '100%' : '300rem',
                      marginBottom: isPhone ? 0 : '4rem',
                      marginTop: isPhone ? '10rem' : '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('City').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={errors['city'] ? errors['city'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'city');
                    }}
                    height={'43rem'}
                    name="city"
                    wrapperStyles={{
                      width: isPhone ? '100%' : '300rem',
                      marginBottom: isPhone ? 0 : '4rem',
                      marginTop: isPhone ? '10rem' : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4>{t('State').toUpperCase()}</H4>
                  </Heading>
                  <FormikInput
                    errorMessage={errors['state'] ? errors['state'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'state');
                    }}
                    height={'43rem'}
                    name="state"
                    wrapperStyles={{
                      width: isPhone ? '100%' : '300rem',
                      marginBottom: isPhone ? 0 : '4rem',
                      marginTop: isPhone ? '10rem' : '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainerSelect>
                  <Heading>
                    <H4>{t('Country').toUpperCase()}</H4>
                  </Heading>
                  <Field
                    errorMessage={errors['cca2'] ? errors['cca2'] : ''}
                    disabled={false}
                    height={'41rem'}
                    name="cca2"
                    options={countryOptions}
                    component={StyledCustomSelect}
                    isMulti={false}
                    onSelect={(option: Option) => {
                      setAddress({
                        ...values,
                        cca2: option.value,
                      });
                      setIsValid(isValid);
                    }}
                  />
                </ColumnContainerSelect>
              </RowSpaceBetween>
            </Container>
          );
        }}
      </Formik>
    </div>
  );
};
