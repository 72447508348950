import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  legendColor?: string;
}

export const LegendWrapper = styled.div`
  display: flex;
  gap: 20rem;

  ${respondTo.bigPhone`
    flex-wrap: wrap;
    gap: 10rem;
  `}
`;

export const LegendEl = styled.div`
  display: flex;
  align-items: center;
  gap: 5rem;
`;
export const LegendBox = styled.span`
  width: 15rem;
  height: 15rem;
  border-radius: 3px;

  ${(props: StyledProps) =>
    props.legendColor &&
    css`
      background-color: ${props.legendColor};
    `}
`;

export const LegendText = styled.span`
  color: ${COLORS.BLACK};
`;
