import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  DatePickerWrap,
  Heading,
  RowSpaceBetween,
  SelectWrapper,
  StyledSelect,
  Title,
  TitleContainer,
} from './EditOrderDetailsModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { Option } from 'components/Select/type';
import CustomSelect from 'components/Select/FormikSelect';
import { useParams } from 'react-router-dom';
import { useGetCompanyUsers } from 'utils/hooks/useGetCompanyUsers';
import { useEditOrderDetails } from './hooks';
import { editOrderDetailsSchema } from './validation';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';

interface IEditOrderDetailsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  quotationData: any;
}
export const EditOrderDetailsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  quotationData,
}: IEditOrderDetailsModalProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { isSmallTablet } = useBreakpointFlag();

  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company_id
  );
  const { data: companyUsers } = useGetCompanyUsers(companyId);
  const { mutate: editOrderDetails } = useEditOrderDetails(id!);

  const salesPersonOptions: Option[] = companyUsers?.users?.map((user: any) => {
    return {
      value: user,
      label: `${user.name} ${user.last_name}`,
      key: user.id,
    };
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
        overflow: 'initial',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Order details')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={{
            reference: quotationData?.reference || '',
            salesPerson: quotationData?.user,
            downpayment: quotationData?.downpayment,
            dueDate: quotationData?.valid_to,
            paymentConditionText: quotationData?.payment_condition,
            downpaymentConditionText: quotationData?.downpayment_condition,
          }}
          validationSchema={editOrderDetailsSchema}
        >
          {({
            values,
            setFieldValue,
            handleBlur,
            errors,
            isValid,
            touched,
          }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };
            return (
              <Container>
                <ColumnContainer>
                  <RowSpaceBetween>
                    <ColumnContainer>
                      <Heading>
                        <H4>{t('Sales person').toUpperCase()}</H4>
                      </Heading>
                      <SelectWrapper>
                        <StyledSelect
                          pwId="sales-person-field"
                          errorMessage={
                            touched['salesPerson'] ? errors['salesPerson'] : ''
                          }
                          name="salesPerson"
                          defaultValue={salesPersonOptions?.find(
                            (option) => option.key === quotationData?.user?.id
                          )}
                          options={salesPersonOptions}
                          component={CustomSelect}
                          isMulti={false}
                          onSelect={({ value }: any) => {
                            setFieldValue('salesPerson', value);
                          }}
                        />
                      </SelectWrapper>
                    </ColumnContainer>
                    <ColumnContainer>
                      <Heading>
                        <H4>{t('Due date').toUpperCase()}</H4>
                      </Heading>
                      <DatePickerWrap>
                        <DatePicker
                          date={moment(values?.dueDate)}
                          setDate={(newValue: any) =>
                            setFieldValue(
                              'dueDate',
                              newValue?.format('YYYY-MM-DD')
                            )
                          }
                          width={isSmallTablet ? '100%' : ''}
                        />
                      </DatePickerWrap>
                    </ColumnContainer>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ColumnContainer>
                      <Heading>
                        <H4>{t('Reference').toUpperCase()}</H4>
                      </Heading>
                      <FormikInput
                        pwId="reference-field"
                        errorMessage={
                          errors['reference']
                            ? (errors['reference'] as string)
                            : ''
                        }
                        onBlur={(e) => {
                          handleBlurFunction(e, 'reference');
                        }}
                        height={'43rem'}
                        name="reference"
                        wrapperStyles={{
                          width: isSmallTablet ? '100%' : '300rem',
                          marginBottom: isSmallTablet ? 0 : '4rem',
                          marginTop: isSmallTablet ? '10rem' : '20rem',
                        }}
                      />
                    </ColumnContainer>
                    <ColumnContainer>
                      <Heading>
                        <H4>{t('Down payment').toUpperCase()}</H4>
                      </Heading>
                      <FormikInput
                        pwId="downpayment-field"
                        errorMessage={
                          errors['downpayment']
                            ? (errors['downpayment'] as string)
                            : ''
                        }
                        onBlur={(e) => {
                          handleBlurFunction(e, 'downpayment');
                        }}
                        height={'43rem'}
                        name="downpayment"
                        wrapperStyles={{
                          width: isSmallTablet ? '100%' : '300rem',
                          marginBottom: isSmallTablet ? 0 : '4rem',
                          marginTop: isSmallTablet ? '10rem' : '20rem',
                        }}
                      />
                    </ColumnContainer>
                  </RowSpaceBetween>
                  <RowSpaceBetween>
                    <ColumnContainer>
                      <Heading>
                        <H4>{t('Payment condition text').toUpperCase()}</H4>
                      </Heading>
                      <FormikInput
                        errorMessage={
                          errors['paymentConditionText']
                            ? (errors['paymentConditionText'] as string)
                            : ''
                        }
                        onBlur={(e) => {
                          handleBlurFunction(e, 'paymentConditionText');
                        }}
                        height={'43rem'}
                        name="paymentConditionText"
                        wrapperStyles={{
                          width: isSmallTablet ? '100%' : '300rem',
                          marginBottom: isSmallTablet ? 0 : '4rem',
                          marginTop: isSmallTablet ? '10rem' : '20rem',
                        }}
                      />
                    </ColumnContainer>
                    <ColumnContainer>
                      <Heading>
                        <H4>
                          {t('Down payment condition text').toUpperCase()}
                        </H4>
                      </Heading>
                      <FormikInput
                        errorMessage={
                          errors['downpaymentConditionText']
                            ? (errors['downpaymentConditionText'] as string)
                            : ''
                        }
                        onBlur={(e) => {
                          handleBlurFunction(e, 'downpaymentConditionText');
                        }}
                        height={'43rem'}
                        name="downpaymentConditionText"
                        wrapperStyles={{
                          width: isSmallTablet ? '100%' : '300rem',
                          marginBottom: isSmallTablet ? 0 : '4rem',
                          marginTop: isSmallTablet ? '10rem' : '20rem',
                        }}
                      />
                    </ColumnContainer>
                  </RowSpaceBetween>
                </ColumnContainer>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!values.salesPerson}
                    onClick={() => {
                      if (isValid) {
                        editOrderDetails({
                          reference: values.reference,
                          sales_person_id: values.salesPerson.id,
                          downpayment_amount:
                            values.downpayment === ''
                              ? null
                              : values.downpayment,
                          due_date: moment.utc(values.dueDate, 'YYYY-MM-DD'),
                          payment_condition: values.paymentConditionText,
                          downpayment_condition:
                            values.downpaymentConditionText,
                        });
                        onCancel();
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
