import { COLORS, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  withoutMarginTop: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;

  ${respondTo.smallTablet`
    max-width: 100%;
    width: 100%;
  `}
`;

export const Card = styled.div`
  max-width: 750rem;
  width: 100%;
  border-radius: 10rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${respondTo.smallTablet`
    padding: 20rem;
    margin-top: 0;
    max-width: 100%;
  `}
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;

  ${respondTo.smallTablet`
    height: auto;
  `}
`;
export const Label = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
  margin-left: ${marginMd};

  ${respondTo.smallTablet`
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 0;
  `}
`;

export const Heading = styled.label`
  padding-bottom: 30rem;
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  width: 700rem;

  ${respondTo.smallTablet`
    width: 100%;
    padding-bottom: 20rem;
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: ${marginSm};
  flex: 1;

  ${respondTo.smallTablet`
    gap: 20rem;
    margin-bottom: 10rem;
  `}
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${respondTo.smallTablet`
    flex-direction: column;
  `}
`;

export const AddSignatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const NoContentLabel = styled.label`
  margin-top: 43rem;
  margin-bottom: 50rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-top: ${marginMd};
  margin-bottom: ${marginMd};
  ${(props: StyledProps) =>
    props.withoutMarginTop &&
    css`
      margin-top: 0;
    `};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${marginSm};
`;
