import { COLORS } from 'assets/styled';
import Placeholder from 'components/Input/Placeholder/Placeholder';
import {
  ChevronDownWrapper,
  Container,
  FakeInputLabel,
  IconWrap,
  SymbolSpan,
  SymbolWrap,
} from './FakeInput.styled';
import Icon from 'components/Icon/Icon';
import { CaretDown } from '@phosphor-icons/react';

interface IFakeInputProps {
  placeholder: string;
  label?: string;
  isDropdown?: boolean;
  width: string;
  height?: string;
  marginLeft?: string;
  marginRight?: string;
  marginBottom?: string;
  marginTop?: string;
  maxHeight?: string;
  icon?: any;
  secure?: boolean;
  symbol?: string;
  pwId?: string;
}

const FakeInput = ({
  placeholder,
  label,
  isDropdown = false,
  width,
  height,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom,
  maxHeight = '41rem',
  icon,
  secure,
  symbol,
  pwId,
}: IFakeInputProps) => {
  return (
    <Container
      data-testid={pwId}
      maxHeight={maxHeight}
      width={width}
      height={height}
      marginLeft={marginLeft}
      marginTop={marginTop}
      marginBottom={marginBottom}
      marginRight={marginRight}
    >
      <FakeInputLabel secure={secure}>{label}</FakeInputLabel>
      {isDropdown && (
        <ChevronDownWrapper>
          <Icon
            svg={CaretDown}
            size={15}
            color={COLORS.BLACK}
            pointer={false}
            disabled
          />
        </ChevronDownWrapper>
      )}
      {icon && (
        <IconWrap>
          <Icon
            svg={icon}
            size={15}
            color={COLORS.BLACK}
            pointer={false}
            disabled
          />
        </IconWrap>
      )}
      {symbol && (
        <SymbolWrap>
          <SymbolSpan>{symbol}</SymbolSpan>
        </SymbolWrap>
      )}
      <Placeholder
        isTranslatedToTop={true}
        placeholder={placeholder}
        isFormikPlaceholder={false}
        isSelectPlaceholder={false}
      />
    </Container>
  );
};

export default FakeInput;
