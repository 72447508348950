import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ActionsIcons,
  ActionsWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  InfoWrapper,
  LoaderWrapper,
  P2,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import DraftQuotationSwitch from '../SingleQuotationGeneralPage/DraftQuotationSwitch/DraftQuotationSwitch';
import Icon from 'components/Icon/Icon';
import {
  DownloadSimple,
  EnvelopeSimple,
  Signature,
  WarningCircle,
} from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { SingleQuotationRoutes } from 'navigation/SingleQuotation/SingleQuotation.routes';
import { ReactComponent as SignatureUploaded } from 'assets/icons/signature-uploaded.svg';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ReactComponent as MailFailed } from 'assets/icons/mail-failed.svg';
import { ReactComponent as MailPending } from 'assets/icons/mail-pending.svg';
import Spinner from 'components/Spinner/Spinner';
import { isQuotationClosed } from '../helpers';
import { useNavigate } from 'react-router-dom';
import { EmailStatuses } from 'types/Email.types';
import { ICreateAppointmentNavigationState } from 'pages/Manager/PlanningPage/NewAppointmentModal/hooks';
import { AppointmentType } from 'types/Appointment.types';
import { RoutesConfig } from 'navigation/routes';
import { QuotationTypeIdsEnum } from 'types/Quotations.types';

interface IActionsMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  canEditQuotation: boolean;
  isLoadingExport: boolean;
  exportQuotation: any;
  emailStatus: any;
  isFetchingQuotation: boolean;
  canCreateAppointment: boolean;
  createSalesOrderIsLoading: boolean;
  canCreateSalesOrder: boolean;
  setIsCreateSalesOrderIfDraftModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsAddEmailNoteModalOpen: Dispatch<SetStateAction<boolean>>;
  createSalesOrder: any;
  canHandleMeasurements: any;
  latestAction: any;
}

const ActionsMobileModal = ({
  isOpen,
  setIsOpen,
  data,
  canEditQuotation,
  isLoadingExport,
  exportQuotation,
  emailStatus,
  isFetchingQuotation,
  canCreateAppointment,
  createSalesOrderIsLoading,
  canCreateSalesOrder,
  setIsCreateSalesOrderIfDraftModalOpen,
  setIsAddEmailNoteModalOpen,
  createSalesOrder,
  canHandleMeasurements,
  latestAction,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isDraft = data?.type_id === QuotationTypeIdsEnum.DRAFT;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>
            {t('Quotation')} {data?.number}
          </Title>
        </TitleContainer>
        <ActionsWrapper>
          <DraftQuotationSwitch
            editable={canEditQuotation}
            quotation={data}
            canHandleMeasurements={canHandleMeasurements}
          />
          <ActionsIcons>
            {!isQuotationClosed(data) && (
              <Icon
                data-testid="add-signature"
                svg={data?.signature_uploaded ? SignatureUploaded : Signature}
                size={25}
                color={COLORS.PRIMARY}
                onClick={() => {
                  navigate(SingleQuotationRoutes.SingleQuotationSignature.path);
                  setIsOpen(false);
                }}
              />
            )}

            {isLoadingExport ? (
              <LoaderWrapper>
                <Spinner size={20} />
              </LoaderWrapper>
            ) : (
              <Icon
                data-testid="download-pdf"
                svg={DownloadSimple}
                color={COLORS.PRIMARY}
                size={25}
                onClick={() => exportQuotation(data?.id)}
              />
            )}

            <Icon
              data-testid="send-email"
              svg={
                data?.sent_to_customer
                  ? emailStatus?.status === EmailStatuses.SENT
                    ? MailSent
                    : emailStatus?.status === EmailStatuses.PENDING
                    ? MailPending
                    : MailFailed
                  : EnvelopeSimple
              }
              size={25}
              onClick={() => setIsAddEmailNoteModalOpen(true)}
              color={COLORS.PRIMARY}
            />
          </ActionsIcons>
        </ActionsWrapper>
        {latestAction?.name && (
          <InfoWrapper>
            <Icon svg={WarningCircle} size={22} color={COLORS.PRIMARY} />
            <P2>{`${t('Required')}: ${t(latestAction.name)}`}</P2>
          </InfoWrapper>
        )}

        <ButtonsWrapper>
          <Button
            disabled={
              isFetchingQuotation ||
              isQuotationClosed(data) ||
              !canCreateAppointment
            }
            onClick={() => {
              const navigationState: ICreateAppointmentNavigationState = {
                openModal: true,
                type: AppointmentType.MEASURING,
                quotation_id: data.id,
                quotation_number: data?.number,
              };
              navigate(RoutesConfig.Planning.fullPath, {
                state: navigationState,
              });
              setIsOpen(false);
            }}
            label={t('New measurement')}
            width="100%"
            secondary
          />
          <Button
            disabled={
              isFetchingQuotation ||
              isQuotationClosed(data) ||
              !canEditQuotation
            }
            onClick={() => {
              navigate(RoutesConfig.NewQuotation.fullPath, {
                state: { prepopulateQuotationData: data },
              });
              setIsOpen(false);
            }}
            label={t('New quotation')}
            width="100%"
            secondary
          />

          <Button
            disabled={
              isFetchingQuotation ||
              createSalesOrderIsLoading ||
              isQuotationClosed(data) ||
              !canCreateSalesOrder
            }
            onClick={() => {
              if (isDraft) {
                setIsCreateSalesOrderIfDraftModalOpen(true);
              } else {
                createSalesOrder({ quotationId: data.id! });
              }
              setIsOpen(false);
            }}
            label={t('Create sales order')}
            width="100%"
            primary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
