import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const RelativeDiv = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  ${respondTo.smallTablet`
    
  `}
`;
