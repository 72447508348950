import styled, { css } from 'styled-components';
import { borderRadiusSm, COLORS } from 'assets/styled';
import { CSSProperties } from 'react';
import Button from '@mui/material/Button';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  $primary: boolean;
  $secondary: boolean;
  $table: boolean;
  $tableAlt: boolean;
  $red: boolean;
  $green: boolean;
  $redOutline: boolean;
  $greenOutline: boolean;
  $link: boolean;
  $redLink: boolean;
  $underline: boolean;
  width: string;
  height: string;
  color: string;
  fontSize: string;
  iconStyles: CSSProperties;
  $iconPosition?: 'left' | 'right';
}
const border = `1rem solid ${COLORS.PRIMARY}`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    transition: all 0.3s;
  }

  &:hover svg {
    filter: brightness(0) invert(1);
  }
`;

export const Text = styled.span`
  display: inline-block !important;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}

  ${(props: StyledProps) =>
    props.$iconPosition === 'right'
      ? css`
          padding-left: 15rem;
        `
      : css`
          padding-left: 0;
        `}
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 15rem;
  transform: translateY(-50%);
`;

export const StyledButton = styled(Button)`
  font-family: 'Poppins';
  min-width: 30rem;
  letter-spacing: 0.1rem;
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5rem;
  padding: 12rem 16rem 12rem 14rem;
  font-weight: 700;
  line-height: 12rem;
  font-size: 12rem;
  border-radius: ${borderRadiusSm};
  border: none;
  height: 40rem;
  width: ${(props: StyledProps) => props.width};

  &:disabled {
    cursor: not-allowed !important;
    pointer-events: all;
    color: ${COLORS.BLACK};
  }
  color: ${(props: StyledProps) => props.color || `${COLORS.GREY_100}`};

  ${(props: StyledProps) =>
    props.$primary &&
    css`
      background-color: ${COLORS.PRIMARY};
      color: ${COLORS.STRICT_WHITE};
      gap: 10rem;
      height: 40rem;
      width: ${props.width};
      font-weight: 300;
      font-size: 14rem;
      line-height: 15rem;

      &:hover:enabled {
        box-shadow: 0 0 0 1rem ${COLORS.PRIMARY};
        background-color: ${COLORS.PRIMARY};
      }

      &:disabled {
        background-color: ${COLORS.PRIMARY_DISABLED};
        color: ${COLORS.STRICT_WHITE};
      }
    `}

  ${(props: StyledProps) =>
    props.$secondary &&
    css`
      background-color: transparent;
      color: ${COLORS.BLACK};
      border: ${border};
      font-weight: 300;
      width: ${props.width};
      font-size: 14rem;
      line-height: 15rem;

      &:hover:enabled {
        color: ${COLORS.STRICT_WHITE};
        background-color: ${COLORS.PRIMARY};
        box-shadow: 0 0 0 1rem ${COLORS.GREY_400};
      }

      &:disabled {
        opacity: 0.5;
      }
    `}

  ${(props: StyledProps) =>
    props.$table &&
    css`
      background-color: ${COLORS.PRIMARY};
      color: ${COLORS.WHITE};
      padding: 10rem 20rem;
      gap: 10rem;
      height: 31rem;
      line-height: 0;
      width: ${props.width};

      &:hover:enabled {
        color: ${COLORS.PRIMARY};
        background-color: ${COLORS.WHITE};
        box-shadow: 0 0 0 1rem ${COLORS.GREY_400};
      }

      &:disabled {
        background-color: ${COLORS.PRIMARY_DISABLED};
      }
    `}

    ${(props: StyledProps) =>
    props.$tableAlt &&
    css`
      background-color: ${COLORS.WHITE};
      color: ${COLORS.PRIMARY};
      padding: 10rem 20rem;
      gap: 10rem;
      height: 31rem;
      width: ${props.width};
      border: 1px solid ${COLORS.PRIMARY};

      &:hover:enabled {
        color: ${COLORS.STRICT_WHITE};
        background-color: ${COLORS.PRIMARY};
        box-shadow: 0 0 0 1rem ${COLORS.GREY_400};
      }

      &:disabled {
        opacity: 0.5;
      }
    `}


  ${(props: StyledProps) =>
    props.height &&
    css`
      height: ${(props: StyledProps) => props.height};
    `}

    ${(props: StyledProps) =>
    (props.$link || props.$redLink) &&
    css`
      padding: 0rem;
      background-color: transparent;
      transition: none;
      background-repeat: no-repeat;
      border: none;
      ${(props: StyledProps) =>
        props.fontSize
          ? css`
              font-size: ${props.fontSize};
              line-height: ${props.fontSize};
            `
          : css`
              font-size: 14rem;
              line-height: 15rem;
            `}
      font-weight: 300;
      line-height: 21rem;
      letter-spacing: 0em;
      text-align: left;
      color: ${COLORS.PRIMARY};
      svg {
        color: ${COLORS.GREY_700};
      }
      &:hover {
        background-color: transparent;

        svg {
          filter: none;
        }
      }

      span {
        display: none;
      }

      ${(props: StyledProps) =>
        props.$redLink &&
        css`
          color: ${COLORS.RED_TRASH};
        `}
    `}

	${(props: StyledProps) =>
    props.$red &&
    css`
      background-color: ${COLORS.RED_200};
      color: ${COLORS.STRICT_WHITE};
      line-height: 13rem;
      font-size: 14rem;
      font-weight: 300;

      &:disabled {
        background-color: ${COLORS.RED_200};
        opacity: 0.8;
      }
      &:hover {
        background-color: ${COLORS.RED_100};
      }
    `}


  ${(props: StyledProps) =>
    props.$green &&
    css`
      background-color: ${COLORS.LOGYX_GREEN};
      color: ${COLORS.STRICT_WHITE};
      &:disabled {
        background-color: ${COLORS.LOGYX_LIGHT_GREEN};
      }
      &:hover {
        background-color: ${COLORS.LOGYX_GREEN};
        color: ${COLORS.STRICT_WHITE};
      }
    `}

  ${(props: StyledProps) =>
    props.$redOutline &&
    css`
      font-size: 14rem;
      line-height: 15rem;
      font-weight: 300;
      background-color: transparent;
      border: 1rem solid ${COLORS.RED_200};
      color: ${COLORS.BLACK};
      &:hover {
        background-color: ${COLORS.RED_200};
        color: ${COLORS.STRICT_WHITE};
      }
      &:disabled {
        background-color: ${COLORS.RED_100};
      }
    `}

    ${(props: StyledProps) =>
    props.$greenOutline &&
    css`
      font-size: 14rem;
      line-height: 15rem;
      font-weight: 300;
      background-color: transparent;
      border: 1rem solid ${COLORS.GREEN};
      color: ${COLORS.BLACK};
      &:hover {
        background-color: ${COLORS.GREEN};
        color: ${COLORS.STRICT_WHITE};
      }
      &:disabled {
        background-color: ${COLORS.GREEN};
      }
    `}

     ${(props: StyledProps) =>
    props.$underline &&
    css`
      & ${Text} {
        position: relative;

        &::before,
        &::after {
          content: '';
          position: absolute;
          height: 1px;
          width: 50%;
          background-color: currentColor;
          transition: ease 0.3s;
          bottom: 0;
        }

        &::before {
          left: 0;
        }

        &::after {
          right: 0;
        }
      }
    `}

    &:hover ${Text} {
    &::before,
    &::after {
      width: 0;
    }
  }
`;
