import Button from 'components/Button/Button';
import {
  Container,
  ContentContainer,
  FirstRow,
  NoContentLabel,
  Row,
  SpinnerNoContentWrapper,
  TableWrapper,
  Title,
} from './PaymentConditionsPage.styled';
import { Plus } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { COLORS } from 'assets/styled';
import { useEffect, useState } from 'react';
import { AddEditPaymentConditionModal } from './AddEditPaymentConditionModal/AddEditPaymentConditionModal';
import {
  IPaymentCondition,
  IPaymentConditionTableDTO,
} from 'types/PaymentCondition.types';
import {
  useDeletePaymentCondition,
  useGetPaymentConditions,
  useMakeDefaultPaymentCondition,
} from './hooks';
import { PAYMENT_CONDITIONS_PER_PAGE, tableConfig } from './constants';
import { formatTableData } from './helpers';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import Input from 'components/Input/Input';
import { MagnifyingGlass } from '@phosphor-icons/react';
import { useDebounce } from 'utils/hooks/useDebounce';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import Spinner from 'components/Spinner/Spinner';
import { PaymentConditionsTable } from './PaymentConditionsTable/PaymentConditionsTable';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export const PaymentConditionsPage = () => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const [isAddPaymentConditionModalOpen, setIsAddPaymentConditionModalOpen] =
    useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');

  const debouncedSearchBy = useDebounce(searchBy);
  const [paymentConditionIdForDeletion, setPaymentConditionIdForDeletion] =
    useState<string>('');
  const [paymentConditionIdToEdit, setPaymentConditionToEdit] =
    useState<IPaymentCondition>();

  const [
    isDeletePaymentConditionModalOpen,
    setIsDeletePaymentConditionModalOpen,
  ] = useState<boolean>(false);

  const { data, isLoading: isLoadingPaymentConditions } =
    useGetPaymentConditions(
      page,
      PAYMENT_CONDITIONS_PER_PAGE,
      debouncedSearchBy
    );

  const { mutate: deletePaymentCondition } = useDeletePaymentCondition();

  useEffect(() => {
    if (!isAddPaymentConditionModalOpen) setPaymentConditionToEdit(undefined);
  }, [isAddPaymentConditionModalOpen]);

  let tableData: IPaymentConditionTableDTO[] = [];
  if (data) {
    tableData = formatTableData(
      data.payment_conditions
        .map((paymentCondition: IPaymentCondition[]) => {
          return paymentCondition;
        })
        .flat(),
      currencySymbol
    );
  }

  const {
    mutate: makeDefaultPaymentCondition,
    isLoading: isLoadingMakeDefaultPaymentCondition,
  } = useMakeDefaultPaymentCondition();

  const { isPhone } = useBreakpointFlag();

  return (
    <Container>
      <FirstRow>
        <Title>{t('Payment conditions')}</Title>
      </FirstRow>

      <ContentContainer>
        <Row>
          <Input
            pwId="search-field"
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={isPhone ? '100%' : '300rem'}
            wrapperStyles={{ width: isPhone ? '100%' : '' }}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />

          <Button
            icon={Plus}
            label={t('Add new payment condition')}
            sizeIcon={15}
            colorIcon={COLORS.PRIMARY}
            weightIcon="bold"
            link
            onClick={() => setIsAddPaymentConditionModalOpen(true)}
            fontSize="18rem"
          />
        </Row>

        <TableWrapper>
          <PaymentConditionsTable
            page={page}
            perPage={PAYMENT_CONDITIONS_PER_PAGE}
            total={data?.total}
            loadPage={(newPage) => setPage(newPage)}
            tableData={tableData}
            tableConfig={tableConfig}
            onEdit={(rowData: any) => {
              setPaymentConditionToEdit(
                data?.payment_conditions.find(
                  (paymentCondition: IPaymentCondition) =>
                    paymentCondition.id === rowData.id
                )
              );
              setIsAddPaymentConditionModalOpen(true);
            }}
            onDelete={(rowData: any) => {
              setPaymentConditionIdForDeletion(rowData.id);
              setIsDeletePaymentConditionModalOpen(true);
            }}
            onMakeDefault={(rowData: any) =>
              !isLoadingMakeDefaultPaymentCondition &&
              !isLoadingPaymentConditions &&
              makeDefaultPaymentCondition(rowData.id)
            }
          />
        </TableWrapper>
        <SpinnerNoContentWrapper>
          {isLoadingPaymentConditions ? (
            <Spinner />
          ) : tableData.length ? null : (
            <NoContentLabel>{t('No payment conditions found')}</NoContentLabel>
          )}
        </SpinnerNoContentWrapper>
      </ContentContainer>

      <Modal
        level={'SECOND'}
        isOpen={isDeletePaymentConditionModalOpen}
        setIsOpen={setIsDeletePaymentConditionModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              paymentConditionIdForDeletion &&
                deletePaymentCondition(paymentConditionIdForDeletion, {
                  onSuccess: () => {
                    setPaymentConditionIdForDeletion('');
                    setIsDeletePaymentConditionModalOpen(false);
                  },
                });
            },
            text: t('Delete'),
            disabled: false,
          }}
          cancel={{
            onClick: () => setIsDeletePaymentConditionModalOpen(false),
          }}
          title={t('Delete Payment Condition')}
          description={
            t('Are you sure you want to delete this payment condition') + '?'
          }
        />
      </Modal>

      <AddEditPaymentConditionModal
        isOpen={isAddPaymentConditionModalOpen}
        setIsOpen={setIsAddPaymentConditionModalOpen}
        onCancel={() => setIsAddPaymentConditionModalOpen(false)}
        paymentConditionToEdit={paymentConditionIdToEdit}
      />
    </Container>
  );
};
