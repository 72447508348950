import Table from 'components/Table/Table';
import { formatSalesOrdersTableData } from 'components/Table/tableDataFormatter';
import { RoutesConfig } from 'navigation/routes';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ISalesOrdersResponseDTO,
  ISalesOrderTableDTO,
} from 'types/SalesOrders.types';
import { SINGLE_PURCHASE_ORDER_SALES_ORDERS_PER_PAGE } from './constants';
import {
  useGetPurchaseOrderId,
  useGetSinglePurchaseOrderSalesOrders,
} from './hooks';
import {
  Container,
  LoaderWrapper,
  NoContentLabel,
} from './SinglePurchaseOrderSalesOrdersPage.styled';
import { tableConfig } from 'pages/Manager/SalesOrdersPage/constants';
import Spinner from 'components/Spinner/Spinner';
import { COLORS } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';

const SinglePurchaseOrderSalesOrdersPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const purchaseOrderId = useGetPurchaseOrderId();
  const currencySymbol = useGetCurrencySymbol();
  const [page, setPage] = useState<number>(1);
  const { isLoading, data } = useGetSinglePurchaseOrderSalesOrders(
    purchaseOrderId,
    page,
    SINGLE_PURCHASE_ORDER_SALES_ORDERS_PER_PAGE
  );

  const handleViewButtonClick = (rowData: ISalesOrderTableDTO) => {
    navigate(
      RoutesConfig.SingleSalesOrder.fullPath
        .replace(':id', rowData.navigationID)
        .replace('/*', '')
    );
  };

  let tableData: ISalesOrderTableDTO[] = [];
  if (data) {
    tableData = formatSalesOrdersTableData(
      data.sales_orders
        .map((salesOrder: ISalesOrdersResponseDTO[]) => {
          return salesOrder;
        })
        .flat(),
      currencySymbol
    );
  }

  return (
    <Container>
      {tableData.length ? (
        <Table
          isLoading={isLoading}
          page={page}
          perPage={SINGLE_PURCHASE_ORDER_SALES_ORDERS_PER_PAGE}
          total={data?.total}
          tableData={tableData}
          tableConfig={tableConfig}
          hasExtraAction={true}
          loadPage={(newPage) => setPage(newPage)}
          onViewButtonClick={handleViewButtonClick}
          overflowX
        />
      ) : (
        <>
          {isLoading && (
            <LoaderWrapper>
              <Spinner size={50} color={COLORS.PRIMARY} />
            </LoaderWrapper>
          )}
          {!isLoading && (
            <NoContentLabel>
              {t('There are currently no Sales orders on this Purchase order')}
            </NoContentLabel>
          )}
        </>
      )}
    </Container>
  );
};

export default SinglePurchaseOrderSalesOrdersPage;
