import { useEffect, useState } from 'react';

const useScrollPosition = (thresholdRem: number, isMobile: boolean) => {
  const [isScrolled, setIsScrolled] = useState(false);

  const handleScroll = () => {
    if (!isMobile) return;

    const scrollTop = window.scrollY;
    const threshold = thresholdRem * 0.8; // 1rem = 0.8px

    if (scrollTop >= threshold) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    if (isMobile) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (isMobile) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [thresholdRem, isMobile]);

  return isScrolled;
};

export default useScrollPosition;
