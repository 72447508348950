import { COLORS, marginMd, marginSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
`;

export const Wrapper = styled.div`
  width: 650rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${respondTo.bigPhone`
      max-width: 100%;
      width: 100%;
  `}
`;

export const Heading = styled.label`
  padding-bottom: 28rem;
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  text-align: center;
`;

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${marginSm};
  flex: 1;

  &:first-child {
    margin-right: 20rem;
  }
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 16rem;
  line-height: 24rem;
  display: flex;
  align-items: center;
  margin-bottom: ${marginXs};
`;
export const Label = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  display: flex;
  align-items: center;
  text-align: right;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10rem;
  margin-top: ${marginMd};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25rem;
  height: 25rem;
  margin-left: ${marginXs};
  margin-right: ${marginXs};
`;
