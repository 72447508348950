import { useCallback, useEffect, useState } from 'react';
import { CheckboxWrapper, Container } from './PermissionsCell.styled';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { ILocalPermissions, IProps } from './types';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { BookOpenText, PencilSimpleLine } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';

const PermissionsCell = ({
  user_id,
  permissionDetails,
  handlePermissionChange,
  rowDataKey,
  page,
  resetPerms,
  setResetPerms,
  isAdminChecked,
  isFieldLicense,
}: IProps) => {
  const [localPermissions, setLocalPermissions] = useState<ILocalPermissions>({
    read: permissionDetails.read,
    edit: permissionDetails.edit,
  });

  const isAdmin = rowDataKey === 'adminPermission';
  const { isTablet, isSmallTablet } = useBreakpointFlag();

  const handleCheckboxChange = useCallback(
    (type: string, permissionId: number) => {
      const newValue = !localPermissions[type];
      setLocalPermissions((prevState: any) => ({
        ...prevState,
        [type]: newValue,
      }));

      handlePermissionChange(user_id, permissionId, newValue);
    },
    [localPermissions, handlePermissionChange, user_id]
  );

  useEffect(() => {
    resetValues();
    if (resetPerms) {
      setResetPerms && setResetPerms(false);
    }
  }, [page, resetPerms, permissionDetails]);

  const resetValues = () => {
    setLocalPermissions({
      read: permissionDetails.read,
      edit: permissionDetails.edit,
    });
  };

  return (
    <Container>
      {isAdmin ? (
        <Checkbox
          isChecked={localPermissions.read || localPermissions.edit}
          onChange={() => {
            handleCheckboxChange('read', permissionDetails.read_id);
            handleCheckboxChange('edit', permissionDetails.edit_id);
          }}
          inputStyle={{ transform: isTablet ? 'scale(1.3)' : 'scale(1.5)' }}
          disabled={isFieldLicense}
        />
      ) : (
        <>
          <CheckboxWrapper>
            {isSmallTablet && (
              <Icon svg={BookOpenText} size={20} color={COLORS.PRIMARY} />
            )}
            <Checkbox
              isChecked={localPermissions.read}
              onChange={() =>
                handleCheckboxChange('read', permissionDetails.read_id)
              }
              inputStyle={{ transform: isTablet ? 'scale(1.3)' : 'scale(1.5)' }}
              disabled={
                isAdminChecked || localPermissions.edit || isFieldLicense
              }
            />
          </CheckboxWrapper>
          <CheckboxWrapper>
            {isSmallTablet && (
              <Icon svg={PencilSimpleLine} size={20} color={COLORS.PRIMARY} />
            )}
            <Checkbox
              isChecked={localPermissions.edit}
              onChange={() => {
                if (!localPermissions.edit && !localPermissions.read) {
                  handleCheckboxChange('read', permissionDetails.read_id);
                }
                handleCheckboxChange('edit', permissionDetails.edit_id);
              }}
              inputStyle={{ transform: isTablet ? 'scale(1.3)' : 'scale(1.5)' }}
              disabled={isAdminChecked || isFieldLicense}
            />
          </CheckboxWrapper>
        </>
      )}
    </Container>
  );
};

export default PermissionsCell;
