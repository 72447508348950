import * as Yup from 'yup';
import moment from 'moment';
import { capitalizeFirstLetter } from './stringUtils';

export const PHONE_NUMBER_REGEX = /^(?:\+\d{1,3})?(?:[ ()-]*\d[ ()-]*){9,15}$/;

export const isValidEmail = (email: string) => {
  const re = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/;
  return re.test(String(email).toLowerCase());
};

export const isNumeric = (num: any) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num as number);

export function validateNumericField(
  value: string,
  fieldName: string,
  canBeNegative?: boolean
) {
  if (value === '' || value === undefined || value === null) {
    return `${capitalizeFirstLetter(fieldName)} is invalid`;
  } else if (!isNumeric(value)) {
    return `${capitalizeFirstLetter(fieldName)} must be a number`;
  }
  if (Number(value) === 0)
    return `${capitalizeFirstLetter(fieldName)} cannot be 0`;
  if (Number(value) < 0 && !canBeNegative) {
    return `${capitalizeFirstLetter(fieldName)} must be higher than 0`;
  }
  if (Math.abs(Number(value)) >= 1000000) {
    if (Number(value) > 0)
      return `${capitalizeFirstLetter(fieldName)} must be lower than 1 million`;
    else
      return `${capitalizeFirstLetter(
        fieldName
      )} must be greater than -1 million`;
  }
  if (!/^-?\d+(\.\d{1,2})?$/.test(value)) {
    return `${capitalizeFirstLetter(
      fieldName
    )} can only have two decimal places`;
  }
}

export function validateNumericFieldWithZero(
  value: string,
  fieldName: string,
  canBeNegative?: boolean
) {
  if (value === '' || value === undefined || value === null) {
    return `${capitalizeFirstLetter(fieldName)} is invalid`;
  } else if (!isNumeric(value)) {
    return `${capitalizeFirstLetter(fieldName)} must be a number`;
  }
  if (Number(value) < 0 && !canBeNegative) {
    return `${capitalizeFirstLetter(fieldName)} must be a positive number`;
  }
  if (Math.abs(Number(value)) >= 1000000) {
    if (Number(value) > 0)
      return `${capitalizeFirstLetter(fieldName)} must be lower than 1 million`;
    else
      return `${capitalizeFirstLetter(
        fieldName
      )} must be greater than -1 million`;
  }
  if (!/^-?\d+(\.\d{1,2})?$/.test(value)) {
    return `${capitalizeFirstLetter(
      fieldName
    )} can only have two decimal places`;
  }
}

export function validateField(value: string, fieldName: string) {
  if (value === '' || value === undefined || value === null) {
    return `${capitalizeFirstLetter(fieldName)} is invalid`;
  }
}

export function validateOptionalNumericField(value: string, fieldName: string) {
  if (value === '' || value === undefined || value === null) {
    return null;
  } else if (!isNumeric(value)) {
    return `${capitalizeFirstLetter(fieldName)} must be a number`;
  }
  if (Number(value) <= 0) {
    return `${capitalizeFirstLetter(fieldName)} must be higher than 0`;
  }
  if (Number(value) >= 1000000) {
    return `${capitalizeFirstLetter(fieldName)} must be lower than 1 million`;
  }
  if (!/^\d+(\.\d{1,2})?$/.test(value)) {
    return `${capitalizeFirstLetter(
      fieldName
    )} can only have two decimal places`;
  }
}

export function validateOptionalNumericFieldWithZero(
  value: string,
  fieldName: string
) {
  if (value === '' || value === undefined || value === null) {
    return null;
  } else if (!isNumeric(value)) {
    return `${capitalizeFirstLetter(fieldName)} must be a number`;
  }
  if (Number(value) < 0) {
    return `${capitalizeFirstLetter(fieldName)} must be a positive number`;
  }
  if (Number(value) >= 1000000) {
    return `${capitalizeFirstLetter(fieldName)} must be lower than 1 million`;
  }
  if (!/^\d+(\.\d{1,2})?$/.test(value)) {
    return `${capitalizeFirstLetter(
      fieldName
    )} can only have two decimal places`;
  }
}

export function validateTimeFromTo(from: string, to: string) {
  const fromTime = moment(from, 'HH:mm:ss');
  const toTime = moment(to, 'HH:mm:ss');

  if (!fromTime.isBefore(toTime)) {
    return 'Time is invalid.';
  }

  return true;
}

export function validateAlternativeEmailField(
  value: string
): string | undefined {
  try {
    const schema = Yup.string().max(
      50,
      'Email should be less than 50 characters'
    );

    schema.validateSync(value);
    return undefined;
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      return error.message;
    }
    return 'Email validation failed';
  }
}

/**
 * Checks if the given string is a valid 6-digit hex color code without alpha.
 * @param color The string to check.
 * @returns True if the string is a valid hex color code, false otherwise.
 */
export function isValidHexColor(color: string): boolean {
  // Regular expression to match a 6-digit hex color code
  const hexColorRegex = /^#[0-9A-Fa-f]{6}$/;

  return hexColorRegex.test(color);
}
