import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  AdvancedFilterWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  Title,
  TitleContainer,
} from './FilterMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';

interface IFilterMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  setIsAdvancedFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  advancedFilters: string;
}

const FilterMobileModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  setIsAdvancedFilterModalOpen,
  advancedFilters,
}: IFilterMobileModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Filter')}</Title>
        </TitleContainer>
        <AdvancedFilterWrapper>
          <Button
            onClick={() => setIsAdvancedFilterModalOpen(true)}
            label={t('Advanced filter')}
            width="200rem"
            height="40rem"
            secondary={!advancedFilters}
            primary={!!advancedFilters}
          />
        </AdvancedFilterWrapper>
        <ButtonsWrapper>
          <Button
            onClick={() => setIsOpen(false)}
            label={t('Finish')}
            width="100%"
            height="40rem"
            primary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default FilterMobileModal;
