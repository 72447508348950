import {
  borderRadiusSm,
  COLORS,
  gapLg,
  gapSm,
  gapXs,
  marginLg,
  marginMd,
  marginSm,
  paddingMd,
  paddingXs,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  color: string;
  isSelected: boolean;
}

export const Container = styled.div`
  height: 100%;
  max-width: 1075rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};
  gap: 30rem;

  ${respondTo.midTablet`
    padding: 0 15rem;
    max-width: 100%;
  `}
`;

export const Subtitle = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
  margin-bottom: ${marginMd};

  ${respondTo.midTablet`
    margin: 20rem 0;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${COLORS.WHITE};
  height: 100%;
  width: 100%;
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};

  ${respondTo.smallTablet`
    padding: 15rem;
  `}
`;

export const Label = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  min-width: 150rem;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const ColorOption = styled.div`
  width: 41rem;
  height: 41rem;
  border-radius: 5rem;
  cursor: pointer;
  ${(props: StyledProps) =>
    props.color &&
    css`
      background-color: ${props.color};
      border: 1px solid ${props.color};
    `};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      border: 1px solid ${COLORS.BLACK};
      box-shadow: 0px 0px 16px -4px rgba(26, 127, 191, 1);
    `};
`;
export const ColorOptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15rem;
`;

export const ColorInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 100rem;
  margin-bottom: 22rem;
  margin-top: 22rem;

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
    align-items: flex-start;
    width: 100%;
  `}
`;

export const InputLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const FilterTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 30rem;
  margin-top: ${marginMd};

  ${respondTo.bigPhone`
    margin-top: 0;
  `}
`;

export const FilterTypeLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;
export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Heading = styled.div`
  padding-top: ${marginSm};
  padding-bottom: ${marginSm};
`;

export const ApplyWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;
  width: 100%;

  ${respondTo.bigPhone`
    padding-top: 20rem;
  `}
`;

export const TabLabel = styled.label`
  margin-bottom: ${marginSm};
  font-weight: 300;
  font-size: 14rem;
  line-height: 27rem;
  white-space: pre-line;
`;

export const SelectInputWrapper = styled.div`
  width: 300rem;
  height: 41rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
    gap: 10rem;
    margin-bottom: 20rem;
  `}
`;

export const FontWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30rem;
  margin-bottom: 22rem;

  ${respondTo.smallPhone`
    gap: 15rem;
  `}
`;

export const FontSizeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5rem;
`;

export const FontSelectInputWrapper = styled.div`
  width: 200rem;
  height: 41rem;

  ${respondTo.smallPhone`
    width: 180rem;
  `}
`;

export const StyledTextArea = styled.textarea`
  font-family: inherit;
  border: 1rem solid ${COLORS.GREY_1000};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  flex-grow: 0;
  padding: ${paddingXs};
  gap: ${gapSm};
  transition: all 0.3s;
  resize: none;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 16rem;
  `}
`;

export const StyledInput = styled.input`
  width: 300rem;
  font-family: inherit;
  border: 1rem solid ${COLORS.GREY_1000};
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  flex-grow: 0;
  padding: ${paddingXs};
  gap: ${gapSm};
  transition: all 0.3s;
  resize: none;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    height: 41rem;
    font-size: 16rem;
  `}

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
  margin-top: ${marginLg};

  ${respondTo.smallTablet`
    margin-top: 20rem;
  `}
`;

export const EmailConfigurationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: ${gapLg};
  width: 100%;

  ${respondTo.smallTablet`
    gap: 20rem;
    flex-direction: column;
  `}
`;

export const LabelSecondary = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;

export const DefaultAttachmentLabelsWrapper = styled.div`
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
  display: flex;
  flex-direction: column;
  gap: ${gapXs};
  max-width: 100%;
`;

export const DefaultAttachmentNameLinkDeleteIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${gapSm};
  align-items: center;
  justify-content: space-between;
`;

export const DefaultAttachmentNameLink = styled.a`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.PRIMARY};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  max-width: calc(33.33% - 50rem);

  ${respondTo.smallTablet`
    max-width: 100%;
  `}
`;

export const LoaderWrapper = styled.div`
  width: 200rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
