import { COLORS, H3 } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  opacity: any;
  previewVisible: boolean;
}

export const Container = styled.div`
  padding: 50rem;
  display: flex;
  justify-content: space-between;
  max-width: 1300rem;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: 1600px) {
    max-width: 1400rem;
  }

  ${respondTo.midTablet`
    flex-direction: column;
    align-items: center;
    gap: 30rem;
  `}

  ${respondTo.bigTablet`
    padding: 30rem;
  `}

  ${(props: StyledProps) =>
    !props.previewVisible &&
    css`
      justify-content: center;
    `};
`;

export const SpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450rem;

  @media screen and (min-width: 1600px) {
    max-width: 40%;

    ${(props: StyledProps) =>
      !props.previewVisible &&
      css`
        max-width: 800rem;
      `};
  }

  ${(props: StyledProps) =>
    !props.previewVisible &&
    css`
      max-width: 800rem;
    `};

  ${respondTo.midTablet`
    max-width: 100%;
  `}
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 40vh;
  width: 100%;
  height: 70vh;

  @media screen and (min-width: 1600px) {
    max-width: 50vh;
    height: 80vh;
  }
`;

export const Headline = styled(H3)`
  margin-bottom: 20rem;
`;

export const LeftWrapper = styled.div`
  padding: 20rem;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
`;

export const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10rem;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const Text = styled.span`
  display: block;
  color: ${COLORS.BLACK};
  font-size: 16rem;
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
`;

export const SaveWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 30rem;
`;

export const SelectWrapper = styled.div`
  display: flex;
  width: 200rem;
  flex-direction: column;
  margin-top: 10rem;
`;

export const RightWrapper = styled.div`
  position: relative;
  background-color: ${COLORS.STRICT_WHITE};
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const WatermarkImage = styled.img`
  position: absolute;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  max-width: 100%;

  ${(props: StyledProps) =>
    props.opacity &&
    css`
      opacity: ${(props: StyledProps) => props.opacity};
    `};
`;
