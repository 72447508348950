import { COLORS, marginSm, paddingSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const Description = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding-bottom: ${paddingSm};
  justify-content: center;
  align-items: center;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ApplyWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;

  ${respondTo.smallTablet`
    padding-top: 20rem;
  `}
`;
