import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'utils/toast';
import {
  deleteSalesOrderLine,
  editSalesOrderGeneralDiscount,
  editSalesOrderProductsDefinitive,
} from 'services/SalesOrder/SalesOrderService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { IEditSalesOrderProductsDefinitiveDTO } from './constants';

export const useDeleteSalesOrderLine = (salesOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (salesOrderLineId: string) =>
      deleteSalesOrderLine(salesOrderId, salesOrderLineId),
    {
      onSuccess: () => {
        toast.success(t('Sales order line deleted'), {
          className: ReactMutationKeys.DELETE_SALES_ORDER_LINE,
        });
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_ORDER]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DELETE_SALES_ORDER_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.DELETE_SALES_ORDER_LINE,
    }
  );
};

export const useEditSalesOrderProductsDefinitive = (salesOrderId: string) =>
  useMutation(
    (editProductsDefinitiveData: IEditSalesOrderProductsDefinitiveDTO) =>
      editSalesOrderProductsDefinitive(
        salesOrderId,
        editProductsDefinitiveData
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_ORDER]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SALES_ORDER_PRODUCTS_DEFINITIVE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SALES_ORDER_PRODUCTS_DEFINITIVE,
    }
  );

export const useEditSalesOrderGeneralDiscount = (salesOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (generalDiscount: number | null) =>
      editSalesOrderGeneralDiscount(salesOrderId, generalDiscount),
    {
      onSuccess: () => {
        toast.success(t('General discount applied'));
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_ORDER]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EDIT_SALES_ORDER_GENERAL_DISCOUNT,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SALES_ORDER_GENERAL_DISCOUNT,
    }
  );
};
