import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30rem;
  overflow: scroll;
  align-items: center;
  height: calc(100vh - 75rem - 185rem);
  max-width: 100%;
  width: 100%;
  padding-bottom: 50rem;

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const NoContentLabel = styled.label`
  margin-top: 100rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 390rem;
  max-width: 850rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  padding: 30rem;

  ${respondTo.midTablet`
    min-width: 800rem;
    height: auto;
  `}

  ${respondTo.smallTablet`
    max-width: 100%;
    min-width: auto;
    width: 100%;
  `}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30rem;

  ${respondTo.smallTablet`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20rem;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 97rem;
  :nth-child(2) {
    padding-right: 102rem;
  }
  :last-child {
    padding-right: 0rem;
  }
`;

export const LabelTitle = styled.div`
  font-size: 14rem;
  font-weight: 600;
  line-height: 21rem;
  width: 129rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;
export const Label = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30rem;
`;

export const DeleteLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.RED_TRASH};
  cursor: pointer;
  ${hoverLineOut}
`;

export const DeleteIcon = styled.div`
  padding-left: 5rem;
`;

export const EditDeleteWrapperRelative = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;
`;
