import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Label,
  RowSpaceBetween,
  RowSpaceBetweenAlignCenter,
  TimePickerColumnContainer,
  Title,
  TitleContainer,
} from './EditPaymentReminderSettingsModal.styled';
import { Input } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { editEmailCompanySettingsSchema } from './validation';
import { toast } from 'react-toastify';
import { transformEmptyValues } from 'utils/transformEmptyValues';
import { useEditCompanySettings } from '../hooks';
import { IEditCompanySettingsDTO } from 'types/Admin.types';
import moment, { Moment } from 'moment';
import TimePicker from 'components/TimePicker/TimePicker';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IEditPaymentReminderSettingsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  settingsData: any;
  companyId: string;
}
export const EditPaymentReminderSettingsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  settingsData,
  companyId,
}: IEditPaymentReminderSettingsModalProps) => {
  const { t } = useTranslation();
  const { isPhone, isSmallPhone } = useBreakpointFlag();

  const {
    mutate: editCompanySettings,
    isSuccess,
    isLoading,
  } = useEditCompanySettings(Number(companyId));

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Company settings updated'));
      onCancel();
    }
  }, [isSuccess]);

  const handleEditCompanySettings = (values: any) => {
    const editCompanySettingsDTO: IEditCompanySettingsDTO = {
      due_date_start_reminder: values.due_date_start_reminder,
      due_date_second_reminder: values.due_date_second_reminder,
      due_date_third_reminder: values.due_date_third_reminder,
      due_date_reminder_sent_time: values.due_date_reminder_sent_time,
    };
    editCompanySettings(editCompanySettingsDTO);
  };

  const getInitialValues = () => {
    return {
      due_date_start_reminder: settingsData?.due_date_start_reminder,
      due_date_second_reminder: settingsData?.due_date_second_reminder,
      due_date_third_reminder: settingsData?.due_date_third_reminder,
      due_date_reminder_sent_time: settingsData?.due_date_reminder_sent_time,
      is_checked: !!settingsData?.due_date_start_reminder,
    };
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        overflow: 'visible',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Company payment reminder settings')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={getInitialValues()}
          validationSchema={editEmailCompanySettingsSchema}
        >
          {({ values, setFieldValue, handleBlur, errors, isValid }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };

            return (
              <Container>
                <RowSpaceBetween>
                  <RowSpaceBetweenAlignCenter>
                    <Checkbox
                      isChecked={values.is_checked}
                      onChange={() => {
                        if (values.is_checked) {
                          setFieldValue('due_date_start_reminder', '');
                          setFieldValue('due_date_second_reminder', '');
                          setFieldValue('due_date_third_reminder', '');
                          setFieldValue('due_date_reminder_sent_time', '');
                          setFieldValue('is_checked', false);
                        } else {
                          setFieldValue('is_checked', true);
                        }
                      }}
                    />

                    <H4>{t('Automatic payment reminder')}</H4>
                  </RowSpaceBetweenAlignCenter>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Due date start reminder').toUpperCase()}</H4>
                    </Label>
                    <Input
                      disabled={!values.is_checked}
                      errorMessage={
                        errors['due_date_start_reminder'] && values.is_checked
                          ? (errors['due_date_start_reminder'] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'due_date_start_reminder');
                      }}
                      height={'41rem'}
                      name="due_date_start_reminder"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Due date second reminder').toUpperCase()}</H4>
                    </Label>
                    <Input
                      disabled={!values.is_checked}
                      errorMessage={
                        errors['due_date_second_reminder'] && values.is_checked
                          ? (errors['due_date_second_reminder'] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'due_date_second_reminder');
                      }}
                      height={'41rem'}
                      name="due_date_second_reminder"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>

                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Due date third reminder').toUpperCase()}</H4>
                    </Label>
                    <Input
                      disabled={!values.is_checked}
                      errorMessage={
                        errors['due_date_third_reminder'] && values.is_checked
                          ? (errors['due_date_third_reminder'] as string)
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'due_date_third_reminder');
                      }}
                      height={'41rem'}
                      name="due_date_third_reminder"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <TimePickerColumnContainer>
                    <Label>
                      <H4>{t('Due date reminder sent time').toUpperCase()}</H4>
                    </Label>
                    <TimePicker
                      disabled={!values.is_checked}
                      width={isPhone ? '100%' : '300rem'}
                      time={moment(
                        values?.due_date_reminder_sent_time,
                        'HH:mm:ss'
                      )}
                      setTime={(newValue: Moment) => {
                        setFieldValue(
                          'due_date_reminder_sent_time',
                          newValue?.format('HH:mm:ss')
                        );
                      }}
                    />
                  </TimePickerColumnContainer>
                </RowSpaceBetween>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={
                      isLoading ||
                      (values.is_checked &&
                        (!values.due_date_start_reminder ||
                          !values.due_date_second_reminder ||
                          !values.due_date_third_reminder ||
                          !values.due_date_reminder_sent_time))
                    }
                    onClick={() => {
                      if (isValid || !values.is_checked)
                        handleEditCompanySettings(transformEmptyValues(values));
                    }}
                    primary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
