import styled, { css } from 'styled-components';
import { COLORS, borderRadiusSm, marginSm } from 'assets/styled';
import CustomSelect from 'components/Select/FormikSelect';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isSelected: boolean;
  marginRight: string;
}

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  max-width: 690rem;
  width: 100%;
  min-height: 385rem;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};

  ${respondTo.smallTablet`
    max-width: calc(100% - 30rem);
    min-height: auto;
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${respondTo.bigPhone`
    align-items: flex-start;
    flex-direction: column;
    gap: 20rem;
    margin-bottom: 20rem;
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  margin-top: 14rem; //30-16

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const FieldWrapper = styled.div`
  width: 50%;
  padding-right: 15rem;

  ${respondTo.bigPhone`
    width: 100%;
    padding-right: 0;
  `}
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
`;

export const AlignRow = styled(Row)`
  justify-content: center;
`;

export const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${marginSm};
  margin-top: ${marginSm};
`;

export const ExistingOptionLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-underline-position: under;
    `}
  ${(props: StyledProps) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}
`;

export const SelectWrapper = styled.div`
  width: 203rem;
  height: 42rem;

  ${respondTo.bigPhone`
    width: 100%;
    height: auto;
  `}
`;
