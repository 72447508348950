import styled from 'styled-components';
import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800rem;
  width: 100%;
  padding-bottom: 50rem;
  margin: 0 auto;
  padding: 0 15rem;
`;
export const ProfileCard = styled.div`
  max-width: 100%;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  display: flex;
  flex-direction: column;
`;

export const H3 = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  padding-top: 50rem;
  padding-bottom: 50rem;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    padding: 20rem 0;
  `}
`;
export const ProfileImg = styled.img`
  position: relative;
  width: fit-content;
  max-width: 150rem;
  height: 150rem;
`;

export const ProfileImgWrapper = styled.div`
  width: 150rem;
  height: 150rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const IconWrapper = styled.div`
  position: absolute;
  width: 40rem;
  height: 40rem;
  right: 0;
  top: 30rem;
  cursor: pointer;
  border-radius: 50%;
  background-color: ${COLORS.PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const HeadingWrapper = styled.div`
  max-width: 785rem;
  width: 100%;
  display: flex;
  align-self: left;
`;

export const ProfileCardImg = styled.div`
  position: relative;
  padding-top: 30rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200rem;
  margin: 0 auto;
`;
