import { Formik } from 'formik';
import { COLORS, H5 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  ApplyWrapper,
  TimeWrapper,
  WorkersAndResources,
  StyledSelect,
  Cont,
  StyledSelectTop,
  HorizontalLine,
  WorkersAndResourcesHeading,
  SalesOrderHeading,
  ProductsHeading,
  ProductErrorLabel,
  UsersHeading,
  UsersContainer,
  Scrollable,
  DateTimeWrapper,
  TimeIntervalErrorMessageCont,
  TimeIntervalErrorMessage,
  ArrivalTime,
  ArrivalTimeText,
  DateTimeSection,
  ArrivalTimeWrapper,
  ArrivalTimeInner,
  CheckboxContainer,
  HalfCont,
  AltTimeWrapper,
  DateLabel,
  SpinnerWrapper,
  SelectAllContainer,
  SelectAllLabel,
  ClearLabel,
  ProductsHeadingSecondRow,
  PaginationWrapper,
} from './Installationtab.styled';
import CustomSelect from 'components/Select/FormikSelect';
import { useLocation } from 'react-router-dom';
import {
  useCreateAppointment,
  useGetResourcesInfinite,
  useGetSalesOrderLinesSummary,
  useGetUsersSummaryInfinite,
  useGetWorkersInfinite,
} from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { ICreateAppointmentDTO } from 'pages/Manager/PlanningPage/types';
import { Option } from 'components/Select/type';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import moment, { Moment } from 'moment';
import {
  AddEmailNoteModal,
  IEmailDetails,
} from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import { SALES_ORDERS_PER_PAGE } from './constants';
import { useIsMutating } from 'react-query';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import {
  RESOURCES_PER_PAGE,
  USERS_PER_PAGE,
  WORKERS_PER_PAGE,
} from '../GeneralTab/constants';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  ICreateAppointmentNavigationState,
  useGetSelectedNewAppointmentEntity,
  useHandleSelectOptions,
} from '../hooks';
import DatePicker from 'components/DatePicker/DatePicker';
import TimePicker from 'components/TimePicker/TimePicker';
import {
  validateDateTimeInterval,
  validateTimeFields,
  validateTimeOnlyInterval,
} from '../validation';
import Tooltip from 'components/Tooltip/Tooltip';
import Icon from 'components/Icon/Icon';
import { getArrivalTime, getNewAppointmentEmailDetails } from '../helpers';
import { Minus, WarningCircle } from '@phosphor-icons/react';
import {
  useGetSalesOrdersOptions,
  useGetSalesOrdersSummaryInfinite,
} from 'utils/hooks/useGetSalesOrders';
import { AppointmentTypeIds } from 'types/Appointment.types';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import { AppointmentSalesOrderLine } from '../AppointmentSalesOrderLine/AppointmentSalesOrderLine';
import Spinner from 'components/Spinner/Spinner';
import { queryClient } from 'index';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import Input from 'components/Input/Input';
import { MagnifyingGlass } from '@phosphor-icons/react';
import {
  SALES_ORDER_LINES_PER_PAGE,
  useSalesOrderLinesLocalPagination,
} from '../../hooks';
import Pagination from 'components/Table/Pagination/Pagination';
import Loader from 'components/Loader/Loader';

export interface IInstallationTabProps {
  isNewAppointmentModalOpen: boolean;
  isCreatingAppointmentFromNavigationState: boolean;
  setIsCreatingAppointmentFromNavigationState: Dispatch<
    SetStateAction<boolean>
  >;
  closeAppointmentModal: () => void;
  startDate?: string;
  endDate?: string;
  time_from?: string;
  time_to?: string;
  arrival_to?: string;
  arrival_from?: string;
  selectedUsersOptions: Option[];
  selectedWorkersOptions: Option[];
  selectedResourcesOptions: Option[];
  appointmentLowerInterval: number;
  appointmentUpperInterval: number;
}

const InstallationTab = ({
  isNewAppointmentModalOpen,
  isCreatingAppointmentFromNavigationState,
  setIsCreatingAppointmentFromNavigationState,
  closeAppointmentModal,
  startDate,
  endDate,
  time_from,
  time_to,
  arrival_to,
  arrival_from,
  selectedUsersOptions,
  selectedWorkersOptions,
  selectedResourcesOptions,
  appointmentUpperInterval,
  appointmentLowerInterval,
}: IInstallationTabProps) => {
  const { t } = useTranslation();
  const { state }: { state: ICreateAppointmentNavigationState } = useLocation();

  const [salesOrderSearchBy, setSalesOrderSearchBy] = useState<string>('');
  const debouncedSalesOrderSearchBy = useDebounce(salesOrderSearchBy);

  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedUserSearchBy = useDebounce(userSearchBy);

  const [workerSearchBy, setWorkerSearchBy] = useState<string>('');
  const debouncedWorkerSearchBy = useDebounce(workerSearchBy);

  const [resourceSearchBy, setResourceSearchBy] = useState<string>('');
  const debouncedResourceSearchBy = useDebounce(resourceSearchBy);

  const [selectedSalesOrderLineIds, setSelectedSalesOrderLineIds] = useState<
    number[]
  >([]);

  const [showArrivalTime, setShowArrivalTime] = useState<boolean>(false);

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);

  const [formikValues, setFormikValues] = useState<ICreateAppointmentDTO>();
  const {
    mutate: createAppointment,
    isSuccess,
    isLoading,
  } = useCreateAppointment();

  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);

  const isMutatingCreateAppointment = useIsMutating(
    ReactMutationKeys.CREATE_APPOINTMENT
  );
  const createAppointmentIsLoading = !!isMutatingCreateAppointment || isLoading;

  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersSummaryInfinite(
    USERS_PER_PAGE,
    debouncedUserSearchBy,
    isNewAppointmentModalOpen
  );

  const {
    data: workersData,
    fetchNextPage: fetchNextPageWorkers,
    hasNextPage: hasNextPageWorkers,
    isLoading: isLoadingWorkers,
  } = useGetWorkersInfinite(
    WORKERS_PER_PAGE,
    debouncedWorkerSearchBy,
    undefined, // dateFrom
    undefined, // dateTo
    isNewAppointmentModalOpen
  );

  const {
    data: resourcesData,
    fetchNextPage: fetchNextPageResources,
    hasNextPage: hasNextPageResources,
    isLoading: isLoadingResources,
  } = useGetResourcesInfinite(
    RESOURCES_PER_PAGE,
    debouncedResourceSearchBy,
    undefined, // dateFrom
    undefined, // dateTo
    isNewAppointmentModalOpen
  );

  const {
    data: salesOrdersData,
    fetchNextPage: fetchNextPageSalesOrders,
    hasNextPage: hasNextPageSalesOrders,
    isLoading: isLoadingSalesOrders,
  } = useGetSalesOrdersSummaryInfinite(
    SALES_ORDERS_PER_PAGE,
    debouncedSalesOrderSearchBy
  );

  const salesOrdersOptions = useGetSalesOrdersOptions(salesOrdersData);

  const {
    userOptions,
    setSelectedUserOptions,
    workerOptions,
    setSelectedWorkerOptions,
    resourceOptions,
    setSelectedResourceOptions,
  } = useHandleSelectOptions(
    usersData,
    workersData,
    resourcesData,
    selectedUsersOptions,
    selectedWorkersOptions,
    selectedResourcesOptions
  );

  useEffect(() => {
    if (isSuccess) {
      setIsCreatingAppointmentFromNavigationState(false);
      closeAppointmentModal();
      setIsSendEmailModalOpen(false);
    }
  }, [isSuccess]);

  const checkEmailSending = (values: ICreateAppointmentDTO) => {
    setFormikValues(values);
    setIsSendEmailModalOpen(true);
  };

  const handleCreateAppointment = (
    values: ICreateAppointmentDTO,
    shouldSendEmail: boolean
  ) => {
    const createAppointmentData: ICreateAppointmentDTO = {
      ...(values?.sales_order_id && {
        sales_order_id: values?.sales_order_id,
      }),
      date_from: `${values.startDate} ${values.time_from}`,
      date_to: `${values.endDate} ${values.time_to}`,
      appointment_type_id: values.appointment_type_id,
      users_ids: values?.users_ids,
      workers_ids: values?.workers_ids,
      resources_ids: values?.resources_ids,
      confirmed: isConfirmed,
      sales_order_lines_ids: selectedSalesOrderLineIds,
      ...(values.arrival_from && {
        arrival_from: `${values.startDate} ${values.arrival_from}`,
      }),
      ...(values.arrival_to && {
        arrival_to: `${values.startDate} ${values.arrival_to}`,
      }),
    };
    createAppointment({
      createAppointmentData,
      shouldSendEmail,
      note: '',
      files: [],
    });
  };

  const {
    isLoading: isLoadingSelectedSalesOrder,
    setSelectedSalesOrderId,
    selectedSalesOrder,
    selectedSalesOrderId,
  } = useGetSelectedNewAppointmentEntity(
    isCreatingAppointmentFromNavigationState ? state : undefined
  );

  const emailDetails: IEmailDetails | undefined = getNewAppointmentEmailDetails(
    undefined,
    selectedSalesOrder
  );

  const {
    data: salesOrderLinesData,
    isLoading: isLoadingSalesOrderLines,
    isSuccess: isSuccessLoadingSalesOrderLines,
  } = useGetSalesOrderLinesSummary(selectedSalesOrderId!);

  useEffect(() => {
    if (isSuccessLoadingSalesOrderLines) {
      // After fetching them, automatically select sales order lines
      const initiallySelectedLineIds: number[] =
        salesOrderLinesData.sales_order_lines
          .filter(
            (line: ISalesOrderLine) =>
              !line.working_hours_line && !line.is_planned && !line.is_delivered
          )
          .map((line: ISalesOrderLine) => line.id);
      setSelectedSalesOrderLineIds(initiallySelectedLineIds);
    }
  }, [isSuccessLoadingSalesOrderLines]);

  useEffect(() => {
    // State cleanup
    if (!isNewAppointmentModalOpen) {
      setSelectedSalesOrderId(undefined);
      setSelectedSalesOrderLineIds([]);
    }
  }, [isNewAppointmentModalOpen]);

  const { page, setPage, setSearchTerm, getElementsForCurrentPage } =
    useSalesOrderLinesLocalPagination();

  const getTimeInterval = (timeFrom: string, timeTo: string) => {
    return moment(timeTo, 'HH:mm:ss').diff(moment(timeFrom, 'HH:mm:ss'));
  };
  const getDateInterval = (dateFrom: string, dateTo: string) => {
    return moment(dateTo).diff(moment(dateFrom));
  };

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={() => {
          // onSubmit
        }}
        initialValues={{
          sales_order_id:
            state && isCreatingAppointmentFromNavigationState
              ? state?.sales_order_id
              : '',
          startDate: startDate ? startDate : moment().format('YYYY-MM-DD'),
          endDate: endDate ? endDate : moment().format('YYYY-MM-DD'),
          date_from: '',
          date_to: '',
          time_to: time_to?.split('+')[0] || '',
          time_from: time_from?.split('+')[0] || '',
          arrival_to: arrival_to?.split('+')[0] || '',
          arrival_from: arrival_from?.split('+')[0] || '',
          appointment_type_id: AppointmentTypeIds.INSTALLATION,
          users_ids: selectedUsersOptions.map((user) => user.id) || [],
          resources_ids:
            selectedResourcesOptions.map((resource) => resource.id) || [],
          workers_ids: selectedWorkersOptions.map((worker) => worker.id) || [],
        }}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, setFieldValue, setFieldError, values, isValid }) => {
          return (
            <Container>
              {createAppointmentIsLoading && <Loader />}
              <Scrollable>
                <DateTimeSection>
                  <H5>{t('DATE & TIME')}</H5>
                  <DateLabel>{t('From')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.startDate)}
                      setDate={(newValue: any) => {
                        const initialDateInterval = getDateInterval(
                          values.startDate,
                          values.endDate
                        );

                        const newDateFrom = newValue?.format('YYYY-MM-DD');
                        const newDateTo = moment(newValue)
                          .add(initialDateInterval, 'milliseconds')
                          .format('YYYY-MM-DD');

                        setFieldValue('startDate', newDateFrom);
                        setFieldValue('endDate', newDateTo);
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              startDate: newDateFrom,
                              endDate: newDateTo,
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <TimePicker
                        width="100%"
                        pwId="time-from-select"
                        time={moment(values?.time_from, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          const initialTimeInterval = getTimeInterval(
                            values?.time_from,
                            values?.time_to
                          );
                          const newTimeFrom = newValue?.format('HH:mm:ss');
                          const {
                            arrivalFrom: newArrivalTimeFrom,
                            arrivalTo: newArrivalTimeTo,
                          } = getArrivalTime(
                            newTimeFrom,
                            appointmentLowerInterval,
                            appointmentUpperInterval
                          );
                          const newTimeTo = moment(newValue, 'HH:mm:ss')
                            .add(initialTimeInterval, 'milliseconds')
                            .format('HH:mm:ss');

                          setFieldValue('time_from', newTimeFrom);
                          setFieldValue('time_to', newTimeTo);
                          setFieldValue('arrival_from', newArrivalTimeFrom);
                          setFieldValue('arrival_to', newArrivalTimeTo);

                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            newTimeFrom,
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            newTimeTo,
                            setFieldError,
                            t,
                            'time_to'
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <DateLabel>{t('To')}</DateLabel>
                  <DateTimeWrapper>
                    <DatePicker
                      pwId="date-field"
                      width="50%"
                      date={moment(values?.endDate)}
                      setDate={(newValue: any) => {
                        setFieldValue(
                          'endDate',
                          newValue?.format('YYYY-MM-DD')
                        );
                        setFieldError('time_to', undefined);
                        setTimeout(() => {
                          validateTimeFields(
                            {
                              ...values,
                              endDate: newValue?.format('YYYY-MM-DD'),
                            },
                            setFieldError,
                            t
                          );
                        }, 0);
                      }}
                    />
                    <TimeWrapper>
                      <TimePicker
                        width="100%"
                        pwId="time-to-select"
                        time={moment(values?.time_to, 'HH:mm:ss')}
                        setTime={(newValue: Moment) => {
                          validateDateTimeInterval(
                            moment(values?.startDate).format('YYYY-MM-DD'),
                            values.time_from,
                            moment(values?.endDate).format('YYYY-MM-DD'),
                            newValue?.format('HH:mm:ss'),
                            setFieldError,
                            t,
                            'time_to'
                          );
                          setFieldValue(
                            'time_to',
                            newValue?.format('HH:mm:ss')
                          );
                        }}
                      />
                    </TimeWrapper>
                  </DateTimeWrapper>
                  <TimeIntervalErrorMessageCont>
                    <TimeIntervalErrorMessage>
                      {errors.time_to}
                    </TimeIntervalErrorMessage>
                  </TimeIntervalErrorMessageCont>
                  <ArrivalTimeWrapper>
                    <ArrivalTimeInner>
                      <ArrivalTime>
                        <ArrivalTimeText
                          onClick={() => setShowArrivalTime(true)}
                        >
                          {t('Arrival time')}
                        </ArrivalTimeText>
                        <Tooltip
                          content={
                            t(
                              'This is the expected arrival time that will be communicated with the customer via the appointment email'
                            ) + '.'
                          }
                        >
                          <Icon
                            svg={WarningCircle}
                            color={COLORS.PRIMARY}
                            size={20}
                            weight="regular"
                            marginLeft={'10rem'}
                            pointer={false}
                          />
                        </Tooltip>
                      </ArrivalTime>
                      {showArrivalTime && (
                        <>
                          <AltTimeWrapper>
                            <TimePicker
                              width="100%"
                              pwId="time-from-select"
                              time={moment(values?.arrival_from, 'HH:mm:ss')}
                              setTime={(newValue: any) => {
                                validateTimeOnlyInterval(
                                  newValue?.format('HH:mm:ss'),
                                  values.arrival_to,
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_from',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                            <Icon
                              svg={Minus}
                              size={18}
                              weight="bold"
                              color={COLORS.BLACK}
                              marginLeft="5rem"
                              marginRight="5rem"
                            />
                            <TimePicker
                              width="100%"
                              pwId="time-to-select"
                              time={moment(values?.arrival_to, 'HH:mm:ss')}
                              setTime={(newValue: any) => {
                                validateTimeOnlyInterval(
                                  values.arrival_from,
                                  newValue?.format('HH:mm:ss'),
                                  setFieldError,
                                  t,
                                  'arrival_to'
                                );
                                setFieldValue(
                                  'arrival_to',
                                  newValue?.format('HH:mm:ss')
                                );
                              }}
                            />
                          </AltTimeWrapper>
                        </>
                      )}
                    </ArrivalTimeInner>
                    <TimeIntervalErrorMessageCont>
                      <TimeIntervalErrorMessage>
                        {errors.arrival_to}
                      </TimeIntervalErrorMessage>
                    </TimeIntervalErrorMessageCont>
                  </ArrivalTimeWrapper>
                </DateTimeSection>
                <HorizontalLine />
                <SalesOrderHeading>
                  <H5>{t('SALES ORDER')}</H5>
                </SalesOrderHeading>
                {isCreatingAppointmentFromNavigationState &&
                state?.sales_order_id ? (
                  <FormikInput
                    disabled
                    height={'41rem'}
                    name="sales_order_id"
                    value={state?.sales_order_number}
                    wrapperStyles={{
                      width: '300rem',
                      marginBottom: '4rem',
                      marginTop: '20rem',
                    }}
                  />
                ) : (
                  <HalfCont>
                    <StyledSelectTop
                      pwId="sales-order-id"
                      name="sales_order_id"
                      options={salesOrdersOptions}
                      component={CustomSelect}
                      placeholder={t('Sales order')}
                      isMulti={false}
                      onInputChange={(e: string) => setSalesOrderSearchBy(e)}
                      onSelect={({ value }: any) => {
                        setSelectedSalesOrderId(value);
                        // The query is reset here in order to reset the summary cache for that order,
                        // so that isSuccess for summary fetching can trigger regardless if the lines summary data has been cached or not
                        queryClient.resetQueries([
                          ReactQueryKeys.GET_SALES_ORDER_LINES_SUMMARY,
                          value,
                        ]);
                      }}
                      onMenuScrollToBottom={() =>
                        hasNextPageSalesOrders && fetchNextPageSalesOrders()
                      }
                      isLoading={isLoadingSalesOrders}
                      translate={false}
                      errorMessage={errors.sales_order_id}
                      menuHeight={'200rem'}
                    />
                  </HalfCont>
                )}
                <HorizontalLine />
                <ProductsHeading>
                  <H5>{t('PRODUCTS')}</H5>
                  {selectedSalesOrder && (
                    <ProductsHeadingSecondRow>
                      <Input
                        pwId="search-field"
                        icon={MagnifyingGlass}
                        width={'200rem'}
                        placeholder={t('Search')}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <SelectAllContainer>
                        <SelectAllLabel
                          data-testid="select-all"
                          onClick={() =>
                            !isLoadingSalesOrderLines &&
                            setSelectedSalesOrderLineIds(
                              salesOrderLinesData?.sales_order_lines?.map(
                                (line: ISalesOrderLine) => line.id
                              )
                            )
                          }
                        >
                          {t('Select all')}
                        </SelectAllLabel>
                        <ClearLabel
                          data-testid="clear"
                          onClick={() =>
                            !isLoadingSalesOrderLines &&
                            setSelectedSalesOrderLineIds([])
                          }
                        >
                          {t('Clear')}
                        </ClearLabel>
                      </SelectAllContainer>
                    </ProductsHeadingSecondRow>
                  )}
                </ProductsHeading>
                {isLoadingSalesOrderLines ? (
                  <SpinnerWrapper>
                    <Spinner />
                  </SpinnerWrapper>
                ) : (
                  getElementsForCurrentPage(
                    salesOrderLinesData?.sales_order_lines || []
                  )?.map((salesOrderLine: ISalesOrderLine, index: number) => {
                    const isSelected = selectedSalesOrderLineIds?.includes(
                      Number(salesOrderLine.id)
                    );
                    return (
                      <AppointmentSalesOrderLine
                        key={`sales-order-line-${index}`}
                        salesOrderLine={salesOrderLine}
                        isSelected={isSelected}
                        toggleIsSelected={(salesOrderLineId: number) =>
                          isSelected
                            ? setSelectedSalesOrderLineIds(
                                selectedSalesOrderLineIds.filter(
                                  (id) => id !== salesOrderLineId
                                )
                              )
                            : setSelectedSalesOrderLineIds([
                                ...selectedSalesOrderLineIds,
                                salesOrderLineId,
                              ])
                        }
                      />
                    );
                  })
                )}
                {salesOrderLinesData?.sales_order_lines?.length >
                  SALES_ORDER_LINES_PER_PAGE && (
                  <PaginationWrapper>
                    <Pagination
                      page={page}
                      perPage={SALES_ORDER_LINES_PER_PAGE}
                      total={salesOrderLinesData?.sales_order_lines?.length}
                      loadPage={(newPage) => setPage(newPage)}
                    />
                  </PaginationWrapper>
                )}
                {selectedSalesOrderId && !selectedSalesOrderLineIds?.length && (
                  <ProductErrorLabel>
                    {t('Please choose at least one product')}
                  </ProductErrorLabel>
                )}
                <HorizontalLine />
                <UsersHeading>
                  <H5>{t('USERS')}</H5>
                </UsersHeading>
                <UsersContainer>
                  <HalfCont>
                    <StyledSelect
                      name="users_ids"
                      options={userOptions}
                      onInputChange={(e: string) => setUserSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Users')}
                      isMulti={true}
                      onSelect={(selectedOptions: Option[]) =>
                        setSelectedUserOptions(selectedOptions)
                      }
                      onMenuScrollToBottom={() =>
                        hasNextPageUsers && fetchNextPageUsers()
                      }
                      isLoading={isLoadingUsers}
                      translate={false}
                      menuHeight={'200rem'}
                    />
                  </HalfCont>
                </UsersContainer>
                <HorizontalLine />
                <WorkersAndResourcesHeading>
                  <H5>{t('WORKERS & RESOURCES')}</H5>
                </WorkersAndResourcesHeading>
                <WorkersAndResources>
                  <Cont>
                    <StyledSelect
                      pwId="workers-id"
                      name="workers_ids"
                      options={workerOptions}
                      onInputChange={(e: string) => setWorkerSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Workers')}
                      isMulti={true}
                      onSelect={(selectedOptions: Option[]) =>
                        setSelectedWorkerOptions(selectedOptions)
                      }
                      onMenuScrollToBottom={() => {
                        hasNextPageWorkers && fetchNextPageWorkers();
                      }}
                      isLoading={isLoadingWorkers}
                      translate={false}
                      menuHeight={'200rem'}
                      menuPlacement={'top'}
                    />
                  </Cont>
                  <Cont>
                    <StyledSelect
                      pwId="resources-id"
                      name="resources_ids"
                      options={resourceOptions}
                      onInputChange={(e: string) => setResourceSearchBy(e)}
                      component={CustomSelect}
                      placeholder={t('Resources')}
                      isMulti
                      onSelect={(selectedOptions: Option[]) =>
                        setSelectedResourceOptions(selectedOptions)
                      }
                      onMenuScrollToBottom={() => {
                        hasNextPageResources && fetchNextPageResources();
                      }}
                      isLoading={isLoadingResources}
                      translate={false}
                      menuHeight={'200rem'}
                      menuPlacement={'top'}
                    />
                  </Cont>
                </WorkersAndResources>
                <CheckboxContainer>
                  <Checkbox
                    isChecked={isConfirmed}
                    onChange={setIsConfirmed}
                    label={t('The customer already confirmed the appointment')}
                  />
                </CheckboxContainer>
              </Scrollable>
              <ApplyWrapper>
                <Button
                  disabled={createAppointmentIsLoading}
                  onClick={closeAppointmentModal}
                  secondary
                  width="200rem"
                  label={t('Cancel')}
                />
                <Button
                  disabled={
                    !isValid ||
                    !selectedSalesOrderId || // Sales order id is kept outside formik
                    !selectedSalesOrderLineIds?.length || // Line ids are kept outside formik
                    createAppointmentIsLoading ||
                    isLoadingSelectedSalesOrder ||
                    isLoadingSalesOrderLines
                  }
                  onClick={() => {
                    checkEmailSending(values);
                  }}
                  primary
                  width="200rem"
                  label={t('Save')}
                />
              </ApplyWrapper>
            </Container>
          );
        }}
      </Formik>
      <YesOrNoModal
        pwId="yes-or-no-appointment-confirmation-modal"
        level="SECOND"
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onYes={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onNo={() => {
          handleCreateAppointment(formikValues!, false);
          setIsSendEmailModalOpen(false);
        }}
        title={''}
        description={
          isConfirmed
            ? t(
                'Do you want to send the appointment confirmation to the customer'
              ) + '?'
            : t('Do you want to send the appointment offer to the customer') +
              '?'
        }
      />

      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={formikValues?.sales_order_id || ''}
        type={AddEmailNoteModalType.INSTALLATION_CREATION}
        newAppointment={
          {
            ...formikValues,
            sales_order_lines_ids: selectedSalesOrderLineIds,
          } as ICreateAppointmentDTO
        }
        isConfirmedAppointment={isConfirmed}
        onCreateAppointmentSuccess={() => {
          isCreatingAppointmentFromNavigationState &&
            setIsCreatingAppointmentFromNavigationState(false);
          closeAppointmentModal();
        }}
        emailDetails={emailDetails}
      />
    </div>
  );
};

export default InstallationTab;
