import { CSSProperties } from '@mui/styled-engine';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  containerStyle: CSSProperties;
  isDragging: boolean;
}

export const Container = styled.div`
  /* TODO: Check all places where we have this */
  max-width: 100%;
  width: 100%;
  ${({ containerStyle }: StyledProps) => containerStyle}
  transition: opacity 200ms ease-out;
  ${({ isDragging }: StyledProps) =>
    isDragging &&
    css`
      opacity: 0;
    `}

  ${respondTo.smallTablet`
      margin-bottom: 10rem;
    `}
`;
