import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  InputContainer,
} from './AdvancedFilterSaveModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import Input from 'components/Input/Input';

interface IAdvancedFilterSaveModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: (newName: string) => void;
  onCancel?: () => void;
  pwId?: string;
}

export const AdvancedFilterSaveModal = ({
  isOpen,
  setIsOpen,
  onConfirm,
  onCancel,
  pwId,
}: IAdvancedFilterSaveModalProps) => {
  const { t } = useTranslation();
  const [newName, setNewName] = useState<string>('');

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      level="SECOND"
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <TopContainer>
        <CloseContainer>
          <Close
            pwId={pwId}
            onClick={() => {
              onCancel && onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title data-testid={`${pwId}-save-advanced-filter-title`}>
            {t('Save advanced filter')}
          </Title>
        </TitleContainer>
        <InputContainer>
          <Input
            pwId={`${pwId}-filter-name-input`}
            placeholder={t('Filter name')}
            width={'400rem'}
            height="41rem"
            value={newName}
            onChange={(e) => {
              setNewName(e.target.value);
            }}
          />
        </InputContainer>
      </TopContainer>
      <ButtonContainer>
        <Margin>
          <Button
            pwId={`${pwId}-save-filter-modal-cancel-button`}
            onClick={() => {
              onCancel && onCancel();
            }}
            secondary
            width="200rem"
            label={t('Cancel')}
          />
        </Margin>
        <Button
          pwId={`${pwId}-save-filter-modal-save-button`}
          primary
          width="200rem"
          label={t('Save')}
          onClick={() => {
            onConfirm && onConfirm(newName);
          }}
          disabled={!newName.length}
        />
      </ButtonContainer>
    </Modal>
  );
};
