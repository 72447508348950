import { COLORS, gapSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import Button from 'components/Button/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: ${gapSm};
  margin-left: auto;

  ${respondTo.smallTablet`
    margin: 0;
    flex-direction: column;
  `}
`;

export const IconColorInvertButton = styled(Button)`
  svg {
    filter: brightness(0) invert(1);
  }
`;

export const HoverIconColorInvertButton = styled(Button)`
  &:hover svg {
    filter: brightness(0) invert(1);
  }
`;

export const IntervalDurationLabel = styled.label`
  font-size: 18rem;
  font-weight: 300;
  color: ${COLORS.PRIMARY};
`;
