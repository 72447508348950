import { Container } from './SinglePurchaseCreditInvoicePage.styled';
import { useTranslation } from 'react-i18next';
import {
  useChangePurchaseInvoiceStatus,
  useExportPurchaseInvoice,
  useGetSinglePurchaseCreditInvoice,
} from './hooks';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';
import SubSideMenu from 'components/Layout/Navigation/SubSideMenu/SubSideMenu';
import {
  TitleContainer,
  ButtonContainer,
  Wrapper,
  IconContainer,
  LeftSide,
  H3,
  StatusContainer,
  StatusOptionsContainer,
  PenImg,
  Option,
  P,
} from './SinglePurchaseCreditInvoicePage.styled';
import Button from 'components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { subMenuItems } from './constants';
import SinglePurchaseCreditInvoiceRouter from 'navigation/SingleInvoice/PurchaseCreditInvoice/SinglePurchaseCreditInvoiceRouter';
import { AddPaymentModal } from 'components/Modal/AddPaymentModal/AddPaymentModal';
import { useGetPurchaseInvoiceStatuses } from 'utils/hooks/useGetPurchaseInvoiceStatuses';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import { RoutesConfig } from 'navigation/routes';
import Icon from 'components/Icon/Icon';
import { COLORS, marginXs } from 'assets/styled';
import Tooltip from 'components/Tooltip/Tooltip';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import {
  CaretLeft,
  DownloadSimple,
  EnvelopeSimple,
  PencilSimple,
} from '@phosphor-icons/react';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';

const SinglePurchaseCreditInvoicePage = () => {
  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const { data, isError: isErrorPurchaseCreditInvoice } =
    useGetSinglePurchaseCreditInvoice(id!);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);

  const canEditPurchaseInvoice = useCan(Actions.EDIT_PURCHASE_INVOICE);

  const { mutate: exportPurchaseInvoice } = useExportPurchaseInvoice();
  const { mutate: changePurchaseInvoiceStatus } =
    useChangePurchaseInvoiceStatus();

  const statusContainerWrapperRef = useRef<HTMLDivElement>();

  useOutsideAlerter(
    statusContainerWrapperRef,
    () => canEditPurchaseInvoice && setIsStatusModalOpen(false)
  );

  // const statusOptions = useGetStatusOptions(); //
  const statusOptions = useGetPurchaseInvoiceStatuses();

  return !isErrorPurchaseCreditInvoice ? (
    <Container>
      <SubSideMenu subMenuItems={subMenuItems} />
      <Wrapper>
        <TitleContainer>
          <LeftSide>
            <Icon
              svg={CaretLeft}
              weight="regular"
              size={15}
              color={COLORS.BLACK}
              onClick={() => navigate(RoutesConfig.Invoices.fullPath)}
            />
            <H3>
              {t('Invoice')}&nbsp;
              {data?.purchase_invoice?.invoice_number}
            </H3>
            <StatusContainer ref={statusContainerWrapperRef}>
              <P
                onClick={() =>
                  canEditPurchaseInvoice &&
                  setIsStatusModalOpen(!isStatusModalOpen)
                }
              >
                {capitalizeFirstLetter(
                  t(data?.purchase_invoice?.payment_status?.name)
                )}
              </P>
              {canEditPurchaseInvoice && (
                <PenImg>
                  <Icon
                    onClick={() => setIsStatusModalOpen(!isStatusModalOpen)}
                    svg={PencilSimple}
                    color={COLORS.PRIMARY}
                    size={15}
                  />
                </PenImg>
              )}
              {isStatusModalOpen && (
                <StatusOptionsContainer>
                  {statusOptions.map((option) => {
                    return (
                      <Option
                        isSelected={
                          option.label ===
                          data?.purchase_invoice.payment_status?.name
                        }
                        key={option.value}
                        onClick={() => {
                          changePurchaseInvoiceStatus({
                            id: data?.purchase_invoice?.id || '',
                            status_id: option.value,
                          });
                        }}
                      >
                        {capitalizeFirstLetter(t(option.label))}
                      </Option>
                    );
                  })}
                </StatusOptionsContainer>
              )}
            </StatusContainer>
          </LeftSide>
          <IconContainer>
            <Tooltip content={t('Open PDF')} key={'exportKey'}>
              <Icon
                disabled={!data}
                data-testid="download-pdf"
                svg={DownloadSimple}
                color={COLORS.PRIMARY}
                size={25}
                marginRight={marginXs}
                marginLeft={marginXs}
                onClick={() =>
                  exportPurchaseInvoice(data?.purchase_invoice?.id)
                }
              />
            </Tooltip>
            <Tooltip content={t('Send mail')} key={'emailKey'}>
              <Icon
                data-testid="send-email"
                svg={
                  data?.purchase_invoice.sent_to_customer
                    ? MailSent
                    : EnvelopeSimple
                }
                size={25}
                onClick={() => setIsSendEmailModalOpen(true)}
                color={COLORS.PRIMARY}
              />
            </Tooltip>
          </IconContainer>
        </TitleContainer>
        <ButtonContainer>
          <Button
            label={t('Add Payment')}
            secondary
            disabled={!canEditPurchaseInvoice}
            width="200rem"
            onClick={() => setIsPaymentModalOpen(true)}
          />
        </ButtonContainer>
        <SinglePurchaseCreditInvoiceRouter />
      </Wrapper>
      <ConfirmModal
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onConfirm={() => {
          // sendPurchaseInvoiceEmail(id!);
          setIsSendEmailModalOpen(false);
        }}
        onCancel={() => setIsSendEmailModalOpen(false)}
        title={t('Send E-mail')}
        description={'TODO: sendPurchaseInvoiceEmail'}
      />
      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        setIsOpen={setIsPaymentModalOpen}
        invoiceId={data?.purchase_invoice?.id}
        invoiceType={InvoiceType.CREDIT_PURCHASE_INVOICE}
        initialPaymentAmount={
          data?.purchase_invoice?.total_amount -
          data?.purchase_invoice?.total_amount_payed
        }
      />
    </Container>
  ) : (
    <PageNotFound />
  );
};

export default SinglePurchaseCreditInvoicePage;
