import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10rem;
  padding: 30rem;
  background-color: ${COLORS.WHITE};
  width: 100%;
  height: 420rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
    height: auto;
    padding: 20rem;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6.17rem;
  cursor: pointer;
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_1000};
  display: flex;
  width: 100%;
`;

export const NoContentLabel = styled.label`
  margin-top: 100rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15rem;

  ${respondTo.smallTablet`
    gap: 5rem;
  `}
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;
