import { COLORS, H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Percent, Info } from '@phosphor-icons/react';

import {
  AlternativeDownpaymentConditionWrapper,
  ApplyWrapper,
  CheckboxContainer,
  CloseContainer,
  ColumnContainer,
  Container,
  Heading,
  RowSpaceBetween,
  Title,
  TitleContainer,
} from './AddEditPaymentConditionModal.styled';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { validateNumericFieldWithZero } from 'utils/validation';
import { addPaymentConditionSchema } from './validation';
import { useAddPaymentCondition, useEditPaymentCondition } from './hooks';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import { IPaymentCondition } from 'types/PaymentCondition.types';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IAddEditPaymentConditionModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  paymentConditionToEdit?: IPaymentCondition;
}
export const AddEditPaymentConditionModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  paymentConditionToEdit,
}: IAddEditPaymentConditionModalProps) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();

  const { mutate: addPaymentCondition, isSuccess: isSuccessAdd } =
    useAddPaymentCondition();
  const { mutate: editPaymentCondition, isSuccess: isSuccessEdit } =
    useEditPaymentCondition(paymentConditionToEdit?.id.toString() || '');

  useEffect(() => {
    if (isSuccessAdd || isSuccessEdit) setIsOpen(false);
  }, [isSuccessAdd, isSuccessEdit]);

  const { isPhone, isSmallPhone } = useBreakpointFlag();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
        overflow: 'initial',
        zIndex: 10000,
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>
          {paymentConditionToEdit
            ? t('Edit Payment Condition')
            : t('Add Payment Condition')}
        </Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          validateOnMount
          initialValues={{
            name: paymentConditionToEdit?.name || '',
            text: paymentConditionToEdit?.text || '',
            downpaymentThreshold:
              paymentConditionToEdit?.downpayment_threshold || '',
            downpaymentPercentage:
              paymentConditionToEdit?.downpayment_percentage || '',
            altText: paymentConditionToEdit?.alt_text || '',
            altChecked: !!paymentConditionToEdit?.alt_text,
          }}
          validationSchema={addPaymentConditionSchema}
        >
          {({
            values,
            setFieldValue,
            errors,
            isValid,
            touched,
            handleBlur,
          }) => {
            return (
              <Container>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Payment condition label').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      placeholder={t('Label')}
                      errorMessage={touched['name'] ? errors['name'] : ''}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('name', value);
                        handleBlur(e);
                      }}
                      height={'43rem'}
                      name="name"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                        marginBottom: isPhone ? 0 : '4rem',
                        marginTop: isPhone ? '15rem' : '20rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Payment condition text').toUpperCase()}</H4>
                      <Tooltip
                        content={t(
                          "Payment condition text visible on the PDF's"
                        )}
                      >
                        <Icon svg={Info} color={COLORS.PRIMARY} />
                      </Tooltip>
                    </Heading>
                    <FormikInput
                      placeholder={t('Text')}
                      errorMessage={touched['text'] ? errors['text'] : ''}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('text', value);
                        handleBlur(e);
                      }}
                      height={'43rem'}
                      name="text"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                        marginBottom: isPhone ? 0 : '4rem',
                        marginTop: isPhone ? '15rem' : '20rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>

                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Down payment threshold').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      placeholder={t('Threshold')}
                      errorMessage={
                        touched['downpaymentThreshold']
                          ? errors['downpaymentThreshold']
                          : ''
                      }
                      validate={(value: string) =>
                        validateNumericFieldWithZero(
                          value,
                          'downpaymentThreshold'
                        )
                      }
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('downpaymentThreshold', value);
                        handleBlur(e);
                      }}
                      height={'43rem'}
                      name="downpaymentThreshold"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                        marginBottom: isPhone ? 0 : '4rem',
                        marginTop: isPhone ? '15rem' : '20rem',
                      }}
                      type="number"
                      symbol={currencySymbol}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4>{t('Down payment percentage').toUpperCase()}</H4>
                    </Heading>
                    <FormikInput
                      placeholder={t('Percentage')}
                      errorMessage={
                        touched['downpaymentPercentage']
                          ? errors['downpaymentPercentage']
                          : ''
                      }
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('downpaymentPercentage', value);
                        handleBlur(e);
                      }}
                      height={'43rem'}
                      name="downpaymentPercentage"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                        marginBottom: isPhone ? 0 : '4rem',
                        marginTop: isPhone ? '15rem' : '20rem',
                      }}
                      type="number"
                      icon={Percent}
                      validate={(value: string) =>
                        validateNumericFieldWithZero(
                          value,
                          'downpaymentPercentage'
                        )
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>

                <ColumnContainer>
                  <Heading>
                    <H4>{t('Down payment condition').toUpperCase()}</H4>
                    <Tooltip
                      content={t(
                        "Down payment condition text visible on the down payment invoice PDF's"
                      )}
                    >
                      <Icon svg={Info} color={COLORS.PRIMARY} />
                    </Tooltip>
                  </Heading>
                  <AlternativeDownpaymentConditionWrapper>
                    <CheckboxContainer>
                      <Checkbox
                        isChecked={values.altChecked}
                        onChange={() => {
                          if (values.altChecked) setFieldValue('altText', '');
                          setFieldValue('altChecked', !values.altChecked);
                        }}
                        label={t('Alternative down payment condition')}
                      />
                    </CheckboxContainer>
                    <FormikInput
                      disabled={!values.altChecked}
                      placeholder={t('Alternative down payment condition')}
                      errorMessage={touched['altText'] ? errors['altText'] : ''}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('altText', value);
                        handleBlur(e);
                      }}
                      height={'43rem'}
                      name="altText"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                        marginBottom: isPhone ? 0 : '4rem',
                        marginTop: isPhone ? '15rem' : '20rem',
                      }}
                    />
                  </AlternativeDownpaymentConditionWrapper>
                </ColumnContainer>

                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!isValid}
                    onClick={() => {
                      if (isValid) {
                        const newPaymentCondition = {
                          name: values.name,
                          text: values.text,
                          downpayment_threshold: Number(
                            values.downpaymentThreshold
                          ),
                          downpayment_percentage: Number(
                            values.downpaymentPercentage
                          ),
                          alt_text: values.altText,
                        };
                        if (paymentConditionToEdit)
                          editPaymentCondition(newPaymentCondition);
                        else addPaymentCondition(newPaymentCondition);
                        onCancel();
                      }
                    }}
                    primary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
