import { gapLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;
  gap: ${gapLg};

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    gap: 20rem;
  `}
`;
