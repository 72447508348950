import {
  COLORS,
  borderRadiusSm,
  gapSm,
  gapXs,
  marginLg,
  marginMd,
  marginSm,
  paddingMd,
  paddingLg,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-width: 1100rem;
  width: 100%;
  padding: 0 15rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
  margin-top: ${marginLg};
  margin-bottom: ${marginLg};

  ${respondTo.smallTablet`
    margin: 20rem 0;
  `}
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${COLORS.WHITE};
  height: 100%;
  width: 100%;
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};

  ${respondTo.smallTablet`
    padding: 20rem;
  `}
`;

export const Label = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  max-width: 30%;

  ${respondTo.smallTablet`
    max-width: 100%;
    width: 100%;
    border-bottom: 1px solid ${COLORS.GREY_200};
    padding-bottom: 20rem;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  gap: 50rem;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;

  ${respondTo.bigTablet`
    gap: 30rem;
  `}

  ${respondTo.smallTablet`
    flex-direction: column;
    align-items: flex-start;
    gap: 20rem;
    margin-bottom: 20rem;
  `}
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
`;

export const EmailConfigurationWrapper = styled.div`
  margin-top: ${marginSm};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const LabelSecondary = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;

export const DefaultAttachmentLabelsWrapper = styled.div`
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
  display: flex;
  flex-direction: column;
  gap: ${gapXs};
`;

export const DefaultAttachmentNameLinkDeleteIconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${gapSm};
  align-items: center;
`;

export const DefaultAttachmentNameLink = styled.a`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.PRIMARY};
`;

export const LoaderWrapper = styled.div`
  width: 200rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
