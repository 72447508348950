import { H4 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import CustomSelect from 'components/Select/FormikSelect';
import { Option } from 'components/Select/type';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IRecurringQuotationDetails } from '../constants';
import {
  useGetRecurringIntervalOptions,
  useGetRecurringTypeOptions,
} from './hooks';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Heading,
  RowSpaceBetween,
  SelectWrapper,
  StyledSelect,
  Title,
  TitleContainer,
} from './RecurringQuotationModal.styled';

const PWID = 'reccuring-quotation-modal';

interface IRecurringQuotationModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onApply: (values: IRecurringQuotationDetails) => void;
  recurringDetails: IRecurringQuotationDetails;
  pwId?: string;
}
export const RecurringQuotationModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  onApply,
  recurringDetails,
  pwId,
}: IRecurringQuotationModalProps) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const recurringTypeOptions = useGetRecurringTypeOptions();
  const recurringIntervalOptions = useGetRecurringIntervalOptions();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
        overflow: 'initial',
      }}
    >
      <CloseContainer>
        <Close pwId={`${pwId}-${PWID}`} onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title data-testid={`${pwId}-${PWID}-recurring-quotation-title`}>
          {t('Recurring Quotation')}
        </Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={recurringDetails}
        >
          {({ values, setFieldValue, errors, isValid, touched }) => {
            return (
              <Container>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-recurring-type-title`}>
                        {t('Recurring type').toUpperCase()}
                      </H4>
                    </Heading>
                    <SelectWrapper>
                      <StyledSelect
                        pwId={`${pwId}-${PWID}-recurring-type`}
                        errorMessage={
                          touched['recurring_type']
                            ? errors['recurring_type']
                            : ''
                        }
                        name="recurring_type"
                        options={recurringTypeOptions}
                        component={CustomSelect}
                        isMulti={false}
                        onSelect={(option: Option) => {
                          setFieldValue(
                            'recurring_type',
                            option ? option.value : ''
                          );
                        }}
                        isClearable
                      />
                    </SelectWrapper>
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-recurring-period-type`}>
                        {t('Recurring period').toUpperCase()}
                      </H4>
                    </Heading>
                    <SelectWrapper>
                      <StyledSelect
                        pwId={`${pwId}-${PWID}-recurring-period`}
                        errorMessage={
                          touched['recurring_period']
                            ? errors['recurring_period']
                            : ''
                        }
                        name="recurring_period"
                        options={recurringIntervalOptions}
                        component={CustomSelect}
                        isMulti={false}
                        onSelect={(option: Option) => {
                          setFieldValue(
                            'recurring_period',
                            option ? option.value : ''
                          );
                        }}
                        isClearable
                      />
                    </SelectWrapper>
                  </ColumnContainer>
                </RowSpaceBetween>

                <ApplyWrapper>
                  <Button
                    pwId={`${pwId}-${PWID}-cancel-button`}
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    pwId={`${pwId}-${PWID}-apply-button`}
                    disabled={
                      (!!values.recurring_period && !values.recurring_type) ||
                      (!values.recurring_period && !!values.recurring_type)
                    }
                    onClick={() => {
                      if (isValid) {
                        onApply(values);
                        onCancel();
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
