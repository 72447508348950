import {
  COLORS,
  H3,
  marginLg,
  marginMd,
  marginSm,
  marginXs,
} from 'assets/styled';
import styled from 'styled-components';
import { borderRadiusSm } from 'assets/styled';
import { Field } from 'formik';
import CustomSelect from 'components/Select/FormikSelect';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  max-width: 690rem;
  width: 100%;
  min-height: 385rem;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginLg};

  ${respondTo.smallTablet`
    max-width: calc(100% - 30rem);
    min-height: auto;
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  width: 100%;

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
`;

export const Margin = styled.div`
  margin-left: 30rem;
`;

export const Line = styled.div`
  margin-top: ${marginXs};
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
`;

export const RightAlign = styled(Row)`
  justify-content: flex-end;
  margin-top: 10rem;
`;

export const ItemsLabel = styled(H3)``;

export const SingleItemWrapper = styled.div``;

export const StyledTextArea = styled(Field)`
  width: 100%;
  resize: none;
  border-radius: ${borderRadiusSm};
  padding-left: 16rem;
  padding-right: 16rem;
  padding-top: 8rem;
  padding-bottom: 8rem;
  height: 120rem;
`;

export const RowMarginBottom20 = styled.div`
  margin-bottom: ${marginSm};
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;
`;

export const ItemDropdownWrapper = styled.div`
  margin-top: ${marginMd};
  margin-bottom: ${marginMd};
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${respondTo.bigPhone`
    margin-bottom: 10rem;
  `}
`;

export const FieldWrapper = styled.div`
  width: 50%;
  padding-right: 15rem;

  ${respondTo.bigPhone`
    width: 100%;
    padding-right: 0;
  `}
`;

export const RowMarginTop = styled.div`
  margin-top: ${marginSm};
`;
