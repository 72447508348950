import { COLORS, marginMd, marginSm, marginXs } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  $withoutMargin: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10rem;
  max-height: 70vh;
  overflow-y: auto;
  padding: 0 5rem;
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
  margin-bottom: 20rem;
`;

export const Actions = styled.div`
  display: flex;
  gap: 10rem;
`;

export const Title = styled.div`
  font-size: 20px;
  font-weight: 300;
  text-align: left;
  color: ${COLORS.BLACK};
  margin-right: 10rem;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  max-width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-bottom: 9rem;
`;

export const FieldTitle = styled.span`
  font-size: 16rem;
  display: block;
  color: ${COLORS.BLACK};
  margin-bottom: 10rem;
  text-transform: uppercase;
  font-weight: bold;
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const FieldTitleSelect = styled(FieldTitle)`
  margin-bottom: 20rem;
`;

export const CheckboxWrapper = styled.div`
  padding-left: 5rem;
  padding-bottom: 5rem;
`;

export const SelectWrapper = styled.div`
  width: 50%;
  margin-bottom: 10rem;
  padding-right: 15rem;
`;

export const FieldValue = styled.span`
  display: block;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  margin-bottom: 10rem;
`;

export const MultiFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30rem;
`;

export const BlueValue = styled(FieldValue)`
  color: ${COLORS.PRIMARY};
`;

export const MultiSelectWrapper = styled.div`
  display: flex;
  width: 50%;
  gap: 30rem;
  width: 100%;
  margin-bottom: 15rem;
`;

export const HalfWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${marginXs};
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 10rem;
  flex-wrap: wrap;
`;

export const SpinnerWrapper = styled.div`
  margin-right: ${marginSm};
`;

export const AddLabelDropdownWrapper = styled.div`
  margin-left: ${marginSm};
  ${(props: StyledProps) =>
    props.$withoutMargin &&
    css`
      margin: 0;
    `};
`;

export const StartButtonWrapper = styled.div`
  margin-left: ${marginMd};
`;
