import { useNavigate, useParams } from 'react-router-dom';
import {
  AccountingFlowWrapper,
  ClickableLabel,
  Container,
  CreatedAtValidToDiv,
  CreatedAtValidToLabel,
  CreatedAtValidToLabelValue,
  InvoiceDescriptionLabel,
  LoaderWrapper,
  PenImg,
  ReferenceSpan,
  ReferenceWrapper,
  RightDiv,
  RowSpaceBetween,
  RowSpaceBetweenMarginTop,
  SmallColumnDiv,
  SmallColumnDivLabel,
  SmallColumnDivLabelValue,
  SpinnerWrapper,
  StatusLabel,
} from './SingleSalesInvoiceGeneralPage.styled';
import { useGetSingleSalesInvoice } from '../hooks';
import {
  ISalesInvoiceLine,
  ISalesInvoiceLineTableDTO,
} from 'types/Invoice.types';
import { formatSalesInvoiceLineTableData } from 'components/Table/tableDataFormatter';
import { tableConfig } from './constants';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { InvoiceType } from 'components/Modal/AddPaymentModal/constants';
import { EditExternalReferenceModal } from 'pages/Manager/SingleInvoice/EditExternalReferenceModal/EditExternalReferenceModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import moment from 'moment';
import { formatNumber } from 'utils/numberFormatter';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS, marginMd } from 'assets/styled';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { AccountingFlowSwitch } from '../../AccountingFlow/AccountingFlowSwitch';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import DraggableTable from 'components/DragAndDrop/DraggableTable/DraggableTable';
import { useReorderSalesInvoiceLines } from './hooks';
import { IReorderLinesRequestDTO } from 'components/DragAndDrop/DraggableLines/hooks';
import { PencilSimple } from '@phosphor-icons/react';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import Tooltip from 'components/Tooltip/Tooltip';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const EMPTY_VALUE = 0;

const SingleSalesInvoiceGeneralPage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();
  const {
    data,
    isLoading: isLoadingSingleSalesInvoice,
    isFetched: isFetchedSingleSalesInvoice,
  } = useGetSingleSalesInvoice(typeof id === 'string' ? id : '');
  const [isEditReferenceModalOpen, setIsEditReferenceModalOpen] =
    useState<boolean>(false);

  const { mutate: reorderSalesInvoiceLines } = useReorderSalesInvoiceLines(
    Number(id!)
  );

  const { isMidTablet } = useBreakpointFlag();

  const canEditSalesInvoice = useCan(Actions.EDIT_SALES_INVOICE);

  const {
    tax_rate: taxRate,
    has_accounting_integration: hasAccountingIntegration,
  } = useSelector((state: IRootReducerState) => state?.companyInfo?.settings);

  let tableData: ISalesInvoiceLineTableDTO[] = [];
  if (data?.sales_invoice?.sales_invoice_lines) {
    tableData = formatSalesInvoiceLineTableData(
      data.sales_invoice?.sales_invoice_lines
        .map((salesInvoiceLine: ISalesInvoiceLine) => {
          return salesInvoiceLine;
        })
        .flat(),
      taxRate,
      currencySymbol
    );
  }

  return (
    <Container className="single-sales-invoice-step-5">
      <AccountingFlowWrapper>
        {isFetchedSingleSalesInvoice ? (
          hasAccountingIntegration && (
            <AccountingFlowSwitch invoice={data?.sales_invoice} />
          )
        ) : (
          <SpinnerWrapper>
            <Spinner size={25} />
          </SpinnerWrapper>
        )}
      </AccountingFlowWrapper>

      <Container>
        {isLoadingSingleSalesInvoice ? (
          <LoaderWrapper>
            <Spinner size={50} />
          </LoaderWrapper>
        ) : (
          <>
            <RowSpaceBetween>
              <StatusLabel>
                {t(data?.sales_invoice?.payment_status?.name)}
              </StatusLabel>
              <RightDiv>
                <ReferenceWrapper>
                  {data?.sales_invoice?.external_id?.length > 10 ? (
                    <Tooltip content={data?.sales_invoice?.external_id}>
                      <ReferenceSpan
                        $editable={!canEditSalesInvoice}
                        onClick={() => {
                          if (canEditSalesInvoice)
                            return setIsEditReferenceModalOpen(true);
                        }}
                      >
                        {`${t('External reference')}${
                          data?.sales_invoice?.external_id
                            ? ` - 
                              ${data?.sales_invoice?.external_id.substring(
                                0,
                                10
                              )}...`
                            : ''
                        }`}
                      </ReferenceSpan>
                    </Tooltip>
                  ) : (
                    <ReferenceSpan
                      $editable={!canEditSalesInvoice}
                      onClick={() => {
                        if (canEditSalesInvoice)
                          return setIsEditReferenceModalOpen(true);
                      }}
                    >
                      {`${t('External reference')}${
                        data?.sales_invoice?.external_id
                          ? ' - ' + data?.sales_invoice?.external_id
                          : ''
                      }`}
                    </ReferenceSpan>
                  )}
                  {canEditSalesInvoice && (
                    <PenImg data-testid="edit-external-reference">
                      <Icon
                        onClick={() => setIsEditReferenceModalOpen(true)}
                        svg={PencilSimple}
                        color={COLORS.PRIMARY}
                        size={15}
                      />
                    </PenImg>
                  )}
                </ReferenceWrapper>
              </RightDiv>
            </RowSpaceBetween>

            <RowSpaceBetweenMarginTop>
              <CreatedAtValidToDiv>
                <CreatedAtValidToLabel>
                  {t('Invoice date')}
                </CreatedAtValidToLabel>
                <CreatedAtValidToLabelValue>
                  {moment(data?.sales_invoice?.invoice_date).format(
                    'DD-MM-YYYY'
                  )}
                </CreatedAtValidToLabelValue>
              </CreatedAtValidToDiv>
              <CreatedAtValidToDiv>
                <CreatedAtValidToLabel>{t('Valid to')}</CreatedAtValidToLabel>
                <CreatedAtValidToLabelValue>
                  {moment(data?.sales_invoice?.valid_to).format('DD-MM-YYYY')}
                </CreatedAtValidToLabelValue>
              </CreatedAtValidToDiv>
            </RowSpaceBetweenMarginTop>
            <InvoiceDescriptionLabel>
              <ClickableLabel
                onClick={() =>
                  navigate(
                    SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
                      ':id',
                      data?.sales_invoice?.customer?.id
                    )
                  )
                }
              >
                {data?.sales_invoice?.customer?.name +
                  ' ' +
                  data?.sales_invoice?.customer?.last_name}
              </ClickableLabel>
              {data?.sales_invoice?.description &&
                ` | ${data?.sales_invoice?.description}`}
            </InvoiceDescriptionLabel>
            <RowSpaceBetweenMarginTop
              marginBottom={isMidTablet ? '20rem' : marginMd}
            >
              <SmallColumnDiv>
                <SmallColumnDivLabel>
                  {data?.sales_invoice?.is_credit
                    ? t('Corrective amount')
                    : t('Total')}
                </SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.sales_invoice?.total_amount)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('Invoiced')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {data?.sales_invoice?.sent_to_customer
                    ? formatNumber(data?.sales_invoice?.total_amount)
                    : EMPTY_VALUE}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('Open amount')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.sales_invoice?.open_amount)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              <SmallColumnDiv>
                <SmallColumnDivLabel>
                  {t('Received amount')}
                </SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.sales_invoice?.total_amount_payed)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('Credit amount')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.sales_invoice?.total_credit_amount)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
              <SmallColumnDiv>
                <SmallColumnDivLabel>{t('Offset amount')}</SmallColumnDivLabel>
                <SmallColumnDivLabelValue>
                  {currencySymbol}
                  {formatNumber(data?.sales_invoice?.offset_amount)}
                </SmallColumnDivLabelValue>
              </SmallColumnDiv>
            </RowSpaceBetweenMarginTop>
            <DraggableTable
              tableData={tableData}
              tableConfig={tableConfig}
              isLoading={isLoadingSingleSalesInvoice}
              onDragComplete={(dto: IReorderLinesRequestDTO) =>
                reorderSalesInvoiceLines(dto)
              }
              isDragDisabled={!canEditSalesInvoice}
            />
            <EditExternalReferenceModal
              isOpen={isEditReferenceModalOpen}
              setIsOpen={setIsEditReferenceModalOpen}
              onCancel={() => setIsEditReferenceModalOpen(false)}
              externalId={data?.sales_invoice?.external_id}
              type={InvoiceType.SALES_INVOICE}
            />
          </>
        )}
      </Container>
    </Container>
  );
};

export default SingleSalesInvoiceGeneralPage;
