import * as Yup from 'yup';
import { baseCustomerDetailsSchema } from '../NewQuotationPage/CustomerTab/validation';

export const createCustomerSchema = () => {
  return baseCustomerDetailsSchema()
    .pick([
      'name',
      'last_name',
      'title',
      'country',
      'phone',
      'email',
      'code',
      'street',
      'city',
      'house_number',
      'company_name',
      'vat_number',
    ])
    .shape({
      coc_number: Yup.string()
        .max(20, 'COC number should be less than 20 characters')
        .optional(),
    });
};
