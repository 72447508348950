import { useState } from 'react';
import { CreateInvoiceTab } from './constants';
import {
  useCreatePurchaseInvoice,
  useGetPrepopulateInvoiceData,
} from './hooks';
import {
  getInitialSupplierDetailsData,
  getInitialInvoiceDetailsData,
} from './helpers';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import SupplierTab from './SupplierTab/SupplierTab';
import { ICreateInvoiceDetails } from './InvoiceDetailsTab/constants';
import { useTranslation } from 'react-i18next';
import InvoiceDetailsTab from './InvoiceDetailsTab/InvoiceDetailsTab';
import OverviewTab from './OverviewTab/OverviewTab';
import {
  ICreatePurchaseInvoiceDTO,
  ICreatePurchaseInvoiceLine,
} from 'types/Invoice.types';
import moment from 'moment';
import {
  Container,
  CreateNewInvoiceLabel,
  Tab,
  TabContainer,
} from './NewPurchaseInvoicePage.styled';
import { ICreateInvoiceSupplierDetails } from './SupplierTab/constants';
import { IPurchaseOrderLine } from 'types/PurchaseOrders.types';

const NewPurchaseInvoicePage = () => {
  const { t } = useTranslation();
  const prepopulateInvoiceData = useGetPrepopulateInvoiceData();
  const initialCountry = useGetInitialCountry();

  const [activeTab, setActiveTab] = useState<string>(
    CreateInvoiceTab.SUPPLIER_DETAILS
  );

  const [supplierDetails, setSupplierDetails] =
    useState<ICreateInvoiceSupplierDetails>(
      getInitialSupplierDetailsData(prepopulateInvoiceData, initialCountry.cca2)
    );

  const [invoiceDetails, setInvoiceDetails] = useState<ICreateInvoiceDetails>(
    getInitialInvoiceDetailsData(prepopulateInvoiceData)
  );

  const { mutate, data, status } = useCreatePurchaseInvoice();

  const createInvoice = () => {
    const invoiceLines: Array<ICreatePurchaseInvoiceLine> = [];

    for (const purchaseOrderLine of prepopulateInvoiceData.purchase_order_lines as IPurchaseOrderLine[]) {
      if (purchaseOrderLine.working_hours_line) {
        continue;
      }

      const line = {
        purchase_order_line_id: Number(purchaseOrderLine.id),
        billed_amount: (
          Number(purchaseOrderLine.product_purchase_price) *
          Number(purchaseOrderLine.quantity)
        ).toString(),
        quantity: purchaseOrderLine.quantity,
        product_id: purchaseOrderLine.product?.id,
        supplier_id: purchaseOrderLine.supplier.id,
      };
      invoiceLines.push(line);
    }

    const createInvoiceData: ICreatePurchaseInvoiceDTO = {
      valid_to: `${moment()
        .endOf('day')
        .add(invoiceDetails.daysValid, 'days')
        .format('YYYY-MM-DD')} 00:00:00`,
      is_credit: false,
      ...(prepopulateInvoiceData?.id && {
        purchase_order_id: Number(prepopulateInvoiceData?.id),
      }),
      purchase_invoice_lines: invoiceLines,
      external_id: invoiceDetails.externalId,
    };

    mutate(createInvoiceData);
  };

  const handleActiveTab = () => {
    if (activeTab === CreateInvoiceTab.SUPPLIER_DETAILS) {
      return (
        <TabContainer>
          <Tab active>1. {t('Supplier details')}</Tab>
          <Tab>2. {t('Invoice details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateInvoiceTab.INVOICE_DETAILS) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Supplier details')}</Tab>
          <Tab active>2. {t('Invoice details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateInvoiceTab.OVERVIEW) {
      return (
        <TabContainer>
          <Tab finished>1. {t('Supplier details')}</Tab>
          <Tab finished>3. {t('Invoice details')}</Tab>
        </TabContainer>
      );
    }
  };

  return (
    <Container>
      <CreateNewInvoiceLabel>Create New Invoice</CreateNewInvoiceLabel>
      {handleActiveTab()}
      {activeTab === CreateInvoiceTab.SUPPLIER_DETAILS && (
        <SupplierTab
          isSupplierDataPrepopulated={prepopulateInvoiceData.supplier}
          onForward={() => setActiveTab(CreateInvoiceTab.INVOICE_DETAILS)}
          supplierDetails={supplierDetails}
          setSupplierDetails={setSupplierDetails}
        />
      )}
      {activeTab === CreateInvoiceTab.INVOICE_DETAILS && (
        <InvoiceDetailsTab
          onBack={() => setActiveTab(CreateInvoiceTab.SUPPLIER_DETAILS)}
          onForward={() => setActiveTab(CreateInvoiceTab.OVERVIEW)}
          invoiceDetails={invoiceDetails}
          setInvoiceDetails={setInvoiceDetails}
        />
      )}
      {activeTab === CreateInvoiceTab.OVERVIEW && (
        <OverviewTab
          onBack={() => setActiveTab(CreateInvoiceTab.INVOICE_DETAILS)}
          onForward={() => createInvoice()}
          supplierDetails={supplierDetails}
          invoiceDetails={invoiceDetails}
          status={status}
          newlyCreatedInvoiceId={data?.purchase_invoice?.id}
        />
      )}
    </Container>
  );
};

export default NewPurchaseInvoicePage;
