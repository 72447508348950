import { Pagination } from 'components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { excludedTableDataKeys, translatableColumnFields } from './constants';
import {
  ActionCell,
  ActionHeaderCell,
  Body,
  BodyCell,
  BodyLabel,
  Card,
  CardActions,
  CardContent,
  Cards,
  CardTitle,
  CardValue,
  Container,
  Footer,
  Header,
  HeaderCell,
  HeaderLabel,
  Row,
  Table,
} from './SimpleActionsTable.styled';
import { Copy, PencilSimple, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import Button from 'components/Button/Button';

interface ISimpleActionsTable {
  page: number;
  perPage: number;
  total: number;
  loadPage: (page: number) => void;
  tableData: any[];
  tableConfig: ISimpleActionsTableConfig;
  onEdit?: (param: any) => void;
  onDelete?: (param: any) => void;
  onCopy?: (param: any) => void;
  animatedRows?: boolean;
  removedRows?: string[];
  pwId?: string;
}

export interface ISimpleActionsTableConfig {
  rowConfigs: ISimpleActionsTableRowConfig[];
}

export interface ISimpleActionsTableRowConfig {
  header: string;
  flexValue: number;
  isBlue?: boolean;
}

export const SimpleActionsTable = ({
  page,
  perPage,
  total,
  loadPage,
  tableConfig,
  tableData,
  onEdit,
  onDelete,
  onCopy,
  animatedRows,
  removedRows,
  pwId,
}: ISimpleActionsTable) => {
  const { t } = useTranslation();
  const { isSmallTablet } = useBreakpointFlag();

  return isSmallTablet ? (
    <Container>
      <Cards>
        {tableData?.map((rowData, rowIndex) => (
          <Card key={rowIndex}>
            {tableConfig?.rowConfigs.map((config, configIndex) => {
              return (
                <CardContent key={configIndex}>
                  <CardTitle>{t(config.header)}</CardTitle>
                  <CardValue>
                    {translatableColumnFields.includes(config.header)
                      ? t(rowData[config.header.toLowerCase()])
                      : rowData[config.header.toLowerCase()]}
                  </CardValue>
                </CardContent>
              );
            })}
            <CardActions>
              {onDelete && (
                <Button
                  redOutline
                  table
                  label={t('Delete')}
                  onClick={() => {
                    onDelete(rowData);
                  }}
                />
              )}
              {onEdit && (
                <Button
                  onClick={() => {
                    onEdit(rowData);
                  }}
                  table
                  label={t('Edit')}
                />
              )}
              {onCopy && (
                <Button
                  onClick={() => {
                    onCopy(rowData);
                  }}
                  table
                  label={t('Copy')}
                />
              )}
            </CardActions>
          </Card>
        ))}
      </Cards>
      {total > perPage && (
        <Pagination
          page={page}
          perPage={perPage}
          total={total}
          loadPage={loadPage}
        />
      )}
    </Container>
  ) : (
    <Table>
      <Header>
        {tableConfig?.rowConfigs.map((rowData, index) => (
          <HeaderCell key={index} $flexValue={rowData.flexValue}>
            <HeaderLabel data-testid={`${pwId}-${rowData.header}`}>
              {t(rowData.header)}
            </HeaderLabel>
          </HeaderCell>
        ))}
        {onCopy && <ActionHeaderCell></ActionHeaderCell>}
        {onEdit && <ActionHeaderCell></ActionHeaderCell>}
        {onDelete && <ActionHeaderCell></ActionHeaderCell>}
      </Header>
      <Body>
        {tableData?.map((rowData, rowIndex) => {
          return (
            <Row
              data-testid={`${pwId}-row-${rowIndex}`}
              key={rowIndex}
              className={
                animatedRows && removedRows?.includes(rowData.entityId)
                  ? 'exit-row'
                  : ''
              }
            >
              {Object.keys(rowData)
                .filter((key) => !excludedTableDataKeys.includes(key))
                .map((cellData: any, cellIndex: any) => (
                  <BodyCell
                    key={cellIndex}
                    data-testid={`${pwId}-product-name-and-description-${cellIndex}`}
                  >
                    <BodyLabel
                      $isBlueColor={tableConfig?.rowConfigs[cellIndex]?.isBlue}
                    >
                      {translatableColumnFields.includes(cellData)
                        ? t(rowData[cellData])
                        : rowData[cellData]}
                    </BodyLabel>
                  </BodyCell>
                ))}
              {onCopy && (
                <ActionCell>
                  <Icon
                    pwId={`${pwId}-copy`}
                    svg={Copy}
                    size={20}
                    color={COLORS.PRIMARY}
                    onClick={() => onCopy(rowData)}
                  />
                </ActionCell>
              )}
              {onEdit && (
                <ActionCell>
                  <Icon
                    pwId={`${pwId}-edit`}
                    svg={PencilSimple}
                    size={20}
                    color={COLORS.PRIMARY}
                    onClick={() => onEdit(rowData)}
                  />
                </ActionCell>
              )}
              {onDelete && (
                <ActionCell>
                  <Icon
                    pwId={`${pwId}-delete`}
                    svg={Trash}
                    size={20}
                    color={COLORS.RED_TRASH}
                    onClick={() => onDelete(rowData)}
                  />
                </ActionCell>
              )}
            </Row>
          );
        })}
      </Body>
      <Footer>
        {total > perPage && (
          <Pagination
            page={page}
            perPage={perPage}
            total={total}
            loadPage={loadPage}
          />
        )}
      </Footer>
    </Table>
  );
};
