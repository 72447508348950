import { marginLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1075rem;
  width: 100%;
  align-self: center;
  height: 100%;
  padding: 0 15rem;
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
  margin-top: ${marginLg};
  margin-bottom: ${marginLg};

  ${respondTo.smallTablet`
    margin: 20rem 0;
  `}
`;

export const NotificationsWrapper = styled.div`
  padding-bottom: 150rem;
  width: 100%;
  max-width: 100%;
  overflow: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`;

export const NoContentLabel = styled.label`
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  width: 100%;
`;

export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const SpinnerNoContentWrapper = styled.div`
  display: flex;
  flex: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
