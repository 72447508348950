import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginLg, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  position: relative;
`;

export const AddAttributeLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  text-align: center;
  user-select: none;
  position: fixed;
  z-index: 3;
  background-color: ${COLORS.WHITE};
  width: 400rem;

  ${respondTo.bigPhone`
    max-width: 100%;
    width: 100%;
  `}
`;

export const ButtonWrapper = styled.div`
  margin-top: ${marginMd};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 420rem;

  ${respondTo.bigPhone`
    max-width: 100%;
    width: 100%;
    gap: 20rem;
  `}
`;

export const CloseAbsoluteWrapper = styled.div`
  position: fixed;
  width: 500rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: ${marginLg};
  z-index: 5;
`;

export const AddAnotherAttributeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;

export const AddAnotherAttributeClickableWrapper = styled.div`
  cursor: pointer;
  user-select: none;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-top: ${marginMd};
`;

export const AbsoluteContainer = styled.div`
  position: absolute;
  bottom: 0;
`;

export const ScrollContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 800rem;
  margin-top: 50rem;
  margin-bottom: 100rem;
  /* WebKit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 5rem;
  }
  ::-webkit-scrollbar-track {
    background-color: #fff;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
  }
  /* Firefox */
  scrollbar-color: #888 #f1f1f1;
  scrollbar-width: thin;
`;

export const BottomDiv = styled.div``;
