import { COLORS, marginLg, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  $darkMode: boolean;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;

  ${respondTo.smallTablet`
    max-width: 100%;
    width: 100%;
  `}
`;

export const Card = styled.div`
  max-width: 750rem;
  width: 100%;
  border-radius: 10rem;
  margin-top: 60rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;
  display: flex;
  justify-content: center;

  ${respondTo.smallTablet`
    padding: 20rem;
    margin-top: 0;
    max-width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 10rem;
  margin-top: ${marginMd};

  ${respondTo.smallTablet`
    margin-top: 20rem;;
  `}
`;

export const TopContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${marginSm};
`;

export const LabelKey = styled.label`
  font-weight: 600;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
`;
export const Label = styled.label`
  font-weight: 300;
  font-size: 16rem;
  line-height: 24rem;
  height: 41rem;
  display: flex;
  align-items: center;
  margin-left: ${marginMd};

  ${respondTo.smallTablet`
    margin-left: 0;
  `}
`;

export const Heading = styled.label`
  padding-bottom: 30rem;
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;

  ${respondTo.smallTablet`
    padding-bottom: 20rem;
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  margin-bottom: ${marginSm};
  flex: 1;

  ${respondTo.smallTablet`
    gap: 20rem;
    margin-bottom: 10rem;
  `}
`;

export const Wrapper = styled.div`
  width: 700rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const RowWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  ${respondTo.smallTablet`
    flex-direction: column;
  `}
`;

export const SignatureWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5rem;
  margin: 0 auto;
  width: 500rem;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}

  ${(props: StyledProps) =>
    props.$darkMode &&
    css`
      background-color: ${COLORS.CANVAS};
    `}
`;

export const ValueWrapperRelative = styled.div`
  display: flex;
  position: relative;
  margin-right: ${marginLg};
`;

export const RowHalf = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${respondTo.smallTablet`
    flex-direction: row;
    gap: 20rem;
    align-items: center;
  `}
`;

export const NonEditModeLabelWrapper = styled.div`
  height: 41rem;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    height: auto;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

export const SignatureImage = styled.img`
  max-width: 500rem;
`;
