import {
  Container,
  CloseContainer,
  TitleContainer,
  Title,
  HorizontalLine,
  FieldTitle,
  SelectWrapper,
  FieldValue,
  BlueValue,
  Actions,
  MultiFieldWrapper,
  MultiSelectWrapper,
  HalfWrapper,
  ButtonWrapper,
  CheckboxWrapper,
  FieldTitleSelect,
  LabelContainer,
  LabelWrapper,
  SpinnerWrapper,
  AddLabelDropdownWrapper,
  RowSpaceBetween,
  StartButtonWrapper,
} from './ViewToDoModal.styled';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Close } from 'components/Close/Close';
import { PriorityOptions } from 'components/Table/Cells/SelectCell/constants';
import { useGetUsersSummaryInfinite } from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { useDebounce } from 'utils/hooks/useDebounce';
import { Select } from 'components/Select/Select';
import { Checkbox } from 'components/Checkbox/Checkbox';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import {
  ArrowRight,
  ChatCircle,
  PencilSimple,
  Trash,
} from '@phosphor-icons/react';
import { CommentsModal } from '../CommentsModal/CommentsModal';
import { Danger } from 'components/Modal/Danger/Danger';
import {
  useDeleteToDo,
  useGetSingleToDo,
  useGetToDoTypes,
  useRelatedTypeOptions,
  useUpdateToDo,
} from '../hooks';
import moment from 'moment';
import Loader from 'components/Loader/Loader';
import { TodoRelatedTypeId } from 'types/ToDo.types';
import { RelatedTypeOptions } from '../constants';
import { Option } from 'components/Select/type';
import Button from 'components/Button/Button';
import { USERS_PER_PAGE } from '../AddEditToDoModal/constants';
import { PreviewAppointmentReportModal } from 'pages/Manager/PlanningPage/ViewAppointmentModal/PreviewAppointmentReportModal/PreviewAppointmentReportModal';
import { useGetAppointmentReport } from 'pages/Manager/PlanningPage/ViewAppointmentModal/hooks';
import {
  useDeleteLabelFromEntity,
  useGetEntityLabels,
} from 'pages/Manager/LabelsPage/hooks';
import { ILabelDataDTO, LabelEntityTypeId } from 'types/EntityLabel.types';
import { formatLabelsData } from 'pages/Manager/LabelsPage/helpers';
import Spinner from 'components/Spinner/Spinner';
import { EntityLabel } from 'components/EntityLabel/EntityLabel';
import { AddLabelDropdown } from 'components/AddLabelDropdown/AddLabelDropdown';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';
import { useHandleTodoNavigateSalesInvoice } from './hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  setActiveTodoId,
  setForceUpdateTodoPage,
} from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';
import { toast } from 'react-toastify';

export interface IViewToDoModalProps {
  id: string;
  isOpen: boolean;
  relatedTypeId: string;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm?: () => void;
  onEdit?: (id: string) => void;
  forceUpdate: number;
}

const ViewToDoModal = ({
  id,
  isOpen,
  relatedTypeId,
  setIsOpen,
  onEdit,
  forceUpdate,
}: IViewToDoModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(userSearchBy);
  const [isCommentsModalOpen, setIsCommentsModalOpen] =
    useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [relatedTypeSearchBy, setRelatedTypeSearchBy] = useState<string>('');
  const [relatedType, setRelatedType] = useState<string>(relatedTypeId);
  const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);

  const activeTodoId = useSelector(
    (state: IRootReducerState) => state.commonInfo.activeTodoId
  );

  const { data, isLoading } = useGetSingleToDo(id, isOpen);

  const { data: appointmentReport } = useGetAppointmentReport(
    data?.appointment_report?.appointment?.id,
    isReportModalOpen
  );

  const { mutate: deleteToDo } = useDeleteToDo({
    onSuccess: () => {
      if (activeTodoId && activeTodoId === Number(id))
        dispatch(setActiveTodoId(null));
    },
  });
  const { mutate: updateToDo } = useUpdateToDo({
    // ForceUpdate is used to force rerender manually on every updateToDo because of selects and checkbox values
    onSuccess: () => {
      dispatch(setForceUpdateTodoPage(forceUpdate + 1));
    },
  });

  const { data: toDoTypesOptions } = useGetToDoTypes();

  const [relatedToValue, setRelatedToValue] = useState<Option | undefined>(
    undefined
  );

  const {
    relatedToOptions,
    fetchNextPageQuotations,
    fetchNextPageSalesOrders,
    fetchNextPageSalesInvoices,
    fetchNextPagePurchaseOrders,
    fetchNextPageCustomers,
    hasNextPageQuotations,
    hasNextPageSalesOrders,
    hasNextPageSalesInvoices,
    hasNextPagePurchaseOrders,
    hasNextPageCustomers,
    isLoadingRelatedOptions,
  } = useRelatedTypeOptions(relatedType, relatedTypeSearchBy);

  const { data: labels, isLoading: isLoadingLabels } = useGetEntityLabels(
    id!,
    LabelEntityTypeId.TODO
  );

  let formattedLabels: ILabelDataDTO[] = [];
  if (data) {
    formattedLabels = formatLabelsData(labels);
  }

  const { mutate: deleteLabelFromEntity } = useDeleteLabelFromEntity(
    LabelEntityTypeId.TODO,
    Number(id)
  );

  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersSummaryInfinite(USERS_PER_PAGE, debouncedSearchBy, true);

  const users = useMemo(() => {
    if (usersData?.pages?.length) {
      return usersData.pages
        .map((page) => page.users)
        .flat()
        .map((user: any) => {
          return {
            value: user?.id,
            label: user.name + ' ' + user.last_name,
          };
        });
    }
    return [];
  }, [usersData]);

  const assignedToValue = useMemo(() => {
    if (data?.todo?.assigned_to) {
      return {
        label: `${data.todo.assigned_to.name} ${data.todo.assigned_to.last_name}`,
        value: data.todo.assigned_to.id.toString(),
      };
    }
  }, [data]);

  useEffect(() => {
    if (data?.todo?.related_to_id) {
      setRelatedToValue({
        label: data.todo.related_to_number,
        value: data.todo.related_to_id.toString(),
      });
    } else {
      setRelatedToValue(undefined);
    }

    //Set related type when navigating from notifications
    if (!relatedTypeId && data?.todo?.related_type_id) {
      setRelatedType(data?.todo?.related_type_id.toString());
    }
  }, [data]);

  const {
    handleTodoNavigateSalesInvoice,
    isFetchingSalesInvoice: isNavigationDisabled,
  } = useHandleTodoNavigateSalesInvoice(navigate);

  const handleNavigateToEntity = (
    relatedType: TodoRelatedTypeId,
    relatedToIdNumber: number
  ) => {
    if (!relatedToIdNumber) {
      return;
    }
    const relatedToId = relatedToIdNumber.toString();
    switch (relatedType) {
      case TodoRelatedTypeId.QUOTATION:
        navigate(
          RoutesConfig.SingleQuotation.fullPath.replace(':id/*', relatedToId)
        );
        break;
      case TodoRelatedTypeId.SALES_ORDER:
        navigate(
          RoutesConfig.SingleSalesOrder.fullPath.replace(':id/*', relatedToId)
        );
        break;
      case TodoRelatedTypeId.SALES_INVOICE:
        handleTodoNavigateSalesInvoice(relatedToId);
        break;
      case TodoRelatedTypeId.PURCHASE_ORDER:
        navigate(
          RoutesConfig.SinglePurchaseOrder.fullPath.replace(
            ':id/*',
            relatedToId
          )
        );
        break;
      case TodoRelatedTypeId.CUSTOMER:
        navigate(
          RoutesConfig.SingleCustomer.fullPath.replace(':id/*', relatedToId)
        );
        break;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ position: 'fixed', margin: 'auto', padding: '20rem' }}
    >
      <CloseContainer>
        <Close onClick={() => setIsOpen(false)} />
      </CloseContainer>

      <TitleContainer>
        <Title>{t('To Do')}</Title>
        <Actions>
          <Icon
            svg={ChatCircle}
            color={COLORS.PRIMARY}
            size={20}
            onClick={() => setIsCommentsModalOpen(true)}
          />
          <Icon
            svg={PencilSimple}
            color={COLORS.PRIMARY}
            size={20}
            onClick={() => {
              onEdit && onEdit(id);
              setIsOpen(false);
            }}
          />
          <Icon
            svg={Trash}
            color={COLORS.RED_200}
            size={20}
            onClick={() => setOpenDeleteModal(true)}
          />
          {!data?.todo?.finished && (
            <StartButtonWrapper>
              <Button
                disabled={data?.todo?.id === activeTodoId}
                primary
                label={t('Start')}
                width="100rem"
                height="31rem"
                onClick={() => {
                  if (activeTodoId)
                    toast.error(
                      t(
                        'An active ToDo already exists. Please finish it before starting a new one.'
                      )
                    );
                  else {
                    dispatch(setActiveTodoId(Number(id)));
                    setIsOpen(false);
                  }
                }}
              />
            </StartButtonWrapper>
          )}
        </Actions>
      </TitleContainer>
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <FieldTitle>{t('toDoTitle')}</FieldTitle>
          <FieldValue>{data?.todo.title}</FieldValue>
          <HorizontalLine />
          <FieldTitle>{t('Description')}</FieldTitle>
          <FieldValue>
            {data?.todo.description
              ?.split('\n')
              .map((line: string, index: number) => (
                <span key={index}>
                  {line}
                  <br />
                </span>
              ))}
          </FieldValue>
          <HorizontalLine />
          <FieldTitle>{t('Created at')}</FieldTitle>
          <MultiFieldWrapper>
            <FieldValue>
              {moment
                .utc(data?.todo.created_at)
                .utcOffset(moment().utcOffset())
                .format('DD-MM-YYYY')}
            </FieldValue>
            <FieldValue>
              {moment
                .utc(data?.todo.created_at)
                .utcOffset(moment().utcOffset())
                .format('H:mm')}
            </FieldValue>
          </MultiFieldWrapper>
          <HorizontalLine />
          <FieldTitle>{t('Created by')}</FieldTitle>
          <BlueValue>{`${data?.todo.created_by.name} ${data?.todo.created_by.last_name}`}</BlueValue>
          <HorizontalLine />
          <FieldTitle>{t('Type')}</FieldTitle>
          <BlueValue>
            {/* FIELD_WORKER is only set when a todo is created through an report */}
            {data?.todo?.todo_type?.name === 'FIELD_WORKER'
              ? `${t('Field Worker')}`
              : toDoTypesOptions?.find(
                  (op: any) => op.key === data?.todo?.todo_type?.name
                )?.label || ''}
          </BlueValue>
          <HorizontalLine />
          <FieldTitleSelect>{t('Priority')}</FieldTitleSelect>
          <SelectWrapper>
            <Select
              key={`${data?.todo.todo_priority.name}`}
              pwId={'priority-field'}
              height={'41rem'}
              name="priority"
              options={PriorityOptions}
              onChange={(e: any) => {
                updateToDo({
                  id: id,
                  updateToDoData: { priority_id: e.value },
                });
              }}
              defaultValue={PriorityOptions.find(
                (op) => op.key === data?.todo.todo_priority.name
              )}
              placeholder={t('Priority')}
            />
          </SelectWrapper>
          <HorizontalLine />
          <FieldTitle>{t('Labels')}</FieldTitle>
          <LabelContainer>
            <LabelWrapper>
              {isLoadingLabels ? (
                <SpinnerWrapper>
                  <Spinner size={15} />
                </SpinnerWrapper>
              ) : (
                <>
                  {formattedLabels.map((labelObj: ILabelDataDTO) => {
                    return (
                      <EntityLabel
                        key={labelObj.label.id}
                        label={labelObj.label}
                        tooltipContent={
                          labelObj.label.name.length > 15
                            ? labelObj.label.name
                            : labelObj.label.description
                        }
                        onDelete={() => {
                          deleteLabelFromEntity(labelObj.label.id);
                        }}
                      />
                    );
                  })}
                </>
              )}
            </LabelWrapper>
            <AddLabelDropdownWrapper
              $withoutMargin={formattedLabels?.length === 0}
            >
              <AddLabelDropdown
                entityType={LabelEntityTypeId.TODO}
                entityId={id!}
                inModal={true}
              />
            </AddLabelDropdownWrapper>
          </LabelContainer>
          <HorizontalLine />
          <FieldTitleSelect>{t('Assigned to')}</FieldTitleSelect>
          <SelectWrapper>
            <Select
              key={`${data?.todo?.assigned_to?.id.toString()}`}
              pwId={'assigned_to-field'}
              height={'41rem'}
              name="assigned_to"
              options={users || []}
              isClearable={true}
              menuPlacement={'top'}
              placeholder={t('Assigned to')}
              defaultValue={assignedToValue ?? undefined}
              isMulti={false}
              translate={false}
              onChange={(e: any) => {
                updateToDo({
                  id: id,
                  updateToDoData: {
                    assigned_to_user_id: e === null ? null : e.value,
                  },
                });
              }}
              onInputChange={(e: string) => {
                setUserSearchBy(e);
              }}
              onMenuScrollToBottom={() =>
                hasNextPageUsers && fetchNextPageUsers()
              }
              isLoading={isLoadingUsers}
            />
          </SelectWrapper>
          <HorizontalLine />
          <MultiSelectWrapper>
            <HalfWrapper>
              <FieldTitleSelect>{t('Related type')}</FieldTitleSelect>
              <Select
                key={relatedType}
                pwId={'related_type_id-field'}
                height={'41rem'}
                name="related_type_id"
                isClearable={true}
                menuPlacement={'top'}
                options={RelatedTypeOptions}
                placeholder={t('Related type')}
                defaultValue={RelatedTypeOptions.find(
                  (op) => op.value === relatedType
                )}
                isMulti={false}
                onChange={(item: any) => {
                  setRelatedType(item === null ? null : item.value);
                  setRelatedToValue(undefined);

                  if (item === null) {
                    updateToDo({
                      id: id,
                      updateToDoData: {
                        related_type_id: null,
                        related_to_id: null,
                      },
                    });
                  }
                }}
                onInputChange={(e: string) => setRelatedTypeSearchBy(e)}
              />
            </HalfWrapper>
            <HalfWrapper>
              <RowSpaceBetween>
                <FieldTitleSelect>{t('Related to')}</FieldTitleSelect>
                {relatedToValue ? (
                  <Icon
                    disabled={isNavigationDisabled}
                    svg={ArrowRight}
                    color={COLORS.PRIMARY}
                    size={20}
                    weight="regular"
                    onClick={() =>
                      handleNavigateToEntity(
                        relatedType as TodoRelatedTypeId,
                        relatedToValue?.value
                      )
                    }
                    marginBottom="20rem"
                    marginRight="5rem"
                  />
                ) : null}
              </RowSpaceBetween>
              <Select
                key={`${relatedToValue}`}
                pwId={'related_to_id-field'}
                height={'41rem'}
                name="related_to_id"
                isClearable={true}
                options={relatedToOptions}
                menuPlacement={'top'}
                placeholder={t('Related to number')}
                defaultValue={relatedToValue}
                isDisabled={!relatedType}
                isMulti={false}
                translate={false}
                onChange={(e: any) => {
                  if (e === null) {
                    setRelatedToValue(undefined);
                    setRelatedType('');
                  }

                  updateToDo({
                    id: id,
                    updateToDoData: {
                      related_type_id: e === null ? null : relatedType,
                      related_to_id: e === null ? null : e.value,
                    },
                  });
                }}
                onInputChange={(e: string) => setRelatedTypeSearchBy(e)}
                onMenuScrollToBottom={() => {
                  if (relatedType === TodoRelatedTypeId.QUOTATION) {
                    hasNextPageQuotations && fetchNextPageQuotations();
                  }
                  if (relatedType === TodoRelatedTypeId.SALES_ORDER) {
                    hasNextPageSalesOrders && fetchNextPageSalesOrders();
                  }
                  if (relatedType === TodoRelatedTypeId.SALES_INVOICE) {
                    hasNextPageSalesInvoices && fetchNextPageSalesInvoices();
                  }
                  if (relatedType === TodoRelatedTypeId.PURCHASE_ORDER) {
                    hasNextPagePurchaseOrders && fetchNextPagePurchaseOrders();
                  }
                  if (relatedType === TodoRelatedTypeId.CUSTOMER) {
                    hasNextPageCustomers && fetchNextPageCustomers();
                  }
                }}
                isLoading={isLoadingRelatedOptions}
              />
            </HalfWrapper>
          </MultiSelectWrapper>
          <HorizontalLine />
          {data?.appointment_report?.appointment && (
            <>
              <FieldTitle>{t('Field report')}</FieldTitle>
              <ButtonWrapper>
                <Button
                  secondary
                  label={t('View')}
                  width="200rem"
                  onClick={() => setIsReportModalOpen(true)}
                />
              </ButtonWrapper>
              <HorizontalLine />
            </>
          )}
          <FieldTitle>{t('Finished')}</FieldTitle>
          <CheckboxWrapper>
            <Checkbox
              key={`${data?.todo.finished}`}
              isChecked={data?.todo.finished}
              onChange={(value: boolean) => {
                updateToDo(
                  {
                    id: id,
                    updateToDoData: { finished: value },
                  },
                  {
                    onSuccess: () => {
                      if (
                        activeTodoId &&
                        activeTodoId === data?.todo.id &&
                        value
                      )
                        dispatch(setActiveTodoId(null));
                    },
                  }
                );
              }}
              inputStyle={{ transform: 'scale(1.5)' }}
            />
          </CheckboxWrapper>
        </Container>
      )}
      <CommentsModal
        setIsOpen={setIsCommentsModalOpen}
        isOpen={isCommentsModalOpen}
        onCancel={() => setIsCommentsModalOpen(false)}
        todoId={data?.todo.id}
      />
      <Modal
        level={'SECOND'}
        isOpen={openDeleteModal}
        setIsOpen={setOpenDeleteModal}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              deleteToDo(id);
              setOpenDeleteModal(false);
              setIsOpen(false);
            },
            text: t('Delete'),
            disabled: false,
          }}
          cancel={{ onClick: () => setOpenDeleteModal(false) }}
          title={t('Delete To Do')}
          description={t('Are you sure you want to delete this To Do') + '?'}
        />
      </Modal>

      {appointmentReport && (
        <PreviewAppointmentReportModal
          isOpen={isReportModalOpen}
          setIsOpen={setIsReportModalOpen}
          previewReportData={appointmentReport}
          appointment={data?.appointment_report?.appointment}
        />
      )}
    </Modal>
  );
};

export default ViewToDoModal;
