import { useLayoutEffect, useRef, useState } from 'react';

export const useCalculateHeaderHeight = () => {
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number>(0);

  useLayoutEffect(() => {
    const headerElement = headerWrapperRef.current;
    if (headerElement) {
      setHeaderHeight(headerElement.offsetHeight);

      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.target === headerElement) {
            setHeaderHeight(entry.contentRect.height);
          }
        }
      });
      resizeObserver.observe(headerElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return {
    headerHeight,
    headerWrapperRef,
  };
};
