import { borderRadiusSm, COLORS, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Wrapper = styled.div`
  background-color: ${COLORS.WHITE};
  padding: ${paddingMd};
  border-radius: ${borderRadiusSm};
  width: 100%;
  max-width: 970rem;

  ${respondTo.smallTablet`
    padding: 20rem;
  `}
`;

export const NoResultsContainer = styled.div`
  width: 100%;
  max-width: 970rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 10rem;
  gap: 20rem;
`;

export const Message = styled.span`
  margin-top: 30rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 70rem;

  ${respondTo.smallTablet`
    align-items: center;
  `}
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding-top: 30rem;
`;
