import Button from 'components/Button/Button';
import {
  Container,
  ContentContainer,
  Line,
  Label,
  Title,
  RowSpaceBetween,
} from './SettingsPage.styled';
import { useTranslation } from 'react-i18next';
import { config } from 'config';
import { useEffect } from 'react';
import { useGenerateDealerPanelIntent } from './hooks';
import { getUrlWithQueryParams } from 'services/api/getUrlWithQueryParams';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import {
  IMobileMessage,
  MobileMessageTypes,
  postMobileMessage,
} from 'utils/mobile/postMobileMessage';
import Icon from 'components/Icon/Icon';
import { CaretRight } from '@phosphor-icons/react';
import i18n, { FALLBACK_I18N_LANG } from 'providers/i18n/i18n';
import { Base64 } from 'js-base64';
import { COLORS } from 'assets/styled';

const LOGYX_DEALER_PANEL_URL = config.logyx.logyxDealerPanelUrl;

const SettingsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pwaDeferredInstallPrompt = useSelector(
    (state: IRootReducerState) => state.pwaInfo.deferredPrompt
  );
  const isMobileApp: boolean = useSelector(
    (state: IRootReducerState) => state.commonInfo.isMobileApp
  );
  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company_id
  );
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const {
    data: dealerPanelIntentData,
    mutate: generateDealerPanelIntent,
    isLoading: isGenerateDealerPanelIntentLoading,
    isSuccess: isGenerateDealerPanelIntentSuccess,
  } = useGenerateDealerPanelIntent();

  const handleOpenLogyxDealerPanel = () => {
    if (!LOGYX_DEALER_PANEL_URL) {
      alert('Logyx dealer panel app url missing');
      return;
    }
    generateDealerPanelIntent();
  };

  useEffect(() => {
    if (isGenerateDealerPanelIntentSuccess) {
      const base64Metadata = Base64.encode(
        JSON.stringify({
          companyId: companyId,
        })
      );
      const queryParamsObj = {
        intentUUID: dealerPanelIntentData.intent_uuid,
        ...(darkMode && { isDarkMode: true }),
        lang: i18n.resolvedLanguage || FALLBACK_I18N_LANG,
        isDealerPanel: true,
        base64Metadata,
      };
      const fullUrl = getUrlWithQueryParams(
        `${LOGYX_DEALER_PANEL_URL}/`,
        queryParamsObj
      );
      if (isMobileApp) {
        const mobileMessage: IMobileMessage = {
          type: MobileMessageTypes.OPEN_DEALER_PANEL,
          payload: fullUrl,
        };
        postMobileMessage(mobileMessage);
      } else {
        window.open(fullUrl, 'LOGYX_DEALER_PANEL');
      }
    }
  }, [isGenerateDealerPanelIntentSuccess]);

  return (
    <Container>
      <Title>{t('Settings')}</Title>
      <ContentContainer>
        <RowSpaceBetween>
          <Label>Logyx</Label>
          <Button
            disabled={isGenerateDealerPanelIntentLoading}
            label={t('Configure')}
            green
            width="150rem"
            onClick={handleOpenLogyxDealerPanel}
          />
        </RowSpaceBetween>
        <Line />
        <RowSpaceBetween>
          <Label>{t('Email configuration')}</Label>
          <Icon
            svg={CaretRight}
            onClick={() => navigate(RoutesConfig.EmailTemplates.path)}
            color={COLORS.BLACK}
          />
        </RowSpaceBetween>

        <Line />
        <RowSpaceBetween>
          <Label>{t('Labels')}</Label>
          <Icon
            svg={CaretRight}
            onClick={() => navigate(RoutesConfig.Labels.fullPath)}
            color={COLORS.BLACK}
          />
        </RowSpaceBetween>

        <Line />
        <RowSpaceBetween>
          <Label>{t('Product groups')}</Label>
          <Icon
            svg={CaretRight}
            onClick={() => navigate(RoutesConfig.ProductGroups.fullPath)}
            color={COLORS.BLACK}
          />
        </RowSpaceBetween>

        <Line />
        <RowSpaceBetween>
          <Label>{t('Payment conditions')}</Label>
          <Icon
            svg={CaretRight}
            onClick={() => navigate(RoutesConfig.PaymentConditions.fullPath)}
            color={COLORS.BLACK}
          />
        </RowSpaceBetween>

        <Line />

        <RowSpaceBetween>
          <Label>{t('User Roles')}</Label>
          <Icon
            svg={CaretRight}
            onClick={() => navigate(RoutesConfig.UserRoles.path)}
            color={COLORS.BLACK}
          />
        </RowSpaceBetween>
        <Line />
        <RowSpaceBetween>
          <Label>{t('Company configuration')}</Label>
          <Icon
            svg={CaretRight}
            onClick={() => navigate(RoutesConfig.CompanyConfiguration.path)}
            color={COLORS.BLACK}
          />
        </RowSpaceBetween>
        <Line />
        <RowSpaceBetween>
          <Label>{t('External integrations')}</Label>
          <Icon
            svg={CaretRight}
            onClick={() => navigate(RoutesConfig.ExternalIntegrations.path)}
            color={COLORS.BLACK}
          />
        </RowSpaceBetween>
        <Line />
        <RowSpaceBetween>
          <Label>{t('PDF Watermark')}</Label>
          <Icon
            svg={CaretRight}
            onClick={() => navigate(RoutesConfig.Watermark.fullPath)}
            color={COLORS.BLACK}
          />
        </RowSpaceBetween>
        {pwaDeferredInstallPrompt && (
          <>
            <Line />
            <RowSpaceBetween>
              <Label>{t('Add Blueprint app icon to home screen')}</Label>
              <Button
                width="150rem"
                primary
                label={t('Add icon')}
                onClick={() => pwaDeferredInstallPrompt()}
              />
            </RowSpaceBetween>
          </>
        )}
      </ContentContainer>
    </Container>
  );
};
export default SettingsPage;
