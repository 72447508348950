import { marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  overflow: scroll;
  align-items: center;
  height: calc(100vh - 75rem - 185rem);
  width: 100%;
  padding-bottom: 50rem;
`;

export const NoContentLabel = styled.label`
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;

export const RightAlign = styled.div`
  width: 850rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${respondTo.midTablet`
    width: 100%;
    max-width: 100%;
  `}

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
    gap: 20rem;
  `}
`;

export const DatePickerWrap = styled.div`
  margin-right: ${marginMd};

  ${respondTo.bigPhone`
    margin-right: 0;
    width: 100%;
    max-width: 100%;
  `}
`;
