import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ActionsWrapper,
  CloseContainer,
  Container,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { IAppointment } from 'types/Appointment.types';
import FwTimer from '../FwTimer/FwTimer';
import { useTranslation } from 'react-i18next';
import { getAppointmentTypeLabel } from '../helpers';

interface IActionsMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  appointment: undefined | IAppointment;
  appointmentData: any;
}

const ActionsMobileModal = ({
  isOpen,
  setIsOpen,
  appointment,
  appointmentData,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>
            {t(getAppointmentTypeLabel(appointmentData?.appointment))} #
            {appointmentData?.appointment?.id}
          </Title>
        </TitleContainer>
        <ActionsWrapper>
          <FwTimer
            appointment={appointmentData?.appointment}
            appointmentLines={appointmentData?.appointment_lines}
          />
        </ActionsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
