import { DateRangePicker, Provider, defaultTheme } from '@adobe/react-spectrum';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import moment from 'moment';
import { parseDate } from '@internationalized/date';
import { useLocale } from 'utils/hooks/useLocale';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface ICustomDateRangePickerProps {
  startDate?: string;
  endDate?: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
}
export const CustomDateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: ICustomDateRangePickerProps) => {
  const { locale } = useLocale();
  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const { isPhone } = useBreakpointFlag();

  const [range, setRange] = useState<any>({
    start: parseDate(moment(startDate).format('YYYY-MM-DD')),
    end: parseDate(moment(endDate).format('YYYY-MM-DD')),
  });

  const handleChange = (newRange: any) => {
    setRange(newRange);

    if (newRange?.start) {
      const date = new Date(
        `${newRange?.start?.year}-${newRange?.start?.month}-${newRange?.start?.day}`
      );
      setStartDate(moment(date).format('YYYY-MM-DD HH:mm:ss'));
    }
    if (newRange?.end) {
      const date = new Date(
        `${newRange?.end?.year}-${newRange?.end?.month}-${newRange?.end?.day}`
      );
      setEndDate(moment(date).format('YYYY-MM-DD HH:mm:ss'));
    }
  };

  return (
    <Provider
      locale={locale === 'sr' ? 'sr-Latn' : locale}
      theme={defaultTheme}
      colorScheme={darkMode ? 'dark' : 'light'}
      width={isPhone ? '100%' : 'auto'}
    >
      <DateRangePicker
        aria-label="date-range-picker"
        value={startDate && endDate ? range : undefined}
        onChange={handleChange}
        width={isPhone ? '100%' : 'auto'}
      />
    </Provider>
  );
};
