import { paddingLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSideMenuCollapsed: boolean;
  isFieldWorker: boolean;
  isBannerShown: boolean;
}

export const LayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 75rem;
  margin-left: 265rem;

  ${(props: StyledProps) =>
    props.isBannerShown &&
    css`
      margin-top: 135rem;
    `}

  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      margin-left: 115rem;
    `}

  ${respondTo.horizontalTablet`
    padding-bottom: ${paddingLg};
    max-width: calc(100% - 115rem);
  `}

  ${respondTo.smallTablet`
    margin-left: 0;
    max-width: 100%;
    margin-top: 50rem;
    padding-bottom: 75rem; 
  `}

  ${respondTo.smallPhone`
     ${(props: StyledProps) =>
       props.isBannerShown &&
       css`
         margin-top: 160rem;
       `}
  `}
`;

export const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const MobileBreadcrumbs = styled.div`
  padding: 10rem 15rem;
`;
