import { COLORS, marginMd, marginSm, marginXs, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import Button from 'components/Button/Button';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const Container = styled.div`
  padding: 50rem;

  ${respondTo.horizontalTablet`
    padding: ${paddingMd};
  `}

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Row = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 30rem;

  ${respondTo.horizontalTablet`
    flex-direction: column;
  `}

  ${respondTo.smallTablet`
    margin-top: 15rem;
  `}
`;

export const SelectInputWrapper = styled.div`
  margin-left: 22rem;
  width: 203rem;
  height: 41rem;
`;
export const RowRightPart = styled.div`
  display: flex;
  gap: 20rem;

  ${respondTo.horizontalTablet`
    margin-top: 20rem;
    align-self: flex-end;
    flex-wrap: wrap;
  `}

  ${respondTo.smallTablet`
    display: none;
  `}
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    gap: 20rem;
  `}
`;

export const InvoiceFilterTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    gap: 20rem;
    margin-bottom: 20rem;
  `}
`;

export const InvoiceFilterTypeLabel = styled.label`
  cursor: pointer;
  margin-top: ${marginSm};
  margin-bottom: -10rem;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}

  ${respondTo.smallTablet`
      margin-top: 0;
      margin-bottom: 0;
      margin-right: 0;
    `}
`;

export const ExcelExportButton = styled(Button)`
  svg {
    transition: all 0.3s;
  }
  &:hover {
    svg {
      filter: brightness(0) invert(1);
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20rem;

  ${respondTo.smallTablet`
    display: none;
  `}
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${marginXs};
  align-items: center;
  gap: 20rem;

  ${respondTo.smallTablet`
    display: none;
  `}
`;

export const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ActionsWrapper = styled.div`
  margin: 20rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10rem;
`;

export const AddPaymentsButton = styled.div`
  width: 100%;
`;
