import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { declineOfferFromEmail } from 'services/Quotation/QuotationService';
import { toast } from 'utils/toast';
import { IDeclineOfferDTO } from './types';

export const useDeclineOfferFromEmail = () => {
  const { t } = useTranslation();
  return useMutation(
    (declineOfferData: IDeclineOfferDTO) =>
      declineOfferFromEmail(
        declineOfferData.token,
        declineOfferData.companyId,
        declineOfferData.optionalMessage
      ),
    {
      onSuccess: () => {
        toast.success(t('Offer declined successfully'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.DECLINE_OFFER_FROM_EMAIL,
        });
      },
      mutationKey: ReactMutationKeys.DECLINE_OFFER_FROM_EMAIL,
    }
  );
};
