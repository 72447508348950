import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  FilterTypeLabel,
  FilterTypesWrapper,
  Title,
  TitleContainer,
} from './EditCompanyDataModal.styled';
import {
  EditCompanyDataTab,
  IEditAddress,
  IEditBank,
  IEditCompanyDataDTO,
  IEditDetails,
} from './constants';
import { DetailsTab } from './DetailsTab/DetailsTab';
import Button from 'components/Button/Button';
import {
  getInitialAddressData,
  getInitialBankData,
  getInitialDetailsData,
} from './helpers';
import { ICompanyData } from 'types/Admin.types';
import { AddressTab } from './AddressTab/AddressTab';
import { BankTab } from './BankTab/BankTab';
import { useEditCompany } from './hooks';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IEditCommpanyDataModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  companyId: string;
  data: ICompanyData;
}

export const EditCommpanyDataModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  data,
  companyId,
}: IEditCommpanyDataModalProps) => {
  const { t } = useTranslation();

  const { mutate: editCompany } = useEditCompany(Number(companyId));

  const [selectedTab, setSelectedTab] = useState<EditCompanyDataTab>(
    EditCompanyDataTab.DETAILS
  );

  const [details, setDetails] = useState<IEditDetails>(
    getInitialDetailsData(data)
  );
  const [address, setAddress] = useState<IEditAddress>(
    getInitialAddressData(data)
  );
  const [bank, setBank] = useState<IEditBank>(getInitialBankData(data));

  const [isValidDetailsTab, setIsValidDetailsTab] = useState<boolean>(true);
  const [isValidAddressTab, setIsValidAddressTab] = useState<boolean>(true);
  const [isValidBankTab, setIsValidBankTab] = useState<boolean>(true);

  const { isSmallPhone } = useBreakpointFlag();

  const handleOnClick = () => {
    const editCompanyDataDTO: IEditCompanyDataDTO = {
      name: details.name,
      vat_number: details.vat_number,
      kvk_number: details.coc_number,
      website: details.website,
      email: details.email,
      phone_number: details.phone_number,
      address: address,
      bank_info: bank,
    };
    editCompany(editCompanyDataDTO, {
      onSuccess: () => setIsOpen(false),
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Edit company data')}</Title>
      </TitleContainer>
      <FilterTypesWrapper>
        <FilterTypeLabel
          onClick={() => setSelectedTab(EditCompanyDataTab.DETAILS)}
          isSelected={selectedTab === EditCompanyDataTab.DETAILS}
        >
          {t(EditCompanyDataTab.DETAILS)}
        </FilterTypeLabel>
        <FilterTypeLabel
          onClick={() => setSelectedTab(EditCompanyDataTab.ADDRESS)}
          isSelected={selectedTab === EditCompanyDataTab.ADDRESS}
        >
          {t(EditCompanyDataTab.ADDRESS)}
        </FilterTypeLabel>
        <FilterTypeLabel
          onClick={() => setSelectedTab(EditCompanyDataTab.BANK)}
          isSelected={selectedTab === EditCompanyDataTab.BANK}
        >
          {t(EditCompanyDataTab.BANK)}
        </FilterTypeLabel>
      </FilterTypesWrapper>
      {selectedTab === EditCompanyDataTab.DETAILS && (
        <DetailsTab
          details={details}
          setDetails={setDetails}
          setIsValid={setIsValidDetailsTab}
        />
      )}
      {selectedTab === EditCompanyDataTab.ADDRESS && (
        <AddressTab
          address={address}
          setAddress={setAddress}
          setIsValid={setIsValidAddressTab}
        />
      )}
      {selectedTab === EditCompanyDataTab.BANK && (
        <BankTab bank={bank} setBank={setBank} setIsValid={setIsValidBankTab} />
      )}
      <ApplyWrapper>
        <Button
          onClick={onCancel}
          secondary
          width={isSmallPhone ? '150rem' : '200rem'}
          label={t('Cancel')}
        />
        <Button
          disabled={!isValidDetailsTab || !isValidAddressTab || !isValidBankTab}
          onClick={() => {
            if (isValidDetailsTab && isValidAddressTab && isValidBankTab) {
              handleOnClick();
              onCancel();
            }
          }}
          primary
          width={isSmallPhone ? '150rem' : '200rem'}
          label={t('Apply')}
        />
      </ApplyWrapper>
    </Modal>
  );
};
