import { IProduct } from 'types/Product.types';

export interface IViewProductProps {
  product: IProduct;
  quantity: number | string;
  placement: string;
  status: string;
  supplier: string;
  salesPrice: string;
  totalDiscount: string;
  totalPurchaseCost: string;
  purchaseOrder?: {
    id: string;
    number: string;
  };
}

export enum ViewProductType {
  PURCHASE_ORDER_LINE_FORM_DTO = 'purchase_order_line_form_dto',
  PENDING_INVOICE = 'pending_invoice',
  SALES_ORDER = 'sales_order',
  PURCHASE_ORDER_LINE = 'purchase_order_line',
}
