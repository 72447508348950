import * as Yup from 'yup';

export const addPaymentConditionSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Payment condition label is required'),
    text: Yup.string().optional(),
    downpaymentThreshold: Yup.number().required(
      'Down payment threshold is required'
    ),
    downpaymentPercentage: Yup.number()
      .min(0, 'Down payment percentage must be between 0 and 100')
      .max(100, 'Down payment percentage must be between 0 and 100')
      .required('Down payment percentage is required'),
    altText: Yup.string().when('altChecked', {
      is: true,
      then: Yup.string().required(
        'Alternative down payment condition is required'
      ),
    }),
  });
};
