import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Input } from 'components/Input/InputFormik';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IPurchaseOrderLine } from 'types/PurchaseOrders.types';
import { validateNumericField } from 'utils/validation';
import {
  CloseContainer,
  Title,
  TitleContainer,
  FormikContainer,
  Row,
  AlignRow,
  SubmitRow,
  Margin,
} from './EditProductModal.styled';
import { useEditPurchaseOrderLinePrice } from './hooks';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IEditProductModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  purchaseOrderLine: IPurchaseOrderLine;
}

export const EditProductModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  purchaseOrderLine,
}: IEditProductModalProps) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { mutate } = useEditPurchaseOrderLinePrice(id!, purchaseOrderLine?.id);
  const { isSmallTablet } = useBreakpointFlag();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      level="FIRST"
      modalStyle={{
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{purchaseOrderLine?.product?.name}</Title>
      </TitleContainer>
      <Formik
        enableReinitialize
        initialValues={{
          product_purchase_price: purchaseOrderLine?.product_purchase_price,
          expected_total_amount: purchaseOrderLine?.expected_total_amount,
        }}
        onSubmit={() => {
          //
        }}
      >
        {({ handleBlur, setFieldValue, isValid, values, errors }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId="product-purchase-price"
                  validate={(value: string) => {
                    const errorMessage = validateNumericField(
                      value,
                      'product_purchase_price'
                    );
                    return errorMessage?.replaceAll('_', ' ');
                  }}
                  errorMessage={errors['product_purchase_price'] as string}
                  height={'41rem'}
                  name="product_purchase_price"
                  placeholder={t('Actual purchase price')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('product_purchase_price', +value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '300rem',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId="expected-total-amount"
                  validate={(value: string) => {
                    const errorMessage = validateNumericField(
                      value,
                      'expected_total_amount'
                    );
                    return errorMessage?.replaceAll('_', ' ');
                  }}
                  errorMessage={errors['expected_total_amount'] as string}
                  height={'41rem'}
                  name="expected_total_amount"
                  placeholder={t('Expected purchase price')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('expected_total_amount', +value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '300rem',
                  }}
                />
              </Row>
              <AlignRow>
                <SubmitRow>
                  <Margin>
                    <Button
                      width={'200rem'}
                      onClick={onCancel}
                      label={t('Cancel')}
                      secondary
                    />
                  </Margin>
                  <Button
                    disabled={!isValid}
                    width={'200rem'}
                    onClick={() => {
                      mutate(values);
                      onCancel();
                    }}
                    label={t('Apply')}
                    primary
                  />
                </SubmitRow>
              </AlignRow>
            </FormikContainer>
          );
        }}
      </Formik>
    </Modal>
  );
};
