import { marginMd, marginXs, paddingMd } from 'assets/styled';
import { paddingLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${paddingLg};

  ${respondTo.horizontalTablet`
    padding: ${paddingMd};
  `}

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const Row = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${marginMd};

  ${respondTo.horizontalTablet`
    flex-direction: column;
  `}

  ${respondTo.smallTablet`
    margin-top: 15rem;
  `}
`;

export const SelectInputWrapper = styled.div`
  margin-left: 22rem;
  width: 203rem;
  height: 41rem;
`;
export const RowRightPart = styled.div`
  display: flex;
  gap: 20rem;

  ${respondTo.horizontalTablet`
    margin-top: 20rem;
    align-self: flex-end;
    flex-wrap: wrap;
  `}

  ${respondTo.smallTablet`
    display: none;
  `}
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    gap: 20rem;
  `}
`;

export const FiltersWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${marginXs};
  align-items: center;
  gap: 20rem;

  ${respondTo.smallTablet`
    display: none;
  `}
`;

export const ButtonWrapper = styled.div`
  ${respondTo.smallTablet`
    display: none;
  `}
`;

export const ActionsWrapper = styled.div`
  margin: 20rem 0;
  display: flex;
  justify-content: space-between;
  gap: 20rem;
`;

export const QuotButton = styled.div`
  width: 100%;
`;

export const ExportButton = styled.div`
  width: 40%;
`;
