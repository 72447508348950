import { gapLg, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;
  gap: ${gapLg};

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    gap: 20rem;
  `}
`;

export const PageNotFoundWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const MarginRight = styled.div`
  margin-right: ${marginMd};
`;

export const ImgWrapper = styled.div`
  margin-right: ${marginSm};
  display: flex;
  align-items: center;
`;
