import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  Description,
} from './YesOrNoModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../../components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import { Level } from '../type';

interface IYesOrNoModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onYes: () => void;
  onNo: () => void;
  description?: string;
  title: string;
  level?: Level;
  disabled?: boolean;
  pwId?: string;
}

export const YesOrNoModal = ({
  isOpen,
  setIsOpen,
  onYes,
  onNo,
  description,
  title,
  level = 'FIRST',
  disabled,
  pwId,
}: IYesOrNoModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', position: 'fixed' }}
      level={level}
    >
      <TopContainer>
        <CloseContainer>
          <Close
            pwId={`${pwId}-yes-or-no-modal-close-button`}
            onClick={() => setIsOpen(false)}
          />
        </CloseContainer>
        <TitleContainer>
          <Title data-testid={`${pwId}-yes-or-no-modal-title`}>{title}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description data-testid={`${pwId}-yes-or-no-modal-description`}>
            {description}
          </Description>
        </TitleContainer>
      </TopContainer>
      <ButtonContainer>
        <Margin>
          <Button
            pwId={`${pwId}-yes-or-no-modal-no-button`}
            disabled={disabled}
            onClick={() => {
              onNo && onNo();
            }}
            secondary
            width="200rem"
            label={t('No')}
          />
        </Margin>
        <Button
          pwId={`${pwId}-yes-or-no-modal-yes-button`}
          disabled={disabled}
          primary
          width="200rem"
          label={t('Yes')}
          onClick={() => {
            onYes && onYes();
          }}
        />
      </ButtonContainer>
    </Modal>
  );
};
