import { COLORS, Label } from 'assets/styled';
import {
  Container,
  Heading,
  BackgroundColorWrapper,
  CardContentWrapper,
  ValueWrapperRelative,
  EditLabel,
  EditIcon,
  RowSpaceBetween,
  LoaderWrapper,
} from './SimpleCard.styled';
import { ISimpleCardProps } from './types';
import Tooltip from 'components/Tooltip/Tooltip';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { PencilSimple } from '@phosphor-icons/react';

const TITLE_KEY = 'Title';
const ADDRESS_KEY = 'Address';

const renderCardData = (
  cardData: object,
  t: (s: string) => string,
  pwId?: string
) => {
  function getValue(key: keyof object) {
    return cardData[key];
  }
  return (Object.keys(cardData) as Array<keyof typeof cardData>).map(
    (key: keyof object, index: number) => {
      return (
        <Fragment key={key}>
          <Label data-testid={`${pwId}-${key}-label`}>{t(key)}</Label>
          {String(getValue(key)).length > 20 ? (
            <Tooltip content={String(getValue(key))}>
              {key === ADDRESS_KEY ? (
                <p>{`${String(getValue(key)).substring(0, 38)}...`}</p>
              ) : (
                <p>{`${String(getValue(key)).substring(0, 20)}...`}</p>
              )}
            </Tooltip>
          ) : (
            <p>{key === TITLE_KEY ? t(getValue(key)) : getValue(key)}</p>
          )}
        </Fragment>
      );
    }
  );
};

const SimpleCard = ({
  pwId,
  cardTitle,
  cardData,
  onEditClick,
  width = '400rem',
  isLoading = false,
}: ISimpleCardProps) => {
  const { t } = useTranslation();
  const canEditStock = useCan(Actions.EDIT_STOCK_ITEM);
  return (
    <Container width={width}>
      <BackgroundColorWrapper>
        <RowSpaceBetween>
          <Heading data-testid={`${pwId}-${cardTitle}`}>{cardTitle}</Heading>
          {onEditClick && canEditStock ? (
            <ValueWrapperRelative>
              <EditLabel
                data-testid={`${pwId}-edit-label`}
                onClick={onEditClick}
              >
                {t('Edit')}
              </EditLabel>
              <EditIcon>
                <Icon
                  pwId={`${pwId}-edit-details`}
                  svg={PencilSimple}
                  onClick={onEditClick}
                  color={COLORS.PRIMARY}
                  size={15}
                />
              </EditIcon>
            </ValueWrapperRelative>
          ) : null}
        </RowSpaceBetween>
        {isLoading ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <CardContentWrapper>
            {renderCardData(cardData, t, pwId)}
          </CardContentWrapper>
        )}
      </BackgroundColorWrapper>
    </Container>
  );
};
export default SimpleCard;
