import { useCallback, useEffect, useState } from 'react';
import {
  TableResponsive,
  CustomTable,
  Th,
  ThLabel,
  ThWrapper,
  TD,
  TdLabel,
  TrBody,
} from './DraggableTable.styled';

import { ITableConfig, ITableRowConfig } from 'components/Table/Table';
import DndProvider from '../DndProvider/DndProvider';
import Draggable from '../Draggable/Draggable';
import TableSkeleton from 'components/TableSkeleton/TableSkeleton';
import {
  isOrderDifferentFromInitial,
  sortRowsByIndex,
  swapDragAndHoverRowIndexes,
} from './helpers';
import { excludedTableDataKeys } from './constants';
import {
  IReorderLineDTO,
  IReorderLinesRequestDTO,
} from '../DraggableLines/hooks';
import { useTranslation } from 'react-i18next';

interface IDraggableTableProps {
  tableData: any[];
  tableConfig: ITableConfig;
  isLoading?: boolean;
  onDragComplete: (dto: IReorderLinesRequestDTO) => void;
  isDragDisabled: boolean;
}

// Each tableData array element should have 'index' property
const DraggableTable = ({
  tableData,
  tableConfig,
  isLoading,
  onDragComplete,
  isDragDisabled,
}: IDraggableTableProps) => {
  const { t } = useTranslation();
  const [rows, setRows] = useState(tableData);
  const [draggedId, setDraggedId] = useState<number | null>(null);

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        const newRows = swapDragAndHoverRowIndexes(rows, dragIndex, hoverIndex);
        setRows(newRows);
      }
    },
    [rows]
  );

  useEffect(() => {
    if (!draggedId) {
      const reorderLinesDTOs: IReorderLineDTO[] = rows.map((row: any) => {
        return { lineId: Number(row.dndId), lineIndex: Number(row.index) };
      });
      if (
        tableData &&
        isOrderDifferentFromInitial(tableData, reorderLinesDTOs)
      ) {
        const dto: IReorderLinesRequestDTO = { lines: reorderLinesDTOs };
        onDragComplete && onDragComplete(dto);
      }
    }
  }, [rows, draggedId]);

  return (
    <DndProvider>
      <TableResponsive>
        <CustomTable $withoutPagination={true}>
          <thead>
            <tr
              style={{
                display: 'flex',
                flexDirection: 'row',
                flex: 1,
                width: '100%',
              }}
            >
              <th />
              {tableConfig.rowConfigs.map((config, index) => (
                <Th key={index} flexValue={config.flexValue}>
                  <ThWrapper>
                    <ThLabel>{t(config.header)}</ThLabel>
                  </ThWrapper>
                </Th>
              ))}
              <th />
            </tr>
          </thead>
          {isLoading && <TableSkeleton numRows={5} numColumns={1} />}
          <tbody>
            {sortRowsByIndex(rows).map((rowData: any, rowIndex: number) => (
              <Draggable
                id={rowData.dndId}
                key={`row-${rowData.dndId}-${rowData.index}`}
                index={rowData.index}
                onDragHover={moveRow}
                draggedId={draggedId}
                setDraggedId={setDraggedId}
                isDragDisabled={isDragDisabled}
              >
                <TrBody hoverEffectEnabled={!draggedId}>
                  <TD />
                  {Object.keys(rowData)
                    .filter((key) => !excludedTableDataKeys.includes(key))
                    .map((rowDataKey: any, tdIndex: number) => {
                      const rowConfig: ITableRowConfig =
                        tableConfig.rowConfigs?.[tdIndex];

                      return (
                        <TD
                          flexValue={rowConfig && rowConfig.flexValue}
                          key={`${rowIndex}-${tdIndex}`}
                        >
                          <TdLabel isBlueColor={rowConfig && rowConfig.isBlue}>
                            {rowData[rowDataKey] ===
                            ('Down payment' || 'Working hours')
                              ? t(rowData[rowDataKey])
                              : rowData[rowDataKey]}
                          </TdLabel>
                        </TD>
                      );
                    })}
                  <TD />
                </TrBody>
              </Draggable>
            ))}
          </tbody>
        </CustomTable>
      </TableResponsive>
    </DndProvider>
  );
};

export default DraggableTable;
