import {
  EditIcon,
  SmallCardContainer,
  SmallCardLabel,
  SmallCardValue,
  ValueWrapperRelative,
} from './SmallCard.styled';
import Spinner from 'components/Spinner/Spinner';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { PencilSimple } from '@phosphor-icons/react';

interface ISmallCard {
  label: string;
  value: string;
  onEditClick?: () => void;
  isLoading?: boolean;
  editable?: boolean;
  pwId?: string;
}

const SmallCard = ({
  label,
  value,
  onEditClick,
  isLoading,
  editable,
  pwId,
}: ISmallCard) => {
  return (
    <SmallCardContainer>
      <SmallCardLabel data-testid={`${pwId}-${label}`}>{label}</SmallCardLabel>
      <ValueWrapperRelative>
        <SmallCardValue data-testid={`${pwId}-${label}-value`}>
          {isLoading ? <Spinner /> : value}
        </SmallCardValue>
        {onEditClick && editable ? (
          <EditIcon>
            <Icon
              pwId={`${pwId}-${label}-edit`}
              svg={PencilSimple}
              onClick={onEditClick}
              color={COLORS.PRIMARY}
              size={15}
            />
          </EditIcon>
        ) : null}
      </ValueWrapperRelative>
    </SmallCardContainer>
  );
};

export default SmallCard;
