import { COLORS, marginMd, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const CloseContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
`;

export const ProductNameLabel = styled.label`
  font-size: 24rem;
  font-weight: 400;
  line-height: 32rem;
  text-align: center;
  margin-bottom: ${marginMd};
  color: ${COLORS.BLACK};
`;

export const FilterTypesWrapper = styled.div`
  display: flex;
  height: 30rem;

  ${respondTo.bigPhone`
    margin-bottom: 20rem;
    gap: 20rem;
  `}
`;

export const FilterTypeLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-right: ${marginMd};
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}

  ${respondTo.bigPhone`
    margin-right: 0;
    text-align: center;
  `}
`;

export const CloseWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;
`;

export const HorizontalLine = styled.div`
  width: 500rem;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
  margin-bottom: ${marginXs};
`;

export const LinkText = styled.a`
  font-size: 18rem;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.PRIMARY};
  text-decoration: underline;
  cursor: pointer;
`;

export const LinkTextWrapper = styled.div`
  display: flex;
  margin-top: ${marginXs};
`;
