import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isFormikPlaceholder: boolean;
  isSelectPlaceholder: boolean;
  isTranslatedToTop: boolean;
  isTextAreaPlaceholder: boolean;
  darkMode: boolean;
}

export const PlaceholderSpan = styled.span`
  pointer-events: none;
  position: absolute;
  left: 13rem;
  max-width: 100%;
  width: 100%;

  ${(props: StyledProps) =>
    props.isFormikPlaceholder
      ? props.isSelectPlaceholder
        ? css`
            top: 50%;
          `
        : props.isTextAreaPlaceholder
        ? css`
            top: 20rem;
          `
        : css`
            top: 35%;
          `
      : props.isTextAreaPlaceholder
      ? css`
          top: 20rem;
        `
      : css`
          top: 50%;
        `}

  transform: translateY(-50%);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 3rem;
  padding-right: 15rem;
  color: ${COLORS.GREY_1000};
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease,
    background-color 0.3s ease;

  ${(props: StyledProps) =>
    props.isTranslatedToTop &&
    css`
      top: -7rem;
      font-size: 12rem;
    `}

  ${(props: StyledProps) =>
    props.darkMode &&
    css`
      color: ${COLORS.GREY_500};
    `}

    ${respondTo.smallTablet`
      max-width: calc(100% - 13rem);
    `}
`;
