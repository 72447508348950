import { Pagination } from 'components/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import { excludedTableDataKeys, translatableColumnFields } from './constants';
import {
  ActionCell,
  ActionHeaderCell,
  ActionsWrapper,
  Body,
  BodyCell,
  BodyLabel,
  ButtonWrapper,
  Card,
  CardLeftSide,
  CardRightSide,
  CardWrapper,
  Footer,
  Header,
  HeaderCell,
  HeaderCellMobile,
  HeaderLabel,
  PaginationWrapper,
  Row,
  Table,
} from './PaymentConditionsTable.styled';
import { PencilSimple, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import { ISimpleActionsTableConfig } from 'components/SimpleActionTable/SimpleActionsTable';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import MobileActionDropdown from 'components/MobileActionDropdown/MobileActionDropdown';

interface IPaymentConditionsTable {
  page: number;
  perPage: number;
  total: number;
  loadPage: (page: number) => void;
  tableData: any[];
  tableConfig: ISimpleActionsTableConfig;
  onEdit?: (param: any) => void;
  onDelete?: (param: any) => void;
  onMakeDefault?: (param: any) => void;
  animatedRows?: boolean;
  removedRows?: string[];
}

export const PaymentConditionsTable = ({
  page,
  perPage,
  total,
  loadPage,
  tableConfig,
  tableData,
  onEdit,
  onDelete,
  onMakeDefault,
  animatedRows,
  removedRows,
}: IPaymentConditionsTable) => {
  const { t } = useTranslation();
  const { isSmallTablet } = useBreakpointFlag();

  const renderMobileTable = () => {
    return (
      <CardWrapper>
        {tableData.map((rowData, rowIndex) => (
          <Card key={rowIndex}>
            <ActionsWrapper>
              <MobileActionDropdown
                onEditClick={() => onEdit && onEdit(rowData)}
                onDeleteClick={() => onDelete && onDelete(rowData)}
                canUseAction={true}
              />
            </ActionsWrapper>
            <CardLeftSide>
              {tableConfig?.rowConfigs.map((rowData, index) => (
                <HeaderCellMobile key={index} $flexValue={rowData.flexValue}>
                  <HeaderLabel>{t(rowData.header)}</HeaderLabel>
                </HeaderCellMobile>
              ))}
            </CardLeftSide>
            <CardRightSide>
              {Object.keys(rowData)
                .filter((key) => !excludedTableDataKeys.includes(key))
                .map((cellData: any, cellIndex: any) => (
                  <BodyCell
                    key={cellIndex}
                    $flexValue={tableConfig?.rowConfigs[cellIndex]?.flexValue}
                  >
                    <BodyLabel
                      $isBlueColor={tableConfig?.rowConfigs[cellIndex]?.isBlue}
                    >
                      {translatableColumnFields.includes(cellData)
                        ? t(rowData[cellData])
                        : rowData[cellData]}
                    </BodyLabel>
                  </BodyCell>
                ))}
            </CardRightSide>
            {onMakeDefault && (
              <ButtonWrapper>
                <Button
                  onClick={() => onMakeDefault(rowData)}
                  tableAlt={!rowData['excludedIsDefault']}
                  table={rowData['excludedIsDefault']}
                  width="100%"
                  label={
                    rowData['excludedIsDefault']
                      ? t('Default')
                      : t('Set default')
                  }
                />
              </ButtonWrapper>
            )}
          </Card>
        ))}
        {total > perPage && (
          <PaginationWrapper>
            <Pagination
              page={page}
              perPage={perPage}
              total={total}
              loadPage={loadPage}
            />
          </PaginationWrapper>
        )}
      </CardWrapper>
    );
  };

  const renderDesktopTable = () => {
    return (
      <Table>
        <Header>
          {tableConfig?.rowConfigs.map((rowData, index) => (
            <HeaderCell key={index} $flexValue={rowData.flexValue}>
              <HeaderLabel>{t(rowData.header)}</HeaderLabel>
            </HeaderCell>
          ))}
          {onEdit && <ActionHeaderCell></ActionHeaderCell>}
          {onDelete && <ActionHeaderCell></ActionHeaderCell>}
          {onMakeDefault && (
            <ActionHeaderCell width={'170rem'}></ActionHeaderCell>
          )}
        </Header>
        <Body>
          {tableData?.map((rowData, rowIndex) => {
            return (
              <Row
                key={rowIndex}
                className={
                  animatedRows && removedRows?.includes(rowData.entityId)
                    ? 'exit-row'
                    : ''
                }
              >
                {Object.keys(rowData)
                  .filter((key) => !excludedTableDataKeys.includes(key))
                  .map((cellData: any, cellIndex: any) => (
                    <BodyCell
                      key={cellIndex}
                      $flexValue={tableConfig?.rowConfigs[cellIndex]?.flexValue}
                    >
                      <BodyLabel
                        $isBlueColor={
                          tableConfig?.rowConfigs[cellIndex]?.isBlue
                        }
                      >
                        {translatableColumnFields.includes(cellData)
                          ? t(rowData[cellData])
                          : rowData[cellData]}
                      </BodyLabel>
                    </BodyCell>
                  ))}

                {onEdit && (
                  <ActionCell>
                    <Icon
                      svg={PencilSimple}
                      size={20}
                      color={COLORS.PRIMARY}
                      onClick={() => onEdit(rowData)}
                    />
                  </ActionCell>
                )}
                {onDelete && (
                  <ActionCell>
                    <Icon
                      svg={Trash}
                      size={20}
                      color={COLORS.RED_TRASH}
                      onClick={() => onDelete(rowData)}
                    />
                  </ActionCell>
                )}
                {onMakeDefault && (
                  <ActionCell width="170rem">
                    <Button
                      onClick={() => onMakeDefault(rowData)}
                      tableAlt={!rowData['excludedIsDefault']}
                      table={rowData['excludedIsDefault']}
                      width="150rem"
                      label={
                        rowData['excludedIsDefault']
                          ? t('Default')
                          : t('Set default')
                      }
                    />
                  </ActionCell>
                )}
              </Row>
            );
          })}
        </Body>
        <Footer>
          {total > perPage && (
            <Pagination
              page={page}
              perPage={perPage}
              total={total}
              loadPage={loadPage}
            />
          )}
        </Footer>
      </Table>
    );
  };

  return <>{isSmallTablet ? renderMobileTable() : renderDesktopTable()}</>;
};
