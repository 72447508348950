import SimpleWideCard from 'components/Cards/SimpleWideCard/SimpleWideCard';
import { EditingCustomerOrSupplierEnum } from 'components/Modal/EditCustomerOrSupplierModal/constants';
import { EditCustomerOrSupplierModal } from 'components/Modal/EditCustomerOrSupplierModal/EditCustomerOrSupplierModal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { formatNumber } from 'utils/numberFormatter';
import { useGetSingleCustomer } from '../hooks';
import { Container, CardContainer } from './SingleCustomerGeneralPage.styled';

const PWID = 'single-customer-general-page';

export const SingleCustomerGeneralPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currencySymbol = useGetCurrencySymbol();

  const { data: customerData, isLoading } = useGetSingleCustomer(id!);

  const [isEditCustomerModalOpen, setIsEditCustomerModalOpen] =
    useState<boolean>(false);

  const customerDetailsLeftCardData = {
    Name: `${customerData?.customer?.name} ${customerData?.customer?.last_name}`,
    Address: customerData?.customer.address?.full_address,
    'Zip code': customerData?.customer?.address?.zip_code,
    'E-mail': customerData?.customer.email,
    'Phone number': customerData?.customer.phone,
    'Country code': customerData?.customer.address?.country?.cca2,
    'Company name': customerData?.customer.company_name,
    'COC number':
      customerData?.customer?.coc_number && customerData?.customer?.coc_number,
    ...(customerData?.customer?.alternative_invoice_email && {
      'Alternative invoice E-mail':
        customerData?.customer?.alternative_invoice_email,
    }),
  };
  const customerDetailsRightCardData = {
    'Amount sold': `${currencySymbol}${formatNumber(
      customerData?.amount_sold ? customerData?.amount_sold : 0
    )}`,
    'Total orders': customerData?.total_orders,
    'Due payment': `${currencySymbol}${formatNumber(
      customerData?.due_payment
    )}`,
    'Open orders': customerData?.open_orders,
  };

  return (
    <Container>
      <CardContainer>
        <SimpleWideCard
          pwId={PWID}
          cardTitle={t('Customer details')}
          leftSubtitle={t('GENERAL')}
          rightSubtitle={t('SALES')}
          leftData={customerDetailsLeftCardData}
          rightData={customerDetailsRightCardData}
          onEditClick={() => setIsEditCustomerModalOpen(true)}
          isLoading={isLoading}
        />
      </CardContainer>
      <EditCustomerOrSupplierModal
        pwId={PWID}
        type={EditingCustomerOrSupplierEnum.CUSTOMER}
        isOpen={isEditCustomerModalOpen}
        setIsOpen={setIsEditCustomerModalOpen}
        onCancel={() => setIsEditCustomerModalOpen(false)}
      />
    </Container>
  );
};
