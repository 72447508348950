import {
  TopContainer,
  Container,
  LogyxModelIdDigitalOrderWrapper,
  CloseContainer,
  TitleContainer,
  Title,
  SelectWrapper,
} from './AddEditProduct.styled';
import { Close } from 'components/Close/Close';
import { Field, Formik } from 'formik';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { addProductSchema } from './validation';
import { useTranslation } from 'react-i18next';
import {
  useAddProduct,
  useEditProduct,
  useIsLogyxModelIdFieldVisible,
  useManageProductGroupsSelect,
} from './hooks';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'utils/toast';
import { IAddEditProduct } from './types';
import { IProductAttribute } from 'types/Product.types';
import { getInitialAttributes } from './helpers';
import FileUploader from 'components/FileUploader/FileUploader';
import { AddDefaultFieldsForm } from './AddDefaultFieldsForm/AddDefaultFieldsForm';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { IProductGroup } from 'pages/Manager/ProductGroupsPage/types';
import { Select } from 'components/Select/Select';
import { RoutesConfig } from 'navigation/routes';
import { Option } from 'components/Select/type';
import CustomMenuList from 'components/Select/SelectComponents/CustomMenuList/CustomMenuList';
import { Pen } from '@phosphor-icons/react';

const PWID = 'add-edit-product';

export interface IAddEditProductProps {
  onCancel: () => void;
  product?: IAddEditProduct;
  copyProduct?: boolean;
  pwId?: string;
}

const AddEditProduct = ({
  onCancel,
  product,
  copyProduct,
  pwId,
}: IAddEditProductProps) => {
  const { t } = useTranslation();
  const [attributes, setAttributes] = useState<IProductAttribute[]>(
    getInitialAttributes(product)
  );

  const companySettings = useSelector(
    (state: IRootReducerState) => state.companyInfo.settings
  );

  const [deletedAttachments, setDeletedAttachments] = useState([]);
  const scrollPositionRef = useRef(0);

  const { id } = useParams();
  const navigate = useNavigate();
  const {
    isSuccess: isSuccessEditProduct,
    mutate: editProduct,
    isLoading: isLoadingEditProduct,
  } = useEditProduct();
  const {
    isSuccess: isSuccessAddProduct,
    mutate: addProduct,
    isLoading: isLoadingAddProduct,
  } = useAddProduct();

  const isLogyxModelIdFieldVisible = useIsLogyxModelIdFieldVisible();

  const {
    productGroups,
    isLoadingProductGroups,
    setSearchBy,
    hasNextPage,
    fetchNextPage,
  } = useManageProductGroupsSelect();

  useEffect(() => {
    if (isSuccessAddProduct) {
      toast.success('Product group added successfully');
      onCancel();
    }
  }, [isSuccessAddProduct]);

  useEffect(() => {
    if (isSuccessEditProduct) {
      toast.success('Product group edited successfully');
      onCancel();
    }
  }, [isSuccessEditProduct]);

  const handleAddEditProduct = async (
    values: {
      name: string;
      description: string;
      logyx_model_id: string;
      files: any;
      digital_order_enabled: boolean;
      product_group: IProductGroup;
    },
    isValid: any
  ) => {
    if (!isValid) {
      return;
    }

    if (product && !copyProduct) {
      editProduct({
        ...values,
        attributes,
        product_id: product.id,
        deleted_attachment_ids: deletedAttachments,
        digital_order_enabled: values.digital_order_enabled,
        product_group_id: values.product_group
          ? values.product_group.id.toString()
          : '',
      });
    } else {
      addProduct({
        ...values,
        attributes,
        supplier_id: id || '',
        digital_order_enabled: values.digital_order_enabled,
        product_group_id: values.product_group
          ? values.product_group.id.toString()
          : '',
      });
    }
  };

  const getTitle = () => {
    if (product && !copyProduct) return t('Edit Product');
    else return t('Add Product');
  };

  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          description: product ? product.description : '',
          name: product ? product.name : '',
          logyx_model_id: product?.logyx_model_id ? product.logyx_model_id : '',
          files: product?.attachments ? product.attachments : [],
          digital_order_enabled: product?.digital_order_enabled,
          product_group: product?.product_group,
        }}
        validationSchema={addProductSchema}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        onSubmit={() => {
          // On submit
        }}
      >
        {({
          handleBlur,
          setFieldValue,
          setTouched,
          isValid,
          values,
          errors,
          touched,
        }) => {
          return (
            <div>
              <TopContainer>
                <CloseContainer>
                  <Close pwId={`${pwId}-${PWID}`} onClick={onCancel} />
                </CloseContainer>
                <TitleContainer>
                  <Title data-testid={`${pwId}-${PWID}-title`}>
                    {getTitle()}
                  </Title>
                </TitleContainer>
                <FormikInput
                  pwId={`${pwId}-${PWID}-name-field`}
                  errorMessage={touched['name'] ? errors['name'] : ''}
                  height={'41rem'}
                  name="name"
                  placeholder={t('Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    marginTop: '30rem',
                  }}
                />
                <SelectWrapper>
                  <Select
                    pwId={`${pwId}-${PWID}-select-group`}
                    isLoading={isLoadingProductGroups}
                    name="product_group"
                    placeholder={t('Group')}
                    isMulti={false}
                    isClearable
                    isSearchable
                    onChange={(e: Option | null) => {
                      setFieldValue('product_group', e ? e.value : undefined);
                    }}
                    options={productGroups}
                    onInputChange={(searchBy: string) => setSearchBy(searchBy)}
                    translate={false}
                    defaultValue={productGroups.find(
                      (option) => option.value.id === values.product_group?.id
                    )}
                    components={{
                      MenuList: (props) => (
                        <CustomMenuList
                          {...props}
                          scrollPositionRef={scrollPositionRef}
                          label={t('Manage groups')}
                          onClick={() => {
                            navigate(RoutesConfig.ProductGroups.fullPath);
                          }}
                          onMenuScrollToBottom={() =>
                            hasNextPage && fetchNextPage()
                          }
                          icon={Pen}
                        />
                      ),
                    }}
                  />
                </SelectWrapper>
                <div style={{ height: '16rem' }}></div>

                <FormikInput
                  pwId={`${pwId}-${PWID}-description-field`}
                  isTextArea
                  errorMessage={
                    touched['description'] ? errors['description'] : ''
                  }
                  name="description"
                  placeholder={t('Description')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('description', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    marginTop: '30rem',
                  }}
                />
                <LogyxModelIdDigitalOrderWrapper>
                  {isLogyxModelIdFieldVisible && (
                    <FormikInput
                      pwId={`${pwId}-${PWID}-logyx-model-id`}
                      type="number"
                      errorMessage={
                        touched['logyx_model_id']
                          ? errors['logyx_model_id']
                          : ''
                      }
                      name="logyx_model_id"
                      height={'41rem'}
                      placeholder={t('Logyx model ID')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('logyx_model_id', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        flex: 1,
                      }}
                    />
                  )}
                  {companySettings.digital_order_enabled && (
                    <Checkbox
                      pwId={`${pwId}-${PWID}-digital-order-checkbox`}
                      isChecked={!!values.digital_order_enabled}
                      onChange={() =>
                        setFieldValue(
                          'digital_order_enabled',
                          !values.digital_order_enabled
                        )
                      }
                      inputStyle={{
                        transform: 'scale(1.5)',
                        marginTop: '10rem',
                      }}
                      style={{ marginTop: '10rem' }}
                      label={t('Digital order enabled')}
                    />
                  )}
                </LogyxModelIdDigitalOrderWrapper>
                <FileUploader
                  name="files"
                  values={values}
                  setFieldValue={setFieldValue}
                  setDeletedAttachments={setDeletedAttachments}
                />
              </TopContainer>

              <AddDefaultFieldsForm
                pwId={`${pwId}-${PWID}`}
                attributes={attributes}
                setAttributes={setAttributes}
                values={values}
                isLogyxModelIdFieldVisible={isLogyxModelIdFieldVisible}
                onCancel={onCancel}
                isLoading={isLoadingAddProduct || isLoadingEditProduct}
                setTouched={setTouched}
                isValid={isValid}
                handleSubmit={handleAddEditProduct}
                product={product}
                copyProduct={copyProduct}
              />
            </div>
          );
        }}
      </Formik>
    </Container>
  );
};

export default AddEditProduct;
