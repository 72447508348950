export const getInitialCustomerData = (customerData: any) => {
  const customerInitialData = {
    name: customerData?.customer?.name,
    last_name: customerData?.customer.last_name,
    company_name: customerData?.customer.company_name,
    vat_number: customerData?.customer.vat_number,
    address: {
      street: customerData?.customer?.address?.street,
      house_number: customerData?.customer?.address?.house_number,
      city: customerData?.customer?.address?.city,
      zip_code: customerData?.customer?.address?.zip_code,
      country: customerData?.customer?.address?.country?.cca2,
    },
    email: customerData?.customer?.email,
    phone: customerData?.customer?.phone,
    coc_number: customerData?.customer?.coc_number,
    title: customerData?.customer?.title,
    alternative_invoice_email:
      customerData?.customer?.alternative_invoice_email || '',
  };
  return customerInitialData;
};

export const getInitialSupplierData = (supplierData: any) => {
  const supplierInitialData = {
    company_name: supplierData?.supplier?.company_name,
    contact_person: supplierData?.supplier?.contact_person,
    address: {
      street: supplierData?.supplier?.address?.street,
      house_number: supplierData?.supplier?.address?.house_number,
      city: supplierData?.supplier?.address?.city,
      zip_code: supplierData?.supplier?.address?.zip_code,
      country: supplierData?.supplier?.address?.country?.cca2,
    },
    email: supplierData?.supplier?.email,
    phone: supplierData?.supplier?.phone,
    vat_number: supplierData?.supplier?.vat_number,
    number: supplierData?.supplier?.number,
    coc_number: supplierData?.supplier?.coc_number,
  };
  return supplierInitialData;
};
