import { H3 } from 'assets/styled';
import {
  Card,
  ButtonContainer,
  TopContainer,
  Margin,
  Container,
  SelectInputWrapper,
  RowMarginTop,
} from './SingleSalesOrderNewComment.styled';
import Input from 'components/Input/Input';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { useGetSingleSalesOrder, useNewSalesOrderComment } from '../hooks';
import { SingleSalesOrderRoutes } from 'navigation/SingleSalesOrder/SingleSalesOrder.routes';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import i18n from 'providers/i18n/i18n';
import { useGetCommentTypes } from 'utils/hooks/useGetCommentTypes';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const SingleSalesOrderNewComment = () => {
  const navigate = useNavigate();
  const [headline, setHeadline] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const options = useGetCommentTypes();
  const [selectedOption, setSelectedOption] = useState<Option>(options[0]);
  const { id } = useParams();
  const { t } = useTranslation();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const { data } = useGetSingleSalesOrder(typeof id === 'string' ? id : '');

  const {
    mutate: createComment,
    isSuccess,
    isLoading,
  } = useNewSalesOrderComment(
    headline,
    description,
    selectedOption.value === options[0].value,
    data?.id || ''
  );

  useEffect(() => {
    if (isSuccess) {
      setDescription('');
      setHeadline('');
      navigate(
        `/sales/${id}/${SingleSalesOrderRoutes.SingleSalesOrderComments.path}`
      );
    }
  }, [isSuccess]);

  const { isSmallTablet, isSmallPhone } = useBreakpointFlag();

  const [headlineError, setHeadlineError] = useState<string>('');
  const [descriptionError, setDescriptionError] = useState<string>('');

  const validateForm = () => {
    let isValid = true;
    if (headline.length > 250) {
      setHeadlineError(t(`Title can't be larger than 250 characters`));
      isValid = false;
    } else {
      setHeadlineError('');
    }
    if (description.length > 1000) {
      setDescriptionError(
        t(`Description can't be larger than 1000 characters`)
      );
      isValid = false;
    } else {
      setDescriptionError('');
    }
    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      createComment();
    }
  };

  useEffect(() => {
    if (headlineError) {
      setHeadlineError(t(`Title can't be larger than 250 characters`));
    }
    if (descriptionError) {
      setDescriptionError(
        t(`Description can't be larger than 1000 characters`)
      );
    }
  }, [i18n.resolvedLanguage]);

  return (
    <Container>
      <Card>
        <TopContainer>
          <H3>{t('Add new comment')}</H3>
          <RowMarginTop>
            <div>
              <Input
                pwId="title-field"
                wrapperStyles={{
                  width: isSmallTablet ? '100%' : '550rem',
                  marginBottom: isSmallTablet ? '20rem' : '30rem',
                }}
                value={headline}
                placeholder={t('Subject')}
                onChange={(e) => setHeadline(e.target.value)}
                error={!!headlineError}
                errorMessage={headlineError}
              />
            </div>
            <div>
              <SelectInputWrapper>
                <Select
                  pwId="type-select"
                  name="status"
                  placeholder={t('Type')}
                  isMulti={false}
                  isDisabled={false}
                  isSearchable={false}
                  onChange={(e: Option) => setSelectedOption(e)}
                  options={options}
                  defaultValue={options[0]}
                />
              </SelectInputWrapper>
            </div>
          </RowMarginTop>
          <Input
            pwId="description-field"
            isTextArea
            value={description}
            placeholder={t('Detailed account of the reason for the comment')}
            onChange={(e) => setDescription(e.target.value)}
            error={!!descriptionError}
            errorMessage={descriptionError}
          />
        </TopContainer>
        <ButtonContainer>
          <Margin>
            <Button
              secondary
              disabled={isLoading}
              width={isSmallPhone ? '150rem' : '200rem'}
              label={t('Cancel')}
              onClick={() => navigate(-1)}
            />
          </Margin>
          <Button
            primary
            disabled={isLoading || !headline || !description || !selectedOption}
            width={isSmallPhone ? '150rem' : '200rem'}
            label={t('Add comment')}
            onClick={() => setIsConfirmModalOpen(true)}
          />
        </ButtonContainer>
      </Card>
      <ConfirmModal
        title={t('New Comment')}
        description={`${t('Are you sure you want to add a New Comment')}?`}
        isOpen={isConfirmModalOpen}
        setIsOpen={setIsConfirmModalOpen}
        onConfirm={() => handleSubmit()}
        onCancel={() => setIsConfirmModalOpen(false)}
      />
    </Container>
  );
};

export default SingleSalesOrderNewComment;
