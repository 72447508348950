import { H3 } from 'assets/styled';
import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import CustomSelect from 'components/Select/FormikSelect';
import { Option } from 'components/Select/type';
import { Field, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICountry } from 'store/Common/types';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import { useCreateCustomer } from './hooks';
import { useTranslation } from 'react-i18next';
import {
  AddCustomerWrapper,
  ButtonCreate,
  ButtonsWrapper,
  CheckboxWrapper,
  Container,
  CustomerDetailsContainer,
  CustomerDetailsLabel,
  FormikContainer,
  Row,
  SelectWrapper,
  StyledCustomSelect,
  StyledSelect,
} from './NewCustomerPage.styled';
import {
  ICreateCustomerDTO,
  ICreateCustomerQueryParamsForCustomer,
} from './types';
import { createCustomerSchema } from './validation';
import debounce from 'lodash.debounce';
import { useGetCustomerByEmail } from 'pages/Manager/NewQuotationPage/CustomerTab/hooks';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { RoutesConfig } from 'navigation/routes';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import {
  IGetCityAndAddressDTO,
  useGetCityAndAddress,
} from 'utils/hooks/useGetCityAndAddress';
import { validateAlternativeEmailField } from 'utils/validation';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { isValidGetCityAndAddressData } from '../NewQuotationPage/CustomerTab/helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Info } from '@phosphor-icons/react';

const NewCustomerPage = () => {
  const navigate = useNavigate();
  const [isNewCustomerSameEmailModalOpen, setIsNewCustomerSameEmailModalOpen] =
    useState(false);
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const handleCancel = () => {
    navigate(RoutesConfig.Customers.fullPath);
  };
  const [isShowAltInvoiceEmail, setIsShowAltInvoiceEmail] =
    useState<boolean>(false);

  const { data, mutate: createCustomer, isSuccess } = useCreateCustomer();
  const { t } = useTranslation();

  const titleOptions = useGetTitleOptions();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);

  const { isPhone, isSmallPhone } = useBreakpointFlag();

  useEffect(() => {
    if (isSuccess) {
      navigate(
        SingleCustomerRoutes.SingleCustomerGeneral.fullPath.replace(
          ':id',
          data.customer.id
        )
      );
    }
  }, [isSuccess]);

  const {
    mutate: getCustomerByEmail,
    data: existingCustomerWithSameEmail,
    isLoading: existingCustomerWithSameEmailIsLoading,
    isSuccess: existingCustomerWithSameEmailIsSuccess,
  } = useGetCustomerByEmail();

  useEffect(() => {
    if (existingCustomerWithSameEmailIsSuccess) {
      if (existingCustomerWithSameEmail?.customer) {
        setIsNewCustomerSameEmailModalOpen(true);
      } else {
        const customerQueryParams: ICreateCustomerQueryParamsForCustomer = {
          force_create_customer: false,
        };
        handleCreateCustomer(customerQueryParams);
      }
    }
  }, [existingCustomerWithSameEmailIsSuccess]);

  const handleCreateCustomer = async (
    customerQueryParams: ICreateCustomerQueryParamsForCustomer
  ) => {
    const { values, isValid } = formikRef.current;

    if (!isValid) {
      return;
    }
    const createCustomerDTO: ICreateCustomerDTO = {
      data: {
        name: values.name,
        last_name: values.last_name,
        company_name: values.company_name || null,
        vat_number: values.vat_number || null,
        title: values.title,
        email: values.email,
        address: {
          country: values.country,
          street: values.street,
          zip_code: values.code,
          city: values.city,
          house_number: values.house_number,
        },
        phone: values.phone,
        coc_number: values.coc_number,
        ...(isShowAltInvoiceEmail && {
          alternative_invoice_email: values.alternative_invoice_email,
        }),
      },
      ...(isShowAltInvoiceEmail && {
        alternative_invoice_email: values.alternative_invoice_email || null,
      }),
      queryParams: customerQueryParams,
    };
    createCustomer(createCustomerDTO);
  };

  const initialCountry: ICountry = useGetInitialCountry();
  const countryOptions: Option[] = useGetCountryOptions();

  // Get city and address handling
  const formikRef = useRef<any>();
  const {
    mutate: getCityAndAddress,
    data: cityAndAddressData,
    isSuccess: isSuccessGetCityAndAddress,
    isLoading: isLoadingGetCityAndAddress,
  } = useGetCityAndAddress();

  useEffect(() => {
    if (
      isSuccessGetCityAndAddress &&
      cityAndAddressData &&
      !formikRef?.current.values.city
    ) {
      formikRef?.current.setFieldValue('city', cityAndAddressData.address.city);
      if (
        formikRef?.current.values.house_number &&
        formikRef?.current.values.street
      )
        getCityAndAddress({
          countryCode: formikRef?.current.values.country,
          zipCode: formikRef?.current.values.code,
          houseNumber: formikRef?.current.values.house_number,
          city: cityAndAddressData.address.city,
          street: formikRef?.current.values.street,
        });
    }
  }, [isSuccessGetCityAndAddress]);

  const debouncedGetCityAndAddress = debounce(
    (getCityAndAddressData: IGetCityAndAddressDTO) => {
      if (isValidGetCityAndAddressData(getCityAndAddressData))
        getCityAndAddress({
          countryCode: getCityAndAddressData.countryCode,
          zipCode: getCityAndAddressData.zipCode,
          houseNumber: getCityAndAddressData.houseNumber,
          city: getCityAndAddressData.city,
          street: getCityAndAddressData.street,
        });
    },
    1000
  );

  return (
    <Container>
      <AddCustomerWrapper>
        <H3>{t('Create customer')}</H3>
      </AddCustomerWrapper>
      <CustomerDetailsContainer>
        <CustomerDetailsLabel>{t('Customer details')}</CustomerDetailsLabel>
        <Formik
          innerRef={formikRef}
          enableReinitialize
          initialValues={{
            name: '',
            last_name: '',
            title: '',
            email: '',
            alternative_invoice_email: '',
            country: initialCountry.cca2,
            phone: '',
            street: '',
            code: '',
            city: '',
            house_number: '',
            company_name: '',
            vat_number: '',
            coc_number: '',
          }}
          validationSchema={createCustomerSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={() => {
            // onSubmit
          }}
          validateOnMount={true}
        >
          {({
            handleBlur,
            setFieldValue,
            setFieldError,
            values,
            errors,
            touched,
            isValid,
          }) => {
            return (
              <FormikContainer>
                <Row>
                  <Input
                    pwId={'name-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['name']
                        ? errors['name']
                        : ''
                    }
                    height={'41rem'}
                    name="name"
                    placeholder={t('Name')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('name', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                  />
                  <Input
                    pwId={'last-name-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['last_name']
                        ? errors['last_name']
                        : ''
                    }
                    height={'41rem'}
                    name="last_name"
                    placeholder={t('Last name')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('last_name', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                  />
                </Row>
                <Row>
                  <SelectWrapper>
                    <Field
                      pwId={'title-field'}
                      errorMessage={
                        areAllFieldsTouched || touched['title']
                          ? errors['title']
                          : ''
                      }
                      height={'41rem'}
                      width={isPhone ? '100%' : '203rem'}
                      name="title"
                      options={titleOptions}
                      component={StyledCustomSelect}
                      placeholder={t('Title')}
                      isMulti={false}
                      onSelect={(value: string) => {
                        setFieldValue('title', value);
                      }}
                    />
                  </SelectWrapper>
                  <Input
                    pwId={'email-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['email']
                        ? errors['email']
                        : ''
                    }
                    height={'41rem'}
                    name="email"
                    placeholder={t('E-mail')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('email', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                    icon={Info}
                    iconSize={20}
                    iconTooltip={t('Valid format: example@mail.com')}
                  />
                </Row>
                <Row>
                  <CheckboxWrapper>
                    <Checkbox
                      isChecked={isShowAltInvoiceEmail}
                      label={t('Alternative invoice E-mail')}
                      style={{ fontSize: '16rem' }}
                      onChange={() => {
                        const newIsUsingAltInvoiceValue =
                          !isShowAltInvoiceEmail;
                        if (!newIsUsingAltInvoiceValue) {
                          setFieldError('alternative_invoice_email', undefined);
                        } else {
                          // Manually trigger validation
                          const emailError: string | undefined =
                            validateAlternativeEmailField(
                              values.alternative_invoice_email
                            );
                          if (emailError) {
                            setFieldError(
                              'alternative_invoice_email',
                              emailError
                            );
                          }
                        }
                        setIsShowAltInvoiceEmail(!isShowAltInvoiceEmail);
                      }}
                    />
                  </CheckboxWrapper>
                  {isShowAltInvoiceEmail && (
                    <Input
                      validate={(value: string) =>
                        validateAlternativeEmailField(value)
                      }
                      pwId={'alternative_invoice_email-field'}
                      errorMessage={
                        areAllFieldsTouched ||
                        touched['alternative_invoice_email']
                          ? errors['alternative_invoice_email']
                          : ''
                      }
                      height={'41rem'}
                      name="alternative_invoice_email"
                      placeholder={t('Alternative invoice E-mail')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('alternative_invoice_email', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                    />
                  )}
                </Row>
                <Row>
                  <Input
                    pwId={'company-name-field'}
                    errorMessage={
                      touched['company_name'] ? errors['company_name'] : ''
                    }
                    height={'41rem'}
                    name="company_name"
                    placeholder={t('Company name')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('company_name', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                  />
                  <Input
                    pwId={'company-vat-field'}
                    errorMessage={
                      touched['vat_number'] ? errors['vat_number'] : ''
                    }
                    height={'41rem'}
                    name="vat_number"
                    placeholder={t('Company VAT')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('vat_number', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    pwId={'phone-number-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['phone']
                        ? errors['phone']
                        : ''
                    }
                    height={'41rem'}
                    name="phone"
                    placeholder={t('Phone number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('phone', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                  />
                  <Input
                    pwId={'coc-number-field'}
                    errorMessage={
                      touched['coc_number'] ? errors['coc_number'] : ''
                    }
                    height={'41rem'}
                    name="coc_number"
                    placeholder={t('COC number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('coc_number', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'zip-code-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['code']
                        ? errors['code']
                        : ''
                    }
                    height={'41rem'}
                    name="code"
                    placeholder={t('Zip code')}
                    onBlur={(e: any) => {
                      const value = e.target.value.trim();
                      setFieldValue('code', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                    onChange={(value: any) => {
                      if (values.country) {
                        debouncedGetCityAndAddress({
                          countryCode: values.country,
                          zipCode: value,
                          houseNumber: values.city ? values.house_number : '',
                          city: values.city,
                          street: values.city ? values.street : '',
                        });
                      }
                    }}
                  />
                  <Input
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'house-number-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['house_number']
                        ? errors['house_number']
                        : ''
                    }
                    height={'41rem'}
                    name="house_number"
                    placeholder={t('House number')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('house_number', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                    onChange={(value: any) => {
                      if (values.street && values.country) {
                        debouncedGetCityAndAddress({
                          countryCode: values.country,
                          zipCode: values.code,
                          houseNumber: value,
                          city: values.city,
                          street: values.street,
                        });
                      }
                    }}
                  />
                </Row>
                <Row>
                  <Input
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'city-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['city']
                        ? errors['city']
                        : ''
                    }
                    height={'41rem'}
                    name="city"
                    placeholder={t('City')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('city', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                    onChange={(value: any) => {
                      if (values.country) {
                        debouncedGetCityAndAddress({
                          countryCode: values.country,
                          zipCode: values.code,
                          houseNumber: values.house_number,
                          city: value,
                          street: values.street || '',
                        });
                      }
                    }}
                  />
                  <Input
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'street-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['street']
                        ? errors['street']
                        : ''
                    }
                    height={'41rem'}
                    name="street"
                    placeholder={t('Street')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('street', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                    }}
                    onChange={(value: any) => {
                      if (values.city && values.country) {
                        debouncedGetCityAndAddress({
                          countryCode: values.country,
                          zipCode: values.code,
                          houseNumber: values.house_number,
                          city: values.city,
                          street: value,
                        });
                      }
                    }}
                  />
                </Row>
                <Row>
                  <SelectWrapper>
                    <StyledSelect
                      isLoading={isLoadingGetCityAndAddress}
                      pwId={'country-code-field'}
                      errorMessage={
                        areAllFieldsTouched || touched['country']
                          ? errors['country']
                          : ''
                      }
                      height={'41rem'}
                      name="country"
                      width={isPhone ? '100%' : '203rem'}
                      options={countryOptions}
                      component={CustomSelect}
                      isMulti={false}
                      onSelect={(value: any) => {
                        setFieldValue('country', value);
                        debouncedGetCityAndAddress({
                          countryCode: value.value,
                          zipCode: values.code,
                          houseNumber: values.house_number,
                          city: values.city,
                          street: values.street,
                        });
                      }}
                    />
                  </SelectWrapper>
                </Row>
                <ButtonsWrapper>
                  <Button
                    disabled={existingCustomerWithSameEmailIsLoading}
                    width={isSmallPhone ? '150rem' : '200rem'}
                    onClick={() => handleCancel()}
                    label={t('Back')}
                    secondary
                  />
                  <ButtonCreate
                    data-testid="create-button"
                    disabled={existingCustomerWithSameEmailIsLoading}
                    width={isSmallPhone ? '150rem' : '200rem'}
                    onClick={() => {
                      if (isValid) {
                        setIsConfirmModalOpen(true);
                      } else if (!areAllFieldsTouched) {
                        setAreAllFieldsTouched(true);
                      }
                    }}
                    label={t('Create')}
                    primary
                  />
                </ButtonsWrapper>
                <ConfirmModal
                  title={t('New Customer')}
                  description={`${t(
                    'Are you sure you want to add a New Customer'
                  )}?`}
                  isOpen={isConfirmModalOpen}
                  setIsOpen={setIsConfirmModalOpen}
                  onConfirm={() => {
                    getCustomerByEmail(values.email);
                    setIsConfirmModalOpen(false);
                  }}
                  onCancel={() => setIsConfirmModalOpen(false)}
                />
              </FormikContainer>
            );
          }}
        </Formik>
      </CustomerDetailsContainer>
      <YesOrNoModal
        pwId="yes-or-no-existing-customer-modal"
        isOpen={isNewCustomerSameEmailModalOpen}
        setIsOpen={setIsNewCustomerSameEmailModalOpen}
        onYes={() => {
          const customerQueryParams: ICreateCustomerQueryParamsForCustomer = {
            force_create_customer: true,
          };
          handleCreateCustomer(customerQueryParams);
        }}
        onNo={() => setIsNewCustomerSameEmailModalOpen(false)}
        title={''}
        description={`${
          t('Email already exists for customer') +
          ` ${existingCustomerWithSameEmail?.customer?.number} (${existingCustomerWithSameEmail?.customer?.name} ${existingCustomerWithSameEmail?.customer?.last_name})` +
          t(', do you still want to proceed with adding a new customer')
        }?`}
      />
    </Container>
  );
};

export default NewCustomerPage;
