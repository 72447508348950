import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ProductTab from 'pages/Manager/NewQuotationPage/ProductTab/ProductTab';
import { ICreateQuotationProductFormDTO } from 'pages/Manager/NewQuotationPage/ProductTab/constants';
import { useEffect, useState } from 'react';
import OverviewTab from 'pages/Manager/NewQuotationPage/OverviewTab/OverviewTab';
import {
  useGetPrepopulateLineData,
  useAddQuotationLine,
  useEditQuotationLine,
  IUseEditQuotationLine,
  useGetGeneralDiscountInfo,
} from './hooks';
import { isNumeric } from 'utils/validation';
import { getInitialProductsData } from './helpers';
import { SingleQuotationNewLinePageTab } from './constants';
import {
  AddNewProductLabel,
  Container,
  LoaderWrapper,
} from './SingleQuotationNewLinePage.styled';
import {
  ICreateQuotationLineDTO,
  IEditQuotationLineData,
} from 'types/Quotations.types';
import { useSocketConnection } from 'providers/SocketIOProvider/SocketIOProvider';
import { toast } from 'utils/toast';
import { useGetSingleQuotation } from '../hooks';
import Loader from 'components/Loader/Loader';

const SingleQuotationNewLinePage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const prepopulateLineData = useGetPrepopulateLineData();
  const generalDiscountInfo = useGetGeneralDiscountInfo();

  const [activeTab, setActiveTab] = useState<string>(
    SingleQuotationNewLinePageTab.PRODUCTS
  );
  const [productFormDtos, setProductFormDtos] = useState<
    ICreateQuotationProductFormDTO[]
  >(getInitialProductsData(prepopulateLineData));

  useSocketConnection();

  const { mutate: addQuotationLine, status: addStatus } = useAddQuotationLine(
    id!
  );
  const { mutate: editQuotationLine, status: editStatus } =
    useEditQuotationLine(id!);

  const {
    data: quotationData,
    isLoading: isLoadingQuotation,
    isSuccess: isSuccessQuotation,
  } = useGetSingleQuotation(id!);

  useEffect(() => {
    if (addStatus === 'success' || editStatus === 'success') {
      navigate(-1);
    }
  }, [addStatus, editStatus]);

  const handleSubmit = () => {
    if (prepopulateLineData) {
      const productFormDto = productFormDtos[0];
      const productAttributes = {};
      for (const attributeKey of Object.keys(productFormDto.attributes)) {
        productAttributes[attributeKey] =
          productFormDto.attributes[attributeKey];
      }

      const editQuotationLineData: IEditQuotationLineData = {
        quantity: Number(productFormDto.quantity),
        discount: {
          type: productFormDto.discountType,
          amount: +productFormDto.discount,
        },
        description: productFormDto.description,
        ...(productFormDto.logyxConfigurationIntent && {
          logyx_configuration_intent_uuid:
            productFormDto.logyxConfigurationIntent.uuid,
        }),
        placement: productFormDto.placement,
        product_purchase_price: isNumeric(productFormDto.purchasePrice)
          ? Number(productFormDto.purchasePrice)
          : 0,
        product_sales_price: Number(productFormDto.salesPrice),
        product_attributes: productAttributes,
      };

      const payload: IUseEditQuotationLine = {
        quotationLineId: prepopulateLineData.id,
        editQuotationLineData,
      };
      editQuotationLine(payload, {
        onSuccess: () => {
          toast.success(t('Quotation line edited'));
        },
      });
    } else {
      const productFormDto = productFormDtos[0];
      const productAttributes = {};
      for (const attributeKey of Object.keys(productFormDto.attributes)) {
        productAttributes[attributeKey] =
          productFormDto.attributes[attributeKey];
      }
      const createQuotationLineData: ICreateQuotationLineDTO = {
        supplier_id: Number(productFormDto.supplier.id),
        ...(!productFormDto.isStockItem &&
          !prepopulateLineData && {
            product_id: productFormDto.product.id,
          }),
        ...(productFormDto.stockItemId && {
          stock_item_id: productFormDto.stockItemId,
        }),
        quantity: Number(productFormDto.quantity),
        product_attributes: productAttributes,
        discount: {
          type: productFormDto.discountType,
          amount: +productFormDto.discount,
        },
        description: productFormDto.description,
        ...(productFormDto.logyxConfigurationIntent && {
          logyx_configuration_intent_uuid:
            productFormDto.logyxConfigurationIntent.uuid,
        }),
        placement: productFormDto.placement,
        product_purchase_price: isNumeric(productFormDto.purchasePrice)
          ? Number(productFormDto.purchasePrice)
          : 0,
        product_sales_price: Number(productFormDto.salesPrice),
      };
      addQuotationLine(createQuotationLineData);
    }
  };

  return (
    <Container>
      {(isLoadingQuotation || !isSuccessQuotation) && (
        <LoaderWrapper>
          <Loader positionType="relative" />
        </LoaderWrapper>
      )}
      <AddNewProductLabel>
        {prepopulateLineData ? t('Edit product') : t('Add new product')}
      </AddNewProductLabel>
      {activeTab === SingleQuotationNewLinePageTab.PRODUCTS && (
        <ProductTab
          onForward={() => setActiveTab(SingleQuotationNewLinePageTab.OVERVIEW)}
          onBack={() => {
            if (prepopulateLineData) {
              navigate(location.pathname.replace('/add-line', '/products'));
            } else {
              navigate(-1);
            }
          }}
          productFormDtos={productFormDtos}
          setProductFormDtos={setProductFormDtos}
          canAddAnotherProduct={false}
          hideMeasurementsToggle={!!prepopulateLineData}
          generalDiscountInfo={generalDiscountInfo}
          //When editing line, line price is excluded from total price so that initial price contains only the rest of line prices
          initialTotalSalesPriceForAllLines={
            prepopulateLineData
              ? quotationData?.total_amount - prepopulateLineData.total_amount
              : quotationData?.total_amount
          }
        />
      )}
      {activeTab === SingleQuotationNewLinePageTab.OVERVIEW && (
        <OverviewTab
          onBack={() => setActiveTab(SingleQuotationNewLinePageTab.PRODUCTS)}
          onForward={() => {
            handleSubmit();
          }}
          customerDetails={null}
          productFormDtos={productFormDtos}
          orderDetails={null}
          status={addStatus || editStatus}
          newlyCreatedQuotationId={''}
        />
      )}
    </Container>
  );
};

export default SingleQuotationNewLinePage;
