import { H3, marginMd } from 'assets/styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import FakeInput from '../../../../components/FakeInput/FakeInput';
import {
  Container,
  SupplierDetailsWrapper,
  FirstRow,
  Line,
  Margin,
  OrderDetailsWrapper,
  RowMarginTop,
  SectionTitle,
  SubmitRow,
} from './OverviewTab.styled';
import { ICreateInvoiceDetails } from '../InvoiceDetailsTab/constants';
import { useEffect } from 'react';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';
import { ICreateInvoiceSupplierDetails } from '../SupplierTab/constants';
import Tooltip from 'components/Tooltip/Tooltip';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IOverviewTabProps {
  onForward: () => void;
  onBack: () => void;
  supplierDetails: ICreateInvoiceSupplierDetails;
  invoiceDetails: ICreateInvoiceDetails;
  status: string;
  newlyCreatedInvoiceId: string;
}

const OverviewTab = ({
  onForward,
  onBack,
  supplierDetails,
  invoiceDetails,
  status,
  newlyCreatedInvoiceId,
}: IOverviewTabProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isPhone } = useBreakpointFlag();

  useEffect(() => {
    if (status === 'success' && newlyCreatedInvoiceId) {
      navigate(
        RoutesConfig.SinglePurchaseInvoice.fullPath
          .replace(':id', newlyCreatedInvoiceId)
          .replace('/*', '')
      );
    }
  }, [status]);

  return (
    <Container>
      <FirstRow>
        <H3>{t('Overview')}</H3>
      </FirstRow>
      <SupplierDetailsWrapper>
        <SectionTitle>{t('Supplier details')}</SectionTitle>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.contact_person}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Contact person')}
          />
          <FakeInput
            label={supplierDetails.phone}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Phone number')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.email}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('E-mail')}
          />
          <FakeInput
            label={supplierDetails.country}
            isDropdown={true}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Country')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.company_name}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Company name')}
          />
          <FakeInput
            label={supplierDetails.vat_number}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Company VAT')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.city}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('City')}
          />
          <FakeInput
            label={supplierDetails.code}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Zip code')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            label={supplierDetails.street}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Street')}
          />
          <FakeInput
            label={supplierDetails.house_number}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('House number')}
          />
        </RowMarginTop>
        <Line />
      </SupplierDetailsWrapper>
      <OrderDetailsWrapper>
        <SectionTitle>{t('Order details')}</SectionTitle>
        <RowMarginTop marginTop={marginMd}>
          {invoiceDetails?.externalId?.length > 30 ? (
            <Tooltip content={invoiceDetails.externalId}>
              <FakeInput
                marginTop={marginMd}
                label={`${invoiceDetails.externalId?.substring(0, 30)}...`}
                isDropdown={false}
                width={'100%'}
                placeholder={t('External reference')}
              />
            </Tooltip>
          ) : (
            <FakeInput
              label={invoiceDetails.externalId}
              isDropdown={false}
              width={isPhone ? '100%' : '50%'}
              placeholder={t('External reference')}
            />
          )}
          <FakeInput
            label={invoiceDetails.daysValid}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Days valid')}
          />
        </RowMarginTop>
      </OrderDetailsWrapper>
      <SubmitRow>
        <Button
          width={'200rem'}
          onClick={() => onBack()}
          label={t('Back')}
          secondary
          disabled={status === 'loading'}
        />
        <Margin>
          <Button
            width={'200rem'}
            onClick={() => onForward()}
            label={t('Finish')}
            primary
            disabled={status === 'loading'}
          />
        </Margin>
      </SubmitRow>
    </Container>
  );
};

export default OverviewTab;
