import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled, { css } from 'styled-components';

interface IStyledProps {
  $isGroup: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 32rem;

  ${respondTo.midTablet`
    padding: 32rem 15rem;
  `}

  ${respondTo.smallTablet`
    padding-top: 50rem;
  `}
`;

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const TitleContainer = styled.div`
  text-align: center;
  margin-bottom: 30rem;

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;

export const SaveWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20rem;
  padding: 0 15rem;

  ${respondTo.midTablet`
    padding: 0;
  `}
`;

export const SaveSelectWrapper = styled.div`
  width: 250rem;
`;

export const Title = styled.span`
  font-size: 24rem;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    font-size: 18rem;
  `}
`;

export const FilterWrapper = styled.div`
  margin-bottom: 30rem;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;

export const FilterInner = styled.div`
  overflow: auto;
  max-height: 50vh;
  padding-top: 5rem;

  ${respondTo.midTablet`
    width: 100%;
    max-width: 100%;
  `}

  ${respondTo.smallTablet`
    max-height: 100%;
  `}
`;

export const FilterContainer = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 20rem;

  ${respondTo.smallTablet`
    gap: 15rem;
  `}
`;

export const FilterSubContainer = styled(FilterContainer)`
  ${respondTo.midTablet`
    flex-direction: column;
  `}
`;

export const Filter = styled.div<IStyledProps>`
  display: flex;
  flex-direction: column;
  gap: 20rem;
  width: 100%;
  max-width: 100%;
  margin-bottom: 20rem;
  border-radius: 10rem;
  padding: 10rem 15rem;
  background-color: ${(props: IStyledProps) =>
    props.$isGroup ? COLORS.BACKGROUND_COLOR : COLORS.WHITE};

  ${respondTo.smallTablet`
    padding: 0;
  `}

  ${(props: IStyledProps) =>
    props.$isGroup &&
    css`
      padding: 20rem 15rem 10rem;
      max-width: 790rem;

      ${respondTo.horizontalTablet`
        max-width: 785rem;
      `}

      ${respondTo.midTablet`
        max-width: 100%;
      `}

      ${respondTo.smallTablet`
        padding: 10rem;
      `}
    `};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  gap: 30rem;
`;

export const SelectWrapper = styled.div<IStyledProps>`
  min-width: 100rem;
  width: 100rem;
  margin-left: 15rem;

  ${respondTo.horizontalTablet`
    width: 120rem;
  `}

  ${respondTo.midTablet`
    margin-left: 0;
  `}

  ${respondTo.smallTablet`
    margin-left: 0;
    width: 100%;
    max-width: 48%;
  `}
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  width: 100%;
  padding-right: 30rem;

  ${respondTo.horizontalTablet`
    padding-right: 0;
  `}
`;

export const UnderlineButton = styled.span`
  display: flex;
  color: ${COLORS.RED_100};
  font-size: 16rem;
  cursor: pointer;
  ${hoverLineOut}
  align-self: center;
`;
