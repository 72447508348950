import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { NavLink } from 'react-router-dom';

export const Container = styled.div`
  z-index: 2;
  display: flex;
  width: 100%;
  max-width: 100%;
  border: none;
  overflow-x: auto;
  background-color: ${COLORS.WHITE};
  gap: 20rem;
  padding: 0 20rem;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

export const Item = styled(NavLink)`
  position: relative;
  cursor: pointer;
  background-color: ${COLORS.WHITE};
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: background-color ease 100ms;
  height: 50rem;
`;

export const ItemText = styled.span`
  display: block;
  color: ${COLORS.WHITE};
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.BLACK};
  user-select: none;
  white-space: nowrap;

  ${Item}.active & {
    color: ${COLORS.PRIMARY};
  }
`;
