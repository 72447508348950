import { IEmailDetails } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import moment from 'moment';
import { IAlternativeContact } from 'types/Appointment.types';
import { ICustomer } from 'types/Customer.types';
import { IQuotation } from 'types/Quotations.types';
import { ISalesOrder } from 'types/SalesOrders.types';
import {
  INITIAL_APPOINTMENT_LOWER_INTERVAL,
  INITIAL_APPOINTMENT_UPPER_INTERVAL,
} from './constants';

export const getArrivalTime = (
  time_from: string | undefined,
  appointmentLowerInterval: number | undefined,
  appointmentUpperInterval: number | undefined
): { arrivalFrom: string; arrivalTo: string } => {
  let arrivalFrom = 'N/A';
  let arrivalTo = 'N/A';

  const lowerInterval =
    appointmentLowerInterval ?? INITIAL_APPOINTMENT_LOWER_INTERVAL;
  const upperInterval =
    appointmentUpperInterval ?? INITIAL_APPOINTMENT_UPPER_INTERVAL;

  if (time_from) {
    // Include both formats with and without timezone
    // Times obtained through dragging  have timezone. while ones obtained through TabletPlusIcon dont have timezone
    const formats = ['HH:mm:ssZ', 'HH:mm:ss'];

    if (moment(time_from, formats, true).isValid()) {
      const appointmentTime = moment(time_from, formats[0]);
      arrivalFrom = appointmentTime
        .clone()
        .subtract(lowerInterval, 'minutes')
        .format('HH:mm');

      arrivalTo = appointmentTime
        .clone()
        .add(upperInterval, 'minutes')
        .format('HH:mm');
    }
  }

  return { arrivalFrom, arrivalTo };
};

export const getNewAppointmentEmailDetails = (
  quotation?: IQuotation,
  salesOrder?: ISalesOrder,
  customer?: ICustomer,
  alternativeContact?: IAlternativeContact
): IEmailDetails | undefined => {
  if (quotation) {
    return {
      email:
        quotation?.customer_snapshot?.shipping_data?.email ??
        quotation?.customer?.email,
      type: 'Ship-to',
    };
  } else if (salesOrder) {
    return {
      email:
        salesOrder?.customer_snapshot?.shipping_data?.email ??
        salesOrder?.customer_snapshot?.email,
      type: 'Ship-to',
    };
  } else if (alternativeContact) {
    return {
      email: alternativeContact.email,
      type: 'Ship-to',
    };
  } else if (customer) {
    return {
      email: customer.email,
      type: 'Ship-to',
    };
  }
};
