import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import {
  Title,
  TitleContainer,
  SearchContainer,
  CloseContainer,
  AddMoreContainer,
  WorkerContainer,
  WorkerDescription,
  WorkerName,
  ButtonContainer,
  Margin,
  DataWrapper,
  DataContainer,
  MarginTop,
} from 'pages/Manager/PlanningPage/WorkersModal/WorkersModal.styled';
import Pagination from 'components/Table/Pagination/Pagination';
import { useTranslation } from 'react-i18next';
import AddResourcesForm from './AddResourcesForm/AddResourcesForm';
import { useGetResources, useDeleteResource } from './hooks';
import {
  EditingType,
  IWorkerAndResource,
} from 'pages/Manager/PlanningPage/WorkersModal/type';
import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { EditWorkerOrResourceModal } from '../EditWorkerOrResourceModal/EditWorkerOrResourceModal';
import Icon from 'components/Icon/Icon';
import { COLORS, marginXs } from 'assets/styled';
import { ClearButton } from '../UsersModal/UsersModal.styled';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { MagnifyingGlass, PencilSimple, Plus } from '@phosphor-icons/react';
import { Trash } from '@phosphor-icons/react/dist/ssr';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IResourcesModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setSelectedResources: (resourcesIds: string[]) => void;
  selectedResources: string[];
}

const RESOURCES_PER_PAGE = 4;

const ResourcesModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  setSelectedResources,
  selectedResources,
}: IResourcesModalProps) => {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const [deleteId, setDeleteId] = useState<string>('');
  const [searchBy, setSearchBy] = useState<string>('');
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [localResources, setLocalResources] =
    useState<string[]>(selectedResources);
  const [selectedResource, setSelectedResource] =
    useState<IWorkerAndResource>();
  const canEditPlanning = useCan(Actions.EDIT_APPOINTMENT);
  const { t } = useTranslation();
  const { data: resourcesData } = useGetResources(
    page,
    RESOURCES_PER_PAGE,
    searchBy,
    undefined,
    undefined,
    isOpen
  );

  useEffect(() => {
    if (searchBy && page != 1) {
      setPage(1);
    }
  }, [searchBy]);

  const { isMidTablet } = useBreakpointFlag();

  const { mutate: deleteResource, isSuccess } = useDeleteResource();

  useEffect(() => {
    setOpenDeleteModal(false);
  }, [isSuccess]);

  const resetSearchBy = () => {
    setSearchBy('');
  };

  return (
    <Modal
      isOpen={isOpen}
      level={isMidTablet ? 'SECOND' : 'FIRST'}
      setIsOpen={setIsOpen}
      onBackDropClick={resetSearchBy}
      modalStyle={{ position: 'fixed', margin: 'auto' }}
    >
      <div>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
              resetSearchBy();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Resources')}</Title>
        </TitleContainer>
        <SearchContainer>
          <Input
            icon={MagnifyingGlass}
            width={'300rem'}
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />
          <ClearButton
            onClick={() => {
              setLocalResources([]);
            }}
          >
            {t('Clear')}
          </ClearButton>
          {canEditPlanning && (
            <AddMoreContainer>
              <Button
                label={t('Add more')}
                link
                icon={Plus}
                sizeIcon={15}
                weightIcon="regular"
                colorIcon={COLORS.PRIMARY}
                onClick={() => setOpenAddModal(true)}
                fontSize="16rem"
              />
            </AddMoreContainer>
          )}
        </SearchContainer>
        {resourcesData?.resources?.map((resource: IWorkerAndResource) => {
          const isSelected = localResources.includes(resource.id);
          return (
            <WorkerContainer selected={isSelected} key={resource.id}>
              <DataContainer>
                <DataWrapper>
                  <WorkerName>{resource.name}</WorkerName>
                  <WorkerDescription>{resource.description}</WorkerDescription>
                </DataWrapper>
                <AddMoreContainer>
                  <Button
                    data-testid={
                      isSelected
                        ? `unselect-button-${resource.name}`
                        : `select-button-${resource.name}`
                    }
                    onClick={() => {
                      isSelected
                        ? setLocalResources(
                            localResources.filter((id) => id !== resource.id)
                          )
                        : setLocalResources([...localResources, resource.id]);
                    }}
                    width={'100rem'}
                    secondary
                    label={isSelected ? t('Unselect') : t('Select')}
                  />
                  <Icon
                    svg={PencilSimple}
                    color={COLORS.PRIMARY}
                    size={20}
                    marginLeft={marginXs}
                    data-testid={`edit-icon-${resource.name}`}
                    onClick={() => {
                      setSelectedResource(resource);
                      setOpenEditModal(true);
                    }}
                  />
                  <Icon
                    data-testid={`trash-icon-${resource.name}`}
                    svg={Trash}
                    weight="regular"
                    size={20}
                    color={COLORS.RED_200}
                    marginLeft={marginXs}
                    onClick={() => {
                      setDeleteId(resource.id);
                      setOpenDeleteModal(true);
                    }}
                  />
                </AddMoreContainer>
              </DataContainer>
            </WorkerContainer>
          );
        })}
        <MarginTop>
          {resourcesData?.total > RESOURCES_PER_PAGE && (
            <Pagination
              page={page}
              perPage={RESOURCES_PER_PAGE}
              total={resourcesData?.total}
              loadPage={(newPage) => setPage(newPage)}
            />
          )}
        </MarginTop>

        <ButtonContainer>
          <Button
            width={'200rem'}
            secondary
            onClick={() => {
              onCancel();
              resetSearchBy();
            }}
            label={t('Cancel')}
          />
          <Margin>
            <Button
              data-testid="apply-button"
              onClick={() => {
                setIsOpen(false);
                resetSearchBy();
                setSelectedResources(localResources);
              }}
              width={'200rem'}
              primary
              label={'Apply'}
            />
          </Margin>
        </ButtonContainer>
        <Modal
          level={'SECOND'}
          isOpen={openAddModal}
          setIsOpen={setOpenAddModal}
          modalStyle={{ position: 'fixed', margin: 'auto' }}
        >
          <AddResourcesForm onCancel={() => setOpenAddModal(false)} />
        </Modal>
        <Modal
          level={'SECOND'}
          isOpen={openDeleteModal}
          setIsOpen={setOpenDeleteModal}
          modalStyle={{ position: 'fixed', margin: 'auto' }}
        >
          <Danger
            submit={{
              onClick: () => {
                deleteResource(deleteId);
              },
              text: 'Delete',
              disabled: false,
            }}
            cancel={{ onClick: () => setOpenDeleteModal(false) }}
            title={t('Delete Resources')}
            description={`${t(
              'Are you sure you want to delete this resource'
            )}?`}
          />
        </Modal>
        <EditWorkerOrResourceModal
          isOpen={openEditModal}
          setIsOpen={setOpenEditModal}
          onCancel={() => setOpenEditModal(false)}
          entity={selectedResource!}
          type={EditingType.RESOURCE}
        />
      </div>
    </Modal>
  );
};

export default ResourcesModal;
