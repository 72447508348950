import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ActionsIcons,
  ActionsWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  LoaderWrapper,
  MollieCustomerSpan,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import {
  ChatCircle,
  DownloadSimple,
  EnvelopeSimple,
  WarningCircle,
} from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ReactComponent as MailFailed } from 'assets/icons/mail-failed.svg';
import { ReactComponent as MailPending } from 'assets/icons/mail-pending.svg';
import Spinner from 'components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { EmailStatuses } from 'types/Email.types';
import { SingleSalesInvoiceRoutes } from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';
import { RoutesConfig } from 'navigation/routes';
import { ICreateAppointmentNavigationState } from 'pages/Manager/PlanningPage/NewAppointmentModal/hooks';
import { AppointmentType } from 'types/Appointment.types';

interface IActionsMobileModalProps {
  id: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  canEditCustomerAndQuotations: boolean;
  setIsCommentModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsActivateModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsAddMollieCustomerModalOpen: Dispatch<SetStateAction<boolean>>;
  customerComments: any;
}

const ActionsMobileModal = ({
  id,
  isOpen,
  setIsOpen,
  data,
  canEditCustomerAndQuotations,
  setIsCommentModalOpen,
  setIsDeleteModalOpen,
  setIsActivateModalOpen,
  setIsAddMollieCustomerModalOpen,
  customerComments,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>
            {`${data?.customer?.name} ${data?.customer?.last_name}`}
          </Title>
          {data?.customer?.mollie_customer && (
            <MollieCustomerSpan>{`${t('Mollie customer')}`}</MollieCustomerSpan>
          )}
        </TitleContainer>
        <ActionsWrapper>
          <ActionsIcons>
            <Icon
              svg={ChatCircle}
              color={COLORS.PRIMARY}
              size={25}
              onClick={() => setIsCommentModalOpen(true)}
            />
            {!!customerComments?.total && (
              <Icon svg={WarningCircle} size={25} color={COLORS.PRIMARY} />
            )}
          </ActionsIcons>
        </ActionsWrapper>
        <ButtonsWrapper>
          {!data?.customer?.deleted_at ? (
            <Button
              width={'100%'}
              onClick={() => setIsDeleteModalOpen(true)}
              label={t('Delete Customer')}
              redOutline
              disabled={!canEditCustomerAndQuotations}
            />
          ) : (
            <Button
              width={'100%'}
              onClick={() => setIsActivateModalOpen(true)}
              secondary
              label={t('Activate Customer')}
              disabled={!canEditCustomerAndQuotations}
            />
          )}
          <Button
            onClick={() => {
              const navigationState: ICreateAppointmentNavigationState = {
                openModal: true,
                type: AppointmentType.MEASURING,
                customer_id: id,
                customer_number: data?.customer?.number,
              };
              navigate(RoutesConfig.Planning.fullPath, {
                state: navigationState,
              });
            }}
            secondary
            width={'100%'}
            label={t('New measurement')}
          />
          {/* {!data?.customer?.mollie_customer && (
            //intented use is for automated Mollie mandates, but doesn't seem to be used currently
            <Button
              onClick={() => setIsAddMollieCustomerModalOpen(true)}
              secondary
              width="100%"
              label={t('Link to Mollie')}
            />
          )} */}
          <Button
            width={'100%'}
            label={t('Create quotation')}
            primary
            disabled={!canEditCustomerAndQuotations}
            onClick={() => {
              navigate(RoutesConfig.NewQuotation.fullPath, {
                state: {
                  data,
                },
              });
            }}
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
