import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isReceived: boolean;
}

export const SwitchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20rem;

  ${respondTo.bigPhone`
    gap: 10rem;
  `}
`;

export const ReceivedLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.GREY_700};
  ${(props: StyledProps) =>
    props.isReceived &&
    css`
      color: ${COLORS.PRIMARY};
    `};

  ${respondTo.bigPhone`
      font-size: 16rem;
      line-height: 1.2;
    `}
`;
