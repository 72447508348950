import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddAnotherAttributeClickableWrapper,
  AddAnotherAttributeWrapper,
  AddAttributeLabel,
  BottomDiv,
  ButtonWrapper,
  CloseAbsoluteWrapper,
  Container,
  AbsoluteContainer,
  HorizontalLine,
  ScrollContainer,
} from './AddEditProductAttributeModal.styled';
import { Level } from 'components/Modal/type';
import {
  IDocVisibilityOptionEnum,
  IProductAttribute,
  ProductAttributeType,
} from 'types/Product.types';
import { ProductFieldAttributeForm } from './ProductFieldAttributeForm/ProductFieldAttributeForm';
import { ProductDropdownAttributeForm } from './ProductDropdownAttributeForm/ProductDropdownAttributeForm';
import {
  areAllAttributesValid,
  getInitialAttributes,
  handleSetAttributes,
  handleFormatAttributesDefaultValues,
} from './helpers';
import { COLORS, paddingXs } from 'assets/styled';
import { Plus } from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const PWID = 'add-edit-product-attribute-modal';

export interface IAddEditProductAttributeModalProps {
  onClose: () => void;
  attributeForEditing: IProductAttribute | null;
  onEdit: (attribute: IProductAttribute) => void;
  onSubmit: (attributes: IProductAttribute[]) => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  level?: Level;
  pwId?: string;
}

export function AddEditProductAttributeModal({
  onClose,
  attributeForEditing,
  onEdit,
  onSubmit,
  isOpen,
  setIsOpen,
  level,
  pwId,
}: IAddEditProductAttributeModalProps) {
  const isEditMode = attributeForEditing;
  useEffect(() => {
    if (
      attributeForEditing &&
      attributes[0].fieldName !== attributeForEditing.fieldName
    ) {
      setAttributes(getInitialAttributes(attributeForEditing));
    }
  }, [attributeForEditing]);

  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const bottomDivRef = useRef<HTMLDivElement>(null); // Used for scrolling to bottom when adding new attribute
  const { t } = useTranslation();
  const [attributes, setAttributes] = useState<IProductAttribute[]>(
    getInitialAttributes()
  );
  const [validAttributesArray, setValidAttributesArray] = useState<boolean[]>(
    []
  );

  const setIsValidForAttribute = (attributeIndex: number, isValid: boolean) => {
    const validAttributesArrayCopy = [...validAttributesArray];
    validAttributesArrayCopy[attributeIndex] = isValid;
    setValidAttributesArray(validAttributesArrayCopy);
  };

  const { isSmallPhone } = useBreakpointFlag();

  const handleClose = () => {
    setIsOpen(false);
    onClose();
    setAreAllFieldsTouched(false);
    // This will be initial state of the modal after the modal has been closed
    const newAttribute: IProductAttribute = {
      type: ProductAttributeType.FIELD,
      fieldName: '',
      hasDefaultValue: false,
      defaultOptionIndex: null,
      defaultValue: '',
      options: ['', ''],
      docVisibilityOptions: Object.values(IDocVisibilityOptionEnum),
      shouldDisplayAfterLogyxConfiguration: false,
    };
    setAttributes([newAttribute]);
  };

  return (
    <Modal
      level={level}
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (!boolean) {
          handleClose();
        } else {
          setIsOpen(boolean);
        }
      }}
      modalStyle={{
        position: 'fixed',
        margin: 'auto',
        paddingRight: paddingXs,
      }} // Center positioning
    >
      <Container>
        <AddAttributeLabel
          data-testid={`${pwId}-${PWID}-${
            isEditMode ? 'edit-default-field' : 'add-default-fields'
          }`}
        >
          {isEditMode ? t('Edit default field') : t('Add default fields')}
        </AddAttributeLabel>
        <ScrollContainer>
          {attributes.map((attribute: IProductAttribute, index: number) => {
            return (
              <Fragment key={index}>
                {attribute.type === ProductAttributeType.FIELD && (
                  <ProductFieldAttributeForm
                    pwId={`${pwId}-${PWID}`}
                    isEditMode={!!isEditMode}
                    areAllFieldsTouched={areAllFieldsTouched}
                    attribute={attribute}
                    attributeIndex={index}
                    isValidForAttribute={validAttributesArray[index]}
                    setIsValidForAttribute={setIsValidForAttribute}
                    handleSetAttribute={(
                      attributeIndex: number,
                      newAttribute: IProductAttribute | undefined
                    ) => {
                      handleSetAttributes(
                        attributeIndex,
                        newAttribute,
                        attributes,
                        setAttributes
                      );
                      if (newAttribute === undefined) {
                        const validAttributesArrayCopy = [
                          ...validAttributesArray,
                        ];
                        validAttributesArrayCopy.splice(attributeIndex, 1);
                        setValidAttributesArray(validAttributesArrayCopy);
                      }
                    }}
                  />
                )}
                {attribute.type === ProductAttributeType.DROPDOWN && (
                  <ProductDropdownAttributeForm
                    pwId={`${pwId}-${PWID}`}
                    isEditMode={!!isEditMode}
                    areAllFieldsTouched={areAllFieldsTouched}
                    attribute={attribute}
                    attributeIndex={index}
                    isValidForAttribute={validAttributesArray[index]}
                    setIsValidForAttribute={setIsValidForAttribute}
                    handleSetAttribute={(
                      attributeIndex: number,
                      newAttribute: IProductAttribute | undefined
                    ) => {
                      handleSetAttributes(
                        attributeIndex,
                        newAttribute,
                        attributes,
                        setAttributes
                      );

                      if (newAttribute === undefined) {
                        const validAttributesArrayCopy = [
                          ...validAttributesArray,
                        ];
                        validAttributesArrayCopy.splice(attributeIndex, 1);
                        setValidAttributesArray(validAttributesArrayCopy);
                      }
                    }}
                  />
                )}
                {attributes.length - 1 > index && <HorizontalLine />}
              </Fragment>
            );
          })}
          <BottomDiv ref={bottomDivRef} />
        </ScrollContainer>
        <AbsoluteContainer>
          {!isEditMode && (
            <AddAnotherAttributeWrapper>
              <AddAnotherAttributeClickableWrapper>
                <Button
                  pwId={`${pwId}-${PWID}-add-another-default-field-button`}
                  label={t('Add another default field')}
                  icon={Plus}
                  sizeIcon={20}
                  colorIcon={COLORS.PRIMARY}
                  link
                  onClick={() => {
                    const attributesCopy = [...attributes];
                    const newAttribute: IProductAttribute = {
                      type: ProductAttributeType.FIELD,
                      fieldName: '',
                      hasDefaultValue: false,
                      defaultOptionIndex: null,
                      defaultValue: '',
                      options: ['', ''],
                      docVisibilityOptions: Object.values(
                        IDocVisibilityOptionEnum
                      ),
                      shouldDisplayAfterLogyxConfiguration: false,
                    };
                    attributesCopy.push(newAttribute);
                    setAttributes(attributesCopy);
                    setTimeout(() => {
                      bottomDivRef.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                      });
                    }, 100);
                  }}
                  fontSize="14rem"
                />
              </AddAnotherAttributeClickableWrapper>
            </AddAnotherAttributeWrapper>
          )}
          <ButtonWrapper>
            <Button
              pwId={`${pwId}-${PWID}-cancel-button`}
              label={t('Cancel')}
              secondary
              width={isSmallPhone ? '150rem' : '200rem'}
              onClick={() => handleClose()}
            />
            <Button
              pwId={`${pwId}-${PWID}-${
                isEditMode ? 'edit-button' : 'add-button'
              }`}
              label={isEditMode ? t('Edit') : t('Add')}
              disabled={
                !attributes.length ||
                (areAllFieldsTouched &&
                  !areAllAttributesValid(validAttributesArray))
              }
              primary
              width={isSmallPhone ? '150rem' : '200rem'}
              onClick={() => {
                setAreAllFieldsTouched(true);
                if (areAllAttributesValid(validAttributesArray)) {
                  const formattedAttributes =
                    handleFormatAttributesDefaultValues(attributes);
                  if (isEditMode) {
                    onEdit(formattedAttributes[0]);
                  } else {
                    onSubmit(formattedAttributes);
                  }
                  handleClose();
                }
              }}
            />
          </ButtonWrapper>
        </AbsoluteContainer>
        <CloseAbsoluteWrapper>
          <Close pwId={`${pwId}-${PWID}`} onClick={() => handleClose()} />
        </CloseAbsoluteWrapper>
      </Container>
    </Modal>
  );
}
