import { COLORS, H3 } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
}

export const Container = styled.div`
  padding-top: 50rem;
  width: 100%;
`;
export const CloseContainer = styled.div`
  position: absolute;
  right: 15rem;
  top: 15rem;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(H3)`
  margin-bottom: 20rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20rem;
  width: 100;
  margin-top: 20rem;
`;

export const SelectInputWrapper = styled.div`
  width: 100%;
  height: 41rem;
  z-index: 4;

  .MuiInputBase-root-hxnqRp {
    border-radius: 8rem;
  }
`;

export const ViewTypeOptionLabel = styled.label`
  user-select: none;
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
`;

export const DatePickerWrap = styled.div`
  width: 100%;
`;
