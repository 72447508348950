import { Outlet } from 'react-router-dom';
import Header from '../Header/Header';
import SideMenu from '../Navigation/SideMenu/SideMenu';
import {
  LayoutColumn,
  LayoutRow,
  MobileBreadcrumbs,
} from './ManagerLayout.styled';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import MobileMenu from '../Navigation/MobileMenu/MobileMenu';
import Breadcrumbs from '../Header/Breadcrumbs/Breadcrumbs';
import { TodoInlineBanner } from '../TodoInlineBanner/TodoInlineBanner';

export function ManagerLayout() {
  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const { isSmallTablet } = useBreakpointFlag();
  const activeTodoId = useSelector(
    (state: IRootReducerState) => state.commonInfo.activeTodoId
  );

  return (
    <LayoutRow>
      {isSmallTablet ? <MobileMenu /> : <SideMenu />}
      <LayoutColumn
        isSideMenuCollapsed={isSideMenuCollapsed}
        isBannerShown={!!activeTodoId}
      >
        <Header />
        {activeTodoId && <TodoInlineBanner />}
        {isSmallTablet && (
          <MobileBreadcrumbs>
            <Breadcrumbs />
          </MobileBreadcrumbs>
        )}
        <Outlet />
      </LayoutColumn>
    </LayoutRow>
  );
}

export default ManagerLayout;
