import { COLORS, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 100%;
`;

export const RightAlign = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;
  gap: 20rem;

  ${respondTo.bigPhone`
    justify-content: flex-start;
    width: 100%;
  `}
`;

export const CheckboxContainer = styled.div`
  justify-content: start;
  align-items: center;
  width: 50%;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 900rem;
  width: 100%;
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    flex-direction: column;
    margin-bottom: 20rem;
  `}
`;

export const GeneralDiscountContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 900rem;
  width: 100%;
  margin-bottom: ${marginMd};
  gap: 10rem;

  ${respondTo.bigPhone`
    flex-direction: column;
    margin-bottom: 20rem;
  `}
`;

export const ClearLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 16rem;
  align-self: center !important;
  color: ${COLORS.RED_200};
  ${hoverLineOut}
  margin-left: ${marginSm};
`;

export const GeneralDiscountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10rem;
`;
