import styled from 'styled-components';
import { COLORS, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  ${respondTo.smallTablet`
    padding-top: 20rem;
    width: 100%;
    max-width: 100%;
  `}
`;

export const Card = styled.div`
  width: 850rem;
  border-radius: 10rem;
  margin-top: 60rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;

  ${respondTo.smallTablet`
    padding: 20rem;
    max-width: 100%;
    width: 100%;
    margin-top: 0;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;

  ${respondTo.smallTablet`
    margin-top: 20rem;
    gap: 20rem;
  `}
`;

export const TopContainer = styled.div``;

export const Margin = styled.div`
  margin-right: ${marginMd};

  ${respondTo.smallTablet`
    margin-right: 0;
  `}
`;

export const RowMarginTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${marginMd};

  ${respondTo.smallTablet`
    flex-direction: column;
    margin: 20rem 0;
  `}
`;

export const SelectInputWrapper = styled.div`
  width: 203rem;
  margin-top: -3rem;

  ${respondTo.smallTablet`
    max-width: 100%;
    width: 100%;
    margin-top: 0;
  `}
`;
