import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { IBreadcrumbsConfig } from './types';
import { RoutesConfig } from 'navigation/routes';
import { SinglePurchaseOrderRoutes } from 'navigation/SinglePurchaseOrder/SinglePurchaseOrder.routes';
import { SingleQuotationRoutes } from 'navigation/SingleQuotation/SingleQuotation.routes';
import { FwSingleAppointmentRoutes } from 'navigation/FwSingleAppointment/FwSingleAppointment.routes';
import { SingleSalesOrderRoutes } from 'navigation/SingleSalesOrder/SingleSalesOrder.routes';
import { SingleSalesInvoiceRoutes } from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';
import { SalesCreditInvoiceRoutes } from 'navigation/SingleInvoice/SalesCreditInvoice/SingleSalesCreditInvoice.routes';
import { SalesPurchaseOrderInvoiceRoutes } from 'navigation/SingleInvoice/PurchaseInvoice/SinglePurchaseInvoice.routes';
import { getAppointmentTypeLabel } from 'pages/FieldWorker/FwSingleAppointmentPage/helpers';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import { SingleSupplierRoutes } from 'navigation/SingleSupplier/SingleSupplier.routes';

// To add a new breadcrumbs configuration:
// [ROUTE_PATH]: {
//   breadcrumbs: 'Breadcrumb Title', // Static breadcrumb title
//   dynamicSegment: {
//     key: 'ReactQueryKey', // React Query key to fetch data
//     property: 'path.to.property', // Path to the property in the fetched data
//     transformation: (queryData: any) => { // Custom transformation for dynamic part of breadcrumbs
//       return `${getAppointmentTypeLabel(queryData?.appointment)} #${queryData?.appointment?.id}`;
//     },
//   },
// },

export const breadcrumbsConfig: Record<string, IBreadcrumbsConfig> = {
  [RoutesConfig.Home.path]: {
    breadcrumbs: 'Home',
  },

  // QUOTATION
  [RoutesConfig.NewQuotation.fullPath]: {
    breadcrumbs: 'Create quotation',
  },
  [RoutesConfig.SingleQuotation.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_QUOTATION,
      property: 'number',
    },
  },
  [SingleQuotationRoutes.SingleQuotationNewComment.fullPath]: {
    breadcrumbs: 'Add new comment',
  },

  // SALES ORDERS
  [RoutesConfig.NewSalesOrder.fullPath]: {
    breadcrumbs: 'Create sales order',
  },
  [RoutesConfig.SalesOrders.fullPath]: {
    breadcrumbs: 'Sales Orders',
  },
  [RoutesConfig.SingleSalesOrder.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_SALES_ORDER,
      property: 'number',
    },
  },
  [SingleSalesOrderRoutes.SingleSalesOrderNewComment.fullPath]: {
    breadcrumbs: 'Add new comment',
  },

  // PURCHASE ORDERS
  [RoutesConfig.PurchaseOrders.fullPath]: {
    breadcrumbs: 'Purchase Orders',
  },
  [RoutesConfig.SinglePurchaseOrder.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_PURCHASE_ORDER,
      property: 'number',
    },
  },
  [SinglePurchaseOrderRoutes.SinglePurchaseOrderNewComment.fullPath]: {
    breadcrumbs: 'Add new comment',
  },

  // INVOICES
  [SingleSalesInvoiceRoutes.SingleSalesInvoiceNewCreditInvoice.fullPath]: {
    breadcrumbs: 'Create credit invoice',
  },
  [SingleSalesInvoiceRoutes.SingleSalesInvoiceGeneral.fullPath]: {
    breadcrumbs: 'Invoices',
  },
  [RoutesConfig.SingleSalesInvoice.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_SALES_INVOICE,
      property: 'sales_invoice.invoice_number',
    },
  },
  [SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceGeneral.fullPath]: {
    breadcrumbs: 'Invoices',
  },
  [RoutesConfig.SinglePurchaseInvoice.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_PURCHASE_INVOICE,
      property: 'purchase_invoice.invoice_number',
    },
  },
  [SalesCreditInvoiceRoutes.SingleSalesCreditInvoiceGeneral.fullPath]: {
    breadcrumbs: 'Invoices',
  },
  [RoutesConfig.SingleSalesCreditInvoice.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_SALES_CREDIT_INVOICE,
      property: 'sales_invoice.invoice_number',
    },
  },
  [RoutesConfig.PurchaseCreditInvoice.fullPath]: {
    breadcrumbs: 'Invoices',
  },
  [RoutesConfig.SinglePurchaseCreditInvoice.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_PURCHASE_CREDIT_INVOICE,
      property: 'purchase_invoice.invoice_number',
    },
  },

  // STOCK ITEMS
  [RoutesConfig.StockItems.fullPath]: {
    breadcrumbs: 'Stock Items',
  },
  [RoutesConfig.NewStockItem.fullPath]: {
    breadcrumbs: 'Add stock item',
  },
  [RoutesConfig.SingleStockItem.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_STOCK_ITEM,
      property: 'stock_item.name',
    },
  },

  //CUSTOMERS
  [RoutesConfig.NewCustomer.fullPath]: {
    breadcrumbs: 'Create customer',
  },
  [SingleCustomerRoutes.SingleCustomerGeneral.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_CUSTOMER,
      property: 'customer.name',
    },
  },

  //SUPPLIERS
  [RoutesConfig.NewSupplier.fullPath]: {
    breadcrumbs: 'Create supplier',
  },
  [SingleSupplierRoutes.SingleSupplierGeneral.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_SUPPLIER,
      property: 'supplier.company_name',
    },
  },
  [SingleSupplierRoutes.SingleSupplierNewPurchaseOrder.fullPath]: {
    breadcrumbs: 'Add Purchase Order',
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_SUPPLIER,
      property: 'supplier.company_name',
    },
  },

  // FIELD WORKER
  [FwSingleAppointmentRoutes.FwSingleAppointmentNewComment.fullPath]: {
    breadcrumbs: 'Add new comment',
  },
  [RoutesConfig.FwSingleAppointment.fullPath]: {
    dynamicSegment: {
      key: ReactQueryKeys.GET_SINGLE_FW_APPOINTMENT,
      property: 'appointment.type.name',
      transformation: (queryData: any) => {
        return getAppointmentTypeLabel(queryData?.appointment);
      },
    },
  },
};
