import { COLORS, gapXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const RelativeCanvasWrapper = styled.div`
  position: relative;
  background-color: ${COLORS.CANVAS};
  border-radius: 10rem;
  max-width: 326rem;
  width: 100%;

  ${respondTo.smallTablet`
    max-width: 100%;
  `}
`;

export const SignatureErrorLabel = styled.label`
  position: absolute;
  left: 2rem;
  bottom: -15rem;
  font-weight: 400;
  font-size: 12rem;
  line-height: 10rem;
  color: ${COLORS.RED_100};
`;

export const ClearSignatureLabel = styled.label`
  cursor: pointer;
  position: absolute;
  right: 2rem;
  bottom: -15rem;
  font-weight: 400;
  font-size: 14rem;
  line-height: 10rem;
  color: ${COLORS.PRIMARY};
  text-decoration: underline;
`;

export const ColumnGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${gapXs};

  ${respondTo.smallTablet`
    max-width: 100%;
    width: 100%;
  `}
`;

export const SignatureImage = styled.img`
  width: 350rem;
  height: 250rem;

  ${respondTo.smallTablet`
    max-width: 100%;
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  max-width: 595rem;
  width: 100%;

  ${respondTo.smallTablet`
    max-width: 100%;
  `}
`;

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
`;
