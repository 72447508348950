import { COLORS, borderRadiusSm, marginSm, paddingMd } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 850rem;
  padding: ${paddingMd};
  border-radius: ${borderRadiusSm};
  background-color: ${COLORS.WHITE};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
export const Title = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;
export const ValueWrapperRelative = styled.div`
  position: relative;
  margin-right: ${marginSm};
  height: min-content;
`;

export const EditLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
`;

export const EditIcon = styled.div`
  cursor: pointer;
  position: absolute;
  width: 12rem;
  height: 12rem;
  right: -20rem;
  top: 1rem;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: ${marginSm};
`;

export const CompanyLogoImg = styled.img`
  border-radius: ${borderRadiusSm};
  height: fit-content;
  max-width: 340rem;
`;

export const LoaderAndImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
