import { useTranslation } from 'react-i18next';
import {
  ButtonsWrapper,
  Card,
  CardTitle,
  Column,
  ExternalServiceLogo,
  Label,
  LabelValue,
  MarginTop30,
  RowSpaceBetween,
} from './MollieIntegrationCard.styled';
import { useEffect, useState } from 'react';
import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import { useEditCompanySettings } from '../hooks';
import { toast } from 'react-toastify';
import MollieLogo from 'assets/images/ExternalServices/mollieLogo.png';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IMollieIntegrationCardProps {
  companyId: number;
  initialAPIKey: string;
}

const MollieIntegrationCard = ({
  companyId,
  initialAPIKey,
}: IMollieIntegrationCardProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [APIKey, setAPIKey] = useState<string>(initialAPIKey);

  const { isSmallTablet } = useBreakpointFlag();

  const {
    mutate: editCompanySettings,
    isLoading,
    isSuccess,
  } = useEditCompanySettings(Number(companyId!));

  const handleEditCompanySettings = () => {
    editCompanySettings({ mollie_api_key: APIKey === '' ? null : APIKey });
  };

  useEffect(() => {
    if (isSuccess) {
      setIsEditing(false);
      toast.success(t('API Key updated'));
    }
  }, [isSuccess]);

  return (
    <Card>
      <RowSpaceBetween>
        <CardTitle>Mollie</CardTitle>
        <ExternalServiceLogo src={MollieLogo} />
      </RowSpaceBetween>
      {isEditing ? (
        <Input
          disabled={isLoading}
          placeholder={t('API Key')}
          value={APIKey}
          onChange={(e: any) => setAPIKey(e.target.value)}
          wrapperStyles={{
            marginTop: '12rem',
            width: isSmallTablet ? '100%' : '50%',
          }}
        />
      ) : (
        <Column>
          <Label>{t('API Key')}</Label>
          <LabelValue isRed={!APIKey}>
            {APIKey ? APIKey : t('Undefined')}
          </LabelValue>
        </Column>
      )}
      <MarginTop30>
        <ButtonsWrapper>
          {isEditing && (
            <Button
              disabled={isLoading}
              label={t('Cancel')}
              secondary
              width="150rem"
              onClick={() => {
                setIsEditing(false);
                setAPIKey(initialAPIKey);
              }}
            />
          )}
          <Button
            disabled={isLoading}
            label={isEditing ? t('Save') : t('Change')}
            primary
            width="150rem"
            onClick={() => {
              if (isEditing) {
                handleEditCompanySettings();
              } else {
                setIsEditing(true);
              }
            }}
          />
        </ButtonsWrapper>
      </MarginTop30>
    </Card>
  );
};

export default MollieIntegrationCard;
