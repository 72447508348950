import { DiscountType } from 'types/Discount.types';
import {
  calculateTotalPurchasePriceForSingleProductFormDTO,
  calculateTotalSalesPriceForSingleProductFormDTO,
} from '../ProductTab/ProductList/helpers';
import { ICreateQuotationProductFormDTO } from '../ProductTab/constants';
import { ICreateQuotationOrderDetails } from './constants';
import { calculateInitialDownpayment } from '../helpers';
import { Option } from 'components/Select/type';
import { IPaymentCondition } from 'types/PaymentCondition.types';

export const calculateTotalWorkingHoursPrice = (
  orderDetails: ICreateQuotationOrderDetails
) => {
  const totalWorkingHoursPriceWithoutDiscount =
    Number(orderDetails.workingHoursRate) * Number(orderDetails.workingHours);
  let totalWorkingHoursPriceWithDiscount;
  if (orderDetails.workingHoursDiscountType === DiscountType.PERCENTAGE) {
    totalWorkingHoursPriceWithDiscount =
      totalWorkingHoursPriceWithoutDiscount *
      ((100 - Number(orderDetails.workingHoursDiscount)) / 100);
  } else {
    totalWorkingHoursPriceWithDiscount =
      totalWorkingHoursPriceWithoutDiscount -
      Number(orderDetails.workingHoursDiscount);
  }
  const totalWorkingHoursPrice = totalWorkingHoursPriceWithDiscount
    .toFixed(2)
    .toString();

  return isNaN(totalWorkingHoursPrice as any) ? '0.00' : totalWorkingHoursPrice;
};

export const calculateSalesPriceExcludingDiscount = (
  orderDetails: ICreateQuotationOrderDetails,
  productFormDtos: ICreateQuotationProductFormDTO[]
) => {
  let salesPriceExcludingDiscount = 0;
  for (const productFormDto of productFormDtos) {
    salesPriceExcludingDiscount += Number(
      calculateTotalSalesPriceForSingleProductFormDTO(productFormDto)
    );
  }
  return (
    Number(calculateTotalWorkingHoursPrice(orderDetails)) +
    Number(salesPriceExcludingDiscount)
  )
    .toFixed(2)
    .toString();
};

export const calculateSalesPriceWithDiscount = (
  orderDetails: ICreateQuotationOrderDetails,
  productFormDtos: ICreateQuotationProductFormDTO[]
) => {
  const salesPriceExcludingDiscount = calculateSalesPriceExcludingDiscount(
    orderDetails,
    productFormDtos
  );
  let totalSalesPrice = 0;
  if (orderDetails.discountType === DiscountType.PERCENTAGE) {
    totalSalesPrice =
      Number(salesPriceExcludingDiscount) *
      ((100 - Number(orderDetails.discount)) / 100);
  } else {
    totalSalesPrice =
      Number(salesPriceExcludingDiscount) - Number(orderDetails.discount);
  }
  return totalSalesPrice.toFixed(2).toString();
};

export const calculateTotalPurchasePrice = (
  productFormDtos: ICreateQuotationProductFormDTO[]
) => {
  let totalPurchasePrice = 0;
  for (const productFormDto of productFormDtos) {
    totalPurchasePrice += Number(
      calculateTotalPurchasePriceForSingleProductFormDTO(productFormDto)
    );
  }
  return totalPurchasePrice.toFixed(2).toString();
};

export const validateDownpayment = (
  downpayment: any,
  salesPriceWithDiscount: number,
  setFieldError: any
) => {
  if (downpayment !== '') {
    if (downpayment < 0) {
      setFieldError('downPayment', 'Down payment must be larger than 0');
      return false;
    }
    if (downpayment > salesPriceWithDiscount) {
      setFieldError(
        'downPayment',
        'Down payment cant be larger than sales price'
      );
      return false;
    }
  }
  return true;
};

export const calculateAndSetDownpayment = (
  orderDetails: ICreateQuotationOrderDetails,
  productFormDtos: ICreateQuotationProductFormDTO[],
  downpaymentThreshold: string,
  downpaymentPercentage: string,
  setFieldValue: any
) => {
  if (!downpaymentThreshold && !downpaymentPercentage) {
    return;
  }
  const salesPriceWithDiscount = calculateSalesPriceWithDiscount(
    orderDetails,
    productFormDtos
  );
  const downPayment = calculateInitialDownpayment(
    salesPriceWithDiscount,
    Number(downpaymentThreshold),
    Number(downpaymentPercentage)
  ).toFixed(2);

  if (Number(salesPriceWithDiscount) <= Number(downPayment)) {
    setFieldValue('downPayment', 0);
  } else {
    setFieldValue('downPayment', downPayment);
  }
};

export const generatePaymentConditionOptionOptionFromPaymentCondition = (
  paymentCondition: IPaymentCondition
): Option => {
  return {
    value: paymentCondition?.id,
    label: paymentCondition?.name,
    text: paymentCondition?.text,
    altText: paymentCondition?.alt_text,
    downpaymentThreshold: paymentCondition?.downpayment_threshold,
    downpaymentPercentage: paymentCondition?.downpayment_percentage,
  };
};
