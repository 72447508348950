import { marginLg, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-width: 1075rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};
  gap: 30rem;

  ${respondTo.smallTablet`
    max-width: calc(100% - 30rem);
  `}
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  margin-top: ${marginLg};
  max-width: 850rem;
  width: 100%;

  ${respondTo.smallTablet`
    margin-top: 20rem;
  `}
`;
