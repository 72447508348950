import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ApplyWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Label,
  RowSpaceBetween,
  Title,
  TitleContainer,
} from './EditEmailCompanySettingsModal.styled';
import { Input } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { editEmailCompanySettingsSchema } from './validation';
import { toast } from 'react-toastify';
import { transformEmptyValues } from 'utils/transformEmptyValues';
import { useEditCompanySettings } from '../hooks';
import { IEditCompanySettingsDTO } from 'types/Admin.types';
import Tooltip from 'components/Tooltip/Tooltip';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IEditEmailCompanySettingsModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  settingsData: any;
  companyId: string;
}
export const EditEmailCompanySettingsModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  settingsData,
  companyId,
}: IEditEmailCompanySettingsModalProps) => {
  const { t } = useTranslation();
  const { isPhone, isSmallPhone } = useBreakpointFlag();

  const {
    mutate: editCompanySettings,
    isSuccess,
    isLoading,
  } = useEditCompanySettings(Number(companyId));

  useEffect(() => {
    if (isSuccess) {
      toast.success(t('Company settings updated'));
      onCancel();
    }
  }, [isSuccess]);

  const handleEditCompanySettings = (values: any) => {
    const editCompanySettingsDTO: IEditCompanySettingsDTO = {
      bcc_email_address: values.bcc_email_address,
      invoice_bcc_email_address: values.invoice_bcc_email_address,
      sales_order_products_definitive_email_note:
        values.sales_order_products_definitive_email_note,
      field_work_notification_email: values.field_work_notification_email,
      quotation_notification_email: values.quotation_notification_email,
      email_footer_email: values.email_footer_email,
    };
    editCompanySettings(editCompanySettingsDTO);
  };

  const getInitialValues = () => {
    return {
      sender_email: settingsData?.sender_email as string,
      bcc_email_address: settingsData?.bcc_email_address as string,
      invoice_bcc_email_address:
        settingsData?.invoice_bcc_email_address as string,
      sales_order_products_definitive_email_note:
        settingsData?.sales_order_products_definitive_email_note as string,
      field_work_notification_email:
        settingsData?.field_work_notification_email as string,
      quotation_notification_email:
        settingsData?.quotation_notification_email as string,
      email_footer_email: settingsData?.email_footer_email as string,
    };
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        overflow: 'visible',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{t('Company email settings')}</Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={getInitialValues()}
          validationSchema={editEmailCompanySettingsSchema}
        >
          {({ values, setFieldValue, handleBlur, errors, isValid }) => {
            const handleBlurFunction = (e: any, name: string) => {
              const value = e.target.value.trim();
              setFieldValue(name, value);
              handleBlur(e);
            };
            return (
              <Container>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Sender email').toUpperCase()}</H4>
                    </Label>
                    <Tooltip
                      content={t('This field can be editable only by CodeFlex')}
                    >
                      <Input
                        disabled
                        errorMessage={
                          errors['sender_email'] ? errors['sender_email'] : ''
                        }
                        onBlur={(e) => {
                          handleBlurFunction(e, 'sender_email');
                        }}
                        height={'41rem'}
                        name="sender_email"
                        wrapperStyles={{
                          width: isPhone ? '100%' : '300rem',
                        }}
                      />
                    </Tooltip>
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Email copy').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['bcc_email_address']
                          ? errors['bcc_email_address']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'bcc_email_address');
                      }}
                      height={'41rem'}
                      name="bcc_email_address"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Invoice copy').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['invoice_bcc_email_address']
                          ? errors['invoice_bcc_email_address']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'invoice_bcc_email_address');
                      }}
                      height={'41rem'}
                      name="invoice_bcc_email_address"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>
                        {t('Order definitive default note').toUpperCase()}
                      </H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['sales_order_products_definitive_email_note']
                          ? errors['sales_order_products_definitive_email_note']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(
                          e,
                          'sales_order_products_definitive_email_note'
                        );
                      }}
                      height={'41rem'}
                      name="sales_order_products_definitive_email_note"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>
                        {t('Field worker notification email').toUpperCase()}
                      </H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['field_work_notification_email']
                          ? errors['field_work_notification_email']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'field_work_notification_email');
                      }}
                      height={'41rem'}
                      name="field_work_notification_email"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Quotation notification email').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['quotation_notification_email']
                          ? errors['quotation_notification_email']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'quotation_notification_email');
                      }}
                      height={'41rem'}
                      name="quotation_notification_email"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Label>
                      <H4>{t('Email content footer address').toUpperCase()}</H4>
                    </Label>
                    <Input
                      errorMessage={
                        errors['email_footer_email']
                          ? errors['email_footer_email']
                          : ''
                      }
                      onBlur={(e) => {
                        handleBlurFunction(e, 'email_footer_email');
                      }}
                      height={'41rem'}
                      name="email_footer_email"
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Cancel')}
                  />
                  <Button
                    disabled={!isValid || isLoading}
                    onClick={() => {
                      if (isValid) {
                        handleEditCompanySettings(transformEmptyValues(values));
                      }
                    }}
                    primary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
