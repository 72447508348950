import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Margin,
  Description,
} from './LeavePageModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Modal } from '../Modal';
import { Dispatch, SetStateAction } from 'react';
import { Level } from '../type';

const PWID = 'leave-page-modal';

interface ILeavePageModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onYes: () => void;
  onNo: () => void;
  description?: string;
  title: string;
  level?: Level;
  disabled?: boolean;
  pwId?: string;
}

export const LeavePageModal = ({
  isOpen,
  setIsOpen,
  onYes,
  onNo,
  description,
  title,
  level = 'FIRST',
  disabled,
  pwId,
}: ILeavePageModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', position: 'fixed', zIndex: '10000' }}
      level={level}
      onBackDropClick={() => onNo()}
    >
      <TopContainer>
        <CloseContainer>
          <Close
            pwId={`${pwId}-${PWID}`}
            onClick={() => {
              onNo && onNo();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title data-testid={`${pwId}-${PWID}-title`}>{title}</Title>
        </TitleContainer>
        <TitleContainer>
          <Description data-testid={`${pwId}-${PWID}-description`}>
            {description}
          </Description>
        </TitleContainer>
      </TopContainer>
      <ButtonContainer>
        <Margin>
          <Button
            pwId={`${pwId}-${PWID}-no-button`}
            disabled={disabled}
            onClick={() => {
              onNo && onNo();
            }}
            secondary
            width="200rem"
            label={t('No')}
          />
        </Margin>
        <Button
          pwId={`${pwId}-${PWID}-yes-button`}
          disabled={disabled}
          primary
          width="200rem"
          label={t('Yes')}
          onClick={() => {
            onYes && onYes();
          }}
        />
      </ButtonContainer>
    </Modal>
  );
};
