import { COLORS, H3 } from 'assets/styled';
import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import { formatSuppliersTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ISupplier, ISupplierTableDTO } from 'types/Supplier.types';
import { SUPPLIERS_PER_PAGE, tableConfig } from './constants';
import {
  useGetSuppliers,
  useGetSuppliersPageFilters,
  useManageAndSaveFilters,
} from './hooks';
import {
  AdvancedFilterWrapper,
  ButtonWrapper,
  Container,
  CreateSupplierButton,
  FlexAlignCenter,
  Row,
  RowRightPart,
  SelectInputWrapper,
  TitleContainer,
} from './SuppliersPage.styled';
import { Option } from 'components/Select/type';
import { Select } from 'components/Select/Select';
import { getStatusValue } from './helpers';
import { useDebounce } from 'utils/hooks/useDebounce';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { Funnel, MagnifyingGlass } from '@phosphor-icons/react';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useState } from 'react';
import { useGetSuppliersAdvancedFilters } from './advancedFilters';
import { RoutesConfig } from 'navigation/routes';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { formatInitialAdvancedFilters } from 'components/Modal/AdvancedFilterModal/helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import Icon from 'components/Icon/Icon';
import FilterMobileModal from './FilterMobileModal/FilterMobileModal';

const PWID = 'suppliers-page';

const SuppliersPage = () => {
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const canCreateSupplier = useCan(Actions.EDIT_PURCHASE_ORDER);
  const currencySymbol = useGetCurrencySymbol();
  const [isMobileFilterModalOpen, setIsMobileFilterModalOpen] =
    useState<boolean>(false);

  const { isSmallTablet } = useBreakpointFlag();

  const { suppliersPageFilters, statusOptions } = useGetSuppliersPageFilters();

  const filtersData = useManageAndSaveFilters(suppliersPageFilters);

  const {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOption,
    setSelectedStatusOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    advancedFilters,
    setAdvancedFilters,
  } = filtersData;

  const debouncedSearchBy = useDebounce(searchBy);

  const { isLoading, data } = useGetSuppliers(
    page,
    SUPPLIERS_PER_PAGE,
    debouncedSearchBy,
    getStatusValue(selectedStatusOption.value),
    sortBy,
    sortDirection,
    advancedFilters
  );

  let tableData: ISupplierTableDTO[] = [];
  if (data) {
    tableData = formatSuppliersTableData(
      data.suppliers
        .map((supplier: ISupplier[]) => {
          return supplier;
        })
        .flat(),
      currencySymbol
    );
  }

  const handleViewButtonClick = (rowData: ISupplierTableDTO) => {
    navigate(`${rowData.navigationID}`);
  };

  const { filterTypes } = useGetSuppliersAdvancedFilters();

  return (
    <Container>
      <TitleContainer>
        <H3 data-testid={`${PWID}-overview-title`}>{t('Overview')}</H3>
        <ButtonWrapper>
          <Button
            pwId={`${PWID}-create-supplier-button`}
            onClick={() => navigate(RoutesConfig.NewSupplier.fullPath)}
            label={t('Create supplier')}
            width="200rem"
            height="41rem"
            primary
            disabled={!canCreateSupplier}
          />
        </ButtonWrapper>
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId={`${PWID}-search-input`}
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={isSmallTablet ? '100%' : '400rem'}
            wrapperStyles={{ width: isSmallTablet ? '100%' : '' }}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />

          {isSmallTablet && (
            <Icon
              svg={Funnel}
              size={30}
              color={COLORS.PRIMARY}
              weight="regular"
              onClick={() => setIsMobileFilterModalOpen(true)}
            />
          )}
        </FlexAlignCenter>
        <RowRightPart>
          <SelectInputWrapper>
            <Select
              pwId={PWID}
              defaultValue={
                suppliersPageFilters.selectedStatusOption
                  ? suppliersPageFilters.selectedStatusOption
                  : statusOptions[0]
              }
              name="status"
              placeholder={t('Status')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => setSelectedStatusOption(e)}
              options={statusOptions}
            />
          </SelectInputWrapper>
        </RowRightPart>
      </Row>
      {isSmallTablet && (
        <CreateSupplierButton>
          <Button
            pwId={`${PWID}-create-supplier-button`}
            onClick={() => navigate(RoutesConfig.NewSupplier.fullPath)}
            label={t('Create supplier')}
            width="100%"
            height="41rem"
            primary
            disabled={!canCreateSupplier}
          />
        </CreateSupplierButton>
      )}
      <AdvancedFilterWrapper>
        <Button
          pwId={`${PWID}-advanced-filter-button`}
          onClick={() => setIsAdvancedFilterModalOpen(true)}
          label={t('Advanced filter')}
          width="145rem"
          height="31rem"
          secondary={!advancedFilters}
          primary={!!advancedFilters}
        />
      </AdvancedFilterWrapper>
      <Table
        pwId={PWID}
        isLoading={isLoading}
        page={page}
        perPage={SUPPLIERS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
      />
      <AdvancedFilterModal
        pwId={PWID}
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
        initialFilterGroups={
          advancedFilters
            ? formatInitialAdvancedFilters(advancedFilters)
            : undefined
        }
      />

      {isSmallTablet && (
        <FilterMobileModal
          isOpen={isMobileFilterModalOpen}
          setIsOpen={setIsMobileFilterModalOpen}
          onCancel={() => setIsMobileFilterModalOpen(false)}
          filtersData={filtersData}
          statusOptions={statusOptions}
          setIsAdvancedFilterModalOpen={setIsAdvancedFilterModalOpen}
          advancedFilters={advancedFilters}
          setSelectedStatusOption={setSelectedStatusOption}
        />
      )}
    </Container>
  );
};

export default SuppliersPage;
