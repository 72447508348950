import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { IFwAppointmentsPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';
import { FwAppointmentsViewType } from './FwAppointmentsPage';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { useEffect, useRef, useState } from 'react';
import { Option } from 'components/Select/type';
import { SortDirection } from 'components/Table/constants';

const useGetFwAppointmentTypeOptions = () => {
  const options: Option[] = [
    { value: '', label: 'All', key: 'all' },
    { value: '1', label: 'Measuring', key: 'Measuring' },
    { value: '2', label: 'Installation', key: 'Installation' },
    { value: '4', label: 'Service', key: 'Service' },
  ];
  return options;
};

export const useGetFwAppointmentsPageFilters = () => {
  const dispatch = useDispatch();
  const fwAppointmentsPageFilters: IFwAppointmentsPageFilters | null =
    useSelector(
      (state: IRootReducerState) => state.filtersInfo.fwAppointmentsPage
    );
  const appointmentTypeOptions = useGetFwAppointmentTypeOptions();

  if (!fwAppointmentsPageFilters) {
    const initialFwAppointmentsPageFilters: IFwAppointmentsPageFilters = {
      searchBy: '',
      sortBy: undefined,
      sortDirection: undefined,
      startDate: moment(),
      endDate: moment().add(1, 'week'),
      selectedAppointmentTypeOption: appointmentTypeOptions[0],
      selectedViewType: FwAppointmentsViewType.CALENDAR,
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.FW_APPOINTMENTS,
        data: initialFwAppointmentsPageFilters,
      })
    );
    return {
      fwAppointmentsPageFilters: initialFwAppointmentsPageFilters,
      appointmentTypeOptions,
    };
  }

  return {
    fwAppointmentsPageFilters,
    appointmentTypeOptions,
  };
};

export const useManageAndSaveFilters = (
  initialFilters: IFwAppointmentsPageFilters
) => {
  const dispatch = useDispatch();

  // State for each filter
  const [searchBy, setSearchBy] = useState<string>(initialFilters.searchBy);

  const [startDate, setStartDate] = useState<Moment>(
    moment(initialFilters.startDate)
  );
  const [endDate, setEndDate] = useState<Moment>(
    moment(initialFilters.endDate)
  );

  const [filterDate, setFilterDate] = useState<string>(
    initialFilters.filterDate || ''
  );

  const [selectedAppointmentTypeOption, setSelectedAppointmentTypeOption] =
    useState<Option>(initialFilters.selectedAppointmentTypeOption);

  const [selectedViewType, setSelectedViewType] =
    useState<FwAppointmentsViewType>(initialFilters.selectedViewType);

  const [sortBy, setSortBy] = useState<string | undefined>(
    initialFilters.sortBy
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialFilters.sortDirection
  );

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<IFwAppointmentsPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  useEffect(() => {
    filtersRef.current = {
      searchBy,
      sortBy,
      sortDirection,
      startDate,
      endDate,
      selectedAppointmentTypeOption,
      selectedViewType,
      filterDate,
    };
  }, [
    searchBy,
    sortBy,
    sortDirection,
    startDate,
    endDate,
    selectedAppointmentTypeOption,
    selectedViewType,
    filterDate,
  ]);

  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.FW_APPOINTMENTS,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    searchBy,
    setSearchBy,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedAppointmentTypeOption,
    setSelectedAppointmentTypeOption,
    selectedViewType,
    setSelectedViewType,
    filterDate,
    setFilterDate,
  };
};
