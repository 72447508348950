import Button from 'components/Button/Button';
import { useGetProducts } from 'pages/Manager/NewQuotationPage/ProductTab/AddNewProduct/hooks';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ButtonWrapper,
  Container,
  FlexAlignCenter,
  Heading,
  ProductsWrapper,
  RowSpaceBetween,
  SelectInputWrapper,
} from './SingleSupplierProductsPage.styled';
import { Plus, MagnifyingGlass } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import AddEditProduct from './AddEditProduct/AddEditProduct';
import { IAddEditProduct } from './AddEditProduct/types';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { useDeleteProduct } from './hooks';
import { IProduct } from 'types/Product.types';
import Input from 'components/Input/Input';
import { useDebounce } from 'utils/hooks/useDebounce';
import { SimpleActionsTable } from 'components/SimpleActionTable/SimpleActionsTable';
import { formatTableData } from './helpers';
import { tableConfig } from './constants';
import { Option } from 'components/Select/type';
import { Select } from 'components/Select/Select';
import { useManageProductGroupsSelect } from './AddEditProduct/hooks';
import CustomMenuList from 'components/Select/SelectComponents/CustomMenuList/CustomMenuList';
import { Pen } from '@phosphor-icons/react';
import { RoutesConfig } from 'navigation/routes';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const PRODUCTS_PER_PAGE = 4;
const PWID = 'single-supplier-products-page';

export const SingleSupplierProductsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const [page, setPage] = useState<number>(1);
  const [searchBy, setSearchBy] = useState<string>('');

  const debouncedSearchBy = useDebounce(searchBy);

  const [editProduct, setEditProduct] = useState<IAddEditProduct>();
  const [copyProduct, setCopyProduct] = useState<boolean>();
  const [isAddEditProductModalOpen, setIsAddEditProductModalOpen] =
    useState<boolean>(false);
  const [isDeleteProductModalOpen, setIsDeleteProductModalOpen] =
    useState<boolean>(false);
  const [productIdForDeletion, setProductIdForDeletion] = useState<string>('');
  const [products, setProducts] = useState<IAddEditProduct[]>();
  const [selectedProductGroupId, setSelectedProductGroupId] =
    useState<string>();
  const scrollPositionRef = useRef(0);

  const { data } = useGetProducts(
    page,
    PRODUCTS_PER_PAGE,
    debouncedSearchBy,
    id || '',
    selectedProductGroupId
  );
  const { mutate: deleteProduct, isSuccess: isDeleteProductSuccess } =
    useDeleteProduct();

  useEffect(() => {
    if (isDeleteProductSuccess) {
      setIsDeleteProductModalOpen(false);
      setProductIdForDeletion('');
    }
  }, [isDeleteProductSuccess]);

  useEffect(() => {
    if (!data) return;
    const newArray = data?.products?.map((product: IProduct) => {
      const productDto: IAddEditProduct = {
        id: product.id.toString(),
        name: product.name,
        description: product.description,
        attachments: product.attachments,
        logyx_model_id: product.logyx_model_id?.toString(),
        attributes: product.attributes || [],
        docVisibilityOptions: [], // Set during flow; these values are persisted in product.attributes
        digital_order_enabled: product.digital_order_enabled,
        product_group: product.product_group,
      };
      return productDto;
    });
    setProducts(newArray);
  }, [data]);

  const {
    productGroups,
    isLoadingProductGroups,
    hasNextPage,
    fetchNextPage,
    setSearchBy: setProductGroupSearchBy,
  } = useManageProductGroupsSelect();

  const { isPhone } = useBreakpointFlag();

  return (
    <Container>
      <FlexAlignCenter>
        <Input
          pwId={`${PWID}-search-input`}
          icon={MagnifyingGlass}
          placeholder={t('Search')}
          width={isPhone ? '100%' : '400rem'}
          height="41rem"
          value={searchBy}
          onChange={(e) => setSearchBy(e.target.value)}
        />
        <SelectInputWrapper>
          <Select
            name="product_group"
            placeholder={t('Group')}
            isMulti={false}
            isDisabled={false}
            isSearchable={true}
            onChange={(e: Option) => {
              setSelectedProductGroupId(e ? e.value.id : '');
            }}
            onInputChange={(e: any) => setProductGroupSearchBy(e)}
            options={productGroups}
            isLoading={isLoadingProductGroups}
            isClearable
            components={{
              MenuList: (props) => (
                <CustomMenuList
                  {...props}
                  scrollPositionRef={scrollPositionRef}
                  label={t('Manage groups')}
                  onClick={() => {
                    navigate(RoutesConfig.ProductGroups.fullPath);
                  }}
                  onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
                  icon={Pen}
                />
              ),
            }}
          />
        </SelectInputWrapper>
      </FlexAlignCenter>

      <ProductsWrapper>
        <RowSpaceBetween>
          <Heading data-testid={`${PWID}-product-title`}>
            {t('Products')}
          </Heading>
        </RowSpaceBetween>
        <ButtonWrapper>
          <Button
            pwId={`${PWID}-add-new-product-button`}
            label={t('Add new product')}
            link
            icon={Plus}
            sizeIcon={20}
            weightIcon="regular"
            colorIcon={COLORS.PRIMARY}
            onClick={() => {
              setEditProduct(undefined);
              setIsAddEditProductModalOpen(true);
            }}
            fontSize="16rem"
          />
        </ButtonWrapper>

        <SimpleActionsTable
          pwId={PWID}
          page={page}
          perPage={PRODUCTS_PER_PAGE}
          total={data?.total}
          loadPage={(newPage) => setPage(newPage)}
          tableData={formatTableData(data?.products)}
          tableConfig={tableConfig}
          onEdit={(rowData: any) => {
            setCopyProduct(false);
            setEditProduct(
              products?.find(
                (product: IAddEditProduct) => product.id == rowData.id
              )
            );
            setIsAddEditProductModalOpen(true);
          }}
          onDelete={(rowData: any) => {
            setProductIdForDeletion(rowData.id);
            setIsDeleteProductModalOpen(true);
          }}
          onCopy={(rowData: any) => {
            setCopyProduct(true);
            setEditProduct(
              products?.find(
                (product: IAddEditProduct) => product.id == rowData.id
              )
            );
            setIsAddEditProductModalOpen(true);
          }}
        />
      </ProductsWrapper>
      <Modal
        level={'SECOND'}
        isOpen={isAddEditProductModalOpen}
        setIsOpen={setIsAddEditProductModalOpen}
        modalStyle={{
          position: 'fixed',
          margin: 'auto',
          maxWidth: '695rem',
        }}
      >
        {editProduct ? (
          <AddEditProduct
            pwId={PWID}
            copyProduct={copyProduct}
            product={editProduct}
            onCancel={() => setIsAddEditProductModalOpen(false)}
          />
        ) : (
          <AddEditProduct
            pwId={PWID}
            onCancel={() => setIsAddEditProductModalOpen(false)}
          />
        )}
      </Modal>
      <Modal
        level={'SECOND'}
        isOpen={isDeleteProductModalOpen}
        setIsOpen={setIsDeleteProductModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          pwId={PWID}
          submit={{
            onClick: () => {
              productIdForDeletion && deleteProduct(productIdForDeletion);
            },
            text: t('Delete'),
            disabled: false,
          }}
          cancel={{ onClick: () => setIsDeleteProductModalOpen(false) }}
          title={t('Delete Product')}
          description={t('Are you sure you want to delete this product') + '?'}
        />
      </Modal>
    </Container>
  );
};
