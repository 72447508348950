import styled from 'styled-components';
import { COLORS } from './colors';
import { respondTo } from './breakpoints';

export const H1 = styled.h1`
  font-size: 46rem;

  ${respondTo.bigPhone`
    font-size: 24rem;
  `}
`;

export const H2 = styled.h2`
  font-size: 36rem;

  ${respondTo.bigPhone`
    font-size: 20rem;
  `}
`;

export const H3 = styled.h3`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 18rem;
    line-height: 1;
  `}
`;

export const H4 = styled.h4`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
    line-height: 1;
  `}
`;

export const H5 = styled.h5`
  font-size: 16rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const P = styled.p`
  line-height: 20rem;
  color: ${COLORS.BLACK_200};
`;
export const Link = styled.a`
  display: inline-block;
  font-size: 12rem;
  color: ${COLORS.PRIMARY};
`;

export const Label = styled.label`
  color: ${COLORS.BLACK_200};
`;
