import styled from 'styled-components';
import {
  COLORS,
  gapMd,
  gapXs,
  marginMd,
  marginSm,
  paddingSm,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const DatePickerWrap = styled.div`
  padding: 10rem 0;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;

  ${respondTo.smallTablet`
    margin-top: 20rem;
  `}
`;

export const TopContainer = styled.div``;

export const Margin = styled.div`
  margin-right: ${marginSm};
`;

export const StyledWrapper = styled.div`
  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.WHITE};
  width: 100%;
  padding: ${paddingSm};
  justify-content: center;
  align-items: center;
`;

export const UploadFileButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${marginMd};
  margin-bottom: 5rem;
  gap: ${gapMd};
  justify-content: center;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    align-items: center;
    margin-top: 20rem;
    margin-bottom: 10rem;
  `}
`;

export const FileNameLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  color: ${COLORS.PRIMARY};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    margin-bottom: 20rem;
  `}
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 20rem;
    line-height: 1.2;
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: ${gapXs};
`;
