import styled from 'styled-components';
import {
  COLORS,
  borderRadiusSm,
  marginLg,
  marginMd,
  paddingMd,
  paddingXs,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1075rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};
  padding-left: 30rem;
  padding-right: 30rem;

  ${respondTo.smallTablet`
    padding: 0 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 0 15rem;
  `}
`;

export const Title = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  align-self: flex-start;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${COLORS.WHITE};
  height: 100%;
  width: 100%;
  border-radius: ${borderRadiusSm};
  padding-left: ${paddingMd};
  padding-left: ${paddingMd};
  padding-top: ${paddingXs};
  padding-bottom: ${paddingXs};
`;

export const FirstRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: ${marginLg};
  margin-bottom: ${marginMd};

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 10rem;
    align-items: flex-start;
    margin: 20rem 0;
  `}
`;

export const NoContentLabel = styled.label`
  margin-top: 30rem;
  margin-bottom: 30rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
`;

export const SpinnerNoContentWrapper = styled.div`
  display: flex;
  flex: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
