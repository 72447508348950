import { Option } from 'components/Select/type';
import { ITableConfig, ITableRowConfig } from 'components/Table/Table';
import { CellType } from 'components/Table/constants';

export const TODOS_PER_PAGE = 10;
export const RELATED_TYPES_PER_PAGE = 10;

const rowConfigs: ITableRowConfig[] = [
  {
    header: 'toDoTitle',
    flexValue: 1,
    isBlue: false,
    alternativeSortField: 'title',
  },
  {
    header: 'Created by',
    flexValue: 1,
    isBlue: false,
    alternativeSortField: 'created_by_user_id',
  },
  {
    header: 'Type',
    flexValue: 1,
    isBlue: false,
    alternativeSortField: 'type_id',
  },
  { header: 'Created at', flexValue: 1, isBlue: false },
  {
    header: 'Priority',
    flexValue: 1.5,
    isBlue: false,
    cellType: CellType.SELECT,
    alternativeSortField: 'priority_id',
  },
  {
    header: 'Assigned to',
    flexValue: 1.5,
    isBlue: false,
    cellType: CellType.SELECT,
    alternativeSortField: 'assigned_to_user_id',
  },
  {
    header: 'Related',
    flexValue: 1,
    isBlue: true,
    alternativeSortField: 'related_to_number',
  },
  {
    header: 'Finished',
    flexValue: 1,
    isBlue: false,
    cellType: CellType.CHECKBOX,
  },
];

export const tableConfig: ITableConfig = {
  rowConfigs: rowConfigs,
  extraActionsFlexValue: 2,
};

export enum TabsType {
  OPEN = 'Open',
  FINISHED = 'Finished',
  ALL = 'All',
  // MY_TODOS = "My To-Do's",
}

export const TabsOptions = [
  { name: TabsType.OPEN },
  { name: TabsType.FINISHED },
  { name: TabsType.ALL },
  // { name: TabsType.MY_TODOS },
];

export const RelatedTypeOptions: Option[] = [
  {
    value: '1',
    label: 'Quotation',
  },
  {
    value: '2',
    label: 'Sales Order',
  },
  {
    value: '3',
    label: 'Sales Invoice',
  },
  {
    value: '4',
    label: 'Purchase Order',
  },
  {
    value: '5',
    label: 'Customer',
  },
];
