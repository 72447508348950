import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ButtonsWrapper,
  ClearWrapper,
  CloseContainer,
  Container,
  Filters,
  SelectInputWrapper,
  Title,
  TitleContainer,
} from './FilterMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import {
  ArrowWrapper,
  ButtonWrapper,
  ClearText,
  DatePickerWrap,
  IconWrapper,
  ResourcesButton,
  WorkersButton,
} from '../PlanningTab.styled';
import Icon from 'components/Icon/Icon';
import { CaretDown, Factory, User, UsersThree } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import CheckableSelect from 'components/Select/SelectComponents/CheckableSelect/CheckableSelect';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { Option } from 'components/Select/type';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IFilterMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  selectedUsers: string[];
  setIsUsersModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedWorkers: string[];
  setIsWorkersModalOpen: Dispatch<SetStateAction<boolean>>;
  selectedResources: string[];
  setIsResourcesModalOpen: Dispatch<SetStateAction<boolean>>;
  filterDate: string;
  setFilterDate: Dispatch<SetStateAction<string>>;
  resetCounter: number;
  appointmentTypeOptions: Option[];
  appointmentViewOptions: Option[];
  selectedAppointmentTypeOptions: Option[];
  setSelectedAppointmentTypeOptions: Dispatch<SetStateAction<Option[]>>;
  clearFilters: () => void;
  handleViewChange: (e: Option) => void;
  selectedAppointmentViewOption: any;
}

const FilterMobileModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  selectedUsers,
  setIsUsersModalOpen,
  selectedWorkers,
  setIsWorkersModalOpen,
  selectedResources,
  setIsResourcesModalOpen,
  filterDate,
  setFilterDate,
  resetCounter,
  appointmentTypeOptions,
  appointmentViewOptions,
  selectedAppointmentTypeOptions,
  setSelectedAppointmentTypeOptions,
  clearFilters,
  handleViewChange,
  selectedAppointmentViewOption,
}: IFilterMobileModalProps) => {
  const { t } = useTranslation();
  const { isPhone } = useBreakpointFlag();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Filter')}</Title>
        </TitleContainer>

        <Filters>
          <DatePickerWrap>
            <DatePicker
              pwId="date-field"
              width="100%"
              date={moment(filterDate)}
              setDate={(newValue: any) => {
                setFilterDate(newValue?.format('YYYY-MM-DD'));
              }}
              onAccept={(newDate: any) => {
                if (newDate === null) {
                  setFilterDate('');
                }
              }}
            />
          </DatePickerWrap>

          {!isPhone && (
            <SelectInputWrapper>
              <CheckableSelect
                placeholder={t('View')}
                width="100%"
                options={appointmentViewOptions}
                translate
                labelTransform={(string: string) =>
                  capitalizeFirstLetter(string)
                }
                onChange={(options: Option[]) => handleViewChange(options[0])}
                value={
                  selectedAppointmentViewOption
                    ? selectedAppointmentViewOption
                    : appointmentViewOptions[1]
                }
              />
            </SelectInputWrapper>
          )}

          <SelectInputWrapper>
            <CheckableSelect
              width="100%"
              key={`${resetCounter}`}
              placeholder={t('Type')}
              options={appointmentTypeOptions}
              translate
              isMulti
              labelTransform={(string: string) => capitalizeFirstLetter(string)}
              onChange={(options: Option[]) =>
                setSelectedAppointmentTypeOptions(options)
              }
              defaultValues={
                selectedAppointmentTypeOptions
                  ? selectedAppointmentTypeOptions
                  : [appointmentTypeOptions[0]]
              }
            />
          </SelectInputWrapper>

          <ButtonWrapper>
            <IconWrapper>
              <Icon svg={User} size={20} color={COLORS.BLACK} />
            </IconWrapper>
            <WorkersButton
              label={t('Users')}
              width="100%"
              secondary
              onClick={() => setIsUsersModalOpen(true)}
              active={selectedUsers?.length}
            />
            <ArrowWrapper>
              <Icon svg={CaretDown} color={COLORS.BLACK} size={15} />
            </ArrowWrapper>
          </ButtonWrapper>
          <ButtonWrapper>
            <IconWrapper>
              <Icon svg={UsersThree} size={20} color={COLORS.BLACK} />
            </IconWrapper>
            <WorkersButton
              label={t('Workers')}
              width="100%"
              secondary
              onClick={() => setIsWorkersModalOpen(true)}
              active={selectedWorkers?.length}
            />
            <ArrowWrapper>
              <Icon svg={CaretDown} color={COLORS.BLACK} size={15} />
            </ArrowWrapper>
          </ButtonWrapper>
          <ButtonWrapper>
            <IconWrapper>
              <Icon svg={Factory} size={20} color={COLORS.BLACK} />
            </IconWrapper>
            <ResourcesButton
              onClick={() => setIsResourcesModalOpen(true)}
              label={t('Resources')}
              width="100%"
              secondary
              active={selectedResources?.length}
            />
            <ArrowWrapper>
              <Icon svg={CaretDown} color={COLORS.BLACK} size={15} />
            </ArrowWrapper>
          </ButtonWrapper>
        </Filters>

        <ClearWrapper>
          <ClearText onClick={() => clearFilters()}>
            {t('Clear filters')}
          </ClearText>
        </ClearWrapper>

        <ButtonsWrapper>
          <Button
            onClick={() => setIsOpen(false)}
            label={t('Finish')}
            width="100%"
            height="40rem"
            primary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default FilterMobileModal;
