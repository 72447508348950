import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ButtonsWrapper,
  CloseContainer,
  Container,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import { StockItemType } from '../constants';

interface IActionsMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  setIsAddDefaultFieldsModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsDeleteModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsActivateStockItemModalOpen: Dispatch<SetStateAction<boolean>>;
  handleCreatePurchaseOrder: () => void;
  canCreatePurchaseOrder: boolean;
  canDeleteStock: boolean;
}

const ActionsMobileModal = ({
  isOpen,
  setIsOpen,
  data,
  setIsAddDefaultFieldsModalOpen,
  setIsDeleteModalOpen,
  setIsActivateStockItemModalOpen,
  handleCreatePurchaseOrder,
  canCreatePurchaseOrder,
  canDeleteStock,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{data?.stock_item?.name}</Title>
        </TitleContainer>
        <ButtonsWrapper>
          <Button
            width="100%"
            onClick={() => setIsAddDefaultFieldsModalOpen(true)}
            label={t('Add default fields')}
            secondary
          />
          {data?.stock_item?.is_active ? (
            <Button
              width="100%"
              onClick={() => setIsDeleteModalOpen(true)}
              label={t('Deactivate stock item')}
              redOutline
              disabled={!canDeleteStock}
            />
          ) : (
            <Button
              width="100%"
              onClick={() => setIsActivateStockItemModalOpen(true)}
              secondary
              label={t('Activate stock item')}
              disabled={!canDeleteStock}
            />
          )}
          {data?.stock_item?.type?.name !== StockItemType.SERVICE && (
            <Button
              width="100%"
              onClick={() => handleCreatePurchaseOrder()}
              label={t('Create purchase order')}
              primary
              disabled={!canCreatePurchaseOrder}
            />
          )}
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
