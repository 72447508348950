import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  AdvancedFilterWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  LabelsFilterWrapper,
  SelectInputWrapper,
  Title,
  TitleContainer,
} from './FilterMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import CheckableSelect from 'components/Select/SelectComponents/CheckableSelect/CheckableSelect';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { Option } from 'components/Select/type';
import Button from 'components/Button/Button';
import { ONGOING_STATUS } from 'pages/Manager/SingleSalesOrder/constants';
import LabelsFilter from 'components/LabelsFilter/LabelsFilter';

const PWID = 'sales-page';

interface IFilterMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  filtersData: any;
  statusOptions: Option[];
  subStatusOptions: Option[];
  paymentStatusOptions: Option[];
  setIsAdvancedFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  advancedFilters: string;
}

const FilterMobileModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  filtersData,
  statusOptions,
  subStatusOptions,
  paymentStatusOptions,
  setIsAdvancedFilterModalOpen,
  advancedFilters,
}: IFilterMobileModalProps) => {
  const { t } = useTranslation();
  const {
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedSubStatusOptions,
    setSelectedSubStatusOptions,
    selectedPaymentStatusOptions,
    setSelectedPaymentStatusOptions,
  } = filtersData;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Filter')}</Title>
        </TitleContainer>

        <SelectInputWrapper>
          <CheckableSelect
            key={JSON.stringify(
              selectedStatusOptions.length
                ? selectedStatusOptions.map((option: Option) => option.value)
                : 'status=select'
            )}
            placeholder={t('Status')}
            isMulti
            width="100%"
            options={statusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => {
              if (!e.find((option) => option.value === ONGOING_STATUS))
                setSelectedSubStatusOptions([]);
              setSelectedStatusOptions(e);
            }}
            defaultValues={selectedStatusOptions}
          />
        </SelectInputWrapper>
        <SelectInputWrapper>
          <CheckableSelect
            key={JSON.stringify(
              selectedSubStatusOptions.length
                ? selectedSubStatusOptions.map((option: Option) => option.value)
                : 'substatus-select'
            )}
            placeholder={t('Substatus')}
            isMulti
            width="100%"
            options={subStatusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => setSelectedSubStatusOptions(e)}
            disabled={
              !selectedStatusOptions?.find(
                (option: Option) => option.value === ONGOING_STATUS
              )
            }
            defaultValues={selectedSubStatusOptions}
          />
        </SelectInputWrapper>
        <SelectInputWrapper>
          <CheckableSelect
            key={JSON.stringify(
              selectedPaymentStatusOptions.length
                ? selectedPaymentStatusOptions.map(
                    (option: Option) => option.value
                  )
                : 'payment-select'
            )}
            placeholder={t('Payment Status')}
            isMulti
            width="100%"
            options={paymentStatusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => setSelectedPaymentStatusOptions(e)}
            defaultValues={selectedPaymentStatusOptions}
          />
        </SelectInputWrapper>
        <AdvancedFilterWrapper>
          <Button
            onClick={() => setIsAdvancedFilterModalOpen(true)}
            label={t('Advanced filter')}
            width="200rem"
            height="40rem"
            secondary={!advancedFilters}
            primary={!!advancedFilters}
          />
        </AdvancedFilterWrapper>
        <LabelsFilterWrapper>
          <LabelsFilter
            pwId={`${PWID}`}
            selectedLabelIds={filtersData.selectedLabelIds}
            setSelectedLabelIds={filtersData.setSelectedLabelIds}
          />
        </LabelsFilterWrapper>
        <ButtonsWrapper>
          <Button
            onClick={() => setIsOpen(false)}
            label={t('Finish')}
            width="100%"
            height="40rem"
            primary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default FilterMobileModal;
