import { Option } from 'components/Select/type';
import { SortDirection } from 'components/Table/constants';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { getPurchaseInvoices } from 'services/Invoice/PurchaseInvoiceService';
import {
  exportSalesInvoicesExcel,
  getSalesInvoices,
} from 'services/Invoice/SalesInvoiceService';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { FiltersPageEnum } from 'store/Filters/constants';
import { IInvoicesPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';
import { useGetSalesInvoiceStatuses } from 'utils/hooks/useGetSalesInvoiceStatuses';
import { InvoicesFilterType } from './constants';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { toast } from 'utils/toast';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { useGetPurchaseInvoiceStatuses } from 'utils/hooks/useGetPurchaseInvoiceStatuses';
import { useGetDateIntervalOptions } from 'utils/hooks/useGetDateIntervalOptions';
import { IAnalyticsNavigationState } from '../AnalyticsPage/types';
import { useLocation } from 'react-router-dom';
import { IExportInvoiceExcelDTO } from './types';

export const useGetSalesInvoices = (
  page: number,
  perPage: number,
  isEnabled = true,
  searchBy?: string,
  filterNotPaid?: boolean,
  statusOptions?: string,
  dateInterval?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string,
  selectedLabelIds?: number[]
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_SALES_INVOICES,
      page,
      perPage,
      searchBy,
      filterNotPaid,
      statusOptions,
      dateInterval,
      sortBy,
      sortDirection,
      advancedFilters,
      selectedLabelIds,
    ],
    queryFn: () => {
      return getSalesInvoices(
        page,
        perPage,
        searchBy,
        filterNotPaid,
        statusOptions,
        dateInterval,
        sortBy,
        sortDirection,
        advancedFilters,
        selectedLabelIds
      );
    },
    enabled: isEnabled,
  });

export const useGetPurchaseInvoices = (
  page: number,
  perPage: number,
  isEnabled = true,
  searchBy?: string,
  filterNotPaid?: boolean,
  statusOptions?: string,
  dateInterval?: string,
  sortBy?: string,
  sortDirection?: string,
  advancedFilters?: string,
  selectedLabelIds?: number[]
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_PURCHASE_INVOICES,
      page,
      perPage,
      searchBy,
      filterNotPaid,
      statusOptions,
      dateInterval,
      sortBy,
      sortDirection,
      advancedFilters,
      selectedLabelIds,
    ],
    queryFn: () => {
      return getPurchaseInvoices(
        page,
        perPage,
        searchBy,
        filterNotPaid,
        statusOptions,
        dateInterval,
        sortBy,
        sortDirection,
        advancedFilters,
        selectedLabelIds
      );
    },
    enabled: isEnabled,
  });

export const useGetInvoicesPageFilters = () => {
  const dispatch = useDispatch();
  const invoicesPageFilters: IInvoicesPageFilters | null = useSelector(
    (state: IRootReducerState) => state.filtersInfo.invoicesPage
  );
  const salesInvoiceStatusOptions = useGetSalesInvoiceStatuses();
  const purchaseInvoiceStatusOptions = useGetPurchaseInvoiceStatuses();
  const dateIntervalOptions = useGetDateIntervalOptions();

  if (!invoicesPageFilters) {
    const initialInvoicesPageFilters: IInvoicesPageFilters = {
      page: 1,
      selectedInvoiceFilterType: InvoicesFilterType.SALES_INVOICES,
      searchBy: '',
      selectedStatusOptions: [],
      selectedDateIntervalOption:
        dateIntervalOptions[dateIntervalOptions.length - 1],
      sortBy: undefined,
      sortDirection: undefined,
      advancedFilters: '',
      selectedLabelIds: [],
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.INVOICES,
        data: initialInvoicesPageFilters,
      })
    );
    return {
      invoicesPageFilters: initialInvoicesPageFilters,
      salesInvoiceStatusOptions,
      purchaseInvoiceStatusOptions,
      dateIntervalOptions,
    };
  }

  return {
    invoicesPageFilters,
    salesInvoiceStatusOptions,
    purchaseInvoiceStatusOptions,
    dateIntervalOptions,
  };
};

export const useManageAndSaveFilters = (
  initialFilters: IInvoicesPageFilters
) => {
  const dispatch = useDispatch();

  // State for each filter
  const [page, setPage] = useState<number>(initialFilters.page);
  const [selectedInvoiceFilterType, setSelectedInvoiceFilterType] =
    useState<InvoicesFilterType>(initialFilters.selectedInvoiceFilterType);

  const [searchBy, setSearchBy] = useState<string>(initialFilters.searchBy);
  const [selectedStatusOptions, setSelectedStatusOptions] = useState<Option[]>(
    initialFilters.selectedStatusOptions
  );
  const [selectedDateIntervalOption, setSelectedDateIntervalOption] =
    useState<Option>(initialFilters.selectedDateIntervalOption);

  const [sortBy, setSortBy] = useState<string | undefined>(
    initialFilters.sortBy
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialFilters.sortDirection
  );
  const [advancedFilters, setAdvancedFilters] = useState<string>(
    initialFilters.advancedFilters
  );
  const [selectedLabelIds, setSelectedLabelIds] = useState<number[]>(
    initialFilters.selectedLabelIds
  );

  // Ref to track initial render
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      // On mount, set initialRender to false
      initialRender.current = false;
    } else {
      // * Reset page count if any other filter changes *
      setPage(1);
    }
  }, [
    selectedInvoiceFilterType,
    searchBy,
    selectedStatusOptions,
    selectedDateIntervalOption,
    sortBy,
    sortDirection,
    advancedFilters,
    selectedLabelIds,
  ]);

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<IInvoicesPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  // Update persisted page count when only page changes
  useEffect(() => {
    filtersRef.current = {
      ...filtersRef.current,
      page,
    };
  }, [page]);
  // Reset persisted page count if any other filter changes
  useEffect(() => {
    filtersRef.current = {
      page: 1,
      selectedInvoiceFilterType,
      searchBy,
      selectedStatusOptions,
      selectedDateIntervalOption,
      sortBy,
      sortDirection,
      advancedFilters,
      selectedLabelIds,
    };
  }, [
    selectedInvoiceFilterType,
    searchBy,
    selectedStatusOptions,
    selectedDateIntervalOption,
    sortBy,
    sortDirection,
    advancedFilters,
    selectedLabelIds,
  ]);

  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.INVOICES,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    page,
    setPage,
    selectedInvoiceFilterType,
    setSelectedInvoiceFilterType,
    searchBy,
    setSearchBy,
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedDateIntervalOption,
    setSelectedDateIntervalOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    advancedFilters,
    setAdvancedFilters,
    selectedLabelIds,
    setSelectedLabelIds,
  };
};

export const useExportSalesInvoicesExcel = () => {
  const { t } = useTranslation();
  return useMutation(
    (exportExcelData: IExportInvoiceExcelDTO) =>
      exportSalesInvoicesExcel(exportExcelData),
    {
      onSuccess: () => {
        toast.success(t('A download link is sent. Please check your email.'));
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.EXPORT_SALES_INVOICE_EXCEL,
        });
      },
      mutationKey: ReactMutationKeys.EXPORT_SALES_INVOICE_EXCEL,
    }
  );
};

export const useHandleAnalyticsNavigationState = (
  setSelectedDateIntervalOption: Dispatch<SetStateAction<Option>>
) => {
  const { state }: { state: IAnalyticsNavigationState } = useLocation();
  useEffect(() => {
    if (state?.dateIntervalOption) {
      setSelectedDateIntervalOption(state.dateIntervalOption);
    }
  }, []);
};
