import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, marginMd, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  $flexValue: string;
  $isBlueColor: boolean;
  $selected: boolean;
  $isRed: boolean;
}

export const Table = styled.div`
  ${respondTo.bigPhone`
    max-width: 100%;
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  padding: 10rem 20rem;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.GREY_500};
  border-radius: ${borderRadiusSm};
`;

export const HeaderCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 0 5rem;

  ${respondTo.smallTablet`
    padding: 0;
    min-height: 30rem;
    align-items: center;
    justify-content: flex-start;
  `}

  ${(props: StyledProps) =>
    props.$flexValue &&
    css`
      flex: ${props.$flexValue};
    `};
`;

export const HeaderLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GREY_500};
  padding: 15rem;

  &:last-of-type {
    border: none;
  }

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : `none`};
`;

export const BodyCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 1;
  padding: 0 5rem;
`;

export const BodyLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;

  ${(props: StyledProps) =>
    props.$isBlueColor &&
    css`
      color: ${COLORS.PRIMARY};
    `};
`;

export const ActionHeaderCell = styled.div`
  display: flex;
  width: 100rem;
  justify-content: flex-end;
  text-decoration: underline;
  color: ${COLORS.PRIMARY};
  cursor: pointer;

  ${(props: StyledProps) =>
    props.$isRed &&
    css`
      color: ${COLORS.RED_100};
    `};
`;

export const ActionCell = styled.div`
  display: flex;
  width: 100rem;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  padding: 20rem;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const Margin = styled.div`
  margin-left: ${marginXs};
`;

export const MobileAction = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20rem;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 4%;
`;

export const Card = styled.div`
  max-width: 48%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${COLORS.GREY_300};
  margin-bottom: 20rem;
  justify-content: space-between;
  position: relative;
  padding: 5rem 10rem;
  padding-bottom: 20rem;
  border-radius: 5rem;

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : `none`};

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &:nth-child(2n) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -4%;
      background-color: ${COLORS.GREY_300};
      width: 1px;
      height: 80%;

      ${respondTo.bigPhone`
        display: none;
      `}
    }
  }

  ${respondTo.bigPhone`
    max-width: 100%;

     &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  `}
`;

export const CardLeftSide = styled.div`
  max-width: 50%;
  width: 100%;
`;
export const CardRightSide = styled.div`
  max-width: 50%;
  width: 100%;
`;
export const CardValueWrapper = styled.div`
  min-height: 30rem;
  display: flex;
  align-items: center;
`;
export const CardValue = styled.label`
  font-weight: 300;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${(props: StyledProps) =>
    props.$isBlueColor &&
    css`
      cursor: pointer;
      color: ${COLORS.PRIMARY};
    `};
`;
