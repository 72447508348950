import { COLORS, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${respondTo.midTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  padding: 30rem;
  margin: 0 auto 30rem;

  ${respondTo.midTablet`
    width: 100%;
    max-width: 100%;
  `}

  ${respondTo.bigPhone`
    flex-direction: column;
    margin-bottom: 20rem;
  `}
`;

export const Label = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const CostContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20rem;
`;

export const CostLabel = styled.div`
  font-size: 18rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  color: ${COLORS.PRIMARY};
`;

export const CostLabelTitle = styled.div`
  font-size: 14rem;
  font-weight: 600;
  line-height: 21rem;
  width: 100rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const TypeLabel = styled.h4`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  color: ${COLORS.GREY_1000};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20rem;
`;

export const CheckboxContainer = styled.div`
  justify-content: start;
  align-items: center;
  margin-bottom: ${marginMd};

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;
