import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm, marginSm, marginXs, paddingMd } from 'assets/styled';
import Button from 'components/Button/Button';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.WHITE};
  margin-top: 75rem;
  right: 40rem;
  top: 10rem;
  padding-left: ${paddingMd};
  padding-right: ${paddingMd};
  border-radius: ${borderRadiusSm};
  z-index: 100;
  border: 1rem solid ${COLORS.GREY_200};
  min-width: 250rem;

  ${respondTo.smallTablet`
    margin-top: 50rem;
    right: 20rem;
    width: 350rem;
  `}
`;

export const Version = styled.span`
  position: absolute;
  bottom: 0rem;
  right: 2rem;
  color: ${COLORS.BLACK};
  opacity: 0;

  &:hover {
    opacity: 1;
  }
`;

export const ImageAvatar = styled.img`
  width: fit-content;
  max-width: 2.43vw;
  height: 2.43vw;
  min-width: 35rem;
  min-height: 35rem;
`;

export const ImageAvatarWrapper = styled.div`
  width: 2.43vw;
  height: 2.43vw;
  min-width: 35rem;
  min-height: 35rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-top: ${marginSm};
  margin-bottom: ${marginXs};
`;

export const ToggleDarkModeWrapper = styled.div`
  position: absolute;
  top: 15rem;
  right: 15rem;
`;

export const FullNameLabel = styled.label`
  max-width: 10vw;
  min-width: 150rem;
  display: inline-block;
  word-break: break-word;
  text-align: center;
  margin-bottom: ${marginSm};
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const ManageProfileButton = styled(Button)`
  margin-bottom: ${marginXs};
`;

export const SwitchViewButton = styled(Button)`
  margin-bottom: ${marginXs};
`;

export const LogoutButton = styled(Button)`
  margin-bottom: ${marginSm};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  min-width: 180rem;
`;

export const CompanyLogoWrapper = styled.div`
  padding: 10rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CompanyLogo = styled.img`
  height: fit-content;
  width: 2vw;
`;

export const UserGuidesLabel = styled.label`
  font-weight: 300;
  color: ${COLORS.BLACK};
  font-size: 14rem;
  cursor: pointer;
  margin-top: 1rem;
`;

export const UserGuidesSpan = styled.div`
  display: flex;
  gap: 10rem;
  align-self: flex-start;
  height: 21rem;
  margin-top: 22rem;
  &:hover {
    ${UserGuidesLabel} {
      text-decoration: underline;
    }
  }
`;
