import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  ButtonContainer,
  DefaultFieldsDocSelectTextWrapper,
  DefaultFieldsText,
  Divider,
  DocSelectText,
  LeftAlign,
  Margin,
} from '../AddEditProduct.styled';
import ProductAttribute from '../ProductAttribute/ProductAttribute';
import {
  IDocVisibilityOptionEnum,
  IProductAttribute,
} from 'types/Product.types';
import { AddEditProductAttributeModal } from '../AddEditProductAttributeModal/AddEditProductAttributeModal';
import { useCallback, useState } from 'react';
import { IAddEditProduct } from '../types';
import DndProvider from 'components/DragAndDrop/DndProvider/DndProvider';
import { Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const PWID = 'add-deafult-fields-form';

interface AddDefaultFieldsFormProps {
  attributes: IProductAttribute[];
  setAttributes: (attributes: IProductAttribute[]) => void;
  isLoading: boolean;
  onCancel: () => void;
  handleSubmit: (values?: any, isValid?: boolean) => void;
  values?: any;
  isLogyxModelIdFieldVisible?: boolean;
  setTouched?: (touched: any) => void;
  isValid?: boolean;
  product?: IAddEditProduct;
  copyProduct?: boolean;
  isStockItem?: boolean;
  disableSubmitButton?: boolean;
  pwId?: string;
}
export const AddDefaultFieldsForm = ({
  attributes,
  setAttributes,
  values,
  isLogyxModelIdFieldVisible,
  onCancel,
  isLoading,
  product,
  copyProduct,
  setTouched,
  isValid,
  isStockItem,
  handleSubmit,
  disableSubmitButton,
  pwId,
}: AddDefaultFieldsFormProps) => {
  const { t } = useTranslation();
  const [attributeForEditing, setAttributeForEditing] =
    useState<IProductAttribute | null>(null);
  const [attributeIndexForEditing, setAttributeIndexForEditing] = useState<
    number | null
  >(null);
  const [isAddProductAttributeModalOpen, setIsAddProductAttributeModalOpen] =
    useState<boolean>(false);

  const { isSmallPhone } = useBreakpointFlag();

  const handleSetShouldDisplayAfterLogyxConfigurationForAttribute = (
    boolean: boolean,
    index: number
  ) => {
    const attributeCopy = { ...attributes[index] };
    const attributesCopy = [...attributes];
    attributesCopy.splice(index, 1, {
      ...attributeCopy,
      shouldDisplayAfterLogyxConfiguration: boolean,
    });
    setAttributes(attributesCopy);
  };

  const handleSetDocVisibilityOptionsForAttribute = (
    docVisibilityOptions: IDocVisibilityOptionEnum[],
    index: number
  ) => {
    const attributeCopy = { ...attributes[index] };
    const attributesCopy = [...attributes];
    attributesCopy.splice(index, 1, {
      ...attributeCopy,
      docVisibilityOptions: docVisibilityOptions,
    });
    setAttributes(attributesCopy);
  };

  const handleRemoveAttribute = (attributeIndex: number) => {
    const newAttributes = [...attributes];
    newAttributes.splice(attributeIndex, 1);
    setAttributes(newAttributes);
  };

  const handleEditAttribute = (attribute: IProductAttribute) => {
    const attributesCopy = [...attributes];
    if (attributeIndexForEditing != null) {
      attributesCopy.splice(attributeIndexForEditing, 1, attribute);
    }
    setAttributes(attributesCopy);
    setAttributeForEditing(null);
    setAttributeIndexForEditing(null);
  };

  const handleAddAttributes = (attributesArray: IProductAttribute[]) => {
    setAttributes([...attributes, ...attributesArray]);
  };

  const onDragHover = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        const attributesCopy = [...attributes];
        const dragItem = attributesCopy[dragIndex];
        const hoverItem = attributesCopy[hoverIndex];

        // Swap places of dragItem and hoverItem in the attributes array
        const updatedAttributes = [...attributes];
        updatedAttributes[dragIndex] = hoverItem;
        updatedAttributes[hoverIndex] = dragItem;

        setAttributes(updatedAttributes);
      }
    },
    [attributes]
  );

  return (
    <>
      <DefaultFieldsDocSelectTextWrapper>
        <DefaultFieldsText data-testid={`${pwId}-${PWID}-default-fields-label`}>
          {t('Default fields')}
        </DefaultFieldsText>
        <DocSelectText
          data-testid={`${pwId}-${PWID}-visibility-in-documents-label`}
        >
          {t('Visibility in documents')}
        </DocSelectText>
      </DefaultFieldsDocSelectTextWrapper>
      <DndProvider>
        {attributes?.map((attribute: IProductAttribute, index: number) => (
          <div key={index}>
            <ProductAttribute
              pwId={`${pwId}-${PWID}`}
              isLogyxEnabledForProduct={
                isLogyxModelIdFieldVisible && !!values.logyx_model_id
              }
              handleSetDocVisibilityOptionsForAttribute={
                handleSetDocVisibilityOptionsForAttribute
              }
              handleSetShouldDisplayAfterLogyxConfigurationForAttribute={
                handleSetShouldDisplayAfterLogyxConfigurationForAttribute
              }
              onEditAttribute={() => {
                setAttributeForEditing(attribute);
                setAttributeIndexForEditing(index);
              }}
              key={index}
              index={index}
              attribute={attribute}
              handleRemoveAttribute={() => handleRemoveAttribute(index)}
              onDragHover={onDragHover}
            />
            {index !== attributes.length - 1 && <Divider />}
          </div>
        ))}
      </DndProvider>
      <LeftAlign>
        <Button
          pwId={`${pwId}-${PWID}-add-default-fields`}
          label={t('Add default fields')}
          icon={Plus}
          colorIcon={COLORS.PRIMARY}
          sizeIcon={20}
          weightIcon="regular"
          link
          onClick={() => {
            // Adding will cause a rerender, so here we set the product form dtos so we dont lose any configuration
            setIsAddProductAttributeModalOpen(true);
          }}
          fontSize="16rem"
        />
      </LeftAlign>
      <ButtonContainer>
        <Margin>
          <Button
            pwId={`${pwId}-${PWID}-cancel-button`}
            onClick={onCancel}
            secondary
            width={isSmallPhone ? '150rem' : '200rem'}
            label={t('Cancel')}
          />
        </Margin>
        <Button
          loader={isLoading}
          data-testid={`${pwId}-${PWID}-${
            product ? 'edit-product-button' : 'add-product-button'
          }`}
          disabled={isLoading || disableSubmitButton}
          primary
          width={isSmallPhone ? '150rem' : '200rem'}
          label={
            isStockItem
              ? t('Apply')
              : product && !copyProduct
              ? t('Edit')
              : t('Add')
          }
          onClick={() => {
            if (isStockItem) {
              handleSubmit();
              return;
            }
            const touched = {};
            Object.keys(values).forEach((key) => {
              touched[key] = true;
            });
            setTouched?.(touched); // Trigger validation for all fields
            handleSubmit(values, isValid);
          }}
        />
      </ButtonContainer>
      <AddEditProductAttributeModal
        pwId={`${pwId}-${PWID}`}
        onClose={() => {
          setAttributeForEditing(null);
          setAttributeIndexForEditing(null);
        }}
        attributeForEditing={attributeForEditing}
        isOpen={!!attributeForEditing || isAddProductAttributeModalOpen}
        setIsOpen={setIsAddProductAttributeModalOpen}
        onEdit={(attribute: IProductAttribute) =>
          handleEditAttribute(attribute)
        }
        onSubmit={(attributes: IProductAttribute[]) =>
          handleAddAttributes(attributes)
        }
        level="THIRD"
      />
    </>
  );
};
