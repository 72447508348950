import { ICreateQuotationProductFormDTO } from './constants';
import { Dispatch, SetStateAction, useState } from 'react';
import AddNewProduct from './AddNewProduct/AddNewProduct';
import ProductList from './ProductList/ProductList';
import { RelativeDiv } from './ProductTab.styled';
import { useNavigate } from 'react-router-dom';
import { DiscountType } from 'types/Discount.types';
import { IGeneralDiscountInfo } from '../constants';

interface IProductTabProps {
  onForward: (productFormDtosData: ICreateQuotationProductFormDTO[]) => void; // productFormDtosData is for order tab initial down payment calculation
  onBack: () => void;
  productFormDtos: ICreateQuotationProductFormDTO[];
  setProductFormDtos: Dispatch<
    SetStateAction<ICreateQuotationProductFormDTO[]>
  >;
  canAddAnotherProduct?: boolean;
  canAddOnlyStockItems?: boolean;
  withoutPrices?: boolean;
  hideMeasurementsToggle?: boolean;
  generalDiscountInfo?: IGeneralDiscountInfo;
  setGeneralDiscountInfo?: Dispatch<SetStateAction<IGeneralDiscountInfo>>;
  initialTotalSalesPriceForAllLines?: number;
}

const ProductTab = ({
  onForward,
  onBack,
  productFormDtos,
  setProductFormDtos,
  canAddAnotherProduct = true,
  canAddOnlyStockItems = false,
  withoutPrices = false,
  hideMeasurementsToggle = false,
  generalDiscountInfo,
  setGeneralDiscountInfo,
  initialTotalSalesPriceForAllLines,
}: IProductTabProps) => {
  const navigate = useNavigate();

  const [shouldScrollToLastProduct, setShouldScrollToLastProduct] =
    useState<boolean>(false);

  const [isAddingNewProduct, setIsAddingNewProduct] = useState<boolean>(
    !productFormDtos.length
  );

  const handleAddProductFormDto = (formDTO: ICreateQuotationProductFormDTO) => {
    setProductFormDtos((prevProductFormDtos) => {
      const updatedProductFormDtos = [...prevProductFormDtos, formDTO].map(
        (product) => {
          if (
            generalDiscountInfo?.value &&
            generalDiscountInfo?.isAppliedToAllLines
          ) {
            return {
              ...product,
              discount: generalDiscountInfo?.value,
              discountType: DiscountType.PERCENTAGE,
            };
          }
          return product;
        }
      );

      return updatedProductFormDtos;
    });
    setIsAddingNewProduct(false);
    setShouldScrollToLastProduct(true);
  };

  return (
    <RelativeDiv>
      {isAddingNewProduct ? (
        <AddNewProduct
          addProductFormDto={handleAddProductFormDto}
          onBack={onBack}
          productsLength={productFormDtos?.length}
          canAddOnlyStockItems={canAddOnlyStockItems}
        />
      ) : (
        <ProductList
          onForward={onForward}
          onBack={() => {
            // canAddAnotherProduct is true when creating quotation, and is false when adding/editing salesOrder/appointment line
            if (!canAddAnotherProduct) {
              navigate(-1);
            } else {
              onBack();
            }
          }}
          productFormDtos={productFormDtos}
          setProductFormDtos={setProductFormDtos}
          addNewProduct={() => setIsAddingNewProduct(true)}
          canAddAnotherProduct={canAddAnotherProduct}
          withoutPrices={withoutPrices}
          shouldScrollToLastProduct={shouldScrollToLastProduct}
          setShouldScrollToLastProduct={setShouldScrollToLastProduct}
          hideMeasurementsToggle={hideMeasurementsToggle}
          generalDiscountInfo={generalDiscountInfo!}
          setGeneralDiscountInfo={setGeneralDiscountInfo!}
          initialTotalSalesPriceForAllLines={initialTotalSalesPriceForAllLines}
        />
      )}
    </RelativeDiv>
  );
};

export default ProductTab;
