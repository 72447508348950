import FwSingleAppointmentRouter from 'navigation/FwSingleAppointment/FwSingleAppointmentRouter';
import {
  Container,
  H3,
  RightSide,
  LeftSide,
  TitleContainer,
  Wrapper,
  MenuWrapper,
  ThreeDots,
} from './FwSingleAppointmentPage.styled';
import SubSideMenu from 'components/Layout/Navigation/SubSideMenu/SubSideMenu';
import { generalAppointmentSubMenuItems, subMenuItems } from './constants';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import {
  useGetSingleFwAppointment,
  useGetSingleFwAppointmentInfo,
} from './hooks';
import { getAppointmentTypeLabel } from './helpers';
import { useCurrentInterval } from './FwTimer/hooks';
import FwTimer from './FwTimer/FwTimer';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { COLORS } from 'assets/styled';
import Icon from 'components/Icon/Icon';
import { mapSubSideMenuItemsInfo } from 'components/Layout/Navigation/SubSideMenu/helpers';
import { CaretLeft, DotsThree } from '@phosphor-icons/react';
import { AppointmentStatus, AppointmentType } from 'types/Appointment.types';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import MobileSubMenu from 'components/Layout/Navigation/MobileSubMenu/MobileSubMenu';
import ActionsMobileModal from './ActionsMobileModal/ActionsMobileModal';

const FwSingleAppointmentPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isReportAlreadyExistsModalOpen, setIsReportAlreadyExistsModalOpen] =
    useState<boolean>(false);
  const [
    generalAppointmentCompletedModalOpen,
    setGeneralAppointmentCompletedModalOpen,
  ] = useState<boolean>(false);
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);
  const [isActionsFilterModalOpen, setIsActionsFilterModalOpen] =
    useState<boolean>(false);
  const { id } = useParams();

  const { data: appointmentData, isSuccess: isSuccessGetSingleFwAppointment } =
    useGetSingleFwAppointment(id!);

  const hasReport = appointmentData?.appointment?.has_report;
  const isGeneralAppointment =
    appointmentData?.appointment?.type?.name === AppointmentType.GENERAL;
  const generalAppointmentCompleted =
    isGeneralAppointment &&
    appointmentData?.appointment?.status?.name ===
      AppointmentStatus.FIELD_WORK_FINISHED;

  useEffect(() => {
    if (isSuccessGetSingleFwAppointment) {
      hasReport && setIsReportAlreadyExistsModalOpen(true);
      generalAppointmentCompleted &&
        setGeneralAppointmentCompletedModalOpen(true);
    }
  }, [isSuccessGetSingleFwAppointment]);

  const { currentInterval } = useCurrentInterval();

  const { data: infoData } = useGetSingleFwAppointmentInfo(id!);

  const { isSmallTablet } = useBreakpointFlag();

  return (
    <Container>
      {!isSmallTablet && (
        <SubSideMenu
          subMenuItems={
            isGeneralAppointment ? generalAppointmentSubMenuItems : subMenuItems
          }
          subMenuItemsInfo={mapSubSideMenuItemsInfo(infoData?.appointment_info)}
          isFieldWorker={true}
        />
      )}
      <Wrapper>
        <TitleContainer>
          <LeftSide>
            <Icon
              svg={CaretLeft}
              marginRight="15rem"
              color={COLORS.BLACK}
              weight="regular"
              onClick={() => navigate(RoutesConfig.FwAppointments.fullPath)}
              size={15}
            />
            <H3>
              {t(getAppointmentTypeLabel(appointmentData?.appointment))} #
              {appointmentData?.appointment?.id}
            </H3>
          </LeftSide>
          <RightSide isSpaceBetween={!!currentInterval}>
            {!isSmallTablet && (
              <FwTimer
                appointment={appointmentData?.appointment}
                appointmentLines={appointmentData?.appointment_lines}
              />
            )}
            {isSmallTablet && (
              <ThreeDots>
                <Icon
                  svg={DotsThree}
                  color={COLORS.BLACK}
                  size={30}
                  weight="bold"
                  onClick={() => setIsActionsFilterModalOpen(true)}
                />
              </ThreeDots>
            )}
          </RightSide>
        </TitleContainer>

        {isSmallTablet && (
          <MenuWrapper>
            <MobileSubMenu
              subMenuItems={
                isGeneralAppointment
                  ? generalAppointmentSubMenuItems
                  : subMenuItems
              }
              subMenuItemsInfo={mapSubSideMenuItemsInfo(
                infoData?.appointment_info
              )}
            />
          </MenuWrapper>
        )}

        <FwSingleAppointmentRouter />
      </Wrapper>
      <ConfirmModal
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onConfirm={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onCancel={() => setIsSendEmailModalOpen(false)}
        title={t('Send E-mail')}
        description={
          t(
            'Are you sure you want to send this Appointment via email to the customer'
          ) + '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={appointmentData?.appointment?.id}
        type={AddEmailNoteModalType.FW_APPOINTMENT} // TODO
      />
      <OkModal
        isOpen={isReportAlreadyExistsModalOpen}
        setIsOpen={setIsReportAlreadyExistsModalOpen}
        description={t('Report already exists') + '.'}
        buttonLabel={t('Close')}
        level="FIRST"
        onClick={() => setIsReportAlreadyExistsModalOpen(false)}
      />
      <OkModal
        isOpen={generalAppointmentCompletedModalOpen}
        setIsOpen={setGeneralAppointmentCompletedModalOpen}
        description={t('General appointment already completed') + '.'}
        buttonLabel={t('Close')}
        level="FIRST"
        onClick={() => setGeneralAppointmentCompletedModalOpen(false)}
      />

      <ActionsMobileModal
        isOpen={isActionsFilterModalOpen}
        setIsOpen={setIsActionsFilterModalOpen}
        appointment={appointmentData?.appointment}
        appointmentData={appointmentData}
      />
    </Container>
  );
};

export default FwSingleAppointmentPage;
