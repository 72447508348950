import Button from 'components/Button/Button';
import Input from 'components/Input/Input';
import Spinner from 'components/Spinner/Spinner';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { changeLanguage } from 'utils/changeLanguage';
import {
  useGetEmailTemplateQuotationInfo,
  useRequestAppointmentFromEmail,
} from './hooks';
import {
  ButtonContainer,
  Container,
  Heading,
  Label,
  LabelKey,
  LabelWrapper,
  RowWrapper,
  TopContentContainer,
  Wrapper,
} from './RequestAppointmentPage.styled';

export const RequestAppointmentPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const companyId = searchParams.get('company_id');
  const lang = searchParams.get('lang');
  const id = searchParams.get('quotation_id');

  const { data: quotationData, isLoading } = useGetEmailTemplateQuotationInfo(
    token!,
    companyId!
  );

  const { mutate: requestAppointment, isSuccess } =
    useRequestAppointmentFromEmail();

  const [optionalMessage, setOptionalMessage] = useState<string>();

  useEffect(() => {
    if (lang) {
      changeLanguage(lang as any);
    }
  }, [lang]);

  return (
    <Container>
      {isLoading ? (
        <Spinner size={50} />
      ) : (
        <Wrapper>
          <Heading>{t('Request Appointment')}</Heading>
          <TopContentContainer>
            <RowWrapper>
              <LabelWrapper>
                <LabelKey>{t('Quotation')}</LabelKey>
                <Label>{quotationData?.quotation?.number}</Label>
              </LabelWrapper>
              <LabelWrapper>
                <LabelKey>{t('Sales Person')}</LabelKey>
                <Label>
                  {quotationData?.quotation?.user?.name}{' '}
                  {quotationData?.quotation?.user?.last_name}
                </Label>
              </LabelWrapper>
            </RowWrapper>
            <LabelKey>{t('Optional message')}</LabelKey>
            <Input
              isTextArea
              onChange={(e: any) => {
                setOptionalMessage(e.target.value);
              }}
            />
          </TopContentContainer>
          <ButtonContainer>
            <Button width="200rem" secondary label={t('Cancel')} />
            <Button
              width="200rem"
              primary
              label={t('Confirm')}
              disabled={isSuccess}
              onClick={() => {
                if (token && companyId)
                  requestAppointment({
                    token: token,
                    companyId: companyId,
                    optionalMessage: optionalMessage || '',
                  });
              }}
            />
          </ButtonContainer>
        </Wrapper>
      )}
    </Container>
  );
};
