import { H3, marginMd } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  AlignRow,
  FirstRow,
  StyledCustomSelect,
  SecondRow,
  ExistingOptionLabel,
  SelectWrapper,
  CheckboxContainer,
  CustomSelectWrapper,
  StyledCustomSelectSmall,
  SelectsWrapper,
  CheckboxWrapper,
} from './CustomerTab.styled';
import Button from 'components/Button/Button';
import { Field, Formik, getIn } from 'formik';
import { Input } from 'components/Input/InputFormik';
import {
  baseCustomerDetailsSchema,
  createQuotationNoShipToAddressCustomerTabSchema,
} from './validation';
import {
  CUSTOMERS_PER_PAGE,
  ICreateQuotationCustomerDetails,
} from './constants';
import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Option } from 'components/Select/type';
import { useTranslation } from 'react-i18next';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { Select } from 'components/Select/Select';
import { ICustomer, ICustomerAlternativeContact } from 'types/Customer.types';
import { Checkbox } from 'components/Checkbox/Checkbox';
import {
  useGetCustomerAlternativeContactOptions,
  useGetCustomerByEmail,
} from './hooks';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import { ICreateQuotationQueryParamsForCustomer } from 'types/Quotations.types';
import debounce from 'lodash.debounce';
import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from 'navigation/routes';
import { INewAltContactNavigationState } from 'pages/Manager/SingleCustomerPage/types';
import {
  IGetCityAndAddressDTO,
  useGetCityAndAddress,
} from 'utils/hooks/useGetCityAndAddress';
import {
  generateAltContactOptionFromAltContact,
  generateCustomerOptionFromCustomer,
  generateExistingCustomerDetails,
  handleUpdateFormikValuesOnAlternativeContactSelect,
  isValidGetCityAndAddressData,
} from './helpers';
import CustomMenuList from 'components/Select/SelectComponents/CustomMenuList/CustomMenuList';
import { useGetCustomersInfinite } from 'utils/hooks/useGetCustomers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Info } from '@phosphor-icons/react/dist/ssr';

const PWID = 'customer-tab';

interface ICustomerTabProps {
  pwId?: string;
  onForward: (
    customerQueryParams: ICreateQuotationQueryParamsForCustomer
  ) => void;
  customerDetails: ICreateQuotationCustomerDetails;
  setCustomerDetails: Dispatch<SetStateAction<ICreateQuotationCustomerDetails>>;
  resetCustomerTabForm: (showEmptyCustomerValues: boolean) => void;
  isEditQuotation?: boolean;
}

const CustomerTab = ({
  onForward,
  customerDetails,
  setCustomerDetails,
  resetCustomerTabForm,
  isEditQuotation = false,
  pwId,
}: ICustomerTabProps) => {
  const navigate = useNavigate();
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const [areAllShippingFieldsTouched, setAreAllShippingFieldsTouched] =
    useState<boolean>(false);
  const countryOptions: Option[] = useGetCountryOptions();
  const [isNewCustomerSameEmailModalOpen, setIsNewCustomerSameEmailModalOpen] =
    useState(false);
  const [isUsingExistingCustomer, setIsUsingExistingCustomer] =
    useState<boolean>(!!customerDetails?.existingCustomer);
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    customerDetails?.existingCustomer || null
  );
  const [selectedAlternativeContact, setSelectedAlternativeContact] =
    useState<ICustomerAlternativeContact | null>(
      customerDetails?.alternativeContact || null
    );
  const [
    shouldUpdateShippingDataToMatchCustomer,
    setShouldUpdateShippingDataToMatchCustomer,
  ] = useState<boolean>(false);
  const [
    shouldUpdateShippingDataToMatchAltContact,
    setShouldUpdateShippingDataToMatchAltContact,
  ] = useState<boolean>(false);

  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const [searchByAlternativeContact, setSearchByAlternativeContact] =
    useState<string>('');
  const debouncedSearchByAlternativeContact = useDebounce(
    searchByAlternativeContact
  );
  const scrollPositionRef = useRef(0);

  const isShippingDataFormShown = !!customerDetails.isShippingDataFormShown;
  const setIsShippingDataFormShown = (
    boolean: boolean,
    customerDetailsFormikValues: any
  ) => {
    const newCustomerDetails: ICreateQuotationCustomerDetails = {
      ...customerDetailsFormikValues,
      isShippingDataFormShown: boolean,
    };
    setCustomerDetails(newCustomerDetails);
  };

  const { t } = useTranslation();
  const { isPhone } = useBreakpointFlag();

  const {
    data: customersData,
    fetchNextPage,
    hasNextPage,
    isLoading: isLoadingCustomers,
  } = useGetCustomersInfinite(
    CUSTOMERS_PER_PAGE,
    debouncedSearchBy,
    true,
    true,
    undefined,
    undefined
  );

  const customerOptions: Option[] = useMemo(() => {
    if (customersData?.pages?.length) {
      return customersData.pages
        .map((page) => page.customers)
        .flat()
        .map((customer: ICustomer) =>
          generateCustomerOptionFromCustomer(customer)
        );
    }
    return [];
  }, [customersData]);

  const titleOptions = useGetTitleOptions();

  useEffect(() => {
    if (selectedCustomer) {
      setCustomerDetails(
        generateExistingCustomerDetails(
          selectedCustomer,
          selectedAlternativeContact,
          customerDetails,
          formikRef?.current?.values,
          shouldUpdateShippingDataToMatchCustomer,
          shouldUpdateShippingDataToMatchAltContact
        )
      );
      if (shouldUpdateShippingDataToMatchCustomer) {
        setShouldUpdateShippingDataToMatchCustomer(false);
      }
    }
  }, [selectedCustomer, selectedAlternativeContact]);

  const {
    mutate: getCustomerByEmail,
    data: existingCustomerWithSameEmail,
    isLoading: existingCustomerWithSameEmailIsLoading,
    isSuccess: existingCustomerWithSameEmailIsSuccess,
  } = useGetCustomerByEmail();

  useEffect(() => {
    if (existingCustomerWithSameEmailIsSuccess) {
      if (existingCustomerWithSameEmail?.customer) {
        setIsNewCustomerSameEmailModalOpen(true);
      } else {
        const customerQueryParams: ICreateQuotationQueryParamsForCustomer = {
          force_create_customer: false,
        };
        onForward(customerQueryParams);
      }
    }
  }, [existingCustomerWithSameEmailIsSuccess]);

  // API address validation
  const formikRef = useRef<any>();
  const {
    mutate: getCityAndAddress,
    data: cityAndAddressData,
    isSuccess: isSuccessGetCityAndAddress,
    isLoading: isLoadingGetCityAndAddress,
  } = useGetCityAndAddress();

  useEffect(() => {
    if (
      isSuccessGetCityAndAddress &&
      cityAndAddressData &&
      !formikRef?.current.values.city
    ) {
      formikRef?.current.setFieldValue('city', cityAndAddressData.address.city);
      if (
        formikRef?.current.values.house_number &&
        formikRef?.current.values.street
      )
        getCityAndAddress({
          countryCode: formikRef?.current.values.country,
          zipCode: formikRef?.current.values.code,
          houseNumber: formikRef?.current.values.house_number,
          city: cityAndAddressData.address.city,
          street: formikRef?.current.values.street,
        });
    }
  }, [isSuccessGetCityAndAddress]);

  const debouncedGetCityAndAddress = debounce(
    (getCityAndAddressData: IGetCityAndAddressDTO) => {
      if (isValidGetCityAndAddressData(getCityAndAddressData))
        getCityAndAddress({
          countryCode: getCityAndAddressData.countryCode,
          zipCode: getCityAndAddressData.zipCode,
          houseNumber: getCityAndAddressData.houseNumber,
          city: getCityAndAddressData.city,
          street: getCityAndAddressData.street,
        });
    },
    1000
  );

  //Ship-to
  const {
    mutate: getCityAndAddressShipTo,
    data: cityAndAddressShipToData,
    isSuccess: isSuccessGetCityAndAddressShipTo,
    isLoading: isLoadingGetCityAndAddressShipTo,
  } = useGetCityAndAddress();

  useEffect(() => {
    if (
      isSuccessGetCityAndAddressShipTo &&
      cityAndAddressShipToData &&
      !formikRef?.current.values.shipping_data.city
    ) {
      formikRef?.current.setFieldValue(
        'shipping_data.city',
        cityAndAddressShipToData.address.city
      );
      if (
        formikRef?.current.values.shipping_data.house_number &&
        formikRef?.current.values.shipping_data.street
      )
        getCityAndAddressShipTo({
          countryCode: formikRef?.current.values.shipping_data.country,
          zipCode: formikRef?.current.values.shipping_data.code,
          houseNumber: formikRef?.current.values.shipping_data.house_number,
          city: cityAndAddressShipToData.address.city,
          street: formikRef?.current.values.shipping_data.street,
        });
    }
  }, [isSuccessGetCityAndAddressShipTo]);

  const debouncedGetCityAndAddressForShipTo = debounce(
    (getCityAndAddressShipToData: IGetCityAndAddressDTO) => {
      if (isValidGetCityAndAddressData(getCityAndAddressShipToData))
        getCityAndAddressShipTo({
          countryCode: getCityAndAddressShipToData.countryCode,
          zipCode: getCityAndAddressShipToData.zipCode,
          houseNumber: getCityAndAddressShipToData.street
            ? getCityAndAddressShipToData.houseNumber
            : '',
          city: getCityAndAddressShipToData.city,
          street: getCityAndAddressShipToData.city
            ? getCityAndAddressShipToData.street
            : '',
        });
    },
    1000
  );

  const {
    customerAlternativeContactOptions,
    isLoadingAltContacts,
    hasNextPageAltContacts,
    fetchNextPageAltContacts,
  }: {
    customerAlternativeContactOptions: Option[];
    isLoadingAltContacts: boolean;
    hasNextPageAltContacts: boolean | undefined;
    fetchNextPageAltContacts: any;
  } = useGetCustomerAlternativeContactOptions(
    selectedCustomer?.id,
    debouncedSearchByAlternativeContact
  );

  const handleSelectAlternativeContact = (
    contact: ICustomerAlternativeContact | null
  ) => {
    setSelectedAlternativeContact(contact);
    handleUpdateFormikValuesOnAlternativeContactSelect(
      formikRef?.current?.setFieldValue,
      contact
    );
  };

  return (
    <Container>
      <FirstRow>
        <H3 data-testid={`${pwId}-${PWID}-title`}>{t('Customer details')}</H3>
      </FirstRow>
      <SecondRow>
        <ExistingOptionLabel
          data-testid={`${pwId}-${PWID}-new-customer-label`}
          isSelected={!isUsingExistingCustomer}
          onClick={() => {
            if (!isUsingExistingCustomer) return;
            setIsUsingExistingCustomer(false);
            setSelectedCustomer(null);
            setSelectedAlternativeContact(null);
            const showEmptyCustomerValues = true;
            resetCustomerTabForm(showEmptyCustomerValues);
          }}
          marginRight={marginMd}
        >
          {t('New Customer')}
        </ExistingOptionLabel>
        <ExistingOptionLabel
          data-testid={`${pwId}-${PWID}-existing-customer-label`}
          isSelected={isUsingExistingCustomer}
          onClick={() => {
            if (isUsingExistingCustomer) return;
            const showEmptyCustomerValues = isEditQuotation ? true : false;
            resetCustomerTabForm(showEmptyCustomerValues);
            setIsUsingExistingCustomer(true);
            customerDetails?.existingCustomer &&
              setSelectedCustomer(customerDetails.existingCustomer);
            customerDetails?.alternativeContact &&
              setSelectedAlternativeContact(customerDetails.alternativeContact);
          }}
        >
          {t('Existing Customer')}
        </ExistingOptionLabel>
      </SecondRow>
      {isUsingExistingCustomer && (
        <SelectsWrapper>
          <SelectWrapper>
            <Select
              pwId={`${pwId}-${PWID}-existing-customer`}
              name="existingCustomer"
              placeholder={t('Select Customer')}
              isMulti={false}
              isDisabled={false}
              isSearchable={true}
              onChange={(option: Option) => {
                setSelectedCustomer(option.value);
                setSelectedAlternativeContact(null);
                setShouldUpdateShippingDataToMatchCustomer(true);
              }}
              options={customerOptions}
              defaultValue={
                customerDetails?.existingCustomer
                  ? generateCustomerOptionFromCustomer(
                      customerDetails.existingCustomer
                    )
                  : undefined
              }
              onInputChange={(value: string) => setSearchBy(value)}
              isLoading={isLoadingCustomers}
              translate={false}
              onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
            />
          </SelectWrapper>
          <SelectWrapper>
            <Select
              pwId={`${pwId}-${PWID}-alternative-contact`}
              isLoading={isLoadingAltContacts}
              name="alternativeContact"
              placeholder={t('Alternative contact')}
              isMulti={false}
              isDisabled={!selectedCustomer}
              isClearable={true}
              isSearchable={true}
              onChange={(e: Option | null) => {
                handleSelectAlternativeContact(
                  e ? (e.value as ICustomerAlternativeContact) : (e as null)
                );
                setShouldUpdateShippingDataToMatchAltContact(true);
              }}
              options={customerAlternativeContactOptions}
              defaultValue={
                customerDetails?.alternativeContact
                  ? generateAltContactOptionFromAltContact(
                      customerDetails.alternativeContact
                    )
                  : undefined
              }
              onInputChange={(value: string) =>
                setSearchByAlternativeContact(value)
              }
              translate={false}
              components={{
                MenuList: (props) => (
                  <CustomMenuList
                    pwId={`${pwId}-${PWID}-create-alternative-button`}
                    {...props}
                    scrollPositionRef={scrollPositionRef}
                    label={t('Create alternative contact')}
                    onClick={() => {
                      if (selectedCustomer) {
                        const navigationState: INewAltContactNavigationState = {
                          customerId: Number(selectedCustomer.id),
                        };
                        navigate(RoutesConfig.NewAltContact.fullPath, {
                          state: navigationState,
                        });
                      }
                    }}
                    onMenuScrollToBottom={() =>
                      hasNextPageAltContacts && fetchNextPageAltContacts()
                    }
                  />
                ),
              }}
            />
          </SelectWrapper>
        </SelectsWrapper>
      )}
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={customerDetails}
        validationSchema={
          isShippingDataFormShown
            ? baseCustomerDetailsSchema
            : createQuotationNoShipToAddressCustomerTabSchema
        }
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={() => {
          // onSubmit
        }}
        validateOnMount={true}
      >
        {({ handleBlur, setFieldValue, isValid, values, errors, touched }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId={`${pwId}-${PWID}-name-input`}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['name'] ? errors['name'] : ''
                  }
                  height={'41rem'}
                  name="name"
                  placeholder={t('First name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                />
                <Input
                  pwId={`${pwId}-${PWID}-last-name-input`}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['last_name']
                      ? errors['last_name']
                      : ''
                  }
                  height={'41rem'}
                  name="last_name"
                  placeholder={t('Last name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('last_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId={`${pwId}-${PWID}-phone-number-input`}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['phone']
                      ? errors['phone']
                      : ''
                  }
                  height={'41rem'}
                  name="phone"
                  placeholder={t('Phone number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('phone', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                />
                <CustomSelectWrapper>
                  <Field
                    pwId={`${pwId}-${PWID}-gender-field`}
                    disabled={isUsingExistingCustomer}
                    errorMessage={
                      areAllFieldsTouched || touched['title']
                        ? errors['title']
                        : ''
                    }
                    height={'41rem'}
                    width={isPhone ? '100%' : '50%'}
                    name="title"
                    options={titleOptions}
                    component={StyledCustomSelect}
                    placeholder={t('Title')}
                    isMulti={false}
                    onSelect={(value: string) => setFieldValue('title', value)}
                  />
                </CustomSelectWrapper>
              </Row>
              <Row>
                <Input
                  pwId={`${pwId}-${PWID}-email-input`}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['email']
                      ? errors['email']
                      : ''
                  }
                  height={'41rem'}
                  name="email"
                  placeholder={t('E-mail')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('email', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                  icon={isUsingExistingCustomer ? undefined : Info}
                  iconSize={20}
                  iconTooltip={t('Valid format: example@mail.com')}
                />
                <CustomSelectWrapper>
                  <Field
                    pwId={`${pwId}-${PWID}-country-field`}
                    isLoading={isLoadingGetCityAndAddress}
                    disabled={isUsingExistingCustomer}
                    errorMessage={
                      areAllFieldsTouched || touched['country']
                        ? errors['country']
                        : ''
                    }
                    height={'41rem'}
                    name="country"
                    width={isPhone ? '100%' : '50%'}
                    options={countryOptions}
                    component={StyledCustomSelect}
                    placeholder={t('Country Code')}
                    isMulti={false}
                    onSelect={(value: any) => {
                      setFieldValue('country', value);
                      debouncedGetCityAndAddress({
                        countryCode: value.value,
                        zipCode: values.code || '',
                        houseNumber: values.house_number || '',
                        city: values.city || '',
                        street: values.street || '',
                      });
                    }}
                  />
                </CustomSelectWrapper>
              </Row>

              <Row>
                <Input
                  pwId={`${pwId}-${PWID}-zip-code-input`}
                  isLoading={isLoadingGetCityAndAddress}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['code'] ? errors['code'] : ''
                  }
                  height={'41rem'}
                  name="code"
                  placeholder={t('Zip code')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('code', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: value,
                        houseNumber: values.city ? values.house_number : '',
                        city: values.city,
                        street: values.city ? values.street : '',
                      });
                    }
                  }}
                />
                <Input
                  pwId={`${pwId}-${PWID}-house-number-input`}
                  isLoading={isLoadingGetCityAndAddress}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['house_number']
                      ? errors['house_number']
                      : ''
                  }
                  height={'41rem'}
                  name="house_number"
                  placeholder={t('House number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('house_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.street && values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: value,
                        city: values.city,
                        street: values.street,
                      });
                    }
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId={`${pwId}-${PWID}-city-field-input`}
                  isLoading={isLoadingGetCityAndAddress}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['city'] ? errors['city'] : ''
                  }
                  height={'41rem'}
                  name="city"
                  placeholder={t('City')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('city', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: values.house_number,
                        city: value,
                        street: values.street,
                      });
                    }
                  }}
                />
                <Input
                  pwId={`${pwId}-${PWID}-street-input`}
                  isLoading={isLoadingGetCityAndAddress}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['street']
                      ? errors['street']
                      : ''
                  }
                  height={'41rem'}
                  name="street"
                  placeholder={t('Street')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('street', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.city && values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: values.house_number,
                        city: values.city,
                        street: value,
                      });
                    }
                  }}
                />
              </Row>
              <Row>
                <CheckboxWrapper>
                  <Checkbox
                    pwId={`${pwId}-${PWID}-alternative-invoice-email-checkbox`}
                    disabled={isUsingExistingCustomer}
                    isChecked={values.hasAlternativeInvoiceEmail}
                    label={t('Alternative invoice E-mail')}
                    style={{ fontSize: '16rem' }}
                    onChange={() =>
                      setFieldValue(
                        'hasAlternativeInvoiceEmail',
                        !values.hasAlternativeInvoiceEmail
                      )
                    }
                  />
                </CheckboxWrapper>
                {values.hasAlternativeInvoiceEmail && (
                  <Input
                    pwId={`${pwId}-${PWID}-alternative-invoice-email-input`}
                    disabled={isUsingExistingCustomer}
                    errorMessage={
                      areAllFieldsTouched ||
                      touched['alternative_invoice_email']
                        ? errors['alternative_invoice_email']
                        : ''
                    }
                    height={'41rem'}
                    name="alternative_invoice_email"
                    placeholder={t('Alternative invoice E-mail')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('alternative_invoice_email', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: '50%',
                    }}
                    icon={isUsingExistingCustomer ? undefined : Info}
                    iconSize={20}
                    iconTooltip={t('Valid format: example@mail.com')}
                  />
                )}
              </Row>
              <Row>
                <Input
                  pwId={`${pwId}-${PWID}-company-name-input`}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['company_name']
                      ? errors['company_name']
                      : ''
                  }
                  height={'41rem'}
                  name="company_name"
                  placeholder={t('Company Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('company_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                />
                <Input
                  pwId={`${pwId}-${PWID}-company-vat-input`}
                  disabled={isUsingExistingCustomer}
                  errorMessage={
                    areAllFieldsTouched || touched['vat_number']
                      ? errors['vat_number']
                      : ''
                  }
                  height={'41rem'}
                  name="vat_number"
                  placeholder={t('Company VAT')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('vat_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                />
              </Row>
              <Row>
                <CheckboxContainer>
                  <Checkbox
                    pwId={`${pwId}-${PWID}-different-shipping-address-checkbox`}
                    isChecked={isShippingDataFormShown}
                    label={t('Different shipping address')}
                    style={{ fontSize: '16rem' }}
                    onChange={() => {
                      if (
                        areAllShippingFieldsTouched &&
                        isShippingDataFormShown
                      )
                        setAreAllShippingFieldsTouched(false);
                      setIsShippingDataFormShown(
                        !isShippingDataFormShown,
                        values
                      );
                    }}
                  />
                </CheckboxContainer>
              </Row>
              {isShippingDataFormShown && (
                <>
                  <Row>
                    <label
                      data-testid={`${pwId}-${PWID}-ship-to-address-label`}
                    >
                      {t('Ship-to address')}
                    </label>
                  </Row>
                  <Row>
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-first-name-field`}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.first_name') &&
                          getIn(errors, 'shipping_data.first_name'))
                          ? getIn(errors, 'shipping_data.first_name')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.first_name"
                      placeholder={t('First name')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.first_name', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                    />
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-last-name-field`}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.last_name') &&
                          getIn(errors, 'shipping_data.last_name'))
                          ? getIn(errors, 'shipping_data.last_name')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.last_name"
                      placeholder={t('Last name')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.last_name', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                    />
                  </Row>
                  <Row>
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-phone-number-field`}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.phone') &&
                          getIn(errors, 'shipping_data.phone'))
                          ? getIn(errors, 'shipping_data.phone')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.phone"
                      placeholder={t('Phone number')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.phone', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                    />
                    <CustomSelectWrapper>
                      <Field
                        pwId={`${pwId}-${PWID}-shipping-data-gender-field`}
                        errorMessage={
                          areAllShippingFieldsTouched ||
                          (getIn(touched, 'shipping_data.title') &&
                            getIn(errors, 'shipping_data.title'))
                            ? getIn(errors, 'shipping_data.title')
                            : ''
                        }
                        height={'41rem'}
                        width={isPhone ? '100%' : '50%'}
                        name="shipping_data.title"
                        options={titleOptions}
                        component={StyledCustomSelectSmall}
                        placeholder={t('Title')}
                        isMulti={false}
                        onSelect={(value: string) =>
                          setFieldValue('shipping_data.title', value)
                        }
                      />
                    </CustomSelectWrapper>
                  </Row>
                  <Row>
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-email-field`}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.email') &&
                          getIn(errors, 'shipping_data.email'))
                          ? getIn(errors, 'shipping_data.email')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.email"
                      placeholder={t('E-mail')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.email', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                      icon={Info}
                      iconSize={20}
                      iconTooltip={t('Valid format: example@mail.com')}
                    />
                    <CustomSelectWrapper>
                      <Field
                        pwId={`${pwId}-${PWID}-shipping-data-country-field`}
                        isLoading={isLoadingGetCityAndAddressShipTo}
                        errorMessage={
                          areAllShippingFieldsTouched ||
                          (getIn(touched, 'shipping_data.country') &&
                            getIn(errors, 'shipping_data.country'))
                            ? getIn(errors, 'shipping_data.country')
                            : ''
                        }
                        height={'41rem'}
                        name="shipping_data.country"
                        width={isPhone ? '100%' : '50%'}
                        options={countryOptions}
                        component={StyledCustomSelectSmall}
                        placeholder={t('Country Code')}
                        isMulti={false}
                        onSelect={(value: any) => {
                          setFieldValue('shipping_data.country', value);
                          debouncedGetCityAndAddressForShipTo({
                            countryCode: value.value,
                            zipCode: values.shipping_data?.zip_code || '',
                            houseNumber:
                              values.shipping_data?.house_number || '',
                            city: values.shipping_data?.city || '',
                            street: values.shipping_data?.street || '',
                          });
                        }}
                      />
                    </CustomSelectWrapper>
                  </Row>
                  <Row>
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-zip-code-field`}
                      isLoading={isLoadingGetCityAndAddressShipTo}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.zip_code') &&
                          getIn(errors, 'shipping_data.zip_code'))
                          ? getIn(errors, 'shipping_data.zip_code')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.zip_code"
                      placeholder={t('Zip code')}
                      onBlur={(e: any) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.zip_code', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                      onChange={(value: any) => {
                        if (values.shipping_data?.country) {
                          debouncedGetCityAndAddressForShipTo({
                            countryCode: values.shipping_data?.country,
                            zipCode: value,
                            houseNumber: values.shipping_data?.city
                              ? values.shipping_data?.house_number
                              : '',
                            city: values.shipping_data?.city,
                            street: values.shipping_data?.city
                              ? values.shipping_data?.street
                              : '',
                          });
                        }
                      }}
                    />
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-house-number-field`}
                      isLoading={isLoadingGetCityAndAddressShipTo}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.house_number') &&
                          getIn(errors, 'shipping_data.house_number'))
                          ? getIn(errors, 'shipping_data.house_number')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.house_number"
                      placeholder={t('House number')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.house_number', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                      onChange={(value: any) => {
                        if (
                          values.shipping_data?.street &&
                          values.shipping_data?.country
                        ) {
                          debouncedGetCityAndAddressForShipTo({
                            countryCode: values.shipping_data?.country,
                            zipCode: values.shipping_data?.zip_code,
                            houseNumber: value,
                            city: values.shipping_data?.city,
                            street: values.shipping_data?.street,
                          });
                        }
                      }}
                    />
                  </Row>
                  <Row>
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-city-field`}
                      isLoading={isLoadingGetCityAndAddressShipTo}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.city') &&
                          getIn(errors, 'shipping_data.city'))
                          ? getIn(errors, 'shipping_data.city')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.city"
                      placeholder={t('City')}
                      onBlur={(e: any) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.city', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                      onChange={(value: any) => {
                        if (values.shipping_data?.country) {
                          debouncedGetCityAndAddressForShipTo({
                            countryCode: values.shipping_data?.country,
                            zipCode: values.shipping_data?.zip_code,
                            houseNumber: values.shipping_data?.house_number,
                            city: value,
                            street: values.shipping_data?.street,
                          });
                        }
                      }}
                    />
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-street-field`}
                      isLoading={isLoadingGetCityAndAddressShipTo}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.street') &&
                          getIn(errors, 'shipping_data.street'))
                          ? getIn(errors, 'shipping_data.street')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.street"
                      placeholder={t('Street')}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.street', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                      }}
                      onChange={(value: any) => {
                        if (
                          values.shipping_data?.city &&
                          values.shipping_data?.country
                        ) {
                          debouncedGetCityAndAddressForShipTo({
                            countryCode: values.shipping_data?.country,
                            zipCode: values.shipping_data?.zip_code,
                            houseNumber: values.shipping_data?.house_number,
                            city: values.shipping_data?.city,
                            street: value,
                          });
                        }
                      }}
                    />
                  </Row>
                  <Row>
                    <Input
                      pwId={`${pwId}-${PWID}-shipping-data-company-name-field`}
                      errorMessage={
                        areAllShippingFieldsTouched ||
                        (getIn(touched, 'shipping_data.company_name') &&
                          getIn(errors, 'shipping_data.company_name'))
                          ? getIn(errors, 'shipping_data.company_name')
                          : ''
                      }
                      height={'41rem'}
                      name="shipping_data.company_name"
                      placeholder={t('Company name')}
                      onBlur={(e: any) => {
                        const value = e.target.value.trim();
                        setFieldValue('shipping_data.company_name', value);
                        handleBlur(e);
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '50%',
                        paddingRight: isPhone ? '0' : '15rem',
                      }}
                    />
                  </Row>
                </>
              )}

              <AlignRow>
                <Button
                  pwId={`${pwId}-${PWID}-next-button`}
                  disabled={existingCustomerWithSameEmailIsLoading}
                  width={'200rem'}
                  onClick={() => {
                    setAreAllFieldsTouched(true);
                    if (isShippingDataFormShown)
                      setAreAllShippingFieldsTouched(true);

                    if (
                      isValid ||
                      (Object.keys(errors).length === 1 &&
                        Object.keys(errors)[0] === 'shipping_data' &&
                        !isShippingDataFormShown)
                    ) {
                      if (!isShippingDataFormShown) delete values.shipping_data;
                      setCustomerDetails(values);
                      if (isUsingExistingCustomer) {
                        const customerQueryParams: ICreateQuotationQueryParamsForCustomer =
                          {
                            force_create_customer: false,
                          };
                        onForward(customerQueryParams);
                      } else {
                        getCustomerByEmail(values.email);
                      }
                    }
                  }}
                  label={t('Next')}
                  primary
                />
              </AlignRow>
            </FormikContainer>
          );
        }}
      </Formik>
      <YesOrNoModal
        pwId={`${pwId}-${PWID}-existing-customer-modal`}
        isOpen={isNewCustomerSameEmailModalOpen}
        setIsOpen={setIsNewCustomerSameEmailModalOpen}
        onYes={() => {
          const customerQueryParams: ICreateQuotationQueryParamsForCustomer = {
            force_create_customer: true,
          };
          onForward(customerQueryParams);
        }}
        onNo={() => setIsNewCustomerSameEmailModalOpen(false)}
        title={''}
        description={`${
          t('Email already exists for customer') +
          ` ${existingCustomerWithSameEmail?.customer?.number} (${existingCustomerWithSameEmail?.customer?.name} ${existingCustomerWithSameEmail?.customer?.last_name})` +
          t(', do you still want to proceed with adding a new customer')
        }?`}
      />
    </Container>
  );
};

export default CustomerTab;
