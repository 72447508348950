import {
  ButtonContainer,
  Title,
  TitleContainer,
  CloseContainer,
  TopContainer,
  Label,
  Wrapper,
  ActionContainer,
  LoadingPDFWrapper,
  ButtonText,
} from './SingleSalesOrderCreatedModal.styled';
import { Close } from 'components/Close/Close';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Modal } from 'components/Modal/Modal';
import { Level } from 'components/Modal/type';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useChangeDownPaymentAmount, useGetSingleSalesOrder } from '../hooks';
import { useParams } from 'react-router-dom';
import { useEditSalesOrderProductsDefinitive } from '../SingleSalesOrderProductsPage/hooks';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import Input from 'components/Input/Input';
import { validateNumericFieldWithZero } from 'utils/validation';
import { COLORS } from 'assets/styled';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import Spinner from 'components/Spinner/Spinner';
import { DownloadSimple, EnvelopeSimple } from '@phosphor-icons/react';

interface ISingleSalesOrderCreatedModal {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  onPDFClick: () => void;
  onEmailClick?: () => void;
  isLoadingExport: boolean;
  level?: Level;
  shouldShowAllProductsDefinitiveSection: boolean;
}

export const SingleSalesOrderCreatedModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  onPDFClick,
  onEmailClick,
  isLoadingExport,
  level = 'FIRST',
  shouldShowAllProductsDefinitiveSection,
}: ISingleSalesOrderCreatedModal) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currencySymbol = useGetCurrencySymbol();

  const { data } = useGetSingleSalesOrder(id!);

  const [amount, setAmount] = useState<string | null>(
    data?.downpayment?.toString()
  );
  const [amountError, setAmountError] = useState<string>('');

  const { mutate: editProductsDefinitive } =
    useEditSalesOrderProductsDefinitive(id!);

  const [allProductsDefinitive, setAllProductsDefinitive] = useState<boolean>(
    useSelector(
      (state: IRootReducerState) =>
        state.companyInfo?.settings?.sales_order_products_definitive
    )
  );

  const { mutate: editDownPayment, isLoading } = useChangeDownPaymentAmount();

  const handleValidateAmountInput = (amount: string) => {
    const validationMsg = validateNumericFieldWithZero(amount, 'Down payment');
    setAmountError(validationMsg ?? '');
  };

  const handleEditDownPaymentAmount = () => {
    if (amountError?.length === 0) {
      editDownPayment({
        id: id!,
        downpaymentAmount: amount!,
      });
    }
  };

  useEffect(() => {
    setAmount(data?.downpayment?.toString());
  }, [data]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{ margin: 'auto', maxWidth: '550rem', position: 'fixed' }}
      level={level}
    >
      <TopContainer>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel && onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Sales order created')}</Title>
        </TitleContainer>
        {shouldShowAllProductsDefinitiveSection && (
          <Wrapper>
            <Label>{t('Down payment amount')}</Label>
            <ActionContainer>
              <Input
                error={amountError !== ''}
                errorMessage={amountError}
                width={'320rem'}
                symbol={currencySymbol}
                height="41rem"
                type="number"
                value={amount?.toString()}
                onChange={(e) => {
                  handleValidateAmountInput(e.target.value);
                  setAmount(e.target.value);
                }}
              />
              <Button
                primary
                width="80rem"
                label={t('Apply')}
                disabled={
                  amount?.length === 0 ||
                  amountError?.length !== 0 ||
                  isLoading ||
                  data?.downpayment == amount
                }
                onClick={() => {
                  handleEditDownPaymentAmount();
                }}
              />
            </ActionContainer>
          </Wrapper>
        )}

        <Wrapper>
          <Label>{t('Order confirmation')}</Label>
          <ActionContainer>
            {isLoadingExport ? (
              <LoadingPDFWrapper>
                <Spinner size={20} />
                <ButtonText>{t('Download pdf')}</ButtonText>
              </LoadingPDFWrapper>
            ) : (
              <Button
                label={t('Download pdf')}
                link
                fontSize="18rem"
                icon={DownloadSimple}
                colorIcon={COLORS.PRIMARY}
                sizeIcon={23}
                onClick={() => {
                  onPDFClick && onPDFClick();
                }}
              />
            )}
            <Button
              label={t('Send email with pdf')}
              link
              fontSize="18rem"
              icon={EnvelopeSimple}
              colorIcon={COLORS.PRIMARY}
              sizeIcon={25}
              onClick={() => {
                onEmailClick && onEmailClick();
              }}
            />
          </ActionContainer>
        </Wrapper>
        {shouldShowAllProductsDefinitiveSection && (
          <Wrapper>
            <ActionContainer>
              <Checkbox
                isChecked={allProductsDefinitive}
                onChange={() => {
                  editProductsDefinitive({
                    all_products_definitive: !allProductsDefinitive,
                  });
                  setAllProductsDefinitive(!allProductsDefinitive);
                }}
                label={t('All product details are definitive')}
              />
            </ActionContainer>
          </Wrapper>
        )}
      </TopContainer>
      <ButtonContainer>
        <Button
          secondary
          width="200rem"
          label={t('Close')}
          onClick={() => {
            onCancel && onCancel();
          }}
        />
      </ButtonContainer>
    </Modal>
  );
};
