import {
  AddMoreOptionsWrapper,
  DefaultLabel,
  DeleteProductFieldIcon,
  DropDownOptionsLabel,
  FormContainer,
  FormikContainer,
  ProductAttributeTypesWrapper,
  ProductTypeLabel,
  RelativeInputWrapper,
  Row,
  SpaceBetween,
} from './ProductDropdownAttributeForm.styled';
import { Formik } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { IProductAttribute, ProductAttributeType } from 'types/Product.types';
import { useTranslation } from 'react-i18next';
import { validateField } from 'utils/validation';
import { CheckboxBig } from 'components/CheckboxBig/CheckboxBig';
import { formatOptionError } from './helpers';
import Icon from 'components/Icon/Icon';
import Button from 'components/Button/Button';
import { Plus, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const PWID = 'product-dropdown-attribute-form';

interface IProductDropdownAttributeFormProps {
  isEditMode: boolean;
  isValidForAttribute: boolean;
  setIsValidForAttribute: (attributeIndex: number, isValid: boolean) => void;
  areAllFieldsTouched: boolean;
  attributeIndex: number;
  attribute: IProductAttribute;
  pwId?: string;
  handleSetAttribute: (
    attributeIndex: number,
    values: IProductAttribute | undefined
  ) => void;
}

export const ProductDropdownAttributeForm = ({
  isEditMode,
  isValidForAttribute,
  setIsValidForAttribute,
  areAllFieldsTouched,
  attributeIndex,
  attribute,
  handleSetAttribute,
  pwId,
}: IProductDropdownAttributeFormProps) => {
  const { t } = useTranslation();

  const { isPhone } = useBreakpointFlag();

  return (
    <FormContainer>
      <Formik
        enableReinitialize
        initialValues={attribute}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={() => {
          // onSubmit
        }}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          setValues,
          isValid,
          values,
          errors,
        }) => {
          if (isValid !== isValidForAttribute) {
            setIsValidForAttribute(attributeIndex, isValid);
          }
          return (
            <FormikContainer>
              <SpaceBetween>
                <ProductAttributeTypesWrapper>
                  <ProductTypeLabel
                    data-testid={`${pwId}-${PWID}-field-label-${attributeIndex}`}
                    onClick={() => {
                      handleSetAttribute(attributeIndex, {
                        ...values,
                        type: ProductAttributeType.FIELD,
                      });
                    }}
                    isSelected={values.type === ProductAttributeType.FIELD}
                  >
                    {t(ProductAttributeType.FIELD)}
                  </ProductTypeLabel>
                  <ProductTypeLabel
                    data-testid={`${pwId}-${PWID}-dropdown-label-${attributeIndex}`}
                    onClick={() => {
                      handleSetAttribute(attributeIndex, {
                        ...values,
                        type: ProductAttributeType.DROPDOWN,
                      });
                    }}
                    isSelected={values.type === ProductAttributeType.DROPDOWN}
                  >
                    {t(ProductAttributeType.DROPDOWN)}
                  </ProductTypeLabel>
                </ProductAttributeTypesWrapper>
                {isEditMode ? null : (
                  <Icon
                    pwId={`${pwId}-${PWID}-trash-${attributeIndex}`}
                    svg={Trash}
                    size={20}
                    weight="regular"
                    color={COLORS.RED_200}
                    onClick={() =>
                      handleSetAttribute(attributeIndex, undefined)
                    }
                  />
                )}
              </SpaceBetween>
              <Input
                pwId={`${pwId}-${PWID}-dropdown-field-name-input-${attributeIndex}`}
                errorMessage={areAllFieldsTouched ? errors['fieldName'] : ''}
                height={'41rem'}
                name="fieldName"
                placeholder={t('Field name')}
                validate={(value: string) => validateField(value, 'fieldName')}
                onBlur={(e) => {
                  const value = e.target.value.trim();
                  setFieldValue('fieldName', value);
                  handleBlur(e);
                  handleSetAttribute(attributeIndex, {
                    ...values,
                    fieldName: value,
                  });
                }}
                wrapperStyles={{
                  width: isPhone ? '100%' : '300rem',
                }}
              />
              <SpaceBetween>
                <DropDownOptionsLabel
                  data-testid={`${pwId}-${PWID}-drop-down-options-label-${attributeIndex}`}
                >
                  {t('Drop down options')}
                </DropDownOptionsLabel>
                <DefaultLabel
                  data-testid={`${pwId}-${PWID}-default-label-${attributeIndex}`}
                >
                  {t('Default')}
                </DefaultLabel>
              </SpaceBetween>
              {values.options?.map((option: string, key: number) => (
                <Row key={key}>
                  <RelativeInputWrapper>
                    <Input
                      pwId={`${pwId}-${PWID}-option-field-${key}-${attributeIndex}`}
                      validate={(value: string) =>
                        validateField(value, `options.${key}`)
                      }
                      errorMessage={
                        areAllFieldsTouched
                          ? errors.options
                            ? formatOptionError(errors.options[key])
                            : undefined
                          : ''
                      }
                      height={'41rem'}
                      name={`options.${key}`}
                      placeholder={t('Option') + ' ' + (key + 1)}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue(`options.${key}`, value);
                        handleBlur(e);
                        const newOptions = values.options;
                        newOptions?.splice(key, 1, value);
                        handleSetAttribute(attributeIndex, {
                          ...values,
                          options: newOptions,
                        });
                      }}
                      wrapperStyles={{
                        width: isPhone ? '100%' : '300rem',
                      }}
                    />
                    {values.options && values.options.length > 2 && (
                      <DeleteProductFieldIcon
                        data-testid={`${pwId}-${PWID}-trash-icon`}
                        onClick={() => {
                          setValues({
                            ...values,
                            options: values.options?.filter(
                              (option, index: number) => index !== key
                            ),
                          });
                          const newOptions = values.options?.filter(
                            (option, index: number) => index !== key
                          );
                          handleSetAttribute(attributeIndex, {
                            ...values,
                            options: newOptions,
                          });
                        }}
                      >
                        <Icon
                          svg={Trash}
                          size={20}
                          color={COLORS.RED_200}
                          weight="regular"
                        />
                      </DeleteProductFieldIcon>
                    )}
                  </RelativeInputWrapper>
                  <CheckboxBig
                    pwId={`${pwId}-${PWID}-option-checkbox-${key}-${attributeIndex}`}
                    isChecked={values.defaultOptionIndex === key}
                    onChange={() => {
                      const isChecked = values.defaultOptionIndex === key;
                      handleSetAttribute(attributeIndex, {
                        ...values,
                        hasDefaultValue: !isChecked,
                        defaultOptionIndex: isChecked ? null : key,
                      });
                    }}
                    containerStyle={{ marginTop: '20rem', marginLeft: '18rem' }}
                  />
                </Row>
              ))}
              <AddMoreOptionsWrapper>
                <Button
                  pwId={`${pwId}-${PWID}-add-more-options-button`}
                  label={t('Add more options')}
                  icon={Plus}
                  sizeIcon={20}
                  colorIcon={COLORS.PRIMARY}
                  link
                  onClick={() => {
                    if (values.options && Array.isArray(values.options)) {
                      const options = [...values.options];
                      options.push('');
                      setFieldValue('options', options);
                    }
                  }}
                  fontSize="14rem"
                />
              </AddMoreOptionsWrapper>
            </FormikContainer>
          );
        }}
      </Formik>
    </FormContainer>
  );
};
