import {
  COLORS,
  borderRadiusSm,
  marginLg,
  marginSm,
  paddingMd,
} from 'assets/styled';
import styled from 'styled-components';
import Button from 'components/Button/Button';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 850rem;
  width: 100%;
  align-self: center;
  height: 100%;

  ${respondTo.smallTablet`
    max-width: calc(100% - 40rem);
  `}

  ${respondTo.bigPhone`
    max-width: calc(100% - 30rem);
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${marginSm};
  align-items: center;

  ${respondTo.bigPhone`
    flex-direction: column;
    align-items: flex-start;
    gap: 10rem;
  `}
`;

export const FromToLabel = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${respondTo.bigPhone`
    align-self: flex-end;
    gap: 10rem;
    flex-wrap: wrap;
  `}
`;

export const WorkersButton = styled(Button)``;

export const WorkersButtonWrapper = styled.div`
  position: relative;
  margin-right: ${marginSm};

  svg {
    z-index: 1;
  }

  &:hover svg {
    filter: brightness(0) invert(1);
  }
`;

export const ResourcesButtonWrapper = styled.div`
  position: relative;

  svg {
    z-index: 1;
  }

  &:hover svg {
    filter: brightness(0) invert(1);
  }
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  right: 30rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.3s;
`;

export const WorkersIcon = styled.div`
  position: absolute;
  left: 30rem;
  top: 10rem;
`;

export const ResourcesIcon = styled.div`
  position: absolute;
  left: 30rem;
  top: 11rem;
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${marginLg};

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const DescriptionCard = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
  display: flex;
  flex-direction: column;
  gap: 30rem;
`;
export const DescriptionCardTitle = styled.label`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
`;
export const DescriptionLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
`;
