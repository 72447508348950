import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { IProductAttribute } from 'types/Product.types';
import { useEditStockItemAttributes } from '../hooks';
import { Container } from './AddEditDefaultFields.styled';
import { AddDefaultFieldsForm } from 'pages/Manager/SingleSupplierPage/SingleSupplierProductsPage/AddEditProduct/AddDefaultFieldsForm/AddDefaultFieldsForm';
import {
  CloseContainer,
  TitleContainer,
  Title,
} from 'pages/Manager/SingleSupplierPage/SingleSupplierProductsPage/AddEditProduct/AddEditProduct.styled';

const EMPTY_ARRAY = 0;
const PWID = 'add-edit-default-fields';

export interface IAddEditDefaultFieldsProps {
  onCancel: () => void;
  stockItemAttributes: IProductAttribute[];
  stockItemId: string;
  pwId?: string;
}

const AddEditDefaultFields = ({
  onCancel,
  stockItemAttributes,
  stockItemId,
  pwId,
}: IAddEditDefaultFieldsProps) => {
  const { t } = useTranslation();
  const [attributes, setAttributes] =
    useState<IProductAttribute[]>(stockItemAttributes);

  const { mutate: editStockItemAttributes, isLoading } =
    useEditStockItemAttributes();

  const handleEditStockItemAttributes = async () => {
    if (attributes)
      editStockItemAttributes({
        attributes: attributes,
        stockItemId: stockItemId,
      });
    onCancel();
  };

  return (
    <Container>
      <CloseContainer>
        <Close pwId={`${pwId}-${PWID}`} onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title data-testid={`${pwId}-${PWID}-title`}>
          {t('Edit stock item')}
        </Title>
      </TitleContainer>

      <AddDefaultFieldsForm
        pwId={`${pwId}-${PWID}`}
        attributes={attributes}
        setAttributes={setAttributes}
        onCancel={onCancel}
        isLoading={isLoading}
        handleSubmit={handleEditStockItemAttributes}
        isStockItem
        disableSubmitButton={
          stockItemAttributes?.length === EMPTY_ARRAY &&
          attributes?.length === EMPTY_ARRAY
        }
      />
    </Container>
  );
};

export default AddEditDefaultFields;
