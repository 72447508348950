import { paddingLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const LayoutColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 75rem;

  ${respondTo.horizontalTablet`
    padding-bottom: ${paddingLg};
  `}
`;

export const LayoutRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;
