import {
  ButtonContainer,
  Container,
  TopContentContainer,
  LabelKey,
  NonEditModeLabelWrapper,
  RowHalf,
  RowWrapper,
  SignatureImage,
  SignatureWrapper,
  Wrapper,
  StyledCustomSelect,
} from './SingleSalesOrderNewSignatureForm.styled';
import Button from 'components/Button/Button';
import { YesOrNoModal } from 'components/Modal/YesOrNoModal/YesOrNoModal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { Field, Formik } from 'formik';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { BASE_64_PREFIX } from 'pages/Manager/SingleQuotation/constants';
import {
  useUpdateSalesOrderSignature,
  useUploadSalesOrderSignature,
} from 'pages/Manager/SingleSalesOrder/hooks';
import { Option } from 'components/Select/type';
import { useGetAppointmentOptions, useGetTypeOptions } from './hooks';
import { useGetSingleSalesOrderAppointments } from 'pages/Manager/SingleSalesOrder/SingleSalesOrderAppointmentsPage/hooks';
import { removeBase64Prefix } from './helpers';
import moment from 'moment';
import { AppointmentType, AppointmentTypeIds } from 'types/Appointment.types';
import DatePicker from 'components/DatePicker/DatePicker';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { DiscardModal } from 'components/Modal/DiscardModal/DiscardModal';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface ISignatureFormDTO {
  salesOrderSignatureId: number | null;
  salesOrderId: number;
  full_name: string;
  date: string;
  appointmentId: number | null;
  type: AppointmentType | null;
  signature: any;
}

interface ISingleSalesOrderNewSignatureFormProps {
  handleRemoveSignatureForm: () => void;
  signatureFormDTO: ISignatureFormDTO;
  handleUpdateSignatureForm: (newFormDTO: ISignatureFormDTO) => void;
}

export const SingleSalesOrderNewSignatureForm = ({
  handleRemoveSignatureForm,
  signatureFormDTO,
  handleUpdateSignatureForm,
}: ISingleSalesOrderNewSignatureFormProps) => {
  // formikValues is used for updating the SignatureFormDTO after isSuccess triggers the useEffect
  const [formikValues, setFormikValues] = useState<any>(null);
  const [selectedAppointmentType, setSelectedAppointmentType] = useState<
    AppointmentTypeIds | undefined
  >(undefined);
  const [isEditMode, setIsEditMode] = useState<boolean>(
    signatureFormDTO.signature ? false : true
  );
  const { id } = useParams();
  const { t } = useTranslation();
  const { isSmallTablet } = useBreakpointFlag();

  const sigRef = React.useRef() as React.MutableRefObject<any>;
  const hasSignature = signatureFormDTO.signature;
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [isDiscardModalOpen, setIsDiscardModalOpen] = useState<boolean>(false);

  const { darkMode } = useSelector(
    (state: IRootReducerState) => state.commonInfo
  );

  const {
    data: uploadSignatureData,
    mutate: uploadSalesOrderSignature,
    isSuccess: isSuccessUploadSalesOrderSignature,
    isLoading: isLoadingUploadSignature,
    reset: resetUploadSignature,
  } = useUploadSalesOrderSignature(id!);

  const {
    mutate: updateSalesOrderSignature,
    isSuccess: isSuccessUpdateSignature,
    isLoading: isLoadingUpdateSignature,
    reset: resetUpdateSignature,
  } = useUpdateSalesOrderSignature(
    id!,
    signatureFormDTO?.salesOrderSignatureId
      ? signatureFormDTO?.salesOrderSignatureId?.toString()
      : ''
  );

  const isSuccess =
    isSuccessUploadSalesOrderSignature || isSuccessUpdateSignature;

  useEffect(() => {
    if (isSuccess) {
      setIsEditMode(false);
      // After the signature is updated/uploaded, we need to display the new signature by updating the signatureFormDTO
      // Prefix is removed here, because it is appended again with BASE_64_PREFIX in the SignatureImage src
      const newImageUrl = removeBase64Prefix(
        sigRef.current?.getCanvas().toDataURL('base64')
      );

      const newSignatureFormDTO: ISignatureFormDTO = {
        salesOrderSignatureId: uploadSignatureData
          ? uploadSignatureData.sales_order_signature?.id
          : signatureFormDTO.salesOrderSignatureId,
        salesOrderId: signatureFormDTO.salesOrderId,
        full_name: formikValues.full_name,
        date: formikValues.date,
        appointmentId: formikValues.appointmentId,
        type: formikValues.type,
        signature: newImageUrl,
      };
      handleUpdateSignatureForm(newSignatureFormDTO);
      resetUpdateSignature();
      resetUploadSignature();
    }
  }, [isSuccess]);

  const [isToggleEditModeModalOpen, setIsToggleEditModeModalOpen] =
    useState<boolean>(false);

  const clearSignature = () => {
    sigRef.current.clear();
  };

  const handleSubmit = async (values: any) => {
    const url = sigRef.current?.getCanvas().toDataURL('image/png');
    const blob = await fetch(url).then((r) => r.blob());

    if (!url) {
      return;
    }
    setFormikValues(values); // formikValues is used for updating the SignatureFormDTO after isSuccess triggers the useEffect
    if (
      !signatureFormDTO.signature &&
      !signatureFormDTO.salesOrderSignatureId
    ) {
      uploadSalesOrderSignature({
        file: blob,
        salesOrderId: id || '',
        full_name: values.full_name,
        date: `${moment(values.date).format('YYYY-MM-DD')} 00:00:00`,
        ...(signatureFormDTO.appointmentId && {
          appointmentId: signatureFormDTO.appointmentId,
        }),
        type: values.type?.value ? values.type?.value : values.type, // When selecting an option manually, the field value inside FormikSelect is set to option.value..
      });
    } else {
      updateSalesOrderSignature({
        file: blob,
        salesOrderId: id || '',
        full_name: values.full_name,
        date: `${moment(values.date).format('YYYY-MM-DD')} 00:00:00`,
        ...(signatureFormDTO.appointmentId && {
          appointmentId: signatureFormDTO.appointmentId,
        }),
        type: values.type?.value ? values.type?.value : values.type,
      });
    }
  };

  const { data: appointmentData } = useGetSingleSalesOrderAppointments(
    id!,
    undefined,
    undefined,
    selectedAppointmentType
  );

  const appointmentOptions: Option[] = useGetAppointmentOptions(
    appointmentData?.appointments || []
  );
  const typeOptions: Option[] = useGetTypeOptions();

  return (
    <Container>
      <Formik
        enableReinitialize
        onSubmit={() => {
          // on Submit
        }}
        validateOnChange={true}
        validateOnBlur={true}
        initialValues={{
          full_name: signatureFormDTO.full_name,
          date: signatureFormDTO.date,
          appointmentId: signatureFormDTO.appointmentId,
          type: typeOptions.find(
            (option) => option.value == signatureFormDTO.type
          ),
          signature: signatureFormDTO.signature,
        }}
        validateOnMount={true}
      >
        {({ setFieldValue, values, handleBlur }) => {
          return (
            <Wrapper>
              <TopContentContainer>
                <RowWrapper>
                  <RowHalf>
                    <LabelKey>{t('Name')}</LabelKey>
                    {isEditMode ? (
                      <FormikInput
                        pwId="name-field"
                        type="text"
                        onBlur={(e) => {
                          const value = e.target.value.trim();
                          setFieldValue('name', value);
                          handleBlur(e);
                        }}
                        height={'41rem'}
                        name="full_name"
                        wrapperStyles={{
                          width: isSmallTablet ? '100%' : '300rem',
                        }}
                      />
                    ) : (
                      <NonEditModeLabelWrapper>
                        {values.full_name}
                      </NonEditModeLabelWrapper>
                    )}
                  </RowHalf>
                  <RowHalf>
                    <LabelKey>{t('Date')}</LabelKey>
                    {isEditMode ? (
                      <DatePicker
                        pwId="date-field"
                        date={moment(values?.date)}
                        setDate={(newValue: any) =>
                          setFieldValue('date', newValue?.format('YYYY-MM-DD'))
                        }
                        width={isSmallTablet ? '100%' : ''}
                      />
                    ) : (
                      <NonEditModeLabelWrapper>
                        {values.date}
                      </NonEditModeLabelWrapper>
                    )}
                  </RowHalf>
                </RowWrapper>
                <RowWrapper>
                  <RowHalf>
                    <LabelKey>{t('Type')}</LabelKey>
                    {isEditMode ? (
                      <Field
                        pwId="type-field"
                        key={`unique_select_key__${values.type}`}
                        height={'41rem'}
                        name="type"
                        width="203rem"
                        options={typeOptions}
                        component={StyledCustomSelect}
                        selectedValue={values.type?.value}
                        placeholder={''}
                        isMulti={false}
                        onSelect={(option: Option) => {
                          const appointmentTypeIdKey = Object.keys(
                            AppointmentTypeIds
                          ).find((type) => {
                            return (
                              type.toLowerCase() === option.label.toLowerCase()
                            );
                          });
                          setSelectedAppointmentType(
                            appointmentTypeIdKey
                              ? AppointmentTypeIds[appointmentTypeIdKey]
                              : undefined
                          );
                          // Reset appointment dropdown when type is changed
                          setFieldValue('appointmentId', '');
                          setFieldValue('type', option);
                        }}
                      />
                    ) : (
                      <NonEditModeLabelWrapper>
                        {values.type?.label}
                      </NonEditModeLabelWrapper>
                    )}
                  </RowHalf>
                  <RowHalf>
                    <LabelKey>{t('Appointment')}</LabelKey>
                    {isEditMode ? (
                      <Field
                        pwId="appointment-field"
                        disabled={!values.type}
                        key={`unique_select_key__${values.appointmentId}`}
                        height={'41rem'}
                        name="appointmentId"
                        width="203rem"
                        selectedValue={values.appointmentId}
                        options={appointmentOptions}
                        component={StyledCustomSelect}
                        placeholder={''}
                        isMulti={false}
                        onSelect={(option: Option) => {
                          setFieldValue('appointmentId', option);
                        }}
                      />
                    ) : (
                      <NonEditModeLabelWrapper>
                        {values.appointmentId}
                      </NonEditModeLabelWrapper>
                    )}
                  </RowHalf>
                </RowWrapper>
              </TopContentContainer>

              <SignatureWrapper
                data-testid="signature-canvas"
                $darkMode={darkMode}
              >
                {isEditMode ? (
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{ className: 'signature' }}
                    ref={sigRef}
                    clearOnResize={false}
                  />
                ) : hasSignature ? (
                  <SignatureImage
                    src={`${BASE_64_PREFIX}${signatureFormDTO.signature}`}
                    alt="signature"
                  />
                ) : null}
              </SignatureWrapper>
              <ButtonContainer>
                {isEditMode ? (
                  <>
                    <Button
                      width="200rem"
                      secondary
                      label={t('Cancel')}
                      onClick={() => {
                        setIsDiscardModalOpen(true);
                      }}
                    />
                    <Button
                      width="200rem"
                      disabled={
                        isLoadingUpdateSignature ||
                        isLoadingUploadSignature ||
                        !values.type ||
                        !values.full_name ||
                        !values.date
                      }
                      primary
                      label={t('Confirm')}
                      onClick={() => setIsConfirmModalOpen(true)}
                    />
                  </>
                ) : (
                  <Button
                    // disabled={isQuotationClosed(data)}
                    width="200rem"
                    primary
                    label={hasSignature ? t('Edit') : t('Add signature')}
                    onClick={() => {
                      if (hasSignature) {
                        setIsToggleEditModeModalOpen(true);
                      } else {
                        setIsEditMode(true);
                      }
                    }}
                  />
                )}
              </ButtonContainer>
              <ConfirmModal
                title={t('New Signature')}
                description={`${t(
                  'Are you sure you want to create a New Signature'
                )}?`}
                isOpen={isConfirmModalOpen}
                setIsOpen={setIsConfirmModalOpen}
                onConfirm={() => {
                  handleSubmit(values);
                  setIsConfirmModalOpen(false);
                }}
                onCancel={() => setIsConfirmModalOpen(false)}
              />
              <DiscardModal
                title={t('Discard Signature')}
                description={`${t(
                  'Are you sure you want to discard this Signature'
                )}?`}
                isOpen={isDiscardModalOpen}
                setIsOpen={setIsDiscardModalOpen}
                onDiscard={() => {
                  if (hasSignature) {
                    setIsEditMode(false);
                  } else {
                    handleRemoveSignatureForm();
                  }
                  setIsDiscardModalOpen(false);
                }}
                onCancel={() => setIsDiscardModalOpen(false)}
              />
            </Wrapper>
          );
        }}
      </Formik>
      <YesOrNoModal
        pwId="yes-or-no-add-new-signature-modal"
        isOpen={isToggleEditModeModalOpen}
        setIsOpen={setIsToggleEditModeModalOpen}
        title={''}
        description={
          t(
            'A signature already exists. Do you want to add a new signature? This will clear the existing signature'
          ) + '?'
        }
        onYes={() => {
          setIsEditMode(true);
          setIsToggleEditModeModalOpen(false);
          setTimeout(() => clearSignature(), 100);
        }}
        onNo={() => setIsToggleEditModeModalOpen(false)}
      />
    </Container>
  );
};
