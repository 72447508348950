import { COLORS, H3 } from 'assets/styled';
import Input from 'components/Input/Input';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { formatStockItemsTableData } from 'components/Table/tableDataFormatter';
import Table from 'components/Table/Table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IStockItem, IStockItemTableDTO } from 'types/StockItem.types';
import { STOCK_ITEMS_PER_PAGE, tableConfig } from './constants';
import { getStatusValue } from './helpers';
import {
  useGetStockItems,
  useGetStockItemsPageFilters,
  useManageAndSaveFilters,
} from './hooks';
import {
  AdvancedFilterWrapper,
  ButtonWrapper,
  Container,
  CreateItemButton,
  FlexAlignCenter,
  Row,
  RowRightPart,
  SelectInputWrapper,
  TitleContainer,
} from './StockItemsPage.styled';
import Button from 'components/Button/Button';
import { RoutesConfig } from 'navigation/routes';
import { useDebounce } from 'utils/hooks/useDebounce';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { Funnel, MagnifyingGlass } from '@phosphor-icons/react';
import AdvancedFilterModal from 'components/Modal/AdvancedFilterModal/AdvancedFilterModal';
import { useRef, useState } from 'react';
import { useGetItemsAdvancedFilters } from './advancedFilters';
import { formatInitialAdvancedFilters } from 'components/Modal/AdvancedFilterModal/helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import Icon from 'components/Icon/Icon';
import FilterMobileModal from './FilterMobileModal/FilterMobileModal';
import { useManageProductGroupsSelect } from '../SingleSupplierPage/SingleSupplierProductsPage/AddEditProduct/hooks';
import CustomMenuList from 'components/Select/SelectComponents/CustomMenuList/CustomMenuList';
import { Pen } from '@phosphor-icons/react';

const PWID = 'stock-items-page';

const StockItemsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isAdvancedFilterModalOpen, setIsAdvancedFilterModalOpen] =
    useState<boolean>(false);
  const [isMobileFilterModalOpen, setIsMobileFilterModalOpen] =
    useState<boolean>(false);

  const { isSmallTablet } = useBreakpointFlag();
  const [selectedProductGroupId, setSelectedProductGroupId] =
    useState<string>();

  const canCreateStock = useCan(Actions.CREATE_STOCK_ITEM);

  const { stockItemsPageFilters, statusOptions } =
    useGetStockItemsPageFilters();

  const filtersData = useManageAndSaveFilters(stockItemsPageFilters);

  const {
    page,
    setPage,
    searchBy,
    setSearchBy,
    selectedStatusOption,
    setSelectedStatusOption,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    advancedFilters,
    setAdvancedFilters,
  } = filtersData;

  const debouncedSearchBy = useDebounce(searchBy);
  const scrollPositionRef = useRef(0);

  const { isLoading, data } = useGetStockItems(
    page,
    STOCK_ITEMS_PER_PAGE,
    debouncedSearchBy,
    getStatusValue(selectedStatusOption.value),
    sortBy,
    sortDirection,
    undefined, // Supplier id
    advancedFilters,
    selectedProductGroupId
  );

  const {
    productGroups,
    isLoadingProductGroups,
    hasNextPage,
    fetchNextPage,
    setSearchBy: setProductGroupSearchBy,
  } = useManageProductGroupsSelect();

  let tableData: IStockItemTableDTO[] = [];
  if (data) {
    tableData = formatStockItemsTableData(
      data.stock_items
        .map((stockItem: IStockItem[]) => {
          return stockItem;
        })
        .flat()
    );
  }

  const handleViewButtonClick = (rowData: IStockItemTableDTO) => {
    navigate(`${rowData.navigationID}`);
  };

  const { filterTypes } = useGetItemsAdvancedFilters(statusOptions);

  return (
    <Container>
      <TitleContainer>
        <H3 data-testid={`${PWID}-overview-title`}>{t('Overview')}</H3>
        <ButtonWrapper>
          <Button
            pwId={`${PWID}-create-item-button`}
            width={'200rem'}
            onClick={() => navigate(RoutesConfig.NewStockItem.fullPath)}
            label={t('Create stock item')}
            primary
            disabled={!canCreateStock}
          />
        </ButtonWrapper>
      </TitleContainer>
      <Row>
        <FlexAlignCenter>
          <Input
            pwId={`${PWID}-search-field`}
            icon={MagnifyingGlass}
            placeholder={t('Search')}
            width={isSmallTablet ? '100%' : '400rem'}
            wrapperStyles={{ width: isSmallTablet ? '100%' : '' }}
            height="41rem"
            value={searchBy}
            onChange={(e) => setSearchBy(e.target.value)}
          />

          {isSmallTablet && (
            <Icon
              svg={Funnel}
              size={30}
              color={COLORS.PRIMARY}
              weight="regular"
              onClick={() => setIsMobileFilterModalOpen(true)}
            />
          )}
        </FlexAlignCenter>
        <RowRightPart>
          <SelectInputWrapper>
            <Select
              pwId={`${PWID}-status-field`}
              defaultValue={
                stockItemsPageFilters.selectedStatusOption
                  ? stockItemsPageFilters.selectedStatusOption
                  : statusOptions[0]
              }
              name="status"
              placeholder={t('Status')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => setSelectedStatusOption(e)}
              options={statusOptions}
            />
          </SelectInputWrapper>
          <SelectInputWrapper>
            <Select
              pwId={`${PWID}-select-group`}
              name="product_group"
              placeholder={t('Group')}
              isMulti={false}
              isDisabled={false}
              isSearchable={true}
              onChange={(e: Option) => {
                setSelectedProductGroupId(e ? e.value.id : '');
              }}
              onInputChange={(e: any) => setProductGroupSearchBy(e)}
              options={productGroups}
              isLoading={isLoadingProductGroups}
              isClearable
              components={{
                MenuList: (props) => (
                  <CustomMenuList
                    {...props}
                    scrollPositionRef={scrollPositionRef}
                    label={t('Manage groups')}
                    onClick={() => {
                      navigate(RoutesConfig.ProductGroups.fullPath);
                    }}
                    onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
                    icon={Pen}
                  />
                ),
              }}
            />
          </SelectInputWrapper>
        </RowRightPart>
      </Row>
      {isSmallTablet && (
        <CreateItemButton>
          <Button
            width={'100%'}
            onClick={() => navigate(RoutesConfig.NewStockItem.fullPath)}
            label={t('Create stock item')}
            primary
            disabled={!canCreateStock}
          />
        </CreateItemButton>
      )}
      <AdvancedFilterWrapper>
        <Button
          pwId={`${PWID}-advanced-filter-button`}
          onClick={() => setIsAdvancedFilterModalOpen(true)}
          label={t('Advanced filter')}
          width="145rem"
          height="31rem"
          secondary={!advancedFilters}
          primary={!!advancedFilters}
        />
      </AdvancedFilterWrapper>

      <Table
        pwId={PWID}
        isLoading={isLoading}
        page={page}
        perPage={STOCK_ITEMS_PER_PAGE}
        total={data?.total}
        tableData={tableData}
        tableConfig={tableConfig}
        hasExtraAction={true}
        loadPage={(newPage) => setPage(newPage)}
        onViewButtonClick={handleViewButtonClick}
        sortParams={{ sortBy, setSortBy, sortDirection, setSortDirection }}
      />
      <AdvancedFilterModal
        pwId={PWID}
        isOpen={isAdvancedFilterModalOpen}
        setIsOpen={setIsAdvancedFilterModalOpen}
        filterTypes={filterTypes}
        setAdvancedFilters={setAdvancedFilters}
        initialFilterGroups={
          advancedFilters
            ? formatInitialAdvancedFilters(advancedFilters)
            : undefined
        }
      />

      {isSmallTablet && (
        <FilterMobileModal
          isOpen={isMobileFilterModalOpen}
          setIsOpen={setIsMobileFilterModalOpen}
          onCancel={() => setIsMobileFilterModalOpen(false)}
          filtersData={filtersData}
          statusOptions={statusOptions}
          setIsAdvancedFilterModalOpen={setIsAdvancedFilterModalOpen}
          advancedFilters={advancedFilters}
          setSelectedProductGroupId={setSelectedProductGroupId}
          isLoadingProductGroups={isLoadingProductGroups}
          productGroups={productGroups}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          setProductGroupSearchBy={setProductGroupSearchBy}
        />
      )}
    </Container>
  );
};

export default StockItemsPage;
