import { COLORS, gapSm, marginLg, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSpaceBetween: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-bottom: ${marginLg};
`;

export const Wrapper = styled.div`
  width: calc(100% - 265rem);
  display: flex;
  flex-direction: column;
  padding: 50rem;
  padding-bottom: 0;
  margin-left: 265rem;

  ${respondTo.horizontalTablet`
    margin-left: 0rem;
    width: 100%;
    padding: 30rem;
  `}

  ${respondTo.smallTablet`
    padding: 0;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10rem;
  justify-content: space-between;
  margin-bottom: ${marginLg};

  ${respondTo.smallTablet`
    padding: 0 20rem;
    margin-bottom: 20rem;
  `}
`;

export const MenuWrapper = styled.div`
  margin-bottom: 20rem;
`;

export const RightSide = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: ${marginMd};
  ${(props: StyledProps) =>
    props.isSpaceBetween &&
    css`
      justify-content: space-between;
    `};
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const H3 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24rem;
  padding-right: 30rem;
  color: ${COLORS.BLACK};
`;

export const ThreeDots = styled.div`
  margin-left: auto;
`;
