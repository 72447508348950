import {
  CheckboxContainer,
  ClearLabel,
  Container,
  GeneralDiscountContainer,
  GeneralDiscountWrapper,
  RightAlign,
  Row,
} from './SingleQuotationProductsPage.styled';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetQuotationActiveAppointmentInfo,
  useGetSingleQuotation,
} from '../hooks';
import {
  IQuotation,
  IQuotationLine,
  QuotationTypeIdsEnum,
} from 'types/Quotations.types';
import { useEffect, useState } from 'react';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';
import {
  useDeleteQuotationLine,
  useEditQuotationGeneralDiscount,
  useEditQuotationPricesDefinitive,
} from './hooks';
import { canManipulateQuotationLines } from './helpers';
import { Modal } from 'components/Modal/Modal';
import { Danger } from 'components/Modal/Danger/Danger';
import { RoutesConfig } from 'navigation/routes';
import { OkModal } from 'components/Modal/OkModal/OkModal';
import { EditQuotationWorkingHoursLineModal } from './EditQuotationWorkingHoursLineModal/EditQuotationWorkingHoursLineModal';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { AddEmailNoteModal } from 'components/Modal/AddEmailNoteModal/AddEmailNoteModal';
import { AddEmailNoteModalType } from 'components/Modal/AddEmailNoteModal/constants';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import Button from 'components/Button/Button';
import DraggableLines, {
  DraggableLineType,
  IDraggableLine,
} from 'components/DragAndDrop/DraggableLines/DraggableLines';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { Info, Plus } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { useManageProductGroupsSelect } from 'pages/Manager/SingleSupplierPage/SingleSupplierProductsPage/AddEditProduct/hooks';
import Icon from 'components/Icon/Icon';
import Tooltip from 'components/Tooltip/Tooltip';
import Input from 'components/Input/Input';
import { IGeneralDiscountInfo } from 'pages/Manager/NewQuotationPage/constants';
import Spinner from 'components/Spinner/Spinner';

const GENERAL_DISCOUNT_MIN_VALUE = 0;
const GENERAL_DISCOUNT_MAX_VALUE = 100;

const SingleQuotationProductsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isSendEmailModalOpen, setIsSendEmailModalOpen] =
    useState<boolean>(false);
  const [isAddEmailNoteModalOpen, setIsAddEmailNoteModalOpen] =
    useState<boolean>(false);
  const [isUnableToEditModalOpen, setIsUnableToEditModalOpen] =
    useState<boolean>(false);
  const [isEditWorkingHoursLineModalOpen, setIsEditWorkingHoursLineModalOpen] =
    useState<boolean>(false);
  const [workingHoursLineForEditing, setWorkingHoursLineForEditing] =
    useState<IQuotationLine | null>(null);
  const [selectedProductGroupId, setSelectedProductGroupId] =
    useState<string>();
  const [isConfirmEditModalOpen, setIsConfirmEditModalOpen] =
    useState<boolean>(false);
  const [isConfirmApplyToAllLinesOpen, setIsConfirmApplyToAllLinesOpen] =
    useState<boolean>(false);
  const [showGeneralDiscountInput, setShowGeneralDiscountInput] =
    useState<boolean>(false);
  const [generalDiscountInfo, setGeneralDiscountInfo] =
    useState<IGeneralDiscountInfo>({
      value: '',
      isAppliedToAllLines: false,
    });
  const [generalDiscountErrorMessage, setGeneralDiscountErrorMessage] =
    useState<string>('');

  const canEditQuotation = useCan(Actions.EDIT_QUOTATION);

  const {
    data,
    isLoading: isLoadingSingleQuotation,
    isFetched,
  } = useGetSingleQuotation(id!);
  const { isSuccess: appointmentInProgress } =
    useGetQuotationActiveAppointmentInfo(id!);

  useEffect(() => {
    if (isFetched && data?.default_line_discount) {
      setGeneralDiscountInfo({
        value: data?.default_line_discount.toString(),
        isAppliedToAllLines: true,
      });
      setShowGeneralDiscountInput(true);
    }
  }, [data]);

  // Quotation email is sent to general address
  const emailDetails = {
    email: data?.customer?.email,
    type: 'General',
  };
  const isDraft = data?.type_id === QuotationTypeIdsEnum.DRAFT;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [lineForDeletion, setLineForDeletion] = useState<IQuotationLine | null>(
    null
  );
  const [lineForEditing, setLineForEditing] = useState<IDraggableLine | null>(
    null
  );

  const {
    mutate: editPricesDefinitive,
    isLoading: isLoadingEditPricesDefinitive,
    isSuccess: isSuccessEditPricesDefinitive,
  } = useEditQuotationPricesDefinitive(id!);

  useEffect(() => {
    if (isSuccessEditPricesDefinitive) {
      // The value is previous because on isSuccessEditPricesDefinitive the single quotaton query
      // is invalidated and the new updated value doesnt get fetched yet
      const previousPricesDefinitiveValue = data?.prices_definitive;
      const newPricesDefinitiveValue = !previousPricesDefinitiveValue;
      if (newPricesDefinitiveValue) {
        setIsSendEmailModalOpen(true);
      }
    }
  }, [isSuccessEditPricesDefinitive]);

  const {
    mutate: deleteQuotationLine,
    isLoading: deleteQuotationLineIsLoading,
  } = useDeleteQuotationLine(id!);

  const handleDeleteClick = (line: IQuotationLine) => {
    setLineForDeletion(line);
    setIsDeleteModalOpen(true);
  };

  const handleEditClick = (line: IQuotationLine) => {
    if (line.working_hours_line) {
      setIsEditWorkingHoursLineModalOpen(true);
      setWorkingHoursLineForEditing(line);
    } else {
      navigate(RoutesConfig.NewQuotationLine.fullPath.replace(':id', id!), {
        state: {
          line: line,
          fromDraftQuotation: isDraft,
        },
      });
    }
  };

  const canManipulateLines = canManipulateQuotationLines(data as IQuotation);
  const { steps } = useRunTour(
    GuidePages.SINGLE_QUOTATION_PRODUCTS,
    id,
    isFetched
  );

  const {
    productGroups,
    isLoadingProductGroups,
    hasNextPage,
    fetchNextPage,
    setSearchBy: setProductGroupSearchBy,
  } = useManageProductGroupsSelect();

  const {
    mutate: editGeneralDiscount,
    isLoading: isLoadingEditGeneralDiscount,
  } = useEditQuotationGeneralDiscount(id!);

  const isGeneralDiscountEmpty = () => {
    const value = generalDiscountInfo?.value;
    return value === '' || value === null || value === undefined;
  };

  return (
    <Container className="single-quotation-step-6">
      <Row>
        <CheckboxContainer>
          <Checkbox
            disabled={
              isLoadingEditPricesDefinitive ||
              isLoadingSingleQuotation ||
              !canEditQuotation
            }
            isChecked={data?.prices_definitive}
            onChange={() => editPricesDefinitive(!data?.prices_definitive)}
            label={t('Prices definitive')}
            style={{ fontSize: '18rem' }}
          />
        </CheckboxContainer>
        {canEditQuotation && (
          <RightAlign>
            <Button
              label={t('Add new product')}
              link
              icon={Plus}
              sizeIcon={20}
              weightIcon="regular"
              colorIcon={COLORS.PRIMARY}
              onClick={() =>
                canManipulateLines && !appointmentInProgress
                  ? navigate(
                      RoutesConfig.NewQuotationLine.fullPath.replace(
                        ':id',
                        id!
                      ),
                      {
                        state: { generalDiscountInfo: generalDiscountInfo },
                      }
                    )
                  : setIsUnableToEditModalOpen(true)
              }
              fontSize="18rem"
            />
          </RightAlign>
        )}
      </Row>
      {isLoadingSingleQuotation ? (
        <Spinner />
      ) : (
        <GeneralDiscountContainer>
          {!showGeneralDiscountInput ? (
            <GeneralDiscountWrapper>
              <Button
                label={t('Add general discount')}
                secondary
                onClick={() => setShowGeneralDiscountInput(true)}
                fontSize="18rem"
              />
              <Tooltip
                content={t('General discount will be applied to all lines')}
              >
                <Icon svg={Info} color={COLORS.PRIMARY} size={25} />
              </Tooltip>
            </GeneralDiscountWrapper>
          ) : (
            <>
              <GeneralDiscountWrapper>
                <Input
                  error={generalDiscountErrorMessage != ''}
                  errorMessage={generalDiscountErrorMessage}
                  type="number"
                  icon={Info}
                  iconTooltip={t(
                    'General discount will be applied to all lines'
                  )}
                  width={'340rem'}
                  height={'40rem'}
                  placeholder={t('General discount %')}
                  defaultValue={
                    data?.default_line_discount === 0
                      ? ''
                      : data?.default_line_discount
                  }
                  onChange={(e) => {
                    setGeneralDiscountErrorMessage('');
                    const value = e.target.value;

                    if (
                      Number(value) >= GENERAL_DISCOUNT_MIN_VALUE &&
                      Number(value) <= GENERAL_DISCOUNT_MAX_VALUE
                    )
                      setGeneralDiscountInfo({
                        value: e.target.value,
                        isAppliedToAllLines: false,
                      });
                    else
                      setGeneralDiscountErrorMessage(
                        t('General discount should be between 0 and 100')
                      );
                  }}
                  style={{ fontSize: '16rem' }}
                />
                <Button
                  disabled={
                    isGeneralDiscountEmpty() || !!generalDiscountErrorMessage
                  }
                  label={t('Apply to all lines')}
                  secondary
                  onClick={() => {
                    setIsConfirmApplyToAllLinesOpen(true);
                  }}
                  fontSize="14rem"
                />
              </GeneralDiscountWrapper>

              {isLoadingEditGeneralDiscount && <Spinner size={15} />}
            </>
          )}
        </GeneralDiscountContainer>
      )}
      {/* <RightAlign>
        <SelectInputWrapper>
          <Select
            name="product_group"
            placeholder={t('Group')}
            isMulti={false}
            isDisabled={false}
            isSearchable={true}
            onChange={(e: Option) => {
              setSelectedProductGroupId(e ? e.value.id : '');
            }}
            onInputChange={(e: any) => setProductGroupSearchBy(e)}
            options={productGroups}
            isLoading={isLoadingProductGroups}
            onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
            isClearable
          />
        </SelectInputWrapper>
      </RightAlign> */}
      <DraggableLines
        lines={data?.quotation_lines}
        lineType={DraggableLineType.QUOTATION}
        onEditClick={
          canManipulateLines && !appointmentInProgress
            ? (quotationLine: IDraggableLine) => {
                if (
                  isDraft &&
                  (quotationLine as IQuotationLine).measurement_check
                ) {
                  setLineForEditing(quotationLine);
                  setIsConfirmEditModalOpen(true);
                } else handleEditClick(quotationLine as IQuotationLine);
              }
            : () => setIsUnableToEditModalOpen(true)
        }
        onDeleteClick={
          canManipulateLines && !appointmentInProgress
            ? (quotationLine: IDraggableLine) =>
                handleDeleteClick(quotationLine as IQuotationLine)
            : () => setIsUnableToEditModalOpen(true)
        }
        isDragDisabled={isLoadingSingleQuotation || !canEditQuotation}
        showMeasurementsToggle={isDraft}
      />
      <ConfirmModal
        isOpen={isSendEmailModalOpen}
        setIsOpen={setIsSendEmailModalOpen}
        onConfirm={() => {
          setIsSendEmailModalOpen(false);
          setIsAddEmailNoteModalOpen(true);
        }}
        onCancel={() => setIsSendEmailModalOpen(false)}
        title={t('Send E-mail')}
        description={
          t('Do you want to send the quotation to the customer') + '?'
        }
      />
      <AddEmailNoteModal
        isOpen={isAddEmailNoteModalOpen}
        setIsOpen={setIsAddEmailNoteModalOpen}
        onCancel={() => setIsAddEmailNoteModalOpen(false)}
        id={data?.id}
        type={AddEmailNoteModalType.QUOTATION}
        emailDetails={emailDetails}
      />
      <Modal
        level={'FIRST'}
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        modalStyle={{ position: 'fixed', margin: 'auto' }}
      >
        <Danger
          submit={{
            onClick: () => {
              if (lineForDeletion) {
                deleteQuotationLine(lineForDeletion.id.toString());
                setLineForDeletion(null);
                setIsDeleteModalOpen(false);
              }
            },
            text: t('Delete'),
            disabled: deleteQuotationLineIsLoading,
          }}
          cancel={{
            onClick: () => setIsDeleteModalOpen(false),
            text: t('Back'),
          }}
          title={t('Delete line')}
          description={
            t('Are you sure you want to delete this Quotation line') + '?'
          }
        />
      </Modal>
      <OkModal
        level="FIRST"
        onClick={() => setIsUnableToEditModalOpen(false)}
        description={
          appointmentInProgress
            ? t('You cannot edit a quotation with active appointment')
            : t('You cannot edit a closed quotation')
        }
        buttonLabel="OK"
        setIsOpen={setIsUnableToEditModalOpen}
        isOpen={isUnableToEditModalOpen}
      />
      {isEditWorkingHoursLineModalOpen && workingHoursLineForEditing && (
        <EditQuotationWorkingHoursLineModal
          isOpen={isEditWorkingHoursLineModalOpen}
          setIsOpen={setIsEditWorkingHoursLineModalOpen}
          onCancel={() => setIsEditWorkingHoursLineModalOpen(false)}
          quotationLine={workingHoursLineForEditing}
        />
      )}
      <ConfirmModal
        title={t('Edit Product')}
        description={`${t('Are you sure you want to edit this product')}?`}
        isOpen={isConfirmEditModalOpen}
        setIsOpen={setIsConfirmEditModalOpen}
        onConfirm={() => {
          handleEditClick(lineForEditing as IQuotationLine);
        }}
        onCancel={() => setIsConfirmEditModalOpen(false)}
        warningMessage={t(
          'Please note: When creating the draft quotation, it was indicated that not only the measurements but all fields must be checked.'
        )}
      />
      <ConfirmModal
        title={t('General discount')}
        description={`${t(
          'Are you sure you want to apply this discount to all existing lines'
        )}?`}
        isOpen={isConfirmApplyToAllLinesOpen}
        setIsOpen={setIsConfirmApplyToAllLinesOpen}
        onConfirm={() => {
          if (generalDiscountInfo?.value)
            editGeneralDiscount(Number(generalDiscountInfo?.value), {
              onSuccess: () => {
                setIsConfirmApplyToAllLinesOpen(false);
              },
            });
        }}
        onCancel={() => setIsConfirmApplyToAllLinesOpen(false)}
      />
      <IntroductionGuide steps={steps} />
    </Container>
  );
};

export default SingleQuotationProductsPage;
