import styled from 'styled-components';
import { P } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  max-width: 635rem;
  width: 100%;

  ${respondTo.smallTablet`
    max-width: 100%;
  `}
`;

export const Title = styled(P)`
  margin-bottom: 10rem;
`;
