import { H3, marginMd } from 'assets/styled';
import Button from 'components/Button/Button';
import FakeInput from 'components/FakeInput/FakeInput';
import {
  ICreateStockItemDetails,
  useGetUnitOfMeasureOptions,
} from '../ItemTab/constants';
import { ICreateStockItemSupplierDetails } from '../SupplierTab/constants';
import { useTranslation } from 'react-i18next';
import {
  Container,
  SupplierDetailsWrapper,
  FirstRow,
  Line,
  Margin,
  RowMarginTop,
  SectionTitle,
  SubmitRow,
  ItemsWrapper,
  FieldWrapper,
} from './OverviewTab.styled';
import { useGetTypeOptions } from '../ItemTab/hooks';
import { StockItemType } from 'pages/Manager/SingleStockItemPage/constants';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IOverviewTab {
  onForward: () => void;
  onBack: () => void;
  supplierDetails: ICreateStockItemSupplierDetails;
  item: ICreateStockItemDetails;
  status: string;
  pwId?: string;
}

const OverviewTab = ({
  onForward,
  onBack,
  supplierDetails,
  item,
  status,
  pwId,
}: IOverviewTab) => {
  const { t } = useTranslation();
  const typeOptions = useGetTypeOptions();
  const unitOfMeasureOptions = useGetUnitOfMeasureOptions();

  const { isSmallPhone, isPhone } = useBreakpointFlag();

  return (
    <Container>
      <FirstRow>
        <H3 data-testid={`${pwId}-overview-title`}>{t('Overview')}</H3>
      </FirstRow>
      <SupplierDetailsWrapper>
        <SectionTitle data-testid={`${pwId}-supplier-details-title`}>
          {t('Supplier Details')}
        </SectionTitle>
        <RowMarginTop>
          <FakeInput
            pwId={`${pwId}-contact-person-input`}
            label={supplierDetails.contact_person}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Contact person')}
          />
          <FakeInput
            pwId={`${pwId}-phone-number-input`}
            label={supplierDetails.phone}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Phone number')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            pwId={`${pwId}-company-name-input`}
            label={supplierDetails.company_name}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Company name')}
          />
          <FakeInput
            pwId={`${pwId}-vat-number-input`}
            label={supplierDetails.vat_number}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('VAT number')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            pwId={`${pwId}-email-input`}
            label={supplierDetails.email}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('E-mail')}
          />
          <FakeInput
            pwId={`${pwId}-coc-number-input`}
            label={supplierDetails.coc_number}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('COC number')}
          />
        </RowMarginTop>

        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            pwId={`${pwId}-code-input`}
            label={supplierDetails.code}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Code')}
          />
          <FakeInput
            pwId={`${pwId}-city-input`}
            label={supplierDetails.city}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('City')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            pwId={`${pwId}-street-input`}
            label={supplierDetails.street}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Street')}
          />
          <FakeInput
            pwId={`${pwId}-house-number-input`}
            label={supplierDetails.house_number}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('House number')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FieldWrapper>
            <FakeInput
              pwId={`${pwId}-country-input`}
              label={supplierDetails.country}
              isDropdown={true}
              width={'100%'}
              placeholder={t('Country')}
            />
          </FieldWrapper>
        </RowMarginTop>
        <Line />
      </SupplierDetailsWrapper>
      <ItemsWrapper>
        <SectionTitle data-testid={`${pwId}-item-details-title`}>
          {t('Item details')}
        </SectionTitle>
        <RowMarginTop>
          <FakeInput
            pwId={`${pwId}-name-input`}
            label={item?.name}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Name')}
          />
          <FakeInput
            pwId={`${pwId}-unit-of-measure-input`}
            label={
              unitOfMeasureOptions.find(
                (option) => option.value === item.unit_of_measure
              )?.label || item.unit_of_measure
            }
            isDropdown={true}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Unit of measure')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            pwId={`${pwId}-description-input`}
            label={item?.description}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Description')}
          />
          <FakeInput
            pwId={`${pwId}-type-input`}
            label={
              typeOptions.find((option) => option.value === item.type_id)
                ?.label || item.type_id
            }
            isDropdown={true}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Type')}
          />
        </RowMarginTop>

        <RowMarginTop marginTop={marginMd}>
          <FakeInput
            pwId={`${pwId}-purchase-price-input`}
            label={item?.purchase_price}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Purchase price exc. VAT')}
          />
          <FakeInput
            pwId={`${pwId}-sales-price-input`}
            label={item.sales_price}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Sales Price')}
          />
        </RowMarginTop>
        <RowMarginTop marginTop={marginMd}>
          {item.type !== StockItemType.SERVICE && (
            <FakeInput
              pwId={`${pwId}-in-stock-input`}
              label={item.in_stock}
              isDropdown={false}
              width={isPhone ? '100%' : '50%'}
              placeholder={t('In stock')}
            />
          )}
          <FakeInput
            pwId={`${pwId}-item-supplier-number-input`}
            label={item?.number_supplier || ''}
            isDropdown={false}
            width={isPhone ? '100%' : '50%'}
            placeholder={t('Item supplier number')}
          />
        </RowMarginTop>
      </ItemsWrapper>
      <SubmitRow>
        <Button
          pwId={`${pwId}-back-button`}
          width={isSmallPhone ? '150rem' : '200rem'}
          onClick={() => onBack()}
          label={t('Back')}
          secondary
          disabled={status === 'loading'}
        />
        <Margin>
          <Button
            pwId={`${pwId}-finish-button`}
            width={isSmallPhone ? '150rem' : '200rem'}
            onClick={() => onForward()}
            label={t('Finish')}
            primary
            disabled={status === 'loading'}
          />
        </Margin>
      </SubmitRow>
    </Container>
  );
};

export default OverviewTab;
