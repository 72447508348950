import { COLORS, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
  marginRight: string;
  isSelectAll: boolean;
  flex: number;
  isMoreLabel: boolean;
  isInputCell: boolean;
  isOnlyOne: boolean;
  isFirst: boolean;
  isLast: boolean;
  color: string;
  $withoutMargin: boolean;
  $selected: boolean;
}

export const Container = styled.div`
  width: 100%;

  ${respondTo.smallTablet`
    overflow-x: auto;
  `}
`;

export const SupplierCompanyNameLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  margin-bottom: ${marginSm};

  ${respondTo.smallTablet`
    margin-bottom: 0;
  `}
`;

export const FormHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10rem;
  padding-left: 20rem;
  padding-right: 20rem;
`;

export const HeaderLabel = styled.div`
  color: ${COLORS.BLACK};
  display: flex;

  ${(props: StyledProps) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  text-align: center;
  justify-content: center;

  ${(props: StyledProps) =>
    props.isSelectAll &&
    css`
      cursor: pointer;
      color: ${COLORS.PRIMARY};
      &:hover {
        text-decoration: underline;
      }
    `}

  ${respondTo.smallTablet`
      text-align: left;
    `}
`;

export const PurchaseOrderLinesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PurchaseOrderLineContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 20rem;
  padding-right: 20rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  width: 100%;
  align-items: center;
  border: 1rem solid red;
  ${(props: StyledProps) =>
    props.isFirst &&
    css`
      border-top-left-radius: 10rem;
      border-top-right-radius: 10rem;
    `};
  background-color: ${(props: StyledProps) =>
    props.isSelected ? COLORS.BACKGROUND_COLOR : 'none'};

  ${(props: StyledProps) => {
    if (props.isSelected) {
      if (props.isOnlyOne) {
        return css`
          border: 1rem solid ${COLORS.PRIMARY};
        `;
      } else if (props.isFirst) {
        return css`
          border: 1rem solid ${COLORS.PRIMARY};
          border-bottom: none;
        `;
      } else if (props.isLast) {
        return css`
          border: 1rem solid ${COLORS.PRIMARY};
          border-top: none;
        `;
      } else {
        return css`
          border: 1rem solid ${COLORS.PRIMARY};
          border-top: none;
          border-bottom: none;
        `;
      }
    } else {
      if (props.isOnlyOne) {
        return css`
          border: 1rem solid ${COLORS.GREY_1000};
          border-bottom: none;
        `;
      } else if (props.isFirst) {
        return css`
          border: 1rem solid ${COLORS.GREY_1000};
          border-bottom: none;
        `;
      } else if (props.isLast) {
        return css`
          border: 1rem solid ${COLORS.GREY_1000};
          border-top: none;
          border-bottom: none;
        `;
      } else {
        return css`
          border: 1rem solid ${COLORS.GREY_1000};
          border-top: none;
          border-bottom: none;
        `;
      }
    }
  }};
`;

export const FormCell = styled.div`
  display: flex;
  justify-content: center;
  ${(props: StyledProps) =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  ${(props: StyledProps) =>
    props.isInputCell
      ? css`
          margin-top: 16rem;

          ${respondTo.smallTablet`
            margin-top: 0;
          `}
        `
      : css`
          text-align: center;
          justify-content: center;
        `};

  ${respondTo.smallTablet`
      min-height: 30rem;
      align-items: center;
    `}
`;

export const FormLabel = styled.div`
  color: ${COLORS.BLACK};
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  ${(props: StyledProps) =>
    props.isMoreLabel &&
    css`
      user-select: none;
      color: ${COLORS.PRIMARY};
      cursor: pointer;
      &:hover {
        text-decoration: underline;
        text-decoration-thickness: 1rem;
        text-underline-position: under;
      }
    `};

  ${respondTo.smallTablet`
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    `}
`;

export const StockItemAbsoluteInfoTooltipWrapper = styled.div`
  position: absolute;
  right: 5rem;
  top: 5rem;
`;

export const IsLogyxProductAbsoluteWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 20rem;
  transform: translate(-50%, -50%);
  max-width: 21rem;
`;

export const Line = styled.div`
  width: 100%;
  height: 1rem;
  ${(props: StyledProps) =>
    props.color &&
    css`
      background-color: ${props.color};
    `};
`;

export const LabelSwitchWrapper = styled.div`
  display: flex;
`;

export const SwitchSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16rem;
  font-weight: 400;
  line-height: 25rem;
  cursor: pointer;
  color: ${COLORS.BLACK};
`;

export const LabelsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${respondTo.smallTablet`
    flex-direction: column;
    margin-left: auto;
  `}
`;

export const LabelWrapper = styled.div`
  display: flex;
  gap: 10rem;
  flex-wrap: wrap;
`;
export const AddLabelDropdownWrapper = styled.div`
  margin-left: ${marginSm};

  ${(props: StyledProps) =>
    props.$withoutMargin &&
    css`
      margin: 0;
    `};
`;

export const LabelsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-left: 20rem;
  padding-right: 20rem;
  padding-top: 10rem;
  padding-bottom: 10rem;
  width: 100%;
  align-items: center;
  border-bottom-left-radius: 10rem;
  border-bottom-right-radius: 10rem;
  background-color: none;
  border: 1rem solid ${COLORS.GREY_1000};

  ${respondTo.smallTablet`
    border-top-left-radius: 10rem;
    border-top-right-radius: 10rem;
    flex-direction: column;
    align-items: flex-start;
  `}
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 4%;
`;

export const Card = styled.div`
  max-width: 48%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid ${COLORS.GREY_300};
  margin-bottom: 20rem;
  justify-content: space-between;
  position: relative;
  padding: 10rem 10rem;
  padding-bottom: 20rem;
  border-radius: 5rem;

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : `none`};

  &:nth-child(2n) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -4%;
      background-color: ${COLORS.GREY_300};
      width: 1px;
      height: 80%;

      ${respondTo.bigPhone`
        display: none;
      `}
    }
  }

  ${respondTo.bigPhone`
    max-width: 100%;
  `}
`;

export const CardLeftSide = styled.div`
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const CardRightSide = styled.div`
  max-width: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

export const CardValueWrapper = styled.div`
  min-height: 30rem;
  display: flex;
  align-items: center;
`;
export const CardValue = styled.label`
  font-weight: 300;
  font-size: 16rem;
  color: ${COLORS.BLACK};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const HeaderCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 0 5rem;

  ${respondTo.smallTablet`
    padding: 0;
    min-height: 30rem;
    align-items: center;
    justify-content: flex-start;
  `}
`;

export const MobileAction = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10rem;
  align-items: center;
  justify-content: center;
  margin: 10rem 0;
`;

export const ActionCell = styled.div`
  display: flex;
  width: 100rem;
  justify-content: flex-end;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20rem;
`;

export const SelectAll = styled.label`
  display: block;
  margin-left: auto;
  font-size: 18rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
`;
