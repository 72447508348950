import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  isExpanded: boolean;
}

export const Container = styled.div`
  position: relative;
  border-bottom: 1rem solid ${COLORS.GREY_500};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 30rem;
  padding: 30rem 0;

  ${respondTo.bigPhone`
    padding: 20rem 0;
    margin: 0 20rem;
  `}

  ${(props: StyledProps) =>
    props.isExpanded &&
    css`
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 20rem;
    `}
`;

export const LabelWrapper = styled.div`
  display: flex;

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 10rem;
  `}
`;

export const Label = styled.div`
  font-size: 18rem;
  line-height: 27rem;
  font-weight: 600;
  min-width: 150rem;
  color: ${COLORS.BLACK};
`;
export const LabelValue = styled.div`
  font-size: 18rem;
  line-height: 27rem;
  font-weight: 400rem;
  color: ${COLORS.BLACK};
  flex: 1;
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  top: 24rem;
  right: 0;
  cursor: pointer;
`;

export const EyeWrapper = styled.div`
  user-select: none;
  position: absolute;
  top: 20%;
  right: 15rem;
`;

export const PasswordInputWrapper = styled.div`
  position: relative;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const ButtonWrapper = styled.div`
  ${respondTo.bigPhone`
    display: flex;
    justify-content: center;
    width: 100%;
  `}
`;
