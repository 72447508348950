import * as Yup from 'yup';

export const baseCustomerDetailsSchema = () => {
  return Yup.object().shape({
    name: Yup.string()
      .max(30, 'First name should be less than 30 characters')
      .required('First name is required'),
    last_name: Yup.string()
      .min(2, 'Last name should be between 2 and 30 characters')
      .max(30, 'Last  name should be between 2 and 30 characters')
      .required('Last name is required'),
    title: Yup.string().required('Title is required'),
    country: Yup.string().required('Country is required'),
    phone: Yup.string()
      .max(30, 'Phone number should be less than 30 characters')
      .required('Phone number is required'),
    email: Yup.string()
      .max(50, 'E-mail should be less than 50 characters')
      .required('E-mail is required'),
    alternative_invoice_email: Yup.string()
      .max(50, 'E-mail should be less than 50 characters')
      .optional()
      .nullable(),
    street: Yup.string()
      .max(50, 'Street should be less than 50 characters')
      .required('Street is required'),
    code: Yup.string()
      .max(20, 'Zip code should be less than 20 characters')
      .required('Zip code is required'),
    house_number: Yup.string()
      .max(20, 'House number should be less than 20 characters')
      .required('House number is required'),
    city: Yup.string()
      .max(50, 'City should be less than 50 characters')
      .required('City is required'),
    company_name: Yup.string()
      .min(2, 'Company name should be between 2 and 80 characters')
      .max(80, 'Company name should be between 2 and 80 characters')
      .optional()
      .nullable(),
    vat_number: Yup.string()
      .min(2, 'VAT number should be between 2 and 30 characters')
      .max(30, 'VAT number should be between 2 and 30 characters')
      .optional()
      .nullable(),
    shipping_data: Yup.object().shape({
      city: Yup.string()
        .max(50, 'City should be less than 50 characters')
        .required('City is required'),
      company_name: Yup.string()
        .min(2, 'Company name should be between 2 and 80 characters')
        .max(80, 'Company name should be between 2 and 80 characters')
        .optional()
        .nullable(),
      country: Yup.string().required('Country is required'),
      phone: Yup.string()
        .max(30, 'Phone number should be less than 30 characters')
        .required('Phone number is required'),
      first_name: Yup.string()
        .max(30, 'First name should be less than 30 characters')
        .required('First name is required'),
      house_number: Yup.string()
        .max(20, 'House number should be less than 20 characters')
        .required('House number is required'),
      last_name: Yup.string()
        .min(2, 'Last name should be between 2 and 30 characters')
        .max(30, 'Last name should be between 2 and 30 characters')
        .required('Last name is required'),
      street: Yup.string()
        .max(50, 'Street should be less than 50 characters')
        .required('Street is required'),
      zip_code: Yup.string()
        .max(20, 'Zip code should be less than 20 characters')
        .required('Zip code is required'),
      email: Yup.string()
        .max(50, 'E-mail should be less than 50 characters')
        .required('E-mail is required'),
      title: Yup.string().required('Title is required'),
    }),
  });
};

export const createQuotationNoShipToAddressCustomerTabSchema = () => {
  return baseCustomerDetailsSchema().pick([
    'name',
    'last_name',
    'title',
    'country',
    'phone',
    'email',
    'alternative_invoice_email',
    'street',
    'code',
    'house_number',
    'city',
    'company_name',
    'vat_number',
  ]);
};
