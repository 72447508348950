import styled, { css } from 'styled-components';
import Button from 'components/Button/Button';
import { COLORS, borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';

interface StyledProps {
  active?: boolean;
}

export const TabWrapper = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const CalendarWrapper = styled.div`
  padding: 30rem 30rem 20rem;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  width: 100%;

  ${respondTo.midTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const AddButton = styled.div`
  background-color: ${COLORS.WHITE};
  border: 1px solid ${COLORS.PRIMARY};
  border-radius: 7rem;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10rem;
  padding: 10rem;
  position: relative;
  overflow: hidden;

  &:hover {
    &::before {
      left: 0;
    }
  }

  &::before {
    content: '';
    width: 110%;
    height: 120%;
    background-color: ${COLORS.BACKGROUND_COLOR};
    position: absolute;
    left: -120%;
    top: -3px;
    transition: 0.3s ease-in;
  }
`;

export const Text = styled.span`
  color: ${COLORS.PRIMARY};
  font-size: 16rem;
  z-index: 1;
`;

export const AddAppointmentButton = styled.div`
  position: fixed;
  right: 120rem;
  bottom: 20vh;
  z-index: 5;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20rem;

  ${respondTo.midTablet`
    align-items: flex-start;
  `}
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20rem;
`;

export const WorkersButton = styled(Button)`
  ${(props: StyledProps) =>
    props.active &&
    css`
      background-color: ${COLORS.BACKGROUND_COLOR};
    `}
`;

export const ResourcesButton = styled(WorkersButton)``;

export const TodayButton = styled(Button)`
  width: 150rem;

  ${respondTo.horizontalTablet`
    width: 100rem;
  `}
`;

export const SelectInputWrapper = styled.div`
  width: 203rem;
  height: 41rem;
  z-index: 4;

  .MuiInputBase-root-hxnqRp {
    border-radius: 8rem;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  pointer-events: none;
`;

export const RelativeDiv = styled.div`
  position: relative;
`;

export const LeftSide = styled.div``;
export const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;
export const Row = styled.div`
  display: flex;
  gap: 20rem;
  margin-bottom: 20rem;

  ${respondTo.smallPhone`
    gap: 10rem;
  `}
`;

export const ArrowWrapper = styled.div`
  position: absolute;
  right: 15rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.3s;
`;

export const IconWrapper = styled.div`
  transition: all 0.3s;
  position: absolute;
  left: 20rem;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 20rem;
  height: 20rem;
  z-index: 1;
`;

export const ButtonWrapper = styled.div`
  position: relative;

  svg {
    z-index: 1;
  }

  &:hover svg {
    filter: brightness(0) invert(1) !important;
  }
`;

export const DatePickerWrap = styled.div``;

export const ClearWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ClearText = styled.span`
  display: block;
  font-size: 16rem;
  color: ${COLORS.RED_200};
  ${hoverLineOut}
  cursor: pointer;
`;
