import { COLORS, H3, marginSm } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 50rem;
  width: 100%;
`;
export const CloseContainer = styled.div`
  position: absolute;
  right: 15rem;
  top: 15rem;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(H3)`
  margin-bottom: 20rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20rem;
`;

export const InfoWrapper = styled.div`
  width: 100%;
`;

export const P2 = styled.span`
  color: ${COLORS.PRIMARY};
  padding-left: 5rem;
  font-weight: 300;
  padding-right: 30rem;
  font-size: 14rem;
`;
