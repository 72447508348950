import styled from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const CloseContainer = styled.div`
  position: absolute;
  top: 20rem;
  right: 20rem;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: ${marginSm};
`;

export const Title = styled.div`
  font-size: 24rem;
  font-weight: 300;
  line-height: 36rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;

  ${respondTo.smallTablet`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const TopContainer = styled.div`
  ${respondTo.smallTablet`
    padding-top: 50rem;
  `}
`;

export const Margin = styled.div`
  margin-right: ${marginSm};

  ${respondTo.smallTablet`
    margin-right: 0;
    width: 100%;
  `}
`;

export const ButtonWrapper = styled.div`
  width: 200rem;

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const Description = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: center;
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 16rem;
  `}
`;
