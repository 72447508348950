import { CSSProperties } from 'react';
import { Container } from './CheckboxBig.styled';
import { Check } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';

interface ICheckboxBigProps {
  isChecked: boolean;
  onChange: (param: boolean) => void;
  containerStyle?: CSSProperties;
  pwId?: string;
}

export const CheckboxBig = ({
  onChange,
  isChecked,
  containerStyle,
  pwId,
}: ICheckboxBigProps) => {
  return (
    <Container
      data-testid={pwId}
      style={containerStyle}
      onClick={() => onChange(!isChecked)}
      isChecked={isChecked}
    >
      {isChecked && (
        <Check color={COLORS.STRICT_WHITE} size={16} weight="bold" />
      )}
    </Container>
  );
};
