import { paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 100%;
  height: 100%;
  padding: 50rem;

  ${respondTo.horizontalTablet`
    padding: ${paddingMd};
  `}
`;
