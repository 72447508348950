import { baseCustomerDetailsSchema } from 'pages/Manager/NewQuotationPage/CustomerTab/validation';
import * as Yup from 'yup';

export const editCustomerDetailsGeneralTabSchema = () => {
  return baseCustomerDetailsSchema().pick([
    'name',
    'last_name',
    'title',
    'country',
    'street',
    'code',
    'house_number',
    'city',
    'company_name',
    'vat_number',
  ]);
};

export const editCustomerDetailsContactTabSchema = () => {
  return baseCustomerDetailsSchema().pick(['phone', 'email']);
};

export const editCustomerDetailsShipToTabSchema = () => {
  const shippingDataFieldsSchema =
    baseCustomerDetailsSchema().fields.shipping_data;

  return Yup.object().shape({
    name: shippingDataFieldsSchema.fields.first_name,
    last_name: shippingDataFieldsSchema.fields.last_name,
    title: shippingDataFieldsSchema.fields.title,
    country: shippingDataFieldsSchema.fields.country,
    code: shippingDataFieldsSchema.fields.zip_code,
    house_number: shippingDataFieldsSchema.fields.house_number,
    city: shippingDataFieldsSchema.fields.city,
    street: shippingDataFieldsSchema.fields.street,
    email: shippingDataFieldsSchema.fields.email,
    phone: shippingDataFieldsSchema.fields.phone,
    company_name: shippingDataFieldsSchema.fields.company_name,
  });
};
