import { H4 } from 'assets/styled';
import { Field, Formik } from 'formik';
import {
  ColumnContainer,
  ColumnContainerSelect,
  Container,
  Heading,
  HorizontalLine,
  RowSpaceBetween,
  StyledCustomSelect,
} from '../EditCustomerDetailsModal.styled';
import { Input as FormikInput } from 'components/Input/InputFormik';
import { Option } from 'components/Select/type';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { useTranslation } from 'react-i18next';
import { IEditCustomerDetails } from '../constants';
import { editCustomerDetailsGeneralTabSchema } from '../validation';
import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IGeneralTabProps {
  pwId?: string;
  customerDetails: IEditCustomerDetails;
  setCustomerDetails: (customerDetails: IEditCustomerDetails) => void;
  setIsValid: (isValid: boolean) => void;
}
export const GeneralTab = ({
  customerDetails,
  setCustomerDetails,
  setIsValid,
  pwId,
}: IGeneralTabProps) => {
  const { t } = useTranslation();
  const countryOptions: Option[] = useGetCountryOptions();
  const titlesOptions = useGetTitleOptions();
  const { isSmallTablet } = useBreakpointFlag();

  return (
    <div>
      <Formik
        enableReinitialize
        onSubmit={() => {
          //
        }}
        validationSchema={editCustomerDetailsGeneralTabSchema}
        initialValues={customerDetails}
      >
        {({ values, setFieldValue, handleBlur, errors, isValid }) => {
          const handleBlurFunction = (e: any, name: string) => {
            const value = e.target.value.trim();
            setFieldValue(name, value);
            setCustomerDetails(values);
            handleBlur(e);
            setIsValid(isValid);
          };
          return (
            <Container>
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-first-name-label`}>
                      {t('First name').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-first-name-input-field`}
                    errorMessage={errors['name'] ? errors['name'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'name');
                    }}
                    height={'43rem'}
                    name="name"
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-last-name-label`}>
                      {t('Last name').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-last-name-input-field`}
                    errorMessage={
                      errors['last_name'] ? errors['last_name'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'last_name');
                    }}
                    height={'43rem'}
                    name="last_name"
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainerSelect>
                  <Heading>
                    <H4 data-testid={`${pwId}-title-label`}>
                      {t('Title').toUpperCase()}
                    </H4>
                  </Heading>
                  <Field
                    pwId={`${pwId}-title-input`}
                    errorMessage={errors['title'] ? errors['title'] : ''}
                    disabled={false}
                    name="title"
                    options={titlesOptions}
                    component={StyledCustomSelect}
                    placeholder={t('Title')}
                    isMulti={false}
                    onSelect={(option: Option) => {
                      setCustomerDetails({ ...values, title: option.value });
                      setIsValid(isValid);
                    }}
                  />
                </ColumnContainerSelect>
                <ColumnContainerSelect>
                  <Heading>
                    <H4 data-testid={`${pwId}-country-code-label`}>
                      {t('Country code').toUpperCase()}
                    </H4>
                  </Heading>
                  <Field
                    pwId={`${pwId}-country-code-input`}
                    errorMessage={errors['country'] ? errors['country'] : ''}
                    disabled={false}
                    height={'41rem'}
                    name="country"
                    options={countryOptions}
                    component={StyledCustomSelect}
                    isMulti={false}
                    onSelect={(option: Option) => {
                      setCustomerDetails({ ...values, country: option.value });
                      setIsValid(isValid);
                    }}
                  />
                </ColumnContainerSelect>
              </RowSpaceBetween>

              <HorizontalLine />

              <HorizontalLine />
              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-zipcode-label`}>
                      {t('Zip code').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-zip-code-input-field`}
                    errorMessage={errors['code'] ? errors['code'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'code');
                    }}
                    height={'43rem'}
                    name="code"
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>

                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-house-number-label`}>
                      {t('House number').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-house-number-input-field`}
                    errorMessage={
                      errors['house_number'] ? errors['house_number'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'house_number');
                    }}
                    height={'43rem'}
                    name="house_number"
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-city-label`}>
                      {t('City').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-city-input-field`}
                    errorMessage={errors['city'] ? errors['city'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'city');
                    }}
                    height={'43rem'}
                    name="city"
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-street-label`}>
                      {t('Street').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-street-input-field`}
                    errorMessage={errors['street'] ? errors['street'] : ''}
                    onBlur={(e) => {
                      handleBlurFunction(e, 'street');
                    }}
                    height={'43rem'}
                    name="street"
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>

              <HorizontalLine />

              <RowSpaceBetween>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-company-name-label`}>
                      {t('Company name').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-company-name-input-field`}
                    errorMessage={
                      errors['company_name'] ? errors['company_name'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'company_name');
                    }}
                    height={'43rem'}
                    name="company_name"
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
                <ColumnContainer>
                  <Heading>
                    <H4 data-testid={`${pwId}-vat-number-label`}>
                      {t('VAT number').toUpperCase()}
                    </H4>
                  </Heading>
                  <FormikInput
                    pwId={`${pwId}-vat-number-input-field`}
                    errorMessage={
                      errors['vat_number'] ? errors['vat_number'] : ''
                    }
                    onBlur={(e) => {
                      handleBlurFunction(e, 'vat_number');
                    }}
                    height={'43rem'}
                    name="vat_number"
                    wrapperStyles={{
                      width: '100%',
                      marginBottom: isSmallTablet ? 0 : '4rem',
                      marginTop: isSmallTablet ? 0 : '20rem',
                    }}
                  />
                </ColumnContainer>
              </RowSpaceBetween>
            </Container>
          );
        }}
      </Formik>
    </div>
  );
};
