import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  createToDo,
  deleteToDo,
  getSingleToDo,
  getToDos,
  getToDoTypes,
  updateToDo,
} from 'services/ToDo/ToDoService';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  useGetQuotationOptions,
  useGetQuotationsInfinite,
} from 'utils/hooks/useGetQuotations';
import { toast } from 'utils/toast';
import { RELATED_TYPES_PER_PAGE } from './constants';
import { IToDoDTO, TodoRelatedTypeId } from 'types/ToDo.types';
import {
  useGetSalesOrdersInfinite,
  useGetSalesOrdersOptions,
} from 'utils/hooks/useGetSalesOrders';
import {
  useGetSalesInvoicesInfinite,
  useGetSalesInvoicesOptions,
} from 'utils/hooks/useGetSalesInvoices';
import {
  useGetPurchaseOrdersInfinite,
  useGetPurchaseOrdersOptions,
} from 'utils/hooks/useGetPurchaseOrders';
import { SortDirection } from 'components/Table/constants';
import { useGetCustomersInfinite } from 'utils/hooks/useGetCustomers';
import { useDispatch, useSelector } from 'react-redux';
import { IToDoPageFilters } from 'store/Filters/types';
import { IRootReducerState } from 'store/store';
import { FiltersPageEnum } from 'store/Filters/constants';
import {
  initializePageFilters,
  savePageFiltersOnPageDismount,
} from 'store/Filters/actions/filters';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Option } from 'components/Select/type';
import { generateCustomerOptionFromCustomer } from './helpers';
import { ICustomer } from 'types/Customer.types';

export const useGetToDos = (
  forceUpdate: number,
  page?: number,
  sortBy?: string,
  sortDirection?: string,
  perPage?: number,
  searchBy?: string,
  advancedFilters?: string,
  selectedTab?: boolean
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_TODOS,
      forceUpdate,
      page,
      sortBy,
      sortDirection,
      perPage,
      searchBy,
      advancedFilters,
      selectedTab,
    ],
    queryFn: () => {
      return getToDos(
        page,
        sortBy,
        sortDirection,
        perPage,
        searchBy,
        selectedTab,
        advancedFilters
      );
    },
  });

export const useGetToDoTypes = () =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_TODO_TYPES],
    queryFn: async () => {
      const data = await getToDoTypes();
      // Get transformed data (Option)
      return data.map((item: { id: number; name: string }) => ({
        value: String(item.id),
        label: item.name
          .replace(/_/g, ' ')
          .toLowerCase()
          .replace(/^\w/, (c) => c.toUpperCase()),
        key: item.name,
      }));
    },
  });

export const useCreateToDo = (options?: any) => {
  const { t } = useTranslation();
  return useMutation((createToDoData: IToDoDTO) => createToDo(createToDoData), {
    onSuccess: () => {
      toast.success(t('Successfully created ToDo'), {
        className: ReactMutationKeys.CREATE_TODO,
      });
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT,
      ]);
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_UNFINISHED_TODOS_COUNT,
      ]);

      if (options?.onSuccess) {
        options.onSuccess();
      }
    },
    onError: (error: ERPError) => {
      toast.error(getToastErrorMessage(error), {
        toastId: ReactMutationKeys.CREATE_TODO,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_TODOS);
    },
    mutationKey: ReactMutationKeys.CREATE_TODO,
  });
};

export const useUpdateToDo = (options?: any) => {
  const { t } = useTranslation();
  return useMutation(
    (params: any) => updateToDo(params.id, params.updateToDoData),
    {
      onSuccess: () => {
        toast.success(t('Successfully updated ToDo'), {
          className: ReactMutationKeys.UPDATE_TODO,
        });
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT,
        ]);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_UNFINISHED_TODOS_COUNT,
        ]);
        if (options?.onSuccess) {
          options.onSuccess();
        }
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.UPDATE_TODO,
        });
      },
      onSettled: (data, error, params) => {
        queryClient.invalidateQueries(ReactQueryKeys.GET_TODOS);
        queryClient.invalidateQueries([
          ReactQueryKeys.GET_SINGLE_TODO,
          params.id,
        ]);
        queryClient.invalidateQueries(
          ReactQueryKeys.GET_UNFINISHED_TODOS_COUNT
        );
      },
      mutationKey: ReactMutationKeys.UPDATE_TODO,
    }
  );
};

export const useGetSingleToDo = (id: string, isEnabled: boolean) =>
  useQuery({
    queryKey: [ReactQueryKeys.GET_SINGLE_TODO, id],
    queryFn: () => {
      return getSingleToDo(id);
    },
    enabled: isEnabled,
    staleTime: 0,
  });

export const useDeleteToDo = (options?: any) => {
  const { t } = useTranslation();
  return useMutation((id: string) => deleteToDo(id), {
    onSuccess: () => {
      toast.success(t('Successfully deleted ToDo'));
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_UNREAD_NOTIFICATIONS_COUNT,
      ]);
      queryClient.invalidateQueries([
        ReactQueryKeys.GET_UNFINISHED_TODOS_COUNT,
      ]);
      if (options?.onSuccess) options.onSuccess();
    },
    onError: (error: any) => {
      if (
        error?.response?.data?.errors?.[0]?.split(':')?.[1] ===
        ' ToDo cannot be deleted'
      ) {
        toast.error(t('ToDo cannot be deleted'));
      } else {
        toast.error(getToastErrorMessage(error));
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(ReactQueryKeys.GET_TODOS);
    },
    mutationKey: ReactMutationKeys.DELETE_TODO,
  });
};

export const useRelatedTypeOptions = (
  relatedType: string,
  relatedTypeSearchBy: string
) => {
  const debouncedSearch = useDebounce(relatedTypeSearchBy);

  const {
    data: quotationsData,
    fetchNextPage: fetchNextPageQuotations,
    hasNextPage: hasNextPageQuotations,
    isLoading: isLoadingQuotations,
  } = useGetQuotationsInfinite(
    RELATED_TYPES_PER_PAGE,
    debouncedSearch,
    relatedType === TodoRelatedTypeId.QUOTATION,
    'created_at', // Sort name
    SortDirection.DESC // Sort direction
  );

  const quotationOptions = useGetQuotationOptions(quotationsData);

  const {
    data: salesOrdersData,
    fetchNextPage: fetchNextPageSalesOrders,
    hasNextPage: hasNextPageSalesOrders,
    isLoading: isLoadingSalesOrders,
  } = useGetSalesOrdersInfinite(
    RELATED_TYPES_PER_PAGE,
    debouncedSearch,
    relatedType === TodoRelatedTypeId.SALES_ORDER,
    'created_at', // Sort name
    SortDirection.DESC // Sort direction
  );

  const salesOrdersOptions = useGetSalesOrdersOptions(salesOrdersData);

  const {
    data: salesInvoicesData,
    fetchNextPage: fetchNextPageSalesInvoices,
    hasNextPage: hasNextPageSalesInvoices,
    isLoading: isLoadingSalesInvoices,
  } = useGetSalesInvoicesInfinite(
    RELATED_TYPES_PER_PAGE,
    debouncedSearch,
    relatedType === TodoRelatedTypeId.SALES_INVOICE,
    'created_at', // Sort name
    SortDirection.DESC // Sort direction
  );

  const salesInvoicesOptions = useGetSalesInvoicesOptions(salesInvoicesData);

  const {
    data: purchaseOrdersData,
    fetchNextPage: fetchNextPagePurchaseOrders,
    hasNextPage: hasNextPagePurchaseOrders,
    isLoading: isLoadingPurchaseOrders,
  } = useGetPurchaseOrdersInfinite(
    RELATED_TYPES_PER_PAGE,
    debouncedSearch,
    relatedType === TodoRelatedTypeId.PURCHASE_ORDER,
    'created_at', // Sort name
    SortDirection.DESC // Sort direction
  );

  const purchaseOrdersOptions = useGetPurchaseOrdersOptions(purchaseOrdersData);

  const {
    data: customersData,
    fetchNextPage: fetchNextPageCustomers,
    hasNextPage: hasNextPageCustomers,
    isLoading: isLoadingCustomers,
  } = useGetCustomersInfinite(
    RELATED_TYPES_PER_PAGE,
    debouncedSearch,
    true,
    relatedType === TodoRelatedTypeId.CUSTOMER,
    'number', // Sort name
    SortDirection.DESC // Sort direction
  );

  const customerOptions: Option[] = useMemo(() => {
    if (customersData?.pages?.length) {
      return customersData.pages
        .map((page) => page.customers)
        .flat()
        .map((customer: ICustomer) =>
          generateCustomerOptionFromCustomer(customer)
        );
    }
    return [];
  }, [customersData]);

  const isLoadingRelatedOptions =
    isLoadingQuotations ||
    isLoadingSalesOrders ||
    isLoadingSalesInvoices ||
    isLoadingPurchaseOrders ||
    isLoadingCustomers;

  const relatedToOptions: any = () => {
    switch (relatedType) {
      case TodoRelatedTypeId.QUOTATION:
        return quotationOptions;
      case TodoRelatedTypeId.SALES_ORDER:
        return salesOrdersOptions;
      case TodoRelatedTypeId.SALES_INVOICE:
        return salesInvoicesOptions;
      case TodoRelatedTypeId.PURCHASE_ORDER:
        return purchaseOrdersOptions;
      case TodoRelatedTypeId.CUSTOMER:
        return customerOptions;
      default:
        return [];
    }
  };

  return {
    relatedToOptions: relatedToOptions(),
    fetchNextPageQuotations,
    fetchNextPageSalesOrders,
    fetchNextPageSalesInvoices,
    fetchNextPagePurchaseOrders,
    fetchNextPageCustomers,
    hasNextPageQuotations,
    hasNextPageSalesOrders,
    hasNextPageSalesInvoices,
    hasNextPagePurchaseOrders,
    hasNextPageCustomers,
    isLoadingRelatedOptions,
  };
};

export const useGetToDoPageFilters = () => {
  const dispatch = useDispatch();
  const todoPageFilters: IToDoPageFilters | null = useSelector(
    (state: IRootReducerState) => state.filtersInfo.todoPage
  );

  if (!todoPageFilters) {
    const initialToDoPageFilters: IToDoPageFilters = {
      page: 1,
      searchBy: '',
      sortBy: undefined,
      sortDirection: undefined,
      advancedFilters: '',
    };
    dispatch(
      initializePageFilters({
        page: FiltersPageEnum.TODO,
        data: initialToDoPageFilters,
      })
    );
    return {
      todoPageFilters: initialToDoPageFilters,
    };
  }

  return {
    todoPageFilters,
  };
};

export const useManageAndSaveFilters = (initialFilters: IToDoPageFilters) => {
  const dispatch = useDispatch();

  // State for each filter
  const [page, setPage] = useState<number>(initialFilters.page);
  const [searchBy, setSearchBy] = useState<string>(initialFilters.searchBy);
  const [sortBy, setSortBy] = useState<string | undefined>(
    initialFilters.sortBy
  );
  const [sortDirection, setSortDirection] = useState<SortDirection | undefined>(
    initialFilters.sortDirection
  );
  const [advancedFilters, setAdvancedFilters] = useState<string>(
    initialFilters.advancedFilters
  );

  // Ref to track initial render
  const initialRender = useRef(true);
  useEffect(() => {
    if (initialRender.current) {
      // On mount, set initialRender to false
      initialRender.current = false;
    } else {
      // * Reset page count if any other filter changes *
      setPage(1);
    }
  }, [searchBy, sortBy, sortDirection, advancedFilters]);

  // Ref that holds the latest values of all filters
  const filtersRef = useRef<IToDoPageFilters>(initialFilters);
  // Update the ref every time any filter value changes
  // Update persisted page count when only page changes
  useEffect(() => {
    filtersRef.current = {
      ...filtersRef.current,
      page,
    };
  }, [page]);
  // Reset persisted page count if any other filter changes
  useEffect(() => {
    filtersRef.current = {
      page: 1,
      searchBy,
      sortBy,
      sortDirection,
      advancedFilters,
    };
  }, [searchBy, sortBy, sortDirection, advancedFilters]);

  // Clean-up logic when component unmounts
  useEffect(() => {
    return () => {
      dispatch(
        savePageFiltersOnPageDismount({
          page: FiltersPageEnum.TODO,
          data: filtersRef.current,
        })
      );
    };
  }, []);

  return {
    page,
    setPage,
    searchBy,
    setSearchBy,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    advancedFilters,
    setAdvancedFilters,
  };
};

export const useGetPrepopulateTodoId = () => {
  const { state } = useLocation();
  if (state) {
    return state.todoId;
  } else {
    return null;
  }
};
