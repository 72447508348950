import { ICreateSupplierDTO } from 'pages/Manager/NewSupplierPage/types';
import { IProductGroup } from 'pages/Manager/ProductGroupsPage/types';
import { IProduct } from './Product.types';
import { ISupplier } from './Supplier.types';

export interface IStockItem {
  id: string;
  number: string;
  bought_amount: string;
  description: string;
  in_stock: string;
  is_active: string;
  min_order_amount: string;
  price: string;
  product: IProduct;
  purchase_price: string;
  reserved: string;
  sku: string;
  sold_amount: string;
  supplier: ISupplier;
  name: string; // In case stock item is service type
  type: IStockItemType;
  number_supplier: string;
  product_group_id: string;
  product_group: IProductGroup;
}

export interface IStockItemType {
  name: string;
  id: StockItemTypeIdEnum;
}

export enum StockItemTypeIdEnum {
  NORMAL = 1,
  SERVICE = 2,
}

export interface IStockItemTableDTO {
  navigationID: string;
  number: string;
  type: string;
  name: string;
  quantityBought: string;
  status: string;
  supplier: string;
  supplierID: number;
  inStock: string;
  unitsSold: string;
  group: string;
}

export interface ICreateStockItemData {
  name: string;
  description?: string;
  supplier_id?: string;
  product_id?: string | null;
  min_order_amount?: string;
  in_stock?: string;
  price?: string;
  purchase_price?: string;
  supplier?: ICreateSupplierDTO;
  sku?: string | null;
  measure_unit_id?: string;
  type_id?: string;
}
