import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ButtonsWrapper,
  CloseContainer,
  Container,
  DatePickerWrap,
  SelectInputWrapper,
  Title,
  TitleContainer,
  ViewTypeOptionLabel,
} from './FilterMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button/Button';
import { Option } from 'components/Select/type';
import { Select } from 'components/Select/Select';
import { FwAppointmentsViewType } from '../FwAppointmentsPage';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

interface IFilterMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  selectedAppointmentTypeOption: Option;
  setSelectedAppointmentTypeOption: Dispatch<SetStateAction<Option>>;
  appointmentTypeOptions: Option[];
  setSelectedViewType: Dispatch<SetStateAction<FwAppointmentsViewType>>;
  selectedViewType: FwAppointmentsViewType;
  filterDate: string;
  setFilterDate: Dispatch<SetStateAction<string>>;
}

const FilterMobileModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  selectedAppointmentTypeOption,
  setSelectedAppointmentTypeOption,
  appointmentTypeOptions,
  setSelectedViewType,
  selectedViewType,
  filterDate,
  setFilterDate,
}: IFilterMobileModalProps) => {
  const { t } = useTranslation();
  const { isPhone } = useBreakpointFlag();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Filter')}</Title>
        </TitleContainer>

        <ButtonsWrapper>
          <SelectInputWrapper>
            <Select
              defaultValue={
                selectedAppointmentTypeOption
                  ? selectedAppointmentTypeOption
                  : appointmentTypeOptions[0]
              }
              name="type"
              placeholder={t('Type')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => setSelectedAppointmentTypeOption(e)}
              options={appointmentTypeOptions}
            />
          </SelectInputWrapper>
          {isPhone && selectedViewType === FwAppointmentsViewType.CALENDAR && (
            <DatePickerWrap>
              <DatePicker
                pwId="date-field"
                width="100%"
                date={moment(filterDate)}
                setDate={(newValue: any) => {
                  setFilterDate(newValue?.format('YYYY-MM-DD'));
                }}
                onAccept={(newDate: any) => {
                  if (newDate === null) {
                    setFilterDate('');
                  }
                }}
              />
            </DatePickerWrap>
          )}
          <ViewTypeOptionLabel
            onClick={() => setSelectedViewType(FwAppointmentsViewType.CALENDAR)}
            isSelected={selectedViewType === FwAppointmentsViewType.CALENDAR}
          >
            {t('Calendar view')}
          </ViewTypeOptionLabel>
          <ViewTypeOptionLabel
            isSelected={selectedViewType === FwAppointmentsViewType.LIST}
            onClick={() => setSelectedViewType(FwAppointmentsViewType.LIST)}
          >
            {t('List view')}
          </ViewTypeOptionLabel>
        </ButtonsWrapper>

        <ButtonsWrapper>
          <Button
            onClick={() => setIsOpen(false)}
            label={t('Finish')}
            width="200rem"
            height="40rem"
            primary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default FilterMobileModal;
