import { Container } from './FwAppointmentsPage.styled';
import FwAppointmentsCalendarView from './FwAppointmentsCalendarView/FwAppointmentsCalendarView';
import FwAppointmentsListView from './FwAppointmentsListView/FwAppointmentsListView';
import {
  useGetFwAppointmentsPageFilters,
  useManageAndSaveFilters,
} from './hooks';
import { useGetFwAppointments } from './FwAppointmentsCalendarView/hooks';
import { useDebounce } from 'utils/hooks/useDebounce';
import RunningAndBlockedAppointmentsModal from './RunningAndBlockedAppointmentsModal/RunningAndBlockedAppointmentsModal';
import { useState } from 'react';
import { useRunTour } from 'components/IntroductionGuide/hooks';
import { GuidePages } from 'components/IntroductionGuide/constants';
import { IntroductionGuide } from 'components/IntroductionGuide/IntroductionGuide';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import FilterMobileModal from './FilterMobileModal/FilterMobileModal';

export enum FwAppointmentsViewType {
  LIST = 'LIST',
  CALENDAR = 'CALENDAR',
}

const FwAppointmentsPage = () => {
  const [
    isRunningAndBlockedAppointmentsModalOpen,
    setIsRunningAndBlockedAppointmentsModalOpen,
  ] = useState<boolean>(false);
  const { fwAppointmentsPageFilters, appointmentTypeOptions } =
    useGetFwAppointmentsPageFilters();

  const [isMobileFilterModalOpen, setIsMobileFilterModalOpen] =
    useState<boolean>(false);

  const { isMidTablet } = useBreakpointFlag();

  const {
    searchBy,
    setSearchBy,
    sortBy,
    setSortBy,
    sortDirection,
    setSortDirection,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    selectedAppointmentTypeOption,
    setSelectedAppointmentTypeOption,
    selectedViewType,
    setSelectedViewType,
    filterDate,
    setFilterDate,
  } = useManageAndSaveFilters(fwAppointmentsPageFilters);

  const debouncedSearchBy = useDebounce(searchBy);

  const { data: appointmentsData, isFetching: isFetchingFwAppointments } =
    useGetFwAppointments(
      startDate,
      endDate,
      debouncedSearchBy,
      sortBy,
      sortDirection,
      selectedAppointmentTypeOption.value
    );

  const { steps } = useRunTour(GuidePages.FIELD_WORKER);

  return (
    <Container className="fw-step-1">
      {selectedViewType === FwAppointmentsViewType.CALENDAR ? (
        <FwAppointmentsCalendarView
          appointments={
            appointmentsData?.appointments ? appointmentsData.appointments : []
          }
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedAppointmentTypeOption={selectedAppointmentTypeOption}
          setSelectedAppointmentTypeOption={setSelectedAppointmentTypeOption}
          setSelectedViewType={setSelectedViewType}
          appointmentTypeOptions={appointmentTypeOptions}
          isFetchingFwAppointments={isFetchingFwAppointments}
          setIsMobileFilterModalOpen={setIsMobileFilterModalOpen}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
        />
      ) : (
        <FwAppointmentsListView
          appointments={
            appointmentsData?.appointments ? appointmentsData.appointments : []
          }
          searchBy={searchBy}
          setSearchBy={setSearchBy}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortDirection={sortDirection}
          setSortDirection={setSortDirection}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          selectedAppointmentTypeOption={selectedAppointmentTypeOption}
          setSelectedAppointmentTypeOption={setSelectedAppointmentTypeOption}
          setSelectedViewType={setSelectedViewType}
          appointmentTypeOptions={appointmentTypeOptions}
          setIsMobileFilterModalOpen={setIsMobileFilterModalOpen}
        />
      )}
      <RunningAndBlockedAppointmentsModal
        isOpen={isRunningAndBlockedAppointmentsModalOpen}
        setIsOpen={setIsRunningAndBlockedAppointmentsModalOpen}
      />
      <IntroductionGuide steps={steps} />

      {isMidTablet && isMobileFilterModalOpen && (
        <FilterMobileModal
          isOpen={isMobileFilterModalOpen}
          setIsOpen={setIsMobileFilterModalOpen}
          onCancel={() => setIsMobileFilterModalOpen(false)}
          selectedAppointmentTypeOption={selectedAppointmentTypeOption}
          appointmentTypeOptions={appointmentTypeOptions}
          setSelectedAppointmentTypeOption={setSelectedAppointmentTypeOption}
          setSelectedViewType={setSelectedViewType}
          selectedViewType={selectedViewType}
          filterDate={filterDate}
          setFilterDate={setFilterDate}
        />
      )}
    </Container>
  );
};

export default FwAppointmentsPage;
