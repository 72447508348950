import { IQuotation } from 'types/Quotations.types';
import { ISalesOrder } from 'types/SalesOrders.types';

export const getInitialCustomerDetailsData = (
  data: ISalesOrder | IQuotation
) => {
  if (data)
    return {
      name: data?.customer_snapshot?.first_name,
      last_name: data?.customer_snapshot?.last_name,
      gender: data?.customer_snapshot?.gender,
      country: data?.customer_snapshot?.address?.country?.cca2,
      code: data?.customer_snapshot?.address?.zip_code,
      house_number: data?.customer_snapshot?.address?.house_number,
      street: data?.customer_snapshot?.address?.street,
      city: data?.customer_snapshot?.address?.city,
      company_name: data?.customer_snapshot?.company_name,
      vat_number: data?.customer_snapshot?.company_vat,
      title: data?.customer_snapshot?.title,
    };
  else
    return {
      name: '',
      last_name: '',
      gender: '',
      country: '',
      code: '',
      house_number: '',
      street: '',
      city: '',
      company_name: '',
      vat_number: '',
      title: '',
    };
};

export const getInitialCustomerContactDetailsData = (
  data: ISalesOrder | IQuotation
) => {
  if (data)
    return {
      email: data?.customer_snapshot?.email,
      phone: data?.customer_snapshot?.phone_number,
    };
  else
    return {
      email: '',
      phone: '',
    };
};

export const getInitialCustomerShipToDetailsData = (
  data: ISalesOrder | IQuotation
) => {
  if (data)
    return {
      name: data?.customer_snapshot?.shipping_data?.first_name,
      last_name: data?.customer_snapshot?.shipping_data?.last_name,
      email: data?.customer_snapshot?.shipping_data?.email,
      country: data?.customer_snapshot?.shipping_data?.address?.country?.cca2,
      code: data?.customer_snapshot?.shipping_data?.address?.zip_code,
      house_number:
        data?.customer_snapshot?.shipping_data?.address?.house_number,
      street: data?.customer_snapshot?.shipping_data?.address?.street,
      city: data?.customer_snapshot?.shipping_data?.address?.city,
      company_name: data?.customer_snapshot?.shipping_data?.company_name,
      title: data?.customer_snapshot?.shipping_data?.title,
      phone: data?.customer_snapshot?.shipping_data?.phone,
    };
  else
    return {
      name: '',
      last_name: '',
      email: '',
      country: '',
      code: '',
      house_number: '',
      street: '',
      city: '',
      company_name: '',
      title: '',
      phone: '',
    };
};
