import { IWorkerAndResource } from 'pages/Manager/PlanningPage/WorkersModal/type';
import IAddress from './Address.types';
import { ICustomer } from './Customer.types';
import { IQuotation } from './Quotations.types';
import {
  ISalesOrder,
  ISalesOrderStatus,
  IService,
  ISubStatus,
} from './SalesOrders.types';
import { IDiscount } from './Discount.types';
import { IStockItem } from './StockItem.types';
import { ISupplier } from './Supplier.types';
import { IProduct } from './Product.types';
import { IUser } from './User.types';

export interface IAppointment {
  id: number;
  address: IAddress;
  appointment_quotation_lines: any;
  appointment_sales_order_lines: any;
  customer: ICustomer;
  date_from: string;
  date_to: string;
  users: IUser[];
  resources: IWorkerAndResource[];
  type: IAppointmentType;
  workers: IWorkerAndResource[];
  quotation_id: number;
  quotation: IQuotation;
  sales_order: ISalesOrder;
  sales_order_id: number;
  confirmed?: boolean;
  purpose?: string;
  is_failed?: boolean;
  status: IAppointmentStatus;
  sent_to_customer: boolean;
  has_report: boolean;
  processed_in_office: boolean;
  alternative_contact: IAlternativeContact;
  /** Present only in search_appointments endpoint, when sales_order_id is present */
  are_all_lines_received: boolean;
}

export interface IAppointmentType {
  id: number;
  name: string;
}

export interface IAppointmentStatus {
  name: string;
  id: number;
}

export enum AppointmentEmailType {
  OFFER = 'OFFER',
  CONFIRMATION = 'CONFIRMATION',
  REMINDER = 'REMINDER',
}

export interface IAppointmentInterval {
  id: number;
  start_date: string;
  end_date: string;
  total: number;
  worker: IWorkerAndResource | null;
  resource: IWorkerAndResource | null;
  pauses: IAppointmentIntervalPause[];
}

export interface IAppointmentIntervalPause {
  id: number;
  appointment_interval_id: number;
  start_date: string;
  end_date: string;
}

export interface IFwAppointmentTableDTO {
  customer: string;
  address: string;
  phoneNumber: string;
  status: string;
  type: string;
  date: string;
  timeSlot: string;
  navigationID: number;
}

export interface ICreateFwAppointmentLineDTO {
  supplier_id: number;
  product_id?: number;
  stock_item_id?: number;
  quantity: number;
  description: string;
  placement: string;
  product_purchase_price: number;
  product_sales_price: number;
  product_attributes: object;
  logyx_configuration_intent_uuid?: string;
  deduct_stock?: boolean;
  include_in_appointment_id: number | null;
}

export interface IEditFwAppointmentLineDTO {
  quantity: number;
  description?: string;
  placement?: string;
  product_attributes: any;
  product_sales_price: number;
  product_purchase_price?: number;
  logyx_configuration_intent_uuid?: string;
  measured?: boolean;
}

export interface IFwAppointmentLine {
  appointment_id: number;
  attributes: object;
  description: string;
  id: number;
  placement: string;
  product_id: number;
  product_purchase_price: number;
  product_sales_price: number;
  quantity: number;
  sales_order_id: number;
  stock_deducted: boolean;
  stock_item_id: number;
  supplier_id: number;
  stock_item_line: boolean;
  service_id: number;
  is_service_stock_item: boolean;
  is_normal_stock_item: boolean;
  is_planned: boolean;
  is_delivered: boolean;
  stock_item: IStockItem;
  supplier: ISupplier;
  product: IProduct;
  sub_status: ISubStatus;
  status: ISalesOrderStatus;
  discount_id: number;
  discount: IDiscount;
  service: IService;
  original_quotation_line_id: number | null;
  original_sales_order_line_id: number | null;
  index: number;
  measurement_check: boolean;
  measured: boolean;
}

export interface IReportResource {
  expected_working_hours: number;
  appointment_report_id: number;
  resource_id: number;
  resource: IWorkerAndResource;
}

export enum AppointmentType {
  MEASURING = 'MEASURING',
  INSTALLATION = 'INSTALLATION',
  SERVICE = 'SERVICE',
  GENERAL = 'GENERAL',
}

export enum AppointmentTypeIds {
  MEASURING = '1',
  INSTALLATION = '2',
  GENERAL = '3',
  SERVICE = '4',
}

export enum AppointmentStatus {
  TO_CONFIRM = 'TO_CONFIRM',
  CONFIRMED = 'CONFIRMED',
  FINISHED = 'FINISHED',
  FAILED = 'FAILED',
  FIELD_WORK_IN_PROGRESS = 'FIELD_WORK_IN_PROGRESS',
  FIELD_WORK_FINISHED = 'FIELD_WORK_FINISHED',
  STOPPED = 'STOPPED',
}

export interface IAlternativeContact {
  id: number;
  first_name: string;
  last_name: string;
  address: IAddress;
  alternative_invoice_email: string;
  email: string;
  phone: string;
  secondary_phone: string;
  title: string;
}
