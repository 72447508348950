import { Select } from 'components/Select/Select';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import { useGetCompanyUsers } from 'utils/hooks/useGetCompanyUsers';

interface ISalesPersonSelectProps {
  handleValueChange: (e: any) => void;
  defaultValue: Option | number | string;
}

const SalesPersonSelect = ({
  handleValueChange,
  defaultValue,
}: ISalesPersonSelectProps) => {
  const { t } = useTranslation();

  const companyId = useSelector(
    (state: IRootReducerState) => state.userInfo.company_id
  );
  const { data: companyUsers, isLoading } = useGetCompanyUsers(companyId);

  const salesPersonOptions: Option[] = companyUsers?.users?.map((user: any) => {
    return {
      value: user.id,
      label: `${user.name} ${user.last_name}`,
    };
  });

  const preselectedSalesPerson =
    typeof defaultValue === 'object'
      ? defaultValue
      : typeof defaultValue === 'string' || typeof defaultValue === 'number'
      ? salesPersonOptions?.find(
          (option) => option.value.toString() === defaultValue.toString()
        )
      : undefined;

  return (
    <Select
      key={`${isLoading}`}
      name="advancedFilterValue"
      placeholder={t('Value')}
      isMulti={false}
      isDisabled={false}
      isSearchable={true}
      defaultValue={preselectedSalesPerson}
      onChange={handleValueChange}
      options={salesPersonOptions || []}
      isLoading={isLoading}
    />
  );
};

export default SalesPersonSelect;
