import Icon from 'components/Icon/Icon';
import Input from 'components/Input/Input';
import moment from 'moment';
import DatePicker from 'components/DatePicker/DatePicker';
import CustomerSelect from '../Selects/CustomerSelect';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { IFilterType } from '../types';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import { capitalizeFirstLetter } from 'utils/stringUtils';

import {
  AnyWrapper,
  FirstCell,
  Row,
  SelectWrapper,
  SelectWrapperNotFirst,
  Text,
} from './FilterRow.styled';
import { Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import SuppliersSelect from '../Selects/SuppliersSelect';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { useGetConditionOptions, useGetPreConditionOptions } from '../hooks';
import { getDefaultValue } from '../helpers';
import UsersSelect from '../Selects/UsersSelect';
import SalesPersonSelect from '../Selects/SalesPersonSelect';

interface IFilterRowProps {
  firstRow?: boolean;
  mainRow?: boolean;
  filterTypes: IFilterType[];
  isSubFilter?: boolean;
  onRemoveFilter: () => void;
  onFilterChange: (filter: any) => void;
  initialCondition?: string;
  initialPrecondition?: string;
  initialType?: any;
  initialValue?: any;
  pwId?: string;
}

const FilterRow = ({
  mainRow,
  firstRow,
  isSubFilter,
  filterTypes,
  onFilterChange,
  onRemoveFilter,
  initialCondition,
  initialPrecondition,
  initialType,
  initialValue,
  pwId,
}: IFilterRowProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<any>(initialValue);
  const [selectedType, setSelectedType] = useState<IFilterType | undefined>();
  const [condition, setCondition] = useState<string | undefined>(
    initialCondition
  );
  const [precondition, setPrecondition] = useState<string | undefined>(
    initialPrecondition
  );

  const { isTablet, isSmallTablet } = useBreakpointFlag();
  const conditionOptions = useGetConditionOptions();
  const preConditionOptions = useGetPreConditionOptions();

  useEffect(() => {
    if (initialType) {
      setSelectedType(filterTypes.find((ft) => ft.value === initialType));
    }
  }, []);

  // When we using saved advanced filters we got numbers (id's), not values, so we have to filter it
  const defaultValue = useMemo(
    () => getDefaultValue(value, selectedType?.options || []),
    [value, selectedType?.options]
  );

  const handlePreConditionChange = (e: Option) => {
    const filterData = {
      type: selectedType?.value,
      condition,
      value,
      ...(!firstRow && { precondition: e.value }),
    };

    if (!firstRow) {
      setPrecondition(e.value);
    }

    onFilterChange(filterData);
  };

  const handleTypeChange = (e: Option) => {
    const type = filterTypes.find((ft) => ft.value === e.value);
    setSelectedType(type);
    onFilterChange({
      ...(firstRow ? {} : { precondition }),
      type: e.value,
      condition,
      value: '',
    });
    setValue(null);
  };

  const handleConditionChange = (e: Option) => {
    onFilterChange({
      ...(firstRow ? {} : { precondition }),
      type: selectedType?.value,
      condition: e.value,
      value,
    });

    setCondition(e.value);
  };

  const handleValueChange = (e: any) => {
    onFilterChange({
      ...(firstRow ? {} : { precondition }),
      type: selectedType?.value,
      condition,
      value: e,
    });

    setValue(e);
  };

  const renderSelectComponent = () => {
    switch (selectedType?.value) {
      case 'customer':
        return (
          <CustomerSelect
            handleValueChange={handleValueChange}
            defaultValue={value}
          />
        );
      case 'company':
        return (
          <SuppliersSelect
            handleValueChange={handleValueChange}
            defaultValue={value}
          />
        );
      case 'sales_person':
        return (
          <SalesPersonSelect
            handleValueChange={handleValueChange}
            defaultValue={value}
          />
        );
      case 'user':
      case 'created_by':
      case 'assigned_to':
      case 'created_by_user_id':
      case 'assigned_to_user_id':
        return (
          <UsersSelect
            handleValueChange={handleValueChange}
            defaultValue={value}
          />
        );
      default:
        return (
          <Select
            name="advancedFilterValue"
            placeholder={t('Value')}
            isMulti={false}
            isDisabled={false}
            defaultValue={defaultValue}
            isSearchable={true}
            onChange={handleValueChange}
            options={selectedType?.options || []}
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
          />
        );
    }
  };

  return (
    <Row $isSubFilter={isSubFilter}>
      {!firstRow ? (
        <SelectWrapperNotFirst
          $width={isSmallTablet ? '100%' : isTablet ? '120rem' : '100rem'}
        >
          <Select
            pwId={pwId}
            name="advancedFilterSelect"
            defaultValue={preConditionOptions.find(
              (po) => po.value === initialPrecondition
            )}
            isMulti={false}
            placeholder={''}
            isDisabled={false}
            isSearchable={false}
            onChange={handlePreConditionChange}
            options={preConditionOptions}
          />
          {!mainRow && isSmallTablet && (
            <Icon
              svg={Trash}
              size={25}
              color={COLORS.RED_200}
              weight="regular"
              onClick={onRemoveFilter}
              paddingRight={isSmallTablet ? '0' : '15rem'}
            />
          )}
        </SelectWrapperNotFirst>
      ) : (
        <FirstCell>
          <Text data-testid={`${pwId}-where-text`}>{t('Where')}:</Text>
          {!mainRow && isSmallTablet && (
            <Icon
              svg={Trash}
              size={25}
              color={COLORS.RED_200}
              weight="regular"
              onClick={onRemoveFilter}
            />
          )}
        </FirstCell>
      )}
      <SelectWrapper $width={isSmallTablet ? '48%' : '150rem'}>
        <Select
          pwId={`${pwId}-type-input`}
          key={selectedType?.value}
          name="advancedFilterSelect"
          placeholder={t('Type')}
          isMulti={false}
          isDisabled={false}
          isSearchable={false}
          defaultValue={
            selectedType?.value
              ? {
                  label: selectedType?.label ?? '',
                  value: selectedType?.value ?? '',
                }
              : undefined
          }
          onChange={handleTypeChange}
          options={filterTypes.map((ft) => ({
            label: ft.label,
            value: ft.value,
          }))}
        />
      </SelectWrapper>
      <SelectWrapper $width={isSmallTablet ? '48%' : '130rem'}>
        <Select
          pwId={`${pwId}-condition-input`}
          name="advancedFilterSelect"
          defaultValue={conditionOptions.find(
            (co) => co.value === initialCondition
          )}
          placeholder={''}
          isMulti={false}
          isDisabled={false}
          isSearchable={false}
          onChange={handleConditionChange}
          options={
            selectedType?.type === 'dropdown' || selectedType?.value === 'city'
              ? conditionOptions.slice(0, 5)
              : conditionOptions
          }
        />
      </SelectWrapper>
      <AnyWrapper
        $width={
          isSmallTablet
            ? '100%'
            : isSubFilter
            ? isTablet
              ? '250rem'
              : '265rem'
            : '400rem'
        }
      >
        {(selectedType?.type === 'string' || !selectedType?.type) && (
          <Input
            pwId={`${pwId}-value-input`}
            type="text"
            placeholder={t('Value')}
            onChange={handleValueChange}
            width={
              isSmallTablet
                ? '100%'
                : isSubFilter
                ? isTablet
                  ? '250rem'
                  : '265rem'
                : '400rem'
            }
            defaultValue={value?.target?.value ?? value}
          />
        )}
        {selectedType?.type === 'dropdown' && renderSelectComponent()}
        {selectedType?.type === 'date' && (
          <DatePicker
            date={moment(value)}
            width={
              isSmallTablet
                ? '100%'
                : isSubFilter
                ? isTablet
                  ? '250rem'
                  : '265rem'
                : '400rem'
            }
            setDate={(newValue: any) =>
              handleValueChange(newValue?.format('YYYY-MM-DD'))
            }
          />
        )}
      </AnyWrapper>
      {!mainRow && !isSmallTablet && (
        <Icon
          pwId={`${pwId}-delete`}
          svg={Trash}
          size={20}
          color={COLORS.RED_200}
          weight="regular"
          onClick={onRemoveFilter}
        />
      )}
    </Row>
  );
};

export default FilterRow;
