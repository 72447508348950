import { H4 } from 'assets/styled';
import {
  CloseWrapper,
  Container,
  HorizontalLine,
  LinkText,
  LinkTextWrapper,
  Wrapper,
} from '../ViewProductModal.styled';
import Button from 'components/Button/Button';
import { useTranslation } from 'react-i18next';
import { formatNumber } from 'utils/numberFormatter';
import FakeInput from 'components/FakeInput/FakeInput';
import { IViewProductProps } from '../type';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';

interface IGeneralTabProps {
  productLineData: IViewProductProps;
  onCancel: () => void;
}

export const GeneralTab = ({ productLineData, onCancel }: IGeneralTabProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();

  const { isSmallTablet } = useBreakpointFlag();

  const values = {
    status: t(
      productLineData?.status
        .toLowerCase()
        .replace(/_/g, ' ')
        .charAt(0)
        .toUpperCase() +
        productLineData!.status.toLowerCase().replace(/_/g, ' ').slice(1)
    ),
    supplier: productLineData?.supplier,
    salesPrice: `${currencySymbol}${formatNumber(productLineData?.salesPrice)}`,
    quantity: productLineData?.quantity,
    totalDiscount: `${currencySymbol}${
      productLineData?.totalDiscount
        ? formatNumber(productLineData?.totalDiscount)
        : '0'
    }`,
    totalPurchaseCost: `${currencySymbol}${formatNumber(
      Number(productLineData?.totalPurchaseCost) *
        Number(productLineData?.quantity)
    )}`,
    purchaseOrderNumber: productLineData?.purchaseOrder?.number,
    purchaseOrderId: productLineData?.purchaseOrder?.id,
  };

  return (
    <Container>
      <Wrapper>
        <H4>{t('Status')}</H4>
        <FakeInput
          placeholder=""
          label={values.status}
          width={isSmallTablet ? '100%' : '300rem'}
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Supplier')}</H4>
        <FakeInput
          placeholder=""
          label={values.supplier || ''}
          width={isSmallTablet ? '100%' : '300rem'}
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Sales price')}</H4>
        <FakeInput
          placeholder=""
          label={values.salesPrice || ''}
          width={isSmallTablet ? '100%' : '300rem'}
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Quantity')}</H4>
        <FakeInput
          placeholder=""
          label={values.quantity?.toString() || ''}
          width={isSmallTablet ? '100%' : '300rem'}
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Total discount')}</H4>
        <FakeInput
          placeholder=""
          label={values.totalDiscount || ''}
          width={isSmallTablet ? '100%' : '300rem'}
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />
        <H4>{t('Total purchase cost exc. VAT')}</H4>
        <FakeInput
          placeholder=""
          label={values.totalPurchaseCost || ''}
          width={isSmallTablet ? '100%' : '300rem'}
          marginBottom="20rem"
          marginTop="20rem"
        />
        <HorizontalLine />

        <H4>{t('Purchase Order')}</H4>
        {values.purchaseOrderId && (
          <LinkTextWrapper>
            <LinkText
              href={RoutesConfig.SinglePurchaseOrder.fullPath.replace(
                ':id/*',
                values.purchaseOrderId
              )}
              onClick={(e: any) => {
                e.preventDefault();
                navigate(
                  RoutesConfig.SinglePurchaseOrder.fullPath.replace(
                    ':id/*',
                    values.purchaseOrderId || ''
                  )
                );
              }}
            >
              {values.purchaseOrderNumber}
            </LinkText>
          </LinkTextWrapper>
        )}
      </Wrapper>
      <CloseWrapper>
        <Button onClick={onCancel} primary width="200rem" label={t('Close')} />
      </CloseWrapper>
    </Container>
  );
};
