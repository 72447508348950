import { IAppointment } from 'types/Appointment.types';
import { IShippingData } from 'types/Quotations.types';

export const renderCustomerForGeneralAppointment = (
  appointment: IAppointment,
  appointmentShippingData: IShippingData
) => {
  if (appointmentShippingData)
    return `${appointmentShippingData?.first_name} ${appointmentShippingData?.last_name}`;
  else if (appointment?.quotation)
    return `${appointment?.quotation?.customer?.name} ${appointment?.quotation?.customer?.last_name}`;
  else if (appointment?.sales_order)
    return `${appointment?.sales_order?.customer?.name} ${appointment?.sales_order?.customer?.last_name}`;
  else return null;
};

export const renderCustomerName = (
  appointment: IAppointment,
  appointmentShippingData: IShippingData
) => {
  if (appointmentShippingData)
    return `${appointmentShippingData?.first_name} ${appointmentShippingData?.last_name}`;
  else
    return `${appointment?.customer?.name} ${appointment?.customer?.last_name}`;
};

export const renderCustomerPhone = (appointment: IAppointment) => {
  if (appointment?.quotation) {
    const shippingPhone = appointment?.quotation?.shipping_data?.phone;
    return shippingPhone
      ? shippingPhone
      : appointment?.quotation?.customer?.phone;
  } else if (appointment?.sales_order) {
    const customerPhone =
      appointment?.sales_order?.customer_snapshot?.shipping_data?.phone;
    return customerPhone
      ? customerPhone
      : appointment?.sales_order?.customer_snapshot?.phone_number;
  } else if (appointment?.customer) {
    return appointment?.customer?.phone;
  } else {
    return null;
  }
};

export const renderCustomerAddress = (
  appointment: IAppointment,
  appointmentShippingData: IShippingData
) => {
  if (appointmentShippingData)
    return `${appointmentShippingData?.address?.full_address}`;
  else {
    if (appointment?.quotation)
      return `${appointment?.quotation?.customer?.address?.full_address}`;
    else if (appointment?.sales_order)
      return `${appointment?.sales_order?.customer_snapshot?.address?.full_address}`;
    else if (appointment?.customer)
      return `${appointment?.customer?.address?.full_address}`;
    else return null;
  }
};

export const getEmailDetails = (appointment: IAppointment) => {
  if (appointment?.quotation) {
    return {
      email:
        appointment?.quotation?.shipping_data?.email ??
        appointment?.quotation?.customer?.email,
      type: appointment?.quotation?.different_shipping_address
        ? 'Ship-to'
        : 'General',
    };
  } else if (appointment?.sales_order) {
    return {
      email:
        appointment?.sales_order?.customer_snapshot?.shipping_data?.email ??
        appointment?.sales_order?.customer_snapshot?.email,
      type:
        appointment?.sales_order?.different_shipping_address === null
          ? ''
          : appointment?.sales_order?.different_shipping_address
          ? 'Ship-to'
          : 'General',
    };
  } else if (appointment?.alternative_contact) {
    return {
      email: appointment?.alternative_contact?.email,
      type: 'General',
    };
  } else if (appointment?.customer) {
    return {
      email: appointment?.customer?.email,
      type: 'General',
    };
  } else {
    return;
  }
};
