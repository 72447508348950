import { COLORS, marginMd, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import Button from 'components/Button/Button';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  width: 1075rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-bottom: ${marginMd};

  ${respondTo.horizontalTablet`
    width: 100%;
    padding: 0 ${paddingMd};
  `}

  ${respondTo.smallTablet`
    height: 100%;
    position: relative;
    padding: 0 15rem;
  `}
`;

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 50rem auto 30rem;

  ${respondTo.smallTablet`
    margin: 20rem auto;
  `}
`;

export const Title = styled.h2`
  color: ${COLORS.BLACK};
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;

  ${respondTo.smallTablet`
    position: fixed;
    bottom: 75rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  `}
`;

export const StyledButton = styled(Button)`
  background-color: ${COLORS.GREY_1000};

  &:hover {
    background-color: ${COLORS.GREY_1000};
  }
`;

export const Search = styled.div`
  width: 400rem;
  display: flex;
  align-self: flex-start;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;
