import { ERPError, getToastErrorMessage } from 'services/api/errors';
import { queryClient } from 'index';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'utils/toast';
import {
  createSalesOrderLine,
  editSalesOrderLine,
  getPendingAppointments,
} from 'services/SalesOrder/SalesOrderService';
import { ReactMutationKeys } from 'services/api/reactMutationKeys';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import {
  IAddSalesOrderLineData,
  IEditSalesOrderLine,
} from 'types/SalesOrders.types';

export const useAddSalesOrderLine = (salesOrderId: string) => {
  const { t } = useTranslation();
  return useMutation(
    (addSalesOrderLineData: IAddSalesOrderLineData) =>
      createSalesOrderLine(salesOrderId, addSalesOrderLineData),
    {
      onSuccess: () => {
        toast.success(t('Successfully added Product to Sales Order'), {
          className: ReactMutationKeys.ADD_SALES_ORDER_LINE,
        });
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_ORDER]);
      },
      onError: (error: ERPError) => {
        toast.error(getToastErrorMessage(error), {
          toastId: ReactMutationKeys.ADD_SALES_ORDER_LINE,
        });
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.ADD_SALES_ORDER_LINE,
    }
  );
};

export const useEditSalesOrderLine = (
  salesOrderId: string,
  salesOrderLineId: string
) => {
  const { t } = useTranslation();
  return useMutation(
    (editSalesOrderLineData: IEditSalesOrderLine) =>
      editSalesOrderLine(
        salesOrderId,
        salesOrderLineId,
        editSalesOrderLineData
      ),
    {
      onSuccess: () => {
        toast.success(t('Successfully edited Sales Order Product'), {
          className: ReactMutationKeys.EDIT_SALES_ORDER_LINE,
        });
        queryClient.invalidateQueries([ReactQueryKeys.GET_SINGLE_SALES_ORDER]);
      },
      onError: (error: any) => {
        if (error?.response?.data?.errors?.[0]?.endsWith('cannot be edited')) {
          toast.error(t('Invoiced lines cannot be edited'));
        } else {
          toast.error(getToastErrorMessage(error), {
            toastId: ReactMutationKeys.EDIT_SALES_ORDER_LINE,
          });
        }
      },
      onSettled: () => {
        // Finally
      },
      mutationKey: ReactMutationKeys.EDIT_SALES_ORDER_LINE,
    }
  );
};

export const useGetPrepopulateSalesOrderLineData = () => {
  const { state } = useLocation();
  if (state?.salesOrderLine) {
    return state.salesOrderLine;
  } else {
    return null;
  }
};

export const useGetPendingAppointments = () =>
  useMutation((salesOrderId: number) => getPendingAppointments(salesOrderId), {
    onSuccess: () => {
      //
    },
    onError: (error: ERPError) => {
      //
    },
    onSettled: () => {
      // Finally
    },
    mutationKey: ReactMutationKeys.GET_PENDING_APPOINTMENTS,
  });

export const useGetGeneralDiscountInfo = () => {
  const { state } = useLocation();
  const generalDiscountInfo = state?.generalDiscountInfo || null;

  return generalDiscountInfo;
};
