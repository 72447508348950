import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { borderRadiusSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

interface StyledProps {
  $flexValue: string;
  $isBlueColor: boolean;
  $selected: boolean;
  $isRed: boolean;
  isPaginationPresent: boolean;
}

export const Table = styled.div``;
export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5rem;
  padding: 10rem 20rem;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${COLORS.GREY_500};
  border-radius: ${borderRadiusSm};
`;

export const HeaderCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: left;
  padding: 0 5rem;
  ${(props: StyledProps) =>
    props.$flexValue &&
    css`
      flex: ${props.$flexValue};
    `};
`;

export const HeaderLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${COLORS.GREY_500};
  padding: 15rem;

  &:last-of-type {
    border: none;
  }

  background-color: ${(props: StyledProps) =>
    props.$selected ? `${COLORS.BACKGROUND_COLOR}` : `none`};
`;

export const BodyCell = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex: 1;
  padding: 0 5rem;
`;

export const BodyLabel = styled.label`
  font-size: 14rem;
  color: ${COLORS.BLACK};
  font-weight: 300;

  ${(props: StyledProps) =>
    props.$isBlueColor &&
    css`
      color: ${COLORS.PRIMARY};
    `};
`;

export const ActionHeaderCell = styled.div`
  display: flex;
  width: 40rem;
  justify-content: flex-end;
  color: ${COLORS.PRIMARY};
`;

export const ActionCell = styled.div`
  display: flex;
  width: 40rem;
  justify-content: flex-end;
`;

export const Footer = styled.div`
  padding: 20rem;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20rem;
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  padding: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${COLORS.GREY_300};

  ${respondTo.bigPhone`
    max-width: 100%;
  `}

  &:nth-child(1n) {
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      background-color: ${COLORS.GREY_300};
      width: 1px;
      height: 80%;

      ${respondTo.bigPhone`
        display: none;
      `}
    }
  }

  &:nth-child(2n) {
    &::before {
      display: none;
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20rem;
  margin-bottom: 20rem;
`;
export const CardTitle = styled.span`
  display: block;
  width: 40%;
  color: ${COLORS.BLACK};
`;
export const CardValue = styled.span`
  display: block;
  width: 60%;
  color: ${COLORS.BLACK};
`;
export const CardActions = styled.div`
  display: flex;
  gap: 10rem;
  align-items: center;
  padding: 10rem 0;
  align-self: center;
`;
