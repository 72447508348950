export const defaultFilterGroups = [
  {
    id: 'group1',
    filters: [
      {
        id: 'filter1',
        type: '',
        condition: 'eq',
        value: '',
      },
    ],
    subFilters: [],
  },
];
