import {
  Container,
  FormikContainer,
  Row,
  SubmitRow,
  Margin,
  Line,
  RightAlign,
  ItemsLabel,
  SingleItemWrapper,
  StyledCustomSelect,
  ItemDropdownWrapper,
  RowMarginTop,
  FieldWrapper,
} from './ItemsTab.styled';
import Button from 'components/Button/Button';
import { Field, Formik, getIn } from 'formik';
import { Input } from 'components/Input/InputFormik';
import {
  ICreatePurchaseOrderItem,
  STOCK_ITEMS_FOR_CREATING_PURCHASE_ORDER_PER_PAGE,
} from './constants';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useGetStockItemsForCreatingPurchaseOrderInfinite } from './hooks';
import {
  calculateTotalPriceFromQuantityAndCustomPrice,
  validateLastItem,
} from './helpers';
import { useTranslation } from 'react-i18next';
import { validateNumericField } from 'utils/validation';
import { useParams } from 'react-router-dom';
import { formatNumber } from 'utils/numberFormatter';
import Icon from 'components/Icon/Icon';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { Plus, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useDebounce } from 'utils/hooks/useDebounce';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const MIN_STOCK_ITEMS = 1;

interface IItemsTabProps {
  onForward: () => void;
  onBack: () => void;
  items: ICreatePurchaseOrderItem[];
  setItems: Dispatch<SetStateAction<ICreatePurchaseOrderItem[]>>;
}

const ItemsTab = ({ onForward, onBack, items, setItems }: IItemsTabProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const currencySymbol = useGetCurrencySymbol();

  const [searchBy, setSearchBy] = useState<string>('');
  const debouncedSearchBy = useDebounce(searchBy);

  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);

  const { isPhone, isSmallPhone } = useBreakpointFlag();

  const { data, fetchNextPage, hasNextPage, isLoading } =
    useGetStockItemsForCreatingPurchaseOrderInfinite(
      STOCK_ITEMS_FOR_CREATING_PURCHASE_ORDER_PER_PAGE,
      debouncedSearchBy,
      id!
    );

  const stockItemOptions = useMemo(() => {
    if (data?.pages?.length) {
      return data.pages
        .map((page) => page.stock_items)
        .flat()
        .map((stockItem: any) => {
          return {
            value: stockItem,
            label: stockItem.product.name,
            key: stockItem.id,
          };
        });
    }
    return [];
  }, [data]);

  return (
    <Container>
      <ItemsLabel>{t('Add to Stock Items')}</ItemsLabel>
      <Formik
        enableReinitialize
        initialValues={items}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={setItems}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          handleChange,
          submitForm,
          isValid,
          values,
          errors,
          touched,
          setFieldTouched,
          setFieldError,
          setValues,
        }) => {
          return (
            <FormikContainer>
              <>
                {values.map((item: ICreatePurchaseOrderItem, index) => {
                  return (
                    <SingleItemWrapper key={index}>
                      <ItemDropdownWrapper>
                        <FieldWrapper>
                          <Field
                            pwId={`stock-item-${index}`}
                            name={`${index}.item`}
                            options={stockItemOptions}
                            component={StyledCustomSelect}
                            placeholder={t('Stock Items')}
                            isMulti={false}
                            onInputChange={(value: string) => {
                              setSearchBy(value ? value : '');
                            }}
                            onSelect={(e: any) => {
                              setFieldValue(
                                `${index}.price`,
                                e.value.purchase_price
                              );
                              setFieldValue(
                                `${index}.totalPrice`,
                                `${currencySymbol}${formatNumber(
                                  calculateTotalPriceFromQuantityAndCustomPrice(
                                    getIn(values[index], 'quantity'),
                                    e.value.purchase_price
                                  )
                                )}`
                              );
                            }}
                            isLoading={isLoading}
                            onMenuScrollToBottom={() =>
                              hasNextPage && fetchNextPage()
                            }
                          />
                        </FieldWrapper>
                        {values.length > MIN_STOCK_ITEMS && (
                          <Icon
                            wrapperStyle={{
                              marginLeft: isPhone ? '10rem' : 0,
                              marginTop: isPhone ? '-10rem' : 0,
                            }}
                            svg={Trash}
                            color={COLORS.RED_200}
                            size={20}
                            weight="regular"
                            onClick={() => {
                              const newValues = [...values];
                              newValues.splice(index, 1);
                              setValues(newValues, false);
                            }}
                          />
                        )}
                      </ItemDropdownWrapper>
                      <Row>
                        <Input
                          pwId={`quantity-field-${index}`}
                          type="number"
                          validate={(value: string) =>
                            validateNumericField(value, 'quantity')
                          }
                          errorMessage={
                            getIn(touched[index], 'quantity') ||
                            areAllFieldsTouched
                              ? getIn(errors[index], 'quantity')
                              : ''
                          }
                          height={'41rem'}
                          name={`${index}.quantity`}
                          placeholder={t('Quantity')}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue(e.target.name, value);
                            handleBlur(e);
                          }}
                          wrapperStyles={{
                            width: isPhone ? '100%' : '50%',
                          }}
                          disabled={!getIn(values[index], 'item')}
                          onChange={(e: any) => {
                            const quantity = e;
                            setFieldValue(
                              `${index}.totalPrice`,
                              `${currencySymbol}${formatNumber(
                                calculateTotalPriceFromQuantityAndCustomPrice(
                                  quantity,
                                  getIn(values[index], 'price')
                                )
                              )}`
                            );
                            setTimeout(
                              () =>
                                setFieldTouched(`${index}.price`, true, true) // Settimeout is a workaround because price's value doesnt get validated once its value has been changed with setFieldValue in quantity's onChange
                            );
                            handleChange(e);
                          }}
                        />
                        <Input
                          pwId={`price-field-${index}`}
                          type="number"
                          validate={(value: string) =>
                            validateNumericField(value, 'price')
                          }
                          errorMessage={
                            getIn(touched[index], 'price') ||
                            areAllFieldsTouched
                              ? getIn(errors[index], 'price')
                              : ''
                          }
                          height={'41rem'}
                          name={`${index}.price`}
                          placeholder={t('Purchase Price exc. VAT')}
                          disabled={!getIn(values[index], 'item')}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue(e.target.name, value);
                            handleBlur(e);
                          }}
                          onChange={(e: any) => {
                            const price = e;

                            setFieldValue(
                              `${index}.totalPrice`,
                              `${currencySymbol}${formatNumber(
                                calculateTotalPriceFromQuantityAndCustomPrice(
                                  getIn(values[index], 'quantity'),
                                  price
                                )
                              )}`
                            );
                            handleChange(e);
                          }}
                          wrapperStyles={{
                            width: isPhone ? '100%' : '50%',
                          }}
                        />
                      </Row>
                      <RowMarginTop>
                        <FieldWrapper>
                          <Input
                            height={'41rem'}
                            name={`${index}.totalPrice`}
                            placeholder={t('Total price')}
                            disabled
                            wrapperStyles={{
                              width: '100%',
                            }}
                          />
                        </FieldWrapper>
                      </RowMarginTop>
                      <Line />
                    </SingleItemWrapper>
                  );
                })}
                <RightAlign>
                  <Button
                    label={t('Add another item')}
                    icon={Plus}
                    sizeIcon={15}
                    colorIcon={COLORS.PRIMARY}
                    weightIcon="bold"
                    link
                    underline
                    onClick={() => {
                      const newIndex = values.length;
                      setFieldValue(`${newIndex}.item`, '');
                      setFieldValue(`${newIndex}.quantity`, '');
                      setFieldValue(`${newIndex}.price`, '');
                    }}
                    fontSize="18rem"
                  />
                </RightAlign>
                <SubmitRow>
                  <Button
                    width={isSmallPhone ? '150rem' : '200rem'}
                    onClick={() => {
                      onBack();
                      if (isValid) {
                        setItems(values);
                      }
                    }}
                    label={t('Back')}
                    secondary
                  />
                  <Margin>
                    <Button
                      onClick={async (e) => {
                        setItems(values);
                        submitForm();
                        setAreAllFieldsTouched(true);
                        if (!(await validateLastItem(values, setFieldError))) {
                          return;
                        }
                        if (isValid) {
                          onForward();
                        }
                      }}
                      label={t('Next')}
                      primary
                      width={isSmallPhone ? '150rem' : '200rem'}
                    />
                  </Margin>
                </SubmitRow>
              </>
            </FormikContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ItemsTab;
