import { borderRadiusSm, marginSm, marginXs } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

export const containerStyle = css`
  position: relative;
  font-family: inherit;
  border-radius: ${borderRadiusSm};
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const SelectWrapper = styled.div`
  width: 300rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const DocumentSelectWrapper = styled.div`
  min-width: 238rem;
  margin-left: ${marginSm};

  ${respondTo.bigPhone`
    margin-left: 0;
    min-width: auto;
    max-width: 100%;
    width: 100%;
  `}
`;

export const InputWrapper = styled.div`
  pointer-events: none;
  width: 100%;
`;

export const RowAlignCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const RightPart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
  `}
`;

export const EnableFieldAfterLogyxConfigurationWrapper = styled.div`
  margin-top: ${marginXs};
`;

export const DocSelectText = styled.label`
  display: none;
  ${respondTo.bigPhone`
      display: block
      width: 100%;
      font-size: 16rem;
      line-height: 24rem;
      font-weight: 300;
  `}
`;
