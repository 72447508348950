import { DotsThree, PencilSimple, Trash } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { useTranslation } from 'react-i18next';
import { Container, DropDown, Row, Text } from './MobileActionDropdown.styled';
import Icon from 'components/Icon/Icon';
import { useRef, useState } from 'react';
import { useOutsideAlerter } from 'utils/hooks/useOutsideAlerter';

interface IMobileActionDropdownProps {
  onDeleteClick?: () => void;
  onEditClick?: () => void;
  canUseAction?: boolean;
}

const MobileActionDropdown = ({
  onEditClick,
  onDeleteClick,
  canUseAction,
}: IMobileActionDropdownProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const dropdownRef = useRef();
  useOutsideAlerter(dropdownRef, () => setIsOpen(false));

  return (
    <>
      {canUseAction && (
        <Container ref={dropdownRef}>
          <Icon
            svg={DotsThree}
            color={COLORS.BLACK}
            size={30}
            weight="bold"
            onClick={() => setIsOpen(!isOpen)}
          />
          {isOpen && (
            <DropDown $isOpen={isOpen}>
              {onEditClick && (
                <Row onClick={() => onEditClick()}>
                  <Icon svg={PencilSimple} color={COLORS.PRIMARY} size={15} />
                  <Text>{t('Edit')}</Text>
                </Row>
              )}
              {onDeleteClick && (
                <Row onClick={() => onDeleteClick()}>
                  <Icon svg={Trash} color={COLORS.RED_200} size={15} />
                  <Text>{t('Delete')}</Text>
                </Row>
              )}
            </DropDown>
          )}
        </Container>
      )}
    </>
  );
};

export default MobileActionDropdown;
