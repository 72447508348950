import { COLORS, gapLg, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;
  gap: ${gapLg};

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    gap: 20rem;
  `}
`;

export const TimelineCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 850rem;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
  `}
`;

export const BackgroundColorWrapper = styled.div`
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  padding: 30rem;

  ${respondTo.smallTablet`
    padding: 20rem;
  `}
`;

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  margin-bottom: ${marginMd};
  color: ${COLORS.BLACK};

  ${respondTo.bigPhone`
    font-size: 18rem;
    line-height: 1.2;
    margin-bottom: 20rem;
  `}
`;

export const SwiperWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 100%;
`;
