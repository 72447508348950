import {
  Container,
  ButtonContainer,
  ItemContainer,
  ItemText,
  ButtonContainerTablet,
  ContainerTablet,
  ItemContainerTablet,
  ItemTextTablet,
  ArrowWrapper,
  ItemContainerAbsoluteScalable,
} from './SubSideMenu.styled';
import { ISubMenuItem } from './type';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import Icon from 'components/Icon/Icon';
import { COLORS } from 'assets/styled';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

const PWID = 'sub-side-menu';

export interface ISubMenuProps {
  subMenuItems: ISubMenuItem[];
  subMenuItemsInfo?: { [key: string]: string };
  isFieldWorker?: boolean;
  pwId?: string;
}

const MAX_DISPLAYED_TABLET_SIDEMENU_ITEM_COUNT = 6;

const SubSideMenu = ({
  subMenuItems,
  isFieldWorker = false,
  subMenuItemsInfo,
  pwId,
}: ISubMenuProps) => {
  const { t } = useTranslation();
  const [arrowSubMenuOffset, setArrowSubMenuOffset] = useState<number>(0);

  const { isTablet } = useBreakpointFlag();

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );

  const slicedItems = subMenuItems.slice(
    0 + arrowSubMenuOffset,
    MAX_DISPLAYED_TABLET_SIDEMENU_ITEM_COUNT + arrowSubMenuOffset
  );

  if (isTablet) {
    return (
      <ContainerTablet>
        <ButtonContainerTablet
          isSideMenuCollapsed={isSideMenuCollapsed}
          isFieldWorker={isFieldWorker}
        >
          <ArrowWrapper>
            {subMenuItems.length > MAX_DISPLAYED_TABLET_SIDEMENU_ITEM_COUNT && (
              <Icon
                svg={CaretLeft}
                color={COLORS.BLACK}
                size={18}
                weight="regular"
                wrapperStyle={{
                  display: arrowSubMenuOffset === 0 ? 'none' : '',
                }}
                onClick={() => setArrowSubMenuOffset(arrowSubMenuOffset - 1)}
              />
            )}
          </ArrowWrapper>
          {slicedItems.map((subMenu: ISubMenuItem, index) => {
            return (
              <ItemContainerTablet
                to={subMenu.route}
                end={subMenu.route === ''}
                key={index}
              >
                <ItemContainerAbsoluteScalable>
                  <ItemTextTablet>
                    {t(subMenu.text)}&nbsp;
                    {subMenuItemsInfo?.[subMenu.text]
                      ? `(${subMenuItemsInfo?.[subMenu.text]})`
                      : null}
                  </ItemTextTablet>
                </ItemContainerAbsoluteScalable>
              </ItemContainerTablet>
            );
          })}
          <ArrowWrapper>
            {subMenuItems.length > MAX_DISPLAYED_TABLET_SIDEMENU_ITEM_COUNT && (
              <Icon
                svg={CaretRight}
                color={COLORS.BLACK}
                size={18}
                weight="regular"
                wrapperStyle={{
                  display:
                    arrowSubMenuOffset ===
                    subMenuItems.length -
                      MAX_DISPLAYED_TABLET_SIDEMENU_ITEM_COUNT
                      ? 'none'
                      : '',
                }}
                onClick={() => setArrowSubMenuOffset(arrowSubMenuOffset + 1)}
              />
            )}
          </ArrowWrapper>
        </ButtonContainerTablet>
      </ContainerTablet>
    );
  } else {
    return (
      <Container isFieldWorker={isFieldWorker}>
        <ButtonContainer>
          {subMenuItems.map((subMenu: ISubMenuItem, index) => (
            <ItemContainer
              data-testid={`${pwId}-${PWID}-${subMenu.text}`}
              to={subMenu.route}
              key={index}
              end={subMenu.route === ''}
            >
              {({ isActive }: any) => (
                <ItemText>
                  {t(subMenu.text)}&nbsp;
                  {subMenuItemsInfo?.[subMenu.text]
                    ? `(${subMenuItemsInfo?.[subMenu.text]})`
                    : null}
                </ItemText>
              )}
            </ItemContainer>
          ))}
        </ButtonContainer>
      </Container>
    );
  }
};

export default SubSideMenu;
