import moment, { Moment } from 'moment';
import { useQuery } from 'react-query';
import { getFwAppointmentsDaySummary } from 'services/Appointment/FwAppointmentService';
import { ReactQueryKeys } from 'services/api/reactQueryKeys';
import { Option } from 'components/Select/type';

export const useGetDaySummaryAppointments = (
  dateFrom: Moment | null,
  users?: string[],
  workers?: string[],
  resources?: string[],
  selectedTypes?: Option[]
) =>
  useQuery({
    queryKey: [
      ReactQueryKeys.GET_DAY_SUMMARY_APPOINTMENTS,
      dateFrom?.valueOf(),
      users,
      workers,
      resources,
      selectedTypes,
    ],
    queryFn: () => {
      return getFwAppointmentsDaySummary(
        dateFrom || moment(),
        users,
        workers,
        resources,
        selectedTypes
      );
    },
    enabled: !!dateFrom,
  });
