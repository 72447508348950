import { Navigate, Route, Routes } from 'react-router-dom';
import { RoutesConfig } from './routes';
import ManagerLayout from 'components/Layout/ManagerLayout/ManagerLayout';
import FieldWorkerLayout from 'components/Layout/FieldWorkerLayout/FieldWorkerLayout';
import LoginPage from 'pages/Auth/LoginPage/LoginPage';
import HomePage from 'pages/Manager/HomePage/HomePage';
import InvoicesPage from 'pages/Manager/InvoicesPage/InvoicesPage';
import NewQuotationPage from 'pages/Manager/NewQuotationPage/NewQuotationPage';
import NotificationsPage from 'pages/Shared/NotificationsPage/NotificationsPage';
import PageNotFound from 'pages/Shared/PageNotFound/PageNotFound';
import PlanningPage from 'pages/Manager/PlanningPage/PlanningPage';
import ProfilePage from 'pages/Shared/ProfilePage/ProfilePage';
import PurchaseOrdersPage from 'pages/Manager/PurchaseOrdersPage/PurchaseOrdersPage';
import QuotationsPage from 'pages/Manager/QuotationsPage/QuotationsPage';
import SalesOrdersPage from 'pages/Manager/SalesOrdersPage/SalesOrdersPage';
import SinglePurchaseInvoicePage from 'pages/Manager/SingleInvoice/SinglePurchaseInvoice/SinglePurchaseInvoicePage';
import SingleSalesOrderInvoicePage from 'pages/Manager/SingleInvoice/SingleSalesInvoice/SingleSalesInvoicePage';
import SinglePurchaseOrderPage from 'pages/Manager/SinglePurchaseOrder/SinglePurchaseOrderPage';
import SingleQuotationPage from 'pages/Manager/SingleQuotation/SingleQuotationPage';
import NewSalesOrderLinePage from 'pages/Manager/SingleSalesOrder/NewSalesOrderLinePage/NewSalesOrderLinePage';
import SingleSalesOrderPage from 'pages/Manager/SingleSalesOrder/SingleSalesOrderPage';
import SingleStockItemPage from 'pages/Manager/SingleStockItemPage/SingleStockItemPage';
import StockItemsPage from 'pages/Manager/StockItemsPage/StockItemsPage';
import SingleSalesCreditInvoicePage from 'pages/Manager/SingleInvoice/SingleSalesCreditInvoice/SingleSalesCreditInvoicePage';
import SinglePurchaseCreditInvoicePage from 'pages/Manager/SingleInvoice/SinglePurchaseCreditInvoice/SinglePurchaseCreditInvoicePage';
import NewStockItemPage from 'pages/Manager/NewStockItemPage/NewStockItemPage';
import PaymentSuccessfulPage from 'pages/Manager/PaymentSuccessfulPage/PaymentSuccessfulPage';
import MandateCreatedPage from 'pages/Manager/MandateCreatedPage/MandateCreatedPage';
import SettingsPage from 'pages/Manager/SettingsPage/SettingsPage';
import FwSingleAppointmentPage from 'pages/FieldWorker/FwSingleAppointmentPage/FwSingleAppointmentPage';
import FwAppointmentsPage from 'pages/FieldWorker/FwAppointmentsPage/FwAppointmentsPage';
import FwReportPage from 'pages/FieldWorker/FwReportPage/FwReportPage';
import AnalyticsPage from 'pages/Manager/AnalyticsPage/AnalyticsPage';
import FieldWorkerRoute from 'components/Routes/FieldWorkerRoute/FieldWorkerRoute';
import PublicRoute from 'components/Routes/PublicRoute/PublicRoute';
import PrivateRoute from 'components/Routes/PrivateRoute/PrivateRoute';
import ManagerRoute from 'components/Routes/ManagerRoute/ManagerRoute';
import { SalesPurchaseOrderInvoiceRoutes } from './SingleInvoice/PurchaseInvoice/SinglePurchaseInvoice.routes';
import { SingleSalesInvoiceRoutes } from './SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';
import { SalesCreditInvoiceRoutes } from './SingleInvoice/SalesCreditInvoice/SingleSalesCreditInvoice.routes';
import SignQuotationPage from 'pages/SignQuotation/SignQuotationPage';
import SingleQuotationNewLinePage from 'pages/Manager/SingleQuotation/SingleQuotationNewLinePage/SingleQuotationNewLinePage';
import AppointmentConfirmationPage from 'pages/AppointmentConfirmation/AppointmentConfirmationPage';
import { EmailTemplatesPage } from 'pages/Manager/EmailTemplatesPage/EmailTemplatesPage';
import RequestPasswordReset from 'pages/Auth/RequestPasswordReset/RequestPasswordReset';
import ResetPassword from 'pages/Auth/ResetPassword/ResetPassword';
import UserRolesPage from 'pages/Manager/UserRolesPage/UserRolesPage';
import ProtectedRoute from './ProtectedRoute/ProtectedRoute';
import { Roles } from 'types/Permissions.types';
import { useSelector } from 'react-redux';
import { IRootReducerState } from 'store/store';
import LoadingScreen from 'pages/Shared/LoadingScreen/LoadingScreen';
import { SignUpPage } from 'pages/Auth/SignUpPage/SignUpPage';
import { SignUpActivationPage } from 'pages/Auth/SignUpActivationPage/SignUpActivationPage';
import CustomersPage from 'pages/Manager/CustomersPage/CustomersPage';
import NewCustomerPage from 'pages/Manager/NewCustomerPage/NewCustomerPage';
import SingleCustomerPage from 'pages/Manager/SingleCustomerPage/SingleCustomerPage';
import SuppliersPage from 'pages/Manager/SuppliersPage/SuppliersPage';
import NewSupplierPage from 'pages/Manager/NewSupplierPage/NewSupplierPage';
import SingleSupplierPage from 'pages/Manager/SingleSupplierPage/SingleSupplierPage';
import { SingleSupplierRoutes } from './SingleSupplier/SingleSupplier.routes';
import NewPurchaseOrderPage from 'pages/Manager/NewPurchaseOrderPage/NewPurchaseOrderPage';
import NewSalesOrderPage from 'pages/Manager/NewSalesOrderPage/NewSalesOrderPage';
import NewAltContactPage from 'pages/Manager/NewAltContactPage/NewAltContactPage';
import LabelsPage from 'pages/Manager/LabelsPage/LabelsPage';
import ToDoPage from 'pages/Manager/ToDoPage/ToDoPage';
import { PaymentConditionsPage } from 'pages/Manager/PaymentConditionsPage/PaymentConditionsPage';
import WatermarkPage from 'pages/Manager/WatermarkPage/WatermarkPage';
import { CompanyConfigurationPage } from 'pages/Manager/CompanyConfigurationPage/CompanyConfigurationPage';
import ExternalIntegrationsPage from 'pages/Manager/ExternalIntegrationsPage/ExternalIntegrationsPage';
import { ProductGroupsPage } from 'pages/Manager/ProductGroupsPage/ProductGroupsPage';
import { RequestAppointmentPage } from 'pages/Manager/RequestAppointmentPage/RequestAppointmentPage';
import { DeclineOfferPage } from 'pages/Manager/DeclineOfferPage/DeclineOfferPage';

export default function AppNavigationRouter() {
  const isLoadingScreenShown = useSelector(
    (state: IRootReducerState) => state.commonInfo.isLoadingScreenShown
  );
  if (isLoadingScreenShown) {
    return (
      <Routes>
        <Route path="*" element={<LoadingScreen />} />
      </Routes>
    );
  }
  return (
    <Routes>
      <Route path="*" element={<PageNotFound />} />
      <Route
        path={RoutesConfig.PaymentSuccessful.fullPath}
        element={<PaymentSuccessfulPage />}
      />
      <Route
        path={RoutesConfig.MandateCreated.fullPath}
        element={<MandateCreatedPage />}
      />
      <Route
        path={RoutesConfig.SignQuotation.path}
        element={<SignQuotationPage />}
      />
      <Route
        path={RoutesConfig.RequestAppointment.path}
        element={<RequestAppointmentPage />}
      />
      <Route
        path={RoutesConfig.DeclineOffer.path}
        element={<DeclineOfferPage />}
      />
      <Route
        path={RoutesConfig.AppointmentConfirmation.path}
        element={<AppointmentConfirmationPage />}
      />
      <Route path={RoutesConfig.Home.path} element={<PrivateRoute />}>
        <Route path={RoutesConfig.Home.path} element={<ManagerRoute />}>
          <Route element={<ManagerLayout />}>
            <Route path={RoutesConfig.Home.path} element={<HomePage />} />
            <Route path={RoutesConfig.ToDo.path} element={<ToDoPage />} />
            <Route
              path={RoutesConfig.Quotations.path}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <QuotationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.NewQuotation.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <NewQuotationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.NewSalesOrder.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <NewSalesOrderPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.NewSalesOrderLine.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <NewSalesOrderLinePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SalesOrders.path}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <SalesOrdersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.PurchaseOrders.path}
              element={
                <ProtectedRoute permission={Roles.READ_PURCHASE}>
                  <PurchaseOrdersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Invoices.path}
              element={
                <ProtectedRoute permission={Roles.READ_INVOICE}>
                  <InvoicesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Planning.path}
              element={
                <ProtectedRoute permission={Roles.READ_PLANNING}>
                  <PlanningPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Customers.path}
              element={<CustomersPage />}
            />
            <Route
              path={RoutesConfig.NewCustomer.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <NewCustomerPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.NewAltContact.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <NewAltContactPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SingleCustomer.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <SingleCustomerPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Suppliers.path}
              element={<SuppliersPage />}
            />
            <Route
              path={RoutesConfig.NewSupplier.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_PURCHASE}>
                  <NewSupplierPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SingleSupplier.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_PURCHASE}>
                  <SingleSupplierPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={
                SingleSupplierRoutes.SingleSupplierNewPurchaseOrder.fullPath
              }
              element={
                <ProtectedRoute permission={Roles.READ_PURCHASE}>
                  <NewPurchaseOrderPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.StockItems.path}
              element={
                <ProtectedRoute permission={Roles.READ_STOCK}>
                  <StockItemsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SingleStockItem.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_STOCK}>
                  <SingleStockItemPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.NewStockItem.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_STOCK}>
                  <NewStockItemPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SingleQuotation.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <SingleQuotationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.NewQuotationLine.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <SingleQuotationNewLinePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SingleSalesOrder.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_SALES}>
                  <SingleSalesOrderPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SinglePurchaseOrder.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_PURCHASE}>
                  <SinglePurchaseOrderPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SingleSalesInvoice.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_INVOICE}>
                  <SingleSalesOrderInvoicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SingleSalesCreditInvoice.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_INVOICE}>
                  <SingleSalesCreditInvoicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SinglePurchaseInvoice.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_INVOICE}>
                  <SinglePurchaseInvoicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.SinglePurchaseCreditInvoice.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_INVOICE}>
                  <SinglePurchaseCreditInvoicePage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Settings.path}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <SettingsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Labels.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <LabelsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.ProductGroups.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <ProductGroupsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.PaymentConditions.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <PaymentConditionsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Watermark.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <WatermarkPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.CompanyConfiguration.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <CompanyConfigurationPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.ExternalIntegrations.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <ExternalIntegrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.UserRoles.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <UserRolesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.EmailTemplates.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <EmailTemplatesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Analytics.path}
              element={
                <ProtectedRoute permission={Roles.READ_COMPANY_ADMIN}>
                  <AnalyticsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.Notifications.path}
              element={<NotificationsPage />}
            />
            <Route path={RoutesConfig.Profile.path} element={<ProfilePage />} />
          </Route>
        </Route>

        <Route
          path={RoutesConfig.FwHome.path}
          element={
            <ProtectedRoute permission={Roles.READ_FIELD_WORKER}>
              <FieldWorkerRoute />
            </ProtectedRoute>
          }
        >
          <Route
            path={RoutesConfig.FwHome.fullPath}
            element={<Navigate to={RoutesConfig.FwAppointments.fullPath} />}
          />
          <Route element={<FieldWorkerLayout />}>
            <Route
              path={RoutesConfig.FwSingleAppointment.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_FIELD_WORKER}>
                  <FwSingleAppointmentPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.FwReportPage.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_FIELD_WORKER}>
                  <FwReportPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.FwAppointments.fullPath}
              element={
                <ProtectedRoute permission={Roles.READ_FIELD_WORKER}>
                  <FwAppointmentsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.FwNotifications.path}
              element={
                <ProtectedRoute permission={Roles.READ_FIELD_WORKER}>
                  <NotificationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path={RoutesConfig.FwProfile.path}
              element={
                <ProtectedRoute permission={Roles.READ_FIELD_WORKER}>
                  <ProfilePage />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
        <Route
          path={
            SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceGeneral
              .fullPath
          }
          element={<Navigate to={RoutesConfig.Invoices.fullPath} />}
        />
        <Route
          path={SingleSalesInvoiceRoutes.SingleSalesInvoiceGeneral.fullPath}
          element={<Navigate to={RoutesConfig.Invoices.fullPath} />}
        />
        <Route
          path={
            SalesCreditInvoiceRoutes.SingleSalesCreditInvoiceGeneral.fullPath
          }
          element={<Navigate to={RoutesConfig.Invoices.fullPath} />}
        />
        <Route
          path={RoutesConfig.PurchaseCreditInvoice.path}
          element={<Navigate to={RoutesConfig.Invoices.fullPath} />}
        />
      </Route>
      <Route path={RoutesConfig.Auth.path} element={<PublicRoute />}>
        <Route path={RoutesConfig.Login.path} element={<LoginPage />} />
        <Route
          path={RoutesConfig.RequestPasswordReset.path}
          element={<RequestPasswordReset />}
        />
        <Route
          path={RoutesConfig.ResetPassword.path}
          element={<ResetPassword />}
        />
      </Route>
      <Route element={<PublicRoute />}>
        <Route path={RoutesConfig.SignUp.fullPath} element={<SignUpPage />} />
        <Route
          path={RoutesConfig.SignUpActivation.fullPath}
          element={<SignUpActivationPage />}
        />
      </Route>
    </Routes>
  );
}
