import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledNav = styled.nav``;

export const List = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;

  ${respondTo.smallPhone`
    flex-wrap: wrap;
  `}
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
`;

export const Label = styled(Link)`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
  display: flex;
  align-items: center;
`;

export const BlueLabel = styled.span`
  cursor: default;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
`;
