import { H3, marginXs } from 'assets/styled';
import {
  Container,
  FormikContainer,
  Row,
  AlignRow,
  FirstRow,
  SubmitRow,
  Margin,
  StyledSelect,
  StyledCustomSelectProductGroup,
} from './ItemTab.styled';
import Button from 'components/Button/Button';
import { Field, Formik } from 'formik';
import { Input } from 'components/Input/InputFormik';
import { useTranslation } from 'react-i18next';
import { createStockItemDetailsTabSchema } from './validation';
import { Dispatch, SetStateAction } from 'react';
import {
  ICreateStockItemDetails,
  useGetUnitOfMeasureOptions,
} from './constants';
import CustomSelect from 'components/Select/FormikSelect';
import { useGetTypeOptions } from './hooks';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { useManageProductGroupsSelect } from 'pages/Manager/SingleSupplierPage/SingleSupplierProductsPage/AddEditProduct/hooks';
import { Option } from 'components/Select/type';

interface ISupplierProps {
  onBack: () => void;
  onForward: () => void;
  itemDetails: ICreateStockItemDetails;
  setItemDetails: Dispatch<SetStateAction<ICreateStockItemDetails>>;
  pwId?: string;
}

const ItemDetailsTab = ({
  onBack,
  onForward,
  itemDetails,
  setItemDetails,
  pwId,
}: ISupplierProps) => {
  const { t } = useTranslation();
  const typeOptions = useGetTypeOptions();
  const unitOfMeasureOptions = useGetUnitOfMeasureOptions();
  const currencySymbol = useGetCurrencySymbol();

  const { isPhone, isSmallPhone } = useBreakpointFlag();

  const {
    productGroups,
    isLoadingProductGroups,
    setSearchBy,
    hasNextPage,
    fetchNextPage,
  } = useManageProductGroupsSelect();

  return (
    <Container>
      <FirstRow>
        <H3 data-testid={`${pwId}-item-details`}>{t('Item details')}</H3>
      </FirstRow>
      <Formik
        enableReinitialize
        initialValues={itemDetails}
        validationSchema={createStockItemDetailsTabSchema(unitOfMeasureOptions)}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={onForward}
        validateOnMount={true}
      >
        {({
          handleBlur,
          setFieldValue,
          submitForm,
          isValid,
          values,
          errors,
          touched,
        }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId={`${pwId}-name-field`}
                  errorMessage={touched['name'] ? errors['name'] : ''}
                  height={'41rem'}
                  name="name"
                  placeholder={t('Name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('Name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                />
                <Input
                  pwId={`${pwId}-item-supplier-number-field`}
                  errorMessage={
                    touched['number_supplier'] ? errors['number_supplier'] : ''
                  }
                  height={'41rem'}
                  name="number_supplier"
                  placeholder={t('Item supplier number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('number_supplier', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                  }}
                />
              </Row>
              <Row>
                <Field
                  pwId={`${pwId}-select-group`}
                  errorMessage={
                    touched['product_group'] ? errors['product_group'] : ''
                  }
                  name="product_group"
                  options={productGroups}
                  component={StyledCustomSelectProductGroup}
                  placeholder={t('Group')}
                  isMulti={false}
                  isSearchable={true}
                  translate={false}
                  onSelect={(value: Option) => {
                    setFieldValue('product_group', value ? value : '');
                    console.log(values);
                  }}
                  onInputChange={(searchBy: string) => {
                    setSearchBy(searchBy);
                  }}
                  onMenuScrollToBottom={() => {
                    hasNextPage && fetchNextPage();
                  }}
                  isLoading={isLoadingProductGroups}
                  isClearable
                />
              </Row>
              <Row>
                <Input
                  pwId={`${pwId}-description-field`}
                  errorMessage={
                    touched['description'] ? errors['description'] : ''
                  }
                  height={'41rem'}
                  name="description"
                  placeholder={t('Description')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('description', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: '100%',
                  }}
                />
              </Row>
              <Row>
                <StyledSelect
                  pwId={`${pwId}-unit-of-measure-field`}
                  disabled={values.type === 'SERVICE'}
                  errorMessage={
                    touched['unit_of_measure'] ? errors['unit_of_measure'] : ''
                  }
                  name="unit_of_measure"
                  options={unitOfMeasureOptions}
                  component={CustomSelect}
                  placeholder={t('Unit of measure')}
                  isMulti={false}
                  onSelect={({ value }: any) => {
                    setFieldValue('unit_of_measure', value);
                  }}
                />
                <StyledSelect
                  pwId={`${pwId}-type-field`}
                  errorMessage={touched['type_id'] ? errors['type_id'] : ''}
                  name="type_id"
                  options={typeOptions}
                  component={CustomSelect}
                  placeholder={t('Type')}
                  isMulti={false}
                  onSelect={({ value }: any) => {
                    setFieldValue('type_id', value);
                    setFieldValue(
                      'type',
                      typeOptions
                        .find((option) => option.value === value)!
                        .key.toUpperCase()
                    );

                    if (
                      typeOptions.find((option) => option.value === value)!
                        .key === 'service'
                    )
                      setFieldValue(
                        'unit_of_measure',
                        unitOfMeasureOptions.find(
                          (unit: any) => unit.key === 'piece'
                        )?.value
                      );
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId={`${pwId}-sales-price-field`}
                  type="number"
                  errorMessage={
                    touched['sales_price'] ? errors['sales_price'] : ''
                  }
                  height={'41rem'}
                  name="sales_price"
                  placeholder={t('Sales price')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('sales_price', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                    marginTop: marginXs,
                  }}
                  symbol={currencySymbol}
                />
                <Input
                  pwId={`${pwId}-purchase-price-field`}
                  type="number"
                  errorMessage={
                    touched['purchase_price'] ? errors['purchase_price'] : ''
                  }
                  height={'41rem'}
                  name="purchase_price"
                  placeholder={t('Purchase Price exc. VAT')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('purchase_price', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isPhone ? '100%' : '50%',
                    marginTop: marginXs,
                  }}
                  symbol={currencySymbol}
                />
              </Row>
              {values?.type_id !=
                typeOptions.find((option) => option.key == 'service')!
                  .value && (
                <Row>
                  <Input
                    pwId={`${pwId}-in-stock-field`}
                    type="number"
                    errorMessage={touched['in_stock'] ? errors['in_stock'] : ''}
                    height={'41rem'}
                    name="in_stock"
                    placeholder={t('In stock')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('in_stock', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isPhone ? '100%' : '50%',
                      paddingRight: isPhone ? 0 : '15rem',
                    }}
                  />
                </Row>
              )}

              <AlignRow>
                <SubmitRow>
                  <Margin>
                    <Button
                      pwId={`${pwId}-back-button`}
                      width={isSmallPhone ? '150rem' : '200rem'}
                      onClick={() => {
                        onBack();
                        if (isValid) {
                          setItemDetails(values);
                        }
                      }}
                      label={t('Back')}
                      secondary
                    />
                  </Margin>
                  <Button
                    pwId={`${pwId}-next-button`}
                    width={isSmallPhone ? '150rem' : '200rem'}
                    onClick={() => {
                      submitForm();
                      if (isValid) {
                        setItemDetails(values);
                        onForward();
                      } else if (
                        // Ignore validation error for in_stock if it is the only error, when stock item is of type service
                        Object.keys(errors).length === 1 &&
                        Object.keys(errors)[0] === 'in_stock' &&
                        values?.type_id ===
                          typeOptions.find((option) => option.key == 'service')!
                            .value
                      ) {
                        setItemDetails(values);
                        onForward();
                      }
                    }}
                    label={t('Next')}
                    primary
                  />
                </SubmitRow>
              </AlignRow>
            </FormikContainer>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ItemDetailsTab;
