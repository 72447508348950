import {
  borderRadiusSm,
  COLORS,
  gapMd,
  marginMd,
  paddingMd,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import CustomSelect from 'components/Select/FormikSelect';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSelected: boolean;
  marginRight: string;
}

export const Container = styled.div`
  padding: ${paddingMd};
  max-width: 770rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  height: auto;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};

  ${respondTo.bigPhone`
    max-width: 100%;
    padding: 20rem;
  `}
`;

export const AddProductLabel = styled.label`
  margin-bottom: ${marginMd};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RowMarginBottomMd = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${marginMd};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 430rem;
  width: 100%;
  margin: 0 auto;

  ${respondTo.smallPhone`
    max-width: 100%;
    justify-content: space-evenly;
  `}
`;

export const StyledCustomSelect = styled(CustomSelect)`
  width: 100%;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;

export const FieldWrapper = styled.div`
  height: 71rem;
  width: 340rem;

  ${respondTo.bigPhone`
    width: 100%;
    max-width: 100%;
  `}
`;

export const Margin = styled.div`
  margin-left: ${marginMd};
`;

export const FilterTypeLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  ${(props: StyledProps) =>
    props.isSelected &&
    css`
      color: ${COLORS.PRIMARY};
      text-decoration: underline;
      text-decoration-thickness: 2rem;
      text-decoration-position: under;
    `}
  ${(props: StyledProps) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 142rem;

  ${respondTo.bigPhone`
    height: auto;
  `}
`;

export const Row = styled.div`
  display: flex;
  gap: ${gapMd};

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 0;
  `}
`;
