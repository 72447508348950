import { H3 } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 50rem;
  width: 100%;
`;
export const CloseContainer = styled.div`
  position: absolute;
  right: 15rem;
  top: 15rem;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(H3)`
  margin-bottom: 20rem;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20rem;
`;
