import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useRef } from 'react';
import {
  AdvancedFilterWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  SelectInputWrapper,
  Title,
  TitleContainer,
} from './FilterMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import { Option } from 'components/Select/type';
import Button from 'components/Button/Button';
import { Select } from 'components/Select/Select';
import CustomMenuList from 'components/Select/SelectComponents/CustomMenuList/CustomMenuList';
import { RoutesConfig } from 'navigation/routes';
import { useNavigate } from 'react-router-dom';
import { Pen } from '@phosphor-icons/react';

interface IFilterMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  filtersData: any;
  statusOptions: Option[];
  setIsAdvancedFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  advancedFilters: string;
  setSelectedProductGroupId: any;
  isLoadingProductGroups: boolean;
  productGroups: Option[];
  hasNextPage: any;
  fetchNextPage: any;
  setProductGroupSearchBy: any;
}

const PWID = 'stock-items-page';

const FilterMobileModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  filtersData,
  statusOptions,
  setIsAdvancedFilterModalOpen,
  advancedFilters,
  setSelectedProductGroupId,
  isLoadingProductGroups,
  productGroups,
  hasNextPage,
  fetchNextPage,
  setProductGroupSearchBy,
}: IFilterMobileModalProps) => {
  const { t } = useTranslation();
  const { selectedStatusOption, setSelectedStatusOption } = filtersData;
  const scrollPositionRef = useRef(0);
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Filter')}</Title>
        </TitleContainer>

        <SelectInputWrapper>
          <Select
            pwId={`${PWID}-status-field`}
            defaultValue={
              selectedStatusOption ? selectedStatusOption : statusOptions[0]
            }
            name="status"
            placeholder={t('Status')}
            isMulti={false}
            isDisabled={false}
            isSearchable={false}
            onChange={(e: Option) => setSelectedStatusOption(e)}
            options={statusOptions}
          />
        </SelectInputWrapper>
        <SelectInputWrapper>
          <Select
            pwId={`${PWID}-select-group`}
            name="product_group"
            placeholder={t('Group')}
            isMulti={false}
            isDisabled={false}
            isSearchable={true}
            onChange={(e: Option) => {
              setSelectedProductGroupId(e ? e.value.id : '');
            }}
            onInputChange={(e: any) => setProductGroupSearchBy(e)}
            options={productGroups}
            isLoading={isLoadingProductGroups}
            isClearable
            components={{
              MenuList: (props) => (
                <CustomMenuList
                  {...props}
                  scrollPositionRef={scrollPositionRef}
                  label={t('Manage groups')}
                  onClick={() => {
                    navigate(RoutesConfig.ProductGroups.fullPath);
                  }}
                  onMenuScrollToBottom={() => hasNextPage && fetchNextPage()}
                  icon={Pen}
                />
              ),
            }}
          />
        </SelectInputWrapper>
        <AdvancedFilterWrapper>
          <Button
            onClick={() => setIsAdvancedFilterModalOpen(true)}
            label={t('Advanced filter')}
            width="200rem"
            height="40rem"
            secondary={!advancedFilters}
            primary={!!advancedFilters}
          />
        </AdvancedFilterWrapper>
        <ButtonsWrapper>
          <Button
            onClick={() => setIsOpen(false)}
            label={t('Finish')}
            width="100%"
            height="40rem"
            primary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default FilterMobileModal;
