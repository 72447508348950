import { H4 } from 'assets/styled';
import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Field, Formik, getIn } from 'formik';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  ApplyWrapper,
  CheckboxWrapper,
  CloseContainer,
  ColumnContainer,
  Container,
  Heading,
  HorizontalLine,
  RowSpaceBetween,
  StyledCustomSelect,
  Title,
  TitleContainer,
} from './EditCustomerOrSupplierModal.styled';
import { Input as FormikInput, Input } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';
import { Option } from 'components/Select/type';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { EditingCustomerOrSupplierEnum } from './constants';
import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import {
  editCustomerValidationSchema,
  editSupplierValidationSchema,
} from './validation';
import { useGetSingleCustomer } from 'pages/Manager/SingleCustomerPage/hooks';
import { useEditCustomer, useEditSupplier } from './hooks';
import { useGetSingleSupplier } from 'pages/Manager/SingleSupplierPage/hooks';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { validateAlternativeEmailField } from 'utils/validation';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { getInitialCustomerData, getInitialSupplierData } from './helpers';
import { Info } from '@phosphor-icons/react';

const PWID = 'edit-customer-or-supplier-modal';

interface IEditCustomerOrSupplierModalProps {
  onCancel: () => void;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type: EditingCustomerOrSupplierEnum;
  pwId?: string;
}

export const EditCustomerOrSupplierModal = ({
  onCancel,
  isOpen,
  setIsOpen,
  type,
  pwId,
}: IEditCustomerOrSupplierModalProps) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);

  const { data: customerData } = useGetSingleCustomer(
    id || '',
    isOpen && type === EditingCustomerOrSupplierEnum.CUSTOMER
  );
  const [isShowAltInvoiceEmail, setIsShowAltInvoiceEmail] =
    useState<boolean>(false);
  useEffect(() => {
    if (
      customerData &&
      isOpen &&
      type === EditingCustomerOrSupplierEnum.CUSTOMER
    ) {
      setIsShowAltInvoiceEmail(
        !!customerData?.customer?.alternative_invoice_email
      );
    }
  }, [isOpen, customerData]);
  const { data: supplierData } = useGetSingleSupplier(
    id || '',
    isOpen && type === EditingCustomerOrSupplierEnum.SUPPLIER
  );
  const { mutate: editCustomerDetails } = useEditCustomer(id || '');
  const { mutate: editSupplierDetails } = useEditSupplier(id || '');
  const countryOptions: Option[] = useGetCountryOptions();
  const titleOptions: Option[] = useGetTitleOptions();

  const { isSmallTablet, isSmallPhone } = useBreakpointFlag();

  const customerInitialData = getInitialCustomerData(customerData);
  const supplierInitialData = getInitialSupplierData(supplierData);

  const handleEditCustomerDetails = (values: any) => {
    const editCustomerData = {
      name: values.name,
      last_name: values.last_name,
      company_name: values.company_name || null,
      vat_number: values.vat_number || null,
      address: values.address,
      email: values.email,
      phone: values.phone,
      coc_number: values.coc_number || null,
      title: values.title,
      alternative_invoice_email: values.alternative_invoice_email || null,
    };

    editCustomerDetails(editCustomerData);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
        overflow: 'auto',
      }}
    >
      <CloseContainer>
        <Close pwId={`${pwId}-${PWID}-close-button`} onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title data-testid={`${pwId}-${PWID}-title`}>
          {type === EditingCustomerOrSupplierEnum.CUSTOMER
            ? `${customerData?.customer.name} ${customerData?.customer.last_name}`
            : `${supplierData?.supplier.company_name}`}
        </Title>
      </TitleContainer>
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          initialValues={
            type === EditingCustomerOrSupplierEnum.CUSTOMER
              ? customerInitialData
              : supplierInitialData
          }
          validationSchema={
            type === EditingCustomerOrSupplierEnum.CUSTOMER
              ? editCustomerValidationSchema
              : editSupplierValidationSchema
          }
          validateOnChange={true}
          validateOnMount={true}
          validateOnBlur={true}
        >
          {({
            values,
            errors,
            setFieldValue,
            handleBlur,
            isValid,
            setFieldError,
            touched,
          }) => {
            return (
              <Container>
                {type === EditingCustomerOrSupplierEnum.CUSTOMER && (
                  <>
                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4 data-testid={`${pwId}-${PWID}-title-label`}>
                            {t('Title').toUpperCase()}
                          </H4>
                        </Heading>
                        <Field
                          pwId={`${pwId}-${PWID}-title-field`}
                          disabled={false}
                          height={'41rem'}
                          name="title"
                          width={isSmallTablet ? '100%' : '300rem'}
                          options={titleOptions}
                          component={StyledCustomSelect}
                          isMulti={false}
                          onSelect={(value: string) =>
                            setFieldValue('title', value)
                          }
                          errorMessage={areAllFieldsTouched && errors['title']}
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>

                    <HorizontalLine />

                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4 data-testid={`${pwId}-${PWID}-first-name-title`}>
                            {t('First name').toUpperCase()}
                          </H4>
                        </Heading>
                        <FormikInput
                          pwId={`${pwId}-${PWID}-first-name-field`}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('name', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="name"
                          wrapperStyles={{
                            width: isSmallTablet ? '100%' : '300rem',
                            marginTop: isSmallTablet ? '10rem' : '20rem',
                          }}
                          errorMessage={areAllFieldsTouched && errors['name']}
                        />
                      </ColumnContainer>
                      <ColumnContainer>
                        <Heading>
                          <H4 data-testid={`${pwId}-${PWID}-last-name-title`}>
                            {t('Last name').toUpperCase()}
                          </H4>
                        </Heading>
                        <FormikInput
                          pwId={`${pwId}-${PWID}-last-name-field`}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('last_name', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="last_name"
                          wrapperStyles={{
                            width: isSmallTablet ? '100%' : '300rem',
                            marginTop: isSmallTablet ? '10rem' : '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['last_name']
                          }
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>

                    <HorizontalLine />

                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4
                            data-testid={`${pwId}-${PWID}-company-name-title`}
                          >
                            {t('Company name').toUpperCase()}
                          </H4>
                        </Heading>
                        <FormikInput
                          pwId={`${pwId}-${PWID}-company-name-field`}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('company_name', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="company_name"
                          wrapperStyles={{
                            width: isSmallTablet ? '100%' : '300rem',
                            marginTop: isSmallTablet ? '10rem' : '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['company_name']
                              ? (errors['company_name'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                      <ColumnContainer>
                        <Heading>
                          <H4 data-testid={`${pwId}-${PWID}-vat-number-title`}>
                            {t('VAT number').toUpperCase()}
                          </H4>
                        </Heading>
                        <FormikInput
                          pwId={`${pwId}-${PWID}-vat-number-field`}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('vat_number', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="vat_number"
                          wrapperStyles={{
                            width: isSmallTablet ? '100%' : '300rem',
                            marginTop: isSmallTablet ? '10rem' : '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['vat_number']
                              ? (errors['vat_number'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>
                    <RowSpaceBetween marginTop="30rem">
                      <CheckboxWrapper>
                        <Checkbox
                          pwId={`${pwId}-${PWID}-alternative-invoice-email-checkbox`}
                          isChecked={isShowAltInvoiceEmail}
                          label={t('Alternative invoice E-mail')}
                          style={{ fontSize: '16rem' }}
                          onChange={() => {
                            const newIsUsingAltInvoiceValue =
                              !isShowAltInvoiceEmail;
                            if (!newIsUsingAltInvoiceValue) {
                              setFieldError(
                                'alternative_invoice_email',
                                undefined
                              );
                            } else {
                              // Manually trigger validation
                              const emailError: string | undefined =
                                validateAlternativeEmailField(
                                  (values as any).alternative_invoice_email
                                );
                              if (emailError) {
                                setFieldError(
                                  'alternative_invoice_email',
                                  emailError
                                );
                              }
                            }
                            setIsShowAltInvoiceEmail(!isShowAltInvoiceEmail);
                          }}
                        />
                      </CheckboxWrapper>
                      {isShowAltInvoiceEmail && (
                        <Input
                          validate={(value: string) =>
                            validateAlternativeEmailField(value)
                          }
                          pwId={`${pwId}-${PWID}-alternative_invoice_email-field`}
                          errorMessage={
                            areAllFieldsTouched ||
                            touched['alternative_invoice_email']
                              ? errors['alternative_invoice_email']
                              : ''
                          }
                          height={'41rem'}
                          name="alternative_invoice_email"
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('alternative_invoice_email', value);
                            handleBlur(e);
                          }}
                          wrapperStyles={{
                            width: isSmallTablet ? '100%' : '50%',
                          }}
                          icon={Info}
                          iconSize={20}
                          iconTooltip={t('Valid format: example@mail.com')}
                        />
                      )}
                    </RowSpaceBetween>
                  </>
                )}
                {type === EditingCustomerOrSupplierEnum.SUPPLIER && (
                  <>
                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4
                            data-testid={`${pwId}-${PWID}-company-name-title`}
                          >
                            {t('Company name').toUpperCase()}
                          </H4>
                        </Heading>
                        <FormikInput
                          pwId={`${pwId}-${PWID}-company-name-field`}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('company_name', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="company_name"
                          wrapperStyles={{
                            width: isSmallTablet ? '100%' : '300rem',
                            marginTop: isSmallTablet ? '10rem' : '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['company_name']
                              ? (errors['company_name'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                      <ColumnContainer>
                        <Heading>
                          <H4
                            data-testid={`${pwId}-${PWID}-contact-person-title`}
                          >
                            {t('Contact person').toUpperCase()}
                          </H4>
                        </Heading>
                        <FormikInput
                          pwId={`${pwId}-${PWID}-contact-person-field`}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('contact_person', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="contact_person"
                          wrapperStyles={{
                            width: isSmallTablet ? '100%' : '300rem',
                            marginTop: isSmallTablet ? '10rem' : '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['contact_person']
                              ? (errors['contact_person'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>

                    <HorizontalLine />

                    <RowSpaceBetween>
                      <ColumnContainer>
                        <Heading>
                          <H4 data-testid={`${pwId}-${PWID}-vat-number`}>
                            {t('VAT number').toUpperCase()}
                          </H4>
                        </Heading>
                        <FormikInput
                          pwId={`${pwId}-${PWID}-vat-number-field`}
                          onBlur={(e) => {
                            const value = e.target.value.trim();
                            setFieldValue('vat_number', value);
                            handleBlur(e);
                          }}
                          height={'41rem'}
                          name="vat_number"
                          wrapperStyles={{
                            width: isSmallTablet ? '100%' : '630rem',
                            marginTop: isSmallTablet ? '10rem' : '20rem',
                          }}
                          errorMessage={
                            areAllFieldsTouched && errors['vat_number']
                              ? (errors['vat_number'] as string)
                              : ''
                          }
                        />
                      </ColumnContainer>
                    </RowSpaceBetween>
                  </>
                )}
                <HorizontalLine />
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-email-title`}>
                        {t('E-MAIL')}
                      </H4>
                    </Heading>
                    <FormikInput
                      pwId={`${pwId}-${PWID}-email-field`}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('email', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="email"
                      wrapperStyles={{
                        width: isSmallTablet ? '100%' : '300rem',
                        marginTop: isSmallTablet ? '10rem' : '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched && errors['email']
                          ? (errors['email'] as string)
                          : ''
                      }
                      icon={Info}
                      iconSize={20}
                      iconTooltip={t('Valid format: example@mail.com')}
                    />
                  </ColumnContainer>

                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-phone-number-title`}>
                        {t('Phone number').toUpperCase()}
                      </H4>
                    </Heading>
                    <FormikInput
                      pwId={`${pwId}-${PWID}-phone-number-field`}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('phone', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="phone"
                      wrapperStyles={{
                        width: isSmallTablet ? '100%' : '300rem',
                        marginTop: isSmallTablet ? '10rem' : '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched && errors['phone']
                          ? (errors['phone'] as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>

                <HorizontalLine />

                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-street-title`}>
                        {t('Street').toUpperCase()}
                      </H4>
                    </Heading>
                    <FormikInput
                      pwId={`${pwId}-${PWID}-street-field`}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('address.street', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="address.street"
                      wrapperStyles={{
                        width: isSmallTablet ? '100%' : '300rem',
                        marginTop: isSmallTablet ? '10rem' : '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.street') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-house-number-title`}>
                        {t('House number').toUpperCase()}
                      </H4>
                    </Heading>
                    <FormikInput
                      pwId={`${pwId}-${PWID}-house-number-field`}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('address.house_number', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="address.house_number"
                      wrapperStyles={{
                        width: isSmallTablet ? '100%' : '300rem',
                        marginTop: isSmallTablet ? '10rem' : '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.house_number') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>

                <HorizontalLine />

                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-city-title`}>
                        {t('City').toUpperCase()}
                      </H4>
                    </Heading>
                    <FormikInput
                      pwId={`${pwId}-${PWID}-city-field`}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('address.city', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="address.city"
                      wrapperStyles={{
                        width: isSmallTablet ? '100%' : '300rem',
                        marginTop: isSmallTablet ? '10rem' : '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.city') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-zip-code-title`}>
                        {t('Zip code').toUpperCase()}
                      </H4>
                    </Heading>
                    <FormikInput
                      pwId={`${pwId}-${PWID}-zip-code-field`}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('address.zip_code', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="address.zip_code"
                      wrapperStyles={{
                        width: isSmallTablet ? '100%' : '300rem',
                        marginTop: isSmallTablet ? '10rem' : '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.zip_code') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <HorizontalLine />
                <RowSpaceBetween>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-country-code-title`}>
                        {t('Country code').toUpperCase()}
                      </H4>
                    </Heading>
                    <Field
                      pwId={`${pwId}-${PWID}-country-code-field`}
                      disabled={false}
                      height={'41rem'}
                      name="address.country"
                      width={isSmallTablet ? '100%' : '300rem'}
                      options={countryOptions}
                      component={StyledCustomSelect}
                      isMulti={false}
                      onSelect={(value: string) =>
                        setFieldValue('address.country', value)
                      }
                      errorMessage={
                        areAllFieldsTouched
                          ? (getIn(errors, 'address.country') as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                  <ColumnContainer>
                    <Heading>
                      <H4 data-testid={`${pwId}-${PWID}-coc-number-title`}>
                        {t('COC number').toUpperCase()}
                      </H4>
                    </Heading>
                    <FormikInput
                      pwId={`${pwId}-${PWID}-coc-number-field`}
                      onBlur={(e) => {
                        const value = e.target.value.trim();
                        setFieldValue('coc_number', value);
                        handleBlur(e);
                      }}
                      height={'41rem'}
                      name="coc_number"
                      wrapperStyles={{
                        width: isSmallTablet ? '100%' : '300rem',
                        marginTop: isSmallTablet ? '10rem' : '20rem',
                      }}
                      errorMessage={
                        areAllFieldsTouched && errors['coc_number']
                          ? (errors['coc_number'] as string)
                          : ''
                      }
                    />
                  </ColumnContainer>
                </RowSpaceBetween>
                <ApplyWrapper>
                  <Button
                    pwId={`${pwId}-${PWID}-cancel-button`}
                    onClick={onCancel}
                    secondary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Cancel')}
                  />
                  <Button
                    pwId={`${pwId}-${PWID}-apply-button`}
                    disabled={areAllFieldsTouched && !isValid}
                    onClick={() => {
                      if (!areAllFieldsTouched && !isValid) {
                        setAreAllFieldsTouched(true);
                        return;
                      }
                      if (!isValid) {
                        return;
                      }
                      if (type === EditingCustomerOrSupplierEnum.CUSTOMER) {
                        if (!isShowAltInvoiceEmail) {
                          delete (values as any).alternative_invoice_email;
                        }
                        handleEditCustomerDetails(values);
                      } else {
                        editSupplierDetails(values);
                      }

                      setIsOpen(false);
                    }}
                    primary
                    width={isSmallPhone ? '150rem' : '200rem'}
                    label={t('Apply')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
