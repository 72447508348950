import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { Option } from 'components/Select/type';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { marginXs } from 'assets/styled';

interface ICheckableSelectProps {
  placeholder?: string;
  width?: string;
  height?: string;
  isMulti?: boolean;
  options: Option[];
  translate?: boolean;
  labelTransform?: (str: string) => string;
  onChange: (selectedOptions: Option[]) => void;
  defaultValues?: Option[];
  disabled?: boolean;
  value?: Option;
  pwId?: string;
}
const MenuProps = {
  PaperProps: {
    style: {
      marginTop: `${marginXs}`,
      maxHeight: '50vh',
    },
  },
};

export const CheckableSelect = ({
  placeholder,
  width,
  height,
  isMulti,
  options,
  translate = true,
  labelTransform = (str: string) => str,
  onChange,
  defaultValues,
  disabled,
  value,
  pwId,
}: ICheckableSelectProps) => {
  const { t } = useTranslation();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;

    setSelectedValues(typeof value === 'string' ? [value] : value);
    typeof value === 'string'
      ? onChange(options.filter((option) => value === option.value))
      : onChange(options.filter((option) => value.includes(option.value)));
  };

  useEffect(() => {
    if (disabled) setSelectedValues([]);
  }, [disabled]);

  useEffect(() => {
    if (defaultValues && !selectedValues.length) {
      setSelectedValues(defaultValues?.map((option: Option) => option.value));
    }
  }, [defaultValues]);

  return (
    <div>
      <FormControl
        sx={{
          height: height || '41rem',
          width: width || '200rem',
        }}
      >
        <InputLabel id="multiple-checkbox-label">
          {placeholder || ''}
        </InputLabel>
        <Select
          data-testid={pwId}
          disabled={disabled}
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple={isMulti}
          value={value ? [value.value] : selectedValues}
          onChange={handleChange}
          input={<OutlinedInput label={placeholder} />}
          renderValue={(selected) => {
            return selected
              .map((value) =>
                translate
                  ? labelTransform(
                      t(options.find((option) => option.value === value)?.label)
                    ) || ''
                  : labelTransform(
                      options.find((option) => option.value === value)?.label ||
                        ''
                    )
              )
              .join(', ');
          }}
          MenuProps={MenuProps}
          style={{
            height: height || '41rem',
            width: width || '200rem',
            fontSize: '16rem',
          }}
        >
          {options?.map((option: Option) => (
            <MenuItem key={option.key} value={option.value}>
              <ListItemText
                primary={
                  translate
                    ? labelTransform(t(option.label))
                    : labelTransform(option.label)
                }
              />
              {isMulti && (
                <Checkbox checked={selectedValues.indexOf(option.value) > -1} />
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CheckableSelect;
