import { marginMd, marginXs, paddingLg, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  padding: ${paddingLg};

  ${respondTo.horizontalTablet`
    padding: ${paddingMd};
  `}

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  ${respondTo.smallTablet`
    display: none;
  `}
`;

export const CreateToDoButton = styled.div`
  width: 100%;
  margin: 20rem 0;
`;

export const Row = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: ${marginMd};

  ${respondTo.smallTablet`
    margin-top: 15rem;
  `}
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
    gap: 20rem;
  `}
`;

export const AdvancedFilterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${marginXs};

  ${respondTo.smallTablet`
    display: none;
  `}
`;
