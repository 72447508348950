import { isValidIBAN } from 'utils/iBanValidator';
import { PHONE_NUMBER_REGEX } from 'utils/validation';
import * as Yup from 'yup';

export const editDetailsTabSchema = () => {
  return Yup.object().shape({
    name: Yup.string().required('Name is required'),
    vat_number: Yup.string()
      .min(3, 'VAT number should be between 3 and 50 characters')
      .max(50, 'VAT number should be between 3 and 50 characters')
      .optional(),
    coc_number: Yup.string()
      .max(20, 'COC number should be less than 20 characters')
      .optional(),
    phone_number: Yup.string().matches(
      PHONE_NUMBER_REGEX,
      'Invalid phone number'
    ),
    website: Yup.string(),
    email: Yup.string().email('Invalid E-mail').required('E-mail is required'),
  });
};

export const editAddressTabSchema = () => {
  return Yup.object().shape({
    street: Yup.string()
      .min(1, 'Street should be between 1 and 80 characters')
      .max(80, 'Street should be between 1 and 80 characters')
      .required('Street is required'),
    house_number: Yup.string().required('House number is required'),
    zip_code: Yup.string()
      .min(3, 'Zip code should be between 3 and 10 characters')
      .max(10, 'Zip code should be between 3 and 10 characters')
      .required('Zip code is required'),
    city: Yup.string()
      .min(1, 'City should be between 1 and 80 characters')
      .max(80, 'City should be between 1 and 80 characters')
      .required('City is required'),
    state: Yup.string()
      .min(1, 'State should be between 1 and 80 characters')
      .max(80, 'State should be between 1 and 80 characters'),
    cca2: Yup.string().required('Country is required'),
  });
};

export const editBankTabSchema = () => {
  return Yup.object().shape({
    bank_name: Yup.string(),
    bank_iban: Yup.string().test('is-iban', 'IBAN is not valid', (value) => {
      if (!value) {
        return false;
      }

      return isValidIBAN(value) === 1;
    }),
    bank_code_number: Yup.string(),
    bank_account_holder: Yup.string(),
  });
};
