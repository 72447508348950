import Button from 'components/Button/Button';
import { Input } from 'components/Input/InputFormik';
import CustomSelect from 'components/Select/FormikSelect';
import { Option } from 'components/Select/type';
import { Field, Formik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ICountry } from 'store/Common/types';
import { useGetCountryOptions } from 'utils/hooks/useGetCountryOptions';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import { useTranslation } from 'react-i18next';
import {
  ButtonCreate,
  ButtonsWrapper,
  CheckboxWrapper,
  FormikContainer,
  Row,
  SelectWrapper,
  StyledCustomSelect,
  StyledSelect,
} from './AddEditAltContactForm.styled';
import { IAddEditAltContactDTO } from './types';
import { createAltContactSchema } from './validation';

import { useGetTitleOptions } from 'utils/hooks/useGetTitleOptions';
import { Checkbox } from 'components/Checkbox/Checkbox';
import {
  useCreateCustomerAlternativeContact,
  useEditCustomerAlternativeContact,
} from './hooks';
import { SingleCustomerRoutes } from 'navigation/SingleCustomer/SingleCustomer.routes';
import { validateAlternativeEmailField } from 'utils/validation';
import {
  IGetCityAndAddressDTO,
  useGetCityAndAddress,
} from 'utils/hooks/useGetCityAndAddress';
import debounce from 'lodash.debounce';
import { ICustomerAlternativeContact } from 'types/Customer.types';
import { isValidGetCityAndAddressData } from 'pages/Manager/NewQuotationPage/CustomerTab/helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import { Info } from '@phosphor-icons/react';

interface IAddEditAltContactFormProps {
  customerId: number;
  contact?: ICustomerAlternativeContact;
  onSuccess?: () => void;
}

const AddEditAltContactForm = ({
  customerId,
  contact,
  onSuccess,
}: IAddEditAltContactFormProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [areAllFieldsTouched, setAreAllFieldsTouched] =
    useState<boolean>(false);
  const [isShowAltInvoiceEmail, setIsShowAltInvoiceEmail] = useState<boolean>(
    contact ? !!contact.alternative_invoice_email : false
  );
  const handleCancel = () => {
    navigate(-1);
  };

  const { isSmallTablet, isPhone } = useBreakpointFlag();

  const {
    mutate: createCustomerAlternativeContact,
    isLoading: isLoadingCreate,
  } = useCreateCustomerAlternativeContact(customerId);

  const { mutate: editCustomerAlternativeContact, isLoading: isLoadingEdit } =
    useEditCustomerAlternativeContact(customerId, contact?.id || -1);

  editCustomerAlternativeContact;
  const titleOptions = useGetTitleOptions();

  const handleSubmit = async () => {
    const { values, isValid } = formikRef.current;

    if (!isValid) {
      return;
    }
    const dto: IAddEditAltContactDTO = {
      first_name: values.name,
      last_name: values.last_name,
      title: values.title,
      email: values.email,
      phone: values.phone,
      address: {
        country: values.country,
        street: values.street,
        zip_code: values.code,
        city: values.city,
        house_number: values.house_number,
      },
      ...(isShowAltInvoiceEmail && {
        alternative_invoice_email: values.alternative_invoice_email,
      }),
    };
    if (contact) {
      editCustomerAlternativeContact(dto, {
        onSuccess: () => {
          onSuccess && onSuccess();
          navigate(
            SingleCustomerRoutes.SingleCustomerAltContacts.fullPath.replace(
              ':id',
              customerId.toString()
            )
          );
        },
      });
    } else {
      createCustomerAlternativeContact(dto, {
        onSuccess: () => {
          onSuccess && onSuccess();
          navigate(
            SingleCustomerRoutes.SingleCustomerAltContacts.fullPath.replace(
              ':id',
              customerId.toString()
            )
          );
        },
      });
    }
  };

  const initialCountry: ICountry = useGetInitialCountry();
  const countryOptions: Option[] = useGetCountryOptions();

  // Get city and address handling
  const formikRef = useRef<any>();
  const {
    mutate: getCityAndAddress,
    data: cityAndAddressData,
    isSuccess: isSuccessGetCityAndAddress,
    isLoading: isLoadingGetCityAndAddress,
  } = useGetCityAndAddress();

  useEffect(() => {
    if (
      isSuccessGetCityAndAddress &&
      cityAndAddressData &&
      !formikRef?.current.values.city
    ) {
      formikRef?.current.setFieldValue('city', cityAndAddressData.address.city);
      if (
        formikRef?.current.values.house_number &&
        formikRef?.current.values.street
      )
        getCityAndAddress({
          countryCode: formikRef?.current.values.country,
          zipCode: formikRef?.current.values.code,
          houseNumber: formikRef?.current.values.house_number,
          city: cityAndAddressData.address.city,
          street: formikRef?.current.values.street,
        });
    }
  }, [isSuccessGetCityAndAddress]);

  const debouncedGetCityAndAddress = debounce(
    (getCityAndAddressData: IGetCityAndAddressDTO) => {
      if (isValidGetCityAndAddressData(getCityAndAddressData))
        getCityAndAddress({
          countryCode: getCityAndAddressData.countryCode,
          zipCode: getCityAndAddressData.zipCode,
          houseNumber: getCityAndAddressData.houseNumber,
          city: getCityAndAddressData.city,
          street: getCityAndAddressData.street,
        });
    },
    1000
  );

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          name: contact ? contact.first_name : '',
          last_name: contact ? contact.last_name : '',
          title: contact ? contact.title : '',
          email: contact ? contact.email : '',
          country: contact ? contact.address.country.cca2 : initialCountry.cca2,
          phone: contact ? contact.phone : '',
          street: contact ? contact.address.street : '',
          code: contact ? contact.address.zip_code : '',
          city: contact ? contact.address.city : '',
          house_number: contact ? contact.address.house_number : '',
          alternative_invoice_email: contact
            ? contact.alternative_invoice_email
            : '',
        }}
        validationSchema={createAltContactSchema}
        validateOnChange={true}
        validateOnBlur={true}
        validateOnMount={true}
        onSubmit={() => {
          // onSubmit
        }}
      >
        {({
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched,
          isValid,
          setFieldError,
        }) => {
          return (
            <FormikContainer>
              <Row>
                <Input
                  pwId={'name-field'}
                  errorMessage={
                    areAllFieldsTouched || touched['name'] ? errors['name'] : ''
                  }
                  height={'41rem'}
                  name="name"
                  placeholder={t('First name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '50%',
                  }}
                />
                <Input
                  pwId={'last-name-field'}
                  errorMessage={
                    areAllFieldsTouched || touched['last_name']
                      ? errors['last_name']
                      : ''
                  }
                  height={'41rem'}
                  name="last_name"
                  placeholder={t('Last name')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('last_name', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '50%',
                  }}
                />
              </Row>
              <Row>
                <Input
                  pwId={'email-field'}
                  errorMessage={
                    areAllFieldsTouched || touched['email']
                      ? errors['email']
                      : ''
                  }
                  height={'41rem'}
                  name="email"
                  placeholder={t('E-mail')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('email', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '50%',
                  }}
                  icon={Info}
                  iconSize={20}
                  iconTooltip={t('Valid format: example@mail.com')}
                />
                <SelectWrapper>
                  <Field
                    pwId={'title-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['title']
                        ? errors['title']
                        : ''
                    }
                    height={'41rem'}
                    name="title"
                    options={titleOptions}
                    component={StyledCustomSelect}
                    placeholder={t('Title')}
                    isMulti={false}
                    onSelect={(value: string) => {
                      setFieldValue('title', value);
                    }}
                  />
                </SelectWrapper>
              </Row>
              <Row>
                <Input
                  pwId={'phone-number-field'}
                  errorMessage={
                    areAllFieldsTouched || touched['phone']
                      ? errors['phone']
                      : ''
                  }
                  height={'41rem'}
                  name="phone"
                  placeholder={t('Phone number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('phone', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '50%',
                    paddingRight: '15rem',
                  }}
                />
              </Row>
              <Row>
                <Input
                  isLoading={isLoadingGetCityAndAddress}
                  pwId={'zip-code-field'}
                  errorMessage={
                    areAllFieldsTouched || touched['code'] ? errors['code'] : ''
                  }
                  height={'41rem'}
                  name="code"
                  placeholder={t('Zip code')}
                  onBlur={(e: any) => {
                    const value = e.target.value.trim();
                    setFieldValue('code', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: value,
                        houseNumber: values.city
                          ? values.house_number || ''
                          : '',
                        city: values.city || '',
                        street: values.city ? values.street || '' : '',
                      });
                    }
                  }}
                />
                <Input
                  isLoading={isLoadingGetCityAndAddress}
                  pwId={'house-number-field'}
                  errorMessage={
                    areAllFieldsTouched || touched['house_number']
                      ? errors['house_number']
                      : ''
                  }
                  height={'41rem'}
                  name="house_number"
                  placeholder={t('House number')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('house_number', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.street && values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: value,
                        city: values.city || '',
                        street: values.street,
                      });
                    }
                  }}
                />
              </Row>
              <Row>
                <Input
                  isLoading={isLoadingGetCityAndAddress}
                  pwId={'city-field'}
                  errorMessage={
                    areAllFieldsTouched || touched['city'] ? errors['city'] : ''
                  }
                  height={'41rem'}
                  name="city"
                  placeholder={t('City')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('city', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: values.house_number || '',
                        city: value,
                        street: values.street,
                      });
                    }
                  }}
                />
                <Input
                  isLoading={isLoadingGetCityAndAddress}
                  pwId={'street-field'}
                  errorMessage={
                    areAllFieldsTouched || touched['street']
                      ? errors['street']
                      : ''
                  }
                  height={'41rem'}
                  name="street"
                  placeholder={t('Street')}
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('street', value);
                    handleBlur(e);
                  }}
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '50%',
                  }}
                  onChange={(value: any) => {
                    if (values.city && values.country) {
                      debouncedGetCityAndAddress({
                        countryCode: values.country,
                        zipCode: values.code,
                        houseNumber: values.house_number || '',
                        city: values.city,
                        street: value,
                      });
                    }
                  }}
                />
              </Row>
              <Row>
                <SelectWrapper>
                  <StyledSelect
                    isLoading={isLoadingGetCityAndAddress}
                    pwId={'country-code-field'}
                    errorMessage={
                      areAllFieldsTouched || touched['country']
                        ? errors['country']
                        : ''
                    }
                    height={'41rem'}
                    name="country"
                    options={countryOptions}
                    component={CustomSelect}
                    isMulti={false}
                    onSelect={(value: any) => {
                      setFieldValue('country', value);
                      debouncedGetCityAndAddress({
                        countryCode: value.value,
                        zipCode: values.code,
                        houseNumber: values.house_number || '',
                        city: values.city || '',
                        street: values.street || '',
                      });
                    }}
                  />
                </SelectWrapper>
              </Row>
              <Row>
                <CheckboxWrapper>
                  <Checkbox
                    isChecked={isShowAltInvoiceEmail}
                    label={t('Alternative invoice E-mail')}
                    style={{ fontSize: '16rem' }}
                    onChange={() => {
                      const newIsUsingAltInvoiceValue = !isShowAltInvoiceEmail;
                      if (!newIsUsingAltInvoiceValue) {
                        setFieldError('alternative_invoice_email', undefined);
                      } else {
                        // Manually trigger validation
                        const emailError: string | undefined =
                          validateAlternativeEmailField(
                            values.alternative_invoice_email
                          );
                        if (emailError) {
                          setFieldError(
                            'alternative_invoice_email',
                            emailError
                          );
                        }
                      }
                      setIsShowAltInvoiceEmail(!isShowAltInvoiceEmail);
                    }}
                  />
                </CheckboxWrapper>
                {isShowAltInvoiceEmail && (
                  <Input
                    validate={(value: string) =>
                      validateAlternativeEmailField(value)
                    }
                    pwId={'alternative_invoice_email-field'}
                    errorMessage={
                      areAllFieldsTouched ||
                      touched['alternative_invoice_email']
                        ? errors['alternative_invoice_email']
                        : ''
                    }
                    height={'41rem'}
                    name="alternative_invoice_email"
                    placeholder={t('E-mail')}
                    onBlur={(e) => {
                      const value = e.target.value.trim();
                      setFieldValue('alternative_invoice_email', value);
                      handleBlur(e);
                    }}
                    wrapperStyles={{
                      width: isSmallTablet ? '100%' : '50%',
                    }}
                    icon={Info}
                    iconSize={20}
                    iconTooltip={t('Valid format: example@mail.com')}
                  />
                )}
              </Row>
              <ButtonsWrapper>
                <Button
                  width={isPhone ? '150rem' : '200rem'}
                  onClick={() => handleCancel()}
                  label={t('Back')}
                  secondary
                  disabled={isLoadingCreate || isLoadingEdit}
                />
                <ButtonCreate
                  disabled={isLoadingCreate || isLoadingEdit}
                  data-testid="create-button"
                  width={isPhone ? '150rem' : '200rem'}
                  onClick={() => {
                    if (isValid) {
                      handleSubmit();
                    } else if (!areAllFieldsTouched) {
                      setAreAllFieldsTouched(true);
                    }
                  }}
                  label={contact ? t('Confirm') : t('Create')}
                  primary
                />
              </ButtonsWrapper>
            </FormikContainer>
          );
        }}
      </Formik>
    </>
  );
};

export default AddEditAltContactForm;
