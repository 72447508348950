import { H3 } from 'assets/styled';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 50rem;
  width: 100%;
`;
export const CloseContainer = styled.div`
  position: absolute;
  right: 15rem;
  top: 15rem;
`;
export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled(H3)`
  margin-bottom: 20rem;
`;

export const ButtonsWrapper = styled.div`
  display: block;
  width: 50%;
  margin: 0 auto;
  margin-top: 20rem;
`;

export const Filters = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20rem;
`;

export const SelectInputWrapper = styled.div`
  width: 100%;
  height: 41rem;
  z-index: 4;

  .MuiInputBase-root-hxnqRp {
    border-radius: 8rem;
  }
`;

export const ClearWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20rem;
`;
