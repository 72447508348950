import { useTranslation } from 'react-i18next';
import { SectionTitle } from '../ReportForm.styled';
import { COLORS, gapLg, gapXs, marginSm } from 'assets/styled';
import Input from 'components/Input/Input';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import {
  IReportPageErrors,
  validateFollowUpActionDescription,
  validateSituationDescription,
} from '../validation';
import { Checkbox } from 'components/Checkbox/Checkbox';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import {
  ColumnOuter,
  ColumnGap,
  ResourceWorkingHourRow,
  RowGap,
  ResourceName,
  RequiredResourcesLabel,
  Container,
  InputWrapper,
} from './SituationDescriptionResourceSection.styled';
import { IWorkerAndResource } from 'pages/Manager/PlanningPage/WorkersModal/type';
import { useGetResourcesInfinite } from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { RESOURCES_PER_PAGE } from './constants';
import { removeAlreadySelectedResourcesFromFetchedResources } from './helpers';
import { useDebounce } from 'utils/hooks/useDebounce';
import Icon from 'components/Icon/Icon';
import { Trash } from '@phosphor-icons/react';

export interface IResourceWorkingHours {
  resource: IWorkerAndResource;
  workingHours: number;
}

interface ISituationDescriptionResourceSectionProps {
  isReadOnly: boolean;
  situationDescription: string;
  setSituationDescription: Dispatch<SetStateAction<string>>;
  isFollowUpActionRequired: boolean;
  setIsFollowUpActionRequired: Dispatch<SetStateAction<boolean>>;
  followUpActionDescription: string;
  setFollowUpActionDescription: Dispatch<SetStateAction<string>>;
  errors: IReportPageErrors;
  setErrors: Dispatch<SetStateAction<IReportPageErrors>>;
  expectedTotalWorkingHours: number | null;
  setExpectedTotalWorkingHours: Dispatch<SetStateAction<number | null>>;
  resourceWorkingHours: IResourceWorkingHours[];
  setResourceWorkingHours: Dispatch<SetStateAction<IResourceWorkingHours[]>>;
  isMeasuring: boolean;
}

const SituationDescriptionResourceSection = ({
  isReadOnly,
  situationDescription,
  setSituationDescription,
  isFollowUpActionRequired,
  setIsFollowUpActionRequired,
  followUpActionDescription,
  setFollowUpActionDescription,
  errors,
  setErrors,
  expectedTotalWorkingHours,
  setExpectedTotalWorkingHours,
  resourceWorkingHours,
  setResourceWorkingHours,
  isMeasuring,
}: ISituationDescriptionResourceSectionProps) => {
  const { t } = useTranslation();
  const [resourceSearchBy, setResourceSearchBy] = useState<string>('');
  const [workingHoursError, setWorkingHoursError] = useState<string>('');
  const debouncedResourceSearchBy = useDebounce(resourceSearchBy);

  const {
    data: resourcesData,
    fetchNextPage: fetchNextPageResources,
    hasNextPage: hasNextPageResources,
    isLoading: isLoadingResources,
  } = useGetResourcesInfinite(RESOURCES_PER_PAGE, debouncedResourceSearchBy);

  const fetchedResources = useMemo(() => {
    if (resourcesData?.pages?.length) {
      return resourcesData.pages.map((page) => page.resources).flat();
    }
    return [];
  }, [resourcesData]);

  const resourceOptions = removeAlreadySelectedResourcesFromFetchedResources(
    fetchedResources,
    resourceWorkingHours
  );

  return (
    <Container>
      <SectionTitle marginBottom={marginSm}>
        {isMeasuring
          ? t('SITUATION DESCRIPTION & EXPECTED WORKING HOURS')
          : t('SITUATION DESCRIPTION')}
      </SectionTitle>
      <RowGap gap={gapLg}>
        <InputWrapper>
          <Input
            disabled={isReadOnly}
            error={!!errors.situationDescription}
            errorMessage={errors.situationDescription}
            height={'190rem'}
            isTextArea
            value={situationDescription}
            placeholder={
              isMeasuring
                ? t('Detailed account and important notes from the measurement')
                : t(
                    'Detailed account and important notes from the installation'
                  )
            }
            onChange={(e) => {
              const newValue = e.target.value;
              setSituationDescription(newValue);
              const error = validateSituationDescription(newValue, t);
              setErrors((prevErrors) => ({
                ...prevErrors,
                situationDescription: error,
              }));
            }}
            wrapperStyles={{
              marginBottom: marginSm,
            }}
          />
          <Checkbox
            disabled={isReadOnly}
            label={t('Follow up action required')}
            isChecked={isFollowUpActionRequired}
            onChange={() => {
              if (!isReadOnly) {
                setIsFollowUpActionRequired(!isFollowUpActionRequired);
                setFollowUpActionDescription('');
                const error = validateFollowUpActionDescription(
                  followUpActionDescription,
                  t,
                  !isFollowUpActionRequired
                );
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  followUpActionDescription: error,
                }));
              }
            }}
          />
          {isFollowUpActionRequired ? (
            <Input
              disabled={isReadOnly}
              error={!!errors.followUpActionDescription}
              errorMessage={errors.followUpActionDescription}
              height={'190rem'}
              isTextArea
              value={followUpActionDescription}
              placeholder={t('Required follow up action description')}
              onChange={(e) => {
                const newValue = e.target.value;
                setFollowUpActionDescription(newValue);
                const error = validateFollowUpActionDescription(
                  newValue,
                  t,
                  isFollowUpActionRequired
                );
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  followUpActionDescription: error,
                }));
              }}
              wrapperStyles={{
                marginTop: marginSm,
                marginBottom: marginSm,
              }}
            />
          ) : null}
        </InputWrapper>
        {!isMeasuring ? null : (
          <ColumnOuter>
            <Input
              disabled={isReadOnly}
              type="number"
              height={'41rem'}
              value={expectedTotalWorkingHours?.toString()}
              placeholder={t('Expected total working hours')}
              onChange={(e) => {
                const newValue = e.target.value;
                if (Number(newValue) < 0) {
                  setWorkingHoursError('Hours must have positive value');
                } else {
                  setWorkingHoursError('');
                }
                setExpectedTotalWorkingHours(Number(newValue));
              }}
              errorMessage={workingHoursError}
              error={!!workingHoursError}
            />
            <ColumnGap>
              {isReadOnly ? (
                <RequiredResourcesLabel>
                  {t('Required resources') + ':'}
                </RequiredResourcesLabel>
              ) : (
                <Select
                  isDisabled={isReadOnly}
                  key={`required-resources-${resourceWorkingHours.length}`}
                  name="requiredResources"
                  placeholder={t('Select required resources')}
                  isMulti={false}
                  isSearchable={true}
                  onChange={(selectedOption: Option) => {
                    // Find the resource from fetched resources and push new ResourceWorkingHours element
                    const resourceWorkingHour: IResourceWorkingHours = {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      resource: fetchedResources.find(
                        (resource: IWorkerAndResource) => {
                          return resource.id === selectedOption.value;
                        }
                      )!,
                      workingHours: 0,
                    };
                    setResourceWorkingHours([
                      ...resourceWorkingHours,
                      resourceWorkingHour,
                    ]);
                  }}
                  options={resourceOptions}
                  onInputChange={(e: string) => {
                    setResourceSearchBy(e);
                  }}
                  onMenuScrollToBottom={() => {
                    hasNextPageResources && fetchNextPageResources();
                  }}
                  isLoading={isLoadingResources}
                />
              )}
              {resourceWorkingHours.map(
                (resourceWorkingHour: IResourceWorkingHours, index: number) => {
                  return (
                    <ResourceWorkingHourRow key={index}>
                      <ResourceName>
                        {resourceWorkingHour.resource.name}
                      </ResourceName>
                      <RowGap gap={gapXs}>
                        <Input
                          disabled={isReadOnly}
                          type="number"
                          height={'41rem'}
                          value={resourceWorkingHour.workingHours.toString()}
                          placeholder={t('Expected hours')}
                          onChange={(e) => {
                            const newValue = e.target.value;
                            const resourceWorkingHoursCopy = [
                              ...resourceWorkingHours,
                            ];
                            resourceWorkingHoursCopy[index].workingHours =
                              Number(newValue);
                            setResourceWorkingHours(resourceWorkingHoursCopy);
                          }}
                          wrapperStyles={{
                            width: '150rem',
                          }}
                        />
                        {isReadOnly ? null : (
                          <Icon
                            svg={Trash}
                            marginTop="7rem"
                            size={25}
                            color={COLORS.PRIMARY}
                            onClick={() =>
                              setResourceWorkingHours(
                                resourceWorkingHours.filter(
                                  (_: IResourceWorkingHours, i: number) =>
                                    index !== i
                                )
                              )
                            }
                          />
                        )}
                      </RowGap>
                    </ResourceWorkingHourRow>
                  );
                }
              )}
            </ColumnGap>
          </ColumnOuter>
        )}
      </RowGap>
    </Container>
  );
};

export default SituationDescriptionResourceSection;
