import { COLORS, gapSm, marginSm } from 'assets/styled';
import styled, { css } from 'styled-components';

interface StyledProps {
  fontSize: string;
  gap: string;
  isCursorPointer: boolean;
  isAlternativeShippingData: boolean;
}

export const Label = styled.label`
  font-weight: 300;
  ${(props: StyledProps) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500rem;
  gap: ${gapSm};
  ${(props: StyledProps) =>
    props.isCursorPointer &&
    css`
      cursor: pointer;
    `};

  ${Label} {
    cursor: ${(props: StyledProps) =>
      props.isCursorPointer ? 'pointer' : 'auto'};
  }
`;

export const HorizontalLine = styled.div`
  height: 1rem;
  width: 100%;
  background-color: ${COLORS.GREY_200};
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  ${(props: StyledProps) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  ${(props: StyledProps) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `};
`;

export const BlueQuantityLabel = styled.label`
  color: ${COLORS.PRIMARY};
  font-weight: 300;
  font-size: 18rem;
`;

export const LinkText = styled.a`
  font-size: 18rem;
  font-weight: 300;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.PRIMARY};
  cursor: default;
  ${(props: StyledProps) =>
    !props.isAlternativeShippingData &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `};
`;
