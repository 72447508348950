import { Close } from 'components/Close/Close';
import { Modal } from 'components/Modal/Modal';
import { Formik } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import {
  ApplyWrapper,
  CloseContainer,
  Container,
  Description,
  Title,
  TitleContainer,
} from './EmailInputModal.styled';
import { exportExcelSchema } from './validation';
import { Input as FormikInput } from 'components/Input/InputFormik';
import Button from 'components/Button/Button';

interface IEmailInputModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  title: string;
  description?: string;
  handleConfirm: (email: string) => void;
}
export const EmailInputModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  title,
  description,
  handleConfirm,
}: IEmailInputModalProps) => {
  const { t } = useTranslation();
  const { isSmallTablet } = useBreakpointFlag();

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      modalStyle={{
        minWidth: '700rem',
        margin: 'auto',
        position: 'fixed',
      }}
    >
      <CloseContainer>
        <Close onClick={onCancel} />
      </CloseContainer>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      {description && (
        <TitleContainer>
          <Description>{description}</Description>
        </TitleContainer>
      )}
      <div>
        <Formik
          enableReinitialize
          onSubmit={() => {
            //
          }}
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={{
            email: '',
          }}
          validationSchema={exportExcelSchema}
        >
          {({ errors, setFieldValue, values, handleBlur, isValid }) => {
            return (
              <Container>
                <FormikInput
                  errorMessage={
                    errors['email'] ? (errors['email'] as string) : ''
                  }
                  onBlur={(e) => {
                    const value = e.target.value.trim();
                    setFieldValue('email', value);
                    handleBlur(e);
                  }}
                  height={'41rem'}
                  name="email"
                  wrapperStyles={{
                    width: isSmallTablet ? '100%' : '300rem',
                    marginBottom: '4rem',
                    marginTop: '10rem',
                  }}
                  placeholder={t('Email')}
                />
                <ApplyWrapper>
                  <Button
                    onClick={onCancel}
                    secondary
                    width="200rem"
                    label={t('Cancel')}
                  />
                  <Button
                    onClick={() => {
                      if (isValid) {
                        handleConfirm(values.email);
                        setIsOpen(false);
                      }
                    }}
                    primary
                    width="200rem"
                    label={t('Confirm')}
                  />
                </ApplyWrapper>
              </Container>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
