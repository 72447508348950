import {
  borderRadiusSm,
  COLORS,
  marginMd,
  marginSm,
  paddingMd,
} from 'assets/styled';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: ${COLORS.WHITE};
  height: 100%;
  max-width: 850rem;
  width: 100%;
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
`;

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
  margin-bottom: ${marginMd};
`;

export const ExpandableSectionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Line = styled.div`
  max-width: 790rem;
  width: 100%;
  border-bottom: 1px solid ${COLORS.GREY_200};
  margin-top: ${marginSm};
  margin-bottom: ${marginSm};
`;
