export enum ReactQueryKeys {
  GET_QUOTATIONS = 'GET_QUOTATIONS',
  GET_SALES_ORDERS = 'GET_SALES_ORDERS',
  GET_SALES_LINES_AVAILABILITY = 'GET_SALES_LINES_AVAILABILITY',
  GET_PURCHASE_ORDERS = 'GET_PURCHASE_ORDERS',
  GET_SINGLE_QUOTATION = 'GET_SINGLE_QUOTATION',
  GET_QUOTATION_STATUSES = 'GET_QUOTATION_STATUSES',
  GET_SINGLE_QUOTATION_SALES_ORDER = 'GET_SINGLE_QUOTATION_SALES_ORDER',
  GET_SINGLE_QUOTATION_SIGNATURE = 'GET_SINGLE_QUOTATION_SIGNATURE',
  GET_SINGLE_QUOTATION_EMAIL_STATUS = 'GET_SINGLE_QUOTATION_EMAIL_STATUS',
  GET_SINGLE_SALES_ORDER_SIGNATURES = 'GET_SINGLE_SALES_ORDER_SIGNATURES',
  GET_SINGLE_SALES_ORDER = 'GET_SINGLE_SALES_ORDER',
  GET_SINGLE_SALES_ORDER_EMAIL_STATUS = 'GET_SINGLE_SALES_ORDER_EMAIL_STATUS',
  GET_QUOTATION_FILES = 'GET_QUOTATION_FILES',
  GET_QUOTATION_INFO = 'GET_QUOTATION_INFO',
  GET_QUOTATION_COMMENTS = 'GET_QUOTATION_COMMENTS',
  GET_SALES_ORDER_COMMENTS = 'GET_SALES_ORDER_COMMENTS',
  GET_SALES_ORDER_QUOTATIONS = 'GET_SALES_ORDER_QUOTATIONS',
  GET_SINGLE_SALES_ORDER_QUOTATIONS = 'GET_SINGLE_SALES_ORDER_QUOTATIONS',
  GET_SINGLE_SALES_ORDER_HISTORY = 'GET_SINGLE_SALES_ORDER_HISTORY',
  GET_SINGLE_QUOTATION_HISTORY = 'GET_SINGLE_QUOTATION_HISTORY',
  GET_SINGLE_QUOTATION_PARENT_QUOTATION_HISTORY = 'GET_SINGLE_QUOTATION_PARENT_QUOTATION_HISTORY',
  GET_RESOURCES = 'GET_RESOURCES',
  ADD_RESOURCE = 'ADD_RESOURCE',
  DELETE_RESOURCE = 'DELETE_RESOURCE',
  GET_WORKERS = 'GET_WORKERS',
  ADD_WORKER = 'ADD_WORKER',
  DELETE_WORKER = 'DELETE_WORKER',
  GET_CUSTOMERS = 'GET_CUSTOMERS',
  GET_CUSTOMERS_INFO = 'GET_CUSTOMERS_INFO',
  GET_SUPPLIERS = 'GET_SUPPLIERS',
  GET_SUPPLIERS_INFO = 'GET_SUPPLIERS_INFO',
  GET_EMAILS_INFO = 'GET_EMAILS_INFO',
  GET_WORKERS_INFO = 'GET_WORKERS_INFO',
  GET_RESOURCES_INFO = 'GET_RESOURCES_INFO',
  UPLOAD_QUOTATION_FILE = 'UPLOAD_QUOTATION_FILE',
  GET_SINGLE_CUSTOMER = 'GET_SINGLE_CUSTOMER',
  GET_SINGLE_SUPPLIER = 'GET_SINGLE_SUPPLIER',
  GET_SALES_ORDER_FILES = 'GET_SALES_ORDER_FILES',
  GET_SINGLE_PURCHASE_ORDER = 'GET_SINGLE_PURCHASE_ORDER',
  GET_PURCHASE_ORDER_COMMENTS = 'GET_PURCHASE_ORDER_COMMENTS',
  GET_PURCHASE_ORDER_FILES = 'GET_PURCHASE_ORDER_FILES',
  GET_PRODUCTS = 'GET_PRODUCTS',
  GET_PRODUCTS_INFINITE = 'GET_PRODUCTS_INFINITE',
  GET_APPOINTMENTS = 'GET_APPOINTMENTS',
  GET_APPOINTMENT_COMMENTS = 'GET_APPOINTMENT_COMMENTS',
  GET_SINGLE_APPOINTMENT = 'GET_SINGLE_APPOINTMENT',
  GET_SINGLE_APPOINTMENT_EMAIL_STATUS = 'GET_SINGLE_APPOINTMENT_EMAIL_STATUS',
  GET_SINGLE_STOCK_ITEM = 'GET_SINGLE_STOCK_ITEM',
  GET_STOCK_ITEMS = 'GET_STOCK_ITEMS',
  GET_STOCK_ITEMS_INFINITE = 'GET_STOCK_ITEMS_INFINITE',
  GET_STOCK_INFO = 'GET_STOCK_INFO',
  GET_STOCK_ITEMS_FOR_CREATING_PURCHASE_ORDER = 'GET_STOCK_ITEMS_FOR_CREATING_PURCHASE_ORDER',
  GET_SALES_INVOICES = 'GET_SALES_INVOICES',
  GET_SALES_INFO = 'GET_SALES_INFO',
  GET_PURCHASE_INFO = 'GET_PURCHASE_INFO',
  GET_PURCHASE_INVOICES = 'GET_PURCHASE_INVOICES',
  GET_SINGLE_SALES_INVOICE = 'GET_SINGLE_SALES_INVOICE',
  GET_SINGLE_SALES_INVOICE_EMAIL_STATUS = 'GET_SINGLE_SALES_INVOICE_EMAIL_STATUS',
  GET_SINGLE_PURCHASE_INVOICE = 'GET_SINGLE_PURCHASE_INVOICE',
  GET_SALES_INVOICE_FILES = 'GET_SALES_INVOICE_FILES',
  GET_PURCHASE_INVOICE_FILES = 'GET_PURCHASE_INVOICE_FILES',
  GET_SINGLE_SALES_INVOICE_PAYMENTS = 'GET_SINGLE_SALES_INVOICE_PAYMENTS',
  GET_SINGLE_PURCHASE_INVOICE_PAYMENTS = 'GET_SINGLE_PURCHASE_INVOICE_PAYMENTS',
  GET_SINGLE_SALES_ORDER_INVOICES = 'GET_SINGLE_SALES_ORDER_INVOICES',
  GET_SINGLE_SALES_ORDER_PURCHASE_ORDERS = 'GET_SINGLE_SALES_ORDER_PURCHASE_ORDERS',
  GET_SINGLE_PURCHASE_ORDER_SALES_ORDERS = 'GET_SINGLE_PURCHASE_ORDER_SALES_ORDERS',
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS',
  GET_SINGLE_SALES_INVOICE_CREDIT_INVOICES = 'GET_SINGLE_SALES_INVOICE_CREDIT_INVOICES',
  GET_SINGLE_PURCHASE_INVOICE_CREDIT_INVOICES = 'GET_SINGLE_PURCHASE_INVOICE_CREDIT_INVOICES',
  GET_SINGLE_QUOTATION_APPOINTMENTS = 'GET_SINGLE_QUOTATION_APPOINTMENTS',
  GET_SINGLE_QUOTATION_APPOINTMENTS_FOR_INTERVAL = 'GET_SINGLE_QUOTATION_APPOINTMENTS_FOR_INTERVAL',
  GET_SINGLE_SALES_ORDER_APPOINTMENTS = 'GET_SINGLE_SALES_ORDER_APPOINTMENTS',
  GET_SINGLE_SALES_ORDER_APPOINTMENTS_FOR_INTERVAL = 'GET_SINGLE_SALES_ORDER_APPOINTMENTS_FOR_INTERVAL',
  GET_SINGLE_CUSTOMER_QUOTATIONS = 'GET_SINGLE_CUSTOMER_QUOTATIONS',
  GET_SINGLE_CUSTOMER_SALES_ORDERS = 'GET_SINGLE_CUSTOMER_SALES_ORDERS',
  GET_SINGLE_CUSTOMER_APPOINTMENTS = 'GET_SINGLE_CUSTOMER_APPOINTMENTS',
  GET_SINGLE_CUSTOMER_QUOTATIONS_AND_SALES_ORDERS = 'GET_SINGLE_CUSTOMER_QUOTATIONS_AND_SALES_ORDERS',
  GET_SINGLE_SUPPLIER_QUOTATIONS = 'GET_SINGLE_SUPPLIER_QUOTATIONS',
  GET_SINGLE_SUPPLIER_APPOINTMENTS = 'GET_SINGLE_SUPPLIER_APPOINTMENTS',
  GET_QUOTATION_ACTIONS = 'GET_QUOTATION_ACTIONS',
  GET_QUOTATION_LATEST_ACTION = 'GET_QUOTATION_LATEST_ACTION',
  GET_SINGLE_SALES_CREDIT_INVOICE = 'GET_SINGLE_SALES_CREDIT_INVOICE',
  GET_SINGLE_PURCHASE_CREDIT_INVOICE = 'GET_SINGLE_PURCHASE_CREDIT_INVOICE',
  GET_SINGLE_SUPPLIER_PURCHASE_ORDERS = 'GET_SINGLE_SUPPLIER_PURCHASE_ORDERS',
  GET_PAYMENT_INFO = 'GET_PAYMENT_INFO',
  GET_SINGLE_QUOTATION_QUOTATIONS = 'GET_SINGLE_QUOTATION_QUOTATIONS',
  GET_AUTHORIZE_GOOGLE_ACCOUNT = 'GET_AUTHORIZE_GOOGLE_ACCOUNT',
  GET_CITY_AND_ADDRESS = 'GET_CITY_AND_ADDRESS',
  GET_AUTHORIZE_OUTLOOK = 'GET_AUTHORIZE_OUTLOOK',
  GET_COMPANY_USERS = 'GET_COMPANY_USERS',
  GET_SINGLE_APPOINTMENT_INTERVALS = 'GET_SINGLE_APPOINTMENT_INTERVALS',
  GET_SINGLE_QUOTATION_INFO = 'GET_SINGLE_QUOTATION_INFO',
  GET_SINGLE_SALES_ORDER_INFO = 'GET_SINGLE_SALES_ORDER_INFO',
  GET_SINGLE_PURCHASE_ORDER_INFO = 'GET_SINGLE_PURCHASE_ORDER_INFO',
  GET_SINGLE_SALES_INVOICE_INFO = 'GET_SINGLE_SALES_INVOICE_INFO',
  GET_SINGLE_PURCHASE_INVOICE_INFO = 'GET_SINGLE_PURCHASE_INVOICE_INFO',
  GET_SINGLE_FW_APPOINTMENT_INFO = 'GET_SINGLE_FW_APPOINTMENT_INFO',
  GET_SINGLE_CUSTOMER_INFO = 'GET_SINGLE_CUSTOMER_INFO',
  GET_SINGLE_CUSTOMER_RECURRING_QUOTATIONS = 'GET_SINGLE_CUSTOMER_RECURRING_QUOTATIONS',
  GET_SINGLE_SUPPLIER_INFO = 'GET_SINGLE_SUPPLIER_INFO',
  GET_DEFAULT_ATTACHMENT_MAIL_FILES = 'GET_DEFAULT_ATTACHMENT_MAIL_FILES',
  GET_SINGLE_FW_APPOINTMENT = 'GET_SINGLE_FW_APPOINTMENT',
  GET_FW_APPOINTMENT_FILES = 'GET_FW_APPOINTMENT_FILES',
  GET_SINGLE_CUSTOMER_COMMENTS = 'GET_SINGLE_CUSTOMER_COMMENTS',
  GET_SINGLE_SUPPLIER_COMMENTS = 'GET_SINGLE_SUPPLIER_COMMENTS',
  GET_TWINFIELD_CUSTOMERS_BY_POSTAL_CODE = 'GET_TWINFIELD_CUSTOMERS_BY_POSTAL_CODE',
  GET_FW_APPOINTMENT_INTERVALS = 'GET_FW_APPOINTMENT_INTERVALS',
  GET_FW_APPOINTMENT_COMMENTS = 'GET_FW_APPOINTMENT_COMMENTS',
  GET_SINGLE_APPOINTMENT_REPORT = 'GET_SINGLE_APPOINTMENT_REPORT',
  GET_FW_SINGLE_APPOINTMENT_REPORT = 'GET_FW_SINGLE_APPOINTMENT_REPORT',
  GET_DAY_SUMMARY_APPOINTMENTS = 'GET_DAY_SUMMARY_APPOINTMENTS',
  GET_SALES_ORDER_ANALYTIC = 'GET_SALES_ORDER_ANALYTIC',
  GET_ORDER_PORTFOLIO_ANALYTIC = 'GET_ORDER_PORTFOLIO_ANALYTIC',
  GET_STOCK_ITEMS_ANALYTIC = 'GET_STOCK_ITEMS_ANALYTIC',
  GET_QUOTATIONS_ANALYTIC = 'GET_QUOTATIONS_ANALYTIC',
  GET_SALES_INVOICES_ANALYTIC = 'GET_SALES_INVOICES_ANALYTIC',
  GET_PURCHASE_ORDER_ANALYTIC = 'GET_PURCHASE_ORDER_ANALYTIC',
  GET_PENDING_INVOICE_LINES = 'GET_PENDING_INVOICE_LINES',
  GET_QUOTATION_REPORTS_CURRENT = 'GET_QUOTATION_REPORTS_CURRENT',
  GET_QUOTATION_REPORTS_HISTORICAL = 'GET_QUOTATION_REPORTS_HISTORICAL',
  GET_SALES_ORDER_REPORTS_CURRENT = 'GET_SALES_ORDER_REPORTS_CURRENT',
  GET_SALES_ORDER_REPORTS_HISTORICAL = 'GET_SALES_ORDER_REPORTS_HISTORICAL',
  GET_FW_APPOINTMENT_REPORTS_CURRENT = 'GET_FW_APPOINTMENT_REPORTS_CURRENT',
  GET_FW_APPOINTMENT_REPORTS_HISTORICAL = 'GET_FW_APPOINTMENT_REPORTS_HISTORICAL',
  GET_SIGNATURE_INFO = 'GET_SIGNATURE_INFO',
  GET_EMAIL_TEMPLATE_QUOTATION_INFO = 'GET_EMAIL_TEMPLATE_QUOTATION_INFO',
  GET_PERMISSIONS_GROUPS = 'GET_PERMISSIONS_GROUPS',
  GET_GOOGLE_CALENDAR_PREFERENCES = 'GET_GOOGLE_CALENDAR_PREFERENCES',
  GET_OUTLOOK_CALENDAR_PREFERENCES = 'GET_OUTLOOK_CALENDAR_PREFERENCES',
  GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES',
  GET_EMAIL_TEMPLATE_VARIABLES = 'GET_EMAIL_TEMPLATE_VARIABLES',
  GET_QUOTATION_ATTACHMENTS_PREVIEW = 'GET_QUOTATION_ATTACHMENTS_PREVIEW',
  GET_SALES_ORDER_ATTACHMENTS_PREVIEW = 'GET_SALES_ORDER_ATTACHMENTS_PREVIEW',
  GET_SALES_INVOICE_ATTACHMENTS_PREVIEW = 'GET_SALES_INVOICE_ATTACHMENTS_PREVIEW',
  GET_APPOINTMENT_ATTACHMENTS_PREVIEW = 'GET_APPOINTMENT_ATTACHMENTS_PREVIEW',
  GET_DEFAULT_ATTACHMENTS_FOR_PRODUCTS = 'GET_DEFAULT_ATTACHMENTS_FOR_PRODUCTS',
  GET_CUSTOMER_REPORTS_CURRENT = 'GET_CUSTOMER_REPORTS_CURRENT',
  GET_CUSTOMER_REPORTS_HISTORICAL = 'GET_CUSTOMER_REPORTS_HISTORICAL',
  GET_RUNNING_APPOINTMENTS = 'GET_RUNNING_APPOINTMENTS',
  GET_BLOCKED_FW_APPOINTMENTS = 'GET_BLOCKED_FW_APPOINTMENTS',
  START_LOGYX_VALIDATION_FOR_PURCHASE_ORDER = 'START_LOGYX_VALIDATION_FOR_PURCHASE_ORDER',
  GET_QUOTATION_ACTIVE_APPOINTMENT_INFO = 'GET_QUOTATION_ACTIVE_APPOINTMENT_INFO',
  GET_SUPPLIERS_INFINITE = 'GET_SUPPLIERS_INFINITE',
  GET_COUNTRIES = 'GET_COUNTRIES',
  GET_COMPANY_LICENSES = 'GET_COMPANY_LICENSES',
  GET_COMPANY_BILLING = 'GET_COMPANY_BILLING',
  GET_USER_LICENSES = 'GET_USER_LICENSES',
  GET_SINGLE_MOLLIE_CUSTOMER_MANDATES = 'GET_SINGLE_MOLLIE_CUSTOMER_MANDATES',
  GET_SAVED_ADV_FILTERS = 'GET_SAVED_ADV_FILTERS',
  GET_UNREAD_NOTIFICATIONS_COUNT = 'GET_UNREAD_NOTIFICATIONS_COUNT',
  GET_UNFINISHED_TODOS_COUNT = 'GET_UNFINISHED_TODOS_COUNT',
  GET_LABELS_INFINITE = 'GET_LABELS_INFINITE',
  GET_LABEL_GROUP_OPTIONS = 'GET_LABEL_GROUP_OPTIONS',
  GET_ENTITY_LABELS = 'GET_ENTITY_LABELS',
  GET_USERS_BY_ID = 'GET_USERS_BY_ID',
  GET_TODOS = 'GET_TODOS',
  GET_SINGLE_TODO = 'GET_SINGLE_TODO',
  GET_TODO_TYPES = 'GET_TODO_TYPES',
  GET_TODO_COMMENTS = 'GET_TODO_COMMENTS',
  GET_SALES_ORDER_LINES = 'GET_SALES_ORDER_LINES',
  GET_PAYMENT_CONDITIONS_INFINITE = 'GET_PAYMENT_CONDITIONS_INFINITE',
  GET_PAYMENT_CONDITIONS = 'GET_PAYMENT_CONDITIONS',
  GET_SINGLE_CUSTOMER_ALTERNATIVE_CONTACTS = 'GET_SINGLE_CUSTOMER_ALTERNATIVE_CONTACTS',
  GET_WATERMARK_PICTURE = 'GET_WATERMARK_PICTURE',
  GET_SALES_ORDER_LINES_SUMMARY = 'GET_SALES_ORDER_LINES_SUMMARY',
  GET_COMPANY_SETTINGS_ADMIN = 'GET_COMPANY_SETTINGS_ADMIN',
  GET_SINGLE_COMPANY_ADMIN = 'GET_SINGLE_COMPANY_ADMIN',
  GET_COMPANY_LOGO = 'GET_COMPANY_LOGO',
  GET_TWINFIELD_COMPANY_INFO = 'GET_TWINFIELD_COMPANY_INFO',
  GET_OFFICE_CODES = 'GET_OFFICE_CODES',
  GET_PAYMENT_CONDITION = 'GET_PAYMENT_CONDITION',
  GET_DEFAULT_PAYMENT_CONDITION = 'GET_DEFAULT_PAYMENT_CONDITION',
  GET_PRODUCT_GROUPS = 'GET_PRODUCT_GROUPS',
  GET_PRODUCT_GROUPS_INFINITE = 'GET_PRODUCT_GROUPS_INFINITE',
}
