import { css } from '@mui/material';
import { COLORS, marginMd, marginSm } from 'assets/styled';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled from 'styled-components';

interface StyledProps {
  selected: boolean;
  isReadOnly: boolean;
}

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 100%;

  ${(props: StyledProps) =>
    props.isReadOnly
      ? css`
          padding: 0;
        `
      : css`
          padding: 10rem 0;
        `}
`;

export const SelectAllContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
`;

export const SelectAllLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 16rem;
  color: ${COLORS.PRIMARY};
  ${hoverLineOut}
`;

export const ClearLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 16rem;
  color: ${COLORS.RED_200};
  ${hoverLineOut}
`;

export const ProductContainer = styled.div`
  background-color: ${(props: StyledProps) =>
    props.selected && !props.isReadOnly
      ? `${COLORS.BACKGROUND_COLOR}`
      : `none`};
  border-bottom: 1rem solid ${COLORS.GREY_300};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10rem 5rem;

  &:last-of-type {
    border-bottom: none;
  }
`;

export const PaginationWrapper = styled.div`
  margin-top: 10rem;
`;

export const ProductName = styled.div`
  font-size: 16rem;
  font-weight: 300;
  line-height: 24rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
`;

export const Label = styled.span`
  line-height: 21rem;
  font-size: 14rem;
  color: ${COLORS.BLACK};
  display: block;
`;

export const More = styled(Label)`
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  user-select: none;
  ${hoverLineOut}
  margin-right: ${marginSm};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20rem;
  margin-bottom: 20rem;
`;

export const ReceivedOrNot = styled.div`
  font-size: 16rem;
  font-weight: 300;
  line-height: 24rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.GREY_1000};
`;
