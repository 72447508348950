import Button from 'components/Button/Button';
import { Close } from 'components/Close/Close';
import { ConfirmModal } from 'components/Modal/ConfirmModal/ConfirmModal';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import Spinner from 'components/Spinner/Spinner';
import { USERS_PER_PAGE } from 'components/Table/Cells/SelectCell/SelectCell';
import { RoutesConfig } from 'navigation/routes';
import { useGetUsersSummaryInfinite } from 'pages/Manager/PlanningPage/PlanningTab/hooks';
import { useGetSingleToDo, useUpdateToDo } from 'pages/Manager/ToDoPage/hooks';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setActiveTodoId,
  setForceUpdateTodoPage,
} from 'store/Common/actions/common';
import { IRootReducerState } from 'store/store';
import { TodoRelatedTypeId } from 'types/ToDo.types';
import { useDebounce } from 'utils/hooks/useDebounce';
import {
  Container,
  LinkText,
  RowGap,
  SelectWrapper,
  Span,
  Type,
} from './TodoInlineBanner.styled';

export const TodoInlineBanner = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isSideMenuCollapsed = useSelector(
    (state: IRootReducerState) => state.commonInfo.isSideMenuCollapsed
  );
  const dispatch = useDispatch();
  const activeTodoId = useSelector(
    (state: IRootReducerState) => state.commonInfo.activeTodoId
  );

  const [isFinishTodoModalOpen, setIsFinishTodoModalOpen] =
    useState<boolean>(false);
  const [userSearchBy, setUserSearchBy] = useState<string>('');
  const debouncedUserSearchBy = useDebounce(userSearchBy);
  const {
    data: usersData,
    fetchNextPage: fetchNextPageUsers,
    hasNextPage: hasNextPageUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersSummaryInfinite(USERS_PER_PAGE, debouncedUserSearchBy, true);

  const { mutate: updateToDo } = useUpdateToDo();
  const { data: todoData, isLoading: isLoadingTodo } = useGetSingleToDo(
    activeTodoId.toString(),
    true
  );
  const forceUpdateTodoPage = useSelector(
    (state: IRootReducerState) => state.commonInfo.forceUpdateTodoPage
  );

  const userOptions = useMemo(() => {
    if (usersData?.pages?.length) {
      return usersData.pages
        .map((page) => page.users)
        .flat()
        .map((user: any) => {
          return {
            value: user?.id,
            label: user.name + ' ' + user.last_name,
          };
        });
    }
    return [];
  }, [usersData]);

  const handleFinishClick = (todoData: any) => {
    updateToDo(
      {
        id: todoData?.todo?.id.toString(),
        updateToDoData: { finished: true },
      },
      {
        onSuccess: () => {
          dispatch(setActiveTodoId(null));
        },
      }
    );
  };

  const onSelectChange = (e: Option) => {
    const updateToDoData = { assigned_to_user_id: e === null ? null : e.value };
    updateToDo(
      {
        id: todoData?.todo?.id,
        updateToDoData,
      },
      {
        onSuccess: () => {
          // ForceUpdate is used to manually trigger a re-render on select change, ensuring the selects in the Todo overview table are refreshed
          dispatch(setForceUpdateTodoPage(forceUpdateTodoPage + 1));
        },
      }
    );
  };

  const getRelatedToNavigationRoute = (
    relatedTypeId: string,
    relatedToId: string
  ) => {
    switch (relatedTypeId) {
      case TodoRelatedTypeId.QUOTATION:
        return RoutesConfig.SingleQuotation.fullPath.replace(
          ':id/*',
          relatedToId
        );
      case TodoRelatedTypeId.SALES_ORDER:
        return RoutesConfig.SingleSalesOrder.fullPath.replace(
          ':id/*',
          relatedToId
        );
      case TodoRelatedTypeId.SALES_INVOICE:
        return RoutesConfig.SingleSalesInvoice.fullPath.replace(
          ':id/*',
          relatedToId
        );
      case TodoRelatedTypeId.PURCHASE_ORDER:
        return RoutesConfig.SinglePurchaseOrder.fullPath.replace(
          ':id/*',
          relatedToId
        );
      case TodoRelatedTypeId.CUSTOMER:
        return RoutesConfig.SingleCustomer.fullPath.replace(
          ':id/*',
          relatedToId
        );
      default:
        break;
    }
  };

  return (
    <Container isSideMenuCollapsed={isSideMenuCollapsed}>
      {isLoadingTodo ? (
        <Spinner size={20} />
      ) : (
        <RowGap>
          <Span>{todoData?.todo?.title}</Span>
          <Type>{t(todoData?.todo?.todo_type?.name)}</Type>
          <LinkText
            href={getRelatedToNavigationRoute(
              todoData?.todo?.related_type_id,
              todoData?.todo?.related_to_id
            )}
            onClick={(e: any) => {
              e.preventDefault();
              const navigationRoute = getRelatedToNavigationRoute(
                todoData?.todo?.related_type_id.toString(),
                todoData?.todo?.related_to_id.toString()
              );
              navigationRoute && navigate(navigationRoute);
            }}
          >
            {todoData?.todo?.related_to_number}
          </LinkText>
        </RowGap>
      )}
      <RowGap>
        <SelectWrapper>
          <Select
            name="assignedToSelect"
            placeholder=""
            defaultValue={
              todoData?.todo?.assigned_to
                ? {
                    label: `${todoData?.todo?.assigned_to?.name} ${todoData?.todo?.assigned_to?.last_name}`,
                    value: todoData?.todo?.id,
                  }
                : undefined
            }
            options={userOptions}
            onChange={(e: Option) => {
              onSelectChange(e);
            }}
            onInputChange={(e: string) => setUserSearchBy(e)}
            onMenuScrollToBottom={() =>
              hasNextPageUsers && fetchNextPageUsers()
            }
            isLoading={isLoadingUsers || isLoadingTodo}
            translate={false}
          />
        </SelectWrapper>

        <Button
          disabled={isLoadingTodo}
          width={'100rem'}
          height={'41rem'}
          onClick={() => {
            setIsFinishTodoModalOpen(true);
          }}
          label={t('Finish')}
          primary
        />
        <Close onClick={() => dispatch(setActiveTodoId(null))} />
      </RowGap>
      <ConfirmModal
        isOpen={isFinishTodoModalOpen}
        setIsOpen={setIsFinishTodoModalOpen}
        onConfirm={() => {
          handleFinishClick(todoData);
          setIsFinishTodoModalOpen(false);
        }}
        onCancel={() => setIsFinishTodoModalOpen(false)}
        title={t('Finish To Do')}
        description={t('Are you sure you want to finish this to do') + '?'}
      />
    </Container>
  );
};
