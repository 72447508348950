import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  AdvancedFilterWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  LabelsFilterWrapper,
  SelectInputWrapper,
  Title,
  TitleContainer,
} from './FilterMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import CheckableSelect from 'components/Select/SelectComponents/CheckableSelect/CheckableSelect';
import { capitalizeFirstLetter } from 'utils/stringUtils';
import { Option } from 'components/Select/type';
import Button from 'components/Button/Button';
import LabelsFilter from 'components/LabelsFilter/LabelsFilter';

const PWID = 'quotation-page';

interface IFilterMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  onCancel: () => void;
  filtersData: any;
  statusOptions: Option[];
  dateIntervalOptions: Option[];
  activeFilterOptions: Option[];
  quotationTypeOptions: Option[];
  setIsAdvancedFilterModalOpen: Dispatch<SetStateAction<boolean>>;
  advancedFilters: string;
}

const FilterMobileModal = ({
  isOpen,
  setIsOpen,
  onCancel,
  filtersData,
  statusOptions,
  dateIntervalOptions,
  activeFilterOptions,
  quotationTypeOptions,
  setIsAdvancedFilterModalOpen,
  advancedFilters,
}: IFilterMobileModalProps) => {
  const { t } = useTranslation();
  const {
    selectedStatusOptions,
    setSelectedStatusOptions,
    selectedDateIntervalOption,
    setSelectedDateIntervalOption,
    selectedActiveFilterOption,
    setSelectedActiveFilterOption,
    selectedQuotationTypeOption,
    setSelectedQuotationTypeOption,
  } = filtersData;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              onCancel();
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>{t('Filter')}</Title>
        </TitleContainer>

        <SelectInputWrapper>
          <CheckableSelect
            width="100%"
            placeholder={t('Type')}
            options={quotationTypeOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(options: Option[]) =>
              setSelectedQuotationTypeOption(options[0])
            }
            defaultValues={
              selectedQuotationTypeOption
                ? [selectedQuotationTypeOption]
                : [quotationTypeOptions[0]]
            }
          />
        </SelectInputWrapper>
        <SelectInputWrapper>
          <CheckableSelect
            width="100%"
            placeholder={t('Status')}
            isMulti
            options={statusOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(e: Option[]) => setSelectedStatusOptions(e)}
            defaultValues={selectedStatusOptions}
          />
        </SelectInputWrapper>
        <SelectInputWrapper>
          <CheckableSelect
            width="100%"
            placeholder={t('Active filter')}
            options={activeFilterOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(options: Option[]) =>
              setSelectedActiveFilterOption(options[0])
            }
            defaultValues={
              selectedActiveFilterOption
                ? [selectedActiveFilterOption]
                : [activeFilterOptions[0]]
            }
          />
        </SelectInputWrapper>
        <SelectInputWrapper>
          <CheckableSelect
            width="100%"
            placeholder={t('Date')}
            options={dateIntervalOptions}
            translate
            labelTransform={(string: string) => capitalizeFirstLetter(string)}
            onChange={(options: Option[]) =>
              setSelectedDateIntervalOption(options[0])
            }
            value={selectedDateIntervalOption}
            defaultValues={
              selectedDateIntervalOption
                ? [selectedDateIntervalOption]
                : [dateIntervalOptions[0]]
            }
          />
        </SelectInputWrapper>
        <AdvancedFilterWrapper>
          <Button
            onClick={() => setIsAdvancedFilterModalOpen(true)}
            label={t('Advanced filter')}
            width="200rem"
            height="40rem"
            secondary={!advancedFilters}
            primary={!!advancedFilters}
          />
        </AdvancedFilterWrapper>
        <LabelsFilterWrapper>
          <LabelsFilter
            pwId={`${PWID}`}
            selectedLabelIds={filtersData.selectedLabelIds}
            setSelectedLabelIds={filtersData.setSelectedLabelIds}
          />
        </LabelsFilterWrapper>
        <ButtonsWrapper>
          <Button
            onClick={() => setIsOpen(false)}
            label={t('Finish')}
            width="100%"
            height="40rem"
            primary
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default FilterMobileModal;
