import { COLORS, marginSm, paddingMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import CustomSelect from 'components/Select/FormikSelect';
import styled, { css } from 'styled-components';

interface StyledProps {
  marginTop?: string;
}

export const CloseContainer = styled.div`
  position: absolute;
  right: 34.3rem;
  top: 24.4rem;

  ${respondTo.smallTablet`
    top: 15rem;
    right: 15rem;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  ${respondTo.smallTablet`
    padding-top: 50rem;
  `}
`;

export const Title = styled.div`
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};

  ${respondTo.smallTablet`
    text-align: center;
    line-height: 1.2;
    font-size: 18rem;
  `}
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${paddingMd};
  max-height: 859rem;
`;

export const Heading = styled.div`
  padding-top: ${marginSm};

  ${respondTo.smallTablet`
    padding-top: 0;
  `}
`;

export const HorizontalLine = styled.div`
  width: 100%;
  height: 1rem;
  background-color: ${COLORS.GREY_200};
`;

export const ApplyWrapper = styled.div`
  display: flex;
  padding-top: 32rem;
  padding-bottom: 32rem;
  align-items: center;
  justify-content: center;
  gap: 10rem;

  ${respondTo.smallTablet`
    padding-top: 20rem;
  `}
`;
export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo.smallTablet`
    margin-bottom: 10rem;
  `}
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}

  ${respondTo.smallTablet`
    flex-direction: column;
    margin-top: 10rem;
  `}
`;

export const StyledCustomSelect = styled(CustomSelect)`
  margin-right: 10rem;
  margin-top: ${marginSm};
  width: 300rem;

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: calc(50% + 15rem);
  width: 100%;
  margin-bottom: 14rem;
`;
