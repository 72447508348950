import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ActionsIcons,
  ActionsWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  LoaderWrapper,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { DownloadSimple, EnvelopeSimple } from '@phosphor-icons/react';
import { COLORS } from 'assets/styled';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import { ReactComponent as MailFailed } from 'assets/icons/mail-failed.svg';
import { ReactComponent as MailPending } from 'assets/icons/mail-pending.svg';
import Spinner from 'components/Spinner/Spinner';
import { useNavigate } from 'react-router-dom';
import { EmailStatuses } from 'types/Email.types';
import { SingleSalesInvoiceRoutes } from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';

interface IActionsMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  canEditSalesInvoice: boolean;
  isLoadingExport: boolean;
  exportSalesInvoice: any;
  emailStatus: any;
  setIsAddEmailNoteModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsPaymentModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ActionsMobileModal = ({
  isOpen,
  setIsOpen,
  data,
  canEditSalesInvoice,
  isLoadingExport,
  exportSalesInvoice,
  emailStatus,
  setIsAddEmailNoteModalOpen,
  setIsPaymentModalOpen,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>
            {t('Sales Invoice')} {data?.invoice_number}
          </Title>
        </TitleContainer>
        <ActionsWrapper>
          <ActionsIcons>
            {isLoadingExport ? (
              <LoaderWrapper>
                <Spinner size={20} />
              </LoaderWrapper>
            ) : (
              <Icon
                data-testid="download-pdf"
                svg={DownloadSimple}
                color={COLORS.PRIMARY}
                size={25}
                onClick={() => exportSalesInvoice(data?.id)}
              />
            )}

            <Icon
              data-testid="send-email"
              svg={
                data?.sent_to_customer
                  ? emailStatus?.status === EmailStatuses.SENT
                    ? MailSent
                    : emailStatus?.status === EmailStatuses.PENDING
                    ? MailPending
                    : MailFailed
                  : EnvelopeSimple
              }
              size={25}
              onClick={() => setIsAddEmailNoteModalOpen(true)}
              color={COLORS.PRIMARY}
            />
          </ActionsIcons>
        </ActionsWrapper>
        <ButtonsWrapper>
          <Button
            label={t('Add Payment')}
            secondary
            disabled={!canEditSalesInvoice || !data?.open_amount}
            width="100%"
            onClick={() => setIsPaymentModalOpen(true)}
          />
          <Button
            onClick={() =>
              navigate(
                SingleSalesInvoiceRoutes.SingleSalesInvoiceNewCreditInvoice
                  .path,
                {
                  state: { prepopulateCreditInvoiceData: data },
                }
              )
            }
            label={t('New credit invoice')}
            width="100%"
            secondary
            disabled={!canEditSalesInvoice}
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
