import { COLORS, marginMd, paddingSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 850rem;
  display: flex;
  justify-content: center;
  flex-direction: column;

  ${respondTo.smallTablet`
    max-width: 100%;
  `}
`;

export const ProductsWrapper = styled.div`
  align-self: center;
  margin-top: ${marginMd};
  max-width: 100%;
  width: 100%;
  border-radius: 10rem;
  background-color: ${COLORS.WHITE};
  padding: 30rem;

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const Heading = styled.div`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  color: ${COLORS.BLACK};
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  ${respondTo.bigPhone`
    justify-content: flex-start;
  `}
`;

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${paddingSm};

  ${respondTo.bigPhone`
    flex-direction: column;
    width: 100%;
    gap: 20rem;
    align-items: stretch;
    padding-top: 0;
  `}
`;

export const SelectInputWrapper = styled.div`
  width: 200rem;

  ${respondTo.bigPhone`
    width: 100%;
  `}
`;
