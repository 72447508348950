import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  height: 390rem;
  max-width: 850rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: 10rem;
  margin-bottom: 50rem;
  padding: 30rem;

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
    height: auto;
  `}
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30rem;

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 30rem;
    margin-bottom: 10rem;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 20rem;

  ${respondTo.bigPhone`
    padding-top: 10rem;
  `}
`;

export const ColumnContainer = styled.div`
  width: 100%;
  padding-right: 97rem;
  :nth-child(2) {
    padding-right: 102rem;
  }
  :last-child {
    padding-right: 0rem;
  }

  ${respondTo.bigPhone`
    padding-right: 0;
  `}
`;

export const LabelTitle = styled.div`
  font-size: 14rem;
  font-weight: 600;
  line-height: 21rem;
  width: 129rem;
  letter-spacing: 0em;
  text-align: left;
  color: ${COLORS.BLACK};
`;
export const Label = styled.div`
  font-size: 14rem;
  font-weight: 300;
  line-height: 21rem;
  letter-spacing: 0em;
  text-align: left;
  max-width: 220rem;
  color: ${COLORS.BLACK};
`;
