import {
  Container,
  TabContainer,
  Tab,
  CreateNewPurchaseOrderLabel,
} from './NewStockItemPage.styled';
import { useEffect, useState } from 'react';
import { CreateStockItemTab } from './constants';
import { useCreateStockItem, useGetPrepopulateStockItemData } from './hooks';
import {
  generateExistingSupplierDetails,
  getInitialSupplierDetailsData,
} from './helpers';
import { ICreateStockItemSupplierDetails } from './SupplierTab/constants';
import { ICreateStockItemDetails } from './ItemTab/constants';
import SupplierTab from './SupplierTab/SupplierTab';
import OverviewTab from './OverviewTab/OverviewTab';
import ItemDetailsTab from './ItemTab/ItemTab';
import { ICreateStockItemData } from 'types/StockItem.types';
import { useNavigate } from 'react-router-dom';
import { useGetInitialCountry } from 'utils/hooks/useGetInitialCountry';
import { newEmptyItem } from './ItemTab/constants';
import { useTranslation } from 'react-i18next';
import { ICreateAddressDTO } from 'types/Address.types';
import { ISupplier } from 'types/Supplier.types';
import { STOCK_ITEM_SERVICE_TYPE_ID } from './ItemTab/hooks';
import { ICreateSupplierDTO } from '../NewSupplierPage/types';

const PWID = 'new-stock-item-page';

const NewStockItemPage = () => {
  const navigate = useNavigate();
  const prepopulatePurchaseOrderData = useGetPrepopulateStockItemData();
  const [isUsingExistingSupplier, setIsUsingExistingSupplier] =
    useState<boolean>(prepopulatePurchaseOrderData?.supplier);

  const initialCountry = useGetInitialCountry();

  const [activeTab, setActiveTab] = useState<string>(
    CreateStockItemTab.SUPPLIER_DETAILS
  );

  const [supplierDetails, setSupplierDetails] =
    useState<ICreateStockItemSupplierDetails>(
      getInitialSupplierDetailsData(
        prepopulatePurchaseOrderData,
        initialCountry.cca2
      )
    );

  const [item, setItem] = useState<ICreateStockItemDetails>(newEmptyItem);
  const { t } = useTranslation();
  const { mutate, data, status, isSuccess } = useCreateStockItem();

  useEffect(() => {
    if (isSuccess) {
      navigate(`/stock/${data?.stock_item?.id}`);
    }
  }, [isSuccess]);

  const createStockItem = () => {
    const createStockItemData: ICreateStockItemData = {
      name: item.name,
      description: item.description,
      measure_unit_id: item.unit_of_measure,
      ...(item?.sku && { in_stock: item?.sku }),
      ...(item?.in_stock &&
        item?.type_id !== STOCK_ITEM_SERVICE_TYPE_ID && {
          in_stock: item?.in_stock,
        }),
      ...(item?.min_order_amount && {
        min_order_amount: item?.min_order_amount,
      }),
      ...(item?.sales_price && { price: item?.sales_price }),
      ...(item?.purchase_price && { purchase_price: item?.purchase_price }),
      type_id: item?.type_id,
      ...(item?.number_supplier && { number_supplier: item?.number_supplier }),
      ...(item?.product_group && { product_group_id: item?.product_group.id }),
    };

    if (isUsingExistingSupplier) {
      createStockItemData.supplier_id = supplierDetails.supplierId;
    } else {
      const address: ICreateAddressDTO = {
        country: supplierDetails.country,
        street: supplierDetails.street,
        zip_code: supplierDetails.code,
        house_number: supplierDetails.house_number,
        city: supplierDetails.city,
      };
      const supplier: ICreateSupplierDTO = {
        phone: supplierDetails.phone,
        email: supplierDetails.email,
        address: address,
        vat_number: supplierDetails.vat_number,
        company_name: supplierDetails.company_name,
        contact_person: supplierDetails.contact_person,
        coc_number: supplierDetails.coc_number,
      };
      createStockItemData.supplier = supplier;
    }
    mutate(createStockItemData);
  };

  const handleActiveTab = () => {
    if (activeTab === CreateStockItemTab.SUPPLIER_DETAILS) {
      return (
        <TabContainer>
          <Tab active>1.{t('Supplier details')} </Tab>
          <Tab>2. {t('Item Details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateStockItemTab.ITEMS) {
      return (
        <TabContainer>
          <Tab finished>1.{t('Supplier details')}</Tab>
          <Tab active>2. {t('Item Details')}</Tab>
        </TabContainer>
      );
    } else if (activeTab === CreateStockItemTab.OVERVIEW) {
      return (
        <TabContainer>
          <Tab finished>1.{t('Supplier details')}</Tab>
          <Tab finished>2. {t('Item Details')}</Tab>
        </TabContainer>
      );
    }
  };

  return (
    <Container>
      <CreateNewPurchaseOrderLabel data-testid={`${PWID}-add-stock-item-label`}>
        {t('Add Stock Item')}
      </CreateNewPurchaseOrderLabel>
      {handleActiveTab()}
      {activeTab === CreateStockItemTab.SUPPLIER_DETAILS && (
        <SupplierTab
          pwId={PWID}
          onForward={() => setActiveTab(CreateStockItemTab.ITEMS)}
          supplierDetails={supplierDetails}
          setSupplierDetails={setSupplierDetails}
          resetSupplierTabForm={() => {
            setSupplierDetails(
              getInitialSupplierDetailsData(null, initialCountry.cca2)
            );
          }}
          handlePopulateNewStockItemSupplierTab={
            (supplier: ISupplier) =>
              setSupplierDetails(generateExistingSupplierDetails(supplier))
            // setCurrent
          }
          isUsingExistingSupplier={isUsingExistingSupplier}
          setIsUsingExistingSupplier={setIsUsingExistingSupplier}
        />
      )}
      {activeTab === CreateStockItemTab.ITEMS && (
        <ItemDetailsTab
          pwId={PWID}
          onBack={() => setActiveTab(CreateStockItemTab.SUPPLIER_DETAILS)}
          onForward={() => setActiveTab(CreateStockItemTab.OVERVIEW)}
          itemDetails={item}
          setItemDetails={setItem}
        />
      )}
      {activeTab === CreateStockItemTab.OVERVIEW && (
        <OverviewTab
          pwId={PWID}
          onBack={() => setActiveTab(CreateStockItemTab.ITEMS)}
          onForward={() => createStockItem()}
          supplierDetails={supplierDetails}
          item={item}
          status={status}
        />
      )}
    </Container>
  );
};

export default NewStockItemPage;
