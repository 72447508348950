import {
  CloseContainer,
  IconWrapper,
  LoaderWrapper,
  NoContentLabel,
  PrintWrapper,
  Scrollable,
} from './PreviewDayAppointmentsModal.styled';
import { Close } from 'components/Close/Close';
import { Modal } from '../Modal';
import { Dispatch, SetStateAction, useRef } from 'react';
import { Level } from '../type';
import { IAppointment } from 'types/Appointment.types';
import { PreviewDayAppointmentCard } from './PreviewDayAppointmentCard/PreviewDayAppointmentCard';
import { useTranslation } from 'react-i18next';
import { useGetDaySummaryAppointments } from './hooks';
import { Moment } from 'moment';
import { IQuotationLine } from 'types/Quotations.types';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import Spinner from 'components/Spinner/Spinner';
import { Option } from 'components/Select/type';
import Icon from 'components/Icon/Icon';
import { DownloadSimple } from '@phosphor-icons/react';
import { COLORS, marginLg, marginXs } from 'assets/styled';
import { useReactToPrint } from 'react-to-print';

interface IPreviewDayAppointmentsModalProps {
  dateForDaySummary: Moment | null;
  setDateForDaySummary: Dispatch<SetStateAction<Moment | null>>;
  level?: Level;
  filters?: {
    users?: string[];
    workers?: string[];
    resources?: string[];
    selectedTypes: Option[];
  };
}

export interface IAppointmentSummaryData {
  appointment: IAppointment;
  quotation_lines: IQuotationLine[];
  sales_order_lines: ISalesOrderLine[];
}

export const PreviewDayAppointmentsModal = ({
  dateForDaySummary,
  setDateForDaySummary,
  level = 'FIRST',
  filters,
}: IPreviewDayAppointmentsModalProps) => {
  const { t } = useTranslation();

  const dayAppointmentsRef = useRef<HTMLDivElement>(null);

  const { data: appointmentsData, isLoading: isLoadingDaySummaryAppointments } =
    useGetDaySummaryAppointments(
      dateForDaySummary,
      filters?.users,
      filters?.workers,
      filters?.resources,
      filters?.selectedTypes
    );

  const isOpen: boolean = dateForDaySummary !== null;
  if (!isOpen) {
    return <></>;
  }

  const appointmentSummariesForSelectedDayId: IAppointmentSummaryData[] =
    appointmentsData?.appointments || [];

  const handlePrint = useReactToPrint({
    content: () => dayAppointmentsRef.current,
    documentTitle: '',
    onAfterPrint: () => console.log('Report PDF downloaded successfully'),
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (!boolean) {
          setDateForDaySummary(null);
        }
      }}
      level={level}
      modalStyle={{ position: 'fixed', margin: 'auto', width: '500rem' }}
    >
      <CloseContainer>
        <Close onClick={() => setDateForDaySummary(null)} />
      </CloseContainer>
      {!appointmentSummariesForSelectedDayId.length ? (
        isLoadingDaySummaryAppointments ? (
          <LoaderWrapper>
            <Spinner />
          </LoaderWrapper>
        ) : (
          <NoContentLabel>
            {t('There are currently no appointments for the selected day') +
              '.'}
          </NoContentLabel>
        )
      ) : (
        <>
          <IconWrapper>
            <Icon
              svg={DownloadSimple}
              color={COLORS.PRIMARY}
              size={25}
              marginRight={marginLg}
              marginLeft={marginXs}
              onClick={() => {
                handlePrint();
              }}
            />
          </IconWrapper>
          <Scrollable>
            <PrintWrapper ref={dayAppointmentsRef}>
              {appointmentSummariesForSelectedDayId.map(
                (
                  appointmentSummary: IAppointmentSummaryData,
                  index: number
                ) => (
                  <PreviewDayAppointmentCard
                    key={index}
                    appointmentSummary={appointmentSummary}
                    isLast={
                      appointmentSummariesForSelectedDayId.length - 1 <= index
                    }
                  />
                )
              )}
            </PrintWrapper>
          </Scrollable>
        </>
      )}
    </Modal>
  );
};
