import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  isSideMenuCollapsed: boolean;
  isFieldWorker: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 60rem;
  padding: 0rem 50rem;
  width: calc(100% - 265rem);
  background-color: ${COLORS.WHITE};
  position: fixed;
  top: 75rem;
  left: 265rem;
  right: 0;
  border-bottom: 1px solid ${COLORS.GREY_200};
  z-index: 5;
  transition: all 0.3s;
  justify-content: space-between;
  align-items: center;

  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      left: 115rem;
      width: calc(100% - 115rem);
    `}
  ${(props: StyledProps) =>
    props.isFieldWorker &&
    css`
      left: 0;
      width: 100%;
    `}

  ${respondTo.midTablet`
    padding: 0 30rem;
  `}

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    left: 0;
    padding: 0 15rem;
    height: 50rem;
    top: 50rem;
  `}

  ${respondTo.smallPhone`
    height: auto;
    flex-direction: column;
  `}
`;
export const RowGap = styled.div`
  display: flex;
  align-items: center;
  gap: 20rem;

  ${respondTo.smallPhone`
    padding-bottom: 10rem;
    padding-top: 10rem;
    width: 100%;
    justify-content: center;
  `}
`;

export const Span = styled.span`
  font-size: 14rem;
  color: ${COLORS.BLACK};
`;
export const Type = styled(Span)`
  color: ${COLORS.PRIMARY};
`;

export const SelectWrapper = styled.div`
  width: 180rem;
  height: 41rem;
`;

export const LinkText = styled.a`
  font-size: 14rem;
  color: ${COLORS.PRIMARY};
  text-decoration: underline;
  cursor: pointer;
`;
