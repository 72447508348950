import { ISubMenuItem } from 'components/Layout/Navigation/SubSideMenu/type';
import { Option } from 'components/Select/type';
import { SalesPurchaseOrderInvoiceRoutes } from 'navigation/SingleInvoice/PurchaseInvoice/SinglePurchaseInvoice.routes';

export const statusOptions: Option[] = [
  { value: '', label: 'All', key: 'all' },
  { value: 'NEW', label: 'New', key: 'new' },
  { value: 'SENT', label: 'Sent', key: 'sent' },
  { value: 'PARTIALLY_PAID', label: 'Partially paid', key: 'partially-paid' },
  { value: 'FULLY_PAID', label: 'Fully paid', key: 'fully-paid' },
];

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceGeneral.path,
  },
  {
    text: 'Purchase Orders',
    route:
      SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoicePurchaseOrders.path,
  },
  {
    text: 'Credit Invoices',
    route:
      SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceCreditInvoices.path,
  },
  {
    text: 'Payments',
    route: SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoicePayments.path,
  },
  {
    text: 'Files',
    route: SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoiceFiles.path,
  },
];
