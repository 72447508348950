import { COLORS, H4 } from 'assets/styled';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IProductAttribute } from 'types/Product.types';
import { ISalesOrderLine } from 'types/SalesOrders.types';
import { formatAttributeObjectIntoArray } from 'utils/productAttributeFormatting/formatAttributeObjectIntoArray';
import { formatNumber } from 'utils/numberFormatter';
import { getProductCardTitle, getProductCardType } from '../helpers';
import {
  AttributesWrapper,
  Card,
  ColumnContainer,
  CostContainerAbsolute,
  CostLabel,
  CostLabelTitle,
  DeleteIcon,
  DeleteLabel,
  DescriptionContainer,
  EditIcon,
  EditLabel,
  GreenLine,
  Label,
  LabelTitle,
  LogyxAttributesLabel,
  MainContainer,
  TextColumnContainer,
  TextContainer,
  TitleContainer,
  TitleWrapper,
  TypeLabel,
  EditDeleteWrapperRelative,
  WrapperRelative,
  ActionsRow,
  TitleLabel,
} from './SalesOrderProductCard.styled';
import { formatLogyxAttributesObjectIntoArray } from 'utils/productAttributeFormatting/formatLogyxAttributesObjectIntoArray';
import { ILogyxAttribute, QuestionType } from 'store/Logyx/types';
import Icon from 'components/Icon/Icon';
import useCan from 'utils/hooks/useCan';
import { Actions } from 'types/Permissions.types';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { CaretDown, CaretUp, PencilSimple, Trash } from '@phosphor-icons/react';
import { getBooleanAttributeLabel } from 'components/DragAndDrop/DraggableLines/helpers';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';
import MobileActionDropdown from 'components/MobileActionDropdown/MobileActionDropdown';

interface ISalesOrderProductCardProps {
  salesOrderLine: ISalesOrderLine;
  onDeleteClick?: () => void;
  onEditClick?: () => void;
}

export const SalesOrderProductCard = ({
  salesOrderLine,
  onDeleteClick,
  onEditClick,
}: ISalesOrderProductCardProps) => {
  const { t } = useTranslation();
  const currencySymbol = useGetCurrencySymbol();
  const { isPhone } = useBreakpointFlag();

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const canEditSalesOrder = useCan(Actions.EDIT_SALES_ORDER);

  const attributes = formatAttributeObjectIntoArray(
    salesOrderLine.product?.attributes
  );
  const logyxAttributes = formatLogyxAttributesObjectIntoArray(
    salesOrderLine.product?.logyx_configuration_intent?.data
  );
  const allAttributes = [...logyxAttributes, ...attributes];

  const renderProductAttributes = () => {
    return allAttributes.map(
      (attribute: IProductAttribute | ILogyxAttribute, index: number) => {
        return (
          <AttributesWrapper key={index}>
            {logyxAttributes.length && index === 0 ? (
              <>
                <GreenLine />
                <LogyxAttributesLabel>
                  {t('Logyx Attributes')}
                </LogyxAttributesLabel>
              </>
            ) : null}
            <TextColumnContainer>
              <LabelTitle>{attribute.fieldName}</LabelTitle>
              <Label>
                {attribute.type === QuestionType.BOOLEAN
                  ? t(getBooleanAttributeLabel(attribute.value))
                  : attribute.value}
              </Label>
            </TextColumnContainer>
            {logyxAttributes.length && index === logyxAttributes.length - 1 ? (
              <GreenLine />
            ) : null}
          </AttributesWrapper>
        );
      }
    );
  };
  return (
    <Card>
      <TitleContainer>
        <TitleWrapper>
          <TitleLabel>{getProductCardTitle(salesOrderLine, t)}</TitleLabel>
          <TypeLabel>{getProductCardType(salesOrderLine, t)}</TypeLabel>
        </TitleWrapper>
        <ActionsRow>
          {isPhone && (
            <MobileActionDropdown
              onEditClick={onEditClick}
              onDeleteClick={onDeleteClick}
              canUseAction={canEditSalesOrder}
            />
          )}
          {!isPhone && (
            <>
              {onEditClick && canEditSalesOrder && (
                <EditDeleteWrapperRelative onClick={() => onEditClick()}>
                  <EditLabel
                    data-testid={`edit-${getProductCardTitle(
                      salesOrderLine,
                      t
                    )}`}
                  >
                    {t('Edit')}
                  </EditLabel>
                  <EditIcon>
                    <Icon svg={PencilSimple} color={COLORS.PRIMARY} size={15} />
                  </EditIcon>
                </EditDeleteWrapperRelative>
              )}
              {onDeleteClick && canEditSalesOrder && (
                <EditDeleteWrapperRelative onClick={() => onDeleteClick()}>
                  <DeleteLabel
                    data-testid={`delete-${getProductCardTitle(
                      salesOrderLine,
                      t
                    )}`}
                  >
                    {t('Delete')}
                  </DeleteLabel>
                  <DeleteIcon>
                    <Icon
                      svg={Trash}
                      size={18}
                      color={COLORS.RED_200}
                      weight="regular"
                    />
                  </DeleteIcon>
                </EditDeleteWrapperRelative>
              )}
            </>
          )}
          {!salesOrderLine.working_hours_line && (
            <WrapperRelative>
              <Icon
                data-testid={`expand-${getProductCardTitle(salesOrderLine, t)}`}
                svg={isExpanded ? CaretUp : CaretDown}
                color={COLORS.PRIMARY}
                size={20}
                weight="regular"
                onClick={() => setIsExpanded(!isExpanded)}
              />
            </WrapperRelative>
          )}
        </ActionsRow>
      </TitleContainer>
      {salesOrderLine.description && (
        <DescriptionContainer>
          <TextContainer>
            <Label>{salesOrderLine.description}</Label>
          </TextContainer>
        </DescriptionContainer>
      )}
      {!salesOrderLine.working_hours_line && !salesOrderLine.service && (
        <MainContainer $isExpanded={isExpanded}>
          <ColumnContainer $isExpanded={isExpanded}>
            <H4>{t('Internal note').toUpperCase()}</H4>
            <TextContainer>
              <Label>{salesOrderLine.placement}</Label>
            </TextContainer>
          </ColumnContainer>
          {isExpanded && (
            <ColumnContainer $isExpanded={isExpanded}>
              <H4>{t('General').toUpperCase()}</H4>
              <TextColumnContainer>
                <LabelTitle>{t('Status')}</LabelTitle>
                <Label>
                  {t(
                    salesOrderLine?.sub_status?.name
                      .toLowerCase()
                      .replace(/_/g, ' ')
                      .charAt(0)
                      .toUpperCase() +
                      salesOrderLine?.sub_status?.name
                        .toLowerCase()
                        .replace(/_/g, ' ')
                        .slice(1)
                  )}
                </Label>
              </TextColumnContainer>
              {salesOrderLine?.stock_item?.type && (
                <TextColumnContainer>
                  <LabelTitle>{t('Item type')}</LabelTitle>
                  <Label>
                    {salesOrderLine?.stock_item?.type &&
                      salesOrderLine?.stock_item?.type?.name.charAt(0) +
                        salesOrderLine?.stock_item?.type?.name
                          .slice(1)
                          .toLowerCase()}
                  </Label>
                </TextColumnContainer>
              )}
              <TextColumnContainer>
                <LabelTitle>{t('Supplier')}</LabelTitle>
                <Label>{salesOrderLine.supplier?.company_name}</Label>
              </TextColumnContainer>
              {salesOrderLine?.stock_item_line && (
                <TextColumnContainer>
                  <LabelTitle>{t('Item supplier number')}</LabelTitle>
                  <Label>{salesOrderLine?.stock_item?.number_supplier}</Label>
                </TextColumnContainer>
              )}
              <TextColumnContainer>
                <LabelTitle>{t('Quantity')}</LabelTitle>
                <Label>{salesOrderLine.quantity}</Label>
              </TextColumnContainer>
              <TextColumnContainer>
                <LabelTitle>{t('Sales price')}</LabelTitle>
                <Label>{`${currencySymbol}${formatNumber(
                  salesOrderLine?.product_sales_price
                )}`}</Label>
              </TextColumnContainer>
              <TextColumnContainer>
                <LabelTitle>{t('Total discount')}</LabelTitle>
                <Label>{`${currencySymbol}${
                  salesOrderLine?.discount_amount
                    ? formatNumber(salesOrderLine?.discount_amount)
                    : '0'
                }`}</Label>
              </TextColumnContainer>
              <TextColumnContainer>
                <LabelTitle>{t('Total purchase cost exc. VAT')}</LabelTitle>
                <Label>
                  {`${currencySymbol}${formatNumber(
                    Number(salesOrderLine?.product_purchase_price) *
                      Number(salesOrderLine?.quantity)
                  )}`}
                </Label>
              </TextColumnContainer>
            </ColumnContainer>
          )}
          {isExpanded && (
            <ColumnContainer $isExpanded={isExpanded}>
              <H4>{t('Attributes').toUpperCase()}</H4>
              {renderProductAttributes()}
            </ColumnContainer>
          )}
        </MainContainer>
      )}
      {salesOrderLine.service && (
        <>
          <TextColumnContainer>
            <LabelTitle>{t('Quantity')}</LabelTitle>
            <Label>{salesOrderLine.quantity}</Label>
          </TextColumnContainer>
          <TextColumnContainer>
            <LabelTitle>{t('Amount')}</LabelTitle>
            <Label>{salesOrderLine.service.amount}</Label>
          </TextColumnContainer>
          <TextColumnContainer>
            <LabelTitle>{t('Description')}</LabelTitle>
            <Label>{salesOrderLine.service.description}</Label>
          </TextColumnContainer>
        </>
      )}
      {salesOrderLine.working_hours_line && (
        <>
          <TextColumnContainer>
            <LabelTitle>{t('Rate')}</LabelTitle>
            <Label>{`${currencySymbol}${formatNumber(
              salesOrderLine.working_hours_rate
            )}`}</Label>
          </TextColumnContainer>
          <TextColumnContainer>
            <LabelTitle>{t('Hours')}</LabelTitle>
            <Label>{`${formatNumber(salesOrderLine.quantity)}`}</Label>
          </TextColumnContainer>
        </>
      )}
      <CostContainerAbsolute $isExpanded={isExpanded}>
        <CostLabelTitle>{t('Total amount')}</CostLabelTitle>
        <CostLabel>{`${currencySymbol}${formatNumber(
          salesOrderLine.total_amount
        )}`}</CostLabel>
      </CostContainerAbsolute>
    </Card>
  );
};
