export const config = {
  environment: process.env.REACT_APP_ENVIRONMENT,
  version: process.env.REACT_APP_VERSION,
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
  },
  logyx: {
    logyxFormsUrl: process.env.REACT_APP_LOGYX_FORMS_APP_URL,
    logyxDealerPanelUrl: process.env.REACT_APP_LOGYX_DEALER_PANEL_APP_URL,
  },
  sentry: {
    dsnUrl: process.env.REACT_APP_SENTRY_DSN_URL,
  },
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
};

export enum EnvironmentType {
  LOCAL = 'local',
  DEV = 'dev',
  STAGING = 'staging',
  PROD = 'prod',
}
