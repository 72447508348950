import {
  borderRadiusSm,
  COLORS,
  marginLg,
  marginMd,
  paddingLg,
  paddingMd,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${paddingLg};

  ${respondTo.horizontalTablet`
    padding: ${paddingMd};
  `}

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10rem;
  justify-content: space-between;
  margin-bottom: ${marginLg};
  gap: 20rem;

  ${respondTo.smallTablet`
    margin-bottom: 20rem;
  `}
`;

export const H3 = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 24rem;
  padding-right: 30rem;
`;

export const LeftSide = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${respondTo.smallTablet`
    width: 100%;
  `}
`;

export const RightSide = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20rem;
`;

export const CardsWrapperRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1075rem;
  width: 100%;
  gap: 50rem;

  ${respondTo.midTablet`
    gap: 30rem;
  `}

  ${respondTo.smallTablet`
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20rem;
  `}

  ${respondTo.bigPhone`
    gap: 15rem;
    justify-content: center;
  `}
`;

export const BottomCardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1075rem;
  width: 100%;
  gap: 50rem;

  ${respondTo.midTablet`
    gap: 30rem;
  `}

  ${respondTo.smallTablet`
    flex-direction: column;
    max-width: 100%;
    gap: 20rem;
  `}
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Separator50 = styled.div`
  height: ${marginLg};

  ${respondTo.midTablet`
    height: 30rem;
  `}

  ${respondTo.smallTablet`
    height: 20rem;
  `}
`;

export const DescriptionCardWrapper = styled.div`
  max-width: 625rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};
  align-self: start;

  ${respondTo.smallTablet`
    max-width: 100%;
  `}
`;

export const DescriptionTitle = styled.label`
  font-size: 24rem;
  line-height: 36rem;
  font-weight: 300;
  margin-bottom: ${marginMd};
`;

export const DescriptionValueLabel = styled.label`
  font-weight: 300;
  font-size: 14rem;
  line-height: 21rem;
  word-break: break-word;
`;

export const StockItemIsActiveLabel = styled.label`
  padding-left: ${marginMd};
  color: ${COLORS.PRIMARY};
  font-weight: 300;
  font-size: 18rem;
  margin-right: 3rem;
`;

export const ThreeDots = styled.div`
  margin-left: auto;
`;
