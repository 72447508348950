import { ISubMenuItem } from 'components/Layout/Navigation/SubSideMenu/type';
import { SingleSalesInvoiceRoutes } from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';

export const subMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoiceGeneral.path,
  },
  {
    text: 'Sales orders',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoiceSalesOrders.path,
  },
  {
    text: 'Credit Invoices',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoiceCreditInvoice.path,
  },
  {
    text: 'Payments',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoicePayments.path,
  },
  {
    text: 'Files',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoiceFiles.path,
  },
];

export const downpaymentSubMenuItems: ISubMenuItem[] = [
  {
    text: 'General',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoiceGeneral.path,
  },
  {
    text: 'Sales Orders',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoiceSalesOrders.path,
  },
  {
    text: 'Payments',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoicePayments.path,
  },
  {
    text: 'Files',
    route: SingleSalesInvoiceRoutes.SingleSalesInvoiceFiles.path,
  },
];

export const SENT_STATUS = 'SENT';
export const FULLY_PAID = 'FULLY_PAID';
export const CANCELLED_STATUS = 'CANCELLED';
