import { COLORS, gapLg, marginSm, marginXs } from 'assets/styled';
import { RowGap, SectionTitle } from '../ReportForm.styled';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input/Input';
import {
  ClearSignatureLabel,
  ColumnGap,
  Container,
  InputWrapper,
  RelativeCanvasWrapper,
  SignatureErrorLabel,
  SignatureImage,
} from './SignatureAndCommentsSection.styled';
import SignatureCanvas from 'react-signature-canvas';
import { Dispatch, SetStateAction } from 'react';
import {
  IReportPageErrors,
  validateFullName,
  validateSignature,
} from '../validation';

interface ISignatureAndCommentsSectionProps {
  isReadOnly: boolean;
  sigRef: any;
  signature: string;
  setSignature: Dispatch<SetStateAction<string>>;
  handleSignatureEnd: () => void;
  furtherComments: string;
  setFurtherComments: Dispatch<SetStateAction<string>>;
  fullName: string;
  setFullName: Dispatch<SetStateAction<string>>;
  errors: IReportPageErrors;
  setErrors: Dispatch<SetStateAction<IReportPageErrors>>;
  isMeasuring: boolean;
}

const SignatureAndCommentsSection = ({
  isReadOnly,
  sigRef,
  signature,
  setSignature,
  handleSignatureEnd,
  furtherComments,
  setFurtherComments,
  fullName,
  setFullName,
  errors,
  setErrors,
  isMeasuring,
}: ISignatureAndCommentsSectionProps) => {
  const { t } = useTranslation();
  return (
    <Container>
      <SectionTitle marginBottom={marginSm}>
        {t('CUSTOMER SIGNATURE AND COMMENTS')}
      </SectionTitle>
      <RowGap gap={gapLg} alignItems="flex-start">
        <InputWrapper>
          <ColumnGap>
            <Input
              disabled={isReadOnly}
              error={!!errors.fullName}
              errorMessage={errors.fullName}
              height={'41rem'}
              placeholder={t('Full name')}
              value={fullName}
              onChange={(e) => {
                const newValue = e.target.value;
                setFullName(newValue);
                const error = validateFullName(newValue, t);
                setErrors((prevErrors) => ({ ...prevErrors, fullName: error }));
              }}
              wrapperStyles={{
                marginBottom: marginSm,
              }}
            />
            <Input
              disabled={isReadOnly}
              height={'190rem'}
              isTextArea
              value={furtherComments}
              placeholder={
                isReadOnly ? '' : t('Any further comments about the service')
              }
              onChange={(e) => {
                const newValue = e.target.value;
                setFurtherComments(newValue);
              }}
            />
          </ColumnGap>
        </InputWrapper>
        <ColumnGap>
          <RelativeCanvasWrapper>
            {isReadOnly ? (
              <SignatureImage src={signature} alt="signature" />
            ) : (
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  className: `report-signature ${
                    errors.signature ? 'signature-red-border' : ''
                  }`,
                }}
                ref={sigRef}
                onEnd={handleSignatureEnd}
                clearOnResize={false}
              />
            )}
            <SignatureErrorLabel>{errors.signature}</SignatureErrorLabel>
            {signature && !isReadOnly ? (
              <ClearSignatureLabel
                onClick={() => {
                  sigRef.current.clear();
                  setSignature('');
                  const error = isMeasuring ? '' : validateSignature('', t);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    signature: error,
                  }));
                }}
              >
                {t('Clear signature')}
              </ClearSignatureLabel>
            ) : null}
          </RelativeCanvasWrapper>
        </ColumnGap>
      </RowGap>
    </Container>
  );
};

export default SignatureAndCommentsSection;
