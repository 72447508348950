import {
  borderRadiusSm,
  COLORS,
  marginMd,
  marginXs,
  paddingMd,
} from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50rem;
  width: 100%;
  max-width: 1500rem;
  height: 100%;
  align-items: center;
`;

export const SingleCreditInvoiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${COLORS.WHITE};
  border-radius: ${borderRadiusSm};
  padding: ${paddingMd};

  ${respondTo.smallTablet`
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const RowSpaceBetween = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StatusLabel = styled.label`
  font-weight: 300;
  font-size: 24rem;
  line-height: 36rem;
  color: ${COLORS.PRIMARY};
`;

export const InvoiceDescriptionLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  margin-top: ${marginXs};
`;

export const RowSpaceEvenlyMarginTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: ${marginMd};

  ${respondTo.smallTablet`
    margin: 15rem 0;
    gap: 20rem;
    text-align: center;
  `}
`;

export const SmallColumnDiv = styled.div`
  position: relative;
  height: 60rem;
  width: 180rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${respondTo.smallTablet`
    height: auto;
    gap: 15rem;
  `}
`;

export const SmallColumnDivLabel = styled.label`
  font-weight: 300;
  font-size: 16rem;
`;

export const SmallColumnDivLabelValue = styled.label`
  font-weight: 500;
  font-size: 22rem;
  line-height: 22rem;
  color: ${COLORS.PRIMARY};
  text-align: center;
`;

export const NoContentLabel = styled.label`
  margin-top: 100rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
`;
