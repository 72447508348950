import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction } from 'react';
import {
  ActionsIcons,
  ActionsWrapper,
  ButtonsWrapper,
  CloseContainer,
  Container,
  LoaderWrapper,
  Title,
  TitleContainer,
} from './ActionsMobileModal.styled';
import { Close } from 'components/Close/Close';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import { DownloadSimple, EnvelopeSimple } from '@phosphor-icons/react';
import { COLORS, marginXs } from 'assets/styled';
import { ReactComponent as MailSent } from 'assets/icons/mail-sent.svg';
import Spinner from 'components/Spinner/Spinner';

interface IActionsMobileModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  data: any;
  canEditSalesInvoice: boolean;
  isLoadingExport: boolean;
  exportSalesInvoice: any;
  setIsSendEmailModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsPaymentModalOpen: Dispatch<SetStateAction<boolean>>;
}

const ActionsMobileModal = ({
  isOpen,
  setIsOpen,
  data,
  canEditSalesInvoice,
  isLoadingExport,
  exportSalesInvoice,
  setIsSendEmailModalOpen,
  setIsPaymentModalOpen,
}: IActionsMobileModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <Container>
        <CloseContainer>
          <Close
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </CloseContainer>
        <TitleContainer>
          <Title>
            {t('Credit invoice')}&nbsp;
            {data?.invoice_number}
          </Title>
        </TitleContainer>
        <ActionsWrapper>
          <ActionsIcons>
            {isLoadingExport ? (
              <LoaderWrapper>
                <Spinner size={20} />
              </LoaderWrapper>
            ) : (
              <Icon
                data-testid="download-pdf"
                svg={DownloadSimple}
                color={COLORS.PRIMARY}
                size={25}
                onClick={() => exportSalesInvoice(data?.id)}
              />
            )}

            <Icon
              data-testid="send-email"
              svg={data?.sent_to_customer ? MailSent : EnvelopeSimple}
              size={25}
              onClick={() => setIsSendEmailModalOpen(true)}
              color={COLORS.PRIMARY}
              marginLeft={marginXs}
              marginRight={marginXs}
            />
          </ActionsIcons>
        </ActionsWrapper>
        <ButtonsWrapper>
          <Button
            label={t('Add Payment')}
            secondary
            disabled={!canEditSalesInvoice || !data?.open_amount}
            width="100%"
            onClick={() => setIsPaymentModalOpen(true)}
          />
        </ButtonsWrapper>
      </Container>
    </Modal>
  );
};

export default ActionsMobileModal;
