import styled, { css } from 'styled-components';
import { COLORS } from 'assets/styled/colors';
import { marginLg } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { Badge } from '@mui/material';

interface StyledProps {
  isSideMenuCollapsed: boolean;
  isFieldWorker: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: ${COLORS.WHITE};
  width: calc(100% - 265rem);
  padding: 0rem 50rem;
  height: 75rem;
  position: fixed;
  top: 0;
  left: 265rem;
  right: 0;
  border-bottom: 1px solid ${COLORS.GREY_200};
  z-index: 10;
  transition: all 0.3s;
  ${(props: StyledProps) =>
    props.isSideMenuCollapsed &&
    css`
      left: 115rem;
      width: calc(100% - 115rem);
    `}
  ${(props: StyledProps) =>
    props.isFieldWorker &&
    css`
      left: 0;
      width: 100%;
    `}

  ${respondTo.midTablet`
    padding: 0 30rem;
  `}

  ${respondTo.smallTablet`
    width: 100%;
    max-width: 100%;
    left: 0;
    padding: 0 15rem;
    height: 50rem;
  `}
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const LeftContainer = styled(RightContainer)``;

export const FwBlueprintLogoImg = styled.img`
  width: 150rem;
  cursor: pointer;
  margin-right: ${marginLg};

  ${respondTo.smallTablet`
    max-width: 120rem;
    width: 100%;
    margin-right: 0;
  `}
`;

export const ImageAvatar = styled.img`
  width: fit-content;
  max-width: 47rem;
  max-height: 47rem;
  cursor: pointer;
`;

export const ImageAvatarWrapper = styled.div`
  overflow: hidden;
  width: 47rem;
  height: 47rem;
  margin-left: 40rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  ${respondTo.smallTablet`
    margin-left: 20rem;
  `}
`;

export const IconWrapper = styled.div`
  margin-left: 50rem;

  ${respondTo.smallTablet`
    margin-left: 20rem;
  `}
`;

export const StyledBadge = styled(Badge)`
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; // Remove tap highlight gray rectangle
`;
