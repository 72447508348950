import styled from 'styled-components';
import { Field } from 'formik';
import { COLORS, borderRadiusSm, marginMd } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  width: 690rem;
  background-color: ${COLORS.WHITE};
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};

  ${respondTo.bigPhone`
    max-width: 100%;
    padding: 20rem;
  `}
`;

export const FormikContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;
  width: 100%;

  ${respondTo.bigPhone`
    flex-wrap: wrap;
    gap: 15rem;
  `}
`;

export const StyledSelect = styled(Field)`
  margin-left: ${marginMd};
  width: 200rem;
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 24rem;
`;

export const Margin = styled.div`
  margin-left: ${marginMd};
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};
`;
