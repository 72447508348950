import { COLORS, borderRadiusSm, marginMd, marginSm } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import styled, { css } from 'styled-components';

interface StyledProps {
  marginTop: string;
}

export const Container = styled.div`
  padding: 30rem;
  display: flex;
  max-width: 690rem;
  width: 100%;
  background-color: ${COLORS.WHITE};
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${borderRadiusSm};
  margin-bottom: ${marginMd};

  ${respondTo.smallTablet`
    max-width: calc(100% - 30rem);
    min-height: auto;
    padding: 20rem;
  `}

  ${respondTo.bigPhone`
    padding: 15rem;
  `}
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${marginMd};
`;

export const SubmitRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: ${marginMd};
`;

export const Margin = styled.div`
  margin-left: ${marginMd};
`;

export const RowMarginTop = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 30rem;

  ${respondTo.bigPhone`
    flex-direction: column;
    gap: 20rem;
  `}

  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${(props: StyledProps) => props.marginTop};
    `}
`;

export const FieldWrapper = styled.div`
  padding-right: 15rem;
  width: 50%;

  ${respondTo.bigPhone`
    padding-right: 0;
    width: 100%;
  `}
`;

export const SupplierDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.label`
  font-size: 18rem;
  font-weight: 300;
  line-height: 27rem;
  text-transform: uppercase;
  margin-bottom: ${marginSm};
`;

export const Line = styled.div`
  border-bottom: 1rem solid ${COLORS.GREY_200};
  display: flex;
  width: 100%;
  margin-top: ${marginMd};
  margin-bottom: ${marginSm};
`;
