import { COLORS } from 'assets/styled';
import styled from 'styled-components';

export const CloseContainer = styled.div`
  position: absolute;
  right: 20rem;
  top: 20rem;
`;

export const Scrollable = styled.div`
  max-height: calc(75vh);
  overflow-y: scroll;
`;

export const NoContentLabel = styled.label`
  margin-top: 30rem;
  margin-bottom: 30rem;
  text-align: center;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.BLACK};
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PrintWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  // These styles will only be applied when printing
  @media print {
    background-color: ${COLORS.WHITE};
    padding: 10mm;
    @page {
      size: auto; /* auto is the initial value */

      /* this affects the margin in the printer settings */
      margin: 10mm 10mm 10mm 10mm;
    }
  }
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
