import Button from 'components/Button/Button';
import { Modal } from 'components/Modal/Modal';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AddPaymentLabel,
  ButtonWrapper,
  CloseAbsoluteWrapper,
  Container,
  DatePickerWrap,
  FitContent,
  FormContainer,
  SelectInputWrapper,
} from './AddPaymentModal.styled';
import { Close } from 'components/Close/Close';
import Input from 'components/Input/Input';
import { marginMd } from 'assets/styled';
import { Select } from 'components/Select/Select';
import { Option } from 'components/Select/type';
import { InvoiceType } from './constants';
import {
  useAddSalesInvoicePayment,
  useAddPurchaseInvoicePayment,
  useGetPaymentOptions,
} from './hooks';
import { useNavigate } from 'react-router-dom';
import { isNumeric } from 'utils/validation';
import { SingleSalesInvoiceRoutes } from 'navigation/SingleInvoice/SalesInvoice/SingleSalesInvoice.routes';
import { SalesPurchaseOrderInvoiceRoutes } from 'navigation/SingleInvoice/PurchaseInvoice/SinglePurchaseInvoice.routes';
import DatePicker from 'components/DatePicker/DatePicker';
import moment from 'moment';
import { useGetCurrencySymbol } from 'utils/hooks/useGetCurrencySymbol';
import { useBreakpointFlag } from 'utils/hooks/useBreakpointFlag';

export interface IAddPaymentModalProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  invoiceId: number;
  invoiceType: InvoiceType;
  initialPaymentAmount: number;
  fromOverview?: boolean;
}

export function AddPaymentModal({
  initialPaymentAmount,
  isOpen,
  setIsOpen,
  invoiceId,
  invoiceType,
  fromOverview,
}: IAddPaymentModalProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currencySymbol = useGetCurrencySymbol();
  const [amount, setAmount] = useState<string | null>(
    initialPaymentAmount ? initialPaymentAmount.toString() : null
  );
  const [amountError, setAmountError] = useState<string>('');
  const [paymentTypeId, setPaymentTypeId] = useState<number | null>(null);
  const [reference, setReference] = useState<string>('');
  const dateRef = useRef<any>(null);
  const paymentTypeOptions = useGetPaymentOptions();

  const { isPhone, isSmallPhone } = useBreakpointFlag();

  useEffect(() => {
    if (initialPaymentAmount && isOpen) {
      handleValidateAmountInput(initialPaymentAmount.toString());
      setAmount(initialPaymentAmount.toString());
    }
  }, [initialPaymentAmount, isOpen]);

  useEffect(() => {
    const keyDownHandler = (event: any) => {
      if (event.key === 'Enter' && isOpen) {
        handleAddPayment();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [isOpen]);

  const {
    mutate: addSalesInvoicePayment,
    isLoading: addSalesInvoicePaymentIsLoading,
    isSuccess: addSalesInvoicePaymentIsSuccess,
  } = useAddSalesInvoicePayment();
  const {
    mutate: addPurchaseInvoicePayment,
    isLoading: addPurchaseInvoicePaymentIsLoading,
    isSuccess: addPurchaseInvoicePaymentIsSuccess,
  } = useAddPurchaseInvoicePayment();

  const handleAddPayment = () => {
    if (amountError !== '') {
      return;
    }
    switch (invoiceType) {
      case InvoiceType.SALES_INVOICE:
      case InvoiceType.CREDIT_SALES_INVOICE:
        if (amount && paymentTypeId && invoiceId) {
          addSalesInvoicePayment({
            amount: Number(amount),
            paymentTypeId,
            invoiceId,
            date: dateRef.current,
            reference,
          });
        }
        break;
      case InvoiceType.PURCHASE_INVOICE:
      case InvoiceType.CREDIT_PURCHASE_INVOICE:
        if (amount && paymentTypeId && invoiceId) {
          addPurchaseInvoicePayment({
            amount: Number(amount),
            paymentTypeId,
            invoiceId,
            date: dateRef.current,
            reference,
          });
        }
        break;
    }
  };

  useEffect(() => {
    if (addSalesInvoicePaymentIsSuccess || addPurchaseInvoicePaymentIsSuccess) {
      setIsOpen(false);
      setAmount(null);
      setPaymentTypeId(null);

      if (fromOverview) return;

      if (addSalesInvoicePaymentIsSuccess) {
        navigate(SingleSalesInvoiceRoutes.SingleSalesInvoicePayments.path);
      } else if (addPurchaseInvoicePaymentIsSuccess) {
        navigate(
          SalesPurchaseOrderInvoiceRoutes.SinglePurchaseInvoicePayments.path
        );
      }
    }
  }, [addSalesInvoicePaymentIsSuccess, addPurchaseInvoicePaymentIsSuccess]);

  const handleValidateAmountInput = (newAmount: string) => {
    if (!isNumeric(newAmount)) {
      setAmountError('Amount must be a number');
      return;
    }
    if (
      [
        InvoiceType.CREDIT_SALES_INVOICE,
        InvoiceType.CREDIT_PURCHASE_INVOICE,
      ].includes(invoiceType)
    ) {
      if (Number(newAmount) >= 0) {
        setAmountError('Amount must be a negative number');
        return;
      }
    } else {
      if (Number(newAmount) <= 0) {
        setAmountError('Amount must be a positive number');
        return;
      }
    }
    setAmountError('');
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={(boolean) => {
        if (!boolean) {
          setAmount(null);
          setPaymentTypeId(null);
        }
        setIsOpen(boolean);
      }}
      modalStyle={{ margin: 'auto', position: 'fixed', overflow: 'visible' }} // Center positioning
    >
      <Container>
        <AddPaymentLabel>{t('Add Payment')}</AddPaymentLabel>
        <FormContainer>
          <SelectInputWrapper>
            <Select
              pwId="select-payment-type"
              name="paymentType"
              placeholder={t('Payment type')}
              isMulti={false}
              isDisabled={false}
              isSearchable={false}
              onChange={(e: Option) => setPaymentTypeId(Number(e.value))}
              options={paymentTypeOptions}
            />
          </SelectInputWrapper>
          <FitContent>
            <Input
              pwId="amount-field"
              error={amountError !== ''}
              errorMessage={amountError}
              defaultValue={initialPaymentAmount}
              symbol={currencySymbol}
              placeholder={t('Amount')}
              width={isPhone ? '100%' : '300rem'}
              height="41rem"
              value={amount?.toString()}
              onChange={(e) => {
                handleValidateAmountInput(e.target.value);
                setAmount(e.target.value);
              }}
              wrapperStyles={{ marginBottom: marginMd }}
            />
          </FitContent>
          <FitContent>
            <Input
              pwId="reference-field"
              placeholder={t('Reference')}
              width={isPhone ? '100%' : '300rem'}
              height="41rem"
              value={reference?.toString()}
              onChange={(e) => {
                setReference(e.target.value);
              }}
              wrapperStyles={{ marginBottom: marginMd }}
            />
          </FitContent>
          <DatePickerWrap>
            <DatePicker
              width={isPhone ? '100%' : '300rem'}
              pwId="date-field"
              date={moment(dateRef.current)}
              setDate={(newValue: any) => {
                dateRef.current = newValue
                  ? newValue.format('YYYY-MM-DD')
                  : null;
              }}
            />
          </DatePickerWrap>
        </FormContainer>
        <ButtonWrapper>
          <Button
            label={t('Cancel')}
            secondary
            width={isSmallPhone ? '150rem' : '200rem'}
            onClick={() => {
              setIsOpen(false);
              setAmount(null);
              setPaymentTypeId(null);
            }}
          />
          <Button
            label={t('Add')}
            primary
            width={isSmallPhone ? '150rem' : '200rem'}
            onClick={handleAddPayment}
            disabled={
              !paymentTypeId ||
              !amount ||
              addSalesInvoicePaymentIsLoading ||
              addPurchaseInvoicePaymentIsLoading ||
              !!amountError
            }
          />
        </ButtonWrapper>
        <CloseAbsoluteWrapper>
          <Close
            onClick={() => {
              setIsOpen(false);
              setAmount(null);
              setPaymentTypeId(null);
            }}
          />
        </CloseAbsoluteWrapper>
      </Container>
    </Modal>
  );
}
