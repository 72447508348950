import { Option } from 'components/Select/type';
import { useMemo, useState } from 'react';
import { IUser } from 'types/User.types';
import { IWorkerAndResource } from '../WorkersModal/type';
import { InfiniteData } from 'react-query';
import { useGetSingleQuotation } from 'pages/Manager/SingleQuotation/hooks';
import { useGetSingleSalesOrder } from 'pages/Manager/SingleSalesOrder/hooks';
import { IQuotation } from 'types/Quotations.types';
import { ISalesOrder } from 'types/SalesOrders.types';
import { AppointmentType } from 'types/Appointment.types';
import { ICustomer } from 'types/Customer.types';
import { useGetSingleCustomer } from 'pages/Manager/SingleCustomerPage/hooks';

// React select accepts default values ,but those default values need to exist in the options array,
// so if initial option values exist, we inject them into the options array, and then get rid of possible duplicates
export const useGetUserOptions = (
  selectedUserOptions: Option[], // Prop for manually selected options
  usersData: any,
  selectedUsersOptions: Option[] | [] // Prop from PlanningPage
) => {
  // Combine initial and fetched worker options
  const users = useMemo(() => {
    const fetchedUsers = usersData?.pages?.length
      ? usersData.pages
          .map((page: any) => page.users)
          .flat()
          .map((user: IUser) => ({
            value: user.id,
            label: user.name + ' ' + user.last_name,
          }))
      : [];

    // Filter out duplicates
    const mergeArrays = fetchedUsers.concat(
      selectedUserOptions,
      selectedUsersOptions
    );

    return mergeArrays.filter(
      (element: any, index: any, self: any) =>
        self.findIndex((t: any) => t.value === element.value) === index
    );
  }, [usersData, selectedUserOptions, selectedUsersOptions]);

  return users;
};

// React select accepts default values ,but those default values need to exist in the options array,
// so if initial option values exist, we inject them into the options array, and then get rid of possible duplicates
export const useGetWorkerOptions = (
  selectedWorkerOptions: Option[], // Prop for manually selected options
  workersData: any,
  selectedWorkersOptions: Option[] // Prop from PlanningPage
) => {
  // Combine initial and fetched worker options
  const workers = useMemo(() => {
    const fetchedWorkers = workersData?.pages?.length
      ? workersData.pages
          .map((page: any) => page.workers)
          .flat()
          .map((worker: IWorkerAndResource) => ({
            value: worker?.id,
            label: worker?.name,
          }))
      : [];

    // Filter out duplicates
    const mergeArrays = fetchedWorkers.concat(
      selectedWorkerOptions,
      selectedWorkersOptions
    );

    return mergeArrays.filter(
      (element: any, index: any, self: any) =>
        self.findIndex((t: any) => t.value === element.value) === index
    );
  }, [workersData, selectedWorkerOptions, selectedWorkersOptions]);

  return workers;
};

// React select accepts default values ,but those default values need to exist in the options array,
// so if initial option values exist, we inject them into the options array, and then get rid of possible duplicates
export const useGetResourceOptions = (
  selectedResourceOptions: Option[], // Prop for manually selected options
  resourcesData: any,
  selectedResourcesOptions: Option[] // Prop from PlanningPage
) => {
  // Combine initial and fetched resource options
  const resources = useMemo(() => {
    const fetchedResources = resourcesData?.pages?.length
      ? resourcesData.pages
          .map((page: any) => page.resources)
          .flat()
          .map((resource: IWorkerAndResource) => ({
            value: resource?.id,
            label: resource?.name,
          }))
      : [];

    // Filter out duplicates
    const mergeArrays = fetchedResources.concat(
      selectedResourceOptions,
      selectedResourcesOptions
    );

    return mergeArrays.filter(
      (element: any, index: any, self: any) =>
        self.findIndex((t: any) => t.value === element.value) === index
    );
  }, [resourcesData, selectedResourceOptions, selectedResourcesOptions]);

  return resources;
};

export const useHandleSelectOptions = (
  usersData: InfiniteData<any> | undefined,
  workersData: InfiniteData<any> | undefined,
  resourcesData: InfiniteData<any> | undefined,
  selectedUsersOptions: Option[] | [],
  selectedWorkersOptions: Option[],
  selectedResourcesOptions: Option[]
) => {
  // Options which have been manually selected regardless of whether they have been fetched from the backend in the current infinite query
  const [selectedUserOptions, setSelectedUserOptions] = useState<Option[]>([]);
  const [selectedWorkerOptions, setSelectedWorkerOptions] = useState<Option[]>(
    []
  );
  const [selectedResourceOptions, setSelectedResourceOptions] = useState<
    Option[]
  >([]);

  const userOptions = useGetUserOptions(
    selectedUserOptions,
    usersData,
    selectedUsersOptions
  );
  const workerOptions = useGetWorkerOptions(
    selectedWorkerOptions,
    workersData,
    selectedWorkersOptions
  );
  const resourceOptions = useGetResourceOptions(
    selectedResourceOptions,
    resourcesData,
    selectedResourcesOptions
  );
  return {
    userOptions,
    setSelectedUserOptions,
    workerOptions,
    setSelectedWorkerOptions,
    resourceOptions,
    setSelectedResourceOptions,
  };
};

export const useGetSalesOrdersOptions = (
  salesOrdersData: InfiniteData<any> | undefined
) => {
  const salesOrdersOptions = useMemo(() => {
    if (salesOrdersData?.pages?.length) {
      return salesOrdersData.pages
        .map((page: any) => page.sales_orders)
        .flat()
        .map((sales_order: any) => {
          return {
            value: sales_order.id,
            label: sales_order.number,
            sales_order_lines: sales_order.sales_order_lines,
          };
        });
    }
    return [];
  }, [salesOrdersData]);
  return salesOrdersOptions;
};

export const useGetQuotationOptions = (
  quotationsData: InfiniteData<any> | undefined
) => {
  const quotationOptions = useMemo(() => {
    if (quotationsData?.pages?.length) {
      return quotationsData.pages
        .map((page: any) => page.quotations)
        .flat()
        .map((quotation: any) => {
          return {
            value: quotation.id,
            label: quotation.number,
          };
        });
    }
    return [];
  }, [quotationsData]);
  return quotationOptions;
};

export interface ICreateAppointmentNavigationState {
  openModal: boolean;
  type: AppointmentType;
  sales_order_id?: string;
  sales_order_number?: string;
  quotation_id?: string;
  quotation_number?: string;
  customer_id?: string;
  customer_number?: string;
}

export const useGetSelectedNewAppointmentEntity = (
  state: ICreateAppointmentNavigationState | undefined
) => {
  const [selectedQuotationId, setSelectedQuotationId] = useState<
    string | undefined
  >(state?.quotation_id);
  const [selectedSalesOrderId, setSelectedSalesOrderId] = useState<
    string | undefined
  >(state?.sales_order_id);
  const [selectedCustomerId, setSelectedCustomerId] = useState<
    string | undefined
  >(state?.customer_id);

  const { data: quotationData, isLoading: isLoadingQuotation } =
    useGetSingleQuotation(selectedQuotationId || '');

  const { data: salesOrderData, isLoading: isLoadingSalesOrder } =
    useGetSingleSalesOrder(selectedSalesOrderId || '');

  const { data: customerData, isLoading: isLoadingCustomer } =
    useGetSingleCustomer(selectedCustomerId || '');

  const isLoading =
    isLoadingQuotation || isLoadingSalesOrder || isLoadingCustomer;

  const selectedQuotation: IQuotation | undefined = selectedQuotationId
    ? quotationData
    : undefined;
  const selectedSalesOrder: ISalesOrder | undefined = selectedSalesOrderId
    ? salesOrderData
    : undefined;
  const selectedCustomer: ICustomer | undefined = selectedCustomerId
    ? customerData?.customer
    : undefined;

  return {
    isLoading,
    setSelectedQuotationId,
    setSelectedSalesOrderId,
    setSelectedCustomerId,
    selectedQuotation,
    selectedSalesOrder,
    selectedCustomer,
    selectedQuotationId,
    selectedSalesOrderId,
    selectedCustomerId,
  };
};
