import { COLORS } from 'assets/styled';
import { respondTo } from 'assets/styled/breakpoints';
import { hoverLineOut } from 'assets/styled/commonStyles';
import styled, { css } from 'styled-components';

interface StyledProps {
  withoutLine?: boolean;
  flex?: number;
  marginTop?: string;
}

export const Container = styled.div`
  width: calc(100% - 30rem);
  display: flex;
  flex-direction: column;
  padding-top: 20rem;
  padding-bottom: 20rem;
  ${(props: StyledProps) =>
    !props.withoutLine &&
    css`
      border-bottom: 1rem solid ${COLORS.GREY_200};
    `};
`;

export const EditDeleteWrapperRelative = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;

  ${respondTo.bigPhone`
    display: none;
  `}
`;

export const EditIcon = styled.div`
  padding-left: 5rem;
`;

export const EditLabel = styled.label`
  cursor: pointer;
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.PRIMARY};
  cursor: pointer;
  ${hoverLineOut}
`;
export const DeleteLabel = styled.label`
  font-weight: 300;
  font-size: 18rem;
  line-height: 27rem;
  color: ${COLORS.RED_TRASH};
  cursor: pointer;
  ${hoverLineOut}
`;

export const DeleteIcon = styled.div`
  padding-left: 5rem;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
`;

export const ActionsWrapper = styled.div`
  width: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 20rem;
`;

export const RowGap = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20rem;
  align-items: center;
  justify-content: center;
  ${(props: StyledProps) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `};

  ${respondTo.bigPhone`
      flex-direction: column;
      gap: 10rem;
      align-items: flex-start;
    `}
`;

export const Name = styled.label`
  font-weight: 400;
  font-size: 18rem;
  color: ${COLORS.PRIMARY};
  min-width: 180rem;
  max-width: 180rem;
`;

export const Description = styled.label`
  font-weight: 400;
  font-size: 18rem;
  color: ${COLORS.BLACK};
`;
